import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuantityTypePipe } from './pipes/quantity.pipe';

const pipes = [QuantityTypePipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class QuantityModule {}
