import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectHasSessionTimedOut } from '@store/app';
import { Observable } from 'rxjs';
import { SessionTimeoutComponent } from './session-timeout.component';

@Component({
  standalone: true,
  selector: 'app-session-timeout-container',
  imports: [CommonModule, SessionTimeoutComponent],
  template: `<app-session-timeout *ngIf="hasSessionTimedOut$ | async"></app-session-timeout>`,
})
export class SessionTimeoutContainerComponent {
  readonly hasSessionTimedOut$: Observable<boolean> = this.store.select(selectHasSessionTimedOut);
  constructor(private readonly store: Store) {}
}
