import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EncounterClassHistory } from '@hl7fhir';
import { CodingsPipe, PeriodTypePipe } from '@hl7fhir/data-types';

export class EncounterClassHistoryViewModel extends BackboneElementViewModel<EncounterClassHistory> {
  get class(): string | undefined {
    return this.element?.class && new CodingsPipe().transform(this.element.class);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element.period);
  }
}
