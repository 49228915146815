<ng-template #errorModal
             let-modal>
  <div class="modal-header d-flex flex-column align-items-start">
    <div class="d-flex flex-row w-100 justify-content-between">
      <svg class="error-icon">
        <use xlink:href="./assets/images/assets.svg#error-pink"></use>
      </svg>
      <button type="button"
              data-cy="button-close"
              class="btn-close"
              aria-label="Close"
              (click)="modal.dismiss('Close')"></button>
    </div>

    <h5 data-cy="error-title"
        class="modal-title font-weight-bold"
        id="modal-basic-title">{{ error?.title }}</h5>
  </div>
  <div class="modal-body">
    <p data-cy="error-message">{{ error?.message }}</p>
  </div>
  <div class="modal-footer"
       style="justify-content: space-between;">

    @switch (error?.code) {
    @case ('USER_CANCEL') {
    <app-button type="secondary"
                data-cy="button-request-provider"
                (clicked)="requestProvider()"
                i18n-label="@@app.Error.onboard.userCancel.button.request"
                label="Request Your Provider"></app-button>
    <app-button type="primary"
                data-cy="button-cancel"
                (clicked)="modal.dismiss('Cancel')"
                i18n-label="@@app.Error.onboard.userCancel.button.cancel"
                label="Cancel"></app-button>
    }
    @case ('BINARY_NOT_FOUND') {
    <app-button type="secondary"
                (clicked)="goToSources(); modal.dismiss('Cancel')"
                i18n-label="@@docs.binary.error.modalBtnSecondary"
                label="Go to Sources"></app-button>
    <app-button type="primary"
                data-cy="button-cancel"
                (clicked)="modal.dismiss('Cancel')"
                i18n-label="@@docs.binary.error.modalBtnPrimary"
                label="Ok"></app-button>
    }
    @default {
    <app-button *ngrxLet="user$ as user"
                type="secondary"
                data-cy="button-contact-support"
                (clicked)="contactSupport(user)"
                i18n-label="@@app.ContactSupport"
                label="Contact Support"></app-button>
    <app-button type="primary"
                data-cy="button-try-again"
                (clicked)="modal.dismiss('TryAgain')"
                i18n-label="@@app.TryAgain"
                label="Try Again"></app-button>
    }
    }
  </div>
</ng-template>