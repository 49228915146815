import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const FlagStatus: CodeSystemFlattened = {
  id: 'flag-status',
  codes: {
    active: {
      display: 'Active',
    },
    inactive: {
      display: 'Inactive',
    },
    'entered-in-error': {
      display: 'Entered in Error',
    },
  },
};
