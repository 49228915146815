import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '@layout';

@Component({
  selector: 'app-digi-me-onboard',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './digi-me-onboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigiMeOnboardComponent {
  @Output() sourceLink = new EventEmitter();
}
