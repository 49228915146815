import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  /**
   * @param arr
   * Array can hold any items with the same properties
   * @param name
   * Name of the properties that can contain values to concatenate into a string
   */
  transform(arr: any[] | undefined, name?: string) {
    if (!arr) {
      return undefined;
    }

    if (name) {
      const items = arr.filter((item) => item[name] !== undefined);
      return items.length >= 1 ? items.map((item) => item[name]).join(', ') : undefined;
    }

    return arr.length >= 1 ? arr.join(', ') : undefined;
  }
}
