import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { MedicationPackageContent } from '@hl7fhir';
import { CodeableConceptPipe, QuantityTypePipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';

export class MedicationPackageContentViewModel extends BackboneElementViewModel<MedicationPackageContent> {
  get itemCodeableConcept(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.itemCodeableConcept);
  }

  get itemReference(): string | undefined {
    return this.element?.itemReference && getReference(this.element.itemReference); // TODO: Reference
  }

  get amount(): string | undefined {
    return this.element?.amount && new QuantityTypePipe().transform(this.element.amount);
  }
}
