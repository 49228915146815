import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayOfWeek',
})
export class DayOfWeekPipe implements PipeTransform {
  transform(days: string[] | undefined): string {
    if (!days) {
      return '';
    }

    return days
      .map((day) => {
        switch (day) {
          case 'mon':
            return $localize`:@@fhir.timing-repeat-day-of-week.monday:Monday`;
          case 'tue':
            return $localize`:@@fhir.timing-repeat-day-of-week.tuesday:Tuesday`;
          case 'wed':
            return $localize`:@@fhir.timing-repeat-day-of-week.wednesday:Wednesday`;
          case 'thu':
            return $localize`:@@fhir.timing-repeat-day-of-week.thursday:Thursday`;
          case 'fri':
            return $localize`:@@fhir.timing-repeat-day-of-week.friday:Friday`;
          case 'sat':
            return $localize`:@@fhir.timing-repeat-day-of-week.saturday:Saturday`;
          case 'sun':
            return $localize`:@@fhir.timing-repeat-day-of-week.sunday:Sunday`;
          default:
            return '';
        }
      })
      .join(', ');
  }
}
