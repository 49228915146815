import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, mapToCanActivate } from '@angular/router';
import {
  CallbackComponent,
  DefaultComponent,
  LoginComponent,
  MainComponent,
  PageNotFoundComponent,
  ReturnComponent,
  TopMenuComponent,
} from '@core';
import { DeepLinkLoginGuard } from '@core/services';
import { isAuthenticated } from './authentication/is-authenticated';
import { GettingStartedConsentContainerComponent } from './pages/onboard-or-log-in/getting-started-consent.container';
import { OnboardOrLogInContainerComponent } from './pages/onboard-or-log-in/onboard-or-log-in.container';

const routes: Routes = [
  { path: 'callback', component: CallbackComponent },
  {
    path: '',
    redirectTo: '/linked-sources',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      { path: 'return', component: ReturnComponent },
      {
        path: '',
        component: TopMenuComponent,
        loadChildren: () => import('./pages/fhir/fhir.module').then((m) => m.FhirModule),
      },
      {
        path: 'my-details',
        component: MainComponent,
        loadChildren: () => import('./pages/my-details/my-details.module').then((m) => m.MyDetailsModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'my-health-record',
        component: MainComponent,
        loadChildren: () =>
          import('./pages/my-health-record/my-health-record.module').then((m) => m.MyHealthRecordModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'linked-sources',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/linked-sources/linked-sources-routing.module').then((m) => m.LinkedSourcesRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'faq',
        component: TopMenuComponent,
        loadChildren: () => import('./pages/faq/faq-routing.module').then((m) => m.FaqRoutingModule),
      },
      {
        path: 'portability-report',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/portability-report/portability-report-routing.module').then(
            (m) => m.PortabilityReportRoutingModule
          ),
        canActivate: [isAuthenticated],
      },
      {
        path: 'share',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/share-measurements/share-measurements-routing.module').then(
            (m) => m.ShareMeasurementsRoutingModule
          ),
        canActivate: [isAuthenticated],
      },
      {
        path: 'notifications',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/notifications/notifications-routing.module').then((m) => m.NotificationsRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'privacy-policy',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/privacy-policy/privacy-policy-routing.module').then((m) => m.PrivacyPolicyRoutingModule),
      },
      {
        path: 'terms-and-conditions',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/terms-and-conditions/terms-and-conditions-routing.module').then(
            (m) => m.TermsAndConditionsRoutingModule
          ),
      },
      {
        path: 'settings',
        component: TopMenuComponent,
        loadChildren: () => import('./pages/settings/settings-routing.module').then((m) => m.SettingsRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: '',
        component: TopMenuComponent,
        children: [
          { path: 'onboard-or-log-in', component: OnboardOrLogInContainerComponent },
          { path: 'consent', component: GettingStartedConsentContainerComponent },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: mapToCanActivate([DeepLinkLoginGuard]),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // Preload, because otherwise the navigation cannot be completed if all files are loaded
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
