<div class="border-top border-secondary">
  <div data-cy="names-header"
       *ngIf="humanNames?.length !== 1"
       class="p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span class="h6"
          i18n="@@app.humanNames.header">Names</span>
  </div>

  <p *ngIf="!humanNames || humanNames.length === 0"
     class="p-2"
     data-cy="names-empty"
     i18n="@@app.humanNames.empty">No names to show.</p>

  <ng-container *ngFor="let humanName of humanNames">
    <div data-cy="name-header"
         class="hstack gap-2 h-6 p-2 border-secondary border-bottom border-top  bg-secondary bg-opacity-10">
      <span i18n="@@fhir.HumanName.name"
            class="h6 m-0">Name</span>
      <span i18n="@@fhir.HumanName.use"
            class="ms-auto text-muted">Usage</span>
      <span data-cy="use">{{ humanName?.use | nameUse }}</span>
    </div>
    <app-human-name [humanName]="humanName"></app-human-name>
  </ng-container>
</div>