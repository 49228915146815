import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimingRepeat } from '@hl7fhir';

@Component({
  selector: 'app-timing-repeat',
  templateUrl: './timing-repeat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimingRepeatComponent {
  @Input() timingRepeat: TimingRepeat | undefined;
  @Input() timingRepeatHeader = $localize`:@@app.TimingRepeats.header:Timings Repeat`;
}
