import { Immunization } from '@hl7fhir';
import { ImmunizationViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromImmunization from './immunization-reducer';

export const selectImmunizationState = createFeatureSelector<fromImmunization.State>(features.FHIR.immunizations);

export const selectImmunizationEntities = createSelector(
  selectImmunizationState,
  fromImmunization.selectImmunizationEntities
);

export const selectAllImmunizations = createSelector(selectImmunizationState, fromImmunization.selectAllImmunizations);

export const selectImmunizationById = createSelector(
  selectRouteParams,
  selectImmunizationEntities,
  ({ id }, immunizations: Dictionary<Immunization>) =>
    id && (immunizations[id] ?? immunizations[id.toLowerCase()] ?? immunizations[id.toUpperCase()])
);

export const selectAllImmunizationViewModels = createSelector(selectAllImmunizations, (immunizations) =>
  immunizations.map((immunization) => new ImmunizationViewModel(immunization))
);

export const selectImmunizationViewModelById = createSelector(
  selectImmunizationById,
  (entity: Immunization | undefined) => entity && new ImmunizationViewModel(entity)
);
