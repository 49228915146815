import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Timing } from '@hl7fhir';

@Component({
  selector: 'app-timing',
  templateUrl: './timing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimingComponent implements OnChanges {
  @Input() timing: Timing[] | Timing | undefined;
  @Input() timingHeader = $localize`:@@app.Timings.header:Timings`;

  private _timing: Timing[] | undefined;

  get timings(): Timing[] | undefined {
    return this._timing;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const timing = changes['timing'].currentValue;

    if (timing) {
      this._timing = Array.isArray(timing) ? timing : [timing];
    } else {
      this._timing = undefined;
    }
  }
}
