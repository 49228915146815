import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const GeslachtCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.0.1.1--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/NullFlavor': {
      UNK: {
        'nl-NL': 'Onbekend',
        display: 'Unknown',
      },
    },
    'http://hl7.org/fhir/v3/AdministrativeGender': {
      UN: {
        'nl-NL': 'Ongedifferentieerd',
        display: 'Undifferentiated',
      },
      M: {
        'nl-NL': 'Man',
        display: 'Male',
      },
      F: {
        'nl-NL': 'Vrouw',
        display: 'Female',
      },
    },
  },
};
