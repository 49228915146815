import { Component, Input } from '@angular/core';
import { SharedModule } from '@shared';
import { AccountWarningsModalComponent } from '../account-warnings-modal/account-warnings-modal.component';
import { AccountWarnings } from '../account-warnings.model';

@Component({
  standalone: true,
  selector: 'app-accounts-warnings',
  templateUrl: './accounts-warnings.component.html',
  imports: [SharedModule, AccountWarningsModalComponent],
})
export class AccountsWarningsComponent {
  @Input({ required: true }) accountsWarnings!: AccountWarnings[] | null;

  openModal: boolean = false;
  selectedAccount!: AccountWarnings;

  open(account: AccountWarnings): void {
    this.openModal = true;
    this.selectedAccount = account;
  }
}
