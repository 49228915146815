import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { CodeableConceptModule } from '../codeable-concept';
import { DurationModule } from '../duration';
import { PeriodModule } from '../period';
import { RangeModule } from '../range';
import { DayOfWeekPipe, TimingRepeatComponent, UnitPipe } from './timing-repeat';
import { TimingComponent } from './timing.component';

const components = [TimingComponent];
const pipes = [DayOfWeekPipe, UnitPipe];

@NgModule({
  imports: [SharedModule, CodeableConceptModule, DurationModule, RangeModule, PeriodModule],
  declarations: [TimingRepeatComponent, ...components, ...pipes],
  exports: [...components],
})
export class TimingModule {}
