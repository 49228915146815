import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-section',
  templateUrl: 'section.component.html',
  imports: [MatIconModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) icon!: string;

  @Input({ required: false }) value: string | undefined;
}
