import { Composition } from '@hl7fhir';
import { CompositionViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromComposition from './composition.reduce';

export const selectCompositionState = createFeatureSelector<fromComposition.State>(features.FHIR.composition);

export const selectCompositionEntities = createSelector(
  selectCompositionState,
  fromComposition.selectCompositionEntities
);

export const selectAllCompositions = createSelector(selectCompositionState, fromComposition.selectAllCompositions);

export const selectCompositionById = createSelector(
  selectRouteParams,
  selectCompositionEntities,
  ({ id }, compositions: Dictionary<Composition>) =>
    id && (compositions[id] ?? compositions[id.toLowerCase()] ?? compositions[id.toUpperCase()])
);

export const selectAllCompositionViewModels = createSelector(selectAllCompositions, (compositions: Composition[]) =>
  compositions.map((composition) => new CompositionViewModel(composition))
);

export const selectCompositionViewModelById = createSelector(
  selectCompositionById,
  (entity: Composition | undefined) => entity && new CompositionViewModel(entity)
);
