import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicationDispenseStatus',
})
export class MedicationDispenseStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'preparation':
        return $localize`:@@fhir.medication-status.preparation:Preparation`;
      case 'in-progress':
        return $localize`:@@fhir.medication-status.in-progress:In Progress`;
      case 'on-hold':
        return $localize`:@@fhir.medication-status.on-hold:On Hold`;
      case 'completed':
        return $localize`:@@fhir.medication-status.completed:Completed`;
      case 'entered-in-error':
        return $localize`:@@fhir.medication-status.entered-in-error:Entered in Error`;
      case 'stopped':
        return $localize`:@@fhir.medication-status.stopped:Stopped`;
      default:
        return '';
    }
  }
}
