import { Component } from '@angular/core';

@Component({
  selector: 'app-login',
  template: '',
})
/**
 * This empty component is used only for routing purposes.
 * All the login processes happen in the DeepLinkLoginGuard service.
 * The page will not be accessed by the user.
 */
export class LoginComponent {}
