import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allergyIntoleranceVerificationStatus',
})
export class AllergyIntoleranceVerificationStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'unconfirmed':
        return $localize`:@@fhir.allergy-intolerance-verification-status.unconfirmed:Unconfirmed`;
      case 'presumed':
        return $localize`:@@fhir.allergyintolerance-verification.presumed:Presumed`;
      case 'confirmed':
        return $localize`:@@fhir.allergy-intolerance-verification-status.confirmed:Confirmed`;
      case 'refuted':
        return $localize`:@@fhir.allergy-intolerance-verification-status.refuted:Refuted`;
      case 'entered-in-error':
        return $localize`:@@fhir.allergy-intolerance-verification-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
