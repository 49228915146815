export interface AccessTokenStatus {
  authorized: boolean;
  expiresAt?: number;
}

export type AccountType =
  | 'USER'
  | 'ADMIN'
  | 'EVENT'
  | 'GROUP'
  | 'BANK'
  | 'CREDIT_CARD'
  | 'IMPORTED'
  | 'INVESTMENT'
  | 'INSURANCE'
  | 'LOAN'
  | 'REWARD'
  | 'BILL'
  | 'PUSH';

export interface AccountsResponse {
  id: string;
  accessTokenStatus?: AccessTokenStatus;
  reference: string;
  type: AccountType;
  createdDate: number;
  serviceGroupId: number;
  serviceGroupName: string;
  serviceProviderId?: number;
  serviceProviderName?: string;
  serviceProviderReference?: string;
  serviceTypeId: number;
  serviceTypeName: string;
  serviceTypeReference: string;
  sourceId: number;
  updatedDate: number;
  username?: string;
  providerFavIcon?: string;
  providerLogo?: string;
  sample?: boolean;
}

export interface ReadAccountsResponse {
  accounts: AccountsResponse[];
}
