import { Pipe, PipeTransform } from '@angular/core';
import { Quantity } from '@hl7fhir';

@Pipe({
  name: 'quantity',
})
export class QuantityTypePipe implements PipeTransform {
  transform(quantity: Quantity | undefined): string {
    if (!quantity) {
      return '';
    }

    return quantity.value ? `${quantity.value}${quantity.unit ? ` ${quantity.unit}` : ''}` : '';
  }
}
