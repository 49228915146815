import { Pipe, PipeTransform } from '@angular/core';
import { Age } from '@hl7fhir';

@Pipe({
  name: 'age',
})
export class AgeTypePipe implements PipeTransform {
  transform(age: Age | undefined): string | undefined {
    if (!age) {
      return '';
    }

    return age.value ? `${age.value}${age.unit ? ` ${age.unit}` : ''}` : '';
  }
}
