import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compositionSectionMode',
})
export class CompositionSectionModePipe implements PipeTransform {
  transform(mode: string | undefined): string {
    switch (mode) {
      case 'working':
        return $localize`:@@fhir.composition-section-mode.working:Working`;
      case 'snapshot':
        return $localize`:@@fhir.composition-section-mode.snapshot:Snapshot`;
      case 'changes':
        return $localize`:@@fhir.composition-section-mode.changes:Changes`;
      default:
        return '';
    }
  }
}
