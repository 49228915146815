import { DetailsItem, ElementItem } from '@resource-view/models';
import { AnnotationViewModel } from './annotation.viewmodel';

export function buildAnnotationsDetails(vms: AnnotationViewModel[] | undefined): DetailsItem[] | undefined {
  return vms?.map((vm: AnnotationViewModel, index: number) => ({
    id: `note-${index}`,
    header: vm.time ? $localize`:@@fhir.Annotation:Note` + ` - ${vm.time}` : $localize`:@@fhir.Annotation:Note`,
    elements: buildElements(vm),
  }));
}

function buildElements(vm: AnnotationViewModel): ElementItem[] {
  return [
    {
      id: 'author',
      header: $localize`:@@fhir.Annotation.author:Author`,
      value: vm.author,
    },
    {
      id: 'text',
      header: $localize`:@@fhir.Annotation.text:Text`,
      value: vm.text,
    },
    {
      id: 'time',
      header: $localize`:@@fhir.Annotation.time:Time`,
      value: vm.time,
    },
    {
      id: 'authorReference',
      header: $localize`:@@fhir.Annotation.AuthorReference:Author Reference`,
      value: vm.authorReference,
    },
  ];
}
