import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemActReason: CodeSystemFlattened = {
  id: 'v3-ActReason',
  codes: {
    _ActAccommodationReason: {
      display: 'ActAccommodationReason',
    },
    _ActCoverageReason: {
      display: 'ActCoverageReason',
    },
    _ActInformationManagementReason: {
      display: 'ActInformationManagementReason',
    },
    _ActInvalidReason: {
      display: 'ActInvalidReason',
    },
    _ActInvoiceCancelReason: {
      display: 'ActInvoiceCancelReason',
    },
    _ActNoImmunizationReason: {
      display: 'ActNoImmunizationReason',
    },
    _ActSupplyFulfillmentRefusalReason: {
      display: 'ActSupplyFulfillmentRefusalReason',
    },
    _ClinicalResearchEventReason: {
      display: 'ClinicalResearchEventReason',
    },
    _ClinicalResearchObservationReason: {
      display: 'ClinicalResearchObservationReason',
    },
    _CombinedPharmacyOrderSuspendReasonCode: {
      display: 'CombinedPharmacyOrderSuspendReasonCode',
    },
    _ControlActNullificationReasonCode: {
      display: 'ControlActNullificationReasonCode',
    },
    _ControlActNullificationRefusalReasonType: {
      display: 'ControlActNullificationRefusalReasonType',
    },
    _ControlActReason: {
      display: 'ControlActReason',
    },
    _GenericUpdateReasonCode: {
      display: 'GenericUpdateReasonCode',
    },
    _PatientProfileQueryReasonCode: {
      display: 'patient profile query reason',
    },
    _PharmacySupplyRequestFulfillerRevisionRefusalReasonCode: {
      display: 'PharmacySupplyRequestFulfillerRevisionRefusalReasonCode',
    },
    _RefusalReasonCode: {
      display: 'RefusalReasonCode',
    },
    _SchedulingActReason: {
      display: 'SchedulingActReason',
    },
    _StatusRevisionRefusalReasonCode: {
      display: 'StatusRevisionRefusalReasonCode',
    },
    _SubstanceAdministrationPermissionRefusalReasonCode: {
      display: 'SubstanceAdministrationPermissionRefusalReasonCode',
    },
    _SubstanceAdminSubstitutionNotAllowedReason: {
      display: 'SubstanceAdminSubstitutionNotAllowedReason',
    },
    _SubstanceAdminSubstitutionReason: {
      display: 'SubstanceAdminSubstitutionReason',
    },
    _TransferActReason: {
      display: 'TransferActReason',
    },
    _ActBillableServiceReason: {
      display: 'ActBillableServiceReason',
    },
    BONUS: {},
    CHD: {
      display: 'Children only',
    },
    DEP: {
      display: 'Dependents only',
    },
    ECH: {
      display: 'Employee and children',
    },
    EDU: {},
    EMP: {
      display: 'Employee only',
    },
    ESP: {
      display: 'Employee and spouse',
    },
    FAM: {
      display: 'Family',
    },
    IND: {
      display: 'Individual',
    },
    INVOICE: {},
    PROA: {},
    RECOV: {},
    RETRO: {},
    SPC: {
      display: 'Spouse and children',
    },
    SPO: {
      display: 'Spouse only',
    },
    TRAN: {},
    ACCREQNA: {
      display: 'Accommodation Requested Not Available',
    },
    FLRCNV: {
      display: 'Floor Convenience',
    },
    MEDNEC: {
      display: 'Medical Necessity',
    },
    PAT: {
      display: 'Patient request',
    },
    _EligibilityActReasonCode: {
      display: 'EligibilityActReasonCode',
    },
    _ActIneligibilityReason: {
      display: 'ActIneligibilityReason',
    },
    _CoverageEligibilityReason: {
      display: 'CoverageEligibilityReason',
    },
    COVSUS: {
      display: 'coverage suspended',
    },
    DECSD: {
      display: 'deceased',
    },
    REGERR: {
      display: 'registered in error',
    },
    AGE: {
      display: 'age eligibility',
    },
    CRIME: {
      display: 'crime victim',
    },
    DIS: {
      display: 'disability',
    },
    EMPLOY: {
      display: 'employment benefit',
    },
    FINAN: {
      display: 'financial eligibility',
    },
    HEALTH: {
      display: 'health status',
    },
    MULTI: {
      display: 'multiple criteria eligibility',
    },
    PNC: {
      display: 'property and casualty condition',
    },
    STATUTORY: {
      display: 'statutory eligibility',
    },
    VEHIC: {
      display: 'motor vehicle accident victim',
    },
    WORK: {
      display: 'work related',
    },
    _ActHealthInformationManagementReason: {
      display: 'ActHealthInformationManagementReason',
    },
    _ActInformationPrivacyReason: {
      display: 'ActInformationPrivacyReason',
    },
    _ActConsentInformationAccessOverrideReason: {
      display: 'ActConsentInformationAccessOverrideReason',
    },
    PurposeOfUse: {
      display: 'purpose of use',
    },
    OVRER: {
      display: 'emergency treatment override',
    },
    OVRPJ: {
      display: 'professional judgment override',
    },
    OVRPS: {
      display: 'public safety override',
    },
    OVRTPS: {
      display: 'third party safety override',
    },
    HMARKT: {
      display: 'healthcare marketing',
    },
    HOPERAT: {
      display: 'healthcare operations',
    },
    HPAYMT: {
      display: 'healthcare payment',
    },
    HRESCH: {
      display: 'healthcare research',
    },
    PATRQT: {
      display: 'patient requested',
    },
    PUBHLTH: {
      display: 'public health',
    },
    TREAT: {
      display: 'treatment',
    },
    DONAT: {
      display: 'donation',
    },
    FRAUD: {
      display: 'fraud',
    },
    GOV: {
      display: 'government',
    },
    HACCRED: {
      display: 'health accreditation',
    },
    HCOMPL: {
      display: 'health compliance',
    },
    HDECD: {
      display: 'decedent',
    },
    HDIRECT: {
      display: 'directory',
    },
    HLEGAL: {
      display: 'legal',
    },
    HOUTCOMS: {
      display: 'health outcome measure',
    },
    HPRGRP: {
      display: 'health program reporting',
    },
    HQUALIMP: {
      display: 'health quality improvement',
    },
    HSYSADMIN: {
      display: 'health system administration',
    },
    MEMADMIN: {
      display: 'member administration',
    },
    PATADMIN: {
      display: 'patient administration',
    },
    PATSFTY: {
      display: 'patient safety',
    },
    PERFMSR: {
      display: 'performance measure',
    },
    RECORDMGT: {
      display: 'records management',
    },
    TRAIN: {
      display: 'training',
    },
    CLMATTCH: {
      display: 'claim attachment',
    },
    COVAUTH: {
      display: 'coverage authorization',
    },
    COVERAGE: {
      display: 'coverage under policy or program',
    },
    REMITADV: {
      display: 'remittance advice',
    },
    ELIGDTRM: {
      display: 'eligibility determination',
    },
    ELIGVER: {
      display: 'eligibility verification',
    },
    ENROLLM: {
      display: 'enrollment',
    },
    CLINTRCH: {
      display: 'clinical trial research',
    },
    FAMRQT: {
      display: 'family requested',
    },
    PWATRNY: {
      display: 'power of attorney',
    },
    SUPNWK: {
      display: 'support network',
    },
    DISASTER: {
      display: 'disaster',
    },
    THREAT: {
      display: 'threat',
    },
    CAREMGT: {
      display: 'Care Management',
    },
    CLINTRL: {
      display: 'clinical trial',
    },
    ETREAT: {
      display: 'Emergency Treatment',
    },
    POPHLTH: {
      display: 'population health',
    },
    MARKT: {
      display: 'marketing',
    },
    OPERAT: {
      display: 'operations',
    },
    PAYMT: {
      display: 'payment',
    },
    RESCH: {
      display: 'research',
    },
    SRVC: {
      display: 'service',
    },
    LEGAL: {
      display: 'subpoena',
    },
    ACCRED: {
      display: 'accreditation',
    },
    COMPL: {
      display: 'compliance',
    },
    ENADMIN: {
      display: 'entity administration',
    },
    OUTCOMS: {
      display: 'outcome measure',
    },
    PRGRPT: {
      display: 'program reporting',
    },
    QUALIMP: {
      display: 'quality improvement',
    },
    SYSADMN: {
      display: 'system administration',
    },
    ADVSTORAGE: {
      display: 'adverse storage condition',
    },
    EXPLOT: {
      display: 'expired lot',
    },
    OUTSIDESCHED: {
      display: 'administered outside recommended schedule or practice',
    },
    PRODRECALL: {
      display: 'product recall',
    },
    COLDCHNBRK: {
      display: 'cold chain break',
    },
    INCCOVPTY: {
      display: 'incorrect covered party as patient',
    },
    INCINVOICE: {
      display: 'incorrect billing',
    },
    INCPOLICY: {
      display: 'incorrect policy',
    },
    INCPROV: {
      display: 'incorrect provider',
    },
    IMMUNE: {
      display: 'immunity',
    },
    MEDPREC: {
      display: 'medical precaution',
    },
    OSTOCK: {
      display: 'product out of stock',
    },
    PATOBJ: {
      display: 'patient objection',
    },
    PHILISOP: {
      display: 'philosophical objection',
    },
    RELIG: {
      display: 'religious objection',
    },
    VACEFF: {
      display: 'vaccine efficacy concerns',
    },
    VACSAF: {
      display: 'vaccine safety concerns',
    },
    FRR01: {
      display: 'order stopped',
    },
    FRR02: {
      display: 'stale-dated order',
    },
    FRR03: {
      display: 'incomplete data',
    },
    FRR04: {
      display: 'product unavailable',
    },
    FRR05: {
      display: 'ethical/religious',
    },
    FRR06: {
      display: 'unable to provide care',
    },
    RET: {
      display: 'retest',
    },
    SCH: {
      display: 'scheduled',
    },
    TRM: {
      display: 'termination',
    },
    UNS: {
      display: 'unscheduled',
    },
    NPT: {
      display: 'non-protocol',
    },
    PPT: {
      display: 'per protocol',
    },
    UPT: {
      display: 'per definition',
    },
    ALTCHOICE: {
      display: 'try another treatment first',
    },
    CLARIF: {
      display: 'prescription requires clarification',
    },
    DRUGHIGH: {
      display: 'drug level too high',
    },
    HOSPADM: {
      display: 'admission to hospital',
    },
    LABINT: {
      display: 'lab interference issues',
    },
    'NON-AVAIL': {
      display: 'patient not-available',
    },
    PREG: {
      display: 'parent is pregnant/breast feeding',
    },
    SALG: {
      display: 'allergy',
    },
    SDDI: {
      display: 'drug interacts with another drug',
    },
    SDUPTHER: {
      display: 'duplicate therapy',
    },
    SINTOL: {
      display: 'suspected intolerance',
    },
    SURG: {
      display: 'patient scheduled for surgery',
    },
    WASHOUT: {
      display: 'waiting for old drug to wash out',
    },
    ALTD: {
      display: 'altered decision',
    },
    EIE: {
      display: 'entered in error',
    },
    NORECMTCH: {
      display: 'no record match',
    },
    INRQSTATE: {
      display: 'in requested state',
    },
    NOMATCH: {
      display: 'no match',
    },
    NOPERM: {
      display: 'no permission',
    },
    WRNGVER: {
      display: 'wrong version',
    },
    NOPRODMTCH: {
      display: 'no product match',
    },
    NOSERMTCH: {
      display: 'no service match',
    },
    NOVERMTCH: {
      display: 'no version match',
    },
    NOUSERPERM: {
      display: 'no user permission',
    },
    NOAGNTPERM: {
      display: 'no agent permission',
    },
    NOUSRPERM: {
      display: 'no user permission',
    },
    _MedicationOrderAbortReasonCode: {
      display: 'medication order abort reason',
    },
    _MedicationOrderReleaseReasonCode: {
      display: 'medication order release reason',
    },
    _ModifyPrescriptionReasonType: {
      display: 'ModifyPrescriptionReasonType',
    },
    _PharmacySupplyEventAbortReason: {
      display: 'PharmacySupplyEventAbortReason',
    },
    _PharmacySupplyEventStockReasonCode: {
      display: 'pharmacy supply event stock reason',
    },
    _PharmacySupplyRequestRenewalRefusalReasonCode: {
      display: 'pharmacy supply request renewal refusal reason',
    },
    _SupplyOrderAbortReasonCode: {
      display: 'supply order abort reason',
    },
    DISCONT: {
      display: 'product discontinued',
    },
    INEFFECT: {
      display: 'ineffective',
    },
    MONIT: {
      display: 'response to monitoring',
    },
    NOREQ: {
      display: 'no longer required for treatment',
    },
    NOTCOVER: {
      display: 'not covered',
    },
    PREFUS: {
      display: 'patient refuse',
    },
    RECALL: {
      display: 'product recalled',
    },
    REPLACE: {
      display: 'change in order',
    },
    REPLACEFIX: {
      display: 'error in order',
    },
    UNABLE: {
      display: 'unable to use',
    },
    DOSECHG: {
      display: 'change in medication/dose',
    },
    HOLDDONE: {
      display: 'suspend reason no longer applies',
    },
    HOLDINAP: {
      display: 'suspend reason inappropriate',
    },
    ADMINERROR: {
      display: 'administrative error in order',
    },
    CLINMOD: {
      display: 'clinical modification',
    },
    CONTRA: {
      display: 'contraindication',
    },
    FOABORT: {
      display: 'order aborted',
    },
    FOSUSP: {
      display: 'order suspended',
    },
    NOPICK: {
      display: 'not picked up',
    },
    PATDEC: {
      display: 'patient changed mind',
    },
    QUANTCHG: {
      display: 'change supply quantity',
    },
    FLRSTCK: {
      display: 'floor stock',
    },
    LTC: {
      display: 'long term care use',
    },
    OFFICE: {
      display: 'office use',
    },
    PHARM: {
      display: 'pharmacy transfer',
    },
    PROG: {
      display: 'program use',
    },
    ALREADYRX: {
      display: 'new prescription exists',
    },
    FAMPHYS: {
      display: 'family physician must authorize further fills',
    },
    MODIFY: {
      display: 'modified prescription exists',
    },
    NEEDAPMT: {
      display: 'patient must make appointment',
    },
    NOTAVAIL: {
      display: 'prescriber not available',
    },
    NOTPAT: {
      display: 'patient no longer in this practice',
    },
    ONHOLD: {
      display: 'medication on hold',
    },
    PRNA: {
      display: 'product not available',
    },
    STOPMED: {
      display: 'prescriber stopped medication for patient',
    },
    TOOEARLY: {
      display: 'too early',
    },
    IMPROV: {
      display: 'condition improved',
    },
    INTOL: {
      display: 'intolerance',
    },
    NEWSTR: {
      display: 'new strength',
    },
    NEWTHER: {
      display: 'new therapy',
    },
    CHGDATA: {
      display: 'information change',
    },
    FIXDATA: {
      display: 'error correction',
    },
    MDATA: {
      display: 'merge data',
    },
    NEWDATA: {
      display: 'new information',
    },
    UMDATA: {
      display: 'unmerge data',
    },
    ADMREV: {
      display: 'administrative review',
    },
    PATCAR: {
      display: 'patient care',
    },
    PATREQ: {
      display: 'patient request query',
    },
    PRCREV: {
      display: 'practice review',
    },
    REGUL: {
      display: 'regulatory review',
    },
    RSRCH: {
      display: 'research',
    },
    VALIDATION: {
      display: 'validation review',
    },
    LOCKED: {
      display: 'locked',
    },
    UNKWNTARGET: {
      display: 'unknown target',
    },
    BLK: {
      display: 'Unexpected Block (of Schedule)',
    },
    DEC: {
      display: 'Patient Deceased',
    },
    FIN: {
      display: 'No Financial Backing',
    },
    MED: {
      display: 'Medical Status Altered',
    },
    MTG: {
      display: 'In an outside meeting',
    },
    PHY: {
      display: 'Physician request',
    },
    FILLED: {
      display: 'fully filled',
    },
    PATINELIG: {
      display: 'patient not eligible',
    },
    PROTUNMET: {
      display: 'protocol not met',
    },
    PROVUNAUTH: {
      display: 'provider not authorized',
    },
    ALGINT: {
      display: 'allergy intolerance',
    },
    COMPCON: {
      display: 'compliance concern',
    },
    THERCHAR: {
      display: 'therapeutic characteristics',
    },
    TRIAL: {
      display: 'clinical trial drug',
    },
    CT: {
      display: 'continuing therapy',
    },
    FP: {
      display: 'formulary policy',
    },
    OS: {
      display: 'out of stock',
    },
    RR: {
      display: 'regulatory requirement',
    },
    ER: {
      display: 'Error',
    },
    RQ: {
      display: 'Request',
    },
    _ActBillableClinicalServiceReason: {
      display: 'ActBillableClinicalServiceReason',
    },
  },
};
