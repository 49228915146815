import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { formatLocaleDate } from '@globals';
import {
  Age,
  Attachment,
  CodeableConcept,
  Period,
  Quantity,
  Range,
  Ratio,
  Reference,
  SampledData,
  Timing,
} from '@hl7fhir';

@Component({
  selector: 'app-choice-of-types',
  templateUrl: './choice-of-types.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoiceOfTypesComponent {
  @Input() dateTime: string | undefined;

  @Input() age: Age | undefined;

  @Input() period: Period | undefined;

  @Input() range: Range | undefined;

  @Input() boolean: boolean | undefined;

  @Input() string: string | undefined;

  @Input() instant: string | undefined;

  @Input() timing: Timing | undefined;

  @Input() quantity: Quantity | undefined;

  @Input() codeableConcept: CodeableConcept | undefined;

  @Input() integer: number | undefined;

  @Input() ratio: Ratio | undefined;

  @Input() sampledData: SampledData | undefined;

  @Input() time: string | undefined;

  @Input() attachment: Attachment | undefined;

  @Input() reference: Reference | undefined;

  get formattedDateTime() {
    return formatLocaleDate(this.dateTime, 'long');
  }
}
