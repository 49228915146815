import { DomainResource, Resource } from '@hl7fhir';
import { FhirVersion, VersionPipe } from '@hl7fhir/foundation';

export abstract class ResourceViewModel<T extends Resource> {
  constructor(readonly resource: T | undefined) {}

  /**
   * The source account id from digi.me.
   */
  get accountEntityId(): string | undefined {
    if (this.resource && 'accountentityid' in this.resource) {
      return this.resource.accountentityid as string;
    }
    return undefined;
  }

  get id(): string | undefined {
    return this.resource?.id;
  }

  get resourceType(): string | undefined {
    return this.resource?.resourceType;
  }

  get fhirVersion(): FhirVersion | undefined {
    if (new VersionPipe().transform(this.resource, 'R3')) {
      return 'R3';
    } else if (new VersionPipe().transform(this.resource, 'R4')) {
      return 'R4';
    } else if (new VersionPipe().transform(this.resource, 'R4b')) {
      return 'R4b';
    } else if (new VersionPipe().transform(this.resource, 'R5')) {
      return 'R5';
    }

    return undefined;
  }

  get profile(): string[] | undefined {
    return this.resource?.meta?.profile;
  }

  hasProfile(profile: string): boolean {
    return this.profile?.some((item: string) => item === profile) ?? false;
  }

  protected hasVersion(...versions: FhirVersion[]): boolean {
    if (!this.fhirVersion) {
      return false;
    }
    return versions.includes(this.fhirVersion);
  }

  protected isR3(): boolean {
    return this.hasVersion('R3');
  }

  protected isR5(): boolean {
    return this.hasVersion('R5');
  }
}

export abstract class DomainResourceViewModel<T extends DomainResource> extends ResourceViewModel<T> {}
