import { ElementViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { Annotation } from '@hl7fhir';
import { getReference } from '@hl7fhir/foundation';

export class AnnotationViewModel extends ElementViewModel<Annotation> {
  get author(): string | undefined {
    return this.element?.authorString;
  }

  get time(): string | undefined {
    return formatLocaleDate(this.element?.time, 'short');
  }

  get text(): string | undefined {
    return this.element?.text;
  }

  get authorReference(): string | undefined {
    return getReference(this.element?.authorReference);
  }
}
