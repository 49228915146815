import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemParticipationType: CodeSystemFlattened = {
  id: 'v3-ParticipationType',
  codes: {
    PART: {
      display: 'Participation',
    },
    _ParticipationAncillary: {
      display: 'ParticipationAncillary',
    },
    _ParticipationInformationGenerator: {
      display: 'ParticipationInformationGenerator',
    },
    CST: {
      display: 'custodian',
    },
    DIR: {
      display: 'direct target',
    },
    IND: {
      display: 'indirect target',
    },
    IRCP: {
      display: 'information recipient',
    },
    LOC: {
      display: 'location',
    },
    PRF: {
      display: 'performer',
    },
    RESP: {
      display: 'responsible party',
    },
    VRF: {
      display: 'verifier',
    },
    ADM: {
      display: 'admitter',
    },
    ATND: {
      display: 'attender',
    },
    CALLBCK: {
      display: 'callback contact',
    },
    CON: {
      display: 'consultant',
    },
    DIS: {
      display: 'discharger',
    },
    ESC: {
      display: 'escort',
    },
    REF: {
      display: 'referrer',
    },
    AUT: {
      display: 'author (originator)',
    },
    INF: {
      display: 'informant',
    },
    TRANS: {
      display: 'Transcriber',
    },
    WIT: {
      display: 'witness',
    },
    ENT: {
      display: 'data entry person',
    },
    ALY: {
      display: 'analyte',
    },
    BBY: {
      display: 'baby',
    },
    CAT: {
      display: 'catalyst',
    },
    CSM: {
      display: 'consumable',
    },
    DEV: {
      display: 'device',
    },
    DON: {
      display: 'donor',
    },
    EXPAGNT: {
      display: 'ExposureAgent',
    },
    EXPART: {
      display: 'ExposureParticipation',
    },
    PRD: {
      display: 'product',
    },
    SBJ: {
      display: 'subject',
    },
    TPA: {
      display: 'therapeutic agent',
    },
    NRD: {
      display: 'non-reuseable device',
    },
    RDV: {
      display: 'reusable device',
    },
    EXPTRGT: {
      display: 'ExposureTarget',
    },
    EXSRC: {
      display: 'ExposureSource',
    },
    SPC: {
      display: 'specimen',
    },
    BEN: {
      display: 'beneficiary',
    },
    CAGNT: {
      display: 'causative agent',
    },
    COV: {
      display: 'coverage target',
    },
    GUAR: {
      display: 'guarantor party',
    },
    HLD: {
      display: 'holder',
    },
    RCT: {
      display: 'record target',
    },
    RCV: {
      display: 'receiver',
    },
    NOT: {
      display: 'ugent notification contact',
    },
    PRCP: {
      display: 'primary information recipient',
    },
    REFB: {
      display: 'Referred By',
    },
    REFT: {
      display: 'Referred to',
    },
    TRC: {
      display: 'tracker',
    },
    DST: {
      display: 'destination',
    },
    ELOC: {
      display: 'entry location',
    },
    ORG: {
      display: 'origin',
    },
    RML: {
      display: 'remote',
    },
    VIA: {
      display: 'via',
    },
    DIST: {
      display: 'distributor',
    },
    PPRF: {
      display: 'primary performer',
    },
    SPRF: {
      display: 'secondary performer',
    },
    AUTHEN: {
      display: 'authenticator',
    },
    LA: {
      display: 'legal authenticator',
    },
  },
};
