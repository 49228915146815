import { Extension } from '@hl7fhir';

interface ExtensionHolder {
  extension?: Extension[] | undefined;
}

export function hasExtension(extension: Extension[] | Extension, url: string): boolean {
  if (Array.isArray(extension)) {
    return extension.some((ext: Extension) => ext.url === url);
  }

  return extension.url === url;
}

export function getSubExtensions(
  extensionHolders: ExtensionHolder[] | undefined,
  urlFirstLevel: string[],
  urlSecondLevel: string[]
): Extension[] | undefined {
  if (!extensionHolders) return undefined;

  const subExtensions: Extension[] = [];

  for (const extensionHolder of extensionHolders) {
    if (!extensionHolder.extension) continue;

    for (const extension of extensionHolder.extension) {
      if (!urlFirstLevel.includes(extension.url) || !extension.extension) continue;

      for (const subExtension of extension.extension) {
        if (!urlSecondLevel.includes(subExtension.url)) continue;

        subExtensions.push(subExtension);
      }
    }
  }

  return subExtensions.length > 0 ? subExtensions : undefined;
}
