import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { StorageKeys } from '@globals';
import { ButtonComponent, MedMijTrustedComponent } from '@layout';

@Component({
  standalone: true,
  selector: 'app-account-create',
  imports: [CommonModule, MatIconModule, MedMijTrustedComponent, ButtonComponent],
  templateUrl: './account-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCreateComponent {
  @Output() create = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Input() library: string | undefined | null;

  constructor() {
    localStorage.setItem(StorageKeys.SHOULD_CREATE_ACCOUNT, 'true');
  }
}
