import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContactPoint } from '@hl7fhir';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { ValueSetFlattened } from '@hl7fhir/value-sets';
import { TelecomTypeCodelijst } from '@hl7nl-fhir/value-sets';

@Component({
  selector: 'app-contact-point',
  templateUrl: './contact-point.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['span:empty::before { content: "\\200b"; /* unicode zero width space character */ }'],
})
export class ContactPointComponent {
  @Input() contactPoint: ContactPoint | undefined;

  readonly structureDefinition: typeof StructureDefinition = StructureDefinition;
  readonly telecomTypeCodelijst: ValueSetFlattened = TelecomTypeCodelijst;
}
