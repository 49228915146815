import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const ConsentActionCodes: CodeSystemFlattened = {
  id: 'consent-action',
  codes: {
    collect: {
      display: 'Collect',
    },
    access: {
      display: 'Access',
    },
    use: {
      display: 'Use',
    },
    disclose: {
      display: 'Disclose',
    },
    correct: {
      display: 'Access and Correct',
    },
  },
};
