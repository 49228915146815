import { Flag } from '@hl7fhir';
import { FlagViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromFlag from './flags.reducer';

export const selectFlagState = createFeatureSelector<fromFlag.State>(features.FHIR.flags);

export const selectFlagEntities = createSelector(selectFlagState, fromFlag.selectFlagEntities);

export const selectAllFlags = createSelector(selectFlagState, fromFlag.selectAllFlags);

export const selectFlagById = createSelector(
  selectRouteParams,
  selectFlagEntities,
  ({ id }, flags: Dictionary<Flag>) => id && (flags[id] ?? flags[id.toLowerCase()] ?? flags[id.toUpperCase()])
);

export const selectAllAlerts = createSelector(selectAllFlags, (flags: Flag[]) =>
  flags
    .filter((flag) => flag.status !== 'entered-in-error')
    .sort((a, b) => {
      if (a.status === b.status) return 0;
      return a.status > b.status ? 1 : -1;
    })
);

export const selectAllFlagViewModels = createSelector(selectAllFlags, (dataSource: Flag[]) =>
  dataSource.map((item) => new FlagViewModel(item))
);

export const selectFlagViewModelById = createSelector(
  selectFlagById,
  (entity: Flag | undefined) => entity && new FlagViewModel(entity)
);
