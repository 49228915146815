import { Pipe, PipeTransform } from '@angular/core';
import { Extension } from '@hl7fhir';

@Pipe({
  name: 'extensions',
})
export class ExtensionsPipe implements PipeTransform {
  transform(extensions: Extension[] | undefined, urls: string[] | string | undefined): Extension[] | undefined {
    if (!extensions || !urls) return undefined;

    const result = Array.isArray(urls)
      ? extensions.filter((extension: Extension) => urls.find((url: string) => extension.url === url))
      : extensions.filter((extension: Extension) => extension.url === urls);

    return result.length >= 1 ? result : undefined;
  }
}
