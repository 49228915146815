import { Pipe, PipeTransform } from '@angular/core';
import { CodeableConcept, Coding } from '@hl7fhir';
@Pipe({
  name: 'codeSystem',
})
export class CodeSystemPipe implements PipeTransform {
  transform(type: CodeableConcept[] | CodeableConcept | undefined, system: string): Coding | undefined {
    if (!type || !system) {
      return undefined;
    }

    if (type instanceof Array) {
      return type
        .map((codeableConcept: CodeableConcept) => codeableConcept.coding)
        .flat()
        .find((coding: Coding | undefined) => coding?.system === system);
    } else {
      const codings: Coding[] | undefined = type.coding;
      return codings?.find((coding: Coding | undefined) => coding?.system === system);
    }
  }
}
