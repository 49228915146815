import { NutritionOrder } from '@hl7fhir';
import { NutritionOrderViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromNutritionOrder from './nutrition-order-reducer';

export const selectNutritionOrderState = createFeatureSelector<fromNutritionOrder.State>(features.FHIR.nutritionOrders);

export const selectNutritionOrderEntities = createSelector(
  selectNutritionOrderState,
  fromNutritionOrder.selectNutritionOrderEntities
);

export const selectAllNutritionOrders = createSelector(
  selectNutritionOrderState,
  fromNutritionOrder.selectAllNutritionOrders
);

export const selectNutritionOrderById = createSelector(
  selectRouteParams,
  selectNutritionOrderEntities,
  ({ id }, nutritionOrders: Dictionary<NutritionOrder>) =>
    id && (nutritionOrders[id] ?? nutritionOrders[id.toLowerCase()] ?? nutritionOrders[id.toUpperCase()])
);

export const selectAllNutritionOrderViewModels = createSelector(
  selectAllNutritionOrders,
  (dataSource: NutritionOrder[]) => dataSource.map((item) => new NutritionOrderViewModel(item))
);

export const selectNutritionOrderViewModelById = createSelector(
  selectNutritionOrderById,
  (entity: NutritionOrder | undefined) => entity && new NutritionOrderViewModel(entity)
);
