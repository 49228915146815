import { NutritionOrderSupplement } from '@hl7fhir';
import { CodeableConceptPipe, TimingViewModel, QuantityTypePipe } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class NutritionOrderSupplementViewModel extends ElementViewModel<NutritionOrderSupplement> {
  get type(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.type);
  }

  get productName(): string | undefined {
    return this.element?.productName;
  }

  get instruction(): string | undefined {
    return this.element?.instruction;
  }

  get quantity(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.quantity);
  }

  get schedule(): TimingViewModel[] | undefined {
    const nutritionOrderSupplementR = this.element as
      | r3.NutritionOrderSupplement
      | r4.NutritionOrderSupplement
      | r4b.NutritionOrderSupplement;
    return nutritionOrderSupplementR?.schedule?.map((timing) => new TimingViewModel(timing, this.fhirVersion));
  }
}
