import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../../features';
import { OrganizationEffects } from './organization.effects';
import { reducer } from './organization.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(features.FHIR.organizations, reducer),
    EffectsModule.forFeature([OrganizationEffects]),
  ],
})
export class OrganizationStoreModule {}
