import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameUse',
})
export class NameUsePipe implements PipeTransform {
  transform(
    value: ('usual' | 'official' | 'temp' | 'nickname' | 'anonymous' | 'old' | 'maiden') | undefined
  ): string | undefined {
    if (!value) {
      return undefined;
    }

    switch (value) {
      case 'anonymous':
        return $localize`:2.16.840.1.113883.4.642.3.65@@fhir.name-use.anonymous:Anonymous`;
      case 'maiden':
        return $localize`:2.16.840.1.113883.4.642.3.65@@fhir.name-use.maiden:Name changed for Marriage`;
      case 'nickname':
        return $localize`:2.16.840.1.113883.4.642.3.65@@fhir.name-use.nickname:Nickname`;
      case 'official':
        return $localize`:2.16.840.1.113883.4.642.3.65@@fhir.name-use.official:Official`;
      case 'old':
        return $localize`:2.16.840.1.113883.4.642.3.65@@fhir.name-use.old:Old`;
      case 'temp':
        return $localize`:2.16.840.1.113883.4.642.3.65@@fhir.name-use.temp:Temporary`;
      case 'usual':
        return $localize`:2.16.840.1.113883.4.642.3.65@@fhir.name-use.usual:Usual`;
      default:
        return '';
    }
  }
}
