import { createSelector } from '@ngrx/store';
import { LoadingState } from '@store';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectIsClaimLoading = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.claim.callState === LoadingState.loading
);

export const selectLibrary = createSelector(selectDigiMeState, (state: DigiMeState) => state.claim.toClaim);

export const selectMustCreateAccount = createSelector(
  selectLibrary,
  (library: string | undefined) => library !== undefined
);
