import { Immunization } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectImmunizationId(immunization: Immunization): string {
  if (!immunization.id) throw new Error('The observation is not created yet.');
  return immunization.id;
}

export type State = EntityState<Immunization>;

export const adapter: EntityAdapter<Immunization> = createEntityAdapter<Immunization>({
  selectId: selectImmunizationId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { immunizations }) => {
    return adapter.upsertMany(immunizations, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectImmunizationEntities = selectEntities;

export const selectAllImmunizations = selectAll;
