import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemActCode: CodeSystemFlattened = {
  id: 'v3-ActCode',
  codes: {
    _ActAccountCode: {
      display: 'ActAccountCode',
    },
    _ActAdjudicationCode: {
      display: 'ActAdjudicationCode',
    },
    _ActAdjudicationResultActionCode: {
      display: 'ActAdjudicationResultActionCode',
    },
    _ActBillableModifierCode: {
      display: 'ActBillableModifierCode',
    },
    _ActBillingArrangementCode: {
      display: 'ActBillingArrangementCode',
    },
    _ActBoundedROICode: {
      display: 'ActBoundedROICode',
    },
    _ActCareProvisionCode: {
      display: 'act care provision',
    },
    _ActClaimAttachmentCategoryCode: {
      display: 'ActClaimAttachmentCategoryCode',
    },
    _ActConsentType: {
      display: 'ActConsentType',
    },
    _ActContainerRegistrationCode: {
      display: 'ActContainerRegistrationCode',
    },
    _ActControlVariable: {
      display: 'ActControlVariable',
    },
    _ActCoverageConfirmationCode: {
      display: 'ActCoverageConfirmationCode',
    },
    _ActCoverageLimitCode: {
      display: 'ActCoverageLimitCode',
    },
    _ActCoverageTypeCode: {
      display: 'ActCoverageTypeCode',
    },
    _ActDetectedIssueManagementCode: {
      display: 'ActDetectedIssueManagementCode',
    },
    _ActExposureCode: {
      display: 'ActExposureCode',
    },
    _ActFinancialTransactionCode: {
      display: 'ActFinancialTransactionCode',
    },
    _ActIncidentCode: {
      display: 'ActIncidentCode',
    },
    _ActInformationAccessCode: {
      display: 'ActInformationAccessCode',
    },
    _ActInformationAccessContextCode: {
      display: 'ActInformationAccessContextCode',
    },
    _ActInformationCategoryCode: {
      display: 'ActInformationCategoryCode',
    },
    _ActInvoiceElementCode: {
      display: 'ActInvoiceElementCode',
    },
    _ActInvoiceElementSummaryCode: {
      display: 'ActInvoiceElementSummaryCode',
    },
    _ActInvoiceOverrideCode: {
      display: 'ActInvoiceOverrideCode',
    },
    _ActListCode: {
      display: 'ActListCode',
    },
    _ActMonitoringProtocolCode: {
      display: 'ActMonitoringProtocolCode',
    },
    _ActNonObservationIndicationCode: {
      display: 'ActNonObservationIndicationCode',
    },
    _ActObservationVerificationType: {
      display: 'act observation verification',
    },
    _ActPaymentCode: {
      display: 'ActPaymentCode',
    },
    _ActPharmacySupplyType: {
      display: 'ActPharmacySupplyType',
    },
    _ActPolicyType: {
      display: 'ActPolicyType',
    },
    _ActProductAcquisitionCode: {
      display: 'ActProductAcquisitionCode',
    },
    _ActSpecimenTransportCode: {
      display: 'ActSpecimenTransportCode',
    },
    _ActSpecimenTreatmentCode: {
      display: 'ActSpecimenTreatmentCode',
    },
    _ActSubstanceAdministrationCode: {
      display: 'ActSubstanceAdministrationCode',
    },
    _ActTaskCode: {
      display: 'ActTaskCode',
    },
    _ActTransportationModeCode: {
      display: 'ActTransportationModeCode',
    },
    _ObservationType: {
      display: 'ObservationType',
    },
    _ROIOverlayShape: {
      display: 'ROIOverlayShape',
    },
    C: {
      display: 'corrected',
    },
    DIET: {
      display: 'Diet',
    },
    DRUGPRG: {
      display: 'drug program',
    },
    F: {
      display: 'final',
    },
    PRLMN: {
      display: 'preliminary',
    },
    SECOBS: {
      display: 'SecurityObservationType',
    },
    SUBSIDFFS: {
      display: 'subsidized fee for service program',
    },
    WRKCOMP: {
      display: '(workers compensation program',
    },
    _ActProcedureCode: {
      display: 'ActProcedureCode',
    },
    _HL7DefinedActCodes: {
      display: 'HL7DefinedActCodes',
    },
    COPAY: {},
    DEDUCT: {},
    DOSEIND: {},
    PRA: {},
    STORE: {
      display: 'Storage',
    },
    ACCTRECEIVABLE: {
      display: 'account receivable',
    },
    CASH: {
      display: 'Cash',
    },
    CC: {
      display: 'credit card',
    },
    PBILLACCT: {
      display: 'patient billing account',
    },
    AE: {
      display: 'American Express',
    },
    DN: {
      display: "Diner's Club",
    },
    DV: {
      display: 'Discover Card',
    },
    MC: {
      display: 'Master Card',
    },
    V: {
      display: 'Visa',
    },
    _ActAdjudicationGroupCode: {
      display: 'ActAdjudicationGroupCode',
    },
    AA: {
      display: 'adjudicated with adjustments',
    },
    AR: {
      display: 'adjudicated as refused',
    },
    AS: {
      display: 'adjudicated as submitted',
    },
    CONT: {
      display: 'contract',
    },
    DAY: {
      display: 'day',
    },
    LOC: {
      display: 'location',
    },
    MONTH: {
      display: 'month',
    },
    PERIOD: {
      display: 'period',
    },
    PROV: {
      display: 'provider',
    },
    WEEK: {
      display: 'week',
    },
    YEAR: {
      display: 'year',
    },
    ANF: {
      display: 'adjudicated with adjustments and no financial impact',
    },
    DISPLAY: {
      display: 'Display',
    },
    FORM: {
      display: 'Print on Form',
    },
    CPTM: {
      display: 'CPT modifier codes',
    },
    HCPCSA: {
      display: 'HCPCS Level II and Carrier-assigned',
    },
    BLK: {
      display: 'block funding',
    },
    CAP: {
      display: 'capitation funding',
    },
    CONTF: {
      display: 'contract funding',
    },
    FINBILL: {
      display: 'financial',
    },
    ROST: {
      display: 'roster funding',
    },
    SESS: {
      display: 'sessional funding',
    },
    FFS: {
      display: 'fee for service',
    },
    FFPS: {
      display: 'first fill, part fill, partial strength',
    },
    FFCS: {
      display: 'first fill complete, partial strength',
    },
    TFS: {
      display: 'trial fill partial strength',
    },
    ROIFS: {
      display: 'fully specified ROI',
    },
    ROIPS: {
      display: 'partially specified ROI',
    },
    _ActCredentialedCareCode: {
      display: 'act credentialed care',
    },
    _ActEncounterCode: {
      display: 'ActEncounterCode',
    },
    _ActMedicalServiceCode: {
      display: 'ActMedicalServiceCode',
    },
    _ActCredentialedCareProvisionPersonCode: {
      display: 'act credentialed care provision peron',
    },
    _ActCredentialedCareProvisionProgramCode: {
      display: 'act credentialed care provision program',
    },
    CACC: {
      display: 'certified anatomic pathology and clinical pathology care',
    },
    CAIC: {
      display: 'certified allergy and immunology care',
    },
    CAMC: {
      display: 'certified aerospace medicine care',
    },
    CANC: {
      display: 'certified anesthesiology care',
    },
    CAPC: {
      display: 'certified anatomic pathology care',
    },
    CBGC: {
      display: 'certified clinical biochemical genetics care',
    },
    CCCC: {
      display: 'certified clinical cytogenetics care',
    },
    CCGC: {
      display: 'certified clinical genetics (M.D.) care',
    },
    CCPC: {
      display: 'certified clinical pathology care',
    },
    CCSC: {
      display: 'certified colon and rectal surgery care',
    },
    CDEC: {
      display: 'certified dermatology care',
    },
    CDRC: {
      display: 'certified diagnostic radiology care',
    },
    CEMC: {
      display: 'certified emergency medicine care',
    },
    CFPC: {
      display: 'certified family practice care',
    },
    CIMC: {
      display: 'certified internal medicine care',
    },
    CMGC: {
      display: 'certified clinical molecular genetics care',
    },
    CNEC: {
      display: 'certified neurology care',
    },
    CNMC: {
      display: 'certified nuclear medicine care',
    },
    CNQC: {
      display: 'certified neurology with special qualifications in child neurology care',
    },
    CNSC: {
      display: 'certified neurological surgery care',
    },
    COGC: {
      display: 'certified obstetrics and gynecology care',
    },
    COMC: {
      display: 'certified occupational medicine care',
    },
    COPC: {
      display: 'certified ophthalmology care',
    },
    COSC: {
      display: 'certified orthopaedic surgery care',
    },
    COTC: {
      display: 'certified otolaryngology care',
    },
    CPEC: {
      display: 'certified pediatrics care',
    },
    CPGC: {
      display: 'certified Ph.D. medical genetics care',
    },
    CPHC: {
      display: 'certified public health and general preventive medicine care',
    },
    CPRC: {
      display: 'certified physical medicine and rehabilitation care',
    },
    CPSC: {
      display: 'certified plastic surgery care',
    },
    CPYC: {
      display: 'certified psychiatry care',
    },
    CROC: {
      display: 'certified radiation oncology care',
    },
    CRPC: {
      display: 'certified radiological physics care',
    },
    CSUC: {
      display: 'certified surgery care',
    },
    CTSC: {
      display: 'certified thoracic surgery care',
    },
    CURC: {
      display: 'certified urology care',
    },
    CVSC: {
      display: 'certified vascular surgery care',
    },
    LGPC: {
      display: 'licensed general physician care',
    },
    AALC: {
      display: 'accredited assisted living care',
    },
    AAMC: {
      display: 'accredited ambulatory care',
    },
    ABHC: {
      display: 'accredited behavioral health care',
    },
    ACAC: {
      display: 'accredited critical access hospital care',
    },
    ACHC: {
      display: 'accredited hospital care',
    },
    AHOC: {
      display: 'accredited home care',
    },
    ALTC: {
      display: 'accredited long term care',
    },
    AOSC: {
      display: 'accredited office-based surgery care',
    },
    CACS: {
      display: 'certified acute coronary syndrome care',
    },
    CAMI: {
      display: 'certified acute myocardial infarction care',
    },
    CAST: {
      display: 'certified asthma care',
    },
    CBAR: {
      display: 'certified bariatric surgery care',
    },
    CCAD: {
      display: 'certified coronary artery disease care',
    },
    CCAR: {
      display: 'certified cardiac care',
    },
    CDEP: {
      display: 'certified depression care',
    },
    CDGD: {
      display: 'certified digestive/gastrointestinal disorders care',
    },
    CDIA: {
      display: 'certified diabetes care',
    },
    CEPI: {
      display: 'certified epilepsy care',
    },
    CFEL: {
      display: 'certified frail elderly care',
    },
    CHFC: {
      display: 'certified heart failure care',
    },
    CHRO: {
      display: 'certified high risk obstetrics care',
    },
    CHYP: {
      display: 'certified hyperlipidemia care',
    },
    CMIH: {
      display: 'certified migraine headache care',
    },
    CMSC: {
      display: 'certified multiple sclerosis care',
    },
    COJR: {
      display: 'certified orthopedic joint replacement care',
    },
    CONC: {
      display: 'certified oncology care',
    },
    COPD: {
      display: 'certified chronic obstructive pulmonary disease care',
    },
    CORT: {
      display: 'certified organ transplant care',
    },
    CPAD: {
      display: 'certified parkinsons disease care',
    },
    CPND: {
      display: 'certified pneumonia disease care',
    },
    CPST: {
      display: 'certified primary stroke center care',
    },
    CSDM: {
      display: 'certified stroke disease management care',
    },
    CSIC: {
      display: 'certified sickle cell care',
    },
    CSLD: {
      display: 'certified sleep disorders care',
    },
    CSPT: {
      display: 'certified spine treatment care',
    },
    CTBU: {
      display: 'certified trauma/burn center care',
    },
    CVDC: {
      display: 'certified vascular diseases care',
    },
    CWMA: {
      display: 'certified wound management care',
    },
    CWOH: {
      display: "certified women's health care",
    },
    AMB: {
      display: 'ambulatory',
    },
    EMER: {
      display: 'emergency',
    },
    FLD: {
      display: 'field',
    },
    HH: {
      display: 'home health',
    },
    IMP: {
      display: 'inpatient encounter',
    },
    PRENC: {
      display: 'pre-admission',
    },
    SS: {
      display: 'short stay',
    },
    VR: {
      display: 'virtual',
    },
    ACUTE: {
      display: 'inpatient acute',
    },
    NONAC: {
      display: 'inpatient non-acute',
    },
    ALC: {
      display: 'Alternative Level of Care',
    },
    CARD: {
      display: 'Cardiology',
    },
    CHR: {
      display: 'Chronic',
    },
    DNTL: {
      display: 'Dental',
    },
    DRGRHB: {
      display: 'Drug Rehab',
    },
    GENRL: {
      display: 'General',
    },
    MED: {
      display: 'Medical',
    },
    OBS: {
      display: 'Obstetrics',
    },
    ONC: {
      display: 'Oncology',
    },
    PALL: {
      display: 'Palliative',
    },
    PED: {
      display: 'Pediatrics',
    },
    PHAR: {
      display: 'Pharmaceutical',
    },
    PHYRHB: {
      display: 'Physical Rehab',
    },
    PSYCH: {
      display: 'Psychiatric',
    },
    SURG: {
      display: 'Surgical',
    },
    AUTOATTCH: {
      display: 'auto attachment',
    },
    DOCUMENT: {
      display: 'document',
    },
    HEALTHREC: {
      display: 'health record',
    },
    IMG: {
      display: 'image attachment',
    },
    LABRESULTS: {
      display: 'lab results',
    },
    MODEL: {
      display: 'model',
    },
    WIATTCH: {
      display: 'work injury report attachment',
    },
    XRAY: {
      display: 'x-ray',
    },
    ICOL: {
      display: 'information collection',
    },
    IDSCL: {
      display: 'information disclosure',
    },
    INFA: {
      display: 'information access',
    },
    IRDSCL: {
      display: 'information redisclosure',
    },
    RESEARCH: {
      display: 'research information access',
    },
    INFAO: {
      display: 'access only',
    },
    INFASO: {
      display: 'access and save only',
    },
    RSDID: {
      display: 'de-identified information access',
    },
    RSREID: {
      display: 're-identifiable information access',
    },
    ID: {
      display: 'Identified',
    },
    IP: {
      display: 'In Position',
    },
    L: {
      display: 'Left Equipment',
    },
    M: {
      display: 'Missing',
    },
    O: {
      display: 'In Process',
    },
    R: {
      display: 'Process Completed',
    },
    X: {
      display: 'Container Unavailable',
    },
    AUTO: {
      display: 'auto-repeat permission',
    },
    ENDC: {
      display: 'endogenous content',
    },
    REFLEX: {
      display: 'reflex permission',
    },
    _ActCoverageAuthorizationConfirmationCode: {
      display: 'ActCoverageAuthorizationConfirmationCode',
    },
    _ActCoverageEligibilityConfirmationCode: {
      display: 'ActCoverageEligibilityConfirmationCode',
    },
    AUTH: {
      display: 'Authorized',
    },
    NAUTH: {
      display: 'Not Authorized',
    },
    ELG: {
      display: 'Eligible',
    },
    NELG: {
      display: 'Not Eligible',
    },
    _ActCoverageQuantityLimitCode: {
      display: 'ActCoverageQuantityLimitCode',
    },
    COVMX: {
      display: 'coverage maximum',
    },
    _ActCoveredPartyLimitCode: {
      display: 'ActCoveredPartyLimitCode',
    },
    COVPRD: {
      display: 'coverage period',
    },
    LFEMX: {
      display: 'life time maximum',
    },
    NETAMT: {
      display: 'Net Amount',
    },
    PRDMX: {
      display: 'period maximum',
    },
    UNITPRICE: {
      display: 'Unit Price',
    },
    UNITQTY: {
      display: 'Unit Quantity',
    },
    _ActInsurancePolicyCode: {
      display: 'ActInsurancePolicyCode',
    },
    _ActInsuranceTypeCode: {
      display: 'ActInsuranceTypeCode',
    },
    _ActProgramTypeCode: {
      display: 'ActProgramTypeCode',
    },
    EHCPOL: {
      display: 'extended healthcare',
    },
    HSAPOL: {
      display: 'health spending account',
    },
    AUTOPOL: {
      display: 'automobile',
    },
    PUBLICPOL: {
      display: 'public healthcare',
    },
    WCBPOL: {
      display: "worker's compensation",
    },
    COL: {
      display: 'collision coverage policy',
    },
    UNINSMOT: {
      display: 'uninsured motorist policy',
    },
    DENTPRG: {
      display: 'dental program',
    },
    DISEASEPRG: {
      display: 'public health program',
    },
    MANDPOL: {
      display: 'mandatory health program',
    },
    MENTPRG: {
      display: 'mental health program',
    },
    SAFNET: {
      display: 'safety net clinic program',
    },
    SUBPRG: {
      display: 'substance use program',
    },
    SUBSIDIZ: {
      display: 'subsidized health program',
    },
    CANPRG: {
      display: "women's cancer detection program",
    },
    ENDRENAL: {
      display: 'end renal program',
    },
    HIVAIDS: {
      display: 'HIV-AIDS program',
    },
    SUBSIDMC: {
      display: 'subsidized managed care program',
    },
    SUBSUPP: {
      display: 'subsidized supplemental health program',
    },
    _ActHealthInsuranceTypeCode: {
      display: 'ActHealthInsuranceTypeCode',
    },
    DIS: {
      display: 'disability insurance policy',
    },
    EWB: {
      display: 'employee welfare benefit plan policy',
    },
    FLEXP: {
      display: 'flexible benefit plan policy',
    },
    LIFE: {
      display: 'life insurance policy',
    },
    PNC: {
      display: 'property and casualty insurance policy',
    },
    REI: {
      display: 'reinsurance policy',
    },
    SURPL: {
      display: 'surplus line insurance policy',
    },
    UMBRL: {
      display: 'umbrella liability insurance policy',
    },
    DENTAL: {
      display: 'dental care policy',
    },
    DISEASE: {
      display: 'disease specific policy',
    },
    DRUGPOL: {
      display: 'drug policy',
    },
    HIP: {
      display: 'health insurance plan policy',
    },
    LTC: {
      display: 'long term care policy',
    },
    MCPOL: {
      display: 'managed care policy',
    },
    MENTPOL: {
      display: 'mental health policy',
    },
    SUBPOL: {
      display: 'substance use policy',
    },
    VISPOL: {
      display: 'vision care policy',
    },
    POS: {
      display: 'point of service policy',
    },
    HMO: {
      display: 'health maintenance organization policy',
    },
    PPO: {
      display: 'preferred provider organization policy',
    },
    ANNU: {
      display: 'annuity policy',
    },
    TLIFE: {
      display: 'term life insurance policy',
    },
    ULIFE: {
      display: 'universal life insurance policy',
    },
    CHAR: {
      display: 'charity program',
    },
    CRIME: {
      display: 'crime victim program',
    },
    EAP: {
      display: 'employee assistance program',
    },
    GOVEMP: {
      display: 'government employee health program',
    },
    HIRISK: {
      display: 'high risk pool program',
    },
    IND: {
      display: 'indigenous peoples health program',
    },
    MILITARY: {
      display: 'military health program',
    },
    RETIRE: {
      display: 'retiree health program',
    },
    SOCIAL: {
      display: 'social service program',
    },
    VET: {
      display: 'veteran health program',
    },
    _ActAdministrativeDetectedIssueManagementCode: {
      display: 'ActAdministrativeDetectedIssueManagementCode',
    },
    '1': {
      display: 'Therapy Appropriate',
    },
    '14': {
      display: 'Supply Appropriate',
    },
    '8': {
      display: 'Other Action Taken',
    },
    _AuthorizationIssueManagementCode: {
      display: 'Authorization Issue Management Code',
    },
    EMAUTH: {
      display: 'emergency authorization override',
    },
    '21': {
      display: 'authorization confirmed',
    },
    '19': {
      display: 'Consulted Supplier',
    },
    '2': {
      display: 'Assessed Patient',
    },
    '22': {
      display: 'appropriate indication or diagnosis',
    },
    '23': {
      display: 'prior therapy documented',
    },
    '3': {
      display: 'Patient Explanation',
    },
    '4': {
      display: 'Consulted Other Source',
    },
    '5': {
      display: 'Consulted Prescriber',
    },
    '7': {
      display: 'Interacting Therapy No Longer Active/Planned',
    },
    '6': {
      display: 'Prescriber Declined Change',
    },
    '15': {
      display: 'Replacement',
    },
    '16': {
      display: 'Vacation Supply',
    },
    '17': {
      display: 'Weekend Supply',
    },
    '18': {
      display: 'Leave of Absence',
    },
    '20': {
      display: 'additional quantity on separate dispense',
    },
    '10': {
      display: 'Provided Patient Education',
    },
    '11': {
      display: 'Added Concurrent Therapy',
    },
    '12': {
      display: 'Temporarily Suspended Concurrent Therapy',
    },
    '13': {
      display: 'Stopped Concurrent Therapy',
    },
    '9': {
      display: 'Instituted Ongoing Monitoring Program',
    },
    CHLDCARE: {
      display: 'Day care - Child care Interaction',
    },
    CONVEYNC: {
      display: 'Common Conveyance Interaction',
    },
    HLTHCARE: {
      display: 'Health Care Interaction - Not Patient Care',
    },
    HOMECARE: {
      display: 'Care Giver Interaction',
    },
    HOSPPTNT: {
      display: 'Hospital Patient Interaction',
    },
    HOSPVSTR: {
      display: 'Hospital Visitor Interaction',
    },
    HOUSEHLD: {
      display: 'Household Interaction',
    },
    INMATE: {
      display: 'Inmate Interaction',
    },
    INTIMATE: {
      display: 'Intimate Interaction',
    },
    LTRMCARE: {
      display: 'Long Term Care Facility Interaction',
    },
    PLACE: {
      display: 'Common Space Interaction',
    },
    PTNTCARE: {
      display: 'Health Care Interaction - Patient Care',
    },
    SCHOOL2: {
      display: 'School Interaction',
    },
    SOCIAL2: {
      display: 'Social/Extended Family Interaction',
    },
    SUBSTNCE: {
      display: 'Common Substance Interaction',
    },
    TRAVINT: {
      display: 'Common Travel Interaction',
    },
    WORK2: {
      display: 'Work Interaction',
    },
    CHRG: {
      display: 'Standard Charge',
    },
    REV: {
      display: 'Standard Charge Reversal',
    },
    MVA: {
      display: 'Motor vehicle accident',
    },
    SCHOOL: {
      display: 'School Accident',
    },
    SPT: {
      display: 'Sporting Accident',
    },
    WPA: {
      display: 'Workplace accident',
    },
    ACADR: {
      display: 'adverse drug reaction access',
    },
    ACALL: {
      display: 'all access',
    },
    ACALLG: {
      display: 'allergy access',
    },
    ACCONS: {
      display: 'informational consent access',
    },
    ACDEMO: {
      display: 'demographics access',
    },
    ACDI: {
      display: 'diagnostic imaging access',
    },
    ACIMMUN: {
      display: 'immunization access',
    },
    ACLAB: {
      display: 'lab test result access',
    },
    ACMED: {
      display: 'medication access',
    },
    ACMEDC: {
      display: 'medical condition access',
    },
    ACMEN: {
      display: 'mental health access',
    },
    ACOBS: {
      display: 'common observations access',
    },
    ACPOLPRG: {
      display: 'policy or program information access',
    },
    ACPROV: {
      display: 'provider information access',
    },
    ACPSERV: {
      display: 'professional service access',
    },
    ACSUBSTAB: {
      display: 'substance abuse access',
    },
    INFAUT: {
      display: 'authorized information transfer',
    },
    INFCRT: {
      display: 'only on court order',
    },
    INFDNG: {
      display: 'only if danger to others',
    },
    INFEMER: {
      display: 'only in an emergency',
    },
    INFPWR: {
      display: 'only if public welfare risk',
    },
    INFREG: {
      display: 'regulatory information transfer',
    },
    INFCON: {
      display: 'after explicit consent',
    },
    ALLCAT: {
      display: 'all categories',
    },
    ALLGCAT: {
      display: 'allergy category',
    },
    ARCAT: {
      display: 'adverse drug reaction category',
    },
    COBSCAT: {
      display: 'common observation category',
    },
    DEMOCAT: {
      display: 'demographics category',
    },
    DICAT: {
      display: 'diagnostic image category',
    },
    IMMUCAT: {
      display: 'immunization category',
    },
    LABCAT: {
      display: 'lab test category',
    },
    MEDCCAT: {
      display: 'medical condition category',
    },
    MENCAT: {
      display: 'mental health category',
    },
    PSVCCAT: {
      display: 'professional service category',
    },
    RXCAT: {
      display: 'medication category',
    },
    _ActInvoiceAdjudicationPaymentCode: {
      display: 'ActInvoiceAdjudicationPaymentCode',
    },
    _ActInvoiceDetailCode: {
      display: 'ActInvoiceDetailCode',
    },
    _ActInvoiceGroupCode: {
      display: 'ActInvoiceGroupCode',
    },
    _ActInvoiceAdjudicationPaymentGroupCode: {
      display: 'ActInvoiceAdjudicationPaymentGroupCode',
    },
    _ActInvoiceAdjudicationPaymentSummaryCode: {
      display: 'ActInvoiceAdjudicationPaymentSummaryCode',
    },
    ALEC: {
      display: 'alternate electronic',
    },
    BONUS: {
      display: 'bonus',
    },
    CFWD: {
      display: 'carry forward adjusment',
    },
    EDU: {
      display: 'education fees',
    },
    EPYMT: {
      display: 'early payment fee',
    },
    GARN: {
      display: 'garnishee',
    },
    INVOICE: {
      display: 'submitted invoice',
    },
    PINV: {
      display: 'paper invoice',
    },
    PPRD: {
      display: 'prior period adjustment',
    },
    PROA: {
      display: 'professional association deduction',
    },
    RECOV: {
      display: 'recovery',
    },
    RETRO: {
      display: 'retro adjustment',
    },
    TRAN: {
      display: 'transaction fee',
    },
    INVTYPE: {
      display: 'invoice type',
    },
    PAYEE: {
      display: 'payee',
    },
    PAYOR: {
      display: 'payor',
    },
    SENDAPP: {
      display: 'sending application',
    },
    _ActInvoiceDetailClinicalProductCode: {
      display: 'ActInvoiceDetailClinicalProductCode',
    },
    _ActInvoiceDetailDrugProductCode: {
      display: 'ActInvoiceDetailDrugProductCode',
    },
    _ActInvoiceDetailGenericCode: {
      display: 'ActInvoiceDetailGenericCode',
    },
    _ActInvoiceDetailPreferredAccommodationCode: {
      display: 'ActInvoiceDetailPreferredAccommodationCode',
    },
    _ActInvoiceDetailClinicalServiceCode: {
      display: 'ActInvoiceDetailClinicalServiceCode',
    },
    UNSPSC: {
      display: 'United Nations Standard Products and Services Classification',
    },
    GTIN: {
      display: 'Global Trade Item Number',
    },
    UPC: {
      display: 'Universal Product Code',
    },
    _ActInvoiceDetailGenericAdjudicatorCode: {
      display: 'ActInvoiceDetailGenericAdjudicatorCode',
    },
    _ActInvoiceDetailGenericModifierCode: {
      display: 'ActInvoiceDetailGenericModifierCode',
    },
    _ActInvoiceDetailGenericProviderCode: {
      display: 'ActInvoiceDetailGenericProviderCode',
    },
    _ActInvoiceDetailTaxCode: {
      display: 'ActInvoiceDetailTaxCode',
    },
    COIN: {
      display: 'coinsurance',
    },
    COPAYMENT: {
      display: 'patient co-pay',
    },
    DEDUCTIBLE: {
      display: 'deductible',
    },
    PAY: {
      display: 'payment',
    },
    SPEND: {
      display: 'spend down',
    },
    COINS: {
      display: 'co-insurance',
    },
    AFTHRS: {
      display: 'non-normal hours',
    },
    ISOL: {
      display: 'isolation allowance',
    },
    OOO: {
      display: 'out of office',
    },
    CANCAPT: {
      display: 'cancelled appointment',
    },
    DSC: {
      display: 'discount',
    },
    ESA: {
      display: 'extraordinary service assessment',
    },
    FFSTOP: {
      display: 'fee for service top off',
    },
    FNLFEE: {
      display: 'final fee',
    },
    FRSTFEE: {
      display: 'first fee',
    },
    MARKUP: {
      display: 'markup or up-charge',
    },
    MISSAPT: {
      display: 'missed appointment',
    },
    PERFEE: {
      display: 'periodic fee',
    },
    PERMBNS: {
      display: 'performance bonus',
    },
    RESTOCK: {
      display: 'restocking fee',
    },
    TRAVEL: {
      display: 'travel',
    },
    URGENT: {
      display: 'urgent',
    },
    FST: {
      display: 'federal sales tax',
    },
    HST: {
      display: 'harmonized sales Tax',
    },
    PST: {
      display: 'provincial/state sales tax',
    },
    _ActEncounterAccommodationCode: {
      display: 'ActEncounterAccommodationCode',
    },
    _HL7AccommodationCode: {
      display: 'HL7AccommodationCode',
    },
    I: {
      display: 'Isolation',
    },
    P: {
      display: 'Private',
    },
    S: {
      display: 'Suite',
    },
    SP: {
      display: 'Semi-private',
    },
    W: {
      display: 'Ward',
    },
    _ActInvoiceInterGroupCode: {
      display: 'ActInvoiceInterGroupCode',
    },
    _ActInvoiceRootGroupCode: {
      display: 'ActInvoiceRootGroupCode',
    },
    CPNDDRGING: {
      display: 'compound drug invoice group',
    },
    CPNDINDING: {
      display: 'compound ingredient invoice group',
    },
    CPNDSUPING: {
      display: 'compound supply invoice group',
    },
    DRUGING: {
      display: 'drug invoice group',
    },
    FRAMEING: {
      display: 'frame invoice group',
    },
    LENSING: {
      display: 'lens invoice group',
    },
    PRDING: {
      display: 'product invoice group',
    },
    CPINV: {
      display: 'clinical product invoice',
    },
    CSINV: {
      display: 'clinical service invoice',
    },
    CSPINV: {
      display: 'clinical service and product',
    },
    FININV: {
      display: 'financial invoice',
    },
    OHSINV: {
      display: 'oral health service',
    },
    PAINV: {
      display: 'preferred accommodation invoice',
    },
    RXCINV: {
      display: 'Rx compound invoice',
    },
    RXDINV: {
      display: 'Rx dispense invoice',
    },
    SBFINV: {
      display: 'sessional or block fee invoice',
    },
    VRXINV: {
      display: 'vision dispense invoice',
    },
    _InvoiceElementAdjudicated: {
      display: 'InvoiceElementAdjudicated',
    },
    _InvoiceElementPaid: {
      display: 'InvoiceElementPaid',
    },
    _InvoiceElementSubmitted: {
      display: 'InvoiceElementSubmitted',
    },
    ADNFPPELAT: {
      display: 'adjud. nullified prior-period electronic amount',
    },
    ADNFPPELCT: {
      display: 'adjud. nullified prior-period electronic count',
    },
    ADNFPPMNAT: {
      display: 'adjud. nullified prior-period manual amount',
    },
    ADNFPPMNCT: {
      display: 'adjud. nullified prior-period manual count',
    },
    ADNFSPELAT: {
      display: 'adjud. nullified same-period electronic amount',
    },
    ADNFSPELCT: {
      display: 'adjud. nullified same-period electronic count',
    },
    ADNFSPMNAT: {
      display: 'adjud. nullified same-period manual amount',
    },
    ADNFSPMNCT: {
      display: 'adjud. nullified same-period manual count',
    },
    ADNPPPELAT: {
      display: 'adjud. non-payee payable prior-period electronic amount',
    },
    ADNPPPELCT: {
      display: 'adjud. non-payee payable prior-period electronic count',
    },
    ADNPPPMNAT: {
      display: 'adjud. non-payee payable prior-period manual amount',
    },
    ADNPPPMNCT: {
      display: 'adjud. non-payee payable prior-period manual count',
    },
    ADNPSPELAT: {
      display: 'adjud. non-payee payable same-period electronic amount',
    },
    ADNPSPELCT: {
      display: 'adjud. non-payee payable same-period electronic count',
    },
    ADNPSPMNAT: {
      display: 'adjud. non-payee payable same-period manual amount',
    },
    ADNPSPMNCT: {
      display: 'adjud. non-payee payable same-period manual count',
    },
    ADPPPPELAT: {
      display: 'adjud. payee payable prior-period electronic amount',
    },
    ADPPPPELCT: {
      display: 'adjud. payee payable prior-period electronic count',
    },
    ADPPPPMNAT: {
      display: 'adjud. payee payable prior-period manual amout',
    },
    ADPPPPMNCT: {
      display: 'adjud. payee payable prior-period manual count',
    },
    ADPPSPELAT: {
      display: 'adjud. payee payable same-period electronic amount',
    },
    ADPPSPELCT: {
      display: 'adjud. payee payable same-period electronic count',
    },
    ADPPSPMNAT: {
      display: 'adjud. payee payable same-period manual amount',
    },
    ADPPSPMNCT: {
      display: 'adjud. payee payable same-period manual count',
    },
    ADRFPPELAT: {
      display: 'adjud. refused prior-period electronic amount',
    },
    ADRFPPELCT: {
      display: 'adjud. refused prior-period electronic count',
    },
    ADRFPPMNAT: {
      display: 'adjud. refused prior-period manual amount',
    },
    ADRFPPMNCT: {
      display: 'adjud. refused prior-period manual count',
    },
    ADRFSPELAT: {
      display: 'adjud. refused same-period electronic amount',
    },
    ADRFSPELCT: {
      display: 'adjud. refused same-period electronic count',
    },
    ADRFSPMNAT: {
      display: 'adjud. refused same-period manual amount',
    },
    ADRFSPMNCT: {
      display: 'adjud. refused same-period manual count',
    },
    PDNFPPELAT: {
      display: 'paid nullified prior-period electronic amount',
    },
    PDNFPPELCT: {
      display: 'paid nullified prior-period electronic count',
    },
    PDNFPPMNAT: {
      display: 'paid nullified prior-period manual amount',
    },
    PDNFPPMNCT: {
      display: 'paid nullified prior-period manual count',
    },
    PDNFSPELAT: {
      display: 'paid nullified same-period electronic amount',
    },
    PDNFSPELCT: {
      display: 'paid nullified same-period electronic count',
    },
    PDNFSPMNAT: {
      display: 'paid nullified same-period manual amount',
    },
    PDNFSPMNCT: {
      display: 'paid nullified same-period manual count',
    },
    PDNPPPELAT: {
      display: 'paid non-payee payable prior-period electronic amount',
    },
    PDNPPPELCT: {
      display: 'paid non-payee payable prior-period electronic count',
    },
    PDNPPPMNAT: {
      display: 'paid non-payee payable prior-period manual amount',
    },
    PDNPPPMNCT: {
      display: 'paid non-payee payable prior-period manual count',
    },
    PDNPSPELAT: {
      display: 'paid non-payee payable same-period electronic amount',
    },
    PDNPSPELCT: {
      display: 'paid non-payee payable same-period electronic count',
    },
    PDNPSPMNAT: {
      display: 'paid non-payee payable same-period manual amount',
    },
    PDNPSPMNCT: {
      display: 'paid non-payee payable same-period manual count',
    },
    PDPPPPELAT: {
      display: 'paid payee payable prior-period electronic amount',
    },
    PDPPPPELCT: {
      display: 'paid payee payable prior-period electronic count',
    },
    PDPPPPMNAT: {
      display: 'paid payee payable prior-period manual amount',
    },
    PDPPPPMNCT: {
      display: 'paid payee payable prior-period manual count',
    },
    PDPPSPELAT: {
      display: 'paid payee payable same-period electronic amount',
    },
    PDPPSPELCT: {
      display: 'paid payee payable same-period electronic count',
    },
    PDPPSPMNAT: {
      display: 'paid payee payable same-period manual amount',
    },
    PDPPSPMNCT: {
      display: 'paid payee payable same-period manual count',
    },
    SBBLELAT: {
      display: 'submitted billed electronic amount',
    },
    SBBLELCT: {
      display: 'submitted billed electronic count',
    },
    SBNFELAT: {
      display: 'submitted nullified electronic amount',
    },
    SBNFELCT: {
      display: 'submitted cancelled electronic count',
    },
    SBPDELAT: {
      display: 'submitted pending electronic amount',
    },
    SBPDELCT: {
      display: 'submitted pending electronic count',
    },
    COVGE: {
      display: 'coverage problem',
    },
    EFORM: {
      display: 'electronic form to follow',
    },
    FAX: {
      display: 'fax to follow',
    },
    GFTH: {
      display: 'good faith indicator',
    },
    LATE: {
      display: 'late invoice',
    },
    MANUAL: {
      display: 'manual review',
    },
    OOJ: {
      display: 'out of jurisdiction',
    },
    ORTHO: {
      display: 'orthodontic service',
    },
    PAPER: {
      display: 'paper documentation to follow',
    },
    PIE: {
      display: 'public insurance exhausted',
    },
    PYRDELAY: {
      display: 'delayed by a previous payor',
    },
    REFNR: {
      display: 'referral not required',
    },
    REPSERV: {
      display: 'repeated service',
    },
    UNRELAT: {
      display: 'unrelated service',
    },
    VERBAUTH: {
      display: 'verbal authorization',
    },
    _ActObservationList: {
      display: 'ActObservationList',
    },
    _ActTherapyDurationWorkingListCode: {
      display: 'ActTherapyDurationWorkingListCode',
    },
    MEDLIST: {
      display: 'medication list',
    },
    CARELIST: {
      display: 'care plan',
    },
    CONDLIST: {
      display: 'condition list',
    },
    GOALLIST: {
      display: 'goal list',
    },
    INTOLIST: {
      display: 'intolerance list',
    },
    PROBLIST: {
      display: 'problem list',
    },
    RISKLIST: {
      display: 'risk factors',
    },
    _ActMedicationTherapyDurationWorkingListCode: {
      display: 'act medication therapy duration working list',
    },
    ACU: {
      display: 'short term/acute',
    },
    CHRON: {
      display: 'continuous/chronic',
    },
    ONET: {
      display: 'one time',
    },
    PRN: {
      display: 'as needed',
    },
    CURMEDLIST: {
      display: 'current medication list',
    },
    DISCMEDLIST: {
      display: 'discharge medication list',
    },
    HISTMEDLIST: {
      display: 'medication history',
    },
    CTLSUB: {
      display: 'Controlled Substance',
    },
    INV: {
      display: 'investigational',
    },
    LU: {
      display: 'limited use',
    },
    OTC: {
      display: 'non prescription medicine',
    },
    RX: {
      display: 'prescription only medicine',
    },
    SA: {
      display: 'special authorization',
    },
    SAC: {
      display: 'special access',
    },
    IND01: {
      display: 'imaging study requiring contrast',
    },
    IND02: {
      display: 'colonoscopy prep',
    },
    IND03: {
      display: 'prophylaxis',
    },
    IND04: {
      display: 'surgical prophylaxis',
    },
    IND05: {
      display: 'pregnancy prophylaxis',
    },
    VFPAPER: {
      display: 'verify paper',
    },
    ACH: {
      display: 'Automated Clearing House',
    },
    CHK: {
      display: 'Cheque',
    },
    DDP: {
      display: 'Direct Deposit',
    },
    NON: {
      display: 'Non-Payment Data',
    },
    DF: {
      display: 'Daily Fill',
    },
    EM: {
      display: 'Emergency Supply',
    },
    FF: {
      display: 'First Fill',
    },
    FS: {
      display: 'Floor stock',
    },
    MS: {
      display: 'Manufacturer Sample',
    },
    RF: {
      display: 'Refill',
    },
    UDE: {
      display: 'unit dose equivalent',
    },
    SO: {
      display: 'Script Owing',
    },
    FFC: {
      display: 'First Fill - Complete',
    },
    FFP: {
      display: 'First Fill - Part Fill',
    },
    FFSS: {
      display: 'first fill, partial strength',
    },
    TF: {
      display: 'Trial Fill',
    },
    UD: {
      display: 'Unit Dose',
    },
    RFC: {
      display: 'Refill - Complete',
    },
    RFF: {
      display: 'Refill (First fill this facility)',
    },
    RFP: {
      display: 'Refill - Part Fill',
    },
    RFS: {
      display: 'refill partial strength',
    },
    TB: {
      display: 'Trial Balance',
    },
    RFCS: {
      display: 'refill complete partial strength',
    },
    RFFS: {
      display: 'refill partial strength (first fill this facility)',
    },
    RFPS: {
      display: 'refill part fill partial strength',
    },
    TBS: {
      display: 'trial balance partial strength',
    },
    _ActPrivacyPolicy: {
      display: 'ActPrivacyPolicy',
    },
    ActTrustPolicyType: {
      display: 'trust policy',
    },
    COVPOL: {
      display: 'benefit policy',
    },
    SecurityPolicy: {
      display: 'security policy',
    },
    _ActConsentDirective: {
      display: 'ActConsentDirective',
    },
    _ActPrivacyLaw: {
      display: 'ActPrivacyLaw',
    },
    _InformationSensitivityPolicy: {
      display: 'InformationSensitivityPolicy',
    },
    COMPT: {
      display: 'compartment',
    },
    EMRGONLY: {
      display: 'emergency only',
    },
    GRANTORCHOICE: {
      display: 'grantor choice',
    },
    IMPLIED: {
      display: 'implied consent',
    },
    IMPLIEDD: {
      display: 'implied consent with opportunity to dissent',
    },
    NOCONSENT: {
      display: 'no consent',
    },
    NOPP: {
      display: 'notice of privacy practices',
    },
    OPTIN: {
      display: 'opt-in',
    },
    OPTINR: {
      display: 'opt-in with restrictions',
    },
    OPTOUT: {
      display: 'op-out',
    },
    OPTOUTE: {
      display: 'opt-out with exceptions',
    },
    _ActUSPrivacyLaw: {
      display: '_ActUSPrivacyLaw',
    },
    '42CFRPart2': {
      display: '42 CFR Part2',
    },
    CommonRule: {
      display: 'Common Rule',
    },
    HIPAANOPP: {
      display: 'HIPAA notice of privacy practices',
    },
    HIPAAPsyNotes: {
      display: 'HIPAA psychotherapy notes',
    },
    HIPAASelfPay: {
      display: 'HIPAA self-pay',
    },
    Title38Section7332: {
      display: 'Title 38 Section 7332',
    },
    _ActInformationSensitivityPolicy: {
      display: 'ActInformationSensitivityPolicy',
    },
    _EntitySensitivityPolicyType: {
      display: 'EntityInformationSensitivityPolicy',
    },
    _RoleInformationSensitivityPolicy: {
      display: 'RoleInformationSensitivityPolicy',
    },
    ADOL: {
      display: 'adolescent information sensitivity',
    },
    CEL: {
      display: 'celebrity information sensitivity',
    },
    DIA: {
      display: 'diagnosis information sensitivity',
    },
    DRGIS: {
      display: 'drug information sensitivity',
    },
    EMP: {
      display: 'employee information sensitivity',
    },
    PDS: {
      display: 'patient default sensitivity',
    },
    PRS: {
      display: 'patient requested sensitivity',
    },
    ETH: {
      display: 'substance abuse information sensitivity',
    },
    GDIS: {
      display: 'genetic disease information sensitivity',
    },
    HIV: {
      display: 'HIV/AIDS information sensitivity',
    },
    PSY: {
      display: 'psychiatry information sensitivity',
    },
    SCA: {
      display: 'sickle cell anemia',
    },
    SOC: {
      display: 'social services sensitivity',
    },
    SDV: {
      display: 'sexual assault, abuse, or domestic violence information sensitivity',
    },
    SEX: {
      display: 'sexuality and reproductive health information sensitivity',
    },
    STD: {
      display: 'sexually transmitted disease information sensitivity',
    },
    TBOO: {
      display: 'taboo',
    },
    SICKLE: {
      display: 'sickle cell',
    },
    DEMO: {
      display: 'all demographic information sensitivity',
    },
    DOB: {
      display: 'date of birth information sensitivity',
    },
    GENDER: {
      display: 'gender and sexual orientation information sensitivity',
    },
    LIVARG: {
      display: 'living arrangement information sensitivity',
    },
    MARST: {
      display: 'marital status information sensitivity',
    },
    RACE: {
      display: 'race information sensitivity',
    },
    REL: {
      display: 'religion information sensitivity',
    },
    B: {
      display: 'business information sensitivity',
    },
    EMPL: {
      display: 'employer information sensitivity',
    },
    LOCIS: {
      display: 'location information sensitivity',
    },
    SSP: {
      display: 'sensitive service provider information sensitivity',
    },
    HRCOMPT: {
      display: 'human resource compartment',
    },
    RESCOMPT: {
      display: 'research project compartment',
    },
    RMGTCOMPT: {
      display: 'records management compartment',
    },
    TRSTACCRD: {
      display: 'trust accreditation',
    },
    TRSTAGRE: {
      display: 'trust agreement',
    },
    TRSTASSUR: {
      display: 'trust assurance',
    },
    TRSTCERT: {
      display: 'trust certificate',
    },
    TRSTFWK: {
      display: 'trust framework',
    },
    TRSTMEC: {
      display: 'trust mechanism',
    },
    ObligationPolicy: {
      display: 'obligation policy',
    },
    RefrainPolicy: {
      display: 'refrain policy',
    },
    ANONY: {
      display: 'anonymize',
    },
    AOD: {
      display: 'accounting of disclosure',
    },
    AUDIT: {
      display: 'audit',
    },
    AUDTR: {
      display: 'audit trail',
    },
    CPLYCC: {
      display: 'comply with confidentiality code',
    },
    CPLYCD: {
      display: 'comply with consent directive',
    },
    CPLYJPP: {
      display: 'comply with jurisdictional privacy policy',
    },
    CPLYOPP: {
      display: 'comply with organizational privacy policy',
    },
    CPLYOSP: {
      display: 'comply with organizational security policy',
    },
    CPLYPOL: {
      display: 'comply with policy',
    },
    DECLASSIFYLABEL: {
      display: 'declassify security label',
    },
    DEID: {
      display: 'deidentify',
    },
    DELAU: {
      display: 'delete after use',
    },
    DOWNGRDLABEL: {
      display: 'downgrade security label',
    },
    DRIVLABEL: {
      display: 'derive security label',
    },
    ENCRYPT: {
      display: 'encrypt',
    },
    HUAPRV: {
      display: 'human approval',
    },
    LABEL: {
      display: 'assign security label',
    },
    MASK: {
      display: 'mask',
    },
    MINEC: {
      display: 'minimum necessary',
    },
    PERSISTLABEL: {
      display: 'persist security label',
    },
    PRIVMARK: {
      display: 'privacy mark',
    },
    PSEUD: {
      display: 'pseudonymize',
    },
    REDACT: {
      display: 'redact',
    },
    UPGRDLABEL: {
      display: 'upgrade security label',
    },
    ENCRYPTR: {
      display: 'encrypt at rest',
    },
    ENCRYPTT: {
      display: 'encrypt in transit',
    },
    ENCRYPTU: {
      display: 'encrypt in use',
    },
    NOAUTH: {
      display: 'no disclosure without subject authorization',
    },
    NOCOLLECT: {
      display: 'no collection',
    },
    NODSCLCD: {
      display: 'no disclosure without consent directive',
    },
    NODSCLCDS: {
      display: "no disclosure without information subject's consent directive",
    },
    NOINTEGRATE: {
      display: 'no integration',
    },
    NOLIST: {
      display: 'no unlisted entity disclosure',
    },
    NOMOU: {
      display: 'no disclosure without MOU',
    },
    NOORGPOL: {
      display: 'no disclosure without organizational authorization',
    },
    NOPAT: {
      display: "no disclosure to patient, family or caregivers without attending provider's authorization",
    },
    NOPERSISTP: {
      display: 'no collection beyond purpose of use',
    },
    NORDSCLCD: {
      display: 'no redisclosure without consent directive',
    },
    NORDSCLCDS: {
      display: "no redisclosure without information subject's consent directive",
    },
    NORDSCLW: {
      display: 'no disclosure without jurisdictional authorization',
    },
    NORELINK: {
      display: 'no relinking',
    },
    NOREUSE: {
      display: 'no reuse beyond purpose of use',
    },
    NOVIP: {
      display: 'no unauthorized VIP disclosure',
    },
    ORCON: {
      display: 'no disclosure without originator authorization',
    },
    LOAN: {
      display: 'Loan',
    },
    TRANSFER: {
      display: 'Transfer',
    },
    RENT: {
      display: 'Rent',
    },
    SALE: {
      display: 'Sale',
    },
    SREC: {
      display: 'specimen received',
    },
    SSTOR: {
      display: 'specimen in storage',
    },
    STRAN: {
      display: 'specimen in transit',
    },
    ACID: {
      display: 'Acidification',
    },
    ALK: {
      display: 'Alkalization',
    },
    DEFB: {
      display: 'Defibrination',
    },
    FILT: {
      display: 'Filtration',
    },
    LDLP: {
      display: 'LDL Precipitation',
    },
    NEUT: {
      display: 'Neutralization',
    },
    RECA: {
      display: 'Recalcification',
    },
    UFIL: {
      display: 'Ultrafiltration',
    },
    DRUG: {
      display: 'Drug therapy',
    },
    FD: {
      display: 'food',
    },
    IMMUNIZ: {
      display: 'Immunization',
    },
    BOOSTER: {
      display: 'Booster Immunization',
    },
    INITIMMUNIZ: {
      display: 'Initial Immunization',
    },
    OE: {
      display: 'order entry task',
    },
    PATDOC: {
      display: 'patient documentation task',
    },
    PATINFO: {
      display: 'patient information review task',
    },
    LABOE: {
      display: 'laboratory test order entry task',
    },
    MEDOE: {
      display: 'medication order entry task',
    },
    ALLERLREV: {
      display: 'allergy list review',
    },
    CLINNOTEE: {
      display: 'clinical note entry task',
    },
    IMMLREV: {
      display: 'immunization list review',
    },
    REMLREV: {
      display: 'reminder list review',
    },
    DIAGLISTE: {
      display: 'diagnosis list entry task',
    },
    DISCHINSTE: {
      display: 'discharge instruction entry',
    },
    DISCHSUME: {
      display: 'discharge summary entry task',
    },
    PATEDUE: {
      display: 'patient education entry',
    },
    PATREPE: {
      display: 'pathology report entry task',
    },
    PROBLISTE: {
      display: 'problem list entry task',
    },
    RADREPE: {
      display: 'radiology report entry task',
    },
    WELLREMLREV: {
      display: 'wellness reminder list review',
    },
    ALLERLE: {
      display: 'allergy list entry',
    },
    CDSREV: {
      display: 'clinical decision support intervention review',
    },
    CLINNOTEREV: {
      display: 'clinical note review task',
    },
    DIAGLISTREV: {
      display: 'diagnosis list review task',
    },
    IMMLE: {
      display: 'immunization list entry',
    },
    LABRREV: {
      display: 'laboratory results review task',
    },
    MICRORREV: {
      display: 'microbiology results review task',
    },
    MLREV: {
      display: 'medication list review task',
    },
    OREV: {
      display: 'orders review task',
    },
    PATREPREV: {
      display: 'pathology report review task',
    },
    PROBLISTREV: {
      display: 'problem list review task',
    },
    RADREPREV: {
      display: 'radiology report review task',
    },
    REMLE: {
      display: 'reminder list entry',
    },
    RISKASSESS: {
      display: 'risk assessment instrument task',
    },
    DISCHSUMREV: {
      display: 'discharge summary review task',
    },
    MICROORGRREV: {
      display: 'microbiology organisms results review task',
    },
    MICROSENSRREV: {
      display: 'microbiology sensitivity test results review task',
    },
    MARWLREV: {
      display: 'medication administration record work list review task',
    },
    WELLREMLE: {
      display: 'wellness reminder list entry',
    },
    FALLRISK: {
      display: 'falls risk assessment instrument task',
    },
    _ActPatientTransportationModeCode: {
      display: 'ActPatientTransportationModeCode',
    },
    AFOOT: {
      display: 'pedestrian transport',
    },
    AMBT: {
      display: 'ambulance transport',
    },
    LAWENF: {
      display: 'law enforcement transport',
    },
    PRVTRN: {
      display: 'private transport',
    },
    PUBTRN: {
      display: 'public transport',
    },
    AMBAIR: {
      display: 'fixed-wing ambulance transport',
    },
    AMBGRND: {
      display: 'ground ambulance transport',
    },
    AMBHELO: {
      display: 'helicopter ambulance transport',
    },
    _ActSpecObsCode: {
      display: 'ActSpecObsCode',
    },
    _AnnotationType: {
      display: 'AnnotationType',
    },
    _GeneticObservationType: {
      display: 'GeneticObservationType',
    },
    _ImmunizationObservationType: {
      display: 'ImmunizationObservationType',
    },
    _IndividualCaseSafetyReportType: {
      display: 'Individual Case Safety Report Type',
    },
    _LOINCObservationActContextAgeType: {
      display: 'LOINCObservationActContextAgeType',
    },
    _MedicationObservationType: {
      display: 'MedicationObservationType',
    },
    _ObservationIssueTriggerCodedObservationType: {
      display: 'ObservationIssueTriggerCodedObservationType',
    },
    _ObservationQualityMeasureAttribute: {
      display: 'ObservationQualityMeasureAttribute',
    },
    _ObservationSequenceType: {
      display: 'ObservationSequenceType',
    },
    _ObservationSeriesType: {
      display: 'ObservationSeriesType',
    },
    _PatientImmunizationRelatedObservationType: {
      display: 'PatientImmunizationRelatedObservationType',
    },
    _PopulationInclusionObservationType: {
      display: 'PopulationInclusionObservationType',
    },
    _PreferenceObservationType: {
      display: '_PreferenceObservationType',
    },
    ADVERSE_REACTION: {
      display: 'Adverse Reaction',
    },
    ASSERTION: {
      display: 'Assertion',
    },
    CASESER: {
      display: 'case seriousness criteria',
    },
    CDIO: {
      display: 'case disease imported observation',
    },
    CRIT: {
      display: 'criticality',
    },
    CTMO: {
      display: 'case transmission mode observation',
    },
    DX: {
      display: 'ObservationDiagnosisTypes',
    },
    GISTIER: {
      display: 'GIS tier',
    },
    HHOBS: {
      display: 'household situation observation',
    },
    ISSUE: {
      display: 'detected issue',
    },
    KSUBJ: {
      display: 'knowledge subject',
    },
    KSUBT: {
      display: 'knowledge subtopic',
    },
    OINT: {
      display: 'intolerance',
    },
    SEV: {
      display: 'Severity Observation',
    },
    _FDALabelData: {
      display: 'FDALabelData',
    },
    ARTBLD: {
      display: 'ActSpecObsArtBldCode',
    },
    DILUTION: {
      display: 'ActSpecObsDilutionCode',
    },
    EVNFCTS: {
      display: 'ActSpecObsEvntfctsCode',
    },
    INTFR: {
      display: 'ActSpecObsInterferenceCode',
    },
    VOLUME: {
      display: 'ActSpecObsVolumeCode',
    },
    'AUTO-HIGH': {
      display: 'Auto-High Dilution',
    },
    'AUTO-LOW': {
      display: 'Auto-Low Dilution',
    },
    PRE: {
      display: 'Pre-Dilution',
    },
    RERUN: {
      display: 'Rerun Dilution',
    },
    FIBRIN: {
      display: 'Fibrin',
    },
    HEMOLYSIS: {
      display: 'Hemolysis',
    },
    ICTERUS: {
      display: 'Icterus',
    },
    LIPEMIA: {
      display: 'Lipemia',
    },
    AVAILABLE: {
      display: 'Available Volume',
    },
    CONSUMPTION: {
      display: 'Consumption Volume',
    },
    CURRENT: {
      display: 'Current Volume',
    },
    INITIAL: {
      display: 'Initial Volume',
    },
    _ActPatientAnnotationType: {
      display: 'ActPatientAnnotationType',
    },
    ANNDI: {
      display: 'diagnostic image note',
    },
    ANNGEN: {
      display: 'general note',
    },
    ANNIMM: {
      display: 'immunization note',
    },
    ANNLAB: {
      display: 'laboratory note',
    },
    ANNMED: {
      display: 'medication note',
    },
    GENE: {
      display: 'gene',
    },
    OBSANTC: {
      display: 'antigen count',
    },
    OBSANTV: {
      display: 'antigen validity',
    },
    PAT_ADV_EVNT: {
      display: 'patient adverse event',
    },
    VAC_PROBLEM: {
      display: 'vaccine product problem',
    },
    '21611-9': {
      display: 'age patient qn est',
    },
    '21612-7': {
      display: 'age patient qn reported',
    },
    '29553-5': {
      display: 'age patient qn calc',
    },
    '30525-0': {
      display: 'age patient qn definition',
    },
    '30972-4': {
      display: 'age at onset of adverse event',
    },
    REP_HALF_LIFE: {
      display: 'representative half-life',
    },
    SPLCOATING: {
      display: 'coating',
    },
    SPLCOLOR: {
      display: 'color',
    },
    SPLIMAGE: {
      display: 'image',
    },
    SPLIMPRINT: {
      display: 'imprint',
    },
    SPLSCORING: {
      display: 'scoring',
    },
    SPLSHAPE: {
      display: 'shape',
    },
    SPLSIZE: {
      display: 'size',
    },
    SPLSYMBOL: {
      display: 'symbol',
    },
    _CaseTransmissionMode: {
      display: 'case transmission mode',
    },
    AIRTRNS: {
      display: 'airborne transmission',
    },
    ANANTRNS: {
      display: 'animal to animal transmission',
    },
    ANHUMTRNS: {
      display: 'animal to human transmission',
    },
    BDYFLDTRNS: {
      display: 'body fluid contact transmission',
    },
    BLDTRNS: {
      display: 'blood borne transmission',
    },
    DERMTRNS: {
      display: 'transdermal transmission',
    },
    ENVTRNS: {
      display: 'environmental exposure transmission',
    },
    FECTRNS: {
      display: 'fecal-oral transmission',
    },
    FOMTRNS: {
      display: 'fomite transmission',
    },
    FOODTRNS: {
      display: 'food-borne transmission',
    },
    HUMHUMTRNS: {
      display: 'human to human transmission',
    },
    INDTRNS: {
      display: 'indeterminate disease transmission mode',
    },
    LACTTRNS: {
      display: 'lactation transmission',
    },
    NOSTRNS: {
      display: 'nosocomial transmission',
    },
    PARTRNS: {
      display: 'parenteral transmission',
    },
    PLACTRNS: {
      display: 'transplacental transmission',
    },
    SEXTRNS: {
      display: 'sexual transmission',
    },
    TRNSFTRNS: {
      display: 'transfusion transmission',
    },
    VECTRNS: {
      display: 'vector-borne transmission',
    },
    WATTRNS: {
      display: 'water-borne transmission',
    },
    AGGREGATE: {
      display: 'aggregate measure observation',
    },
    CMPMSRMTH: {
      display: 'composite measure method',
    },
    CMPMSRSCRWGHT: {
      display: 'component measure scoring weight',
    },
    COPY: {
      display: 'copyright',
    },
    CRS: {
      display: 'clinical recommendation statement',
    },
    DEF: {
      display: 'definition',
    },
    DISC: {
      display: 'disclaimer',
    },
    FINALDT: {
      display: 'finalized date/time',
    },
    GUIDE: {
      display: 'guidance',
    },
    IDUR: {
      display: 'improvement notation',
    },
    ITMCNT: {
      display: 'items counted',
    },
    KEY: {
      display: 'keyword',
    },
    MEDT: {
      display: 'measurement end date',
    },
    MSD: {
      display: 'measurement start date',
    },
    MSRADJ: {
      display: 'risk adjustment',
    },
    MSRAGG: {
      display: 'rate aggregation',
    },
    MSRIMPROV: {
      display: 'health quality measure improvement notation',
    },
    MSRJUR: {
      display: 'jurisdiction',
    },
    MSRRPTR: {
      display: 'reporter type',
    },
    MSRRPTTIME: {
      display: 'timeframe for reporting',
    },
    MSRSCORE: {
      display: 'measure scoring',
    },
    MSRSET: {
      display: 'health quality measure care setting',
    },
    MSRTOPIC: {
      display: 'health quality measure topic type',
    },
    MSRTP: {
      display: 'measurement period',
    },
    MSRTYPE: {
      display: 'measure type',
    },
    RAT: {
      display: 'rationale',
    },
    REF: {
      display: 'reference',
    },
    SDE: {
      display: 'supplemental data elements',
    },
    STRAT: {
      display: 'stratification',
    },
    TRANF: {
      display: 'transmission format',
    },
    USE: {
      display: 'notice of use',
    },
    TIME_ABSOLUTE: {
      display: 'absolute time sequence',
    },
    TIME_RELATIVE: {
      display: 'relative time sequence',
    },
    _ECGObservationSeriesType: {
      display: 'ECGObservationSeriesType',
    },
    REPRESENTATIVE_BEAT: {
      display: 'ECG representative beat waveforms',
    },
    RHYTHM: {
      display: 'ECG rhythm waveforms',
    },
    CLSSRM: {
      display: 'classroom',
    },
    GRADE: {
      display: 'grade',
    },
    SCHL: {
      display: 'school',
    },
    SCHLDIV: {
      display: 'school division',
    },
    TEACHER: {
      display: 'teacher',
    },
    DENEX: {
      display: 'denominator exclusions',
    },
    DENEXCEP: {
      display: 'denominator exceptions',
    },
    DENOM: {
      display: 'denominator',
    },
    IPOP: {
      display: 'initial population',
    },
    MSRPOPL: {
      display: 'measure population',
    },
    MSRPOPLEX: {
      display: 'measure population exclusions',
    },
    NUMER: {
      display: 'numerator',
    },
    NUMEX: {
      display: 'numerator exclusions',
    },
    IPPOP: {
      display: 'initial patient population',
    },
    PREFSTRENGTH: {
      display: 'preference strength',
    },
    ADMDX: {
      display: 'admitting diagnosis',
    },
    DISDX: {
      display: 'discharge diagnosis',
    },
    INTDX: {
      display: 'intermediate diagnosis',
    },
    NOI: {
      display: 'nature of injury',
    },
    _ActAdministrativeDetectedIssueCode: {
      display: 'ActAdministrativeDetectedIssueCode',
    },
    _ActSuppliedItemDetectedIssueCode: {
      display: 'ActSuppliedItemDetectedIssueCode',
    },
    _ActAdministrativeAuthorizationDetectedIssueCode: {
      display: 'ActAdministrativeAuthorizationDetectedIssueCode',
    },
    _ActAdministrativeRuleDetectedIssueCode: {
      display: 'ActAdministrativeRuleDetectedIssueCode',
    },
    NAT: {
      display: 'Insufficient authorization',
    },
    SUPPRESSED: {
      display: 'record suppressed',
    },
    VALIDAT: {
      display: 'validation issue',
    },
    KEY204: {
      display: 'Unknown key identifier',
    },
    KEY205: {
      display: 'Duplicate key identifier',
    },
    COMPLY: {
      display: 'Compliance Alert',
    },
    DOSE: {
      display: 'Dosage problem',
    },
    OBSA: {
      display: 'Observation Alert',
    },
    BUS: {
      display: 'business constraint violation',
    },
    CODE_INVAL: {
      display: 'code is not valid',
    },
    FORMAT: {
      display: 'invalid format',
    },
    ILLEGAL: {
      display: 'illegal',
    },
    LEN_RANGE: {
      display: 'length out of range',
    },
    MISSCOND: {
      display: 'conditional element missing',
    },
    MISSMAND: {
      display: 'mandatory element missing',
    },
    NODUPS: {
      display: 'duplicate values are not permitted',
    },
    NOPERSIST: {
      display: 'element will not be persisted',
    },
    REP_RANGE: {
      display: 'repetitions out of range',
    },
    DUPTHPY: {
      display: 'Duplicate Therapy Alert',
    },
    ABUSE: {
      display: 'commonly abused/misused alert',
    },
    FRAUD: {
      display: 'potential fraud',
    },
    PLYDOC: {
      display: 'Poly-orderer Alert',
    },
    PLYPHRM: {
      display: 'Poly-supplier Alert',
    },
    DUPTHPCLS: {
      display: 'duplicate therapeutic alass alert',
    },
    DUPTHPGEN: {
      display: 'duplicate generic alert',
    },
    DOSECOND: {
      display: 'dosage-condition alert',
    },
    DOSEDUR: {
      display: 'Dose-Duration Alert',
    },
    DOSEH: {
      display: 'High Dose Alert',
    },
    DOSEIVL: {
      display: 'Dose-Interval Alert',
    },
    DOSEL: {
      display: 'Low Dose Alert',
    },
    MDOSE: {
      display: 'maximum dosage reached',
    },
    DOSEDURH: {
      display: 'Dose-Duration High Alert',
    },
    DOSEDURL: {
      display: 'Dose-Duration Low Alert',
    },
    DOSEDURHIND: {
      display: 'Dose-Duration High for Indication Alert',
    },
    DOSEDURLIND: {
      display: 'Dose-Duration Low for Indication Alert',
    },
    DOSEHINDA: {
      display: 'High Dose for Age Alert',
    },
    DOSEHIND: {
      display: 'High Dose for Indication Alert',
    },
    DOSEHINDSA: {
      display: 'High Dose for Height/Surface Area Alert',
    },
    DOSEHINDW: {
      display: 'High Dose for Weight Alert',
    },
    DOSEIVLIND: {
      display: 'Dose-Interval for Indication Alert',
    },
    DOSELINDA: {
      display: 'Low Dose for Age Alert',
    },
    DOSELIND: {
      display: 'Low Dose for Indication Alert',
    },
    DOSELINDSA: {
      display: 'Low Dose for Height/Surface Area Alert',
    },
    DOSELINDW: {
      display: 'Low Dose for Weight Alert',
    },
    AGE: {
      display: 'Age Alert',
    },
    COND: {
      display: 'Condition Alert',
    },
    CREACT: {
      display: 'common reaction alert',
    },
    GEN: {
      display: 'Genetic Alert',
    },
    GEND: {
      display: 'Gender Alert',
    },
    LAB: {
      display: 'Lab Alert',
    },
    REACT: {
      display: 'Reaction Alert',
    },
    RREACT: {
      display: 'Related Reaction Alert',
    },
    ADALRT: {
      display: 'adult alert',
    },
    GEALRT: {
      display: 'geriatric alert',
    },
    PEALRT: {
      display: 'pediatric alert',
    },
    HGHT: {},
    LACT: {
      display: 'Lactation Alert',
    },
    PREG: {
      display: 'Pregnancy Alert',
    },
    WGHT: {},
    ALGY: {
      display: 'Allergy Alert',
    },
    INT: {
      display: 'Intolerance Alert',
    },
    RALG: {
      display: 'Related Allergy Alert',
    },
    RAR: {
      display: 'Related Prior Reaction Alert',
    },
    RINT: {
      display: 'Related Intolerance Alert',
    },
    CODE_DEPREC: {
      display: 'code has been deprecated',
    },
    LEN_LONG: {
      display: 'length is too long',
    },
    LEN_SHORT: {
      display: 'length is too short',
    },
    MAXOCCURS: {
      display: 'repetitions above maximum',
    },
    MINOCCURS: {
      display: 'repetitions below minimum',
    },
    KEY206: {
      display: 'non-matching identification',
    },
    OBSOLETE: {
      display: 'obsolete record returned',
    },
    _AdministrationDetectedIssueCode: {
      display: 'AdministrationDetectedIssueCode',
    },
    _SupplyDetectedIssueCode: {
      display: 'SupplyDetectedIssueCode',
    },
    HISTORIC: {
      display: 'record recorded as historical',
    },
    PATPREF: {
      display: 'violates stated preferences',
    },
    _AppropriatenessDetectedIssueCode: {
      display: 'AppropriatenessDetectedIssueCode',
    },
    DACT: {
      display: 'drug action detected issue',
    },
    TIME: {
      display: 'timing detected issue',
    },
    _TimingDetectedIssueCode: {
      display: 'TimingDetectedIssueCode',
    },
    _InteractionDetectedIssueCode: {
      display: 'InteractionDetectedIssueCode',
    },
    PREVINEF: {
      display: 'previously ineffective',
    },
    FOOD: {
      display: 'Food Interaction Alert',
    },
    TPROD: {
      display: 'Therapeutic Product Alert',
    },
    DRG: {
      display: 'Drug Interaction Alert',
    },
    NHP: {
      display: 'Natural Health Product Alert',
    },
    NONRX: {
      display: 'Non-Prescription Interaction Alert',
    },
    ALRTENDLATE: {
      display: 'end too late alert',
    },
    ALRTSTRTLATE: {
      display: 'start too late alert',
    },
    ENDLATE: {
      display: 'End Too Late Alert',
    },
    STRTLATE: {
      display: 'Start Too Late Alert',
    },
    ALLDONE: {
      display: 'already performed',
    },
    FULFIL: {
      display: 'fulfillment alert',
    },
    HELD: {
      display: 'held/suspended alert',
    },
    TOOLATE: {
      display: 'Refill Too Late Alert',
    },
    TOOSOON: {
      display: 'Refill Too Soon Alert',
    },
    NOTACTN: {
      display: 'no longer actionable',
    },
    NOTEQUIV: {
      display: 'not equivalent alert',
    },
    TIMING: {
      display: 'event timing incorrect alert',
    },
    NOTEQUIVGEN: {
      display: 'not generically equivalent alert',
    },
    NOTEQUIVTHER: {
      display: 'not therapeutically equivalent alert',
    },
    INTERVAL: {
      display: 'outside requested time',
    },
    MINFREQ: {
      display: 'too soon within frequency based on the usage',
    },
    PATPREFALT: {
      display: 'violates stated preferences, alternate available',
    },
    ALG: {
      display: 'Allergy',
    },
    DINT: {
      display: 'Drug Intolerance',
    },
    EINT: {
      display: 'Environmental Intolerance',
    },
    FINT: {
      display: 'Food Intolerance',
    },
    NAINT: {
      display: 'Non-Allergy Intolerance',
    },
    DALG: {
      display: 'Drug Allergy',
    },
    EALG: {
      display: 'Environmental Allergy',
    },
    FALG: {
      display: 'Food Allergy',
    },
    DNAINT: {
      display: 'Drug Non-Allergy Intolerance',
    },
    ENAINT: {
      display: 'Environmental Non-Allergy Intolerance',
    },
    FNAINT: {
      display: 'Food Non-Allergy Intolerance',
    },
    FDACOATING: {
      display: 'coating',
    },
    FDACOLOR: {
      display: 'color',
    },
    FDAIMPRINTCD: {
      display: 'imprint code',
    },
    FDALOGO: {
      display: 'logo',
    },
    FDASCORING: {
      display: 'scoring',
    },
    FDASHAPE: {
      display: 'shape',
    },
    FDASIZE: {
      display: 'size',
    },
    CIRCLE: {
      display: 'circle',
    },
    ELLIPSE: {
      display: 'ellipse',
    },
    POINT: {
      display: 'point',
    },
    POLY: {
      display: 'polyline',
    },
    BR: {
      display: 'breikost (GE)',
    },
    DM: {
      display: 'diabetes mellitus diet',
    },
    FAST: {
      display: 'fasting',
    },
    FORMULA: {
      display: 'formula diet',
    },
    GF: {
      display: 'gluten free',
    },
    LF: {
      display: 'low fat',
    },
    LP: {
      display: 'low protein',
    },
    LQ: {
      display: 'liquid',
    },
    LS: {
      display: 'low sodium',
    },
    N: {
      display: 'normal diet',
    },
    NF: {
      display: 'no fat',
    },
    PAF: {
      display: 'phenylalanine free',
    },
    PAR: {
      display: 'parenteral',
    },
    RD: {
      display: 'reduction diet',
    },
    SCH: {
      display: 'schonkost (GE)',
    },
    SUPPLEMENT: {
      display: 'nutritional supplement',
    },
    T: {
      display: 'tea only',
    },
    VLI: {
      display: 'low valin, leucin, isoleucin',
    },
    SECCATOBS: {
      display: 'security category observation',
    },
    SECCLASSOBS: {
      display: 'security classification observation',
    },
    SECCONOBS: {
      display: 'security control observation',
    },
    SECINTOBS: {
      display: 'security integrity observation',
    },
    SECTRSTOBS: {
      display: 'SECTRSTOBS',
    },
    SECALTINTOBS: {
      display: 'security alteration integrity observation',
    },
    SECDATINTOBS: {
      display: 'security data integrity observation',
    },
    SECINTCONOBS: {
      display: 'security integrity confidence observation',
    },
    SECINTPRVOBS: {
      display: 'security integrity provenance observation',
    },
    SECINTSTOBS: {
      display: 'security integrity status observation',
    },
    SECINTPRVABOBS: {
      display: 'security integrity provenance asserted by observation',
    },
    SECINTPRVRBOBS: {
      display: 'security integrity provenance reported by observation',
    },
    TRSTACCRDOBS: {
      display: 'trust accreditation observation',
    },
    TRSTAGREOBS: {
      display: 'trust agreement observation',
    },
    TRSTCERTOBS: {
      display: 'trust certificate observation',
    },
    TRSTFWKOBS: {
      display: 'trust framework observation',
    },
    TRSTLOAOBS: {
      display: 'trust assurance observation',
    },
    TRSTMECOBS: {
      display: 'trust mechanism observation',
    },
    _ActBillableServiceCode: {
      display: 'ActBillableServiceCode',
    },
  },
};
