import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'specimenStatus',
})
export class SpecimenStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'available':
        return $localize`:@@fhir.specimen-status.available:Available`;
      case 'unavailable':
        return $localize`:@@fhir.specimen-status.unavailable:Unavailable`;
      case 'unsatisfactory':
        return $localize`:@@fhir.specimen-status.unsatisfactory:Unsatisfactory`;
      case 'entered-in-error':
        return $localize`:@@fhir.specimen-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
