import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../menu-item.model';

@Component({
  selector: 'app-middle-menu',
  templateUrl: './middle-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiddleMenuComponent {
  @Input() menu: MenuItem[] | undefined | null;
  @Output() selected = new EventEmitter<MenuItem>();

  get activeMenuItem(): MenuItem | undefined {
    return this.menu ? this.menu[0] : undefined;
  }

  select(item: MenuItem) {
    this.selected.emit(item);
  }
}
