import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicationStatus',
})
export class MedicationStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'active':
        return $localize`:@@fhir.medication-status.active:Active`;
      case 'inactive':
        return $localize`:@@fhir.medication-status.inactive:Inactive`;
      case 'entered-in-error':
        return $localize`:@@fhir.medication-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
