import { ImagingStudySeriesInstance } from '@hl7fhir';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { CodingsPipe } from '@hl7fhir/data-types';

export class ImagingStudySeriesInstanceViewModel extends BackboneElementViewModel<ImagingStudySeriesInstance> {
  get number(): string | undefined {
    return this.element?.number?.toString();
  }

  get sopClass(): string | undefined {
    const imagingStudySeriesInstanceR4R4b = this.element as
      | r4.ImagingStudySeriesInstance
      | r4b.ImagingStudySeriesInstance;
    return new CodingsPipe().transform(imagingStudySeriesInstanceR4R4b?.sopClass);
  }

  get title(): string | undefined {
    return this.element?.title;
  }

  get uid(): string | undefined {
    return this.element?.uid;
  }
}
