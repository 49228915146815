import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { ValueSetFlattened } from '@hl7fhir/value-sets';
import { AdresSoortCodelijst } from '@hl7nl-fhir/value-sets';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['span:empty::before { content: "\\200b"; /* unicode zero width space character */ }'],
})
export class AddressComponent {
  @Input() address: Address | undefined;
  @Input() profile = '';

  showUse = true;
  showType = true;
  showLines = true;

  readonly structureDefinition: typeof StructureDefinition = StructureDefinition;
  readonly adresSoortCodelijst: ValueSetFlattened[] = [AdresSoortCodelijst];
  readonly codeSystems: typeof CodeSystems = CodeSystems;
}
