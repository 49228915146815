import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { NarrativeComponent } from './narrative.component';
import { ValueSetsModule } from '@hl7fhir/value-sets';

const components = [NarrativeComponent];

@NgModule({
  imports: [SharedModule, ValueSetsModule],
  declarations: [...components],
  exports: [...components],
})
export class NarrativeModule {}
