import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { AUTHORIZE_URL_API_ACTIONS } from '@store/digi.me';
import { DigiMeOnboardComponent } from './digi-me-onboard.component';

@Component({
  selector: 'app-digi-me-onboard-container',
  standalone: true,
  imports: [SharedModule, DigiMeOnboardComponent],
  template: ` <app-digi-me-onboard (sourceLink)="linkSource()"></app-digi-me-onboard> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigiMeOnboardContainerComponent {
  constructor(private readonly store: Store) {}

  linkSource(): void {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({ createNewUser: false, sourceFetch: true, sourceType: 'pull' })
    );
  }
}
