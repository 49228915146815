import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@hl7fhir';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesComponent {
  @Input() addresses: Address[] | undefined;
}
