import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compositionStatus',
})
export class CompositionStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'preliminary':
        return $localize`:@@fhir.composition-status.preliminary:Preliminary`;
      case 'final':
        return $localize`:@@fhir.composition-status.final:Final`;
      case 'amended':
        return $localize`:@@fhir.composition-status.amended:Amended`;
      case 'entered-in-error':
        return $localize`:@@fhir.composition-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
