import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../menu-item.model';

@Component({
  selector: 'app-side-mobile-menu',
  templateUrl: './side-mobile-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMobileMenuComponent {
  @Input() menu: MenuItem[] | undefined | null;
  @Output() selected = new EventEmitter<MenuItem>();

  select(item: MenuItem) {
    this.selected.emit(item);
  }
}
