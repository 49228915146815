import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const ResourceType: CodeSystemFlattened = {
  id: 'resource-types',
  codes: {
    Account: {
      display: 'Account',
    },
    ActivityDefinition: {
      display: 'ActivityDefinition',
    },
    AdverseEvent: {
      display: 'AdverseEvent',
    },
    AllergyIntolerance: {
      display: 'AllergyIntolerance',
    },
    Appointment: {
      display: 'Appointment',
    },
    AppointmentResponse: {
      display: 'AppointmentResponse',
    },
    AuditEvent: {
      display: 'AuditEvent',
    },
    Basic: {
      display: 'Basic',
    },
    Binary: {
      display: 'Binary',
    },
    BodySite: {
      display: 'BodySite',
    },
    Bundle: {
      display: 'Bundle',
    },
    CapabilityStatement: {
      display: 'CapabilityStatement',
    },
    CarePlan: {
      display: 'CarePlan',
    },
    CareTeam: {
      display: 'CareTeam',
    },
    ChargeItem: {
      display: 'ChargeItem',
    },
    Claim: {
      display: 'Claim',
    },
    ClaimResponse: {
      display: 'ClaimResponse',
    },
    ClinicalImpression: {
      display: 'ClinicalImpression',
    },
    CodeSystem: {
      display: 'CodeSystem',
    },
    Communication: {
      display: 'Communication',
    },
    CommunicationRequest: {
      display: 'CommunicationRequest',
    },
    CompartmentDefinition: {
      display: 'CompartmentDefinition',
    },
    Composition: {
      display: 'Composition',
    },
    ConceptMap: {
      display: 'ConceptMap',
    },
    Condition: {
      display: 'Condition',
    },
    Consent: {
      display: 'Consent',
    },
    Contract: {
      display: 'Contract',
    },
    Coverage: {
      display: 'Coverage',
    },
    DataElement: {
      display: 'DataElement',
    },
    DetectedIssue: {
      display: 'DetectedIssue',
    },
    Device: {
      display: 'Device',
    },
    DeviceComponent: {
      display: 'DeviceComponent',
    },
    DeviceMetric: {
      display: 'DeviceMetric',
    },
    DeviceRequest: {
      display: 'DeviceRequest',
    },
    DeviceUseStatement: {
      display: 'DeviceUseStatement',
    },
    DiagnosticReport: {
      display: 'DiagnosticReport',
    },
    DocumentManifest: {
      display: 'DocumentManifest',
    },
    DocumentReference: {
      display: 'DocumentReference',
    },
    DomainResource: {
      display: 'DomainResource',
    },
    EligibilityRequest: {
      display: 'EligibilityRequest',
    },
    EligibilityResponse: {
      display: 'EligibilityResponse',
    },
    Encounter: {
      display: 'Encounter',
    },
    Endpoint: {
      display: 'Endpoint',
    },
    EnrollmentRequest: {
      display: 'EnrollmentRequest',
    },
    EnrollmentResponse: {
      display: 'EnrollmentResponse',
    },
    EpisodeOfCare: {
      display: 'EpisodeOfCare',
    },
    ExpansionProfile: {
      display: 'ExpansionProfile',
    },
    ExplanationOfBenefit: {
      display: 'ExplanationOfBenefit',
    },
    FamilyMemberHistory: {
      display: 'FamilyMemberHistory',
    },
    Flag: {
      display: 'Flag',
    },
    Goal: {
      display: 'Goal',
    },
    GraphDefinition: {
      display: 'GraphDefinition',
    },
    Group: {
      display: 'Group',
    },
    GuidanceResponse: {
      display: 'GuidanceResponse',
    },
    HealthcareService: {
      display: 'HealthcareService',
    },
    ImagingManifest: {
      display: 'ImagingManifest',
    },
    ImagingStudy: {
      display: 'ImagingStudy',
    },
    Immunization: {
      display: 'Immunization',
    },
    ImmunizationRecommendation: {
      display: 'ImmunizationRecommendation',
    },
    ImplementationGuide: {
      display: 'ImplementationGuide',
    },
    Library: {
      display: 'Library',
    },
    Linkage: {
      display: 'Linkage',
    },
    List: {
      display: 'List',
    },
    Location: {
      display: 'Location',
    },
    Measure: {
      display: 'Measure',
    },
    MeasureReport: {
      display: 'MeasureReport',
    },
    Media: {
      display: 'Media',
    },
    Medication: {
      display: 'Medication',
    },
    MedicationAdministration: {
      display: 'MedicationAdministration',
    },
    MedicationDispense: {
      display: 'MedicationDispense',
    },
    MedicationRequest: {
      display: 'MedicationRequest',
    },
    MedicationStatement: {
      display: 'MedicationStatement',
    },
    MessageDefinition: {
      display: 'MessageDefinition',
    },
    MessageHeader: {
      display: 'MessageHeader',
    },
    NamingSystem: {
      display: 'NamingSystem',
    },
    NutritionOrder: {
      display: 'NutritionOrder',
    },
    Observation: {
      display: 'Observation',
    },
    OperationDefinition: {
      display: 'OperationDefinition',
    },
    OperationOutcome: {
      display: 'OperationOutcome',
    },
    Organization: {
      display: 'Organization',
    },
    Parameters: {
      display: 'Parameters',
    },
    Patient: {
      display: 'Patient',
    },
    PaymentNotice: {
      display: 'PaymentNotice',
    },
    PaymentReconciliation: {
      display: 'PaymentReconciliation',
    },
    Person: {
      display: 'Person',
    },
    PlanDefinition: {
      display: 'PlanDefinition',
    },
    Practitioner: {
      display: 'Practitioner',
    },
    PractitionerRole: {
      display: 'PractitionerRole',
    },
    Procedure: {
      display: 'Procedure',
    },
    ProcedureRequest: {
      display: 'ProcedureRequest',
    },
    ProcessRequest: {
      display: 'ProcessRequest',
    },
    ProcessResponse: {
      display: 'ProcessResponse',
    },
    Provenance: {
      display: 'Provenance',
    },
    Questionnaire: {
      display: 'Questionnaire',
    },
    QuestionnaireResponse: {
      display: 'QuestionnaireResponse',
    },
    ReferralRequest: {
      display: 'ReferralRequest',
    },
    RelatedPerson: {
      display: 'RelatedPerson',
    },
    RequestGroup: {
      display: 'RequestGroup',
    },
    ResearchStudy: {
      display: 'ResearchStudy',
    },
    ResearchSubject: {
      display: 'ResearchSubject',
    },
    Resource: {
      display: 'Resource',
    },
    RiskAssessment: {
      display: 'RiskAssessment',
    },
    Schedule: {
      display: 'Schedule',
    },
    SearchParameter: {
      display: 'SearchParameter',
    },
    Sequence: {
      display: 'Sequence',
    },
    ServiceDefinition: {
      display: 'ServiceDefinition',
    },
    Slot: {
      display: 'Slot',
    },
    Specimen: {
      display: 'Specimen',
    },
    StructureDefinition: {
      display: 'StructureDefinition',
    },
    StructureMap: {
      display: 'StructureMap',
    },
    Subscription: {
      display: 'Subscription',
    },
    Substance: {
      display: 'Substance',
    },
    SupplyDelivery: {
      display: 'SupplyDelivery',
    },
    SupplyRequest: {
      display: 'SupplyRequest',
    },
    Task: {
      display: 'Task',
    },
    TestReport: {
      display: 'TestReport',
    },
    TestScript: {
      display: 'TestScript',
    },
    ValueSet: {
      display: 'ValueSet',
    },
    VisionPrescription: {
      display: 'VisionPrescription',
    },
  },
};
