import { NgModule } from '@angular/core';
import { ExtensibilityModule } from '@hl7fhir/extensibility';
import { SharedModule } from '@shared';
import { CodeableConceptModule } from '../codeable-concept';
import { ContactPointComponent } from './contact-point.component';
import { ContactPointsComponent } from './contact-points.component';
import { ContactPointRankPipe } from './pipes/contact-point-rank.pipe';
import { ContactPointSystemWherePipe } from './pipes/contact-point-system-where.pipe';
import { ContactPointSystemPipe } from './pipes/contact-point-system.pipe';
import { ContactPointUsePipe } from './pipes/contact-point-use-pipe.pipe';

const components = [ContactPointComponent, ContactPointsComponent];
const pipes = [ContactPointUsePipe, ContactPointSystemPipe, ContactPointSystemWherePipe, ContactPointRankPipe];
const dataTypes = [CodeableConceptModule];

@NgModule({
  imports: [SharedModule, ExtensibilityModule, ...dataTypes],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
})
export class ContactPointModule {}
