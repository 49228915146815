import { AspectRatio } from './aspect-ratio.model';
import { ResourcesBase } from './resources-base.model';

export interface Resource extends ResourcesBase {
  mimetype: string;
  resize: string;
  type: number;
  url: string;
  aspectratio: AspectRatio;
  height: number;
  width: number;
}
