import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigatorOnLineService } from '@core/services';

@Component({
  selector: 'app-navigator-on-line',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div data-cy="offline" *ngIf="(online$ | async) === false" class="offline-status">
      <svg class="offline-status-icon">
        <use xlink:href="./assets/images/assets.svg#offline"></use>
      </svg>
      <div>
        <span><strong i18n="@@app.NetworkStatus.noInternet">No Internet Connection Detected</strong></span
        >.
        <span i18n="@@app.NetworkStatus.appNotWork">Some parts of the app may not work as expected</span>
      </div>
    </div>
  `,
})
export class NavigatorOnLineComponent {
  online$ = this.navigatorOnLineService.online$;

  constructor(private navigatorOnLineService: NavigatorOnLineService) {}
}
