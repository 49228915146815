import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EpisodeOfCareStatusHistory } from '@hl7fhir';
import { PeriodTypePipe } from '@hl7fhir/data-types';
import { EpisodeOfCareStatusPipe } from '@hl7fhir/value-sets';

export class EpisodeOfCareStatusHistoryViewModel extends BackboneElementViewModel<EpisodeOfCareStatusHistory> {
  get status(): string | undefined {
    return this.element?.status && new EpisodeOfCareStatusPipe().transform(this.element?.status);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element?.period);
  }
}
