import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentReferenceStatus',
})
export class DocumentReferenceStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'current':
        return $localize`:@@fhir.document-reference-status.current:Current`;
      case 'superseded':
        return $localize`:@@fhir.document-reference-status.superseded:Superseded`;
      case 'entered-in-error':
        return $localize`:@@fhir.document-reference-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
