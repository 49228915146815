import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const LevensovertuigingCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.7.5.1--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '160542002': {
        'nl-NL': 'Atheïst',
        display: 'Atheist (person)',
      },
      '160567004': {
        'nl-NL': 'Agnost',
        display: 'Agnostic (person)',
      },
      '309687009': {
        'nl-NL': 'Boeddhist',
        display: 'Buddhist, follower of religion (person)',
      },
      '271448006': {
        'nl-NL': 'Anglicaan',
        display: 'Anglican, follower of religion (person)',
      },
      '80587008': {
        'nl-NL': "Jehova''s getuige",
        display: "Jehovah''s Witness, follower of religion (person)",
      },
      '276119007': {
        'nl-NL': 'Non-conformist',
        display: 'Nonconformist (person)',
      },
      '160549006': {
        'nl-NL': 'Christen',
        display: 'Christian, follower of religion (person)',
      },
      '309885004': {
        'nl-NL': 'Protestant',
        display: 'Protestant, follower of religion (person)',
      },
      '160540005': {
        'nl-NL': 'Katholiek',
        display: 'Roman Catholic, follower of religion (person)',
      },
      '160234004': {
        'nl-NL': 'Heilsoldaat',
        display: 'Salvation Army member (person)',
      },
      '428821008': {
        'nl-NL': 'Zevendedagsadventist',
        display: 'Seventh Day Adventist, follower of religion (person)',
      },
      '160545000': {
        'nl-NL': 'Hindoe',
        display: 'Hindu, follower of religion (person)',
      },
      '160543007': {
        'nl-NL': 'Jood',
        display: 'Jewish, follower of religion (person)',
      },
      '309884000': {
        'nl-NL': 'Moslim',
        display: 'Muslim, follower of religion (person)',
      },
    },
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Anders',
        display: 'Anders',
      },
    },
    'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4': {
      GeenGeloof: {
        'nl-NL': 'Geen geloof',
        display: 'Geen geloof',
      },
      Humanist: {
        'nl-NL': 'Humanist',
        display: 'Humanist',
      },
      Mormoon: {
        'nl-NL': 'Mormoon',
        display: 'Mormoon',
      },
    },
  },
};
