import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appointmentStatus',
})
export class AppointmentStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'proposed':
        return $localize`:@@fhir.appointment-status.proposed:Proposed`;
      case 'pending':
        return $localize`:@@fhir.appointment-status.pending:Pending`;
      case 'booked':
        return $localize`:@@fhir.appointment-status.booked:Booked`;
      case 'arrived':
        return $localize`:@@fhir.appointment-status.arrived:Arrived`;
      case 'fulfilled':
        return $localize`:@@fhir.appointment-status.fulfilled:Fulfilled`;
      case 'cancelled':
        return $localize`:@@fhir.appointment-status.cancelled:Cancelled`;
      case 'noshow':
        return $localize`:@@fhir.appointment-status.noshow:No Show`;
      case 'entered-in-error':
        return $localize`:@@fhir.appointment-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
