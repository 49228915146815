import { NgModule } from '@angular/core';
import { OrderByPipe } from './pipes/order-by.pipe';

const pipes = [OrderByPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class OrderByModule {}
