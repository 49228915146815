import { Pipe, PipeTransform } from '@angular/core';

// TODO Pipe is not used yet.
@Pipe({
  name: 'contactEntityType',
})
export class ContactEntityTypePipe implements PipeTransform {
  transform(type: ('bill' | 'admin' | 'hr' | 'payor' | 'patinf' | 'press') | undefined): string {
    switch (type) {
      case 'bill':
        return $localize`:@@fhir.contact-entity-type.bill:Billing`;
      case 'admin':
        return $localize`:@@fhir.contact-entity-type.admin:Administrative`;
      case 'hr':
        return $localize`:@@fhir.contact-entity-type.hr:Human Resource`;
      case 'payor':
        return $localize`:@@fhir.contact-entity-type.payor:Payor`;
      case 'patinf':
        return $localize`:@@fhir.contact-entity-type.patinf:Patient`;
      case 'press':
        return $localize`:@@fhir.contact-entity-type.press:Press`;
      default:
        return '';
    }
  }
}
