import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const RolCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.3.1.2--20171231000000',
  systems: {
    'urn:oid:2.16.840.1.113883.2.4.3.11.22.472': {
      '1': {
        'nl-NL': 'Eerste relatie/contactpersoon',
        display: 'Eerste relatie/contactpersoon',
      },
      '2': {
        'nl-NL': 'Tweede relatie/contactpersoon',
        display: 'Tweede relatie/contactpersoon',
      },
      '3': {
        'nl-NL': 'Curator (juridisch)',
        display: 'Curator (juridisch)',
      },
      '4': {
        'nl-NL': 'Financieel (gemachtigd)',
        display: 'Financieel (gemachtigd)',
      },
      '5': {
        'nl-NL': 'Financieel (toetsing)',
        display: 'Financieel (toetsing)',
      },
      '6': {
        'nl-NL': 'Leefeenheid',
        display: 'Leefeenheid',
      },
      '7': {
        'nl-NL': 'Hulpverlener',
        display: 'Hulpverlener',
      },
      '9': {
        'nl-NL': 'Anders',
        display: 'Anders',
      },
      '11': {
        'nl-NL': 'Voogd',
        display: 'Voogd',
      },
      '14': {
        'nl-NL': 'Bewindvoerder',
        display: 'Bewindvoerder',
      },
      '15': {
        'nl-NL': 'Mentor',
        display: 'Mentor',
      },
      '19': {
        'nl-NL': 'Buur',
        display: 'Buur',
      },
      '20': {
        'nl-NL': 'Vriend(in)/kennis',
        display: 'Vriend(in)/kennis',
      },
      '21': {
        'nl-NL': 'Cliëntondersteuner',
        display: 'Cliëntondersteuner',
      },
      '23': {
        'nl-NL': 'Contactpersoon',
        display: 'Contactpersoon',
      },
      '24': {
        'nl-NL': 'Wettelijke vertegenwoordiger',
        display: 'Wettelijke vertegenwoordiger',
      },
    },
  },
};
