import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { ProcedurePerformer } from '@hl7fhir';
import { getReference } from '@hl7fhir/foundation';

export class ProcedurePerformerViewModel extends BackboneElementViewModel<ProcedurePerformer> {
  get actor(): string | undefined {
    return getReference(this.element?.actor);
  }

  get onBehalfOf(): string | undefined {
    return getReference(this.element?.onBehalfOf);
  }
}
