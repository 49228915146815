import { Injectable } from '@angular/core';

export interface AccordionView {
  expandAll(): void;
  collapseAll(): void;
  expand(id: string): void;
}

@Injectable({
  providedIn: 'root',
})
export class AccordionViewService {
  private readonly views: Array<AccordionView> = [];
  add(details: AccordionView) {
    this.views.push(details);
  }

  remove(details: AccordionView) {
    const index = this.views.indexOf(details);
    if (index !== -1) {
      this.views.splice(index, 1);
    }
  }

  collapseAll() {
    for (const view of this.views) {
      view.collapseAll();
    }
  }

  expandAll() {
    for (const view of this.views) {
      view.expandAll();
    }
  }

  expand(id: string) {
    for (const view of this.views) {
      view.expand(id);
    }
  }
}
