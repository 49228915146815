import { NgModule } from '@angular/core';
import { ExtensibilityModule } from '@hl7fhir/extensibility';
import { SharedModule } from '@shared';
import { CodeableConceptModule } from '../codeable-concept';
import { AddressComponent } from './address.component';
import { AddressesComponent } from './addresses.component';
import { LinePipe as AddressLinePipe } from './pipes/address-line.pipe';
import { CountryCodeToNamePipe } from './pipes/country-code-to-name.pipe';
import { CodeModule } from '../code';

const components = [AddressComponent, AddressesComponent];
const pipes = [AddressLinePipe, CountryCodeToNamePipe];

@NgModule({
  imports: [SharedModule, CodeModule, ExtensibilityModule, CodeableConceptModule],
  declarations: [...components, ...pipes],
  exports: [...components],
})
export class AddressModule {}
