import { Patient } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectPatientId(patient: Patient): string {
  // The only time that a resource does not have an id is when it is being submitted to the server using a create operation.
  // https://www.hl7.org/fhir/resource-definitions.html#Resource.id
  if (!patient.id) throw new Error('The patient is not created yet.');
  return patient.id;
}

export function sortById(a: Patient, b: Patient): number {
  return selectPatientId(a).localeCompare(selectPatientId(b));
}

export type State = EntityState<Patient>;

export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>({
  selectId: selectPatientId,
  sortComparer: sortById, // Unsure what to sort it on, but for now at least keep the order consistent by sorting on its id
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { patients }) => {
    return adapter.upsertMany(patients, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of patient ids
export const selectPatientIds = selectIds;

// select the dictionary of patient entities
export const selectPatientEntities = selectEntities;

// select the array of patients
export const selectAllPatients = selectAll;

// select the total patient count
export const selectPatientTotal = selectTotal;
