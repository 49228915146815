import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EncounterHospitalization } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';

export class EncounterHospitalizationViewModel extends BackboneElementViewModel<EncounterHospitalization> {
  get admitSource(): string | undefined {
    return this.element?.admitSource && new CodeableConceptPipe().transform(this.element.admitSource);
  }

  get destination(): string | undefined {
    return this.element?.destination && getReference(this.element.destination);
  }

  get dietPreference(): string | undefined {
    return this.element?.dietPreference && new CodeableConceptPipe().transform(this.element.dietPreference);
  }

  get dischargeDisposition(): string | undefined {
    return this.element?.dischargeDisposition && new CodeableConceptPipe().transform(this.element.dischargeDisposition);
  }

  get origin(): string | undefined {
    return this.element?.origin && getReference(this.element.origin);
  }

  get preAdmissionIdentifier(): IdentifierViewModel | undefined {
    return (
      this.element?.preAdmissionIdentifier &&
      new IdentifierViewModel(this.element.preAdmissionIdentifier, this.fhirVersion)
    );
  }

  get reAdmission(): string | undefined {
    return this.element?.reAdmission && new CodeableConceptPipe().transform(this.element.reAdmission);
  }

  get specialArrangement(): string | undefined {
    return this.element?.specialArrangement && new CodeableConceptPipe().transform(this.element.specialArrangement);
  }

  get specialCourtesy(): string | undefined {
    return this.element?.specialCourtesy && new CodeableConceptPipe().transform(this.element.specialCourtesy);
  }
}
