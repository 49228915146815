<div style="max-width: 600px;"
     class="text-center align-items-center gap-3 flex-column flex-sm-row"
     [class.d-flex]="horizontal">
  <div class="medmij-logo mx-auto d-inline-block mb-1"
       [class.order-2]="horizontal"></div>
  <h5 class="fw-bold"><span i18n="@@app.trustBanner.title">Trusted & Certified by</span>&nbsp;<span
          *ngIf="!horizontal">MedMij</span></h5>
  <p class="m-0"
     *ngIf="showDescription">
    <small i18n="@@app.trustBanner.text">As one of the select few MedMij certified companies,
      we ensure private and secure access to your health records.</small>
  </p>
</div>