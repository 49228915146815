import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { Coverage, Extension, Identifier } from '@hl7fhir';

import { CodeableConceptPipe, IdentifierViewModel, PeriodTypePipe } from '@hl7fhir/data-types';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { CoverageStatusPipe } from '@hl7fhir/value-sets';
import { VerzekeringssoortCodelijst } from '@hl7nl-fhir/value-sets';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { CoveragePayorViewModel } from './coverage-payor.viewmodel';
import { ResourceTypes } from '@globals';

export class CoverageViewModel extends DomainResourceViewModel<Coverage> {
  get beneficiary(): string | undefined {
    return getReference(this.resource?.beneficiary);
  }

  get contract(): string | undefined {
    return getReferences(this.resource?.contract);
  }

  get dependent(): string | undefined {
    return this.resource?.dependent;
  }

  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map(
      (identifier: Identifier) => new IdentifierViewModel(identifier, this.fhirVersion)
    );
  }

  get network(): string | undefined {
    return this.resource?.network;
  }

  get order(): string | undefined {
    return this.resource?.order?.toString();
  }

  get policyHolder(): string | undefined {
    return getReference(this.resource?.policyHolder);
  }

  get relationship(): string | undefined {
    return this.resource?.relationship && new CodeableConceptPipe().transform(this.resource?.relationship);
  }

  get status(): string | undefined {
    return this.resource?.status && new CoverageStatusPipe().transform(this.resource?.status);
  }

  get subscriber(): string | undefined {
    return getReference(this.resource?.subscriber);
  }

  get subscriberId(): IdentifierViewModel[] | string | undefined {
    if (this.fhirVersion === 'R5') {
      const resource = this.resource as r5.Coverage;
      return resource.subscriberId?.map(
        (identifier: Identifier) => new IdentifierViewModel(identifier, this.fhirVersion)
      );
    }

    const resource = this.resource as r3.Coverage | r4.Coverage | r4b.Coverage | undefined;
    return resource?.subscriberId;
  }

  get type(): string | undefined {
    return (
      this.resource?.type && new CodeableConceptPipe().transform(this.resource?.type, [VerzekeringssoortCodelijst])
    );
  }

  get period(): string | undefined {
    return this.resource?.period && new PeriodTypePipe().transform(this.resource?.period);
  }

  // Only R3
  get sequence(): string | undefined {
    const resource = this.resource as r3.Coverage | undefined;

    return resource?.sequence;
  }

  // Only R3
  get payor(): string | undefined {
    const resource = this.resource as r3.Coverage | undefined;
    return resource && getReferences(resource?.payor);
  }

  get isPayorOrganization(): boolean {
    return this.isPayor(ResourceTypes.organization);
  }

  get isPayorPatient(): boolean {
    return this.isPayor(ResourceTypes.patient);
  }

  get bankInformation(): CoveragePayorViewModel | undefined {
    const resource = this.resource as r3.Coverage | undefined;

    if (!resource?.payor || resource.payor.length === 0) {
      return undefined;
    }

    // Payor should only be one according to the Nictiz profile
    // http://nictiz.nl/fhir/StructureDefinition/zib-Payer
    const payor = resource.payor[0];

    const bankInformationExtensions: Extension | undefined = new ExtensionPipe().transform(
      payor.extension,
      StructureDefinition.Nictiz.PAYER.bankInformation
    );

    if (bankInformationExtensions) {
      return new CoveragePayorViewModel(payor, this.fhirVersion);
    }

    return undefined;
  }

  private isPayor(type: ResourceTypes): boolean {
    const resource = this.resource as r3.Coverage | undefined;
    return resource?.payor?.some((payor) => payor.reference?.toLowerCase().startsWith(type.toLowerCase())) ?? false;
  }
}
