import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'narrativeStatus',
})
export class NarrativeStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'generated':
        return $localize`:@@fhir.narrative-status.generated:Generated`;
      case 'extensions':
        return $localize`:@@fhir.narrative-status.extensions:Extensions`;
      case 'additional':
        return $localize`:@@fhir.narrative-status.additional:Additional`;
      case 'empty':
        return $localize`:@@fhir.narrative-status.empty:Empty`;
      default:
        return '';
    }
  }
}
