import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '@layout';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-account-warning',
  standalone: true,
  imports: [MatIconModule, ButtonComponent],
  template: `
    <div data-cy="account-deletion-warning-modal" class="modal-header border-0 p-4">
      <h4 class="modal-title fw-bolder" i18n="@@app.delete-account.header">Delete you digi.me account?</h4>
    </div>
    <div class="modal-body border-0 px-4 py-0">
      <p i18n="@@app.delete-account.warning">
        This will remove any imported records, remove links to your sources, and delete your digi.me account.
      </p>
    </div>
    <div class="modal-footer border-0 justify-content-between p-4">
      <app-button
        type="secondary"
        i18n-label="@@app.delete-account.cancel"
        (clicked)="activeModal.close('Close')"
        label="Cancel"
      ></app-button>
      <app-button
        type="destructive"
        icon="delete_forever"
        (clicked)="activeModal.close('Delete')"
        i18n-label="@@app.delete-account.delete"
        label="Delete my account"
      ></app-button>
    </div>
  `,
})
export class DeleteAccountWarningModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
