import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allergyIntoleranceType',
})
export class AllergyIntoleranceTypePipe implements PipeTransform {
  transform(status: ('allergy' | 'intolerance') | undefined): string {
    switch (status) {
      case 'allergy':
        return $localize`:@@fhir.allergy-intolerance-type.allergy:Allergy`;
      case 'intolerance':
        return $localize`:@@fhir.allergy-intolerance-type.intolerance:Intolerance`;
      default:
        return '';
    }
  }
}
