import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EncounterStatusHistory } from '@hl7fhir';
import { PeriodTypePipe } from '@hl7fhir/data-types';
import { EncounterStatusPipe } from '@hl7fhir/value-sets';

export class EncounterStatusHistoryViewModel extends BackboneElementViewModel<EncounterStatusHistory> {
  get status(): string | undefined {
    return this.element?.status && new EncounterStatusPipe().transform(this.element.status);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element.period);
  }
}
