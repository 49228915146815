/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from '@environments/environment';
import { Platform } from './digi-me-event-enums.model';

/**
 * Model representing the event properties for analytics
 */
export class MixpanelEventProperties {
  /**
   * The version of the app used by the end-user
   */
  app_version = environment.version;

  /**
   * Application name “digi.me“`
   */
  app_name = environment.appName;

  /**
   * A string denoting the platform the app was used on
   */
  platform? = getPlatform();

  /**
   * The user_agent string such as Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36
   */
  user_agent: string = navigator.userAgent;

  /**
   * The user’s operating system such as: Mac OS X
   */
  $os: string | undefined = getOperatingSystem();

  /**
   * The version of the user’s operating system such as: 14.7.1
   */
  os_version: string | undefined = getOsVersion();

  /**
   * When the platform is web, the browser type used such as: Chrome
   */
  $browser: string | undefined = getBrowserType();
  /**
   * When the platform is web, the browser version used such as: 15.1
   */
  $browser_version: string | undefined = getBrowserVersion();

  /**
   * The screen resolution of the user’s device such as: 1390x860
   */
  screen_resolution: string = getScreenResolution();

  $screen_height: number = window.screen.height;
  $screen_width: number = window.screen.width;
}

function getPlatform(): Platform {
  if (getOperatingSystem().toLowerCase() === Platform.iOS) {
    return Platform.iOS;
  }
  if (getOperatingSystem().toLowerCase() === Platform.android) {
    return Platform.android;
  }
  return Platform.web;
}

function getBrowserType(): string | undefined {
  const userAgent = navigator.userAgent?.toLowerCase();

  if (userAgent.includes('chrome') && !userAgent.includes('edg')) {
    return 'Chrome';
  }
  if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
    return 'Safari';
  }
  if (userAgent.includes('firefox')) {
    return 'Firefox';
  }
  if (userAgent.includes('edg')) {
    return 'Edge';
  }
  if (userAgent.includes('opera') || userAgent.includes('opr')) {
    return 'Opera';
  }
  if (userAgent.includes('msie') || userAgent.includes('trident')) {
    return 'Internet Explorer';
  }

  return undefined;
}

function getBrowserVersion(): string {
  const userAgent = navigator.userAgent;
  let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  let temp;

  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return 'IE ' + (temp[1] || '');
  }

  if (match[1] === 'Chrome') {
    temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (temp != null) return temp.slice(1).join(' ').replace('OPR', 'Opera');
  }

  match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((temp = userAgent.match(/version\/(\d+)/i)) != null) match.splice(1, 1, temp[1]);

  return match.join(' ');
}

function getScreenResolution(): string {
  const width = window.screen.width;
  const height = window.screen.height;
  return `${width}x${height}`;
}

function getOperatingSystem(): string {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function getOsVersion(): string | undefined {
  return undefined;
}

export function getDistinctId(): string {
  let distinctId = localStorage.getItem('distinct_id');
  if (!distinctId) {
    distinctId = crypto.randomUUID();
    localStorage.setItem('distinct_id', distinctId);
  }
  return distinctId;
}

export function changeDistinctId(distinctId: string): void {
  const storageDistinctId = localStorage.getItem('distinct_id');
  if (storageDistinctId !== distinctId) {
    localStorage.setItem('distinct_id', distinctId);
  }
}
