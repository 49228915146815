import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { ReferenceComponent } from './reference/reference.component';
import { ReferencesComponent } from './references.component';

const components = [ReferenceComponent, ReferencesComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  exports: [...components],
})
export class ReferencesModule {}
