import { TimingRepeat } from '@hl7fhir';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import { UnitPipe, DayOfWeekPipe } from './timing-repeat';
import { JoinPipe } from '@shared';
import { DurationPipe } from '../duration';
import { RangePipe } from '../range';
import { PeriodTypePipe } from '../period';

export class TimingRepeatViewModel extends ElementViewModel<TimingRepeat> {
  get boundsDuration(): string | undefined {
    return new DurationPipe().transform(this.element?.boundsDuration);
  }

  get boundsRange(): string | undefined {
    return new RangePipe().transform(this.element?.boundsRange);
  }

  get boundsPeriod(): string | undefined {
    return new PeriodTypePipe().transform(this.element?.boundsPeriod);
  }

  get count(): string | undefined {
    return this.element?.count?.toString();
  }

  get countMax(): string | undefined {
    return this.element?.countMax?.toString();
  }

  get duration(): string | undefined {
    return this.element?.duration?.toString();
  }

  get durationMax(): string | undefined {
    return this.element?.durationMax?.toString();
  }

  get durationUnit(): string | undefined {
    return new UnitPipe().transform(this.element?.durationUnit);
  }

  get frequency(): string | undefined {
    return this.element?.frequency?.toString();
  }

  get frequencyMax(): string | undefined {
    return this.element?.frequencyMax?.toString();
  }

  get offset(): string | undefined {
    return this.element?.offset?.toString();
  }

  get period(): string | undefined {
    return this.element?.period?.toString();
  }

  get periodMax(): string | undefined {
    return this.element?.periodMax?.toString();
  }

  get periodUnit(): string | undefined {
    return new UnitPipe().transform(this.element?.periodUnit);
  }

  get timeOfDay(): string | undefined {
    return new JoinPipe().transform(this.element?.timeOfDay, ', ');
  }

  get dayOfWeek(): string | undefined {
    return new DayOfWeekPipe().transform(this.element?.dayOfWeek);
  }

  get when(): string | undefined {
    return new JoinPipe().transform(this.element?.when, ', ');
  }
}
