import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entryType',
})
export class EntryTypePipe implements PipeTransform {
  transform(entryType: string | undefined): string {
    switch (entryType) {
      case 'barcode':
        return $localize`:@@fhir.device-udi-entry-type.barcode:Barcode`;
      case 'rfid':
        return $localize`:@@fhir.device-udi-entry-type.rfid:RFID`;
      case 'manual':
        return $localize`:@@fhir.device-udi-entry-type.manual:Manual`;
      case 'card':
        return $localize`:@@fhir.device-udi-entry-type.card:Card`;
      case 'self-reported':
        return $localize`:@@fhir.device-udi-entry-type.self-reported:Self Reported`;
      case 'unknown':
        return $localize`:@@fhir.device-udi-entry-type.unknown:Unknown`;
      default:
        return '';
    }
  }
}
