import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'administrativeGender',
})
export class AdministrativeGenderPipe implements PipeTransform {
  transform(gender: ('male' | 'female' | 'other' | 'unknown') | undefined): string {
    switch (gender) {
      case 'female':
        return $localize`:@@fhir.administrative-gender.female:Female`;
      case 'male':
        return $localize`:@@fhir.administrative-gender.male:Male`;
      case 'other':
        return $localize`:@@fhir.administrative-gender.other:Other`;
      case 'unknown':
        return $localize`:@@fhir.administrative-gender.unknown:Unknown`;
      default:
        return '';
    }
  }
}
