import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const FlagPriorityCodes: CodeSystemFlattened = {
  id: 'flag-priority',
  codes: {
    PN: {
      display: 'No alarm',
    },
    PL: {
      display: 'Low priority',
    },
    PM: {
      display: 'Medium priority',
    },
    PH: {
      display: 'High priority',
    },
  },
};
