export type Device = 'Desktop' | 'Tablet' | 'Mobile';

export function checkScreenType(innerWidth?: number): Device | undefined {
  const screenWidth = innerWidth ?? window.innerWidth;

  // ! TODO: Compare with bootstrap breakpoints
  if (screenWidth >= 992) return 'Desktop';
  if (screenWidth >= 576 && screenWidth < 992) return 'Tablet';
  if (screenWidth < 576) return 'Mobile';

  return undefined;
}
