import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContactPoint } from '@hl7fhir';

@Component({
  selector: 'app-contact-points',
  templateUrl: './contact-points.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPointsComponent {
  @Input() contactPoints: ContactPoint[] | undefined;
}
