import { createReducer, on } from '@ngrx/store';
import { SET_SORT_BY } from './document-view.action';

export type SortDirection = 'asc' | 'desc' | string | undefined;

export interface DigiMeSortState {
  sortBy: string | undefined;
  sortDirection: SortDirection | undefined;
}

export const initialState: DigiMeSortState = { sortBy: undefined, sortDirection: undefined };

export const reducer = createReducer(
  initialState,
  on(SET_SORT_BY.updateSortBy, (state, action): DigiMeSortState => {
    return { ...state, sortBy: action.sortBy, sortDirection: action.sortDirection };
  })
);
