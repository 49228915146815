import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AccordionViewService } from '@core/services';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MenuItem } from '../menu-item.model';
import { MenuService } from '../menu.service';
import { SideMenuComponent } from './side-menu.component';

@Component({
  selector: 'app-side-menu-container',
  templateUrl: './side-menu.container.html',
  standalone: true,
  imports: [SharedModule, NgbModalModule, SideMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuContainerComponent implements OnDestroy, AfterViewInit {
  subMenu$: Observable<MenuItem[] | undefined>;
  readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private menuService: MenuService,
    private resourceViewService: AccordionViewService
  ) {
    this.subMenu$ = this.menuService.subMenu$;
  }

  ngAfterViewInit(): void {
    this.menuService.currentMenuItems$.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.resourceViewService.collapseAll();
      if (items) {
        items.forEach((item) => {
          this.resourceViewService.expand(item.header ?? '');
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
