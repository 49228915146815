import { Procedure } from '@hl7fhir';
import { ProcedureViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromProcedure from './procedure.reducer';

export const selectProcedureState = createFeatureSelector<fromProcedure.State>(features.FHIR.procedures);

export const selectProcedureEntities = createSelector(selectProcedureState, fromProcedure.selectProcedureEntities);

export const selectAllProcedures = createSelector(selectProcedureState, fromProcedure.selectAllProcedures);

export const selectProcedureById = createSelector(
  selectRouteParams,
  selectProcedureEntities,
  ({ id }, procedures: Dictionary<Procedure>) =>
    id && (procedures[id] ?? procedures[id.toLowerCase()] ?? procedures[id.toUpperCase()])
);

export const selectProcedureViewModels = createSelector(selectAllProcedures, (dataSource: Procedure[]) =>
  dataSource.map((item) => new ProcedureViewModel(item))
);

export const selectProcedureViewModelById = createSelector(
  selectProcedureById,
  (entity: Procedure | undefined) => entity && new ProcedureViewModel(entity)
);
