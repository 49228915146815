import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemLanguageAbilityMode: CodeSystemFlattened = {
  id: 'v3-LanguageAbilityMode',
  codes: {
    ESGN: {
      display: 'Expressed signed',
    },
    ESP: {
      display: 'Expressed spoken',
    },
    EWR: {
      display: 'Expressed written',
    },
    RSGN: {
      display: 'Received signed',
    },
    RSP: {
      display: 'Received spoken',
    },
    RWR: {
      display: 'Received written',
    },
  },
};
