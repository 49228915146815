import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicationStatementTaken',
})
export class MedicationStatementTakenPipe implements PipeTransform {
  transform(value: string | undefined): string {
    switch (value) {
      case 'y':
        return $localize`:@@fhir.medication-statement-taken.y:Yes`;
      case 'n':
        return $localize`:@@fhir.medication-statement-taken.n:No`;
      case 'unk':
        return $localize`:@@fhir.medication-statement-taken.unk:Unknown`;
      case 'na':
        return $localize`:@@fhir.medication-statement-taken.na:Not Applicable`;
      default:
        return '';
    }
  }
}
