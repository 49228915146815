import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { Identifier, Organization } from '@hl7fhir';
import { AddressViewModel, CodeableConceptPipe, ContactPointViewModel, IdentifierViewModel } from '@hl7fhir/data-types';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { OrganizationContactViewModel } from './organization-contact.viewmodel';

export class OrganizationViewModel extends DomainResourceViewModel<Organization> {
  get type(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.type);
  }

  get identifier(): Identifier[] | undefined {
    return this.resource?.identifier;
  }

  get name(): string | undefined {
    return this.resource?.name;
  }

  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((item) => new IdentifierViewModel(item, this.fhirVersion));
  }

  get addresses(): AddressViewModel[] | undefined {
    const organization = this.resource as r3.Organization | r4.Organization | r4b.Organization | undefined;
    return organization?.address?.map((item) => new AddressViewModel(item, this.fhirVersion));
  }

  get contactPoints(): ContactPointViewModel[] | undefined {
    const organization = this.resource as r3.Organization | r4.Organization | r4b.Organization | undefined;
    return organization?.telecom?.map((item) => new ContactPointViewModel(item, this.fhirVersion));
  }

  get contacts(): OrganizationContactViewModel[] | undefined {
    const organization = this.resource as r3.Organization | r4.Organization | r4b.Organization | undefined;
    return organization?.contact?.map((item) => new OrganizationContactViewModel(item, this.fhirVersion));
  }
}
