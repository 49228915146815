import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encounterStatus',
})
export class EncounterStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'planned':
        return $localize`:@@fhir.encounter-status.planned:Planned`;
      case 'arrived':
        return $localize`:@@fhir.encounter-status.arrived:Arrived`;
      case 'triaged':
        return $localize`:@@fhir.encounter-status.triaged:Triaged`;
      case 'in-progress':
        return $localize`:@@fhir.encounter-status.in-progress:In Progress`;
      case 'onleave':
        return $localize`:@@fhir.encounter-status.onleave:On Leave`;
      case 'finished':
        return $localize`:@@fhir.encounter-status.finished:Finished`;
      case 'cancelled':
        return $localize`:@@fhir.encounter-status.cancelled:Cancelled`;
      case 'entered-in-error':
        return $localize`:@@fhir.encounter-status.entered-in-error:Entered in Error`;
      case 'unknown':
        return $localize`:@@fhir.encounter-status.unknown:Unknown`;
      default:
        return '';
    }
  }
}
