import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { MedicationRequestDispenseRequest } from '@hl7fhir';
import { DurationPipe, PeriodTypePipe, QuantityTypePipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class MedicationRequestDispenseRequestViewModel extends BackboneElementViewModel<MedicationRequestDispenseRequest> {
  get validityPeriod(): string | undefined {
    return this.element?.validityPeriod && new PeriodTypePipe().transform(this.element.validityPeriod);
  }

  get numberOfRepeatsAllowed(): string | undefined {
    if (!this.element?.numberOfRepeatsAllowed) {
      return undefined;
    }
    return this.element.numberOfRepeatsAllowed.toString();
  }

  get quantity(): string | undefined {
    return this.element?.quantity && new QuantityTypePipe().transform(this.element.quantity);
  }

  get expectedSupplyDuration(): string | undefined {
    return this.element?.expectedSupplyDuration && new DurationPipe().transform(this.element.expectedSupplyDuration);
  }

  get performer(): string | undefined {
    const elementR = this.element as
      | r3.MedicationRequestDispenseRequest
      | r4.MedicationRequestDispenseRequest
      | r4b.MedicationRequestDispenseRequest
      | undefined;
    return elementR && getReference(elementR.performer);
  }
}
