import { CodeableConcept, Coding, CompositionSection, Extension } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { CodeableConceptPipe, NarrativeViewModel, getNarrative } from '@hl7fhir/data-types';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { CodeSystemPipe, CompositionSectionModePipe } from '@hl7fhir/value-sets';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import { SelectPipe } from '@shared';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class CompositionSectionViewModel extends ElementViewModel<CompositionSection> {
  get title(): string | undefined {
    return this.element?.title;
  }

  get icpcCode(): string | undefined {
    const extensions: Extension[] | undefined = this.element?.extension;
    const extension: Extension | undefined = new ExtensionPipe().transform(
      extensions,
      StructureDefinition.Nictiz.CODE.icpc1Nl
    );

    const value: CodeableConcept | undefined = new SelectPipe().transform(extension, 'valueCodeableConcept');
    const codeSystem: Coding | undefined = new CodeSystemPipe().transform(value, CodeSystems.ICPC_1_NL);
    return codeSystem?.code;
  }

  get icpcDescription(): string | undefined {
    const extensions: Extension[] | undefined = this.element?.extension;
    const extension: Extension | undefined = new ExtensionPipe().transform(
      extensions,
      StructureDefinition.Nictiz.CODE.icpc1Nl
    );

    const value: CodeableConcept | undefined = new SelectPipe().transform(extension, 'valueCodeableConcept');
    const codeSystem: Coding | undefined = new CodeSystemPipe().transform(value, CodeSystems.ICPC_1_NL);
    return codeSystem?.display;
  }

  get code(): string | undefined {
    return this.element?.code && new CodeableConceptPipe().transform(this.element.code);
  }

  get text(): string | undefined {
    return this.element?.text && getNarrative(new NarrativeViewModel(this.element.text, this.fhirVersion));
  }

  //R3, R4, R4b only
  get mode(): string | undefined {
    const compositionSection = this.element as r3.CompositionSection | r4.CompositionSection | r4b.CompositionSection;
    return compositionSection?.mode && new CompositionSectionModePipe().transform(compositionSection.mode);
  }

  get orderedBy(): string | undefined {
    return this.element?.orderedBy && new CodeableConceptPipe().transform(this.element.orderedBy);
  }

  get entry(): string | undefined {
    return this.element?.entry && getReferences(this.element.entry);
  }

  get emptyReason(): string | undefined {
    return this.element?.emptyReason && new CodeableConceptPipe().transform(this.element.emptyReason);
  }

  get subsections(): CompositionSectionViewModel[] | undefined {
    return (
      this.element?.section &&
      this.element.section.map((section) => new CompositionSectionViewModel(section, this.fhirVersion))
    );
  }
}
