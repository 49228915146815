import { DigiMeFile } from '@digi.me/models';
import { ResourceTypes } from '@globals';
import {
  AllergyIntolerance,
  Appointment,
  Binary,
  Composition,
  Condition,
  Consent,
  Coverage,
  Device,
  DeviceUseStatement,
  DiagnosticReport,
  DocumentManifest,
  DocumentReference,
  Encounter,
  EpisodeOfCare,
  Flag,
  HealthcareService,
  ImagingStudy,
  Immunization,
  ImmunizationRecommendation,
  Medication,
  MedicationDispense,
  MedicationRequest,
  MedicationStatement,
  NutritionOrder,
  Observation,
  Organization,
  Patient,
  Practitioner,
  PractitionerRole,
  Procedure,
  Resource,
  Specimen,
} from '@hl7fhir';
import { FhirVersion, getProfileForVersion } from '@hl7fhir/foundation';
import { createActionGroup, props } from '@ngrx/store';

export const DIGI_ME_FHIR_ACTIONS = createActionGroup({
  source: 'Digi.me Api',
  events: {
    'fhir Loaded': props<{
      fileName: string;
      patients: Patient[];
      coverages: Coverage[];
      organizations: Organization[];
      practitioners: Practitioner[];
      practitionerRoles: PractitionerRole[];
      healthcareServices: HealthcareService[];
      observations: Observation[];
      allergyIntolerances: AllergyIntolerance[];
      conditions: Condition[];
      immunizations: Immunization[];
      immunizationRecommendations: ImmunizationRecommendation[];
      diagnosticReports: DiagnosticReport[];
      specimens: Specimen[];
      consents: Consent[];
      encounters: Encounter[];
      nutritionOrders: NutritionOrder[];
      medicationRequests: MedicationRequest[];
      medicationStatements: MedicationStatement[];
      medicationDispenses: MedicationDispense[];
      devices: Device[];
      procedures: Procedure[];
      flags: Flag[];
      medications: Medication[];
      compositions: Composition[];
      binaries: Binary[];
      episodesOfCare: EpisodeOfCare[];
      deviceUseStatements: DeviceUseStatement[];
      imagingStudies: ImagingStudy[];
      appointments: Appointment[];
      documentReferences: DocumentReference[];
      documentManifests: DocumentManifest[];
      triggerSourceId: string;
    }>(),
  },
});

export function createFhirActionFromDigiMeFiles(file: DigiMeFile, sourceId?: string | undefined) {
  const patients: Patient[] = [];
  const coverages: Coverage[] = [];
  const organizations: Organization[] = [];
  const practitioners: Practitioner[] = [];
  const practitionerRoles: PractitionerRole[] = [];
  const healthcareServices: HealthcareService[] = [];
  // Get manually added measurements from local storage
  const observations: Observation[] = [...JSON.parse(localStorage.getItem('digi-me-observations') ?? '[]')];
  const allergyIntolerances: AllergyIntolerance[] = [];
  const conditions: Condition[] = [];
  const encounters: Encounter[] = [];
  const immunizations: Immunization[] = [];
  const immunizationRecommendations: ImmunizationRecommendation[] = [];
  const diagnosticReports: DiagnosticReport[] = [];
  const specimens: Specimen[] = [];
  const consents: Consent[] = [];
  const nutritionOrders: NutritionOrder[] = [];
  const medicationRequests: MedicationRequest[] = [];
  const medicationStatements: MedicationStatement[] = [];
  const medicationDispenses: MedicationDispense[] = [];
  const devices: Device[] = [];
  const procedures: Procedure[] = [];
  const flags: Flag[] = [];
  const medications: Medication[] = [];
  const compositions: Composition[] = [];
  const episodesOfCare: EpisodeOfCare[] = [];
  const deviceUseStatements: DeviceUseStatement[] = [];
  const imagingStudies: ImagingStudy[] = [];
  const appointments: Appointment[] = [];
  const documentReferences: DocumentReference[] = [];
  const documentManifests: DocumentManifest[] = [];
  const binaries: Binary[] = [];

  const triggerSourceId = sourceId ?? '';

  const resources = file.metadata.serviceGroup == 'medical' ? file.content : [];

  for (const resource of resources) {
    switch (resource.resourceType) {
      case ResourceTypes.patient: {
        const patient = resource as Patient;
        // Force R3
        pushFhirVersionProfile(patient, 'R3', 'Patient');
        patients.push(patient);
        break;
      }
      case ResourceTypes.coverage: {
        const coverage = resource as Coverage;
        // Force R3
        pushFhirVersionProfile(coverage, 'R3', 'Coverage');
        coverages.push(coverage);
        break;
      }
      case ResourceTypes.organization: {
        const organization = resource as Organization;
        // Force R3
        pushFhirVersionProfile(organization, 'R3', 'Organization');
        organizations.push(organization);
        break;
      }
      case ResourceTypes.practitioner: {
        const practitioner = resource as Practitioner;
        // Force R3
        pushFhirVersionProfile(practitioner, 'R3', 'Practitioner');
        practitioners.push(practitioner);
        break;
      }
      case ResourceTypes.practitionerRole: {
        const practitionerRole = resource as PractitionerRole;
        // Force R3
        pushFhirVersionProfile(practitionerRole, 'R3', 'PractitionerRole');
        practitionerRoles.push(practitionerRole);
        break;
      }
      case ResourceTypes.healthcareService: {
        const healthcareService = resource as HealthcareService;
        // Force R3
        pushFhirVersionProfile(healthcareService, 'R3', 'HealthcareService');
        healthcareServices.push(healthcareService);
        break;
      }
      case ResourceTypes.imagingStudy: {
        const imagingStudy = resource as ImagingStudy;
        // Force R3
        pushFhirVersionProfile(imagingStudy, 'R3', 'ImagingStudy');
        imagingStudies.push(imagingStudy);
        break;
      }
      case ResourceTypes.allergyIntolerance: {
        const allergyIntolerance = resource as AllergyIntolerance;
        // Force R3
        pushFhirVersionProfile(allergyIntolerance, 'R3', 'AllergyIntolerance');
        allergyIntolerances.push(allergyIntolerance);
        break;
      }
      case ResourceTypes.observation: {
        const observation = resource as Observation;
        // Force R3
        pushFhirVersionProfile(observation, 'R3', 'Observation');
        observations.push(observation);
        break;
      }
      case ResourceTypes.condition: {
        const condition = resource as Condition;
        // Force R3
        pushFhirVersionProfile(condition, 'R3', 'Condition');
        conditions.push(condition);
        break;
      }
      case ResourceTypes.encounter: {
        const encounter = resource as Encounter;
        // Force R3
        pushFhirVersionProfile(encounter, 'R3', 'Encounter');
        encounters.push(encounter);
        break;
      }
      case ResourceTypes.immunization: {
        const immunization = resource as Immunization;
        // Force R3
        pushFhirVersionProfile(immunization, 'R3', 'Immunization');
        immunizations.push(immunization);
        break;
      }
      case ResourceTypes.immunizationRecommendation: {
        const immunizationRecommendation = resource as ImmunizationRecommendation;
        // Force R3
        pushFhirVersionProfile(immunizationRecommendation, 'R3', 'ImmunizationRecommendation');
        immunizationRecommendations.push(immunizationRecommendation);
        break;
      }
      case ResourceTypes.diagnosticReport: {
        const diagnosticReport = resource as DiagnosticReport;
        // Force R3
        pushFhirVersionProfile(diagnosticReport, 'R3', 'DiagnosticReport');
        diagnosticReports.push(diagnosticReport);
        break;
      }
      case ResourceTypes.specimen: {
        const specimen = resource as Specimen;
        // Force R3
        pushFhirVersionProfile(specimen, 'R3', 'Specimen');
        specimens.push(specimen);
        break;
      }
      case ResourceTypes.consent: {
        const consent = resource as Consent;
        // Force R3
        pushFhirVersionProfile(consent, 'R3', 'Consent');
        consents.push(consent);
        break;
      }
      case ResourceTypes.nutritionOrder: {
        const nutritionOrder = resource as NutritionOrder;
        // Force R3
        pushFhirVersionProfile(nutritionOrder, 'R3', 'NutritionOrder');
        nutritionOrders.push(nutritionOrder);
        break;
      }
      case ResourceTypes.medicationRequest: {
        const medicationRequest = resource as MedicationRequest;
        // Force R3
        pushFhirVersionProfile(medicationRequest, 'R3', 'MedicationRequest');
        medicationRequests.push(medicationRequest);
        break;
      }
      case ResourceTypes.medicationStatement: {
        const medicationStatement = resource as MedicationStatement;
        // Force R3
        pushFhirVersionProfile(medicationStatement, 'R3', 'MedicationStatement');
        medicationStatements.push(medicationStatement);
        break;
      }
      case ResourceTypes.medicationDispense: {
        const medicationDispense = resource as MedicationDispense;
        // Force R3
        pushFhirVersionProfile(medicationDispense, 'R3', 'MedicationDispense');
        medicationDispenses.push(medicationDispense);
        break;
      }
      case ResourceTypes.medicalDevice: {
        const device = resource as Device;
        // Force R3
        pushFhirVersionProfile(device, 'R3', 'Device');
        devices.push(device);
        break;
      }
      case ResourceTypes.procedure: {
        const procedure = resource as Procedure;
        // Force R3
        pushFhirVersionProfile(procedure, 'R3', 'Procedure');
        procedures.push(procedure);
        break;
      }
      case ResourceTypes.flag: {
        const flag = resource as Flag;
        // Force R3
        pushFhirVersionProfile(flag, 'R3', 'Flag');
        flags.push(flag);
        break;
      }
      case ResourceTypes.medication: {
        const medication = resource as Medication;
        // Force R3
        pushFhirVersionProfile(medication, 'R3', 'Medication');
        medications.push(medication);
        break;
      }
      case ResourceTypes.composition: {
        const composition = resource as Composition;
        // Force R3
        pushFhirVersionProfile(composition, 'R3', 'Composition');
        compositions.push(composition);
        break;
      }
      case ResourceTypes.episodeOfCare: {
        const episodeOfCare = resource as EpisodeOfCare;
        // Force R3
        pushFhirVersionProfile(episodeOfCare, 'R3', 'EpisodeOfCare');
        episodesOfCare.push(episodeOfCare);
        break;
      }
      case ResourceTypes.deviceUseStatement: {
        const deviceUseStatement = resource as DeviceUseStatement;
        // Force R3
        pushFhirVersionProfile(deviceUseStatement, 'R3', 'DeviceUseStatement');
        deviceUseStatements.push(deviceUseStatement);
        break;
      }
      case ResourceTypes.appointment: {
        const appointment = resource as Appointment;
        // Force R3
        pushFhirVersionProfile(appointment, 'R3', 'Appointment');
        appointments.push(appointment);
        break;
      }
      case ResourceTypes.documentReference: {
        const documentReference = resource as DocumentReference;
        // Force R3
        pushFhirVersionProfile(documentReference, 'R3', 'DocumentReference');
        documentReferences.push(documentReference);
        break;
      }
      case ResourceTypes.documentManifest: {
        const documentManifest = resource as DocumentManifest;
        // Force R3
        pushFhirVersionProfile(documentManifest, 'R3', 'DocumentManifest');
        documentManifests.push(documentManifest);
        break;
      }
      case ResourceTypes.binary: {
        const binary = resource as Binary;
        // Force R3
        pushFhirVersionProfile(binary, 'R3', 'Binary');
        binaries.push(binary);
        break;
      }
      default:
        break;
    }
  }

  return DIGI_ME_FHIR_ACTIONS.fhirLoaded({
    fileName: file.name,
    patients,
    coverages,
    organizations,
    practitioners,
    practitionerRoles,
    healthcareServices,
    observations,
    allergyIntolerances,
    conditions,
    immunizations,
    immunizationRecommendations,
    diagnosticReports,
    specimens,
    consents,
    nutritionOrders,
    medicationRequests,
    medicationStatements,
    medicationDispenses,
    medications,
    compositions,
    devices,
    encounters,
    procedures,
    flags,
    episodesOfCare,
    deviceUseStatements,
    imagingStudies,
    appointments,
    documentReferences,
    documentManifests,
    triggerSourceId,
    binaries,
  });
}

function pushFhirVersionProfile(resource: Resource, version: FhirVersion, resourceType: string) {
  resource.meta ??= {};
  resource.meta.profile ??= [];
  const profile = `${getProfileForVersion(version)}/StructureDefinition/${resourceType}`;
  if (!resource.meta.profile.some((x) => x === profile)) {
    resource.meta.profile = [...resource.meta.profile, profile];
  }
}
