import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const FlagCategory: CodeSystemFlattened = {
  id: 'flag-category',
  codes: {
    diet: {
      display: 'Diet',
    },
    drug: {
      display: 'Drug',
    },
    lab: {
      display: 'Lab',
    },
    admin: {
      display: 'Administrative',
    },
    contact: {
      display: 'Subject contact',
    },
  },
};
