import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { MedicationRequest } from '@hl7fhir';
import { AnnotationViewModel, CodeableConceptPipe, getChoiceOfType, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import {
  MedicationRequestIntentPipe,
  MedicationRequestPriorityPipe,
  MedicationRequestStatusPipe,
} from '@hl7fhir/value-sets';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { DosageViewModel } from '../dosage';
import { MedicationRequestDispenseRequestViewModel } from './medication-request-dispense-request.viewmodel';
import { MedicationRequestRequesterViewModel } from './medication-request-requester.viewmodel';

export class MedicationRequestViewModel extends DomainResourceViewModel<MedicationRequest> {
  get identifier() {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get definition() {
    const resourceR3 = this.resource as r3.MedicationRequest;
    return getReferences(resourceR3.definition);
  }

  get basedOn() {
    return getReferences(this.resource?.basedOn);
  }

  get status() {
    return new MedicationRequestStatusPipe().transform(this.resource?.status);
  }

  get intent() {
    return new MedicationRequestIntentPipe().transform(this.resource?.intent);
  }

  get category() {
    return new CodeableConceptPipe().transform(this.resource?.category);
  }

  get priority() {
    return new MedicationRequestPriorityPipe().transform(this.resource?.priority);
  }

  get medication() {
    return getChoiceOfType({
      codeableConcept: this.resource?.medicationCodeableConcept,
      reference: this.resource?.medicationReference,
    });
  }

  get subject() {
    return getReference(this.resource?.subject);
  }

  get context() {
    const resourceR3 = this.resource as r3.MedicationRequest;
    return getReference(resourceR3.context);
  }

  get supportingInformation() {
    return getReferences(this.resource?.supportingInformation);
  }

  get authoredOn() {
    return formatLocaleDate(this.resource?.authoredOn, 'long');
  }

  get recorder() {
    return getReference(this.resource?.recorder);
  }

  get reasonCode() {
    return new CodeableConceptPipe().transform(this.resource?.reasonCode);
  }

  get reasonReference() {
    return getReferences(this.resource?.reasonReference);
  }

  get priorPrescription() {
    return getReference(this.resource?.priorPrescription);
  }

  get dosageInstruction() {
    return this.resource?.dosageInstruction?.map((dosage) => new DosageViewModel(dosage, this.fhirVersion));
  }

  get requester() {
    if (this.fhirVersion === 'R3') {
      const resourceR3 = this.resource as r3.MedicationRequest;
      return new MedicationRequestRequesterViewModel(resourceR3.requester, this.fhirVersion);
    }
    const resourceR = this.resource as r4.MedicationRequest | r4b.MedicationRequest | r5.MedicationRequest;
    return getReference(resourceR.requester);
  }

  get dispenseRequest() {
    return new MedicationRequestDispenseRequestViewModel(this.resource?.dispenseRequest, this.fhirVersion);
  }

  get note() {
    return this.resource?.note?.map((note) => new AnnotationViewModel(note, this.fhirVersion));
  }
}
