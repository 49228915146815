import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encounterLocationStatus',
})
export class EncounterLocationStatusPipe implements PipeTransform {
  transform(status: 'planned' | 'active' | 'reserved' | 'completed' | undefined): string {
    switch (status) {
      case 'planned':
        return $localize`:@@fhir.encounter-status.planned:Planned`;
      case 'active':
        return $localize`:@@fhir.encounter-status.active:Active`;
      case 'reserved':
        return $localize`:@@fhir.encounter-status.reserved:Reserved`;
      case 'completed':
        return $localize`:@@fhir.encounter-status.completed:Completed`;
      default:
        return '';
    }
  }
}
