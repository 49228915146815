import { NutritionOrderOralDietNutrient } from '@hl7fhir';
import { CodeableConceptPipe, QuantityTypePipe } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';

export class NutritionOrderOralDietNutrientViewModel extends ElementViewModel<NutritionOrderOralDietNutrient> {
  get amount(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.amount);
  }

  get modifier(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.modifier);
  }
}
