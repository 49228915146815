import { Timing } from '@hl7fhir';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import { JoinPipe } from '@shared';
import { TimingRepeatViewModel } from './timing-repeat.viewmodel';
import { CodeableConceptPipe } from '../codeable-concept';

export class TimingViewModel extends ElementViewModel<Timing> {
  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.code);
  }

  get event(): string | undefined {
    return new JoinPipe().transform(this.element?.event, ', ');
  }

  get repeat(): TimingRepeatViewModel | undefined {
    return new TimingRepeatViewModel(this.element?.repeat, this.fhirVersion);
  }
}
