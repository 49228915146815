import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EpisodeOfCareDiagnosis } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';

export class EpisodeOfCareDiagnosisViewModel extends BackboneElementViewModel<EpisodeOfCareDiagnosis> {
  get condition(): string | undefined {
    return getReference(this.element?.condition);
  }

  get role(): string | undefined {
    return this.element?.role && new CodeableConceptPipe().transform(this.element?.role);
  }
}
