import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HumanName } from '@hl7fhir';

@Component({
  selector: 'app-human-names',
  templateUrl: './human-names.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HumanNamesComponent {
  @Input() humanNames: HumanName[] | undefined;
}
