import { NgModule } from '@angular/core';
import { RatioPipe } from './pipes/ratio.pipe';

const pipes = [RatioPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class RatioModule {}
