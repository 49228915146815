import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EntryTypePipe } from '@hl7fhir/value-sets';
import { DeviceUdi } from 'fhir/r3';

export class DeviceUdiViewModel extends BackboneElementViewModel<DeviceUdi> {
  get carrierAIDC(): string | undefined {
    return this.element?.carrierAIDC;
  }

  get carrierHRF(): string | undefined {
    return this.element?.carrierHRF;
  }

  get deviceIdentifier(): string | undefined {
    return this.element?.deviceIdentifier;
  }

  get entryType(): string | undefined {
    return this.element?.entryType && new EntryTypePipe().transform(this.element.entryType);
  }

  get issuer(): string | undefined {
    return this.element?.issuer;
  }

  get jurisdiction(): string | undefined {
    return this.element?.jurisdiction;
  }

  get name(): string | undefined {
    return this.element?.name;
  }
}
