import { ElementViewModel } from '@hl7fhir/viewmodels';
import { HumanName } from '@hl7fhir';
import { FamilyNamePipe } from './pipes/family-name.pipe';
import { GivenNamePipe } from './pipes/given-name.pipe';
import { NameUsePipe } from './pipes/name-use.pipe';

export class HumanNameViewModel extends ElementViewModel<HumanName> {
  get given(): string | undefined {
    return this.element?.text;
  }

  get birth(): string | null | undefined {
    return new GivenNamePipe().transform(this.element, 'BR');
  }

  get callMe(): string | null | undefined {
    return new GivenNamePipe().transform(this.element, 'CL');
  }

  get initials(): string | null | undefined {
    return new GivenNamePipe().transform(this.element, 'IN');
  }

  get lastName(): string | null | undefined {
    return this.element?.family;
  }

  get ownLastName(): string | undefined {
    return new FamilyNamePipe().transform(this.element, 'own-name');
  }

  get ownLastNamePrefix(): string | undefined {
    return new FamilyNamePipe().transform(this.element, 'own-prefix');
  }

  get partnerLastName(): string | undefined {
    return new FamilyNamePipe().transform(this.element, 'partner-name');
  }

  get partnerLastNamePrefix(): string | undefined {
    return new FamilyNamePipe().transform(this.element, 'partner-prefix');
  }

  get fathersLastName(): string | undefined {
    return new FamilyNamePipe().transform(this.element, 'fathers-family');
  }

  get mothersLastName(): string | undefined {
    return new FamilyNamePipe().transform(this.element, 'mothers-family');
  }

  get usage(): string | undefined {
    return new NameUsePipe().transform(this.element?.use);
  }
}
