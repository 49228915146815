<ng-template #accountWarningsModal
             let-modal>

  <ng-container *ngIf="(source.partialSyncErrors?.length ?? 0) >= 1">

    <div class="modal-header">
      <h2 class="modal-title w-100"
          i18n="@@app.info.missing.partialHeader">Partial Sync</h2>
    </div>


    <div class="modal-body">
      <span i18n="@@app.info.missing.partialDetails">We were unable to completely import your records from</span> {{
      source.providerName }}.
    </div>

  </ng-container>

  <ng-container *ngIf="source.unsupportedResourceTypes?.length ?? 0 >= 1">

    <div class="modal-header">
      <h2 class="modal-title w-100"
          i18n="@@app.info.missing.unsupportedHeader">Unsupported Record Types</h2>
    </div>


    <div class="modal-body">
      {{ source.providerName }} <span i18n="@@app.info.missing.unsupportedDetails">does not provide
        access to the following records</span>:

      <ul>
        @for (type of source.unsupportedResourceTypes; track type) {
        <li class="fw-bold">{{ type }}</li>
        }
      </ul>
    </div>

  </ng-container>

  <div class="modal-footer d-flex">
    <span i18n="@@app.info.missing.statement">We continue to request all records with every sync so these may appear at
      a
      later time.</span>

    <app-button type="secondary"
                (clicked)="modal.close('Close click')"
                label="Dismiss"
                i18n-label="@@app.button.dismiss"></app-button>
  </div>
</ng-template>