import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DigiMeModule } from '@digi.me';
import { ButtonComponent, WdxFooterComponent } from '@layout';
import { NgbAccordionModule, NgbDropdownModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { DigiMeStoreModule } from '@store/digi.me';
import { MiddleMenuComponent } from './middle-menu/middle-menu.component';
import { MiddleMenuContainerComponent } from './middle-menu/middle-menu.container';
import { SideMenuContainerComponent } from './side-menu/side-menu.container';
import { SideMobileMenuComponent } from './side-mobile-menu/side-mobile-menu.component';
import { HelpComponent, HelpDropDownMenuComponent, SettingsComponent } from './top-menu';
import { LogInButtonContainerComponent } from './top-menu/login-button/login-button.container';
import { MenuNavBarComponent } from './top-menu/menu-navbar.component';
import { MenuComponent } from './top-menu/menu.component';

@NgModule({
  exports: [
    SideMenuContainerComponent,
    MiddleMenuContainerComponent,
    HelpDropDownMenuComponent,
    MenuComponent,
    MenuNavBarComponent,
  ],
  declarations: [
    MiddleMenuContainerComponent,
    MiddleMenuComponent,
    MenuComponent,
    SideMobileMenuComponent,
    MenuNavBarComponent,
    HelpDropDownMenuComponent,
  ],
  imports: [
    CommonModule,
    DigiMeModule,
    RouterModule,
    NgbDropdownModule,
    NgbOffcanvasModule,
    DigiMeStoreModule,
    NgbAccordionModule,
    SideMenuContainerComponent,
    LogInButtonContainerComponent,
    WdxFooterComponent,
    ButtonComponent,
    HelpComponent,
    SettingsComponent,
  ],
})
export class NavigationModule {}
