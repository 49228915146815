import { Pipe, PipeTransform } from '@angular/core';
import { Extension } from '@hl7fhir';

@Pipe({
  name: 'extension',
})
export class ExtensionPipe implements PipeTransform {
  transform(input: Extension[] | Extension | undefined, url: string | undefined): Extension | undefined {
    if (!input || !url) return undefined;

    // Normalize input to array
    input = Array.isArray(input) ? input : [input];

    return input.find((extension) => extension.url === url);
  }
}
