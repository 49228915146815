import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { OrganizationContact } from '@hl7fhir';
import { AddressViewModel, CodeableConceptPipe, FullNamePipe } from '@hl7fhir/data-types';

export class OrganizationContactViewModel extends BackboneElementViewModel<OrganizationContact> {
  get purpose(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.purpose);
  }

  get name(): string | undefined {
    return new FullNamePipe().transform(this.element?.name);
  }

  get address(): AddressViewModel | undefined {
    return new AddressViewModel(this.element?.address, this.fhirVersion);
  }
}
