import { formatLocaleDate } from '@globals';
import { CompositionAttester } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { CompositionAttesterModesPipe } from '@hl7fhir/value-sets';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';

export class CompositionAttesterViewModel extends ElementViewModel<CompositionAttester> {
  get mode(): string | undefined {
    const compositionAttesterR3 = this.element as r3.CompositionAttester;
    const compositionAttesterR4 = this.element as r4.CompositionAttester | r4b.CompositionAttester;
    const compositionAttesterR5 = this.element as r5.CompositionAttester;
    if (this.fhirVersion === 'R3') {
      return compositionAttesterR3?.mode && new CompositionAttesterModesPipe().transform(compositionAttesterR3?.mode);
    } else if (this.fhirVersion === 'R4' || this.fhirVersion === 'R4b') {
      return compositionAttesterR4?.mode && new CompositionAttesterModesPipe().transform(compositionAttesterR4?.mode);
    } else if (this.fhirVersion === 'R5') {
      return compositionAttesterR5?.mode && new CodeableConceptPipe().transform(compositionAttesterR5?.mode);
    }

    return undefined;
  }

  get time(): string | undefined {
    return this.element?.time && formatLocaleDate(this.element?.time, 'long');
  }

  get party(): string | undefined {
    return this.element?.party && getReference(this.element?.party);
  }
}
