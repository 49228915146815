import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agoDate',
})
export class AgoDatePipe implements PipeTransform {
  transform(dateMilliseconds: number): string {
    const now = new Date(Date.now());
    const compareDate = new Date(dateMilliseconds);

    const diffInMilliseconds = now.getTime() - compareDate.getTime();
    let dayDiff = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    if (now.getHours() < compareDate.getHours() && now.getMinutes() < compareDate.getMinutes()) {
      dayDiff++;
    }

    if (dayDiff === 0) {
      return $localize`:@@app.ago-date.today:today`;
    } else if (dayDiff === 1) {
      return $localize`:@@app.ago-date.yesterday:yesterday`;
    } else {
      return dayDiff + ' ' + $localize`:@@app.ago-date.days-ago:days ago`;
    }
  }
}
