import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'identifierUse',
})
export class IdentifierUsePipe implements PipeTransform {
  transform(value: ('usual' | 'official' | 'temp' | 'secondary' | 'old') | undefined): string | undefined {
    switch (value) {
      case 'usual':
        return $localize`:2.16.840.1.113883.4.642.3.67@@fhir.identifier-use.usual:Usual`;
      case 'official':
        return $localize`:2.16.840.1.113883.4.642.3.67@@fhir.identifier-use.official:Official`;
      case 'temp':
        return $localize`:2.16.840.1.113883.4.642.3.67@@fhir.identifier-use.temp:Temp`;
      case 'secondary':
        return $localize`:2.16.840.1.113883.4.642.3.67@@fhir.identifier-use.secondary:Secondary`;
      case 'old':
        return $localize`:2.16.840.1.113883.4.642.3.67@@fhir.identifier-use.old:Old`;
      default:
        return '';
    }
  }
}
