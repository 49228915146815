import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const AddressUse: CodeSystemFlattened = {
  id: 'address-use',
  codes: {
    home: {
      display: 'Home',
    },
    work: {
      display: 'Work',
    },
    temp: {
      display: 'Temporary',
    },
    old: {
      display: 'Old / Incorrect',
    },
    billing: {
      display: 'Billing',
    },
  },
};
