import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ButtonComponent } from '@layout';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { RESET_API_ACTIONS, RESET_UI_ACTIONS } from '@store/digi.me';

@Component({
  selector: 'app-digi-me-reset',
  standalone: true,
  imports: [CommonModule, ButtonComponent, NgbModalModule],
  templateUrl: './digi-me-reset.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigiMeResetComponent implements OnChanges {
  @Input() showWarning: boolean | null = false;
  @Input() resetting: boolean | null = false;

  @ViewChild('resetWarning') private modal: any;

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const showWarning = changes['showWarning']?.currentValue;
    if (showWarning) {
      this.open();
    } else {
      this.close();
    }
  }

  private open(): void {
    this.modalService.open(this.modal, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.store.dispatch(RESET_UI_ACTIONS.resetWarningDismissed());
        if (result === 'Clear') {
          this.store.dispatch(RESET_API_ACTIONS.resetRequested());
        }
      },
      () => {
        this.store.dispatch(RESET_UI_ACTIONS.resetWarningDismissed());
      }
    );
  }

  private close(): void {
    this.modalService.dismissAll();
  }
}
