import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { DELETE_API_ACTIONS, DELETE_UI_ACTIONS } from '@store/digi.me';
import { DeleteAccountCompletedModalComponent } from './account-deletion-completed.modal';
import { DeleteAccountProgressModalComponent } from './account-deletion-progress.modal';
import { DeleteAccountWarningModalComponent } from './account-deletion-warning.modal';
import { getSuccessfullyDeletedRedirectUrl } from './account-deletion.utils';

@Component({
  standalone: true,
  selector: 'app-account-deletion',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgbModalModule,
    DeleteAccountCompletedModalComponent,
    DeleteAccountProgressModalComponent,
    DeleteAccountWarningModalComponent,
  ],
})
export class AccountDeletionComponent implements OnChanges {
  @Input() showWarning: boolean | null = false;
  @Input() showDeleting: boolean | null = false;
  @Input() showDeleted: boolean | null = false;

  constructor(
    private modalService: NgbModal,
    private store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { showWarning, showDeleted, showDeleting } = changes;

    // Deleting the account takes precedence over any already open modals,
    // but there shouldn't be any other open anyway
    this.modalService.dismissAll();
    if (showDeleted?.currentValue) {
      this.modalService
        .open(DeleteAccountCompletedModalComponent, {
          backdrop: 'static',
          centered: true,
        })
        .result.then(() => {
          window.location.href = getSuccessfullyDeletedRedirectUrl();
        });
    } else if (showDeleting?.currentValue) {
      this.modalService.open(DeleteAccountProgressModalComponent, {
        backdrop: 'static',
        centered: true,
      });
    } else if (showWarning?.currentValue) {
      this.modalService.open(DeleteAccountWarningModalComponent, { backdrop: 'static', centered: true }).result.then(
        (result) => {
          this.store.dispatch(DELETE_UI_ACTIONS.deleteWarningDismissed());
          if (result === 'Delete') {
            this.store.dispatch(DELETE_API_ACTIONS.deleteRequested());
          }
        },
        () => {
          this.store.dispatch(DELETE_UI_ACTIONS.deleteWarningDismissed());
        }
      );
    }
  }
}
