import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MENU_CONFIG } from '@globals';
import { connectable, filter, map, Observable, of, ReplaySubject } from 'rxjs';
import { MenuItem } from './menu-item.model';
import { Menu } from './menu.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  readonly subMenu$: Observable<MenuItem[] | undefined>;
  readonly menu$: Observable<MenuItem[] | undefined>;
  readonly currentActiveItem$: Observable<MenuItem | undefined>;
  readonly currentMenuItems$: Observable<MenuItem[] | undefined>;

  private readonly menu: Menu = MENU_CONFIG;

  constructor(router: Router) {
    const navigationEnd = router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    // When switching pages the previous subscription is disposed leaving nobody that listens to the observable
    // Therefore we need to make it hot and replay the last navigation result.
    const url$ = navigationEnd.pipe(map((event) => event.urlAfterRedirects));
    const _subMenu$ = connectable(
      url$.pipe(
        map((url) => this.menu.menuItems.find((item: MenuItem) => url.toString().startsWith(item.path))?.subMenu)
      ),
      { connector: () => new ReplaySubject<MenuItem[] | undefined>(1) }
    );

    const _currentActiveItem$ = connectable(
      url$.pipe(map((url) => this.findMenuItemByUrl(this.menu.menuItems, url.toString()))),
      { connector: () => new ReplaySubject<MenuItem | undefined>(1) }
    );

    const _currentMenuItems$ = connectable(
      url$.pipe(map((url) => this.findMenuItemsByUrl(this.menu.menuItems, url.toString()))),
      { connector: () => new ReplaySubject<MenuItem[] | undefined>(1) }
    );

    _subMenu$.connect();
    _currentActiveItem$.connect();
    _currentMenuItems$.connect();
    this.currentActiveItem$ = _currentActiveItem$;
    this.currentMenuItems$ = _currentMenuItems$;
    this.subMenu$ = _subMenu$;

    this.menu$ = of(MENU_CONFIG.menuItems);
  }

  findMenuItemByUrl(menuItems: MenuItem[], url: string): MenuItem | undefined {
    for (const item of menuItems) {
      if (url.startsWith(item.path)) {
        if (item.subMenu) {
          const foundInSubMenu = this.findMenuItemByUrl(item.subMenu, url);
          if (foundInSubMenu) {
            return foundInSubMenu;
          }
        }
        return item;
      }
    }
    return undefined;
  }

  findMenuItemsByUrl(menuItems: MenuItem[], url: string, currentPath: MenuItem[] = []): MenuItem[] | undefined {
    for (const item of menuItems) {
      const resultItems = [...currentPath, item];

      if (item.path === url) {
        return resultItems;
      }

      if (item.subMenu) {
        const result = this.findMenuItemsByUrl(item.subMenu, url, resultItems);
        if (result) {
          return result;
        }
      }
    }

    return undefined;
  }
}
