import { AllergyIntolerance } from '@hl7fhir';
import { AllergyIntoleranceViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromAllergyIntolerance from './allergy-intolerance.reducer';

export const selectAllergyIntoleranceState = createFeatureSelector<fromAllergyIntolerance.State>(
  features.FHIR.allergyIntolerances
);

export const selectAllergyIntoleranceEntities = createSelector(
  selectAllergyIntoleranceState,
  fromAllergyIntolerance.selectAllergyIntoleranceEntities
);

export const selectAllAllergyIntolerances = createSelector(
  selectAllergyIntoleranceState,
  fromAllergyIntolerance.selectAllAllergyIntolerances
);

export const selectAllergyIntoleranceById = createSelector(
  selectRouteParams,
  selectAllergyIntoleranceEntities,
  ({ id }, allergyIntolerances: Dictionary<AllergyIntolerance>) =>
    id && (allergyIntolerances[id] ?? allergyIntolerances[id.toLowerCase()] ?? allergyIntolerances[id.toUpperCase()])
);

export const selectAllAllergyIntoleranceViewModels = createSelector(
  selectAllAllergyIntolerances,
  (dataSource: AllergyIntolerance[]) => dataSource.map((item) => new AllergyIntoleranceViewModel(item))
);

export const selectAllergyIntoleranceViewModelById = createSelector(
  selectAllergyIntoleranceById,
  (allergyIntolerance: AllergyIntolerance | undefined) =>
    allergyIntolerance && new AllergyIntoleranceViewModel(allergyIntolerance)
);
