import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r5 from 'fhir/r5';
import { CodeableConceptPipe } from '@hl7fhir/data-types';

export class SpecimenFeatureViewModel extends BackboneElementViewModel<r5.SpecimenFeature> {
  get type(): string | undefined {
    return this.element?.type && new CodeableConceptPipe().transform(this.element.type);
  }

  get description(): string | undefined {
    return this.element?.description;
  }
}
