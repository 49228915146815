import { Pipe, PipeTransform } from '@angular/core';
import { CodeableConcept } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ValueSetFlattened, ValueSets } from '@hl7fhir/value-sets';

@Pipe({
  name: 'codeableConcept',
})
export class CodeableConceptPipe implements PipeTransform {
  transform(
    codeableConcepts: CodeableConcept[] | CodeableConcept | undefined,
    preferredValueSets?: ValueSetFlattened[],
    displayCode?: boolean,
    displaySystem?: boolean
  ): string {
    let result = '';
    if (!codeableConcepts) {
      return result;
    }

    // Normalize input
    codeableConcepts = codeableConcepts instanceof Array ? codeableConcepts : [codeableConcepts];

    for (const codeableConcept of codeableConcepts) {
      // Text takes precedence over all codings
      if (codeableConcept.text) {
        result += result ? ', ' + codeableConcept.text : codeableConcept.text;
        continue;
      }

      const codings: string | undefined =
        codeableConcept.coding
          ?.map((coding) => {
            if (!coding) {
              return undefined;
            }
            const { system, code } = ValueSets.getDisplay(coding, preferredValueSets ?? []);

            // Make an exception to display code if code system is ICPC_1_NL.
            // In all other cases, you should set the displayCode yourself.
            displayCode = displayCode || coding.system === CodeSystems.ICPC_1_NL;

            let value = code ?? coding.display ?? coding.code ?? '';
            if (displayCode && displaySystem && system && coding.code) {
              // When wanting to display both system and code with the possible value
              // If we didn't fallback to the code display <value> (<system> <code>)
              // If there only is a code as the current value display <system> <code>
              value = value !== coding.code ? `${value} (${system} ${coding.code})` : `${system} ${coding.code}`;
            } else if (displayCode && coding.code && value !== coding.code) {
              // Append (<code>) if the value isn't the code already
              value += ` (${coding.code})`;
            } else if (displaySystem && system) {
              // Append the (<system>) if parameter is set and code is set
              // when only having a system value, don't display the () just <system>
              value += value ? ` (${system})` : system;
            }

            return value;
          })
          .filter((value) => value)
          .join(', ') ?? '';

      result += result ? ', ' + codings : codings;
    }

    return result;
  }
}
