import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesno',
})
export class YesNoPipe implements PipeTransform {
  transform(bool: boolean | undefined): string | undefined {
    if (bool === true) {
      return $localize`:@@fhir.boolean.yes:Yes`;
    }

    if (bool === false) {
      return $localize`:@@fhir.boolean.no:No`;
    }

    return undefined;
  }
}
