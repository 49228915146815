import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EncounterLocation } from '@hl7fhir';
import { PeriodTypePipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { EncounterLocationStatusPipe } from '@hl7fhir/value-sets';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class EncounterLocationViewModel extends BackboneElementViewModel<EncounterLocation> {
  private readonly encounterLocationR = this.element as
    | r3.EncounterLocation
    | r4.EncounterLocation
    | r4b.EncounterLocation;

  get location(): string | undefined {
    return this.encounterLocationR?.location && getReference(this.encounterLocationR.location);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element.period);
  }

  get status(): string | undefined {
    return this.element?.status && new EncounterLocationStatusPipe().transform(this.element.status);
  }
}
