import { ElementViewModel } from '@hl7fhir/viewmodels';
import { Identifier } from '@hl7fhir';
import { getReference } from '@hl7fhir/foundation';
import { IdentifierUsePipe } from '@hl7fhir/value-sets';
import { CodeableConceptPipe } from '../codeable-concept';
import { PeriodTypePipe } from '../period';
import { IdentifierUtils } from './identifier.utils';

export class IdentifierViewModel extends ElementViewModel<Identifier> {
  get use(): string | undefined {
    return this.element?.use && new IdentifierUsePipe().transform(this.element?.use);
  }

  get type(): string | undefined {
    return this.element?.type && new CodeableConceptPipe().transform(this.element?.type);
  }

  get system(): string | undefined {
    return this.element?.system;
  }

  get value(): string | undefined {
    return IdentifierUtils.getValueForSystemAndCode(this.element?.system, this.element?.value);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element?.period);
  }

  get assigner(): string | undefined {
    return this.element?.assigner && getReference(this.element?.assigner);
  }
}
