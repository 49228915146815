import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { HumanNameComponent } from './human-name.component';
import { HumanNamesComponent } from './human-names.component';
import { FamilyNamePipe } from './pipes/family-name.pipe';
import { FullNamePipe } from './pipes/full-name.pipe';
import { GivenNamePipe } from './pipes/given-name.pipe';
import { HumanNameRankPipe } from './pipes/human-name-rank.pipe';
import { NameUsePipe } from './pipes/name-use.pipe';

const components = [HumanNameComponent, HumanNamesComponent];
const pipes = [HumanNameRankPipe, FamilyNamePipe, NameUsePipe, GivenNamePipe, FullNamePipe];

@NgModule({
  imports: [SharedModule],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
})
export class HumanNameModule {}
