import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { MedicationPackage } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import * as r3 from 'fhir/r3';
import { MedicationPackageBatchViewModel } from './medication-package-batch.viewmodel';
import { MedicationPackageContentViewModel } from './medication-package-content.viewmodel';

export class MedicationPackageViewModel extends BackboneElementViewModel<MedicationPackage> {
  private elementR3 = this.element as r3.MedicationPackage;

  get container(): string | undefined {
    return new CodeableConceptPipe().transform(this.elementR3?.container);
  }

  get content(): MedicationPackageContentViewModel[] | undefined {
    return this.elementR3?.content?.map((content) => new MedicationPackageContentViewModel(content, this.fhirVersion));
  }

  get batch(): MedicationPackageBatchViewModel[] | undefined {
    return this.elementR3?.batch?.map((batch) => new MedicationPackageBatchViewModel(batch, this.fhirVersion));
  }
}
