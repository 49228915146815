import { Pipe, PipeTransform } from '@angular/core';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ISO3166_1Alpha2Mapping } from '@hl7nl-fhir/codesystems';

@Pipe({
  name: 'countryCodeToName',
})
export class CountryCodeToNamePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) {
      return '';
    }

    const normalizedCode = ISO3166_1Alpha2Mapping[value] ?? value;
    return CodeSystems.getDisplay({ system: 'urn:iso:std:iso:3166', code: normalizedCode }) ?? value;
  }
}
