import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const RelatieCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.3.1.1--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Anders',
        display: 'Other',
      },
    },
    'http://hl7.org/fhir/v3/RoleCode': {
      ADOPTF: {
        'nl-NL': 'Adoptievader',
        display: 'Adoptive father',
      },
      ADOPTM: {
        'nl-NL': 'Adoptiemoeder',
        display: 'Adoptive mother',
      },
      AUNT: {
        'nl-NL': 'Tante',
        display: 'Aunt',
      },
      BRO: {
        'nl-NL': 'Broer',
        display: 'Brother',
      },
      BROINLAW: {
        'nl-NL': 'Zwager',
        display: 'Brother-in-law',
      },
      COUSN: {
        'nl-NL': 'Neef/nicht, zoon/dochter van oom/tante',
        display: 'Cousin',
      },
      DAUC: {
        'nl-NL': 'Dochter',
        display: 'Daughter',
      },
      DAUINLAW: {
        'nl-NL': 'Schoondochter',
        display: 'Daughter in-law',
      },
      DOMPART: {
        'nl-NL': 'Partner',
        display: 'Domestic partner',
      },
      FTH: {
        'nl-NL': 'Vader',
        display: 'Father',
      },
      FTHINLAW: {
        'nl-NL': 'Schoonvader',
        display: 'Father-in-law',
      },
      DAUFOST: {
        'nl-NL': 'Pleegdochter',
        display: 'Foster daughter',
      },
      FTHFOST: {
        'nl-NL': 'Pleegvader',
        display: 'Foster father',
      },
      MTHFOST: {
        'nl-NL': 'Pleegmoeder',
        display: 'Foster mother',
      },
      SONFOST: {
        'nl-NL': 'Pleegzoon',
        display: 'Foster son',
      },
      GRNDDAU: {
        'nl-NL': 'Kleindochter',
        display: 'Granddaughter',
      },
      GRFTH: {
        'nl-NL': 'Opa',
        display: 'Grandfather',
      },
      GRMTH: {
        'nl-NL': 'Oma',
        display: 'Grandmother',
      },
      GRNDSON: {
        'nl-NL': 'Kleinzoon',
        display: 'Grandson',
      },
      GGRFTH: {
        'nl-NL': 'Overgrootvader',
        display: 'Great grandfather',
      },
      GGRMTH: {
        'nl-NL': 'Overgrootmoeder',
        display: 'Great grandmother',
      },
      HUSB: {
        'nl-NL': 'Echtgenoot',
        display: 'Husband',
      },
      MTH: {
        'nl-NL': 'Moeder',
        display: 'Mother',
      },
      MTHINLAW: {
        'nl-NL': 'Schoonmoeder',
        display: 'Mother-in-law',
      },
      NEPHEW: {
        'nl-NL': 'Neef, zoon van broer/zus',
        display: 'Nephew',
      },
      NIECE: {
        'nl-NL': 'Nicht, dochter van broer/zus',
        display: 'Niece',
      },
      SIS: {
        'nl-NL': 'Zuster',
        display: 'Sister',
      },
      SISINLAW: {
        'nl-NL': 'Schoonzuster',
        display: 'Sister-in-law',
      },
      SONC: {
        'nl-NL': 'Zoon',
        display: 'Son',
      },
      SONINLAW: {
        'nl-NL': 'Schoonzoon',
        display: 'Son in-law',
      },
      STPFTH: {
        'nl-NL': 'Stiefvader',
        display: 'Stepfather',
      },
      STPMTH: {
        'nl-NL': 'Stiefmoeder',
        display: 'Stepmother',
      },
      UNCLE: {
        'nl-NL': 'Oom',
        display: 'Uncle',
      },
      WIFE: {
        'nl-NL': 'Echtgenote',
        display: 'Wife',
      },
    },
  },
};
