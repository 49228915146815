import { Pipe, PipeTransform } from '@angular/core';
import { Range } from '@hl7fhir';

@Pipe({
  name: 'range',
})
export class RangePipe implements PipeTransform {
  transform(range: Range | undefined): string | undefined {
    if (!range) {
      return '';
    }

    return range.low?.value || range.high?.value
      ? `${$localize`:@@fhir.Range.low:From`} ${range.low?.value ?? '...'} ${
          range.low?.unit ?? ''
        } - ${$localize`:@@fhir.Range.high:Till`} ${range.high?.value ?? '...'} ${range.high?.unit ?? ''}`
      : '';
  }
}
