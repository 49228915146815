export const ISO3166_1Alpha2Mapping: Record<string, string> = {
  AFG: 'AF',
  '4': 'AF',
  ALB: 'AL',
  '8': 'AL',
  DZA: 'DZ',
  '12': 'DZ',
  ASM: 'AS',
  '16': 'AS',
  AND: 'AD',
  '20': 'AD',
  AGO: 'AO',
  '24': 'AO',
  AIA: 'AI',
  '660': 'AI',
  ATA: 'AQ',
  '10': 'AQ',
  ATG: 'AG',
  '28': 'AG',
  ARG: 'AR',
  '32': 'AR',
  ARM: 'AM',
  '51': 'AM',
  ABW: 'AW',
  '533': 'AW',
  AUS: 'AU',
  '36': 'AU',
  AUT: 'AT',
  '40': 'AT',
  AZE: 'AZ',
  '31': 'AZ',
  BHS: 'BS',
  '44': 'BS',
  BHR: 'BH',
  '48': 'BH',
  BGD: 'BD',
  '50': 'BD',
  BRB: 'BB',
  '52': 'BB',
  BLR: 'BY',
  '112': 'BY',
  BEL: 'BE',
  '56': 'BE',
  BLZ: 'BZ',
  '84': 'BZ',
  BEN: 'BJ',
  '204': 'BJ',
  BMU: 'BM',
  '60': 'BM',
  BTN: 'BT',
  '64': 'BT',
  BOL: 'BO',
  '68': 'BO',
  BES: 'BQ',
  '535': 'BQ',
  BIH: 'BA',
  '70': 'BA',
  BWA: 'BW',
  '72': 'BW',
  BVT: 'BV',
  '74': 'BV',
  BRA: 'BR',
  '76': 'BR',
  IOT: 'IO',
  '86': 'IO',
  BRN: 'BN',
  '96': 'BN',
  BGR: 'BG',
  '100': 'BG',
  BFA: 'BF',
  '854': 'BF',
  BDI: 'BI',
  '108': 'BI',
  CPV: 'CV',
  '132': 'CV',
  KHM: 'KH',
  '116': 'KH',
  CMR: 'CM',
  '120': 'CM',
  CAN: 'CA',
  '124': 'CA',
  CYM: 'KY',
  '136': 'KY',
  CAF: 'CF',
  '140': 'CF',
  TCD: 'TD',
  '148': 'TD',
  CHL: 'CL',
  '152': 'CL',
  CHN: 'CN',
  '156': 'CN',
  CXR: 'CX',
  '162': 'CX',
  CCK: 'CC',
  '166': 'CC',
  COL: 'CO',
  '170': 'CO',
  COM: 'KM',
  '174': 'KM',
  COD: 'CD',
  '180': 'CD',
  COG: 'CG',
  '178': 'CG',
  COK: 'CK',
  '184': 'CK',
  CRI: 'CR',
  '188': 'CR',
  HRV: 'HR',
  '191': 'HR',
  CUB: 'CU',
  '192': 'CU',
  CUW: 'CW',
  '531': 'CW',
  CYP: 'CY',
  '196': 'CY',
  CZE: 'CZ',
  '203': 'CZ',
  CIV: 'CI',
  '384': 'CI',
  DNK: 'DK',
  '208': 'DK',
  DJI: 'DJ',
  '262': 'DJ',
  DMA: 'DM',
  '212': 'DM',
  DOM: 'DO',
  '214': 'DO',
  ECU: 'EC',
  '218': 'EC',
  EGY: 'EG',
  '818': 'EG',
  SLV: 'SV',
  '222': 'SV',
  GNQ: 'GQ',
  '226': 'GQ',
  ERI: 'ER',
  '232': 'ER',
  EST: 'EE',
  '233': 'EE',
  SWZ: 'SZ',
  '748': 'SZ',
  ETH: 'ET',
  '231': 'ET',
  FLK: 'FK',
  '238': 'FK',
  FRO: 'FO',
  '234': 'FO',
  FJI: 'FJ',
  '242': 'FJ',
  FIN: 'FI',
  '246': 'FI',
  FRA: 'FR',
  '250': 'FR',
  GUF: 'GF',
  '254': 'GF',
  PYF: 'PF',
  '258': 'PF',
  ATF: 'TF',
  '260': 'TF',
  GAB: 'GA',
  '266': 'GA',
  GMB: 'GM',
  '270': 'GM',
  GEO: 'GE',
  '268': 'GE',
  DEU: 'DE',
  '276': 'DE',
  GHA: 'GH',
  '288': 'GH',
  GIB: 'GI',
  '292': 'GI',
  GRC: 'GR',
  '300': 'GR',
  GRL: 'GL',
  '304': 'GL',
  GRD: 'GD',
  '308': 'GD',
  GLP: 'GP',
  '312': 'GP',
  GUM: 'GU',
  '316': 'GU',
  GTM: 'GT',
  '320': 'GT',
  GGY: 'GG',
  '831': 'GG',
  GIN: 'GN',
  '324': 'GN',
  GNB: 'GW',
  '624': 'GW',
  GUY: 'GY',
  '328': 'GY',
  HTI: 'HT',
  '332': 'HT',
  HMD: 'HM',
  '334': 'HM',
  VAT: 'VA',
  '336': 'VA',
  HND: 'HN',
  '340': 'HN',
  HKG: 'HK',
  '344': 'HK',
  HUN: 'HU',
  '348': 'HU',
  ISL: 'IS',
  '352': 'IS',
  IND: 'IN',
  '356': 'IN',
  IDN: 'ID',
  '360': 'ID',
  IRN: 'IR',
  '364': 'IR',
  IRQ: 'IQ',
  '368': 'IQ',
  IRL: 'IE',
  '372': 'IE',
  IMN: 'IM',
  '833': 'IM',
  ISR: 'IL',
  '376': 'IL',
  ITA: 'IT',
  '380': 'IT',
  JAM: 'JM',
  '388': 'JM',
  JPN: 'JP',
  '392': 'JP',
  JEY: 'JE',
  '832': 'JE',
  JOR: 'JO',
  '400': 'JO',
  KAZ: 'KZ',
  '398': 'KZ',
  KEN: 'KE',
  '404': 'KE',
  KIR: 'KI',
  '296': 'KI',
  PRK: 'KP',
  '408': 'KP',
  KOR: 'KR',
  '410': 'KR',
  KWT: 'KW',
  '414': 'KW',
  KGZ: 'KG',
  '417': 'KG',
  LAO: 'LA',
  '418': 'LA',
  LVA: 'LV',
  '428': 'LV',
  LBN: 'LB',
  '422': 'LB',
  LSO: 'LS',
  '426': 'LS',
  LBR: 'LR',
  '430': 'LR',
  LBY: 'LY',
  '434': 'LY',
  LIE: 'LI',
  '438': 'LI',
  LTU: 'LT',
  '440': 'LT',
  LUX: 'LU',
  '442': 'LU',
  MAC: 'MO',
  '446': 'MO',
  MDG: 'MG',
  '450': 'MG',
  MWI: 'MW',
  '454': 'MW',
  MYS: 'MY',
  '458': 'MY',
  MDV: 'MV',
  '462': 'MV',
  MLI: 'ML',
  '466': 'ML',
  MLT: 'MT',
  '470': 'MT',
  MHL: 'MH',
  '584': 'MH',
  MTQ: 'MQ',
  '474': 'MQ',
  MRT: 'MR',
  '478': 'MR',
  MUS: 'MU',
  '480': 'MU',
  MYT: 'YT',
  '175': 'YT',
  MEX: 'MX',
  '484': 'MX',
  FSM: 'FM',
  '583': 'FM',
  MDA: 'MD',
  '498': 'MD',
  MCO: 'MC',
  '492': 'MC',
  MNG: 'MN',
  '496': 'MN',
  MNE: 'ME',
  '499': 'ME',
  MSR: 'MS',
  '500': 'MS',
  MAR: 'MA',
  '504': 'MA',
  MOZ: 'MZ',
  '508': 'MZ',
  MMR: 'MM',
  '104': 'MM',
  NAM: 'NA',
  '516': 'NA',
  NRU: 'NR',
  '520': 'NR',
  NPL: 'NP',
  '524': 'NP',
  NLD: 'NL',
  '528': 'NL',
  NCL: 'NC',
  '540': 'NC',
  NZL: 'NZ',
  '554': 'NZ',
  NIC: 'NI',
  '558': 'NI',
  NER: 'NE',
  '562': 'NE',
  NGA: 'NG',
  '566': 'NG',
  NIU: 'NU',
  '570': 'NU',
  NFK: 'NF',
  '574': 'NF',
  MKD: 'MK',
  '807': 'MK',
  MNP: 'MP',
  '580': 'MP',
  NOR: 'NO',
  '578': 'NO',
  OMN: 'OM',
  '512': 'OM',
  PAK: 'PK',
  '586': 'PK',
  PLW: 'PW',
  '585': 'PW',
  PSE: 'PS',
  '275': 'PS',
  PAN: 'PA',
  '591': 'PA',
  PNG: 'PG',
  '598': 'PG',
  PRY: 'PY',
  '600': 'PY',
  PER: 'PE',
  '604': 'PE',
  PHL: 'PH',
  '608': 'PH',
  PCN: 'PN',
  '612': 'PN',
  POL: 'PL',
  '616': 'PL',
  PRT: 'PT',
  '620': 'PT',
  PRI: 'PR',
  '630': 'PR',
  QAT: 'QA',
  '634': 'QA',
  ROU: 'RO',
  '642': 'RO',
  RUS: 'RU',
  '643': 'RU',
  RWA: 'RW',
  '646': 'RW',
  REU: 'RE',
  '638': 'RE',
  BLM: 'BL',
  '652': 'BL',
  SHN: 'SH',
  '654': 'SH',
  KNA: 'KN',
  '659': 'KN',
  LCA: 'LC',
  '662': 'LC',
  MAF: 'MF',
  '663': 'MF',
  SPM: 'PM',
  '666': 'PM',
  VCT: 'VC',
  '670': 'VC',
  WSM: 'WS',
  '882': 'WS',
  SMR: 'SM',
  '674': 'SM',
  STP: 'ST',
  '678': 'ST',
  SAU: 'SA',
  '682': 'SA',
  SEN: 'SN',
  '686': 'SN',
  SRB: 'RS',
  '688': 'RS',
  SYC: 'SC',
  '690': 'SC',
  SLE: 'SL',
  '694': 'SL',
  SGP: 'SG',
  '702': 'SG',
  SXM: 'SX',
  '534': 'SX',
  SVK: 'SK',
  '703': 'SK',
  SVN: 'SI',
  '705': 'SI',
  SLB: 'SB',
  '90': 'SB',
  SOM: 'SO',
  '706': 'SO',
  ZAF: 'ZA',
  '710': 'ZA',
  SGS: 'GS',
  '239': 'GS',
  SSD: 'SS',
  '728': 'SS',
  ESP: 'ES',
  '724': 'ES',
  LKA: 'LK',
  '144': 'LK',
  SDN: 'SD',
  '729': 'SD',
  SUR: 'SR',
  '740': 'SR',
  SJM: 'SJ',
  '744': 'SJ',
  SWE: 'SE',
  '752': 'SE',
  CHE: 'CH',
  '756': 'CH',
  SYR: 'SY',
  '760': 'SY',
  TWN: 'TW',
  '158': 'TW',
  TJK: 'TJ',
  '762': 'TJ',
  TZA: 'TZ',
  '834': 'TZ',
  THA: 'TH',
  '764': 'TH',
  TLS: 'TL',
  '626': 'TL',
  TGO: 'TG',
  '768': 'TG',
  TKL: 'TK',
  '772': 'TK',
  TON: 'TO',
  '776': 'TO',
  TTO: 'TT',
  '780': 'TT',
  TUN: 'TN',
  '788': 'TN',
  TUR: 'TR',
  '792': 'TR',
  TKM: 'TM',
  '795': 'TM',
  TCA: 'TC',
  '796': 'TC',
  TUV: 'TV',
  '798': 'TV',
  UGA: 'UG',
  '800': 'UG',
  UKR: 'UA',
  '804': 'UA',
  ARE: 'AE',
  '784': 'AE',
  GBR: 'GB',
  '826': 'GB',
  UMI: 'UM',
  '581': 'UM',
  USA: 'US',
  '840': 'US',
  URY: 'UY',
  '858': 'UY',
  UZB: 'UZ',
  '860': 'UZ',
  VUT: 'VU',
  '548': 'VU',
  VEN: 'VE',
  '862': 'VE',
  VNM: 'VN',
  '704': 'VN',
  VGB: 'VG',
  '92': 'VG',
  VIR: 'VI',
  '850': 'VI',
  WLF: 'WF',
  '876': 'WF',
  ESH: 'EH',
  '732': 'EH',
  YEM: 'YE',
  '887': 'YE',
  ZMB: 'ZM',
  '894': 'ZM',
  ZWE: 'ZW',
  '716': 'ZW',
  ALA: 'AX',
  '248': 'AX',
};
