import { Organization } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectOrganizationId(organization: Organization): string {
  // The only time that a resource does not have an id is when it is being submitted to the server using a create operation.
  // https://www.hl7.org/fhir/resource-definitions.html#Resource.id
  if (!organization.id) throw new Error('The organization is not created yet.');
  return organization.id;
}

export function sortById(a: Organization, b: Organization): number {
  return selectOrganizationId(a).localeCompare(selectOrganizationId(b));
}

export type State = EntityState<Organization>;

export const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
  selectId: selectOrganizationId,
  sortComparer: sortById, // Unsure what to sort it on, but for now at least keep the order consistent by sorting on its id
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { organizations }) => {
    // TODO Fhir multiversion
    return adapter.upsertMany(organizations, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of organization ids
export const selectOrganizationIds = selectIds;

// select the dictionary of organization entities
export const selectOrganizationEntities = selectEntities;

// select the array of organization
export const selectAllOrganizations = selectAll;

// select the total organization count
export const selectOrganizationTotal = selectTotal;
