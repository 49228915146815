import { ErrorHandler, Injectable } from '@angular/core';
import { MixpanelService } from '@logging';
import { environment } from '@environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly mixpanelService: MixpanelService) {}

  handleError(error: any) {
    if (environment.mixPanel.enabled) {
      this.mixpanelService.trackException(error).subscribe();
    }

    if (!environment.production) {
      console.log('GlobalErrorHandler', error);
    }
  }
}
