import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-settings',
  imports: [SharedModule, ButtonComponent],
  template: `
    <app-button
      type="secondary"
      (clicked)="select()"
      data-cy="settings-button"
      use="menu"
      icon="settings"
      target="_blank"
      i18n-label="@@app.settings"
      label="Settings"
    ></app-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
  @Output() selected = new EventEmitter();

  select() {
    this.selected.emit();
  }
}
