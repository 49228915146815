<ng-container *ngIf="annotation && annotation.text">
  <div *ngIf="annotation?.authorString || annotation?.time"
       data-cy="annotation-header"
       class="hstack gap-2 h-6 p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span *ngIf="annotation.authorString"
          class="h6 m-0"
          data-cy="annotation-author">{{ annotation.authorString }}</span>

    <span *ngIf="annotation.time"
          class="h6 mb-0"
          [ngClass]="annotation.authorString ? 'ms-auto' : ''"
          data-cy="annotation-time">{{ annotation.time | date: 'short' }}</span>
  </div>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.Annotation.text">Text</label>
    <span data-cy="annotation-text">{{ annotation.text }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.Annotation.authorReference">Author Reference</label>
    <app-reference data-cy="annotation-author-reference"
                   [reference]="annotation.authorReference"></app-reference>
  </app-entry>
</ng-container>