import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EncounterParticipant } from '@hl7fhir';
import { CodeableConceptPipe, PeriodTypePipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class EncounterParticipantViewModel extends BackboneElementViewModel<EncounterParticipant> {
  private readonly encounterParticipantR = this.element as
    | r3.EncounterParticipant
    | r4.EncounterParticipant
    | r4b.EncounterParticipant
    | undefined;

  get individual(): string | undefined {
    return this.encounterParticipantR?.individual && getReference(this.encounterParticipantR.individual);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element.period);
  }

  get type(): string | undefined {
    return this.element?.type && new CodeableConceptPipe().transform(this.element.type);
  }
}
