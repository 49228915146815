import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { APP_ACTIONS } from '@store/app';

@Injectable({ providedIn: 'root' })
export class DeepLinkLoginGuard {
  constructor(private readonly store: Store) {}

  canActivate() {
    // Track sign_in_start event using Mixpanel
    this.store.dispatch(APP_ACTIONS.authenticationStarted({ prompt: 'login' }));
    return true;
  }
}
