import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionVerificationStatus',
})
export class ConditionVerificationStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      // == R3
      case 'unknown':
        return $localize`:@@fhir.condition-verification-status.unknown:Unknown`;

      // >= R3
      case 'provisional':
        return $localize`:@@fhir.condition-verification-status.provisional:Provisional`;
      case 'differential':
        return $localize`:@@fhir.condition-verification-status.differential:Differential`;
      case 'confirmed':
        return $localize`:@@fhir.condition-verification-status.confirmed:Confirmed`;
      case 'refuted':
        return $localize`:@@fhir.condition-verification-status.refuted:Refuted`;
      case 'entered-in-error':
        return $localize`:@@fhir.condition-verification-status.entered-in-error:EnteredInError`;

      // >= R4 <= R5
      case 'unconfirmed':
        return $localize`:@@fhir.condition-verification-status.unconfirmed:Unconfirmed`;

      default:
        return '';
    }
  }
}
