import { createSelector } from '@ngrx/store';
import { ErrorState, LoadingState } from '@store';
import { DigiMeState } from '../digi-me.reducer';
import { selectLibrary } from './digi-me-claim.selectors';
import { selectDigiMeState } from './digi-me.selectors';

export const selectIsLoading = createSelector(selectDigiMeState, (state: DigiMeState) =>
  [state?.files.callState, state?.fileList.callState, state?.user.callState].some(
    (callstate) => (callstate as LoadingState) === LoadingState.loading
  )
);

export const selectTrigger = createSelector(selectDigiMeState, (state: DigiMeState) => state?.files.trigger);

export const selectFilesError = createSelector(selectDigiMeState, (state: DigiMeState) => {
  const errorState = state?.files.callState as ErrorState;

  // Filter out 403, because that indicates that the user should onboard
  // and is not an unrecoverable error.
  if (errorState.error && errorState.error.status === 403) {
    return undefined;
  }
  return errorState?.error;
});

export const selectMustOnboard = createSelector(selectDigiMeState, (state: DigiMeState) => {
  const errorStates = [state.files.callState as ErrorState, state.user.callState as ErrorState];
  // Check for 403, because that indicates that the user should onboard
  // and is not an unrecoverable error.
  return errorStates.some((errorState) => errorState?.error && errorState?.error?.status === 403);
});

export const selectIsFilesCompleted = createSelector(
  selectIsLoading,
  selectMustOnboard,
  selectLibrary,
  (isLoading, mustOnboard, mustCreateAccount) => !isLoading && !mustOnboard && mustCreateAccount === undefined
);

export const selectFilesLoaded = createSelector(selectDigiMeState, (state: DigiMeState) => state.files.loaded);
