import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemActUSPrivacyLaw: CodeSystemFlattened = {
  id: 'v3-ActUSPrivacyLaw',
  codes: {
    _ActUSPrivacyLaw: {
      display: 'ActUSPrivacyLaw',
    },
    '42CFRPart2': {
      display: '42 CFR Part2',
    },
    CommonRule: {
      display: 'Common Rule',
    },
    HIPAANOPP: {
      display: 'HIPAA notice of privacy practices',
    },
    HIPAAPsyNotes: {
      display: 'HIPAA psychotherapy notes',
    },
    HIPAASelfPay: {
      display: 'HIPAA self-pay',
    },
    Title38Section7332: {
      display: 'Title 38 Section 7332',
    },
  },
};
