/**
 * Downloads a JSON object as a file.
 *
 * @param data - The JSON object to download.
 * @param filename - The name of the downloaded file.
 */
export function downloadJSON(data: any, filename: string): void {
  const jsonStr = JSON.stringify(data);
  const element = document.createElement('a');

  element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(jsonStr));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
