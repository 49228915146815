import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const GBATabel34Landen: CodeSystemFlattened = {
  id: 'abbcbf14-7c66-4aa6-baba-137ba16d5e58',
  codes: {
    '0000': {
      display: 'Onbekend',
    },
    '5001': {
      display: 'Canada',
    },
    '5002': {
      display: 'Frankrijk',
    },
    '5003': {
      display: 'Zwitserland',
    },
    '5004': {
      display: 'Rhodesië',
    },
    '5005': {
      display: 'Malawi',
    },
    '5006': {
      display: 'Cuba',
    },
    '5007': {
      display: 'Suriname',
    },
    '5008': {
      display: 'Tunesië',
    },
    '5009': {
      display: 'Oostenrijk',
    },
    '5010': {
      display: 'België',
    },
    '5011': {
      display: 'Botswana',
    },
    '5012': {
      display: 'Iran',
    },
    '5013': {
      display: 'Nieuw-Zeeland',
    },
    '5014': {
      display: 'Zuid-Afrika',
    },
    '5015': {
      display: 'Denemarken',
    },
    '5016': {
      display: 'Noord-Jemen',
    },
    '5017': {
      display: 'Hongarije',
    },
    '5018': {
      display: 'Saoedi-Arabië',
    },
    '5019': {
      display: 'Liberia',
    },
    '5020': {
      display: 'Ethiopië',
    },
    '5021': {
      display: 'Chili',
    },
    '5022': {
      display: 'Marokko',
    },
    '5023': {
      display: 'Togo',
    },
    '5024': {
      display: 'Ghana',
    },
    '5025': {
      display: 'Laos',
    },
    '5026': {
      display: 'Angola',
    },
    '5027': {
      display: 'Filipijnen',
    },
    '5028': {
      display: 'Zambia',
    },
    '5029': {
      display: 'Mali',
    },
    '5030': {
      display: 'Ivoorkust',
    },
    '5031': {
      display: 'Burma',
    },
    '5032': {
      display: 'Monaco',
    },
    '5033': {
      display: 'Colombia',
    },
    '5034': {
      display: 'Albanië',
    },
    '5035': {
      display: 'Kameroen',
    },
    '5036': {
      display: 'Zuid-Vietnam',
    },
    '5037': {
      display: 'Singapore',
    },
    '5038': {
      display: 'Paraguay',
    },
    '5039': {
      display: 'Zweden',
    },
    '5040': {
      display: 'Cyprus',
    },
    '5041': {
      display: 'Australisch Nieuw-Guinea',
    },
    '5042': {
      display: 'Brunei',
    },
    '5043': {
      display: 'Irak',
    },
    '5044': {
      display: 'Mauritius',
    },
    '5045': {
      display: 'Vaticaanstad',
    },
    '5046': {
      display: 'Kashmir',
    },
    '5047': {
      display: 'Myanmar',
    },
    '5048': {
      display: 'Jemen',
    },
    '5049': {
      display: 'Slovenië',
    },
    '5050': {
      display: 'Zaïre',
    },
    '5051': {
      display: 'Kroatië',
    },
    '5052': {
      display: 'Taiwan',
    },
    '5053': {
      display: 'Rusland',
    },
    '5054': {
      display: 'Armenië',
    },
    '5055': {
      display: 'Ascension',
    },
    '5056': {
      display: 'Azoren',
    },
    '5057': {
      display: 'Bahrein',
    },
    '5058': {
      display: 'Bhutan',
    },
    '5059': {
      display: 'Britse Antillen',
    },
    '5060': {
      display: 'Comoren',
    },
    '5061': {
      display: 'Falklandeilanden',
    },
    '5062': {
      display: 'Frans-Guyana',
    },
    '5063': {
      display: 'Frans-Somaliland',
    },
    '5064': {
      display: 'Gilbert- en Ellice-eilanden',
    },
    '5065': {
      display: 'Groenland',
    },
    '5066': {
      display: 'Guadeloupe',
    },
    '5067': {
      display: 'Kaapverdische Eilanden',
    },
    '5068': {
      display: 'Macau',
    },
    '5069': {
      display: 'Martinique',
    },
    '5070': {
      display: 'Mozambique',
    },
    '5071': {
      display: 'Pitcairneilanden',
    },
    '5072': {
      display: 'Guinee-Bissau',
    },
    '5073': {
      display: 'Réunion',
    },
    '5074': {
      display: 'Saint Pierre en Miquelon',
    },
    '5075': {
      display: 'Seychellen en Amiranten',
    },
    '5076': {
      display: 'Tonga',
    },
    '5077': {
      display: 'Wallis en Futuna',
    },
    '5078': {
      display: 'Zuidwest-Afrika',
    },
    '5079': {
      display: 'Frans-Indië',
    },
    '5080': {
      display: 'Johnston',
    },
    '5081': {
      display: 'Kedah',
    },
    '5082': {
      display: 'Kelantan',
    },
    '5083': {
      display: 'Malakka',
    },
    '5084': {
      display: 'Mayotte',
    },
    '5085': {
      display: 'Negri Sembilan',
    },
    '5086': {
      display: 'Pahang',
    },
    '5087': {
      display: 'Perak',
    },
    '5088': {
      display: 'Perlis',
    },
    '5089': {
      display: 'Portugees-Indië',
    },
    '5090': {
      display: 'Selangor',
    },
    '5091': {
      display: 'Sikkim',
    },
    '5092': {
      display: 'Saint Vincent en de Grenadines',
    },
    '5093': {
      display: 'Spitsbergen',
    },
    '5094': {
      display: 'Trengganu',
    },
    '5095': {
      display: 'Aruba',
    },
    '5096': {
      display: 'Burkina Faso',
    },
    '5097': {
      display: 'Azerbeidzjan',
    },
    '5098': {
      display: 'Belarus',
    },
    '5099': {
      display: 'Kazachstan',
    },
    '5100': {
      display: 'Macedonië',
    },
    '5101': {
      display: 'Timor Leste',
    },
    '5102': {
      display: 'Servië en Montenegro',
    },
    '5103': {
      display: 'Servië',
    },
    '5104': {
      display: 'Montenegro',
    },
    '5105': {
      display: 'Kosovo',
    },
    '5106': {
      display: 'Bonaire',
    },
    '5107': {
      display: 'Curaçao',
    },
    '5108': {
      display: 'Saba',
    },
    '5109': {
      display: 'Sint Eustatius',
    },
    '5110': {
      display: 'Sint Maarten',
    },
    '5111': {
      display: 'Zuid-Soedan',
    },
    '5112': {
      display: 'Gazastrook en Westelijke Jordaanoever',
    },
    '5113': {
      display: 'Republiek Noord-Macedonië',
    },
    '6000': {
      display: 'Moldavië',
    },
    '6001': {
      display: 'Burundi',
    },
    '6002': {
      display: 'Finland',
    },
    '6003': {
      display: 'Griekenland',
    },
    '6004': {
      display: 'Guatemala',
    },
    '6005': {
      display: 'Nigeria',
    },
    '6006': {
      display: 'Libië',
    },
    '6007': {
      display: 'Ierland',
    },
    '6008': {
      display: 'Brazilië',
    },
    '6009': {
      display: 'Rwanda',
    },
    '6010': {
      display: 'Venezuela',
    },
    '6011': {
      display: 'IJsland',
    },
    '6012': {
      display: 'Liechtenstein',
    },
    '6013': {
      display: 'Somalië',
    },
    '6014': {
      display: 'Verenigde Staten van Amerika',
    },
    '6015': {
      display: 'Bolivia',
    },
    '6016': {
      display: 'Australië',
    },
    '6017': {
      display: 'Jamaica',
    },
    '6018': {
      display: 'Luxemburg',
    },
    '6019': {
      display: 'Tsjaad',
    },
    '6020': {
      display: 'Mauritanië',
    },
    '6021': {
      display: 'Kirgizië',
    },
    '6022': {
      display: 'China',
    },
    '6023': {
      display: 'Afghanistan',
    },
    '6024': {
      display: 'Indonesië',
    },
    '6025': {
      display: 'Guyana',
    },
    '6026': {
      display: 'Noord-Vietnam',
    },
    '6027': {
      display: 'Noorwegen',
    },
    '6028': {
      display: 'San Marino',
    },
    '6029': {
      display: 'Duitsland',
    },
    '6030': {
      display: 'Nederland',
    },
    '6031': {
      display: 'Cambodja',
    },
    '6032': {
      display: 'Fiji',
    },
    '6033': {
      display: "Bahama's",
    },
    '6034': {
      display: 'Israël',
    },
    '6035': {
      display: 'Nepal',
    },
    '6036': {
      display: 'Zuid-Korea',
    },
    '6037': {
      display: 'Spanje',
    },
    '6038': {
      display: 'Oekraïne',
    },
    '6039': {
      display: 'Groot-Brittannië',
    },
    '6040': {
      display: 'Niger',
    },
    '6041': {
      display: 'Haïti',
    },
    '6042': {
      display: 'Jordanië',
    },
    '6043': {
      display: 'Turkije',
    },
    '6044': {
      display: 'Trinidad en Tobago',
    },
    '6045': {
      display: 'Joegoslavië',
    },
    '6046': {
      display: 'Opper-Volta',
    },
    '6047': {
      display: 'Algerije',
    },
    '6048': {
      display: 'Gabon',
    },
    '6049': {
      display: 'Noord-Korea',
    },
    '6050': {
      display: 'Oezbekistan',
    },
    '6051': {
      display: 'Sierra Leone',
    },
    '6052': {
      display: 'Brits-Honduras',
    },
    '6053': {
      display: 'Canarische Eilanden',
    },
    '6054': {
      display: 'Frans-Polynesië',
    },
    '6055': {
      display: 'Gibraltar',
    },
    '6056': {
      display: 'Portugees-Timor',
    },
    '6057': {
      display: 'Tadzjikistan',
    },
    '6058': {
      display: 'Britse Salomonseilanden',
    },
    '6059': {
      display: 'São Tomé en Principe',
    },
    '6060': {
      display: 'Sint-Helena',
    },
    '6061': {
      display: 'Tristan Da Cunha',
    },
    '6062': {
      display: 'West-Samoa',
    },
    '6063': {
      display: 'Turkmenistan',
    },
    '6064': {
      display: 'Georgië',
    },
    '6065': {
      display: 'Bosnië-Herzegovina',
    },
    '6066': {
      display: 'Tsjechië',
    },
    '6067': {
      display: 'Slowakije',
    },
    '6068': {
      display: 'Federale Republiek Joegoslavië',
    },
    '6069': {
      display: 'Democratische Republiek Congo',
    },
    '7001': {
      display: 'Uganda',
    },
    '7002': {
      display: 'Kenya',
    },
    '7003': {
      display: 'Malta',
    },
    '7004': {
      display: 'Barbados',
    },
    '7005': {
      display: 'Andorra',
    },
    '7006': {
      display: 'Mexico',
    },
    '7007': {
      display: 'Costa Rica',
    },
    '7008': {
      display: 'Gambia',
    },
    '7009': {
      display: 'Syrië',
    },
    '7011': {
      display: 'Nederlandse Antillen',
    },
    '7012': {
      display: 'Zuid-Jemen',
    },
    '7014': {
      display: 'Egypte',
    },
    '7015': {
      display: 'Argentinië',
    },
    '7016': {
      display: 'Lesotho',
    },
    '7017': {
      display: 'Honduras',
    },
    '7018': {
      display: 'Nicaragua',
    },
    '7020': {
      display: 'Pakistan',
    },
    '7021': {
      display: 'Senegal',
    },
    '7023': {
      display: 'Dahomey',
    },
    '7024': {
      display: 'Bulgarije',
    },
    '7026': {
      display: 'Maleisië',
    },
    '7027': {
      display: 'Dominicaanse Republiek',
    },
    '7028': {
      display: 'Polen',
    },
    '7029': {
      display: 'Rusland (oud)',
    },
    '7030': {
      display: 'Britse Maagdeneilanden',
    },
    '7031': {
      display: 'Tanzania',
    },
    '7032': {
      display: 'El Salvador',
    },
    '7033': {
      display: 'Sri Lanka',
    },
    '7034': {
      display: 'Soedan',
    },
    '7035': {
      display: 'Japan',
    },
    '7036': {
      display: 'Hongkong',
    },
    '7037': {
      display: 'Panama',
    },
    '7038': {
      display: 'Uruguay',
    },
    '7039': {
      display: 'Ecuador',
    },
    '7040': {
      display: 'Guinee',
    },
    '7041': {
      display: 'Maldiven',
    },
    '7042': {
      display: 'Thailand',
    },
    '7043': {
      display: 'Libanon',
    },
    '7044': {
      display: 'Italië',
    },
    '7045': {
      display: 'Koeweit',
    },
    '7046': {
      display: 'India',
    },
    '7047': {
      display: 'Roemenië',
    },
    '7048': {
      display: 'Tsjecho-Slowakije',
    },
    '7049': {
      display: 'Peru',
    },
    '7050': {
      display: 'Portugal',
    },
    '7051': {
      display: 'Oman',
    },
    '7052': {
      display: 'Mongolië',
    },
    '7053': {
      display: 'Samoa',
    },
    '7054': {
      display: 'Verenigde Arabische Emiraten',
    },
    '7055': {
      display: 'Tibet',
    },
    '7057': {
      display: 'Nauru',
    },
    '7058': {
      display: 'Nederlands Nieuw-Guinea',
    },
    '7059': {
      display: 'Tanganyika',
    },
    '7060': {
      display: 'Palestina',
    },
    '7062': {
      display: 'Brits West-Indië',
    },
    '7063': {
      display: 'Portugees-Afrika',
    },
    '7064': {
      display: 'Letland',
    },
    '7065': {
      display: 'Estland',
    },
    '7066': {
      display: 'Litouwen',
    },
    '7067': {
      display: 'Brits-Afrika',
    },
    '7068': {
      display: 'Belgisch-Congo',
    },
    '7070': {
      display: 'Brits-Indië',
    },
    '7071': {
      display: 'Noord-Rhodesië',
    },
    '7072': {
      display: 'Zuid-Rhodesië',
    },
    '7073': {
      display: 'Saarland',
    },
    '7074': {
      display: 'Frans Indochina',
    },
    '7075': {
      display: 'Brits West-Borneo',
    },
    '7076': {
      display: 'Goudkust',
    },
    '7077': {
      display: 'Ras al-Khaimah',
    },
    '7079': {
      display: 'Frans-Congo',
    },
    '7080': {
      display: 'Siam',
    },
    '7082': {
      display: 'Brits Oost-Afrika',
    },
    '7083': {
      display: 'Brits Noord-Borneo',
    },
    '7084': {
      display: 'Bangladesh',
    },
    '7085': {
      display: 'Duitse Democratische Republiek',
    },
    '7087': {
      display: 'Madeira-eilanden',
    },
    '7088': {
      display: 'Amerikaanse Maagdeneilanden',
    },
    '7089': {
      display: 'Australische Salomonseilanden',
    },
    '7091': {
      display: 'Spaanse Sahara',
    },
    '7092': {
      display: 'Caymaneilanden',
    },
    '7093': {
      display: 'Caicoseilanden',
    },
    '7094': {
      display: 'Turkseilanden',
    },
    '7095': {
      display: 'Brits Antarctisch Territorium',
    },
    '7096': {
      display: 'Brits Indische Oceaanterritorium',
    },
    '7097': {
      display: 'Cookeilanden',
    },
    '7098': {
      display: 'Tokelau',
    },
    '7099': {
      display: 'Nieuw-Caledonië',
    },
    '8000': {
      display: 'Hawaii-eilanden',
    },
    '8001': {
      display: 'Guam',
    },
    '8002': {
      display: 'Amerikaans-Samoa',
    },
    '8003': {
      display: 'Midway',
    },
    '8004': {
      display: 'Riukiu-eilanden',
    },
    '8005': {
      display: 'Wake',
    },
    '8006': {
      display: 'Pacific-eilanden',
    },
    '8008': {
      display: 'Grenada',
    },
    '8009': {
      display: 'Marianen',
    },
    '8010': {
      display: 'Cabinda',
    },
    '8011': {
      display: 'Canton en Enderbury',
    },
    '8012': {
      display: 'Christmaseiland',
    },
    '8013': {
      display: 'Cocoseilanden',
    },
    '8014': {
      display: 'Faeröer',
    },
    '8015': {
      display: 'Montserrat',
    },
    '8016': {
      display: 'Norfolk',
    },
    '8017': {
      display: 'Belize',
    },
    '8018': {
      display: 'Tasmanië',
    },
    '8019': {
      display: 'Turks- en Caicoseilanden',
    },
    '8020': {
      display: 'Puerto Rico',
    },
    '8021': {
      display: 'Papoea-Nieuw-Guinea',
    },
    '8022': {
      display: 'Salomonseilanden',
    },
    '8023': {
      display: 'Benin',
    },
    '8024': {
      display: 'Vietnam',
    },
    '8025': {
      display: 'Kaapverdië',
    },
    '8026': {
      display: 'Seychellen',
    },
    '8027': {
      display: 'Kiribati',
    },
    '8028': {
      display: 'Tuvalu',
    },
    '8029': {
      display: 'Saint Lucia',
    },
    '8030': {
      display: 'Dominica',
    },
    '8031': {
      display: 'Zimbabwe',
    },
    '8032': {
      display: 'Dubai',
    },
    '8033': {
      display: 'Nieuwe Hebriden',
    },
    '8034': {
      display: 'Kanaaleilanden',
    },
    '8035': {
      display: 'Man',
    },
    '8036': {
      display: 'Anguilla',
    },
    '8037': {
      display: 'Saint Kitts en Nevis',
    },
    '8038': {
      display: 'Antigua',
    },
    '8039': {
      display: 'Saint Vincent',
    },
    '8040': {
      display: 'Gilberteilanden',
    },
    '8041': {
      display: 'Panamakanaalzone',
    },
    '8042': {
      display: 'Saint Kitts, Nevis en Anguilla',
    },
    '8043': {
      display: 'Belau',
    },
    '8044': {
      display: 'Palau',
    },
    '8045': {
      display: 'Antigua en Barbuda',
    },
    '9000': {
      display: 'Newfoundland',
    },
    '9001': {
      display: 'Nyasaland',
    },
    '9003': {
      display: 'Eritrea',
    },
    '9005': {
      display: 'Ifni',
    },
    '9006': {
      display: 'Brits-Kameroen',
    },
    '9007': {
      display: 'Keizer Wilhelmsland',
    },
    '9008': {
      display: 'Congo',
    },
    '9009': {
      display: 'Congo-Kinshasa',
    },
    '9010': {
      display: 'Madagaskar',
    },
    '9013': {
      display: 'Congo-Brazzaville',
    },
    '9014': {
      display: 'Leewardeilanden',
    },
    '9015': {
      display: 'Windwardeilanden',
    },
    '9016': {
      display: "Frans Territorium voor Afars en Issa's",
    },
    '9017': {
      display: 'Phoenixeilanden',
    },
    '9020': {
      display: 'Portugees-Guinee',
    },
    '9022': {
      display: 'Duits Zuidwest-Afrika',
    },
    '9023': {
      display: 'Namibië',
    },
    '9027': {
      display: 'Brits-Somaliland',
    },
    '9028': {
      display: 'Italiaans-Somaliland',
    },
    '9030': {
      display: 'Nederlands-Indië',
    },
    '9031': {
      display: 'Brits-Guyana',
    },
    '9036': {
      display: 'Swaziland',
    },
    '9037': {
      display: 'Qatar',
    },
    '9038': {
      display: 'Eswatini',
    },
    '9041': {
      display: 'Aden',
    },
    '9042': {
      display: 'Zuid-Arabische Federatie',
    },
    '9043': {
      display: 'Equatoriaal-Guinea',
    },
    '9044': {
      display: 'Spaans-Guinee',
    },
    '9047': {
      display: 'Verenigde Arabische Republiek',
    },
    '9048': {
      display: 'Bermuda',
    },
    '9049': {
      display: 'Sovjet-Unie',
    },
    '9050': {
      display: 'Duits Oost-Afrika',
    },
    '9051': {
      display: 'Zanzibar',
    },
    '9052': {
      display: 'Ceylon',
    },
    '9053': {
      display: 'Muscat en Oman',
    },
    '9054': {
      display: 'Trucial Oman',
    },
    '9055': {
      display: 'Indochina',
    },
    '9056': {
      display: 'Marshalleilanden',
    },
    '9057': {
      display: 'Sarawak',
    },
    '9058': {
      display: 'Brits-Borneo',
    },
    '9060': {
      display: 'Sabah',
    },
    '9061': {
      display: 'Abu Dhabi',
    },
    '9062': {
      display: 'Ajman',
    },
    '9063': {
      display: 'Basutoland',
    },
    '9064': {
      display: 'Bechuanaland',
    },
    '9065': {
      display: 'Fujairah',
    },
    '9066': {
      display: 'Frans-Kameroen',
    },
    '9067': {
      display: 'Johore',
    },
    '9068': {
      display: 'Korea',
    },
    '9069': {
      display: 'Labuan',
    },
    '9070': {
      display: 'Umm Al-Qaiwain',
    },
    '9071': {
      display: 'Oostenrijk-Hongarije',
    },
    '9072': {
      display: 'Portugees Oost-Afrika',
    },
    '9073': {
      display: 'Portugees West-Afrika',
    },
    '9074': {
      display: 'Sharjah',
    },
    '9075': {
      display: 'Straits Settlements',
    },
    '9076': {
      display: 'Abessinië',
    },
    '9077': {
      display: 'Frans West-Afrika',
    },
    '9078': {
      display: 'Frans Equatoriaal-Afrika',
    },
    '9081': {
      display: 'Urundi',
    },
    '9082': {
      display: 'Ruanda-Urundi',
    },
    '9084': {
      display: 'Goa',
    },
    '9085': {
      display: 'Dantzig',
    },
    '9086': {
      display: 'Centraal-Afrikaanse Republiek',
    },
    '9087': {
      display: 'Djibouti',
    },
    '9088': {
      display: 'Transjordanië',
    },
    '9089': {
      display: 'Bondsrepubliek Duitsland',
    },
    '9090': {
      display: 'Vanuatu',
    },
    '9091': {
      display: 'Niue',
    },
    '9092': {
      display: 'Spaans Noord-Afrika',
    },
    '9093': {
      display: 'Westelijke Sahara',
    },
    '9094': {
      display: 'Micronesia',
    },
    '9095': {
      display: 'Svalbardeilanden',
    },
    '9999': {
      display: 'Internationaal gebied',
    },
  },
};
