import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../features';
import { UserEffects } from '../user/user.effects';
import { AppEffects } from './app.effects';
import { reducer } from './app.reducer';
import { EventLogEffects } from './event-log.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([EventLogEffects, AppEffects, UserEffects]),
    StoreModule.forFeature(features.APP, reducer),
  ],
})
export class AppStoreModule {}
