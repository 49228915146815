<div class="border-top border-secondary">
  <div data-cy="contactpoints-header"
       class="p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span class="h6"
          i18n="@@app.contactPoints.header">Contact details</span>
  </div>

  <p *ngIf="!contactPoints || contactPoints.length === 0"
     class="p-2"
     data-cy="contactpoints-empty"
     i18n="@@app.contactPoints.empty">No contact details to show.</p>

  <app-contact-point data-cy="contactpoint"
                     *ngFor="let contactPoint of contactPoints"
                     [contactPoint]="contactPoint"></app-contact-point>
</div>