import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicationStatementStatus',
})
export class MedicationStatementStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'active':
        return $localize`:@@fhir.medication-statement-status.active:Active`;
      case 'completed':
        return $localize`:@@fhir.medication-statement-status.completed:Completed`;
      case 'entered-in-error':
        return $localize`:@@fhir.medication-statement-status.entered-in-error:Entered in Error`;
      case 'intended':
        return $localize`:@@fhir.medication-statement-status.intended:Intended`;
      case 'stopped':
        return $localize`:@@fhir.medication-statement-status.stopped:Stopped`;
      case 'on-hold':
        return $localize`:@@fhir.medication-statement-status.on-hold:On Hold`;
      default:
        return '';
    }
  }
}
