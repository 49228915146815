import { Pipe, PipeTransform } from '@angular/core';
import { ContactPoint } from '@hl7fhir';

@Pipe({
  name: 'contactPointRank',
})
export class ContactPointRankPipe implements PipeTransform {
  transform(value: ContactPoint[] | undefined | null): ContactPoint | null {
    if (!value?.length) return null;

    // We take the rank as leading, and are ignoring the period
    // The contact point have a rank, so sort it and then return the first.
    return value.sort((a, b) => (a.rank ?? Number.MAX_VALUE) - (b.rank ?? Number.MAX_VALUE))[0];
  }
}
