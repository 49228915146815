import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuItem } from '../menu-item.model';
import { Observable } from 'rxjs';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-middle-menu-container',
  templateUrl: './middle-menu.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiddleMenuContainerComponent {
  subMenu$: Observable<MenuItem[] | undefined>;
  activeMenuItem$: Observable<MenuItem | undefined>;

  constructor(private menuService: MenuService) {
    this.subMenu$ = this.menuService.subMenu$;
    this.activeMenuItem$ = this.menuService.currentActiveItem$;
  }
}
