import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const OrganisatieTypeCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.17.2.3--20171231000000',
  systems: {
    'http://nictiz.nl/fhir/NamingSystem/organization-type': {
      V4: {
        'nl-NL': 'Ziekenhuis',
        display: 'Ziekenhuis',
      },
      V5: {
        'nl-NL': 'Universitair Medisch Centrum',
        display: 'Universitair Medisch Centrum',
      },
      V6: {
        'nl-NL': 'Algemeen ziekenhuis',
        display: 'Algemeen ziekenhuis',
      },
      Z4: {
        'nl-NL': 'Zelfstandig behandelcentrum',
        display: 'Zelfstandig behandelcentrum',
      },
      Z5: {
        'nl-NL': 'Diagnostisch centrum',
        display: 'Diagnostisch centrum',
      },
      B2: {
        'nl-NL': 'Echocentrum',
        display: 'Echocentrum',
      },
      X3: {
        'nl-NL': 'Verplegings- of verzorgingsinstelling',
        display: 'Verplegings- of verzorgingsinstelling',
      },
      R5: {
        'nl-NL': 'Verpleeghuis',
        display: 'Verpleeghuis',
      },
      M1: {
        'nl-NL': 'Verzorgingstehuis',
        display: 'Verzorgingstehuis',
      },
      J8: {
        'nl-NL': 'Openbare apotheek',
        display: 'Openbare apotheek',
      },
      K9: {
        'nl-NL': 'Zelfstandig opererende ziekenhuisapotheek',
        display: 'Zelfstandig opererende ziekenhuisapotheek',
      },
      Z3: {
        'nl-NL': 'Huisartspraktijk (zelfstandig of groepspraktijk)',
        display: 'Huisartspraktijk (zelfstandig of groepspraktijk)',
      },
      K3: {
        'nl-NL': 'Apotheekhoudende huisartspraktijk',
        display: 'Apotheekhoudende huisartspraktijk',
      },
      N6: {
        'nl-NL': 'Huisartsenpost (t.b.v. dienstwaarneming)',
        display: 'Huisartsenpost (t.b.v. dienstwaarneming)',
      },
      L1: {
        'nl-NL': 'Laboratorium',
        display: 'Laboratorium',
      },
      P4: {
        'nl-NL': 'Polikliniek (als onderdeel van een ziekenhuis)',
        display: 'Polikliniek (als onderdeel van een ziekenhuis)',
      },
      R8: {
        'nl-NL': 'Revalidatiecentrum',
        display: 'Revalidatiecentrum',
      },
      P6: {
        'nl-NL': 'Preventieve gezondheidszorg',
        display: 'Preventieve gezondheidszorg',
      },
      G5: {
        'nl-NL': 'Geestelijke Gezondheidszorg',
        display: 'Geestelijke Gezondheidszorg',
      },
      G6: {
        'nl-NL': 'Verstandelijk gehandicaptenzorg',
        display: 'Verstandelijk gehandicaptenzorg',
      },
      T2: {
        'nl-NL': 'Thuiszorg',
        display: 'Thuiszorg',
      },
      JGZ: {
        'nl-NL': 'Jeugdgezondheidszorg',
        display: 'Jeugdgezondheidszorg',
      },
      G3: {
        'nl-NL': 'Verloskundigenpraktijk',
        display: 'Verloskundigenpraktijk',
      },
    },
  },
};
