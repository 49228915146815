import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Reference } from '@hl7fhir';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferencesComponent {
  @Input() references: Reference[] | null | undefined;
  @Input() suppressLink = false;
}
