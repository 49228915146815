<div class="d-flex flex-column h-100">
  <div>
    <svg class="onboard-image onboard-image-left d-none d-lg-block">
      <use xlink:href="./assets/images/assets.svg#linked-empty"></use>
    </svg>

    <svg class="onboard-image onboard-image-right d-none d-lg-block">
      <use xlink:href="./assets/images/assets.svg#linked-empty"></use>
    </svg>

    <div class="text-center vstack gap-5 pt-3 mx-auto mb-5"
         style="max-width: 360px;">

      <app-session-timeout-container></app-session-timeout-container>

      <div>
        <h1 class="text-center fw-bold display-4"><span i18n="@@app.OnboardOrLogIn.yourRecords">Welcome to
            digi.me</span></h1>
        <p class="text-center m-0"
           i18n="@@app.OnboardOrLogIn.description">The simplest, most private way to take control of your health
          data</p>
      </div>

      <svg class="d-block d-md-none">
        <use xlink:href="./assets/images/assets.svg#linked-empty"></use>
      </svg>

      <div>
        <app-button type="primary"
                    (clicked)="onboard.emit()"
                    i18n-label="@@app.OnboardOrLogIn.onboardButton"
                    label="Create your account"
                    use="block"></app-button>

        <p class="mt-3">
          <a [attr.href]="digiMeHref"
             style="cursor: pointer;"
             target="_blank"
             class="link-dark link-primary-hover link-underline-opacity-100-hover fw-semibold"
             i18n="@@app.OnboardOrLogIn.moreButton">Or, discover what digi.me can do</a>
        </p>
      </div>

      <app-medmij-trusted></app-medmij-trusted>
    </div>
  </div>
  <div class="mt-auto">
    <app-wdx-footer [compact]="false"></app-wdx-footer>
    <app-footer></app-footer>
  </div>
</div>