import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { Condition } from '@hl7fhir';
import { AnnotationViewModel, CodeableConceptPipe, getChoiceOfType } from '@hl7fhir/data-types';
import { getReference, VersionPipe } from '@hl7fhir/foundation';
import { ConditionClinicalStatusPipe, ConditionVerificationStatusPipe } from '@hl7fhir/value-sets';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { ConditionEvidenceViewModel } from './condition-evidence.viewmodel';
import { ConditionStageViewModel } from './condition-stage.viewmodel';

export class ConditionViewModel extends DomainResourceViewModel<Condition> {
  get clinicalStatus(): string | undefined {
    const isR3 = new VersionPipe().transform(this.resource, 'R3');

    if (isR3) {
      const condition = this.resource as r3.Condition | undefined;
      return condition?.clinicalStatus && new ConditionClinicalStatusPipe().transform(condition.clinicalStatus);
    }

    const condition = this.resource as r4.Condition | r4b.Condition | r5.Condition | undefined;
    return condition?.clinicalStatus && new CodeableConceptPipe().transform(condition.clinicalStatus);
  }

  get verificationStatus(): string | undefined {
    const isR3 = new VersionPipe().transform(this.resource, 'R3');

    if (isR3) {
      const condition = this.resource as r3.Condition | undefined;
      return (
        condition?.verificationStatus && new ConditionVerificationStatusPipe().transform(condition.verificationStatus)
      );
    }

    const condition = this.resource as r4.Condition | r4b.Condition | r5.Condition | undefined;
    return condition?.verificationStatus && new CodeableConceptPipe().transform(condition.verificationStatus);
  }

  get category(): string | undefined {
    return this.resource?.category && new CodeableConceptPipe().transform(this.resource.category);
  }

  get severity(): string | undefined {
    return this.resource?.severity && new CodeableConceptPipe().transform(this.resource.severity);
  }

  get code(): string | undefined {
    return this.resource?.code && new CodeableConceptPipe().transform(this.resource.code);
  }

  get bodySite(): string | undefined {
    return this.resource?.bodySite && new CodeableConceptPipe().transform(this.resource.bodySite);
  }

  get subject(): string | undefined {
    return this.resource?.subject && getReference(this.resource.subject);
  }

  //R3 only
  get context(): string | undefined {
    const condition = this.resource as r3.Condition | undefined;
    return condition?.context && getReference(condition.context);
  }

  //R4, R4b, R5 only
  get encounter(): string | undefined {
    const condition = this.resource as r4.Condition | r4b.Condition | r5.Condition | undefined;
    return condition?.encounter && getReference(condition?.encounter);
  }

  //R3 only
  get abatement(): string | undefined {
    const conditionR3 = this.resource as r3.Condition | undefined;

    return getChoiceOfType({
      dateTime: this.resource?.abatementDateTime,
      age: this.resource?.abatementAge,
      boolean: conditionR3?.abatementBoolean,
      period: this.resource?.abatementPeriod,
      range: this.resource?.abatementRange,
      string: this.resource?.abatementString,
    });
  }

  get onset(): string | undefined {
    return getChoiceOfType({
      dateTime: this.resource?.onsetDateTime,
      age: this.resource?.onsetAge,
      period: this.resource?.onsetPeriod,
      range: this.resource?.onsetRange,
      string: this.resource?.onsetString,
    });
  }

  get recordedDate(): string | undefined {
    const isR3 = new VersionPipe().transform(this.resource, 'R3');

    if (isR3) {
      const condition = this.resource as r3.Condition | undefined;
      return condition?.assertedDate && formatLocaleDate(condition.assertedDate, 'long');
    }

    const condition = this.resource as r4.Condition | r4b.Condition | r5.Condition | undefined;
    return condition?.recordedDate && formatLocaleDate(condition.recordedDate, 'long');
  }

  //R4, R4b only
  get recorder(): string | undefined {
    const condition = this.resource as r4.Condition | r4b.Condition | undefined;
    return condition?.recorder && getReference(condition.recorder);
  }

  //R3, R4, R4b only
  get asserter(): string | undefined {
    const condition = this.resource as r3.Condition | r4.Condition | r4b.Condition | undefined;
    return condition?.asserter && getReference(condition.asserter);
  }

  get note(): AnnotationViewModel[] | undefined {
    return this.resource?.note && this.resource.note.map((item) => new AnnotationViewModel(item, this.fhirVersion));
  }

  get stage(): ConditionStageViewModel[] | undefined {
    const stages = Array.isArray(this.resource?.stage)
      ? this.resource?.stage
      : this.resource?.stage
        ? [this.resource?.stage].filter((item): item is r3.ConditionStage => !!item)
        : undefined;

    return stages?.map((item) => new ConditionStageViewModel(item, this.fhirVersion));
  }

  get evidence(): ConditionEvidenceViewModel[] | undefined {
    const condition = this.resource as r3.Condition | r4.Condition | r4b.Condition | undefined;
    return (
      condition?.evidence && condition.evidence.map((item) => new ConditionEvidenceViewModel(item, this.fhirVersion))
    );
  }
}
