import { ErrorHandler, Injectable } from '@angular/core';
import { DigiMeEvents, ErrorEventProperties, ErrorOrigin, MixpanelEventProperties } from '@digi.me/models';
import { Observable } from 'rxjs';
// We use the ApiService from the core module, and the services in the core module utilize the Mixpanel service,
// which is why a circular dependency issue arises.
// ! TODO We need to address and resolve this issue.
import { ApiService } from '../core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor(private readonly apiService: ApiService) {}

  init(): void {
    this.initializeGlobalErrorHandler();
  }

  trackEvent(eventName: DigiMeEvents, properties?: Record<string, any>): Observable<any> {
    return this.apiService.eventLog(eventName, {
      ...properties,
      ...new MixpanelEventProperties(),
    });
  }

  trackApiException(error: Error, code?: string | undefined, message?: string | undefined): Observable<any> {
    const errorEventProperties = this.getErrorEventProperties(error);
    errorEventProperties.error_api_message = message;
    errorEventProperties.error_api_code = code;
    return this.trackEvent('error', { ...errorEventProperties });
  }

  trackException(error: Error): Observable<any> {
    const errorEventProperties = this.getErrorEventProperties(error);
    return this.trackEvent('error', { ...errorEventProperties });
  }

  private initializeGlobalErrorHandler(): void {
    const originalErrorHandler = ErrorHandler.prototype.handleError;
    ErrorHandler.prototype.handleError = (error) => {
      const errorEventProperties = this.getErrorEventProperties(error);
      this.trackEvent('error', errorEventProperties);
      originalErrorHandler.call(this, error);
    };
  }

  private getErrorEventProperties(error: Error): ErrorEventProperties {
    const errorOrigin = this.determineErrorOrigin(error);

    const errorEvent = new ErrorEventProperties();
    errorEvent.error_origin = errorOrigin;
    errorEvent.error_name = error.name;
    errorEvent.error_stack_trace = error.stack;
    errorEvent.error_message = error.message;
    return errorEvent;
  }

  private determineErrorOrigin(error: Error): ErrorOrigin {
    const message = error.message?.toLowerCase();

    if (message.includes(ErrorOrigin.api)) {
      return ErrorOrigin.api;
    } else if (message.includes(ErrorOrigin.sdk)) {
      return ErrorOrigin.sdk;
    } else if (message.includes(ErrorOrigin.source)) {
      return ErrorOrigin.source;
    } else {
      return ErrorOrigin.app;
    }
  }
}
