import { DocumentReferenceContent } from '@hl7fhir';
import { AttachmentViewModel, CodeableConceptPipe } from '@hl7fhir/data-types';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class DocumentReferenceContentViewModel extends BackboneElementViewModel<DocumentReferenceContent> {
  get attachment(): AttachmentViewModel | undefined {
    return this.element?.attachment && new AttachmentViewModel(this.element.attachment, this.fhirVersion);
  }

  get format(): string | undefined {
    if (!this.isR5()) {
      const content = this.element as
        | r3.DocumentReferenceContent
        | r4.DocumentReferenceContent
        | r4b.DocumentReferenceContent;
      return content?.format && new CodeableConceptPipe().transform(content.format);
    }

    return undefined;
  }
}
