<div class="border-top border-secondary">
  <div data-cy="annotations-header"
       class="p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span class="h6"
          i18n="@@app.annotations.header">Annotations</span>
  </div>

  <p *ngIf="!annotations || annotations.length === 0"
     class="p-2"
     data-cy="annotations-empty"
     i18n="@@app.annotations.empty">No annotations to show.</p>

  <app-annotation data-cy="annotation"
                  *ngFor="let annotation of annotations"
                  [annotation]="annotation"></app-annotation>
</div>