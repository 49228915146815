import { ImmunizationRecommendation } from '@hl7fhir';
import { ImmunizationRecommendationViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromImmunizationRecommendation from './immunization-recommendation.reducer';

export const selectImmunizationRecommendationState = createFeatureSelector<fromImmunizationRecommendation.State>(
  features.FHIR.immunizationRecommendations
);

export const selectImmunizationRecommendationEntities = createSelector(
  selectImmunizationRecommendationState,
  fromImmunizationRecommendation.selectImmunizationRecommendationEntities
);

export const selectAllImmunizationRecommendations = createSelector(
  selectImmunizationRecommendationState,
  fromImmunizationRecommendation.selectAllImmunizationRecommendations
);

export const selectImmunizationRecommendationById = createSelector(
  selectRouteParams,
  selectImmunizationRecommendationEntities,
  ({ id }, immunizationRecommendations: Dictionary<ImmunizationRecommendation>) =>
    id &&
    (immunizationRecommendations[id] ??
      immunizationRecommendations[id.toLowerCase()] ??
      immunizationRecommendations[id.toUpperCase()])
);

export const selectAllImmunizationRecommendationViewModels = createSelector(
  selectAllImmunizationRecommendations,
  (dataSource: ImmunizationRecommendation[]) => dataSource.map((item) => new ImmunizationRecommendationViewModel(item))
);

export const selectImmunizationRecommendationViewModelById = createSelector(
  selectImmunizationRecommendationById,
  (entity: ImmunizationRecommendation | undefined) => entity && new ImmunizationRecommendationViewModel(entity)
);
