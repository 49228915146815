import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { InputState } from './input-state.model';

@Component({
  standalone: true,
  selector: 'app-editable-input',
  template: `
    <div class="editable-input input-group">
      <input
        #input
        type="text"
        [value]="value"
        class="form-control input-box"
        (focus)="focus()"
        (keyup.enter)="onEnter()"
      />
      <div class="input-group-text" (click)="edit()">
        <mat-icon [fontIcon]="icon"></mat-icon>
      </div>
    </div>
  `,
  imports: [MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableInputComponent implements AfterViewInit {
  @ViewChild('input', { static: false }) input!: ElementRef;
  @Input() value: string | undefined = '';
  @Output() valueChange = new EventEmitter<string>();

  private state: InputState = InputState.edit;
  private inputElement!: HTMLInputElement;

  /**
   * Gets the icon based on the current state.
   * @remarks The states names should be the same as the icon names.
   */
  get icon(): string {
    return this.state;
  }

  ngAfterViewInit(): void {
    this.inputElement = this.input.nativeElement;
  }

  focus(): void {
    this.state = InputState.done;
  }

  edit(): void {
    if (this.state === InputState.edit) {
      this.state = InputState.done;
      this.inputElement.focus();
    } else {
      this.state = InputState.edit;
      this.inputElement.blur();
      this.valueChange.emit(this.input.nativeElement.value);
    }
  }

  onEnter(): void {
    this.inputElement.blur();
    this.state = InputState.edit;
  }
}
