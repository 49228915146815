import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CodePipe } from './code.pipe';

const pipes = [CodePipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class CodeModule {}
