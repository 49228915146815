import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { MedicationDispenseSubstitution } from '@hl7fhir';
import { CodeableConceptPipe, YesNoPipe } from '@hl7fhir/data-types';
import { getReferences } from '@hl7fhir/foundation';

export class MedicationDispenseSubstitutionViewModel extends BackboneElementViewModel<MedicationDispenseSubstitution> {
  get wasSubstituted(): string | undefined {
    if (!this.element?.wasSubstituted) {
      return undefined;
    }

    return new YesNoPipe().transform(this.element.wasSubstituted);
  }

  get type(): string | undefined {
    return this.element?.type && new CodeableConceptPipe().transform(this.element.type);
  }

  get reason(): string | undefined {
    return this.element?.reason && new CodeableConceptPipe().transform(this.element.reason);
  }

  get responsibleParty(): string | undefined {
    if (!this.element?.responsibleParty) {
      return undefined;
    }
    const responsibleParties = Array.isArray(this.element?.responsibleParty)
      ? this.element?.responsibleParty
      : [this.element?.responsibleParty];
    return getReferences(responsibleParties);
  }
}
