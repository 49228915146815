import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { ExtensionPipe } from './extension.pipe';
import { ExtensionsPipe } from './extensions.pipe';

const pipes = [ExtensionsPipe, ExtensionPipe];

@NgModule({
  imports: [SharedModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class ExtensibilityModule {}
