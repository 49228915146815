import {
  Age,
  Attachment,
  CodeableConcept,
  Identifier,
  Period,
  Quantity,
  Range,
  Ratio,
  Reference,
  SampledData,
  Timing,
} from '@hl7fhir';

export interface ChoiceOfType {
  dateTime?: string | undefined;
  string?: string | undefined;
  age?: Age | undefined;
  period?: Period | undefined;
  range?: Range | undefined;
  quantity?: Quantity | undefined;
  codeableConcept?: CodeableConcept | undefined;
  boolean?: boolean | undefined;
  integer?: number | undefined;
  ratio?: Ratio | undefined;
  sampledData?: SampledData | undefined;
  time?: string | undefined;
  attachment?: Attachment | undefined;
  reference?: Reference | undefined;
  instant?: string | undefined;
  identifier?: Identifier | undefined;
  timing?: Timing | undefined;
}
