import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { UriComponent } from './uri.component';
import { ValueSetsModule } from '@hl7fhir/value-sets';

const components = [UriComponent];

@NgModule({
  imports: [SharedModule, ValueSetsModule],
  declarations: [...components],
  exports: [...components],
})
export class UriModule {}
