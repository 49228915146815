import { CodeSystems } from '@hl7fhir/codesystems';
import { ValueSetFlattened, ValueSets } from '@hl7fhir/value-sets';

export class IdentifierUtils {
  static getSystemLabel(system: string | undefined): string | undefined {
    if (!system) {
      return undefined;
    }

    return CodeSystems.getLabel(system);
  }

  static getValueForSystemAndCode(
    system: string | undefined,
    code: string | undefined,
    preferredValueSets?: ValueSetFlattened[]
  ): string | undefined {
    if (!system || !code) {
      return code;
    }

    const { code: value } = ValueSets.getDisplay({ system: system, code: code }, preferredValueSets ?? []);

    return value ? `${value} (${code})` : code;
  }
}
