import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { PractitionerRole } from '@hl7fhir';
import {
  CodeableConceptPipe,
  ContactPointViewModel,
  getEmail,
  getPhone,
  IdentifierViewModel,
  PeriodTypePipe,
  YesNoPipe,
} from '@hl7fhir/data-types';
import { getReference, getReferences, HasProfilePipe } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { ValueSetFlattened } from '@hl7fhir/value-sets';
import { ZorgverlenerRolCodelijst } from '@hl7nl-fhir/value-sets';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
export class PractitionerRoleViewModel extends DomainResourceViewModel<PractitionerRole> {
  private readonly hasProfilePipe = new HasProfilePipe();

  get identifier(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get active(): string | undefined {
    return new YesNoPipe().transform(this.resource?.active);
  }

  get period(): string | undefined {
    return new PeriodTypePipe().transform(this.resource?.period);
  }

  get practitioner(): string | undefined {
    return getReference(this.resource?.practitioner);
  }

  get organization(): string | undefined {
    return getReference(this.resource?.organization);
  }

  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.code, this.preferredValueSets);
  }

  get specialty(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.specialty);
  }
  get location(): string | undefined {
    return getReferences(this.resource?.location);
  }

  get healthcareService(): string | undefined {
    return getReferences(this.resource?.healthcareService);
  }

  /**
   * R5, introduced .availability: not supported
   */
  get availabilityExceptions(): string | undefined {
    const practitionerRoleR = this.resource as r3.PractitionerRole | r4.PractitionerRole | r4b.PractitionerRole;
    return practitionerRoleR.availabilityExceptions;
  }

  /**
   * R5, introduced .contact: not supported
   */
  get telecom(): ContactPointViewModel[] | undefined {
    const practitionerRoleR = this.resource as r3.PractitionerRole | r4.PractitionerRole | r4b.PractitionerRole;
    return practitionerRoleR.telecom?.map((contactPoint) => new ContactPointViewModel(contactPoint, this.fhirVersion));
  }

  get phone(): string | undefined {
    const practitionerRoleR = this.resource as r3.PractitionerRole | r4.PractitionerRole | r4b.PractitionerRole;
    return getPhone(practitionerRoleR?.telecom);
  }

  get email(): string | undefined {
    const practitionerRoleR = this.resource as r3.PractitionerRole | r4.PractitionerRole | r4b.PractitionerRole;
    return getEmail(practitionerRoleR?.telecom);
  }

  private get preferredValueSets(): ValueSetFlattened[] | undefined {
    const practitionerRoleR = this.resource as r3.PractitionerRole | r4.PractitionerRole | r4b.PractitionerRole;

    // Nictiz specifics
    if (this.hasProfilePipe.transform(practitionerRoleR, StructureDefinition.Hl7.PRACTITIONER_ROLE.nlProfile)) {
      return [ZorgverlenerRolCodelijst];
    }

    return undefined;
  }
}
