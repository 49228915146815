import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemParticipationFunction: CodeSystemFlattened = {
  id: 'v3-ParticipationFunction',
  codes: {
    _AuthorizedParticipationFunction: {
      display: 'AuthorizedParticipationFunction',
    },
    _CoverageParticipationFunction: {
      display: 'CoverageParticipationFunction',
    },
    ADMPHYS: {
      display: 'admitting physician',
    },
    ANEST: {
      display: 'anesthesist',
    },
    ANRS: {
      display: 'anesthesia nurse',
    },
    ASSEMBLER: {
      display: 'assembly software',
    },
    ATTPHYS: {
      display: 'attending physician',
    },
    COMPOSER: {
      display: 'composer software',
    },
    DISPHYS: {
      display: 'discharging physician',
    },
    FASST: {
      display: 'first assistant surgeon',
    },
    MDWF: {
      display: 'midwife',
    },
    NASST: {
      display: 'nurse assistant',
    },
    PCP: {
      display: 'primary care physician',
    },
    PRISURG: {
      display: 'primary surgeon',
    },
    REVIEWER: {
      display: 'reviewer',
    },
    RNDPHYS: {
      display: 'rounding physician',
    },
    SASST: {
      display: 'second assistant surgeon',
    },
    SNRS: {
      display: 'scrub nurse',
    },
    TASST: {
      display: 'third assistant',
    },
    _AuthorizedReceiverParticipationFunction: {
      display: 'AuthorizedReceiverParticipationFunction',
    },
    _ConsenterParticipationFunction: {
      display: 'ConsenterParticipationFunction',
    },
    _OverriderParticipationFunction: {
      display: 'OverriderParticipationFunction',
    },
    AUCG: {
      display: 'caregiver information receiver',
    },
    AULR: {
      display: 'legitimate relationship information receiver',
    },
    AUTM: {
      display: 'care team information receiver',
    },
    AUWA: {
      display: 'work area information receiver',
    },
    GRDCON: {
      display: 'legal guardian consent author',
    },
    POACON: {
      display: 'healthcare power of attorney consent author',
    },
    PRCON: {
      display: 'personal representative consent author',
    },
    PROMSK: {
      display: 'authorized provider masking author',
    },
    SUBCON: {
      display: 'subject of consent author',
    },
    AUCOV: {
      display: 'consent overrider',
    },
    AUEMROV: {
      display: 'emergency overrider',
    },
    _PayorParticipationFunction: {
      display: 'PayorParticipationFunction',
    },
    _SponsorParticipationFunction: {
      display: 'SponsorParticipationFunction',
    },
    _UnderwriterParticipationFunction: {
      display: 'UnderwriterParticipationFunction',
    },
    CLMADJ: {
      display: 'claims adjudication',
    },
    ENROLL: {
      display: 'enrollment broker',
    },
    FFSMGT: {
      display: 'ffs management',
    },
    MCMGT: {
      display: 'managed care management',
    },
    PROVMGT: {
      display: 'provider management',
    },
    UMGT: {
      display: 'utilization management',
    },
    FULINRD: {
      display: 'fully insured',
    },
    SELFINRD: {
      display: 'self insured',
    },
    PAYORCNTR: {
      display: 'payor contracting',
    },
    REINS: {
      display: 'reinsures',
    },
    RETROCES: {
      display: 'retrocessionaires',
    },
    SUBCTRT: {
      display: 'subcontracting risk',
    },
    UNDERWRTNG: {
      display: 'underwriting',
    },
  },
};
