import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'select',
})
export class SelectPipe implements PipeTransform {
  transform<T, R>(obj: T | undefined, type: keyof T): R | undefined {
    if (!obj) return undefined;

    return obj[type] as R;
  }
}
