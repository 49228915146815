import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { ConsentPolicy } from '@hl7fhir';

export class ConsentPolicyViewModel extends BackboneElementViewModel<ConsentPolicy> {
  get authority(): string | undefined {
    return this.element?.authority;
  }

  get uri(): string | undefined {
    return this.element?.uri;
  }
}
