import { AccountsResponse } from '@digi.me/models';

export function isExpired(source: AccountsResponse | undefined): boolean {
  // No need to refresh when it is a sample account or when the accesTokenStatus is not returned, this happens with ongoing consent.
  if (!source || source.sample || !source.accessTokenStatus) return false;

  const isSourceAuth = source?.accessTokenStatus.authorized;
  // If no expiresAt is set we assume it isn't expired, then it will follow the authorized property.
  // Ongoing consent migth cause it to be authenticated without an expiry.
  const expired = Date.now() > (source.accessTokenStatus.expiresAt ?? Number.MAX_VALUE);
  return !isSourceAuth || expired;
}
