import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';

export class ImmunizationPractitionerViewModel extends BackboneElementViewModel<
  r3.ImmunizationPractitioner | r4.ImmunizationPerformer | r4b.ImmunizationPerformer | r5.ImmunizationPerformer
> {
  get role(): string | undefined {
    const immuneR3 = this.element as r3.ImmunizationPractitioner;
    const immuneR = this.element as r4.ImmunizationPerformer | r4b.ImmunizationPerformer | r5.ImmunizationPerformer;
    return (
      (immuneR3.role && new CodeableConceptPipe().transform(immuneR3.role)) ??
      (immuneR.function && new CodeableConceptPipe().transform(immuneR.function))
    );
  }

  get actor(): string | undefined {
    return this.element?.actor && getReference(this.element.actor);
  }
}
