import { formatLocaleDate } from '@globals';
import { DocumentReference } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { DocumentReferenceDocStatusPipe, DocumentReferenceStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { DocumentReferenceContentViewModel } from './document-reference-content.viewmodel';
import { DocumentReferenceContextViewModel } from './document-reference-context.viewmodel';
import { DocumentReferenceRelatesToViewModel } from './document-reference-relates-to.viewmodel';

export class DocumentReferenceViewModel extends DomainResourceViewModel<DocumentReference> {
  get masterIdentifier(): IdentifierViewModel | undefined {
    const documentReference = this.resource as r3.DocumentReference | r4.DocumentReference | r4b.DocumentReference;
    return (
      documentReference?.masterIdentifier &&
      new IdentifierViewModel(documentReference.masterIdentifier, this.fhirVersion)
    );
  }

  get identifier(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((id) => new IdentifierViewModel(id, this.fhirVersion));
  }

  get status(): string | undefined {
    return new DocumentReferenceStatusPipe().transform(this.resource?.status);
  }

  get docStatus(): string | undefined {
    return new DocumentReferenceDocStatusPipe().transform(this.resource?.docStatus);
  }

  get type(): string | undefined {
    return this.resource?.type && new CodeableConceptPipe().transform(this.resource.type);
  }

  get class(): string | undefined {
    const documentReference = this.resource as r3.DocumentReference;
    return documentReference?.class && new CodeableConceptPipe().transform(documentReference.class);
  }

  get subject(): string | undefined {
    return this.resource?.subject && getReference(this.resource.subject);
  }

  get created(): string | undefined {
    const documentReference = this.resource as r3.DocumentReference;
    return documentReference?.created && formatLocaleDate(documentReference.created, 'long');
  }

  get indexed(): string | undefined {
    const documentReference = this.resource as r3.DocumentReference;
    return documentReference?.indexed && formatLocaleDate(documentReference.created, 'long');
  }

  get author(): string | undefined {
    return this.resource?.author && getReferences(this.resource.author);
  }

  get authenticator(): string | undefined {
    const documentReference = this.resource as r3.DocumentReference | r4.DocumentReference | r4b.DocumentReference;
    return documentReference?.authenticator && getReference(documentReference.authenticator);
  }

  get custodian(): string | undefined {
    return this.resource?.custodian && getReference(this.resource.custodian);
  }

  get relatesTo(): DocumentReferenceRelatesToViewModel[] | undefined {
    return this.resource?.relatesTo?.map(
      (relatesTo) => new DocumentReferenceRelatesToViewModel(relatesTo, this.fhirVersion)
    );
  }

  get description(): string | undefined {
    return this.resource?.description;
  }

  get securityLabel(): string | undefined {
    return this.resource?.securityLabel && new CodeableConceptPipe().transform(this.resource?.securityLabel);
  }

  get content(): DocumentReferenceContentViewModel[] | undefined {
    if (!this.isR5()) {
      const context = this.resource as r3.DocumentReference | r4.DocumentReference | r4b.DocumentReference;
      return context?.content?.map((content) => new DocumentReferenceContentViewModel(content, this.fhirVersion));
    }

    return undefined;
  }

  get context(): DocumentReferenceContextViewModel | undefined {
    if (!this.isR5()) {
      const context = this.resource as r3.DocumentReference | r4.DocumentReference | r4b.DocumentReference;
      return context?.context && new DocumentReferenceContextViewModel(context.context, this.fhirVersion);
    }

    return undefined;
  }
  get fileLocation(): string | undefined {
    if (!this.resource?.content) return undefined;

    const urls = this.resource.content.map((content) => content.attachment?.url).filter((url) => url !== undefined);

    return urls.join(', ');
  }
}
