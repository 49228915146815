import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const OrganizationTypeCodeSystem: CodeSystemFlattened = {
  id: 'organization-type',
  codes: {
    prov: {
      display: 'Healthcare Provider',
    },
    dept: {
      display: 'Hospital Department',
    },
    team: {
      display: 'Organizational team',
    },
    govt: {
      display: 'Government',
    },
    ins: {
      display: 'Insurance Company',
    },
    edu: {
      display: 'Educational Institute',
    },
    reli: {
      display: 'Religious Institution',
    },
    crs: {
      display: 'Clinical Research Sponsor',
    },
    cg: {
      display: 'Community Group',
    },
    bus: {
      display: 'Non-Healthcare Business or Corporation',
    },
    other: {
      display: 'Other',
    },
  },
};
