export enum ResourceTypes {
  patient = 'Patient',
  coverage = 'Coverage',
  practitioner = 'Practitioner',
  healthcareService = 'HealthcareService',
  organization = 'Organization',
  practitionerRole = 'PractitionerRole',
  allergyIntolerance = 'AllergyIntolerance',
  observation = 'Observation',
  condition = 'Condition',
  immunization = 'Immunization',
  immunizationRecommendation = 'ImmunizationRecommendation',
  diagnosticReport = 'DiagnosticReport',
  specimen = 'Specimen',
  consent = 'Consent',
  nutritionOrder = 'NutritionOrder',
  medicationRequest = 'MedicationRequest',
  medicationStatement = 'MedicationStatement',
  medicationDispense = 'MedicationDispense',
  medication = 'Medication',
  medicalDevice = 'Device',
  procedure = 'Procedure',
  flag = 'Flag',
  composition = 'Composition',
  episodeOfCare = 'EpisodeOfCare',
  deviceUseStatement = 'DeviceUseStatement',
  imagingStudy = 'ImagingStudy',
  appointment = 'Appointment',
  documentReference = 'DocumentReference',
  documentManifest = 'DocumentManifest',
  binary = 'Binary',
  encounter = 'Encounter',
}
