import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flagStatus',
})
export class FlagStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'active':
        return $localize`:@@fhir.flag-status.active:Active`;
      case 'inactive':
        return $localize`:@@fhir.flag-status.inactive:Inactive`;
      case 'entered-in-error':
        return $localize`:@@fhir.flag-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
