import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@hl7fhir';
import { StructureDefinition } from '@hl7fhir/structure-definitions';

@Pipe({
  name: 'line',
})
export class LinePipe implements PipeTransform {
  /**
   * Get the value for the extension on address lines.
   * https://www.hl7.org/fhir/datatypes-extras.html#address
   * @param address
   * @param code Allowed values:
   * geolocation
   * iso21090-AD-use
   * iso21090-ADXP-additionalLocator
   * iso21090-ADXP-buildingNumberSuffix
   * iso21090-ADXP-careOf
   * iso21090-ADXP-censusTract
   * iso21090-ADXP-delimiter
   * iso21090-ADXP-deliveryAddressLine
   * iso21090-ADXP-deliveryInstallationArea
   * iso21090-ADXP-deliveryInstallationQualifier
   * iso21090-ADXP-deliveryInstallationType
   * iso21090-ADXP-deliveryMode
   * iso21090-ADXP-deliveryModeIdentifier
   * iso21090-ADXP-direction
   * iso21090-ADXP-houseNumber
   * iso21090-ADXP-houseNumberNumeric
   * iso21090-ADXP-postBox
   * iso21090-ADXP-precinct
   * iso21090-ADXP-streetAddressLine
   * iso21090-ADXP-streetName
   * iso21090-ADXP-streetNameBase
   * iso21090-ADXP-streetNameType
   * iso21090-ADXP-unitID
   * iso21090-ADXP-unitType
   * language
   * @returns
   */
  transform(address: Address | undefined, code: string) {
    if (!address || !address._line) {
      return undefined;
    }

    return address._line
      .map((line) => line.extension)
      .flat()
      .find((extension) => extension?.url === `${StructureDefinition.Hl7.STRUCTURE_DEFINITION}/${code}`)?.valueString;
  }
}
