/**
 * Blood pressure panel with all children optional.
 */
export const BP_PNL_W_ALL_OPTIONAL = '85354-9';

/**
 * Systolic blood pressure.
 */
export const BP_SYS = '8480-6';

/**
 * Diastolic blood pressure.
 */
export const BP_DIAS = '8462-4';

/**
 * Body Weight
 */
export const BODY_WEIGHT = '29463-7';

/**
 * Body Height
 */
export const BODY_HEIGHT = '8302-2';

/**
 * O2 Saturation
 */
export const O2_SATURATION = '2708-6';
export const O2_SATURATION_BY_PULSE = '59408-5';

/**
 * Body Temperature
 */
export const BODY_TEMPERATURE = '8310-5';

/**
 * Glucose
 */
export const GLUSOCE = '15074-8';
export const GLUCOSE_14770_2 = '14770-2';
export const GLUCOSE_14743_9 = '14743-9';
export const GLUCOSE_14760_3 = '14760-3';

/**
 * Heart rate
 */
export const PULSE_RATE = '8893-0';
export const HEART_RATE = '8867-4';

/**
 * Respiratory rate
 */
export const RESPIRATORY_RATE = '9279-1';
