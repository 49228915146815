<div class="d-flex flex-column h-100">
  <div>
    <app-header text="Confirm you understand and agree with the following before
you begin."
                i18n-text="@@app.signup.consentTitle">
      <app-back-arrow [defaultUrl]="'/onboard-or-log-in'"></app-back-arrow>
    </app-header>

    <div class="px-1 ps-md-4 ms-md-3 ps-lg-4 ms-lg-5">
      <markdown ngPreserveWhitespaces
                [data]="data"></markdown>

      <app-button type="primary"
                  (clicked)="consent.emit()"
                  i18n-label="@@app.signup.consent.buttonStart"
                  label="Link your first healthcare provider"
                  [working]="isLoadingAuthorizeUrl"></app-button>
    </div>
    <div class="clearfix py-5">
      <app-medmij-trusted [showDescription]="false"
                          [horizontal]="true"
                          class="float-sm-end"></app-medmij-trusted>
    </div>
  </div>
  <app-wdx-footer [compact]="false"
                  class="mt-auto"></app-wdx-footer>
</div>