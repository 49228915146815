<div class="section container">
  <div class="row justify-content-between">
    <div class="col-12 col-md-6 pb-3 pb-md-0 align-self-center">
      <div class="d-flex flex-column">
        <div class="d-flex h-100 align-items-center gap-2">
          <mat-icon [fontIcon]="icon"></mat-icon>
          <span class="title">{{ title }}</span>
        </div>
        <h6 *ngIf="value"
            class="mt-2">{{ value }}</h6>
      </div>

    </div>

    <div class="col-12 col-md-6">
      <ng-content></ng-content>
    </div>
  </div>
</div>