import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allergyIntoleranceReactionSeverity',
})
export class AllergyIntoleranceReactionSeverityPipe implements PipeTransform {
  transform(severity: string | undefined): string {
    switch (severity) {
      case 'mild':
        return $localize`:@@fhir.allergy-intolerance-reaction-severity.mild:Mild`;
      case 'moderate':
        return $localize`:@@fhir.allergy-intolerance-reaction-severity.moderate:Moderate`;
      case 'severe':
        return $localize`:@@fhir.allergy-intolerance-reaction-severity.severe:Severe`;
      default:
        return '';
    }
  }
}
