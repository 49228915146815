<div class="list-group-item my-1 p-3 bg-gray-200 border-1 border-gray-400 rounded-2">

  <div class="d-flex mb-1">
    <mat-icon fontIcon="warning"></mat-icon>
    &nbsp; <span i18n="@@app.info.missing.header">Some data may be not have been imported</span>
  </div>

  @for (accountWarning of accountsWarnings; track accountWarning) {
  <div class="d-flex-inline">
    {{ accountWarning.providerName }}

    <a href="javascript:void(0)"
       (click)="open(accountWarning)"><span i18n="@@app.info.missing.moreLink">More info</span> <mat-icon
                fontIcon="help"
                class="mat-size-1"></mat-icon></a>
  </div>
  }
</div>

<app-account-warnings-modal [source]="selectedAccount"
                            [openModal]="openModal"
                            (closeModal)="openModal = false"></app-account-warnings-modal>