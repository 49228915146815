import { HealthProfessionalRole, ResourceTypes } from '@globals';
import { Practitioner, PractitionerRole } from '@hl7fhir';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { getResource, selectAllPractitionerRoles, selectPractitionerEntities } from '@store/hl7fhir';

export const selectHealthProfessionalRoles = createSelector(
  selectAllPractitionerRoles,
  selectPractitionerEntities,
  (practitionerRoles: PractitionerRole[], practitioners: Dictionary<Practitioner>) => {
    const resources: HealthProfessionalRole[] = [];

    practitionerRoles.forEach((practitionerRole: PractitionerRole) => {
      const practitioner = getResource<Practitioner>(
        ResourceTypes.practitioner,
        practitionerRole.practitioner,
        practitioners
      );

      resources.push({
        practitionerRole: practitionerRole,
        practitioner: practitioner,
      });
    });

    return resources;
  }
);

export const selectHealthProfessionalRolesOverviewViewModel = createSelector(
  selectHealthProfessionalRoles,
  (healthProfessionalRoles) => ({
    healthProfessionalRoles,
  })
);
