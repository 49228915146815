import { NutritionOrder } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectNutritionOrderId(nutritionOrder: NutritionOrder): string {
  if (!nutritionOrder.id) throw new Error('The Nutrition Order is not created yet.');
  return nutritionOrder.id;
}

export type State = EntityState<NutritionOrder>;

export const adapter: EntityAdapter<NutritionOrder> = createEntityAdapter<NutritionOrder>({
  selectId: selectNutritionOrderId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { nutritionOrders }) => {
    return adapter.upsertMany(<NutritionOrder[]>nutritionOrders, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectNutritionOrderEntities = selectEntities;

export const selectAllNutritionOrders = selectAll;
