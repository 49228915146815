import { Pipe, PipeTransform } from '@angular/core';
import { SnomedCodes } from '@globals';
import { CodeableConcept, Coding, Consent } from '@hl7fhir';

@Pipe({
  name: 'consentCategoryExtension',
})
export class ConsentCategoryExtensionPipe implements PipeTransform {
  transform(consent: Consent | undefined): CodeableConcept | CodeableConcept[] | undefined {
    if (!consent || !consent.category || !consent.extension) {
      return undefined;
    }

    const treatmentDirective = SnomedCodes.TREATMENT_DIRECTIVE;
    const advanceDirective = SnomedCodes.ADVANCE_DIRECTIVE;
    let categoryCode: string | undefined;

    if (Array.isArray(consent.category)) {
      const flatCategories: CodeableConcept[] = consent.category.flat();

      const relevantCategory = flatCategories.find((category: CodeableConcept) => {
        if (category.coding) {
          return category.coding.some((coding: Coding) =>
            [treatmentDirective, advanceDirective].includes(coding.code || '')
          );
        }
        return false;
      });

      if (relevantCategory?.coding) {
        categoryCode = relevantCategory.coding[0]?.code;
      }
    }

    switch (categoryCode) {
      case advanceDirective:
        return consent.category;
      default:
        return undefined;
    }
  }
}
