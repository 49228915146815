import { Pipe, PipeTransform } from '@angular/core';
import { ContactPoint } from '@hl7fhir';

@Pipe({
  name: 'contactPointSystem',
})
export class ContactPointSystemPipe implements PipeTransform {
  transform(value: ContactPoint): string {
    switch (value.system) {
      case 'phone':
        return $localize`:@@fhir.contact-point-system.phone:Phone`;
      case 'fax':
        return $localize`:@@fhir.contact-point-system.fax:Fax`;
      case 'email':
        return $localize`:@@fhir.contact-point-system.email:Email`;
      case 'pager':
        return $localize`:@@fhir.contact-point-system.pager:Pager`;
      case 'url':
        return $localize`:@@fhir.contact-point-system.url:URL`;
      case 'sms':
        return $localize`:@@fhir.contact-point-system.sms:SMS`;
      case 'other':
        return $localize`:@@fhir.contact-point-system.other:Other`;
      default:
        return '';
    }
  }
}
