import { Reference } from '@hl7fhir';

export function getReference(reference: Reference | undefined): string | undefined {
  if (!reference?.reference) {
    return undefined;
  }

  return reference.display ?? reference.reference.split('/')[1];
}

export function getReferences(references: Reference[] | undefined): string | undefined {
  if (!references) {
    return undefined;
  }

  return references
    .map((reference: Reference) => getReference(reference))
    .filter((value: string | undefined) => value != null)
    .join(', ');
}
