import { MedicationStatement } from '@hl7fhir';
import { MedicationStatementViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromMedicationStatement from './medication-statement.reducer';

export const selectMedicationStatementState = createFeatureSelector<fromMedicationStatement.State>(
  features.FHIR.medicationStatements
);

export const selectMedicationStatementEntities = createSelector(
  selectMedicationStatementState,
  fromMedicationStatement.selectMedicationStatementEntities
);

export const selectAllMedicationStatements = createSelector(
  selectMedicationStatementState,
  fromMedicationStatement.selectAllMedicationStatements
);

export const selectMedicationStatementById = createSelector(
  selectRouteParams,
  selectMedicationStatementEntities,
  ({ id }, medicationStatements: Dictionary<MedicationStatement>) =>
    id && (medicationStatements[id] ?? medicationStatements[id.toLowerCase()] ?? medicationStatements[id.toUpperCase()])
);

export const selectAllMedicationStatementViewModels = createSelector(
  selectAllMedicationStatements,
  (medicationStatements) =>
    medicationStatements.map((medicationStatement) => new MedicationStatementViewModel(medicationStatement))
);

export const selectMedicationStatementViewModelById = createSelector(
  selectMedicationStatementById,
  (medicationStatement) => medicationStatement && new MedicationStatementViewModel(medicationStatement)
);
