import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectErrorMessage } from '@store/app';
import {
  selectIsAccountDeleted,
  selectIsAccountDeleting,
  selectIsResetLoading,
  selectShowAccountDeleteWaring,
  selectShowResetWarning,
} from '@store/digi.me';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
})
export class DefaultComponent {
  readonly isResetting$ = this.store.select(selectIsResetLoading);
  readonly showResetWarning$ = this.store.select(selectShowResetWarning);

  readonly showAccountDeleteWaring$: Observable<boolean> = this.store.select(selectShowAccountDeleteWaring);
  readonly isAccountDeleting$ = this.store.select(selectIsAccountDeleting);
  readonly isAccountDeleted$ = this.store.select(selectIsAccountDeleted);
  readonly errorMessage$ = this.store.select(selectErrorMessage);

  constructor(private readonly store: Store) {}
}
