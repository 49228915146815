import { ImagingStudy } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectImagingStudyId(imagingStudy: ImagingStudy): string {
  if (!imagingStudy.id) throw new Error('The imagingStudy is not created yet.');

  return imagingStudy.id;
}

export type State = EntityState<ImagingStudy>;

export const adapter: EntityAdapter<ImagingStudy> = createEntityAdapter<ImagingStudy>({
  selectId: selectImagingStudyId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { imagingStudies }) => {
    return adapter.upsertMany(imagingStudies, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectImagingStudyEntities = selectEntities;

export const selectAllImagingStudies = selectAll;
