import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemRoleCode: CodeSystemFlattened = {
  id: 'v3-RoleCode',
  codes: {
    _AffiliationRoleType: {
      display: 'AffiliationRoleType',
    },
    _AssignedRoleType: {
      display: 'AssignedRoleType',
    },
    _CertifiedEntityType: {
      display: 'CertifiedEntityType',
    },
    _CitizenRoleType: {
      display: 'CitizenRoleType',
    },
    _ContactRoleType: {
      display: 'ContactRoleType',
    },
    _IdentifiedEntityType: {
      display: 'IdentifiedEntityType',
    },
    _LivingSubjectProductionClass: {
      display: 'LivingSubjectProductionClass',
    },
    _MedicationGeneralizationRoleType: {
      display: 'MedicationGeneralizationRoleType',
    },
    _MemberRoleType: {
      display: 'MemberRoleType',
    },
    _PersonalRelationshipRoleType: {
      display: 'PersonalRelationshipRoleType',
    },
    _PolicyOrProgramCoverageRoleType: {
      display: 'PolicyOrProgramCoverageRoleType',
    },
    _ResearchSubjectRoleBasis: {
      display: 'ResearchSubjectRoleBasis',
    },
    _ServiceDeliveryLocationRoleType: {
      display: 'ServiceDeliveryLocationRoleType',
    },
    _SpecimenRoleType: {
      display: 'SpecimenRoleType',
    },
    CLAIM: {
      display: 'claimant',
    },
    communityLaboratory: {
      display: 'Community Laboratory',
    },
    GT: {
      display: 'Guarantor',
    },
    homeHealth: {
      display: 'Home Health',
    },
    laboratory: {
      display: 'Laboratory',
    },
    pathologist: {
      display: 'Pathologist',
    },
    PH: {
      display: 'Policy Holder',
    },
    phlebotomist: {
      display: 'Phlebotomist',
    },
    PROG: {
      display: 'program eligible',
    },
    PT: {
      display: 'Patient',
    },
    subject: {
      display: 'Self',
    },
    thirdParty: {
      display: 'Third Party',
    },
    DEP: {},
    DEPEN: {
      display: 'dependent',
    },
    FM: {
      display: 'Family Member',
    },
    INDIV: {
      display: 'individual',
    },
    NAMED: {
      display: 'named insured',
    },
    PSYCHCF: {},
    SUBSCR: {
      display: 'subscriber',
    },
    _AgentRoleType: {
      display: 'AgentRoleType',
    },
    _CoverageSponsorRoleType: {
      display: 'CoverageSponsorRoleType',
    },
    _PayorRoleType: {
      display: 'PayorRoleType',
    },
    RESPRSN: {
      display: 'responsible party',
    },
    AMENDER: {
      display: 'amender',
    },
    CLASSIFIER: {
      display: 'classifier',
    },
    CONSENTER: {
      display: 'consenter',
    },
    CONSWIT: {
      display: 'consent witness',
    },
    COPART: {
      display: 'co-participant',
    },
    DECLASSIFIER: {
      display: 'declassifier',
    },
    DELEGATEE: {
      display: 'delegatee',
    },
    DELEGATOR: {
      display: 'delegator',
    },
    DOWNGRDER: {
      display: 'downgrader',
    },
    DRIVCLASSIFIER: {
      display: 'derivative classifier',
    },
    GRANTEE: {
      display: 'grantee',
    },
    GRANTOR: {
      display: 'grantor',
    },
    INTPRTER: {
      display: 'interpreter',
    },
    REVIEWER: {
      display: 'reviewer',
    },
    VALIDATOR: {
      display: 'validator',
    },
    FULLINS: {
      display: 'Fully insured coverage sponsor',
    },
    SELFINS: {
      display: 'Self insured coverage sponsor',
    },
    ENROLBKR: {
      display: 'Enrollment Broker',
    },
    TPA: {
      display: 'Third party administrator',
    },
    UMO: {
      display: 'Utilization management organization',
    },
    EXCEST: {
      display: 'executor of estate',
    },
    GUADLTM: {
      display: 'guardian ad lidem',
    },
    GUARD: {
      display: 'guardian',
    },
    POWATT: {
      display: 'power of attorney',
    },
    DPOWATT: {
      display: 'durable power of attorney',
    },
    HPOWATT: {
      display: 'healthcare power of attorney',
    },
    SPOWATT: {
      display: 'special power of attorney',
    },
    _AssignedNonPersonLivingSubjectRoleType: {
      display: 'AssignedNonPersonLivingSubjectRoleType',
    },
    ASSIST: {
      display: 'Assistive non-person living subject',
    },
    BIOTH: {
      display: 'Biotherapeutic non-person living subject',
    },
    CCO: {
      display: 'Clinical Companion',
    },
    SEE: {
      display: 'Seeing',
    },
    SNIFF: {
      display: 'Sniffing',
    },
    ANTIBIOT: {
      display: 'Antibiotic',
    },
    DEBR: {
      display: 'Debridement',
    },
    CAS: {
      display: 'asylum seeker',
    },
    CN: {
      display: 'national',
    },
    CNRP: {
      display: 'non-country member without residence permit',
    },
    CPCA: {
      display: 'permit card applicant',
    },
    CRP: {
      display: 'non-country member with residence permit',
    },
    CASM: {
      display: 'single minor asylum seeker',
    },
    CNRPM: {
      display: 'non-country member minor without residence permit',
    },
    CRPM: {
      display: 'non-country member minor with residence permit',
    },
    _AdministrativeContactRoleType: {
      display: 'AdministrativeContactRoleType',
    },
    ECON: {
      display: 'emergency contact',
    },
    NOK: {
      display: 'next of kin',
    },
    BILL: {
      display: 'Billing Contact',
    },
    ORG: {
      display: 'organizational contact',
    },
    PAYOR: {
      display: 'Payor Contact',
    },
    _LocationIdentifiedEntityRoleCode: {
      display: 'LocationIdentifiedEntityRoleCode',
    },
    ACHFID: {
      display: 'accreditation location identifier',
    },
    JURID: {
      display: 'jurisdiction location identifier',
    },
    LOCHFID: {
      display: 'local location identifier',
    },
    BF: {
      display: 'Beef',
    },
    BL: {
      display: 'Broiler',
    },
    BR: {
      display: 'Breeder',
    },
    CO: {
      display: 'Companion',
    },
    DA: {
      display: 'Dairy',
    },
    DR: {
      display: 'Draft',
    },
    DU: {
      display: 'Dual',
    },
    FI: {
      display: 'Fiber',
    },
    LY: {
      display: 'Layer',
    },
    MT: {
      display: 'Meat',
    },
    MU: {
      display: 'Multiplier',
    },
    PL: {
      display: 'Pleasure',
    },
    RC: {
      display: 'Racing',
    },
    SH: {
      display: 'Show',
    },
    VL: {
      display: 'Veal',
    },
    WL: {
      display: 'Wool',
    },
    WO: {
      display: 'Working',
    },
    DC: {
      display: 'therapeutic class',
    },
    GD: {
      display: 'generic drug',
    },
    MGDSF: {
      display: 'manufactured drug strength form',
    },
    GDF: {
      display: 'generic drug form',
    },
    GDS: {
      display: 'generic drug strength',
    },
    GDSF: {
      display: 'generic drug strength form',
    },
    TRB: {
      display: 'Tribal Member',
    },
    FAMMEMB: {
      display: 'family member',
    },
    FRND: {
      display: 'unrelated friend',
    },
    NBOR: {
      display: 'neighbor',
    },
    ONESELF: {
      display: 'self',
    },
    ROOM: {
      display: 'Roommate',
    },
    CHILD: {
      display: 'child',
    },
    EXT: {
      display: 'extended family member',
    },
    PRN: {
      display: 'parent',
    },
    SIB: {
      display: 'sibling',
    },
    SIGOTHR: {
      display: 'significant other',
    },
    CHLDADOPT: {
      display: 'adopted child',
    },
    CHLDFOST: {
      display: 'foster child',
    },
    DAUC: {
      display: 'daughter',
    },
    NCHILD: {
      display: 'natural child',
    },
    SONC: {
      display: 'son',
    },
    STPCHLD: {
      display: 'step child',
    },
    DAUADOPT: {
      display: 'adopted daughter',
    },
    SONADOPT: {
      display: 'adopted son',
    },
    DAUFOST: {
      display: 'foster daughter',
    },
    SONFOST: {
      display: 'foster son',
    },
    DAU: {
      display: 'natural daughter',
    },
    STPDAU: {
      display: 'stepdaughter',
    },
    SON: {
      display: 'natural son',
    },
    STPSON: {
      display: 'stepson',
    },
    AUNT: {
      display: 'aunt',
    },
    COUSN: {
      display: 'cousin',
    },
    GGRPRN: {
      display: 'great grandparent',
    },
    GRNDCHILD: {
      display: 'grandchild',
    },
    GRPRN: {
      display: 'grandparent',
    },
    INLAW: {
      display: 'inlaw',
    },
    NIENEPH: {
      display: 'niece/nephew',
    },
    UNCLE: {
      display: 'uncle',
    },
    MAUNT: {
      display: 'maternal aunt',
    },
    PAUNT: {
      display: 'paternal aunt',
    },
    MCOUSN: {
      display: 'maternal cousin',
    },
    PCOUSN: {
      display: 'paternal cousin',
    },
    GGRFTH: {
      display: 'great grandfather',
    },
    GGRMTH: {
      display: 'great grandmother',
    },
    MGGRPRN: {
      display: 'maternal great-grandparent',
    },
    PGGRPRN: {
      display: 'paternal great-grandparent',
    },
    MGGRFTH: {
      display: 'maternal great-grandfather',
    },
    PGGRFTH: {
      display: 'paternal great-grandfather',
    },
    MGGRMTH: {
      display: 'maternal great-grandmother',
    },
    PGGRMTH: {
      display: 'paternal great-grandmother',
    },
    GRNDDAU: {
      display: 'granddaughter',
    },
    GRNDSON: {
      display: 'grandson',
    },
    GRFTH: {
      display: 'grandfather',
    },
    GRMTH: {
      display: 'grandmother',
    },
    MGRPRN: {
      display: 'maternal grandparent',
    },
    PGRPRN: {
      display: 'paternal grandparent',
    },
    MGRFTH: {
      display: 'maternal grandfather',
    },
    PGRFTH: {
      display: 'paternal grandfather',
    },
    MGRMTH: {
      display: 'maternal grandmother',
    },
    PGRMTH: {
      display: 'paternal grandmother',
    },
    CHLDINLAW: {
      display: 'child-in-law',
    },
    PRNINLAW: {
      display: 'parent in-law',
    },
    SIBINLAW: {
      display: 'sibling in-law',
    },
    DAUINLAW: {
      display: 'daughter in-law',
    },
    SONINLAW: {
      display: 'son in-law',
    },
    FTHINLAW: {
      display: 'father-in-law',
    },
    MTHINLAW: {
      display: 'mother-in-law',
    },
    BROINLAW: {
      display: 'brother-in-law',
    },
    SISINLAW: {
      display: 'sister-in-law',
    },
    NEPHEW: {
      display: 'nephew',
    },
    NIECE: {
      display: 'niece',
    },
    MUNCLE: {
      display: 'maternal uncle',
    },
    PUNCLE: {
      display: 'paternal uncle',
    },
    ADOPTP: {
      display: 'adoptive parent',
    },
    FTH: {
      display: 'father',
    },
    MTH: {
      display: 'mother',
    },
    NPRN: {
      display: 'natural parent',
    },
    PRNFOST: {
      display: 'foster parent',
    },
    STPPRN: {
      display: 'step parent',
    },
    ADOPTF: {
      display: 'adoptive father',
    },
    ADOPTM: {
      display: 'adoptive mother',
    },
    FTHFOST: {
      display: 'foster father',
    },
    NFTH: {
      display: 'natural father',
    },
    STPFTH: {
      display: 'stepfather',
    },
    NFTHF: {
      display: 'natural father of fetus',
    },
    GESTM: {
      display: 'gestational mother',
    },
    MTHFOST: {
      display: 'foster mother',
    },
    NMTH: {
      display: 'natural mother',
    },
    STPMTH: {
      display: 'stepmother',
    },
    NMTHF: {
      display: 'natural mother of fetus',
    },
    BRO: {
      display: 'brother',
    },
    HSIB: {
      display: 'half-sibling',
    },
    NSIB: {
      display: 'natural sibling',
    },
    SIS: {
      display: 'sister',
    },
    STPSIB: {
      display: 'step sibling',
    },
    HBRO: {
      display: 'half-brother',
    },
    NBRO: {
      display: 'natural brother',
    },
    STPBRO: {
      display: 'stepbrother',
    },
    TWINBRO: {
      display: 'twin brother',
    },
    FTWINBRO: {
      display: 'fraternal twin brother',
    },
    ITWINBRO: {
      display: 'identical twin brother',
    },
    HSIS: {
      display: 'half-sister',
    },
    NSIS: {
      display: 'natural sister',
    },
    TWIN: {
      display: 'twin',
    },
    TWINSIS: {
      display: 'twin sister',
    },
    FTWINSIS: {
      display: 'fraternal twin sister',
    },
    ITWINSIS: {
      display: 'identical twin sister',
    },
    FTWIN: {
      display: 'fraternal twin',
    },
    ITWIN: {
      display: 'identical twin',
    },
    STPSIS: {
      display: 'stepsister',
    },
    DOMPART: {
      display: 'domestic partner',
    },
    FMRSPS: {
      display: 'former spouse',
    },
    SPS: {
      display: 'spouse',
    },
    HUSB: {
      display: 'husband',
    },
    WIFE: {
      display: 'wife',
    },
    _CoverageRoleType: {
      display: 'CoverageRoleType',
    },
    _CoveredPartyRoleType: {
      display: 'covered party role type',
    },
    FAMDEP: {
      display: 'family dependent',
    },
    HANDIC: {
      display: 'handicapped dependent',
    },
    INJ: {
      display: 'injured plaintiff',
    },
    SELF: {
      display: 'self',
    },
    SPON: {
      display: 'sponsored dependent',
    },
    STUD: {
      display: 'student',
    },
    ADOPT: {
      display: 'adopted child',
    },
    GCHILD: {
      display: 'grandchild',
    },
    GPARNT: {
      display: 'grandparent',
    },
    NAT: {
      display: 'natural child',
    },
    NIENE: {
      display: 'niece/nephew',
    },
    PARNT: {
      display: 'parent',
    },
    SPSE: {
      display: 'spouse',
    },
    STEP: {
      display: 'step child',
    },
    FSTUD: {
      display: 'full-time student',
    },
    PSTUD: {
      display: 'part-time student',
    },
    _ClaimantCoveredPartyRoleType: {
      display: 'ClaimantCoveredPartyRoleType',
    },
    _DependentCoveredPartyRoleType: {
      display: 'DependentCoveredPartyRoleType',
    },
    _IndividualInsuredPartyRoleType: {
      display: 'IndividualInsuredPartyRoleType',
    },
    _ProgramEligiblePartyRoleType: {
      display: 'ProgramEligiblePartyRoleType',
    },
    _SubscriberCoveredPartyRoleType: {
      display: 'SubscriberCoveredPartyRoleType',
    },
    CRIMEVIC: {
      display: 'crime victim',
    },
    INJWKR: {
      display: 'injured worker',
    },
    COCBEN: {
      display: 'continuity of coverage beneficiary',
    },
    DIFFABL: {
      display: 'differently abled',
    },
    WARD: {
      display: 'ward',
    },
    RETIREE: {
      display: 'retiree',
    },
    INDIG: {
      display: 'member of an indigenous people',
    },
    MIL: {
      display: 'military',
    },
    ACTMIL: {
      display: 'active duty military',
    },
    RETMIL: {
      display: 'retired military',
    },
    VET: {
      display: 'veteran',
    },
    ERL: {
      display: 'enrollment',
    },
    SCN: {
      display: 'screening',
    },
    _DedicatedServiceDeliveryLocationRoleType: {
      display: 'DedicatedServiceDeliveryLocationRoleType',
    },
    _IncidentalServiceDeliveryLocationRoleType: {
      display: 'IncidentalServiceDeliveryLocationRoleType',
    },
    _DedicatedClinicalLocationRoleType: {
      display: 'DedicatedClinicalLocationRoleType',
    },
    _DedicatedNonClinicalLocationRoleType: {
      display: 'DedicatedNonClinicalLocationRoleType',
    },
    DX: {
      display: 'Diagnostics or therapeutics unit',
    },
    HOSP: {
      display: 'Hospital',
    },
    HU: {
      display: 'Hospital unit',
    },
    NCCF: {
      display: 'Nursing or custodial care facility',
    },
    OF: {
      display: 'Outpatient facility',
    },
    RTF: {
      display: 'Residential treatment facility',
    },
    CVDX: {
      display: 'Cardiovascular diagnostics or therapeutics unit',
    },
    GIDX: {
      display: 'Gastroenterology diagnostics or therapeutics lab',
    },
    RADDX: {
      display: 'Radiology diagnostics or therapeutics unit',
    },
    CATH: {
      display: 'Cardiac catheterization lab',
    },
    ECHO: {
      display: 'Echocardiography lab',
    },
    ENDOS: {
      display: 'Endoscopy lab',
    },
    RADO: {
      display: 'Radiation oncology unit',
    },
    RNEU: {
      display: 'Neuroradiology unit',
    },
    CHR: {
      display: 'Chronic Care Facility',
    },
    GACH: {
      display: 'Hospitals; General Acute Care Hospital',
    },
    MHSP: {
      display: 'Military Hospital',
    },
    PSYCHF: {
      display: 'Psychatric Care Facility',
    },
    RH: {
      display: 'Rehabilitation hospital',
    },
    RHAT: {
      display: 'addiction treatment center',
    },
    RHII: {
      display: 'intellectual impairment center',
    },
    RHMAD: {
      display: 'parents with adjustment difficulties center',
    },
    RHPI: {
      display: 'physical impairment center',
    },
    RHYAD: {
      display: 'youths with adjustment difficulties center',
    },
    RHPIH: {
      display: 'physical impairment - hearing center',
    },
    RHPIMS: {
      display: 'physical impairment - motor skills center',
    },
    RHPIVS: {
      display: 'physical impairment - visual skills center',
    },
    BMTU: {
      display: 'Bone marrow transplant unit',
    },
    CCU: {
      display: 'Coronary care unit',
    },
    CHEST: {
      display: 'Chest unit',
    },
    EPIL: {
      display: 'Epilepsy unit',
    },
    ER: {
      display: 'Emergency room',
    },
    HD: {
      display: 'Hemodialysis unit',
    },
    HLAB: {
      display: 'hospital laboratory',
    },
    HRAD: {
      display: 'radiology unit',
    },
    HUSCS: {
      display: 'specimen collection site',
    },
    ICU: {
      display: 'Intensive care unit',
    },
    INPHARM: {
      display: 'inpatient pharmacy',
    },
    MBL: {
      display: 'medical laboratory',
    },
    NCCS: {
      display: 'Neurology critical care and stroke unit',
    },
    NS: {
      display: 'Neurosurgery unit',
    },
    OUTPHARM: {
      display: 'outpatient pharmacy',
    },
    PEDU: {
      display: 'Pediatric unit',
    },
    PHU: {
      display: 'Psychiatric hospital unit',
    },
    RHU: {
      display: 'Rehabilitation hospital unit',
    },
    SLEEP: {
      display: 'Sleep disorders unit',
    },
    ETU: {
      display: 'Emergency trauma unit',
    },
    INLAB: {
      display: 'inpatient laboratory',
    },
    OUTLAB: {
      display: 'outpatient laboratory',
    },
    PEDICU: {
      display: 'Pediatric intensive care unit',
    },
    PEDNICU: {
      display: 'Pediatric neonatal intensive care unit',
    },
    SNF: {
      display: 'Skilled nursing facility',
    },
    ALL: {
      display: 'Allergy clinic',
    },
    AMPUT: {
      display: 'Amputee clinic',
    },
    BMTC: {
      display: 'Bone marrow transplant clinic',
    },
    BREAST: {
      display: 'Breast clinic',
    },
    CANC: {
      display: 'Child and adolescent neurology clinic',
    },
    CAPC: {
      display: 'Child and adolescent psychiatry clinic',
    },
    CARD: {
      display: 'Ambulatory Health Care Facilities; Clinic/Center; Rehabilitation: Cardiac Facilities',
    },
    COAG: {
      display: 'Coagulation clinic',
    },
    CRS: {
      display: 'Colon and rectal surgery clinic',
    },
    DERM: {
      display: 'Dermatology clinic',
    },
    ENDO: {
      display: 'Endocrinology clinic',
    },
    ENT: {
      display: 'Otorhinolaryngology clinic',
    },
    FMC: {
      display: 'Family medicine clinic',
    },
    GI: {
      display: 'Gastroenterology clinic',
    },
    GIM: {
      display: 'General internal medicine clinic',
    },
    GYN: {
      display: 'Gynecology clinic',
    },
    HEM: {
      display: 'Hematology clinic',
    },
    HTN: {
      display: 'Hypertension clinic',
    },
    IEC: {
      display: 'Impairment evaluation center',
    },
    INFD: {
      display: 'Infectious disease clinic',
    },
    INV: {
      display: 'Infertility clinic',
    },
    LYMPH: {
      display: 'Lympedema clinic',
    },
    MGEN: {
      display: 'Medical genetics clinic',
    },
    NEPH: {
      display: 'Nephrology clinic',
    },
    NEUR: {
      display: 'Neurology clinic',
    },
    OB: {
      display: 'Obstetrics clinic',
    },
    OMS: {
      display: 'Oral and maxillofacial surgery clinic',
    },
    ONCL: {
      display: 'Medical oncology clinic',
    },
    OPH: {
      display: 'Opthalmology clinic',
    },
    OPTC: {
      display: 'optometry clinic',
    },
    ORTHO: {
      display: 'Orthopedics clinic',
    },
    PAINCL: {
      display: 'Pain clinic',
    },
    PC: {
      display: 'Primary care clinic',
    },
    PEDC: {
      display: 'Pediatrics clinic',
    },
    POD: {
      display: 'Podiatry clinic',
    },
    PREV: {
      display: 'Preventive medicine clinic',
    },
    PROCTO: {
      display: 'Proctology clinic',
    },
    PROFF: {
      display: "Provider's Office",
    },
    PROS: {
      display: 'Prosthodontics clinic',
    },
    PSI: {
      display: 'Psychology clinic',
    },
    PSY: {
      display: 'Psychiatry clinic',
    },
    RHEUM: {
      display: 'Rheumatology clinic',
    },
    SPMED: {
      display: 'Sports medicine clinic',
    },
    SU: {
      display: 'Surgery clinic',
    },
    TR: {
      display: 'Transplant clinic',
    },
    TRAVEL: {
      display: 'Travel and geographic medicine clinic',
    },
    WND: {
      display: 'Wound clinic',
    },
    PEDCARD: {
      display: 'Pediatric cardiology clinic',
    },
    PEDE: {
      display: 'Pediatric endocrinology clinic',
    },
    PEDGI: {
      display: 'Pediatric gastroenterology clinic',
    },
    PEDHEM: {
      display: 'Pediatric hematology clinic',
    },
    PEDID: {
      display: 'Pediatric infectious disease clinic',
    },
    PEDNEPH: {
      display: 'Pediatric nephrology clinic',
    },
    PEDHO: {
      display: 'Pediatric oncology clinic',
    },
    HAND: {
      display: 'Hand clinic',
    },
    PEDRHEUM: {
      display: 'Pediatric rheumatology clinic',
    },
    PLS: {
      display: 'Plastic surgery clinic',
    },
    URO: {
      display: 'Urology clinic',
    },
    PRC: {
      display: 'Pain rehabilitation center',
    },
    SURF: {
      display: 'Substance use rehabilitation facility',
    },
    DADDR: {
      display: 'Delivery Address',
    },
    MOBL: {
      display: 'Mobile Unit',
    },
    PHARM: {
      display: 'Pharmacy',
    },
    AMB: {
      display: 'Ambulance',
    },
    ACC: {
      display: 'accident site',
    },
    COMM: {
      display: 'Community Location',
    },
    PTRES: {
      display: "Patient's Residence",
    },
    SCHOOL: {
      display: 'school',
    },
    UPC: {
      display: 'underage protection center',
    },
    WORK: {
      display: 'work site',
    },
    CSC: {
      display: 'community service center',
    },
    C: {
      display: 'Calibrator',
    },
    G: {
      display: 'Group',
    },
    L: {
      display: 'Pool',
    },
    P: {
      display: 'Patient',
    },
    Q: {
      display: 'Quality Control',
    },
    R: {
      display: 'Replicate',
    },
    B: {
      display: 'Blind',
    },
    E: {
      display: 'Electronic QC',
    },
    F: {
      display: 'Filler Proficiency',
    },
    O: {
      display: 'Operator Proficiency',
    },
    V: {
      display: 'Verifying',
    },
  },
};
