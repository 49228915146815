import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const AddressType: CodeSystemFlattened = {
  id: 'address-type',
  codes: {
    postal: {
      display: 'Postal',
    },
    physical: {
      display: 'Physical',
    },
    both: {
      display: 'Postal & Physical',
    },
  },
};
