import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'observationStatus',
})
export class ObservationStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'registered':
        return $localize`:@@fhir.observation-status.registered:Registered`;
      case 'preliminary':
        return $localize`:@@fhir.observation-status.preliminary:Preliminary`;
      case 'final':
        return $localize`:@@fhir.observation-status.final:Final`;
      case 'amended':
        return $localize`:@@fhir.observation-status.amended:Amended`;
      case 'corrected':
        return $localize`:@@fhir.observation-status.corrected:Corrected`;
      case 'cancelled':
        return $localize`:@@fhir.observation-status.cancelled:Cancelled`;
      case 'entered-in-error':
        return $localize`:@@fhir.observation-status.entered-in-error:Entered in Error`;
      case 'unknown':
        return $localize`:@@fhir.observation-status.unknown:Unknown`;
      default:
        return '';
    }
  }
}
