export const GLUCOSE_DAG_CURVE_GDC = '355';
export const GLUCOSE_NIET_NUCHTER_VENEUS = '371';
export const GLUCOSE_NIET_NUCHTER_DRAAGBARE_METER = '381';
export const GLUCOSE_NUCHTER_DRAAGBARE_METER = '382';
export const GLUCOSE_DAG_CURVE_GDC_DRAAGBARE_METER = '3221';
export const GLUCOSE_DAG_CURVE_2U_NA_DINER_DRAAGBARE_METER = '3222';
export const GLUCOSE_DAG_CURVE_2U_NA_LUNCH_DRAAGBARE_METER = '3223';
export const GLUCOSE_DAG_CURVE_2U_NA_ONTBIJT_DRAAGBARE_METER = '3224';
export const GLUCOSE_DAG_CURVE_VOOR_DE_LUNCH_DRAAGBARE_METER = '3225';
export const GLUCOSE_DAG_CURVE_VOOR_DE_NACHT_DRAAGBARE_METER = '3226';
export const GLUCOSE_DAG_CURVE_VOOR_HET_DINER_DRAAGBARE_METER = '3227';

export const SYSTOLISCHE_BLOEDDRUK = '1744';
export const DIASTOLISCHE_BLOEDDRUK = '1740';
