import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavigatorOnLineService, PreviousRouteService } from '@core/services';
import { configId, environment } from '@environments/environment';
import { FreshdeskService } from '@freshdesk';
import { MixpanelService } from '@logging';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { APP_ACTIONS, IDLE_ACTIONS } from '@store/app';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly oidcEventService: PublicEventsService,
    readonly router: Router,
    private zone: NgZone,
    private previousRouteService: PreviousRouteService,
    private readonly mixpanelService: MixpanelService,
    private readonly freshdeskService: FreshdeskService,
    private readonly navigatorService: NavigatorOnLineService,
    private readonly idle: Idle
  ) {
    this.initializeApp();

    // Configure idle detection
    // set idle parameters
    this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(environment.sessionTimeout); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.store.dispatch(IDLE_ACTIONS.start());
    });

    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.store.dispatch(IDLE_ACTIONS.end());
    });

    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.store.dispatch(IDLE_ACTIONS.timeout());
    });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        let slug = event.url.split(':/').pop();
        if (slug) {
          slug = slug.replace('callback/', 'callback');
          slug = slug.replace('return/', 'return');
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    this.previousRouteService.startHistoryTracking();
    this.mixpanelService.init();
    this.navigatorService.init();
    this.freshdeskService.loadWidget();
  }

  ngOnInit(): void {
    this.oidcEventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.SilentRenewFailed))
      .subscribe((_) => {
        this.store.dispatch(APP_ACTIONS.authenticationFailed());
      });

    this.oidcSecurityService.checkAuth(`${window.location.origin}`, configId).subscribe({
      next: (response) => {
        const countAsVisit = !window.location.href.includes('/callback') && !window.location.href.includes('/return?');
        if (countAsVisit) {
          this.store.dispatch(APP_ACTIONS.userVisit());
        }

        if (response.isAuthenticated) {
          const isReturn = window.location.href.includes('/return?');
          this.store.dispatch(APP_ACTIONS.authenticated({ isReturn: isReturn }));
        }

        if (!response.isAuthenticated) {
          this.store.dispatch(APP_ACTIONS.unauthenticated());
        }
      },
      error: (error) => {
        this.store.dispatch(APP_ACTIONS.authenticationError({ error: error }));
        this.router.navigate(['onboard-or-log-in']);
      },
    });

    this.idle.watch();
  }
}
