import { Pipe, PipeTransform } from '@angular/core';
import { Ratio } from '../../../fhir.types';

@Pipe({
  name: 'ratio',
})
export class RatioPipe implements PipeTransform {
  transform(ratio: Ratio | null | undefined): string {
    if (!ratio || !ratio.numerator || !ratio.denominator) {
      return '';
    }

    return `${ratio.numerator.value} / ${ratio.denominator.value}`;
  }
}
