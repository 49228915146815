import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MAT_DATE_CUSTOM_FORMAT_PROVIDER = {
  provide: MAT_DATE_FORMATS,
  useValue: {
    parse: {
      dateInput: 'DD/MM/YYYY', // This is how your date will be parsed from Input
    },
    display: {
      dateInput: 'DD MMM YYYY', // This is how your date will get displayed on the Input
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  },
};
