import { NutritionOrderOralDietTexture } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';

export class NutritionOrderOralDietTextureViewModel extends ElementViewModel<NutritionOrderOralDietTexture> {
  get foodType(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.foodType);
  }

  get modifier(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.modifier);
  }
}
