import { NgModule } from '@angular/core';
import { YesNoPipe } from './yes-no.pipe';

const pipes = [YesNoPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class BooleanModule {}
