import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ButtonComponent } from '@layout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { take } from 'rxjs';
import { AccountWarnings } from '../account-warnings.model';

@Component({
  standalone: true,
  selector: 'app-account-warnings-modal',
  templateUrl: './account-warnings-modal.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class AccountWarningsModalComponent implements OnChanges {
  @ViewChild('accountWarningsModal', { static: false }) modal!: NgbModal;

  @Input({ required: true }) openModal!: boolean;
  @Input({ required: true }) source!: AccountWarnings;

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { openModal } = changes;

    if (openModal?.currentValue === true) {
      const modalRef = this.modalService.open(this.modal, { centered: true });

      modalRef.hidden.pipe(take(1)).subscribe(() => {
        this.closeModal.emit();
      });
    }
  }
}
