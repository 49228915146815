import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import * as r5 from 'fhir/r5';

export class ImmunizationProgramEligibilityViewModel extends BackboneElementViewModel<r5.ImmunizationProgramEligibility> {
  get documentType(): string | undefined {
    return this.element?.program && new CodeableConceptPipe().transform(this.element.program);
  }

  get reference(): string | undefined {
    return this.element?.programStatus && new CodeableConceptPipe().transform(this.element.programStatus);
  }
}
