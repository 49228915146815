<div *ngIf="timingRepeat"
     class="border-top border-secondary">
  <div data-cy="timing-repeat-header"
       *ngIf="timingRepeat"
       class="p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span class="h6">{{ timingRepeatHeader }}</span>
  </div>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.boundsDuration">Bounds Duration</label>
    <span data-cy="timing-repeat-boundsDuration">{{ timingRepeat.boundsDuration | duration }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.boundsRange">Bounds Range</label>
    <span data-cy="timing-repeat-boundsRange">{{ timingRepeat.boundsRange | range }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.boundsPeriod">Bounds Period</label>
    <span data-cy="timing-repeat-boundsPeriod">{{ timingRepeat.boundsPeriod | period }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.count">Count</label>
    <span data-cy="timing-repeat-count">{{ timingRepeat.count }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.countMax">Count Max</label>
    <span data-cy="timing-repeat-countMax">{{ timingRepeat.countMax }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.duration">Duration</label>
    <span data-cy="timing-repeat-duration">{{ timingRepeat.duration }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.durationMax">Duration Max</label>
    <span data-cy="timing-repeat-durationMax">{{ timingRepeat.durationMax }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.durationUnit">Duration Unit</label>
    <span data-cy="timing-repeat-durationUnit">{{ timingRepeat.durationUnit | unit }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.frequency">Frequency</label>
    <span data-cy="timing-repeat-frequency">{{ timingRepeat.frequency }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.frequencyMax">Frequency Max</label>
    <span data-cy="timing-repeat-frequencyMax">{{ timingRepeat.frequencyMax }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.offset">Offset</label>
    <span data-cy="timing-repeat-offset">{{ timingRepeat.offset }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.period">Period</label>
    <span data-cy="timing-repeat-period">{{ timingRepeat.period }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.periodMax">Period Max</label>
    <span data-cy="timing-repeat-periodMax">{{ timingRepeat.periodMax }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.periodUnit">Period Unit</label>
    <span data-cy="timing-repeat-periodUnit">{{ timingRepeat.periodUnit | unit }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.timeOfDay">Time of Day</label>
    <span data-cy="timing-repeat-timeOfDay">{{ timingRepeat.timeOfDay | join:', ' }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.dayOfWeek">Day of Week</label>
    <span data-cy="timing-repeat-dayOfWeek">{{ timingRepeat.dayOfWeek | dayOfWeek }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.TimingRepeat.when">When</label>
    <span data-cy="timing-repeat-when">{{ timingRepeat.when | join:', ' }}</span>
  </app-entry>
</div>
