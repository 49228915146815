import { ErrorCodes } from '@globals';
import { createReducer, on } from '@ngrx/store';
import { APP_ACTIONS, ERROR_UI_ACTIONS, MODAL_ACTIONS } from './app.actions';

export interface AppState {
  isAuthenticated: boolean;
  error: {
    data: any;
  };
  showingErrorModal: boolean;
  hasSessionTimedOut: boolean;
  modal: string | null;
}

export const initialState: AppState = {
  isAuthenticated: false,
  error: {
    data: null,
  },
  showingErrorModal: false,
  hasSessionTimedOut: false,
  modal: null,
};

export const reducer = createReducer(
  initialState,
  on(APP_ACTIONS.authenticated, (state): AppState => {
    return { ...state, isAuthenticated: true };
  }),
  on(APP_ACTIONS.timeoutLogout, (state): AppState => {
    return { ...state, isAuthenticated: false };
  }),
  on(APP_ACTIONS.unauthenticated, (state): AppState => {
    return { ...state, isAuthenticated: false };
  }),
  on(ERROR_UI_ACTIONS.showErrorMessage, (state, action): AppState => {
    return {
      ...state,
      error: {
        ...state.error,
        data: action.code === ErrorCodes.userCancel ? undefined : action.code,
      },
      showingErrorModal: true,
    };
  }),
  on(ERROR_UI_ACTIONS.resetErrorMessage, (_state): AppState => {
    return { ..._state, showingErrorModal: false };
  }),
  on(APP_ACTIONS.timeoutLogout, (): AppState => {
    return { ...initialState, hasSessionTimedOut: true };
  }),
  on(MODAL_ACTIONS.showModal, (state, action): AppState => {
    return { ...state, modal: action.modal };
  }),
  on(MODAL_ACTIONS.resetState, (state, _action): AppState => {
    return { ...state, modal: null };
  })
);
