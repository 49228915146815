import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[] | any, field: string, direction = 'asc'): any[] | any {
    if (!Array.isArray(array)) return array;

    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1 * (direction === 'desc' ? -1 : 1);
      } else if (a[field] > b[field]) {
        return 1 * (direction === 'desc' ? -1 : 1);
      } else {
        return 0;
      }
    });
    return array;
  }
}
