import { Observation, Timing } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { AnnotationViewModel, CodeableConceptPipe, IdentifierViewModel, getChoiceOfType } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { ObservationStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { ObservationComponentViewModel } from './observation-component.viewmodel';
import { ObservationReferenceViewModel } from './observation-reference.viewmodel';

export class ObservationViewModel extends DomainResourceViewModel<Observation> {
  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get effective(): string | undefined {
    return getChoiceOfType({
      dateTime: this.resource?.effectiveDateTime,
      period: this.resource?.effectivePeriod,
      timing: this.effectiveTiming,
      instant: this.effectiveInstant,
    });
  }

  get basedOn(): string | undefined {
    return getReferences(this.resource?.basedOn);
  }

  get partOf(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.partOf);
  }

  get status(): string | undefined {
    return new ObservationStatusPipe().transform(this.resource?.status);
  }

  get category(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.category);
  }

  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.code);
  }

  get subject(): string | undefined {
    return getReference(this.resource?.subject);
  }

  get focus(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.focus);
  }

  get context(): string | undefined {
    const observationR3 = this.resource as r3.Observation;
    return getReference(observationR3.context);
  }

  get encounter(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReference(observationR?.encounter);
  }

  get issued(): string | undefined {
    return this.resource?.issued;
  }

  get performer(): string | undefined {
    return getReferences(this.resource?.performer);
  }

  get value(): string | undefined {
    return getChoiceOfType({
      dateTime: this.resource?.valueDateTime,
      period: this.resource?.valuePeriod,
      time: this.resource?.valueTime,
      string: this.resource?.valueString,
      boolean: this.resource?.valueBoolean,
      ratio: this.resource?.valueRatio,
      sampledData: this.resource?.valueSampledData,
      quantity: this.resource?.valueQuantity,
      range: this.resource?.valueRange,
      codeableConcept: this.resource?.valueCodeableConcept,
    });
  }

  get dataAbsentReason(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.dataAbsentReason);
  }

  get interpretation(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.interpretation);
  }

  get comment(): string | undefined {
    const observationR3 = this.resource as r3.Observation;
    return observationR3?.comment;
  }

  get notes(): AnnotationViewModel[] | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return observationR?.note?.map((n) => new AnnotationViewModel(n, this.fhirVersion));
  }

  get bodySite(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.bodySite);
  }

  get method(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.method);
  }

  get specimen(): string | undefined {
    return getReference(this.resource?.specimen);
  }

  get device(): string | undefined {
    return getReference(this.resource?.device);
  }

  get referenceRanges(): ObservationReferenceViewModel[] | undefined {
    return this.resource?.referenceRange?.map(
      (referenceRange) => new ObservationReferenceViewModel(referenceRange, this.fhirVersion)
    );
  }

  get hasMember(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.hasMember);
  }

  get derivedFrom(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.derivedFrom);
  }

  get components(): ObservationComponentViewModel[] | undefined {
    return this.resource?.component?.map((component) => new ObservationComponentViewModel(component, this.fhirVersion));
  }

  get profileType(): ProfileType {
    if (
      this.resource?.category?.some((category) =>
        category?.coding?.some(
          (coding) =>
            coding && coding.system === CodeSystems.SNOMED && ['118228005', '384821006'].includes(coding.code ?? '')
        )
      )
    ) {
      return ProfileType.functionalOrMentalStatus;
    }

    if (
      this.resource?.meta?.profile?.some(
        (profile) => profile === StructureDefinition.Nictiz.OBSERVATION.gpLaboratoryResult
      )
    ) {
      return ProfileType.laboratory;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['365508006'].includes(coding.code ?? '')
      )
    ) {
      return ProfileType.livingSituation;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['228366006'].includes(coding.code ?? '')
      )
    ) {
      return ProfileType.drugUse;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['365980008'].includes(coding.code ?? '')
      )
    ) {
      return ProfileType.tobaccoUse;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['228273003'].includes(coding.code ?? '')
      )
    ) {
      return ProfileType.alcoholUse;
    }

    return ProfileType.default;
  }

  private get effectiveTiming(): Timing | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return observationR?.effectiveTiming;
  }

  private get effectiveInstant(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return observationR?.effectiveInstant;
  }
}

export enum ProfileType {
  default = 0,
  functionalOrMentalStatus = 1,
  laboratory = 2,
  livingSituation = 3,
  drugUse = 4,
  tobaccoUse = 5,
  alcoholUse = 6,
}
