import { CompositionRelatesTo } from '@hl7fhir';
import { getChoiceOfType } from '@hl7fhir/data-types';
import { CompositionRelateToCodePipe } from '@hl7fhir/value-sets';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class CompositionRelatesToViewModel extends ElementViewModel<CompositionRelatesTo> {
  get code(): string | undefined {
    return (
      this.compositionRelatesTo.code && new CompositionRelateToCodePipe().transform(this.compositionRelatesTo?.code)
    );
  }

  get target(): string | undefined {
    return getChoiceOfType({
      //TODO: Implement identifier in choice of type
      //identifier: this.compositionRelatesTo?.targetIdentifier,
      reference: this.compositionRelatesTo?.targetReference,
    });
  }

  private get compositionRelatesTo(): r3.CompositionRelatesTo | r4.CompositionRelatesTo | r4b.CompositionRelatesTo {
    return this.element as r3.CompositionRelatesTo | r4.CompositionRelatesTo | r4b.CompositionRelatesTo;
  }
}
