import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionClinicalStatus',
})
export class ConditionClinicalStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      // >= R3
      case 'active':
        return $localize`:@@fhir.condition-clinical-status.active:Active`;
      case 'recurrence':
        return $localize`:@@fhir.condition-clinical-status.recurrence:Recurrence`;
      case 'inactive':
        return $localize`:@@fhir.condition-clinical-status.inactive:Inactive`;
      case 'remission':
        return $localize`:@@fhir.condition-clinical-status.remission:Remission`;
      case 'resolved':
        return $localize`:@@fhir.condition-clinical-status.resolved:Resolved`;
      // >= R4
      case 'relapse':
        return $localize`:@@fhir.condition-clinical-status.relapse:Relapse`;
      // >= R5
      case 'unknown':
        return $localize`:@@fhir.condition-clinical-status.unknown:Unknown`;
      default:
        return '';
    }
  }
}
