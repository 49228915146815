import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { AgeTypePipe } from './pipes/age.pipe';

const pipes = [AgeTypePipe];

@NgModule({
  imports: [SharedModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class AgeModule {}
