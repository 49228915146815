import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const TelecomTypeCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.20.6.1--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/AddressUse': {
      MC: {
        'nl-NL': 'Mobiel telefoonnummer',
        display: 'Mobile Phone',
      },
      PG: {
        'nl-NL': 'Pieper',
        display: 'Pager',
      },
    },
    'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.22.1': {
      LL: {
        'nl-NL': 'Vast telefoonnummer',
        display: 'Land Line',
      },
      FAX: {
        'nl-NL': 'Fax',
        display: 'Fax',
      },
    },
  },
};
