import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentReferenceDocStatus',
})
export class DocumentReferenceDocStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'preliminary':
        return $localize`:@@fhir.document-reference-doc-status.preliminary:Preliminary`;
      case 'final':
        return $localize`:@@fhir.document-reference-doc-status.final:Final`;
      case 'appended':
        return $localize`:@@fhir.document-reference-doc-status.appended:Appended`;
      case 'amended':
        return $localize`:@@fhir.document-reference-doc-status.amended:Amended`;
      case 'entered-in-error':
        return $localize`:@@fhir.document-reference-doc-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
