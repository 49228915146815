import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const BehandelingCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.2.1.1--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '305351004': {
        'nl-NL': 'Opname op intensive care',
        display: 'Admission to intensive care unit (procedure)',
      },
      '89666000': {
        'nl-NL': 'Cardiopulmonaire resuscitatie',
        display: 'Cardiopulmonary resuscitation (procedure)',
      },
      '40617009': {
        'nl-NL': 'Kunstmatige beademing',
        display: 'Artificial respiration (procedure)',
      },
      '116762002': {
        'nl-NL': 'Toediening van een bloedproduct',
        display: 'Administration of blood product (procedure)',
      },
    },
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Overige behandelingen, namelijk (in vrije tekst meegeven)',
        display: 'Other',
      },
    },
  },
};
