import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const ConsentExceptType: CodeSystemFlattened = {
  id: 'consent-except-type',
  codes: {
    deny: {
      display: 'Opt Out',
    },
    permit: {
      display: 'Opt In',
    },
  },
};
