<div class="main d-flex flex-grow-1 w-100">
  <div class="main-content flex-grow-1 px-3 px-md-4">

    @if((success$ | async) === true && (mustCreateAccount$ | async) === false && (mustOnboard$ | async) === false) {
    <router-outlet data-cy="main-router-outlet"></router-outlet>
    }

    <app-digi-me-loading [loading]="loading$ | async"
                         data-cy="main-loading"></app-digi-me-loading>
    <app-digi-me-onboard-container data-cy="main-onboard"
                                   *ngIf="mustOnboard$ | async"></app-digi-me-onboard-container>

    @if((mustCreateAccount$ | async) === true) {
    <app-account-create-container></app-account-create-container>
    }
  </div>
</div>