import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { getReference } from '@hl7fhir/foundation';
import { CodeableConceptPipe, PeriodTypePipe, QuantityTypePipe, getChoiceOfType } from '@hl7fhir/data-types';
import { SpecimenCollection } from 'src/app/fhir/fhir.types';

export class SpecimenCollectionViewModel extends BackboneElementViewModel<SpecimenCollection> {
  private readonly specColR344b = this.element as
    | r3.SpecimenCollection
    | r4.SpecimenCollection
    | r4b.SpecimenCollection;
  private readonly specColR44b5 = this.element as
    | r4.SpecimenCollection
    | r4b.SpecimenCollection
    | r5.SpecimenCollection;
  private readonly specColR5 = this.element as r5.SpecimenCollection;

  get collector(): string | undefined {
    return this.element?.collector && getReference(this.element.collector);
  }

  get collectedDateTime(): string | undefined {
    return this.element?.collectedDateTime && formatLocaleDate(this.element.collectedDateTime, 'long');
  }

  get collectedPeriod(): string | undefined {
    return this.element?.collectedPeriod && new PeriodTypePipe().transform(this.element.collectedPeriod);
  }

  get quantity(): string | undefined {
    return this.element?.quantity && new QuantityTypePipe().transform(this.element.quantity);
  }

  get method(): string | undefined {
    return this.element?.method && new CodeableConceptPipe().transform(this.element.method);
  }

  //Changed to CodeableReference in R5
  get bodySite(): string | undefined {
    return (
      (this.specColR344b?.bodySite && getReference(this.specColR344b.bodySite)) ??
      (this.specColR5?.bodySite && getReference(this.specColR5.bodySite.reference))
    );
  }

  //R4, R4b, R5 only
  get fastingStatus() {
    return getChoiceOfType({
      codeableConcept: this.specColR44b5?.fastingStatusCodeableConcept,
      //TODO: Support duration for R4 and R5
      //duration: this.specColR44b5?.fastingStatusDuration,
    });
  }

  //R5 only
  get device(): string | undefined {
    return this.specColR5?.device && getReference(this.specColR5.device.reference);
  }

  //R5 only
  get procedure(): string | undefined {
    return this.specColR5?.procedure && getReference(this.specColR5.procedure);
  }
}
