import { NgModule } from '@angular/core';
import { VersionPipe } from './version.pipe';

const pipes = [VersionPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class VersionModule {}
