import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '@shared';
import { PreviousRouteService } from '@core/services';

@Component({
  standalone: true,
  selector: 'app-back-arrow',
  imports: [SharedModule],
  templateUrl: './back-arrow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackArrowComponent {
  @Input() defaultUrl: string | undefined;

  constructor(private previousRouteService: PreviousRouteService) {}

  goBack(): void {
    this.previousRouteService.back(this.defaultUrl);
  }
}
