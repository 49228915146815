<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.given">Given</label>
  <p data-cy="entire-name">{{ humanName?.text }}</p>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.name-part-qualifier.BR">Birth</label>
  <span data-cy="given-name">{{ humanName | given:'BR' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.name-part-qualifier.CL">Call Me</label>
  <span data-cy="calling-name">{{ humanName | given:'CL' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.name-part-qualifier.IN">Initials</label>
  <span data-cy="initials">{{ humanName | given:'IN' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.family">Last Name</label>
  <span data-cy="family-name">{{ humanName?.family }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.family.own-name">Own Last Name</label>
  <span data-cy="own-name">{{ humanName | family:'own-name' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.family.own-prefix">Own Last Name Prefix</label>
  <span data-cy="own-prefix">{{ humanName | family:'own-prefix' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.family.partner-name">Partner Last Name</label>
  <span data-cy="partner-name">{{ humanName | family:'partner-name' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.family.partner-prefix">Partner Last Name Prefix</label>
  <span data-cy="partner-prefix">{{ humanName | family:'partner-prefix' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.family.fathers-family">Fathers Last Name</label>
  <span data-cy="fathers-family">{{ humanName | family:'fathers-family' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.family.mothers-family">Mothers Last Name</label>
  <span data-cy="mothers-family">{{ humanName | family:'mothers-family' }}</span>
</app-entry>

<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.HumanName.use">Usage</label>
  <span data-cy="use">{{ humanName?.use | nameUse }}</span>
</app-entry>
