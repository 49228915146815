import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MenuItem } from '../menu-item.model';
import { NgbAccordionDirective, NgbAccordionModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { AccordionView, AccordionViewService } from '@core/services';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  standalone: true,
  imports: [SharedModule, NgbModalModule, NgbAccordionModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnDestroy, OnInit, AccordionView {
  @Input() menu: MenuItem[] | undefined | null;
  @Output() selected = new EventEmitter<MenuItem>();
  @ViewChild('accordion') accordion!: NgbAccordionDirective;

  constructor(private resourceViewService: AccordionViewService) {}

  expand(id: string): void {
    this.accordion.expand(id);
  }

  expandAll(): void {
    this.accordion.expandAll();
  }

  collapseAll(): void {
    this.accordion.collapseAll();
  }

  select(item: MenuItem) {
    this.selected.emit(item);
  }

  ngOnDestroy(): void {
    if (this.menu) {
      this.resourceViewService.remove(this);
    }
  }

  ngOnInit(): void {
    if (this.menu) {
      this.resourceViewService.add(this);
    }
  }
}
