import { DetailsItem, ElementItem } from '@resource-view/models';
import { HumanNameViewModel } from './human-name.viewmodel';

export function buildHumanNameElements(vm: HumanNameViewModel | undefined): ElementItem[] {
  return [
    {
      id: 'given',
      header: $localize`:@@fhir.HumanName.given:Given`,
      value: vm?.given,
    },
    {
      id: 'birth',
      header: $localize`:@@fhir.name-part-qualifier.BR:Birth`,
      value: vm?.birth,
    },
    {
      id: 'callMe',
      header: $localize`:@@fhir.name-part-qualifier.CL:Call Me`,
      value: vm?.callMe,
    },
    {
      id: 'initials',
      header: $localize`:@@fhir.name-part-qualifier.IN:Initials`,
      value: vm?.initials,
    },
    {
      id: 'lastName',
      header: $localize`:@@fhir.HumanName.family:Last Name`,
      value: vm?.lastName,
    },
    {
      id: 'ownLastName',
      header: $localize`:@@fhir.HumanName.family.own-name:Own Last Name`,
      value: vm?.ownLastName,
    },
    {
      id: 'ownLastNamePrefix',
      header: $localize`:@@fhir.HumanName.family.own-prefix:Own Last Name Prefix`,
      value: vm?.ownLastNamePrefix,
    },
    {
      id: 'partnerLastName',
      header: $localize`:@@fhir.HumanName.family.partner-name:Partner Last Name`,
      value: vm?.partnerLastName,
    },
    {
      id: 'partnerLastNamePrefix',
      header: $localize`:@@fhir.HumanName.family.partner-prefix:Partner Last Name Prefix`,
      value: vm?.partnerLastNamePrefix,
    },
    {
      id: 'fathersLastName',
      header: $localize`:@@fhir.HumanName.family.fathers-family:Fathers Last Name`,
      value: vm?.fathersLastName,
    },
    {
      id: 'mothersLastFamily',
      header: $localize`:@@fhir.HumanName.family.mothers-family:Mothers Last Name`,
      value: vm?.mothersLastName,
    },
    {
      id: 'usage',
      header: $localize`:@@fhir.HumanName.use:Usage`,
      value: vm?.usage,
    },
  ];
}

export function buildHumanNamesDetails(vms: HumanNameViewModel[] | undefined): DetailsItem[] | undefined {
  return vms?.map((vm: HumanNameViewModel, index: number) => ({
    id: `human-name-${index}`,
    header: vm.given || $localize`:@@fhir.HumanName:Human Name`,
    elements: buildHumanNameElements(vm),
  }));
}
