import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicationRequestStatus',
})
export class MedicationRequestStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'active':
        return $localize`:@@fhir.medication-request-status.active:Active`;
      case 'on-hold':
        return $localize`:@@fhir.medication-request-on-hold.on-hold:On Hold`;
      case 'cancelled':
        return $localize`:@@fhir.medication-request-status.cancelled:Cancelled`;
      case 'completed':
        return $localize`:@@fhir.medication-request-status.completed:Completed`;
      case 'entered-in-error':
        return $localize`:@@fhir.medication-request-status.entered-in-error:Entered in Error`;
      case 'stopped':
        return $localize`:@@fhir.medication-request-status.stopped:Stopped`;
      case 'draft':
        return $localize`:@@fhir.medication-request-status.draft:Draft`;
      case 'unknown':
        return $localize`:@@fhir.medication-request-status.unknown:Unknown`;
      default:
        return '';
    }
  }
}
