import { createSelector } from '@ngrx/store';
import { selectDigiMeState } from './digi-me.selectors';
import { DigiMeState } from '../digi-me.reducer';
import { LoadingState } from '@store';

export const selectIsAccountDeleted = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.delete.callState === LoadingState.loaded
);

export const selectIsAccountDeleting = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.delete.callState === LoadingState.loading
);

export const selectShowAccountDeleteWaring = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.delete.showWarning
);
