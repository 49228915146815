import { DocumentReferenceRelatesTo } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { DocumentReferenceRelatesToCodePipe } from '@hl7fhir/value-sets';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';

export class DocumentReferenceRelatesToViewModel extends BackboneElementViewModel<DocumentReferenceRelatesTo> {
  get code(): string | undefined {
    if (!this.isR5()) {
      const relatesTo = this.element as
        | r3.DocumentReferenceRelatesTo
        | r4.DocumentReferenceRelatesTo
        | r4b.DocumentReferenceRelatesTo;
      return new DocumentReferenceRelatesToCodePipe().transform(relatesTo?.code);
    } else {
      const relatesTo = this.element as r5.DocumentReferenceRelatesTo;
      return relatesTo?.code && new CodeableConceptPipe().transform(relatesTo.code);
    }
  }

  get target(): string | undefined {
    return this.element?.target && getReference(this.element.target);
  }
}
