import { Period } from '@hl7fhir';

export class PeriodComparer {
  static compare(a: Period | undefined, b: Period | undefined): number {
    if (!a && !b) return 0; // Both are undefined
    if (!a) return -1; // No period is higher ranked than a defined period
    if (!b) return 1;

    if (!a.end && !b.end) return 0; // Both have no end defined
    if (!a.end) return -1; // (a) has no end defined, and (b) does; we consider (a) higher ranked
    if (!b.end) return 1; // (a) end is defined, and (b) hasn't; we consider (a) lower ranked

    const aEnd = Date.parse(a.end);
    const bEnd = Date.parse(b.end);
    const endCompare = bEnd - aEnd;
    if (endCompare) return endCompare; // If (a) ends after (b) it is ranked lower. Look at start if they both ended at the same time

    if (!a.start && !b.start) return 0; // Both have no start defined
    if (!a.start) return -1; // (a) has no start defined, and (b) does; we consider (a) higher ranked
    if (!b.start) return 1; // (a) start is defined, and (b) hasn't; we consider (a) lower ranked

    const aStart = Date.parse(a.start);
    const bStart = Date.parse(b.start);

    return bStart - aStart; // If (a) started after (b) it is ranked lower.
  }
}
