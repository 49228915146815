import saveAs from 'file-saver';

export function downloadBinaryFile(content: string, contentType: string, fileName: string): void {
  const byteCharacters = atob(content);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });

  saveAs(blob, fileName);
}
