import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { AllergyIntolerance, CodeableConcept, Coding, Extension } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { AnnotationViewModel, CodeableConceptPipe, getChoiceOfType, IdentifierViewModel } from '@hl7fhir/data-types';
import { ExtensionPipe, ExtensionsPipe } from '@hl7fhir/extensibility';
import { getReference, HasProfilePipe, VersionPipe } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import {
  AllergyIntoleranceCategoryPipe,
  AllergyIntoleranceClinicalStatusPipe,
  AllergyIntoleranceCriticalityPipe,
  AllergyIntoleranceTypePipe,
  AllergyIntoleranceVerificationStatusPipe,
  CodeSystemPipe,
} from '@hl7fhir/value-sets';
import { AllergieStatusCodelijst } from '@hl7nl-fhir/value-sets';
import { SelectPipe, SelectsPipe } from '@shared';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { AllergyIntoleranceReactionViewModel } from './allergy-intolerance-reaction.viewmodel';

export class AllergyIntoleranceViewModel extends DomainResourceViewModel<AllergyIntolerance> {
  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.code);
  }

  get clinicalStatus(): string | undefined {
    const isR3 = new VersionPipe().transform(this.resource, 'R3');

    if (isR3) {
      const allergyIntoleranceR3: r3.AllergyIntolerance = this.resource as r3.AllergyIntolerance;
      const hasProfile = new HasProfilePipe().transform(
        allergyIntoleranceR3,
        StructureDefinition.Nictiz.ALLERGY_INTOLERANCE
      );

      if (hasProfile && allergyIntoleranceR3._clinicalStatus?.extension) {
        const extension: Extension | undefined = new ExtensionPipe().transform(
          allergyIntoleranceR3._clinicalStatus?.extension,
          StructureDefinition.Nictiz.CODE.specification
        );

        if (extension) {
          const value: CodeableConcept | undefined = new SelectPipe().transform(extension, 'valueCodeableConcept');
          return new CodeableConceptPipe().transform(value, [AllergieStatusCodelijst]);
        }
      }

      return new AllergyIntoleranceClinicalStatusPipe().transform(allergyIntoleranceR3.clinicalStatus);
    }

    const allergyIntoleranceR = this.resource as
      | r4.AllergyIntolerance
      | r4b.AllergyIntolerance
      | r5.AllergyIntolerance
      | undefined;
    return (
      allergyIntoleranceR?.clinicalStatus && new CodeableConceptPipe().transform(allergyIntoleranceR.clinicalStatus)
    );
  }

  get verificationStatus(): string | undefined {
    const isR3 = new VersionPipe().transform(this.resource, 'R3');

    if (isR3) {
      const allergyIntoleranceR3: r3.AllergyIntolerance = this.resource as r3.AllergyIntolerance;
      return (
        allergyIntoleranceR3.verificationStatus &&
        new AllergyIntoleranceVerificationStatusPipe().transform(allergyIntoleranceR3.verificationStatus)
      );
    }

    const allergyIntoleranceR = this.resource as
      | r4.AllergyIntolerance
      | r4b.AllergyIntolerance
      | r5.AllergyIntolerance
      | undefined;
    const codeSystem: Coding | undefined = new CodeSystemPipe().transform(
      allergyIntoleranceR?.verificationStatus,
      CodeSystems.ALLERGY_INTOLERANCE_VERIFICATION
    );
    return codeSystem?.code && new AllergyIntoleranceVerificationStatusPipe().transform(codeSystem.code);
  }

  get type(): string | string[] | undefined {
    const isR5 = new VersionPipe().transform(this.resource, 'R5');
    if (isR5) {
      const resourceR5 = this.resource as r5.AllergyIntolerance;
      return resourceR5.type && new CodeableConceptPipe().transform(resourceR5.type);
    }

    const isR = new VersionPipe().transform(this.resource, 'R3', 'R4', 'R4b');
    if (isR) {
      const resourceR = this.resource as
        | r3.AllergyIntolerance
        | r4.AllergyIntolerance
        | r4b.AllergyIntolerance
        | undefined;

      return resourceR?.type && new AllergyIntoleranceTypePipe().transform(resourceR.type);
    }

    return undefined;
  }

  get category(): string | undefined {
    const categoryExtensions = this.resource?._category?.flatMap((element) => element.extension as Extension[]);

    if (categoryExtensions) {
      const extensions: Extension[] | undefined = new ExtensionsPipe().transform(
        this.resource?._category?.flatMap((element) => element.extension as Extension[]),
        StructureDefinition.Nictiz.CODE.specification
      );

      if (extensions) {
        const values: CodeableConcept[] | undefined = new SelectsPipe().transform(extensions, 'valueCodeableConcept');
        return new CodeableConceptPipe().transform(values);
      }
    }

    return this.resource?.category && new AllergyIntoleranceCategoryPipe().transform(this.resource.category).join(',');
  }

  get criticality(): string | undefined {
    if (this.resource?._criticality?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.resource?._criticality?.extension,
        StructureDefinition.Nictiz.CODE.specification
      );

      if (extension) {
        const values: CodeableConcept[] | undefined = new SelectPipe().transform(extension, 'valueCodeableConcept');
        return new CodeableConceptPipe().transform(values);
      }
    }

    return this.resource?.criticality && new AllergyIntoleranceCriticalityPipe().transform(this.resource.criticality);
  }

  get patient(): string | undefined {
    return this.resource?.patient && getReference(this.resource.patient);
  }

  // Doesn't exist in R3
  get encounter(): string | undefined {
    const isR = new VersionPipe().transform(this.resource, 'R4', 'R4b', 'R5');

    if (isR) {
      const resourceR = this.resource as
        | r4.AllergyIntolerance
        | r4b.AllergyIntolerance
        | r5.AllergyIntolerance
        | undefined;
      return resourceR?.encounter && getReference(resourceR.encounter);
    }

    return undefined;
  }

  get onset(): string | undefined {
    return getChoiceOfType({
      dateTime: this.resource?.onsetDateTime,
      age: this.resource?.onsetAge,
      period: this.resource?.onsetPeriod,
      range: this.resource?.onsetRange,
      string: this.resource?.onsetString,
    });
  }

  // Only for R3
  get assertedDate(): string | undefined {
    const resourceR3 = this.resource as r3.AllergyIntolerance | undefined;

    return resourceR3?.assertedDate && formatLocaleDate(resourceR3.assertedDate, 'long');
  }

  // Only for R4, R4b, R5
  get recordedDate(): string | undefined {
    const resourceR = this.resource as
      | r4.AllergyIntolerance
      | r4b.AllergyIntolerance
      | r5.AllergyIntolerance
      | undefined;
    return resourceR?.recordedDate && formatLocaleDate(resourceR.recordedDate, 'long');
  }

  // Participant - R5 - not supported yet

  // Only for R3, R4, R4b
  get recorder(): string | undefined {
    const resourceR = this.resource as
      | r3.AllergyIntolerance
      | r4.AllergyIntolerance
      | r4b.AllergyIntolerance
      | undefined;
    return resourceR?.recorder && getReference(resourceR.recorder);
  }

  // Only for R3, R4, R4b
  get asserter(): string | undefined {
    const resourceR = this.resource as
      | r3.AllergyIntolerance
      | r4.AllergyIntolerance
      | r4b.AllergyIntolerance
      | undefined;
    return resourceR?.asserter && getReference(resourceR.asserter);
  }

  get lastOccurrence(): string | undefined {
    return this.resource?.lastOccurrence && formatLocaleDate(this.resource.lastOccurrence, 'long');
  }

  get identifier(): IdentifierViewModel[] | undefined {
    return (
      this.resource?.identifier &&
      this.resource.identifier.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion))
    );
  }

  get annotations(): AnnotationViewModel[] | undefined {
    return (
      this.resource?.note &&
      this.resource.note.map((annotation) => new AnnotationViewModel(annotation, this.fhirVersion))
    );
  }

  get reactions(): AllergyIntoleranceReactionViewModel[] | undefined {
    return (
      this.resource?.reaction &&
      this.resource.reaction.map((reaction) => new AllergyIntoleranceReactionViewModel(reaction, this.fhirVersion))
    );
  }
}
