import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeaderComponent } from './header.component';

@Component({
  standalone: true,
  selector: 'app-overview-header',
  imports: [CommonModule, HeaderComponent],
  templateUrl: './overview-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewHeaderComponent {
  @Input() text: string | undefined;
  @Input() icon: string | undefined;
}
