import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { ConditionStage } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReferences } from '@hl7fhir/foundation';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';

export class ConditionStageViewModel extends BackboneElementViewModel<ConditionStage> {
  get summary(): string | undefined {
    return this.element?.summary && new CodeableConceptPipe().transform(this.element.summary);
  }

  get assessment(): string | undefined {
    return this.element?.assessment && getReferences(this.element.assessment);
  }

  get type(): string | undefined {
    const conditionStage = this.element as r4.ConditionStage | r4b.ConditionStage | r5.ConditionStage;
    return conditionStage.type && new CodeableConceptPipe().transform(conditionStage.type);
  }
}
