import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const WilsverklaringTypeCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.7.15.1--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Anders',
        display: 'Other',
      },
    },
    'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.14.1': {
      NR: {
        'nl-NL': 'Niet reanimeren verklaring',
        display: 'Niet reanimeren verklaring',
      },
      VOL: {
        'nl-NL': 'Volmacht',
        display: 'Volmacht',
      },
      VERB: {
        'nl-NL': 'Behandelverbod',
        display: 'Behandelverbod',
      },
      VERBVL: {
        'nl-NL': 'Behandelverbod met aanvulling Voltooid Leven',
        display: 'Behandelverbod met aanvulling Voltooid Leven',
      },
      MON: {
        'nl-NL': 'Mondelinge afspraak',
        display: 'Mondelinge afspraak',
      },
      EU: {
        'nl-NL': 'Euthanasieverzoek',
        display: 'Euthanasieverzoek',
      },
      EUD: {
        'nl-NL': 'Euthanasieverzoek met aanvulling Dementie',
        display: 'Euthanasieverzoek met aanvulling Dementie',
      },
      LW: {
        'nl-NL': 'Levenswensverklaring',
        display: 'Levenswensverklaring',
      },
      DO: {
        'nl-NL': 'Verklaring donorschap',
        display: 'Verklaring donorschap',
      },
    },
  },
};
