import { NgModule } from '@angular/core';
import { ReferencesModule } from '@hl7fhir/foundation';
import { SharedModule } from '@shared';
import { AgeModule } from '../age';
import { BooleanModule } from '../boolean';
import { CodeableConceptModule } from '../codeable-concept';
import { PeriodModule } from '../period';
import { QuantityModule } from '../quantity';
import { ChoiceOfTypesCellRendererComponent } from './choice-of-types-cell-renderer/choice-of-types-cell-renderer.component';
import { ChoiceOfTypesComponent } from './choice-of-types.component';

const components = [ChoiceOfTypesComponent, ChoiceOfTypesCellRendererComponent];
const dataTypes = [PeriodModule, AgeModule, QuantityModule, CodeableConceptModule, BooleanModule];
const foundation = [ReferencesModule];

@NgModule({
  imports: [SharedModule, ...dataTypes, ...foundation],
  declarations: [...components],
  exports: [...components],
})
export class ChoiceOfTypesModule {}
