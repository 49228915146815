/**
 * Source:
 * https://www.vektis.nl/uzovi-register
 */

import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const UZOVI: CodeSystemFlattened = {
  codes: {
    '0101': { display: 'N.V. Univé Zorg' },
    '0104': { display: 'Nationale-Nederlanden Zorg' },
    '0201': { display: 'OHRA Zorgverzekeringen N.V.' },
    '0203': { display: 'OOM Verzekeringen' },
    '0212': { display: 'Stichting Ziektekostenverzekering Krijgsmacht' },
    '0403': { display: 'ASR Ziektekostenverzekeringen' },
    '0699': { display: 'IZA Zorgverzekeraar NV' },
    '0736': { display: 'NV Zorgverzekeraar UMC' },
    '3311': { display: 'Zilveren Kruis Zorgverzekeringen N.V.' },
    '3313': { display: 'Interpolis Zorgverzekeringen NV' },
    '3332': { display: 'Menzis Zorgverzekeraar N.V.' },
    '3333': { display: 'Anderzorg N.V.' },
    '3334': { display: 'IZA-VNG' },
    '3336': { display: 'Ik kieszelf van a.s.r.' },
    '3343': { display: 'ONVZ Ziektekostenverzekeraar' },
    '3344': { display: 'inTwente Zorgverzekeraar' },
    '3346': { display: 'InsureToStudy' },
    '3347': { display: 'Eno Zorgverzekeraar N.V.' },
    '3349': { display: 'StudentsInsured' },
    '3350': { display: 'ASR Schadeverzekering N.V.' },
    '3351': { display: 'FBTO Zorgverzekeringen N.V.' },
    '3354': { display: 'Caresq' },
    '3355': { display: 'Regeling Medische zorg Asielzoekers (RMA)' },
    '3356': { display: 'Medisch noodzakelijke zorg aan onverzekerde verzekeringsplichtigen' },
    '3357': { display: 'Goudse Schadeverzekeringen N.V.' },
    '3358': { display: 'De Friesland Zorgverzekeraar N.V.' },
    '3359': { display: 'EUCARE' },
    '3360': { display: 'Aevitae (EUCARE)' },
    '3361': { display: 'ZEKUR' },
    '3363': { display: 'SZVK' },
    '5501': { display: 'Zorgkantoor Groningen' },
    '5503': { display: 'Zorgkantoor Drenthe' },
    '5504': { display: 'Zorgkantoor Zwolle' },
    '5505': { display: 'Zorgkantoor Twente' },
    '5506': { display: 'Zorgkantoor Apeldoorn }, Zutphen en omstreken' },
    '5507': { display: 'Zorgkantoor Arnhem' },
    '5508': { display: 'Zorgkantoor Nijmegen' },
    '5509': { display: 'Zorgkantoor Utrecht' },
    '5510': { display: 'Zorgkantoor Flevoland' },
    '5511': { display: `Zorgkantoor 't Gooi` },
    '5512': { display: 'Zorgkantoor Noord Holland Noord' },
    '5513': { display: 'Zorgkantoor Kennemerland' },
    '5514': { display: 'Zorgkantoor Zaanstreek/Waterland' },
    '5515': { display: 'Zorgkantoor Amsterdam' },
    '5516': { display: 'Zorgkantoor Amstelland en De Meerlanden' },
    '5517': { display: 'Zorgkantoor Zuid-Holland Noord' },
    '5518': { display: 'Zorgkantoor Haaglanden' },
    '5519': { display: 'Zorgkantoor Westland Schieland Delfland (WSD)' },
    '5520': { display: 'Zorgkantoor Midden-Holland' },
    '5521': { display: 'Zorgkantoor Rotterdam' },
    '5523': { display: 'Zorgkantoor Zuid-Hollandse Eilanden' },
    '5524': { display: 'Zorgkantoor Waardenland' },
    '5525': { display: 'Zorgkantoor Zeeland' },
    '5526': { display: 'Zorgkantoor West-Brabant' },
    '5527': { display: 'Zorgkantoor Midden-Brabant' },
    '5528': { display: 'Zorgkantoor Noordoost Brabant' },
    '5529': { display: 'Zorgkantoor Zuidoost-Brabant' },
    '5530': { display: 'Zorgkantoor Noord- en Midden Limburg' },
    '5531': { display: 'Zorgkantoor Zuid-Limburg' },
    '5532': { display: 'Zorgkantoor Midden-IJssel' },
    '5533': { display: 'Zorgkantoor Friesland' },
    '7029': { display: 'OWM DSW Zorgverzekeraar U.A.' },
    '7037': { display: 'Stad Holland Zorgverzekeraar OWM U.A.' },
    '7085': { display: 'OWM Zorgverzekeraar Zorg en Zekerheid UA' },
    '7095': { display: 'VGZ Zorgverzekeraar N.V.' },
    '7119': { display: 'CZ Zorgverzekeringen N.V.' },
    '7125': { display: 'Groep Buitenlands Recht }, Zilveren Kruis' },
    '8965': { display: 'Caresco BV (VGZ)' },
    '8971': { display: 'One Underwriting B.V. (Zilveren Kruis)' },
    '8980': { display: 'CARESCO (ALGEMEEN T.B.V. RBVZ)' },
    '8981': { display: 'AEVITAE (ALGEMEEN T.B.V. RBVZ)' },
    '8983': { display: 'One Underwriting Health B.V. (ALG T.B.V. RBVZ)' },
    '9018': { display: 'a.s.r. zorg' },
    '9086': { display: 'Pro Life Zorgverzekeringen' },
    '9551': { display: 'Coöperatie VGZ UA' },
    '9664': { display: 'Centrale Verwerkingseenheid CZ: CZ }, Nationale-Nederlanden en OHRA' },
    '9985': { display: 'Uitvoeringsregeling opsporing en verzekering onverzekerden Zvw' },
    '9986': { display: 'Buitenland Verbindingsorgaan' },
    '9987': { display: 'Regeling wanbetalers premie Zorgverzekeringswet' },
    '9988': { display: 'Uitvoeringsregeling gemoedsbezwaarden zorgkosten Zorgverzekeringswet' },
    '9989': { display: 'Regeling financiering zorg illegalen' },
    '9990': { display: 'Buitenland Verdragsgerechtigden' },
    '9991': { display: 'OWM CZ groep U.A. Buitenlandse verzekerden' },
    '9992': { display: 'de Dienst Justitiële Inrichtingen' },
  },
};
