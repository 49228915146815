import { DetailsItem, ElementItem } from '@resource-view/models';
import { ContactPointViewModel } from './contact-point.viewmodel';

export function buildContactPointsDetails(vms: ContactPointViewModel[] | undefined): DetailsItem[] | undefined {
  return vms?.map((vm: ContactPointViewModel, index: number) => ({
    id: `contact-point-${index}`,
    header: vm.value || $localize`:@@fhir.ContactPoint:Contact Point`,
    elements: buildElements(vm),
  }));
}

function buildElements(vm: ContactPointViewModel): ElementItem[] {
  return [
    {
      id: 'value',
      header: vm.system,
      value: vm.value,
    },
    {
      id: 'use',
      header: $localize`:@@fhir.ContactPoint.use:Use`,
      value: vm.use,
    },
    {
      id: 'type',
      header: $localize`:@@fhir.ContactPoint.type:Type`,
      value: vm.type,
    },
  ];
}
