import { Pipe, PipeTransform } from '@angular/core';
import { ContactPoint } from '@hl7fhir';

@Pipe({
  name: 'contactPointUse',
})
export class ContactPointUsePipe implements PipeTransform {
  transform(value: ContactPoint | undefined | null): string {
    switch (value?.use) {
      case 'home':
        return $localize`:2.16.840.1.113883.4.642.3.73@@fhir.contact-point-use.home:Home`;
      case 'mobile':
        return $localize`:2.16.840.1.113883.4.642.3.73@@fhir.contact-point-use.mobile:Mobile`;
      case 'old':
        return $localize`:2.16.840.1.113883.4.642.3.73@@fhir.contact-point-use.old:Old`;
      case 'temp':
        return $localize`:2.16.840.1.113883.4.642.3.73@@fhir.contact-point-use.temp:Temp`;
      case 'work':
        return $localize`:2.16.840.1.113883.4.642.3.73@@fhir.contact-point-use.work:Work`;
      default:
        return '';
    }
  }
}
