import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const ZorgverlenerRolCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.17.1.5--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Anders',
        display: 'Other',
      },
    },
    'http://hl7.org/fhir/v3/ParticipationType': {
      RESP: {
        'nl-NL': 'Hoofdbehandelaar',
        display: 'Responsible Party',
      },
      REF: {
        'nl-NL': 'Verwijzer',
        display: 'Referrer',
      },
      PRF: {
        'nl-NL': 'Uitvoerder',
        display: 'Performer',
      },
      SPRF: {
        'nl-NL': 'Tweede uitvoerder',
        display: 'Secondary Performer',
      },
      CON: {
        'nl-NL': 'Consulent',
        display: 'Consultant',
      },
      ATND: {
        'nl-NL': 'Behandelaar',
        display: 'Attender',
      },
    },
  },
};
