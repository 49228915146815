import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../features';
import { DigiMeEffects } from './digi-me.effects';
import { reducer } from './digi-me.reducer';

@NgModule({
  imports: [StoreModule.forFeature(features.DIGI_ME, reducer), EffectsModule.forFeature([DigiMeEffects])],
})
export class DigiMeStoreModule {}
