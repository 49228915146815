import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import * as r3 from 'fhir/r3';

export class ImmunizationExplanationViewModel extends BackboneElementViewModel<r3.ImmunizationExplanation> {
  get reason(): string | undefined {
    return this.element?.reason && new CodeableConceptPipe().transform(this.element.reason);
  }

  get reasonNotGiven(): string | undefined {
    return this.element?.reasonNotGiven && new CodeableConceptPipe().transform(this.element.reasonNotGiven);
  }
}
