import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeviceService, ShareMeasurementsService } from '@core/services';
import { ErrorCodes } from '@globals';
import { Store } from '@ngrx/store';
import { ERROR_UI_ACTIONS } from '@store/app';
import { DIGI_ME_SAAS_RETURN_ACTIONS, USER_API_ACTIONS } from '@store/digi.me';
import { filter, take, tap } from 'rxjs';

@Component({
  selector: 'app-return',
  template: ``,
})
export class ReturnComponent implements OnInit {
  // TODO: Split the return's to different urls within the app, e.g. /return/onboard, /return/revoke, /return/authorize, /return/reauthorize
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly shareMeasurementsService: ShareMeasurementsService,
    private readonly deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.deviceService.restoreToSessionStorage();
    // Use this flow for the authorization of a first onboarding service response.
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params: Params) => !!params['code'] && params['success'] === 'true')
      )
      .subscribe((params: Params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeSucceeded({ params }));
      });

    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter(
          (params: Params) => params['success'] === 'false' && Object.values(ErrorCodes).includes(params['errorCode'])
        )
      )
      .subscribe((params: Params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeFailed({ params }));
      });

    // Use this flow for the subsequent responses from the onboarding services.
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params: Params) => !params['code'] && params['success'] === 'true'),
        filter((_) => localStorage.getItem('sourceType') === 'pull'),
        tap(async () => await this.router.navigate(['linked-sources']))
      )
      .subscribe((params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.onboardSucceeded({ params }));
      });

    // Use this flow for the revoke/reauth.
    // TODO: Be able to distinguish reauth and revoke, for now we use the reauth flow for both, because a confirm is needed for reauth
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params: Params) => !params['code'] && params['result'] === 'success'),
        tap(async () => await this.router.navigate(['linked-sources']))
      )
      .subscribe((params) => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.reauthorizeCompleted({ params }));
      });

    // Result fail can get returned by a manage action e.g.
    // Dispatch a general error action on a non success result
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params: Params) => params['result'] === 'fail')
      )
      .subscribe((params: Params) => {
        this.store.dispatch(ERROR_UI_ACTIONS.generalFailure({ params }));
      });

    // Use this flow to obtain the account reference necessary for sharing data.
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params: Params) => params['success'] === 'true' && params['accountReference']),
        filter((_) => localStorage.getItem('sourceType') === 'push')
      )
      .subscribe((params: Params) => {
        // An account reference is necessary to identify the provider to which data will be pushed.
        this.shareMeasurementsService.setAccountReference(params['accountReference']);

        // Begin by retrieving all data associated with the user. After the accounts have been successfully loaded,
        // find the specific account using its reference and then proceed to push data to it.
        this.store.dispatch(USER_API_ACTIONS.userDataRequested({ sourceFetch: false, trigger: 'loading' }));
      });

    // Use this flow when the user cancels the manage permissions process.
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        filter((params: Params) => params['result'] === 'cancel')
      )
      .subscribe(() => {
        this.store.dispatch(DIGI_ME_SAAS_RETURN_ACTIONS.managePermissionsCancelled());
      });
  }
}
