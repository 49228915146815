import { ElementViewModel } from '@hl7fhir/viewmodels';
import { Narrative } from '@hl7fhir';
import { NarrativeStatusPipe } from '@hl7fhir/value-sets';

export class NarrativeViewModel extends ElementViewModel<Narrative> {
  get status(): string | undefined {
    return new NarrativeStatusPipe().transform(this.element?.status);
  }

  get div(): string | undefined {
    return this.element?.div;
  }
}
