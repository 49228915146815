import { createActionGroup, props } from '@ngrx/store';
import { DigiMeSortState } from './document-view.reduce';

export type SortDirection = 'asc' | 'desc' | string | undefined;

export const SET_SORT_BY = createActionGroup({
  source: 'Digi.me Set Sort By',
  events: {
    'Update Sort By': props<DigiMeSortState>(),
  },
});
