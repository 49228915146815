import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../../features';
import { CoverageEffects } from './coverage.effects';
import { reducer } from './coverage.reducer';

@NgModule({
  imports: [StoreModule.forFeature(features.FHIR.coverages, reducer), EffectsModule.forFeature([CoverageEffects])],
})
export class CoverageStoreModule {}
