import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const ConsentDataMeaning: CodeSystemFlattened = {
  id: 'consent-data-meaning',
  codes: {
    instance: {
      display: 'Instance',
    },
    related: {
      display: 'Related',
    },
    dependents: {
      display: 'Dependents',
    },
    authoredby: {
      display: 'AuthoredBy',
    },
  },
};
