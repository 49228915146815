import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';
export const DICOMControlledTerminologyDefinitions: CodeSystemFlattened = {
  id: 'dicom-dcim',
  codes: {
    ARCHIVE: {
      display: 'Archive',
    },
    AR: {
      display: 'Autorefraction',
    },
    AS: {
      display: 'Angioscopy',
    },
    AU: {
      display: 'Audio',
    },
    BDUS: {
      display: 'Ultrasound Bone Densitometry',
    },
    BI: {
      display: 'Biomagnetic imaging',
    },
    BMD: {
      display: 'Bone Mineral Densitometry',
    },
    CAD: {
      display: 'Computer Assisted Detection/Diagnosis',
    },
    CAPTURE: {
      display: 'Image Capture',
    },
    CD: {
      display: 'Color flow Doppler',
    },
    CF: {
      display: 'Cinefluorography',
    },
    COMP: {
      display: 'Computation Server',
    },
    CP: {
      display: 'Culposcopy',
    },
    CR: {
      display: 'Computed Radiography',
    },
    CS: {
      display: 'Cystoscopy',
    },
    CT: {
      display: 'Computed Tomography',
    },
    DD: {
      display: 'Duplex Doppler',
    },
    DF: {
      display: 'Digital fluoroscopy',
    },
    DG: {
      display: 'Diaphanography',
    },
    DM: {
      display: 'Digital microscopy',
    },
    DOCD: {
      display: 'Document Digitizer Equipment',
    },
    DS: {
      display: 'Digital Subtraction Angiography',
    },
    DSS: {
      display: 'Department System Scheduler',
    },
    DX: {
      display: 'Digital Radiography',
    },
    EC: {
      display: 'Echocardiography',
    },
    ECG: {
      display: 'Electrocardiography',
    },
    EPS: {
      display: 'Cardiac Electrophysiology',
    },
    ES: {
      display: 'Endoscopy',
    },
    F: {
      display: 'Female',
    },
    FA: {
      display: 'Fluorescein angiography',
    },
    FC: {
      display: 'Female changed to Male',
    },
    FILMD: {
      display: 'Film Digitizer',
    },
    FP: {
      display: 'Female Pseudohermaphrodite',
    },
    FS: {
      display: 'Fundoscopy',
    },
    GM: {
      display: 'General Microscopy',
    },
    H: {
      display: 'Hermaphrodite',
    },
    HC: {
      display: 'Hard Copy',
    },
    HD: {
      display: 'Hemodynamic Waveform',
    },
    IO: {
      display: 'Intra-oral Radiography',
    },
    IVOCT: {
      display: 'Intravascular Optical Coherence Tomography',
    },
    IVUS: {
      display: 'Intravascular Ultrasound',
    },
    KER: {
      display: 'Keratometry',
    },
    KO: {
      display: 'Key Object Selection',
    },
    LEN: {
      display: 'Lensometry',
    },
    LOG: {
      display: 'Procedure Logging',
    },
    LP: {
      display: 'Laparoscopy',
    },
    LS: {
      display: 'Laser surface scan',
    },
    M: {
      display: 'Male',
    },
    MA: {
      display: 'Magnetic resonance angiography',
    },
    MC: {
      display: 'Male changed to Female',
    },
    MCD: {
      display: 'Media Creation Device',
    },
    MEDIM: {
      display: 'Portable Media Importer Equipment',
    },
    MG: {
      display: 'Mammography',
    },
    MP: {
      display: 'Male Pseudohermaphrodite',
    },
    MR: {
      display: 'Magnetic Resonance',
    },
    MS: {
      display: 'Magnetic resonance spectroscopy',
    },
    NEARLINE: {
      display: 'Nearline',
    },
    NM: {
      display: 'Nuclear Medicine',
    },
    OAM: {
      display: 'Ophthalmic Axial Measurements',
    },
    OCT: {
      display: 'Optical Coherence Tomography',
    },
    OFFLINE: {
      display: 'Offline',
    },
    ONLINE: {
      display: 'Online',
    },
    OP: {
      display: 'Ophthalmic photography',
    },
    OPM: {
      display: 'Ophthalmic Mapping',
    },
    OPR: {
      display: 'Ophthalmic Refraction',
    },
    OPT: {
      display: 'Ophthalmic Tomography',
    },
    OPV: {
      display: 'Ophthalmic Visual Field',
    },
    OSS: {
      display: 'Optical Survace Scanner',
    },
    OT: {
      display: 'Other Modality',
    },
    PR: {
      display: 'Presentation State',
    },
    PRINT: {
      display: 'Hard Copy Print Server',
    },
    PT: {
      display: 'Positron emission tomography',
    },
    PX: {
      display: 'Panoramic X-Ray',
    },
    REG: {
      display: 'Registration',
    },
    RF: {
      display: 'Radiofluoroscopy',
    },
    RG: {
      display: 'Radiographic imaging',
    },
    RT: {
      display: 'Radiation Therapy Device',
    },
    RTDOSE: {
      display: 'Radiotherapy Dose',
    },
    RTIMAGE: {
      display: 'Radiotherapy Image',
    },
    RTPLAN: {
      display: 'Radiotherapy Plan',
    },
    RTRECORD: {
      display: 'Radiotherapy Treatment Record',
    },
    RTSTRUCT: {
      display: 'Radiotherapy Structure Set',
    },
    SEG: {
      display: 'Segmentation',
    },
    SM: {
      display: 'Slide Microscopy',
    },
    SMR: {
      display: 'Stereometric Relationship',
    },
    SR: {
      display: 'Structured Report Document',
    },
    SRF: {
      display: 'Subjective Refraction',
    },
    ST: {
      display: 'Single-photon emission computed tomography',
    },
    TG: {
      display: 'Thermography',
    },
    U: {
      display: 'Unknown Sex',
    },
    UNAVAILABLE: {
      display: 'Unavailable',
    },
    US: {
      display: 'Ultrasound',
    },
    VA: {
      display: 'Visual Acuity',
    },
    VF: {
      display: 'Videofluorography',
    },
    VIDD: {
      display: 'Video Tape Digitizer Equipment',
    },
    WSD: {
      display: 'Workstation',
    },
    XA: {
      display: 'X-Ray Angiography',
    },
    XC: {
      display: 'External-camera Photography',
    },
    '109001': {
      display: 'Digital timecode (NOS)',
    },
    '109002': {
      display: 'ECG-based gating signal, processed',
    },
    '109003': {
      display: 'IRIG-B timecode',
    },
    '109004': {
      display: 'X-Ray Fluoroscopy On Signal',
    },
    '109005': {
      display: 'X-Ray On Trigger',
    },
    '109006': {
      display: 'Differential signal',
    },
    '109007': {
      display: 'His bundle electrogram',
    },
    '109008': {
      display: 'Monopole signal',
    },
    '109009': {
      display: 'Pacing (electrical) stimulus, voltage',
    },
    '109010': {
      display: 'Radio frequency ablation, power',
    },
    '109011': {
      display: 'Voltage measurement by basket catheter',
    },
    '109012': {
      display: 'Voltage measurement by mapping catheter',
    },
    '109013': {
      display: 'Voltage measurement, NOS',
    },
    '109014': {
      display: '35% of thermal CO',
    },
    '109015': {
      display: '70% of thermal CO',
    },
    '109016': {
      display: 'A wave peak pressure',
    },
    '109017': {
      display: 'A wave pressure, average',
    },
    '109018': {
      display: 'Beat detected (accepted)',
    },
    '109019': {
      display: 'Beat detected (rejected)',
    },
    '109020': {
      display: 'Diastolic pressure, average',
    },
    '109021': {
      display: 'Diastolic pressure nadir',
    },
    '109022': {
      display: 'End diastole',
    },
    '109023': {
      display: 'End of expiration',
    },
    '109024': {
      display: 'End of inspiration',
    },
    '109025': {
      display: 'Max dp/dt',
    },
    '109026': {
      display: 'Max neg dp/dt',
    },
    '109027': {
      display: 'Mean blood pressure',
    },
    '109028': {
      display: 'Peak of thermal cardiac output bolus',
    },
    '109029': {
      display: 'Start of expiration',
    },
    '109030': {
      display: 'Start of inspiration',
    },
    '109031': {
      display: 'Start of thermal cardiac output bolus',
    },
    '109032': {
      display: 'Systolic pressure, average',
    },
    '109033': {
      display: 'Systolic peak pressure',
    },
    '109034': {
      display: 'V wave peak pressure',
    },
    '109035': {
      display: 'V wave pressure, average',
    },
    '109036': {
      display: 'Valve close',
    },
    '109037': {
      display: 'Valve open',
    },
    '109038': {
      display: 'Ablation off',
    },
    '109039': {
      display: 'Ablation on',
    },
    '109040': {
      display: 'HIS bundle wave',
    },
    '109041': {
      display: 'P wave',
    },
    '109042': {
      display: 'Q wave',
    },
    '109043': {
      display: 'R wave',
    },
    '109044': {
      display: 'S wave',
    },
    '109045': {
      display: 'Start of atrial contraction',
    },
    '109046': {
      display: 'Start of atrial contraction (subsequent)',
    },
    '109047': {
      display: 'Stimulation at rate 1 interval',
    },
    '109048': {
      display: 'Stimulation at rate 2 interval',
    },
    '109049': {
      display: 'Stimulation at rate 3 interval',
    },
    '109050': {
      display: 'Stimulation at rate 4 interval',
    },
    '109051': {
      display: 'T wave',
    },
    '109052': {
      display: 'V wave',
    },
    '109053': {
      display: 'V wave of next beat',
    },
    '109054': {
      display: 'Patient State',
    },
    '109055': {
      display: 'Protocol Stage',
    },
    '109056': {
      display: 'Stress Protocol',
    },
    '109057': {
      display: 'Catheterization Procedure Phase',
    },
    '109058': {
      display: 'Contrast Phase',
    },
    '109059': {
      display: 'Physiological challenges',
    },
    '109060': {
      display: 'Procedure Step Number',
    },
    '109061': {
      display: 'EP Procedure Phase',
    },
    '109063': {
      display: 'Pulse train definition',
    },
    '109070': {
      display: 'End of systole',
    },
    '109071': {
      display: 'Indicator mean transit time',
    },
    '109072': {
      display: 'Tau',
    },
    '109073': {
      display: 'V max myocardial',
    },
    '109080': {
      display: 'Real time acquisition',
    },
    '109081': {
      display: 'Prospective gating',
    },
    '109082': {
      display: 'Retrospective gating',
    },
    '109083': {
      display: 'Paced',
    },
    '109091': {
      display: 'Cardiac Stress State',
    },
    '109092': {
      display: 'Reinjection State',
    },
    '109093': {
      display: 'Redistribution State',
    },
    '109094': {
      display: 'Delayed Redistribution State',
    },
    '109095': {
      display: 'Peak stress state',
    },
    '109096': {
      display: 'Recovery state',
    },
    '109101': {
      display: 'Acquisition Equipment',
    },
    '109102': {
      display: 'Processing Equipment',
    },
    '109103': {
      display: 'Modifying Equipment',
    },
    '109104': {
      display: 'De-identifying Equipment',
    },
    '109105': {
      display: 'Frame Extracting Equipment',
    },
    '109106': {
      display: 'Enhanced Multi-frame Conversion Equipment',
    },
    '109110': {
      display: 'Voice',
    },
    '109111': {
      display: "Operator's narrative",
    },
    '109112': {
      display: 'Ambient room environment',
    },
    '109113': {
      display: 'Doppler audio',
    },
    '109114': {
      display: 'Phonocardiogram',
    },
    '109115': {
      display: 'Physiological audio signal',
    },
    '109116': {
      display: 'Arterial Pulse Waveform',
    },
    '109117': {
      display: 'Respiration Waveform',
    },
    '109120': {
      display: 'On admission to unit',
    },
    '109121': {
      display: 'On discharge',
    },
    '109122': {
      display: 'On discharge from unit',
    },
    '109123': {
      display: 'Pre-intervention',
    },
    '109124': {
      display: 'Post-intervention',
    },
    '109125': {
      display: 'At last appointment',
    },
    '109132': {
      display: 'Joint position method',
    },
    '109133': {
      display: 'Physical force',
    },
    '109134': {
      display: 'Prior to voiding',
    },
    '109135': {
      display: 'Post voiding',
    },
    '109136': {
      display: 'Neutral musculoskeletal position',
    },
    '109200': {
      display: 'America Kennel Club',
    },
    '109201': {
      display: "America's Pet Registry Inc.",
    },
    '109202': {
      display: 'American Canine Association',
    },
    '109203': {
      display: 'American Purebred Registry',
    },
    '109204': {
      display: 'American Rare Breed Association',
    },
    '109205': {
      display: 'Animal Registry Unlimited',
    },
    '109206': {
      display: 'Animal Research Foundation',
    },
    '109207': {
      display: 'Canadian Border Collie Association',
    },
    '109208': {
      display: 'Canadian Kennel Club',
    },
    '109209': {
      display: 'Canadian Livestock Records Association',
    },
    '109210': {
      display: 'Canine Federation of Canada',
    },
    '109211': {
      display: 'Continental Kennel Club',
    },
    '109212': {
      display: 'Dog Registry of America',
    },
    '109213': {
      display: 'Federation of International Canines',
    },
    '109214': {
      display: "International Progressive Dog Breeders' Alliance",
    },
    '109215': {
      display: 'National Kennel Club',
    },
    '109216': {
      display: 'North American Purebred Dog Registry',
    },
    '109217': {
      display: 'United All Breed Registry',
    },
    '109218': {
      display: 'United Kennel Club',
    },
    '109219': {
      display: 'Universal Kennel Club International',
    },
    '109220': {
      display: 'Working Canine Association of Canada',
    },
    '109221': {
      display: 'World Kennel Club',
    },
    '109222': {
      display: 'World Wide Kennel Club',
    },
    '109701': {
      display: 'Overall image quality evaluation',
    },
    '109702': {
      display: 'Grayscale resolution evaluation',
    },
    '109703': {
      display: 'Luminance response evaluation',
    },
    '109704': {
      display: 'Luminance uniformity evaluation',
    },
    '109705': {
      display: 'Chromaticity evaluation',
    },
    '109706': {
      display: 'Pixel faults evaluation',
    },
    '109707': {
      display: 'Veiling glare evaluation',
    },
    '109708': {
      display: 'Geometrical image evaluation',
    },
    '109709': {
      display: 'Angular viewing evaluation',
    },
    '109710': {
      display: 'Clinical evaluation',
    },
    '109801': {
      display: 'TG18-QC Pattern',
    },
    '109802': {
      display: 'TG18-BR Pattern',
    },
    '109803': {
      display: 'TG18-PQC Pattern',
    },
    '109804': {
      display: 'TG18-CT Pattern',
    },
    '109805': {
      display: 'TG18-LN8-01 Pattern',
    },
    '109806': {
      display: 'TG18-LN8-02 Pattern',
    },
    '109807': {
      display: 'TG18-LN8-03 Pattern',
    },
    '109808': {
      display: 'TG18-LN8-04 Pattern',
    },
    '109809': {
      display: 'TG18-LN8-05 Pattern',
    },
    '109810': {
      display: 'TG18-LN8-06 Pattern',
    },
    '109811': {
      display: 'TG18-LN8-07 Pattern',
    },
    '109812': {
      display: 'TG18-LN8-08 Pattern',
    },
    '109813': {
      display: 'TG18-LN8-09 Pattern',
    },
    '109814': {
      display: 'TG18-LN8-10 Pattern',
    },
    '109815': {
      display: 'TG18-LN8-11 Pattern',
    },
    '109816': {
      display: 'TG18-LN8-12 Pattern',
    },
    '109817': {
      display: 'TG18-LN8-13 Pattern',
    },
    '109818': {
      display: 'TG18-LN8-14 Pattern',
    },
    '109819': {
      display: 'TG18-LN8-15 Pattern',
    },
    '109820': {
      display: 'TG18-LN8-16 Pattern',
    },
    '109821': {
      display: 'TG18-LN8-17 Pattern',
    },
    '109822': {
      display: 'TG18-LN8-18 Pattern',
    },
    '109823': {
      display: 'TG18-LN12-01 Pattern',
    },
    '109824': {
      display: 'TG18-LN12-02 Pattern',
    },
    '109825': {
      display: 'TG18-LN12-03 Pattern',
    },
    '109826': {
      display: 'TG18-LN12-04 Pattern',
    },
    '109827': {
      display: 'TG18-LN12-05 Pattern',
    },
    '109828': {
      display: 'TG18-LN12-06 Pattern',
    },
    '109829': {
      display: 'TG18-LN12-07 Pattern',
    },
    '109830': {
      display: 'TG18-LN12-08 Pattern',
    },
    '109831': {
      display: 'TG18-LN12-09 Pattern',
    },
    '109832': {
      display: 'TG18-LN12-10 Pattern',
    },
    '109833': {
      display: 'TG18-LN12-11 Pattern',
    },
    '109834': {
      display: 'TG18-LN12-12 Pattern',
    },
    '109835': {
      display: 'TG18-LN12-13 Pattern',
    },
    '109836': {
      display: 'TG18-LN12-14 Pattern',
    },
    '109837': {
      display: 'TG18-LN12-15 Pattern',
    },
    '109838': {
      display: 'TG18-LN12-16 Pattern',
    },
    '109839': {
      display: 'TG18-LN12-17 Pattern',
    },
    '109840': {
      display: 'TG18-LN12-18 Pattern',
    },
    '109841': {
      display: 'TG18-UN10 Pattern',
    },
    '109842': {
      display: 'TG18-UN80 Pattern',
    },
    '109843': {
      display: 'TG18-UNL10 Pattern',
    },
    '109844': {
      display: 'TG18-UNL80 Pattern',
    },
    '109845': {
      display: 'TG18-AD Pattern',
    },
    '109846': {
      display: 'TG18-MP Pattern',
    },
    '109847': {
      display: 'TG18-RH10 Pattern',
    },
    '109848': {
      display: 'TG18-RH50 Pattern',
    },
    '109849': {
      display: 'TG18-RH89 Pattern',
    },
    '109850': {
      display: 'TG18-RV10 Pattern',
    },
    '109851': {
      display: 'TG18-RV50 Pattern',
    },
    '109852': {
      display: 'TG18-RV89 Pattern',
    },
    '109853': {
      display: 'TG18-PX Pattern',
    },
    '109854': {
      display: 'TG18-CX Pattern',
    },
    '109855': {
      display: 'TG18-LPH10 Pattern',
    },
    '109856': {
      display: 'TG18-LPH50 Pattern',
    },
    '109857': {
      display: 'TG18-LPH89 Pattern',
    },
    '109858': {
      display: 'TG18-LPV10 Pattern',
    },
    '109859': {
      display: 'TG18-LPV50 Pattern',
    },
    '109860': {
      display: 'TG18-LPV89 Pattern',
    },
    '109861': {
      display: 'TG18-AFC Pattern',
    },
    '109862': {
      display: 'TG18-NS10 Pattern',
    },
    '109863': {
      display: 'TG18-NS50 Pattern',
    },
    '109864': {
      display: 'TG18-NS89 Pattern',
    },
    '109865': {
      display: 'TG18-GV Pattern',
    },
    '109866': {
      display: 'TG18-GVN Pattern',
    },
    '109867': {
      display: 'TG18-GQ Pattern',
    },
    '109868': {
      display: 'TG18-GQN Pattern',
    },
    '109869': {
      display: 'TG18-GQB Pattern',
    },
    '109870': {
      display: 'TG18-GA03 Pattern',
    },
    '109871': {
      display: 'TG18-GA05 Pattern',
    },
    '109872': {
      display: 'TG18-GA08 Pattern',
    },
    '109873': {
      display: 'TG18-GA10 Pattern',
    },
    '109874': {
      display: 'TG18-GA15 Pattern',
    },
    '109875': {
      display: 'TG18-GA20 Pattern',
    },
    '109876': {
      display: 'TG18-GA25 Pattern',
    },
    '109877': {
      display: 'TG18-GA30 Pattern',
    },
    '109878': {
      display: 'TG18-CH Image',
    },
    '109879': {
      display: 'TG18-KN Image',
    },
    '109880': {
      display: 'TG18-MM1 Image',
    },
    '109881': {
      display: 'TG18-MM2 Image',
    },
    '109901': {
      display: 'OIQ Pattern',
    },
    '109902': {
      display: 'ANG Pattern',
    },
    '109903': {
      display: 'GD Pattern',
    },
    '109904': {
      display: 'BN01 Pattern',
    },
    '109905': {
      display: 'BN02 Pattern',
    },
    '109906': {
      display: 'BN03 Pattern',
    },
    '109907': {
      display: 'BN04 Pattern',
    },
    '109908': {
      display: 'BN05 Pattern',
    },
    '109909': {
      display: 'BN06 Pattern',
    },
    '109910': {
      display: 'BN07 Pattern',
    },
    '109911': {
      display: 'BN08 Pattern',
    },
    '109912': {
      display: 'BN09 Pattern',
    },
    '109913': {
      display: 'BN10 Pattern',
    },
    '109914': {
      display: 'BN11 Pattern',
    },
    '109915': {
      display: 'BN12 Pattern',
    },
    '109916': {
      display: 'BN13 Pattern',
    },
    '109917': {
      display: 'BN14 Pattern',
    },
    '109918': {
      display: 'BN15 Pattern',
    },
    '109919': {
      display: 'BN16 Pattern',
    },
    '109920': {
      display: 'BN17 Pattern',
    },
    '109921': {
      display: 'BN18 Pattern',
    },
    '109931': {
      display: 'DIN Grayscale Pattern',
    },
    '109932': {
      display: 'DIN Geometry Pattern',
    },
    '109933': {
      display: 'DIN Resolution Pattern',
    },
    '109941': {
      display: 'White Pattern',
    },
    '109943': {
      display: 'SMPTE Pattern',
    },
    '109991': {
      display: 'CRT Display',
    },
    '109992': {
      display: 'Liquid Crystal Display',
    },
    '109993': {
      display: 'Plasma Display',
    },
    '109994': {
      display: 'OLED',
    },
    '109995': {
      display: 'DLP Rear Projection System',
    },
    '109996': {
      display: 'DLP Front Projection System',
    },
    '109997': {
      display: 'CRT Rear Projection System',
    },
    '109998': {
      display: 'CRT Front Projection System',
    },
    '109999': {
      display: 'Other Projection System',
    },
    '110001': {
      display: 'Image Processing',
    },
    '110002': {
      display: 'Quality Control',
    },
    '110003': {
      display: 'Computer Aided Diagnosis',
    },
    '110004': {
      display: 'Computer Aided Detection',
    },
    '110005': {
      display: 'Interpretation',
    },
    '110006': {
      display: 'Transcription',
    },
    '110007': {
      display: 'Report Verification',
    },
    '110008': {
      display: 'Print',
    },
    '110009': {
      display: 'No subsequent Workitems',
    },
    '110010': {
      display: 'Film',
    },
    '110011': {
      display: 'Dictation',
    },
    '110012': {
      display: 'Transcription',
    },
    '110013': {
      display: 'Media Import',
    },
    '110020': {
      display: 'Sheet Film Digitized',
    },
    '110021': {
      display: 'Cine Film Digitized',
    },
    '110022': {
      display: 'Video Tape Digitized',
    },
    '110023': {
      display: 'Paper Digitized',
    },
    '110024': {
      display: 'CD Imported',
    },
    '110025': {
      display: 'DVD Imported',
    },
    '110026': {
      display: 'MOD Imported',
    },
    '110027': {
      display: 'Studies Imported',
    },
    '110028': {
      display: 'Instances Imported',
    },
    '110030': {
      display: 'USB Disk Emulation',
    },
    '110031': {
      display: 'Email',
    },
    '110032': {
      display: 'CD',
    },
    '110033': {
      display: 'DVD',
    },
    '110034': {
      display: 'Compact Flash',
    },
    '110035': {
      display: 'Multi-media Card',
    },
    '110036': {
      display: 'Secure Digital Card',
    },
    '110037': {
      display: 'URI',
    },
    '110038': {
      display: 'Paper Document',
    },
    '110100': {
      display: 'Application Activity',
    },
    '110101': {
      display: 'Audit Log Used',
    },
    '110102': {
      display: 'Begin Transferring DICOM Instances',
    },
    '110103': {
      display: 'DICOM Instances Accessed',
    },
    '110104': {
      display: 'DICOM Instances Transferred',
    },
    '110105': {
      display: 'DICOM Study Deleted',
    },
    '110106': {
      display: 'Export',
    },
    '110107': {
      display: 'Import',
    },
    '110108': {
      display: 'Network Entry',
    },
    '110109': {
      display: 'Order Record',
    },
    '110110': {
      display: 'Patient Record',
    },
    '110111': {
      display: 'Procedure Record',
    },
    '110112': {
      display: 'Query',
    },
    '110113': {
      display: 'Security Alert',
    },
    '110114': {
      display: 'User Authentication',
    },
    '110120': {
      display: 'Application Start',
    },
    '110121': {
      display: 'Application Stop',
    },
    '110122': {
      display: 'Login',
    },
    '110123': {
      display: 'Logout',
    },
    '110124': {
      display: 'Attach',
    },
    '110125': {
      display: 'Detach',
    },
    '110126': {
      display: 'Node Authentication',
    },
    '110127': {
      display: 'Emergency Override Started',
    },
    '110128': {
      display: 'Network Configuration',
    },
    '110129': {
      display: 'Security Configuration',
    },
    '110130': {
      display: 'Hardware Configuration',
    },
    '110131': {
      display: 'Software Configuration',
    },
    '110132': {
      display: 'Use of Restricted Function',
    },
    '110133': {
      display: 'Audit Recording Stopped',
    },
    '110134': {
      display: 'Audit Recording Started',
    },
    '110135': {
      display: 'Object Security Attributes Changed',
    },
    '110136': {
      display: 'Security Roles Changed',
    },
    '110137': {
      display: 'User security Attributes Changed',
    },
    '110138': {
      display: 'Emergency Override Stopped',
    },
    '110139': {
      display: 'Remote Service Operation Started',
    },
    '110140': {
      display: 'Remote Service Operation Stopped',
    },
    '110141': {
      display: 'Local Service Operation Started',
    },
    '110142': {
      display: 'Local Service Operation Stopped',
    },
    '110150': {
      display: 'Application',
    },
    '110151': {
      display: 'Application Launcher',
    },
    '110152': {
      display: 'Destination Role ID',
    },
    '110153': {
      display: 'Source Role ID',
    },
    '110154': {
      display: 'Destination Media',
    },
    '110155': {
      display: 'Source Media',
    },
    '110180': {
      display: 'Study Instance UID',
    },
    '110181': {
      display: 'SOP Class UID',
    },
    '110182': {
      display: 'Node ID',
    },
    '110190': {
      display: 'Issuer of Identifier',
    },
    '110500': {
      display: 'Doctor canceled procedure',
    },
    '110501': {
      display: 'Equipment failure',
    },
    '110502': {
      display: 'Incorrect procedure ordered',
    },
    '110503': {
      display: 'Patient allergic to media/contrast',
    },
    '110504': {
      display: 'Patient died',
    },
    '110505': {
      display: 'Patient refused to continue procedure',
    },
    '110506': {
      display: 'Patient taken for treatment or surgery',
    },
    '110507': {
      display: 'Patient did not arrive',
    },
    '110508': {
      display: 'Patient pregnant',
    },
    '110509': {
      display: 'Change of procedure for correct charging',
    },
    '110510': {
      display: 'Duplicate order',
    },
    '110511': {
      display: 'Nursing unit cancel',
    },
    '110512': {
      display: 'Incorrect side ordered',
    },
    '110513': {
      display: 'Discontinued for unspecified reason',
    },
    '110514': {
      display: 'Incorrect worklist entry selected',
    },
    '110515': {
      display: 'Patient condition prevented continuing',
    },
    '110516': {
      display: 'Equipment change',
    },
    '110518': {
      display: 'Patient Movement',
    },
    '110519': {
      display: 'Operator Error',
    },
    '110521': {
      display: 'Objects incorrectly formatted',
    },
    '110522': {
      display: 'Object Types not supported',
    },
    '110523': {
      display: 'Object Set incomplete',
    },
    '110524': {
      display: 'Media Failure',
    },
    '110526': {
      display: 'Resource pre-empted',
    },
    '110527': {
      display: 'Resource inadequate',
    },
    '110528': {
      display: 'Discontinued Procedure Step rescheduled',
    },
    '110529': {
      display: 'Discontinued Procedure Step rescheduling recommended',
    },
    '110700': {
      display: 'Ventral Diencephalon',
    },
    '110701': {
      display: 'White Matter T1 Hypointensity',
    },
    '110702': {
      display: 'White Matter T2 Hyperintensity',
    },
    '110703': {
      display: 'superior longitudinal fasciculus I',
    },
    '110704': {
      display: 'superior longitudinal fasciculus II',
    },
    '110705': {
      display: 'superior longitudinal fasciculus III',
    },
    '110706': {
      display: 'Perilesional White Matter',
    },
    '110800': {
      display: 'Spin Tagging Perfusion MR Signal Intensity',
    },
    '110801': {
      display: 'Contrast Agent Angio MR Signal Intensity',
    },
    '110802': {
      display: 'Time Of Flight Angio MR Signal Intensity',
    },
    '110803': {
      display: 'Proton Density Weighted MR Signal Intensity',
    },
    '110804': {
      display: 'T1 Weighted MR Signal Intensity',
    },
    '110805': {
      display: 'T2 Weighted MR Signal Intensity',
    },
    '110806': {
      display: 'T2* Weighted MR Signal Intensity',
    },
    '110807': {
      display: 'Field Map MR Signal Intensity',
    },
    '110808': {
      display: 'Fractional Anisotropy',
    },
    '110809': {
      display: 'Relative Anisotropy',
    },
    '110810': {
      display: 'Volumetric Diffusion Dxx Component',
    },
    '110811': {
      display: 'Volumetric Diffusion Dxy Component',
    },
    '110812': {
      display: 'Volumetric Diffusion Dxz Component',
    },
    '110813': {
      display: 'Volumetric Diffusion Dyy Component',
    },
    '110814': {
      display: 'Volumetric Diffusion Dyz Component',
    },
    '110815': {
      display: 'Volumetric Diffusion Dzz Component',
    },
    '110816': {
      display: 'T1 Weighted Dynamic Contrast Enhanced MR Signal Intensity',
    },
    '110817': {
      display: 'T2 Weighted Dynamic Contrast Enhanced MR Signal Intensity',
    },
    '110818': {
      display: 'T2* Weighted Dynamic Contrast Enhanced MR Signal Intensity',
    },
    '110819': {
      display: 'Blood Oxygenation Level',
    },
    '110820': {
      display: 'Nuclear Medicine Projection Activity',
    },
    '110821': {
      display: 'Nuclear Medicine Tomographic Activity',
    },
    '110822': {
      display: 'Spatial Displacement X Component',
    },
    '110823': {
      display: 'Spatial Displacement Y Component',
    },
    '110824': {
      display: 'Spatial Displacement Z Component',
    },
    '110825': {
      display: 'Hemodynamic Resistance',
    },
    '110826': {
      display: 'Indexed Hemodynamic Resistance',
    },
    '110827': {
      display: 'Tissue Velocity',
    },
    '110828': {
      display: 'Flow Velocity',
    },
    '110829': {
      display: 'Flow Variance',
    },
    '110830': {
      display: 'Elasticity',
    },
    '110831': {
      display: 'Perfusion',
    },
    '110832': {
      display: 'Speed of sound',
    },
    '110833': {
      display: 'Ultrasound Attenuation',
    },
    '110834': {
      display: 'RGB R Component',
    },
    '110835': {
      display: 'RGB G Component',
    },
    '110836': {
      display: 'RGB B Component',
    },
    '110837': {
      display: 'YBR FULL Y Component',
    },
    '110838': {
      display: 'YBR FULL CB Component',
    },
    '110839': {
      display: 'YBR FULL CR Component',
    },
    '110840': {
      display: 'YBR PARTIAL Y Component',
    },
    '110841': {
      display: 'YBR PARTIAL CB Component',
    },
    '110842': {
      display: 'YBR PARTIAL CR Component',
    },
    '110843': {
      display: 'YBR ICT Y Component',
    },
    '110844': {
      display: 'YBR ICT CB Component',
    },
    '110845': {
      display: 'YBR ICT CR Component',
    },
    '110846': {
      display: 'YBR RCT Y Component',
    },
    '110847': {
      display: 'YBR RCT CB Component',
    },
    '110848': {
      display: 'YBR RCT CR Component',
    },
    '110849': {
      display: 'Echogenicity',
    },
    '110850': {
      display: 'X-Ray Attenuation',
    },
    '110851': {
      display: 'X-Ray Attenuation Coefficient',
    },
    '110852': {
      display: 'MR signal intensity',
    },
    '110853': {
      display: 'Binary Segmentation',
    },
    '110854': {
      display: 'Fractional Probabilistic Segmentation',
    },
    '110855': {
      display: 'Fractional Occupancy Segmentation',
    },
    '110856': {
      display: 'Linear Displacement',
    },
    '110857': {
      display: 'Photon Energy',
    },
    '110858': {
      display: 'Time',
    },
    '110859': {
      display: 'Angle',
    },
    '110860': {
      display: 'Left-Right Axis',
    },
    '110861': {
      display: 'Head-Foot Axis',
    },
    '110862': {
      display: 'Anterior-Posterior Axis',
    },
    '110863': {
      display: 'Apex-Base Axis',
    },
    '110864': {
      display: 'Anterior-Inferior Axis',
    },
    '110865': {
      display: 'Septum-Wall Axis',
    },
    '110866': {
      display: 'Right To Left',
    },
    '110867': {
      display: 'Left To Right',
    },
    '110868': {
      display: 'Head To Foot',
    },
    '110869': {
      display: 'Foot To Head',
    },
    '110870': {
      display: 'Anterior To Posterior',
    },
    '110871': {
      display: 'Posterior To Anterior',
    },
    '110872': {
      display: 'Apex To Base',
    },
    '110873': {
      display: 'Base To Apex',
    },
    '110874': {
      display: 'Anterior To Inferior',
    },
    '110875': {
      display: 'Inferior To Anterior',
    },
    '110876': {
      display: 'Septum To Wall',
    },
    '110877': {
      display: 'Wall To Septum',
    },
    '110901': {
      display: 'Image Position (Patient) X',
    },
    '110902': {
      display: 'Image Position (Patient) Y',
    },
    '110903': {
      display: 'Image Position (Patient) Z',
    },
    '110904': {
      display: 'Image Orientation (Patient) Row X',
    },
    '110905': {
      display: 'Image Orientation (Patient) Row Y',
    },
    '110906': {
      display: 'Image Orientation (Patient) Row Z',
    },
    '110907': {
      display: 'Image Orientation (Patient) Column X',
    },
    '110908': {
      display: 'Image Orientation (Patient) Column Y',
    },
    '110909': {
      display: 'Image Orientation (Patient) Column Z',
    },
    '110910': {
      display: 'Pixel Data Rows',
    },
    '110911': {
      display: 'Pixel Data Columns',
    },
    '111001': {
      display: 'Algorithm Name',
    },
    '111002': {
      display: 'Algorithm Parameters',
    },
    '111003': {
      display: 'Algorithm Version',
    },
    '111004': {
      display: 'Analysis Performed',
    },
    '111005': {
      display: 'Assessment Category',
    },
    '111006': {
      display: 'Breast composition',
    },
    '111007': {
      display: 'Breast Outline including Pectoral Muscle Tissue',
    },
    '111008': {
      display: 'Calcification Distribution',
    },
    '111009': {
      display: 'Calcification Type',
    },
    '111010': {
      display: 'Center',
    },
    '111011': {
      display: 'Certainty of Feature',
    },
    '111012': {
      display: 'Certainty of Finding',
    },
    '111013': {
      display: 'Certainty of Impression',
    },
    '111014': {
      display: 'Clockface or region',
    },
    '111015': {
      display: 'Composite Feature',
    },
    '111016': {
      display: 'Composite type',
    },
    '111017': {
      display: 'CAD Processing and Findings Summary',
    },
    '111018': {
      display: 'Content Date',
    },
    '111019': {
      display: 'Content Time',
    },
    '111020': {
      display: 'Depth',
    },
    '111021': {
      display: 'Description of Change',
    },
    '111022': {
      display: 'Detection Performed',
    },
    '111023': {
      display: 'Differential Diagnosis/Impression',
    },
    '111024': {
      display: 'Failed Analyses',
    },
    '111025': {
      display: 'Failed Detections',
    },
    '111026': {
      display: 'Horizontal Pixel Spacing',
    },
    '111027': {
      display: 'Image Laterality',
    },
    '111028': {
      display: 'Image Library',
    },
    '111029': {
      display: 'Image Quality Rating',
    },
    '111030': {
      display: 'Image Region',
    },
    '111031': {
      display: 'Image View',
    },
    '111032': {
      display: 'Image View Modifier',
    },
    '111033': {
      display: 'Impression Description',
    },
    '111034': {
      display: 'Individual Impression/Recommendation',
    },
    '111035': {
      display: 'Lesion Density',
    },
    '111036': {
      display: 'Mammography CAD Report',
    },
    '111037': {
      display: 'Margins',
    },
    '111038': {
      display: 'Number of calcifications',
    },
    '111039': {
      display: 'Object type',
    },
    '111040': {
      display: 'Original Source',
    },
    '111041': {
      display: 'Outline',
    },
    '111042': {
      display: 'Pathology',
    },
    '111043': {
      display: 'Patient Orientation Column',
    },
    '111044': {
      display: 'Patient Orientation Row',
    },
    '111045': {
      display: 'Pectoral Muscle Outline',
    },
    '111046': {
      display: 'Percent Fibroglandular Tissue',
    },
    '111047': {
      display: 'Probability of cancer',
    },
    '111048': {
      display: 'Quadrant location',
    },
    '111049': {
      display: 'Qualitative Difference',
    },
    '111050': {
      display: 'Quality Assessment',
    },
    '111051': {
      display: 'Quality Control Standard',
    },
    '111052': {
      display: 'Quality Finding',
    },
    '111053': {
      display: 'Recommended Follow-up',
    },
    '111054': {
      display: 'Recommended Follow-up Date',
    },
    '111055': {
      display: 'Recommended Follow-up Interval',
    },
    '111056': {
      display: 'Rendering Intent',
    },
    '111057': {
      display: 'Scope of Feature',
    },
    '111058': {
      display: 'Selected Region Description',
    },
    '111059': {
      display: 'Single Image Finding',
    },
    '111060': {
      display: 'Study Date',
    },
    '111061': {
      display: 'Study Time',
    },
    '111062': {
      display: 'Successful Analyses',
    },
    '111063': {
      display: 'Successful Detections',
    },
    '111064': {
      display: 'Summary of Detections',
    },
    '111065': {
      display: 'Summary of Analyses',
    },
    '111066': {
      display: 'Vertical Pixel Spacing',
    },
    '111069': {
      display: 'Crosstable',
    },
    '111071': {
      display: 'CAD Operating Point',
    },
    '111072': {
      display: 'Maximum CAD Operating Point',
    },
    '111081': {
      display: 'CAD Operating Point Description',
    },
    '111086': {
      display: 'False Markers per Image',
    },
    '111087': {
      display: 'False Markers per Case',
    },
    '111088': {
      display: 'Case Sensitivity',
    },
    '111089': {
      display: 'Lesion Sensitivity',
    },
    '111090': {
      display: 'Case Specificity',
    },
    '111091': {
      display: 'Image Specificity',
    },
    '111092': {
      display: 'Recommended CAD Operating Point',
    },
    '111093': {
      display: 'CAD Operating Point Table',
    },
    '111099': {
      display: 'Selected region',
    },
    '111100': {
      display: 'Breast geometry',
    },
    '111101': {
      display: 'Image Quality',
    },
    '111102': {
      display: 'Non-lesion',
    },
    '111103': {
      display: 'Density',
    },
    '111104': {
      display: 'Individual Calcification',
    },
    '111105': {
      display: 'Calcification Cluster',
    },
    '111111': {
      display: "Cooper's ligament changes",
    },
    '111112': {
      display: 'Mass in the skin',
    },
    '111113': {
      display: 'Mass on the skin',
    },
    '111120': {
      display: 'Post Procedure Mammograms for Marker Placement',
    },
    '111121': {
      display: 'Follow-up post biopsy as directed by clinician',
    },
    '111122': {
      display: 'Known biopsy proven malignancy - take appropriate action',
    },
    '111123': {
      display: 'Marker placement',
    },
    '111124': {
      display: 'Personal history of breast cancer with mastectomy',
    },
    '111125': {
      display: 'Known biopsy proven malignancy',
    },
    '111126': {
      display: 'Image detected mass',
    },
    '111127': {
      display: 'Targeted',
    },
    '111128': {
      display: 'Survey',
    },
    '111129': {
      display: 'Clustered microcysts',
    },
    '111130': {
      display: 'Complicated cyst',
    },
    '111135': {
      display: 'Additional projections',
    },
    '111136': {
      display: 'Spot magnification view(s)',
    },
    '111137': {
      display: 'Ultrasound',
    },
    '111138': {
      display: 'Old films for comparison',
    },
    '111139': {
      display: 'Ductography',
    },
    '111140': {
      display: 'Normal interval follow-up',
    },
    '111141': {
      display: 'Any decision to biopsy should be based on clinical assessment',
    },
    '111142': {
      display: 'Follow-up at short interval (1-11 months)',
    },
    '111143': {
      display: 'Biopsy should be considered',
    },
    '111144': {
      display: 'Needle localization and biopsy',
    },
    '111145': {
      display: 'Histology using core biopsy',
    },
    '111146': {
      display: 'Suggestive of malignancy - take appropriate action',
    },
    '111147': {
      display: 'Cytologic analysis',
    },
    '111148': {
      display: 'Biopsy should be strongly considered',
    },
    '111149': {
      display: 'Highly suggestive of malignancy - take appropriate action',
    },
    '111150': {
      display: 'Presentation Required: Rendering device is expected to present',
    },
    '111151': {
      display: 'Presentation Optional: Rendering device may present',
    },
    '111152': {
      display: 'Not for Presentation: Rendering device expected not to present',
    },
    '111153': {
      display: 'Target content items are related temporally',
    },
    '111154': {
      display: 'Target content items are related spatially',
    },
    '111155': {
      display: 'Target content items are related contra-laterally',
    },
    '111156': {
      display: 'Feature detected on the only image',
    },
    '111157': {
      display: 'Feature detected on only one of the images',
    },
    '111158': {
      display: 'Feature detected on multiple images',
    },
    '111159': {
      display: 'Feature detected on images from multiple modalities',
    },
    '111168': {
      display: 'Scar tissue',
    },
    '111170': {
      display: 'J Wire',
    },
    '111171': {
      display: 'Pacemaker',
    },
    '111172': {
      display: 'Paddle',
    },
    '111173': {
      display: 'Collimator',
    },
    '111174': {
      display: 'ID Plate',
    },
    '111175': {
      display: 'Other Marker',
    },
    '111176': {
      display: 'Unspecified',
    },
    '111177': {
      display: 'View and Laterality Marker is missing',
    },
    '111178': {
      display: 'View and Laterality Marker does not have both view and laterality',
    },
    '111179': {
      display: 'View and Laterality Marker does not have approved codes',
    },
    '111180': {
      display: 'View and Laterality Marker is not near the axilla',
    },
    '111181': {
      display: 'View and Laterality Marker overlaps breast tissue',
    },
    '111182': {
      display: 'View and Laterality Marker is partially obscured',
    },
    '111183': {
      display: 'View and Laterality Marker is incorrect',
    },
    '111184': {
      display: 'View and Laterality Marker is off image',
    },
    '111185': {
      display: 'Flash is not near edge of film',
    },
    '111186': {
      display: 'Flash is illigible, does not fit, or is lopsided',
    },
    '111187': {
      display: "Flash doesn't include patient name and additional patient id",
    },
    '111188': {
      display: "Flash doesn't include date of examination",
    },
    '111189': {
      display: "Flash doesn't include facility name and location",
    },
    '111190': {
      display: "Flash doesn't include technologist identification",
    },
    '111191': {
      display: "Flash doesn't include cassette/screen/detector identification",
    },
    '111192': {
      display: "Flash doesn't include mammography unit identification",
    },
    '111193': {
      display: 'Date sticker is missing',
    },
    '111194': {
      display: 'Technical factors missing',
    },
    '111195': {
      display: 'Collimation too close to breast',
    },
    '111196': {
      display: 'Inadequate compression',
    },
    '111197': {
      display: 'MLO Insufficient pectoral muscle',
    },
    '111198': {
      display: 'MLO No fat is visualized posterior to fibroglandular tissues',
    },
    '111199': {
      display: 'MLO Poor separation of deep and superficial breast tissues',
    },
    '111200': {
      display: 'MLO Evidence of motion blur',
    },
    '111201': {
      display: 'MLO Inframammary fold is not open',
    },
    '111202': {
      display: 'CC Not all medial tissue visualized',
    },
    '111203': {
      display: 'CC Nipple not centered on image',
    },
    '111204': {
      display: 'CC Posterior nipple line does not measure within 1 cm of MLO',
    },
    '111205': {
      display: 'Nipple not in profile',
    },
    '111206': {
      display: 'Insufficient implant displacement incorrect',
    },
    '111207': {
      display: 'Image artifact(s)',
    },
    '111208': {
      display: 'Grid artifact(s)',
    },
    '111209': {
      display: 'Positioning',
    },
    '111210': {
      display: 'Motion blur',
    },
    '111211': {
      display: 'Under exposed',
    },
    '111212': {
      display: 'Over exposed',
    },
    '111213': {
      display: 'No image',
    },
    '111214': {
      display: 'Detector artifact(s)',
    },
    '111215': {
      display: 'Artifact(s) other than grid or detector artifact',
    },
    '111216': {
      display: 'Mechanical failure',
    },
    '111217': {
      display: 'Electrical failure',
    },
    '111218': {
      display: 'Software failure',
    },
    '111219': {
      display: 'Inappropriate image processing',
    },
    '111220': {
      display: 'Other failure',
    },
    '111221': {
      display: 'Unknown failure',
    },
    '111222': {
      display: 'Succeeded',
    },
    '111223': {
      display: 'Partially Succeeded',
    },
    '111224': {
      display: 'Failed',
    },
    '111225': {
      display: 'Not Attempted',
    },
    '111233': {
      display: 'Individual Impression / Recommendation Analysis',
    },
    '111234': {
      display: 'Overall Impression / Recommendation Analysis',
    },
    '111235': {
      display: 'Unusable - Quality renders image unusable',
    },
    '111236': {
      display: 'Usable - Does not meet the quality control standard',
    },
    '111237': {
      display: 'Usable - Meets the quality control standard',
    },
    '111238': {
      display: 'Mammography Quality Control Manual 1999, ACR',
    },
    '111239': {
      display: 'Title 21 CFR Section 900, Subpart B',
    },
    '111240': {
      display: 'Institutionally defined quality control standard',
    },
    '111241': {
      display: 'All algorithms succeeded; without findings',
    },
    '111242': {
      display: 'All algorithms succeeded; with findings',
    },
    '111243': {
      display: 'Not all algorithms succeeded; without findings',
    },
    '111244': {
      display: 'Not all algorithms succeeded; with findings',
    },
    '111245': {
      display: 'No algorithms succeeded; without findings',
    },
    '111248': {
      display: 'Adenolipoma',
    },
    '111249': {
      display: 'Ductal hyperplasia',
    },
    '111250': {
      display: 'Adenomyoepithelioma',
    },
    '111251': {
      display: 'Normal axillary node',
    },
    '111252': {
      display: 'Axillary node with calcifications',
    },
    '111253': {
      display: 'Axillary node hyperplasia',
    },
    '111254': {
      display: 'Asynchronous involution',
    },
    '111255': {
      display: 'Benign cyst with blood',
    },
    '111256': {
      display: 'Benign Calcifications',
    },
    '111257': {
      display: 'Intracystic papilloma',
    },
    '111258': {
      display: 'Ductal adenoma',
    },
    '111259': {
      display: 'Diabetic fibrous mastopathy',
    },
    '111260': {
      display: 'Extra abdominal desmoid',
    },
    '111262': {
      display: 'Epidermal inclusion cyst',
    },
    '111263': {
      display: 'Fibroadenomatoid hyperplasia',
    },
    '111264': {
      display: 'Fibroadenolipoma',
    },
    '111265': {
      display: 'Foreign body (reaction)',
    },
    '111269': {
      display: 'Galactocele',
    },
    '111271': {
      display: 'Hemangioma - nonparenchymal, subcutaneous',
    },
    '111273': {
      display: 'Hyperplasia, usual',
    },
    '111277': {
      display: 'Juvenile papillomatosis',
    },
    '111278': {
      display: 'Lactating adenoma',
    },
    '111279': {
      display: 'Lactational change',
    },
    '111281': {
      display: 'Large duct papilloma',
    },
    '111283': {
      display: 'Myofibroblastoma',
    },
    '111284': {
      display: 'Microglandular adenosis',
    },
    '111285': {
      display: 'Multiple Intraductal Papillomas',
    },
    '111286': {
      display: 'No abnormality',
    },
    '111287': {
      display: 'Normal breast tissue',
    },
    '111288': {
      display: 'Neurofibromatosis',
    },
    '111290': {
      display: 'Oil cyst (fat necrosis cyst)',
    },
    '111291': {
      display: 'Post reduction mammoplasty',
    },
    '111292': {
      display: 'Pseudoangiomatous stromal hyperplasia',
    },
    '111293': {
      display: 'Radial scar',
    },
    '111294': {
      display: 'Sclerosing adenosis',
    },
    '111296': {
      display: 'Silicone granuloma',
    },
    '111297': {
      display: 'Nipple Characteristic',
    },
    '111298': {
      display: 'Virginal hyperplasia',
    },
    '111299': {
      display: 'Peripheral duct papillomas',
    },
    '111300': {
      display: 'Axillary node with lymphoma',
    },
    '111301': {
      display: 'Axillary nodal metastases',
    },
    '111302': {
      display: 'Angiosarcoma',
    },
    '111303': {
      display: 'Blood vessel (vascular) invasion',
    },
    '111304': {
      display: 'Carcinoma in children',
    },
    '111305': {
      display: 'Carcinoma in ectopic breast',
    },
    '111306': {
      display: 'Carcinoma with endocrine differentiation',
    },
    '111307': {
      display: 'Basal cell carcinoma of nipple',
    },
    '111308': {
      display: 'Carcinoma with metaplasia',
    },
    '111309': {
      display: 'Cartilaginous and osseous change',
    },
    '111310': {
      display: 'Carcinoma in pregnancy and lactation',
    },
    '111311': {
      display: 'Carcinosarcoma',
    },
    '111312': {
      display: 'Intraductal comedocarcinoma with necrosis',
    },
    '111313': {
      display: 'Intraductal carcinoma, low grade',
    },
    '111314': {
      display: 'Intraductal carcinoma micro-papillary',
    },
    '111315': {
      display: 'Intracystic papillary carcinoma',
    },
    '111316': {
      display: 'Invasive and in-situ carcinoma',
    },
    '111317': {
      display: 'Invasive lobular carcinoma',
    },
    '111318': {
      display: 'Leukemic infiltration',
    },
    '111320': {
      display: 'Lympathic vessel invasion',
    },
    '111321': {
      display: 'Lymphoma',
    },
    '111322': {
      display: 'Occult carcinoma presenting with axillary lymph node metastases',
    },
    '111323': {
      display: 'Metastatic cancer to the breast',
    },
    '111324': {
      display: 'Metastatic cancer to the breast from the colon',
    },
    '111325': {
      display: 'Metastatic cancer to the breast from the lung',
    },
    '111326': {
      display: 'Metastatic melanoma to the breast',
    },
    '111327': {
      display: 'Metastatic cancer to the breast from the ovary',
    },
    '111328': {
      display: 'Metastatic sarcoma to the breast',
    },
    '111329': {
      display: 'Multifocal intraductal carcinoma',
    },
    '111330': {
      display: 'Metastatic disease to axillary node',
    },
    '111331': {
      display: 'Malignant fibrous histiocytoma',
    },
    '111332': {
      display: 'Multifocal invasive ductal carcinoma',
    },
    '111333': {
      display: 'Metastasis to an intramammary lymph node',
    },
    '111334': {
      display: 'Malignant melanoma of nipple',
    },
    '111335': {
      display: 'Neoplasm of the mammary skin',
    },
    '111336': {
      display: 'Papillary carcinoma in-situ',
    },
    '111338': {
      display: 'Recurrent malignancy',
    },
    '111340': {
      display: 'Squamous cell carcinoma of the nipple',
    },
    '111341': {
      display: 'Intraductal carcinoma, high grade',
    },
    '111342': {
      display: 'Invasive cribriform carcinoma',
    },
    '111343': {
      display: 'Angular margins',
    },
    '111344': {
      display: 'Fine pleomorphic calcification',
    },
    '111345': {
      display: 'Macrocalcifications',
    },
    '111346': {
      display: 'Calcifications within a mass',
    },
    '111347': {
      display: 'Calcifications outside of a mass',
    },
    '111350': {
      display: 'Breast background echotexture',
    },
    '111351': {
      display: 'Homogeneous fat echotexture',
    },
    '111352': {
      display: 'Homogeneous fibroglandular echotexture',
    },
    '111353': {
      display: 'Heterogeneous echotexture',
    },
    '111354': {
      display: 'Orientation',
    },
    '111355': {
      display: 'Parallel',
    },
    '111356': {
      display: 'Not parallel',
    },
    '111357': {
      display: 'Lesion boundary',
    },
    '111358': {
      display: 'Abrupt interface',
    },
    '111359': {
      display: 'Echogenic halo',
    },
    '111360': {
      display: 'Echo pattern',
    },
    '111361': {
      display: 'Anechoic',
    },
    '111362': {
      display: 'Hyperechoic',
    },
    '111363': {
      display: 'Complex',
    },
    '111364': {
      display: 'Hypoechoic',
    },
    '111365': {
      display: 'Isoechoic',
    },
    '111366': {
      display: 'Posterior acoustic features',
    },
    '111367': {
      display: 'No posterior acoustic features',
    },
    '111368': {
      display: 'Posterior enhancement',
    },
    '111369': {
      display: 'Posterior shadowing',
    },
    '111370': {
      display: 'Combined posterior enhancement and shadowing',
    },
    '111371': {
      display: 'Identifiable effect on surrounding tissues',
    },
    '111372': {
      display: 'Vascularity',
    },
    '111373': {
      display: 'Vascularity not present',
    },
    '111374': {
      display: 'Vascularity not assessed',
    },
    '111375': {
      display: 'Vascularity present in lesion',
    },
    '111376': {
      display: 'Vascularity present immediately adjacent to lesion',
    },
    '111377': {
      display: 'Diffusely increased vascularity in surrounding tissue',
    },
    '111380': {
      display: 'Correlation to other Findings',
    },
    '111381': {
      display: 'Correlates to physical exam findings',
    },
    '111382': {
      display: 'Correlates to mammography findings',
    },
    '111383': {
      display: 'Correlates to MRI findings',
    },
    '111384': {
      display: 'Correlates to ultrasound findings',
    },
    '111385': {
      display: 'Correlates to other imaging findings',
    },
    '111386': {
      display: 'No correlation to other imaging findings',
    },
    '111387': {
      display: 'No correlation to clinical findings',
    },
    '111388': {
      display: 'Malignancy Type',
    },
    '111389': {
      display: 'Invasive breast carcinoma',
    },
    '111390': {
      display: 'Other malignancy type',
    },
    '111391': {
      display: 'Menstrual Cycle Phase',
    },
    '111392': {
      display: '1st week',
    },
    '111393': {
      display: '2nd week',
    },
    '111394': {
      display: '3rd week',
    },
    '111395': {
      display: 'Estimated Timeframe',
    },
    '111396': {
      display: '< 3 months ago',
    },
    '111397': {
      display: '4 months to 1 year ago',
    },
    '111398': {
      display: '> 1 year ago',
    },
    '111399': {
      display: 'Timeframe uncertain',
    },
    '111400': {
      display: 'Breast Imaging Report',
    },
    '111401': {
      display: 'Reason for procedure',
    },
    '111402': {
      display: 'Clinical Finding',
    },
    '111403': {
      display: 'Baseline screening mammogram',
    },
    '111404': {
      display: 'First mammogram ever',
    },
    '111405': {
      display: 'Implant type',
    },
    '111406': {
      display: 'Number of similar findings',
    },
    '111407': {
      display: 'Implant finding',
    },
    '111408': {
      display: 'Film Screen Mammography',
    },
    '111409': {
      display: 'Digital Mammography',
    },
    '111410': {
      display: 'Surgical consult',
    },
    '111411': {
      display: 'Mammography CAD',
    },
    '111412': {
      display: 'Narrative Summary',
    },
    '111413': {
      display: 'Overall Assessment',
    },
    '111414': {
      display: 'Supplementary Data',
    },
    '111415': {
      display: 'Additional evaluation requested from prior study',
    },
    '111416': {
      display: 'Follow-up at short interval from prior study',
    },
    '111417': {
      display: 'History of breast augmentation, asymptomatic',
    },
    '111418': {
      display: 'Review of an outside study',
    },
    '111419': {
      display: 'Additional evaluation requested from abnormal screening exam',
    },
    '111420': {
      display: 'History of benign breast biopsy',
    },
    '111421': {
      display: 'Personal history of breast cancer with breast conservation therapy',
    },
    '111423': {
      display: 'Physical Examination Results',
    },
    '111424': {
      display: 'Comparison to previous findings',
    },
    '111425': {
      display: 'Intraluminal filling defect',
    },
    '111426': {
      display: 'Multiple filling defect',
    },
    '111427': {
      display: 'Abrupt duct termination',
    },
    '111428': {
      display: 'Extravasation',
    },
    '111429': {
      display: 'Duct narrowing',
    },
    '111430': {
      display: 'Cyst fill',
    },
    '111431': {
      display: 'Instrument Approach',
    },
    '111432': {
      display: 'Inferolateral to superomedial',
    },
    '111433': {
      display: 'Inferomedial to superolateral',
    },
    '111434': {
      display: 'Superolateral to inferomedial',
    },
    '111435': {
      display: 'Superomedial to inferolateral',
    },
    '111436': {
      display: 'Number of passes',
    },
    '111437': {
      display: 'Number of specimens',
    },
    '111438': {
      display: 'Needle in target',
    },
    '111439': {
      display: 'Number of needles around target',
    },
    '111440': {
      display: 'Incision made',
    },
    '111441': {
      display: 'Microclip placed',
    },
    '111442': {
      display: 'Confirmation of target',
    },
    '111443': {
      display: 'Target completely contained in the specimen',
    },
    '111444': {
      display: 'Target partially obtained in the specimen',
    },
    '111445': {
      display: 'Target not in the specimen',
    },
    '111446': {
      display: 'Calcifications seen in the core',
    },
    '111447': {
      display: 'Lesion completely removed',
    },
    '111448': {
      display: 'Lesion partially removed',
    },
    '111449': {
      display: 'Fluid obtained',
    },
    '111450': {
      display: 'Light brown color',
    },
    '111451': {
      display: 'Dark red color',
    },
    '111452': {
      display: 'Dark brown color',
    },
    '111453': {
      display: 'Bright red color',
    },
    '111454': {
      display: 'Blood tinged color',
    },
    '111455': {
      display: 'Occult blood test result',
    },
    '111456': {
      display: 'Action on fluid',
    },
    '111457': {
      display: 'Sent for analysis',
    },
    '111458': {
      display: 'Discarded',
    },
    '111459': {
      display: 'Mass with calcifications',
    },
    '111460': {
      display: 'Complex cyst',
    },
    '111461': {
      display: 'Intracystic lesion',
    },
    '111462': {
      display: 'Solid mass',
    },
    '111463': {
      display: 'Supplementary Data for Intervention',
    },
    '111464': {
      display: 'Procedure Modifier',
    },
    '111465': {
      display: 'Needle Gauge',
    },
    '111466': {
      display: 'Severity of Complication',
    },
    '111467': {
      display: 'Needle Length',
    },
    '111468': {
      display: 'Pathology Results',
    },
    '111469': {
      display: 'Sampling DateTime',
    },
    '111470': {
      display: 'Uninvolved',
    },
    '111471': {
      display: 'Involved',
    },
    '111472': {
      display: 'Nipple involved',
    },
    '111473': {
      display: 'Number of nodes removed',
    },
    '111474': {
      display: 'Number of nodes positive',
    },
    '111475': {
      display: 'Estrogen receptor',
    },
    '111476': {
      display: 'Progesterone receptor',
    },
    '111477': {
      display: 'S Phase',
    },
    '111478': {
      display: 'Non-bloody discharge (from nipple)',
    },
    '111479': {
      display: 'Difficult physical/clinical examination',
    },
    '111480': {
      display: 'Cancer elsewhere',
    },
    '111481': {
      display: 'Saline implant',
    },
    '111482': {
      display: 'Polyurethane implant',
    },
    '111483': {
      display: 'Percutaneous silicone injection',
    },
    '111484': {
      display: 'Combination implant',
    },
    '111485': {
      display: 'Pre-pectoral implant',
    },
    '111486': {
      display: 'Retro-pectoral implant',
    },
    '111487': {
      display: 'Mammographic (crosshair)',
    },
    '111488': {
      display: 'Mammographic (grid)',
    },
    '111489': {
      display: 'Palpation guided',
    },
    '111490': {
      display: 'Vacuum assisted',
    },
    '111491': {
      display: 'Abnormal discharge',
    },
    '111492': {
      display: 'No complications',
    },
    '111494': {
      display: 'Stage 0',
    },
    '111495': {
      display: 'Stage I',
    },
    '111496': {
      display: 'Stage IIA',
    },
    '111497': {
      display: 'Stage IIB',
    },
    '111498': {
      display: 'Stage IIIA',
    },
    '111499': {
      display: 'Stage IIIB',
    },
    '111500': {
      display: 'Stage IIIC',
    },
    '111501': {
      display: 'Stage IV',
    },
    '111502': {
      display: 'Bloom-Richardson Grade',
    },
    '111503': {
      display: 'Normal implants',
    },
    '111504': {
      display: 'Asymmetric implants',
    },
    '111505': {
      display: 'Calcified implant',
    },
    '111506': {
      display: 'Distorted implant',
    },
    '111507': {
      display: 'Silicone-laden lymph nodes',
    },
    '111508': {
      display: 'Free silicone',
    },
    '111509': {
      display: 'Herniated implant',
    },
    '111510': {
      display: 'Explantation',
    },
    '111511': {
      display: 'Relevant Patient Information for Breast Imaging',
    },
    '111512': {
      display: 'Medication History',
    },
    '111513': {
      display: 'Relevant Previous Procedures',
    },
    '111514': {
      display: 'Relevant Indicated Problems',
    },
    '111515': {
      display: 'Relevant Risk Factors',
    },
    '111516': {
      display: 'Medication Type',
    },
    '111517': {
      display: 'Relevant Patient Information',
    },
    '111518': {
      display: 'Age when first menstrual period occurred',
    },
    '111519': {
      display: 'Age at First Full Term Pregnancy',
    },
    '111520': {
      display: 'Age at Menopause',
    },
    '111521': {
      display: 'Age when hysterectomy performed',
    },
    '111522': {
      display: 'Age when left ovary removed',
    },
    '111523': {
      display: 'Age when right ovary removed',
    },
    '111524': {
      display: 'Age Started',
    },
    '111525': {
      display: 'Age Ended',
    },
    '111526': {
      display: 'DateTime Started',
    },
    '111527': {
      display: 'DateTime Ended',
    },
    '111528': {
      display: 'Ongoing',
    },
    '111529': {
      display: 'Brand Name',
    },
    '111530': {
      display: 'Risk Factor modifier',
    },
    '111531': {
      display: 'Previous Procedure',
    },
    '111532': {
      display: 'Pregnancy Status',
    },
    '111533': {
      display: 'Indicated Problem',
    },
    '111534': {
      display: 'Role of person reporting',
    },
    '111535': {
      display: 'DateTime problem observed',
    },
    '111536': {
      display: 'DateTime of last evaluation',
    },
    '111537': {
      display: 'Family Member with Risk Factor',
    },
    '111538': {
      display: 'Age at Occurrence',
    },
    '111539': {
      display: 'Menopausal phase',
    },
    '111540': {
      display: 'Side of Family',
    },
    '111541': {
      display: 'Maternal',
    },
    '111542': {
      display: 'Unspecified gynecological hormone',
    },
    '111543': {
      display: 'Breast feeding history',
    },
    '111544': {
      display: 'Average breast feeding period',
    },
    '111545': {
      display: 'Substance Use History',
    },
    '111546': {
      display: 'Used Substance Type',
    },
    '111547': {
      display: 'Environmental Exposure History',
    },
    '111548': {
      display: 'Environmental Factor',
    },
    '111549': {
      display: 'Previous Reports',
    },
    '111550': {
      display: 'Personal breast cancer history',
    },
    '111551': {
      display: 'History of endometrial cancer',
    },
    '111552': {
      display: 'History of ovarian cancer',
    },
    '111553': {
      display: 'History of high risk lesion on previous biopsy',
    },
    '111554': {
      display: 'Post menopausal patient',
    },
    '111555': {
      display: 'Late child bearing (after 30)',
    },
    '111556': {
      display: 'BRCA1 breast cancer gene',
    },
    '111557': {
      display: 'BRCA2 breast cancer gene',
    },
    '111558': {
      display: 'BRCA3 breast cancer gene',
    },
    '111559': {
      display: 'Weak family history of breast cancer',
    },
    '111560': {
      display: 'Intermediate family history of breast cancer',
    },
    '111561': {
      display: 'Very strong family history of breast cancer',
    },
    '111562': {
      display: 'Family history of prostate cancer',
    },
    '111563': {
      display: 'Family history unknown',
    },
    '111564': {
      display: 'Nipple discharge cytology',
    },
    '111565': {
      display: 'Uterine malformations',
    },
    '111566': {
      display: 'Spontaneous Abortion',
    },
    '111567': {
      display: 'Gynecologic condition',
    },
    '111568': {
      display: 'Gynecologic surgery',
    },
    '111569': {
      display: 'Previous LBW or IUGR birth',
    },
    '111570': {
      display: 'Previous fetal malformation/syndrome',
    },
    '111571': {
      display: 'Previous RH negative or blood dyscrasia at birth',
    },
    '111572': {
      display: 'History of multiple fetuses',
    },
    '111573': {
      display: 'Current pregnancy, known or suspected malformations/syndromes',
    },
    '111574': {
      display: 'Family history, fetal malformation/syndrome',
    },
    '111575': {
      display: 'High',
    },
    '111576': {
      display: 'Medium',
    },
    '111577': {
      display: 'Low',
    },
    '111578': {
      display: 'Dose frequency',
    },
    '111579': {
      display: 'Rate of exposure',
    },
    '111580': {
      display: 'Volume of use',
    },
    '111581': {
      display: 'Relative dose amount',
    },
    '111582': {
      display: 'Relative amount of exposure',
    },
    '111583': {
      display: 'Relative amount of use',
    },
    '111584': {
      display: 'Relative dose frequency',
    },
    '111585': {
      display: 'Relative frequency of exposure',
    },
    '111586': {
      display: 'Relative frequency of use',
    },
    '111587': {
      display: 'No known exposure',
    },
    '111590': {
      display: 'Recall for technical reasons',
    },
    '111591': {
      display: 'Recall for imaging findings',
    },
    '111592': {
      display: 'Recall for patient symptoms/ clinical findings',
    },
    '111593': {
      display: 'LBW or IUGR',
    },
    '111601': {
      display: 'Green filter',
    },
    '111602': {
      display: 'Red filter',
    },
    '111603': {
      display: 'Blue filter',
    },
    '111604': {
      display: 'Yellow-green filter',
    },
    '111605': {
      display: 'Blue-green filter',
    },
    '111606': {
      display: 'Infrared filter',
    },
    '111607': {
      display: 'Polarizing filter',
    },
    '111609': {
      display: 'No filter',
    },
    '111621': {
      display: 'Field 1 for Joslin 3 field',
    },
    '111622': {
      display: 'Field 2 for Joslin 3 field',
    },
    '111623': {
      display: 'Field 3 for Joslin 3 field',
    },
    '111625': {
      display: 'Diffuse direct illumination',
    },
    '111626': {
      display: 'Scheimpflug Camera',
    },
    '111627': {
      display: 'Scotopic light',
    },
    '111628': {
      display: 'Mesopic light',
    },
    '111629': {
      display: 'Photopic light',
    },
    '111630': {
      display: 'Dynamic light',
    },
    '111631': {
      display: 'Average Glandular Dose',
    },
    '111632': {
      display: 'Anode Target Material',
    },
    '111633': {
      display: 'Compression Thickness',
    },
    '111634': {
      display: 'Half Value Layer',
    },
    '111635': {
      display: 'X-Ray Grid',
    },
    '111636': {
      display: 'Entrance Exposure at RP',
    },
    '111637': {
      display: 'Accumulated Average Glandular Dose',
    },
    '111638': {
      display: 'Patient Equivalent Thickness',
    },
    '111641': {
      display: 'Fixed grid',
    },
    '111642': {
      display: 'Focused grid',
    },
    '111643': {
      display: 'Reciprocating grid',
    },
    '111644': {
      display: 'Parallel grid',
    },
    '111645': {
      display: 'Crossed grid',
    },
    '111646': {
      display: 'No grid',
    },
    '111671': {
      display: 'Spectacle Prescription Report',
    },
    '111672': {
      display: 'Add Near',
    },
    '111673': {
      display: 'Add Intermediate',
    },
    '111674': {
      display: 'Add Other',
    },
    '111675': {
      display: 'Horizontal Prism Power',
    },
    '111676': {
      display: 'Horizontal Prism Base',
    },
    '111677': {
      display: 'Vertical Prism Power',
    },
    '111678': {
      display: 'Vertical Prism Base',
    },
    '111679': {
      display: 'Distance Pupillary Distance',
    },
    '111680': {
      display: 'Near Pupillary Distance',
    },
    '111685': {
      display: 'Autorefraction Visual Acuity',
    },
    '111686': {
      display: 'Habitual Visual Acuity',
    },
    '111687': {
      display: 'Prescription Visual Acuity',
    },
    '111688': {
      display: 'Right Eye Rx',
    },
    '111689': {
      display: 'Left Eye Rx',
    },
    '111690': {
      display: 'Macular Grid Thickness and Volume Report',
    },
    '111691': {
      display: 'Number of Images Used for Macular Measurements',
    },
    '111692': {
      display: 'Number of Samples Used per Image',
    },
    '111693': {
      display: 'Analysis Quality Rating',
    },
    '111694': {
      display: 'Image Set Quality Rating',
    },
    '111695': {
      display: 'Interfering Tears or Drops',
    },
    '111696': {
      display: 'Visual Fixation Quality During Acquisition',
    },
    '111697': {
      display: 'Visual Fixation Quality Problem',
    },
    '111698': {
      display: 'Ophthalmic Macular Grid Problem',
    },
    '111700': {
      display: 'Specimen Container Identifier',
    },
    '111701': {
      display: 'Processing type',
    },
    '111702': {
      display: 'DateTime of processing',
    },
    '111703': {
      display: 'Processing step description',
    },
    '111704': {
      display: 'Sampling Method',
    },
    '111705': {
      display: 'Parent Specimen Identifier',
    },
    '111706': {
      display: 'Issuer of Parent Specimen Identifier',
    },
    '111707': {
      display: 'Parent specimen type',
    },
    '111708': {
      display: 'Position Frame of Reference',
    },
    '111709': {
      display: 'Location of sampling site',
    },
    '111710': {
      display: 'Location of sampling site X offset',
    },
    '111711': {
      display: 'Location of sampling site Y offset',
    },
    '111712': {
      display: 'Location of sampling site Z offset',
    },
    '111718': {
      display: 'Location of Specimen',
    },
    '111719': {
      display: 'Location of Specimen X offset',
    },
    '111720': {
      display: 'Location of Specimen Y offset',
    },
    '111721': {
      display: 'Location of Specimen Z offset',
    },
    '111723': {
      display: 'Visual Marking of Specimen',
    },
    '111724': {
      display: 'Issuer of Specimen Identifier',
    },
    '111726': {
      display: 'Dissection with entire specimen submission',
    },
    '111727': {
      display: 'Dissection with representative sections submission',
    },
    '111729': {
      display: 'Specimen storage',
    },
    '111741': {
      display: 'Transmission illumination',
    },
    '111742': {
      display: 'Reflection illumination',
    },
    '111743': {
      display: 'Epifluorescence illumination',
    },
    '111744': {
      display: 'Brightfield illumination',
    },
    '111745': {
      display: 'Darkfield illumination',
    },
    '111746': {
      display: 'Oblique illumination',
    },
    '111747': {
      display: 'Phase contrast illumination',
    },
    '111748': {
      display: 'Differential interference contrast',
    },
    '111749': {
      display: 'Total internal reflection fluorescence',
    },
    '111750': {
      display: 'Ultrasound Contact',
    },
    '111751': {
      display: 'Ultrasound Immersion',
    },
    '111752': {
      display: 'Optical',
    },
    '111753': {
      display: 'Manual Keratometry',
    },
    '111754': {
      display: 'Auto Keratometry',
    },
    '111755': {
      display: 'Simulated Keratometry',
    },
    '111756': {
      display: 'Equivalent K-reading',
    },
    '111760': {
      display: 'Haigis',
    },
    '111761': {
      display: 'Haigis-L',
    },
    '111762': {
      display: 'Holladay 1',
    },
    '111763': {
      display: 'Holladay 2',
    },
    '111764': {
      display: 'Hoffer Q',
    },
    '111765': {
      display: 'Olsen',
    },
    '111766': {
      display: 'SRKII',
    },
    '111767': {
      display: 'SRK-T',
    },
    '111768': {
      display: 'ACD Constant',
    },
    '111769': {
      display: 'Haigis a0',
    },
    '111770': {
      display: 'Haigis a1',
    },
    '111771': {
      display: 'Haigis a2',
    },
    '111772': {
      display: 'Hoffer pACD Constant',
    },
    '111773': {
      display: 'Surgeon Factor',
    },
    '111776': {
      display: 'Front Of Cornea To Front Of Lens',
    },
    '111777': {
      display: 'Back Of Cornea To Front Of Lens',
    },
    '111778': {
      display: 'Single or Anterior Lens',
    },
    '111779': {
      display: 'Posterior Lens',
    },
    '111780': {
      display: 'Measurement From This Device',
    },
    '111781': {
      display: 'External Data Source',
    },
    '111782': {
      display: 'Axial Measurements SOP Instance',
    },
    '111783': {
      display: 'Refractive Measurements SOP Instance',
    },
    '111786': {
      display: 'Standard Deviation of measurements used',
    },
    '111787': {
      display: 'Signal to Noise Ratio',
    },
    '111791': {
      display: 'Spherical projection',
    },
    '111792': {
      display: 'Surface contour mapping',
    },
    '111800': {
      display: 'Visual Field 24-2 Test Pattern',
    },
    '111801': {
      display: 'Visual Field 10-2 Test Pattern',
    },
    '111802': {
      display: 'Visual Field 30-2 Test Pattern',
    },
    '111803': {
      display: 'Visual Field 60-4 Test Pattern',
    },
    '111804': {
      display: 'Visual Field Macula Test Pattern',
    },
    '111805': {
      display: 'Visual Field Central 40 Point Test Pattern',
    },
    '111806': {
      display: 'Visual Field Central 76 Point Test Pattern',
    },
    '111807': {
      display: 'Visual Field Peripheral 60 Point Test Pattern',
    },
    '111808': {
      display: 'Visual Field Full Field 81 Point Test Pattern',
    },
    '111809': {
      display: 'Visual Field Full Field 120 Point Test Pattern',
    },
    '111810': {
      display: 'Visual Field G Test Pattern',
    },
    '111811': {
      display: 'Visual Field M Test Pattern',
    },
    '111812': {
      display: 'Visual Field 07 Test Pattern',
    },
    '111813': {
      display: 'Visual Field LVC Test Pattern',
    },
    '111814': {
      display: 'Visual Field Central Test Pattern',
    },
    '111815': {
      display: 'Visual Field SITA-Standard Test Strategy',
    },
    '111816': {
      display: 'Visual Field SITA-SWAP Test Strategy',
    },
    '111817': {
      display: 'Visual Field SITA-Fast Test Strategy',
    },
    '111818': {
      display: 'Visual Field Full Threshold Test Strategy',
    },
    '111819': {
      display: 'Visual Field FastPac Test Strategy',
    },
    '111820': {
      display: 'Visual Field Full From Prior Test Strategy',
    },
    '111821': {
      display: 'Visual Field Optima Test Strategy',
    },
    '111822': {
      display: 'Visual Field Two-Zone Test Strategy',
    },
    '111823': {
      display: 'Visual Field Three-Zone Test Strategy',
    },
    '111824': {
      display: 'Visual Field Quantify-Defects Test Strategy',
    },
    '111825': {
      display: 'Visual Field TOP Test Strategy',
    },
    '111826': {
      display: 'Visual Field Dynamic Test Strategy',
    },
    '111827': {
      display: 'Visual Field Normal Test Strategy',
    },
    '111828': {
      display: 'Visual Field 1-LT Test Strategy',
    },
    '111829': {
      display: 'Visual Field 2-LT Test Strategy',
    },
    '111830': {
      display: 'Visual Field LVS Test Strategy',
    },
    '111831': {
      display: 'Visual Field GATE Test Strategy',
    },
    '111832': {
      display: 'Visual Field GATEi Test Strategy',
    },
    '111833': {
      display: 'Visual Field 2LT-Dynamic Test Strategy',
    },
    '111834': {
      display: 'Visual Field 2LT-Normal Test Strategy',
    },
    '111835': {
      display: 'Visual Field Fast Threshold Test Strategy',
    },
    '111836': {
      display: 'Visual Field CLIP Test Strategy',
    },
    '111837': {
      display: 'Visual Field CLASS Strategy',
    },
    '111838': {
      display: 'Age corrected',
    },
    '111839': {
      display: 'Threshold related',
    },
    '111840': {
      display: 'Single luminance',
    },
    '111841': {
      display: 'Foveal sensitivity related',
    },
    '111842': {
      display: 'Related to non macular sensitivity',
    },
    '111843': {
      display: 'Automated Optical',
    },
    '111844': {
      display: 'Blind Spot Monitoring',
    },
    '111845': {
      display: 'Macular Fixation Testing',
    },
    '111846': {
      display: 'Observation by Examiner',
    },
    '111847': {
      display: 'Outside normal limits',
    },
    '111848': {
      display: 'Borderline',
    },
    '111849': {
      display: 'Abnormally high sensitivity',
    },
    '111850': {
      display: 'General reduction in sensitivity',
    },
    '111851': {
      display: 'Borderline and general reduction in sensitivity',
    },
    '111852': {
      display: 'Visual Field Index',
    },
    '111853': {
      display: 'Visual Field Loss Due to Diffuse Defect',
    },
    '111854': {
      display: 'Visual Field Loss Due to Local Defect',
    },
    '111855': {
      display: 'Glaucoma Hemifield Test Analysis',
    },
    '111856': {
      display: 'Optical Fixation Measurements',
    },
    '111900': {
      display: 'Macula centered',
    },
    '111901': {
      display: 'Disc centered',
    },
    '111902': {
      display: 'Lesion centered',
    },
    '111903': {
      display: 'Disc-macula centered',
    },
    '111904': {
      display: 'Mid-peripheral-superior',
    },
    '111905': {
      display: 'Mid-peripheral-superior temporal',
    },
    '111906': {
      display: 'Mid-peripheral-temporal',
    },
    '111907': {
      display: 'Mid-peripheral-inferior temporal',
    },
    '111908': {
      display: 'Mid-peripheral-inferior',
    },
    '111909': {
      display: 'Mid-peripheral-inferior nasal',
    },
    '111910': {
      display: 'Mid-peripheral-nasal',
    },
    '111911': {
      display: 'Mid-peripheral-superior nasal',
    },
    '111912': {
      display: 'Peripheral-superior',
    },
    '111913': {
      display: 'Peripheral-superior temporal',
    },
    '111914': {
      display: 'Peripheral-temporal',
    },
    '111915': {
      display: 'Peripheral-inferior temporal',
    },
    '111916': {
      display: 'Peripheral-inferior',
    },
    '111917': {
      display: 'Peripheral-inferior nasal',
    },
    '111918': {
      display: 'Peripheral-nasal',
    },
    '111919': {
      display: 'Peripheral-superior nasal',
    },
    '111920': {
      display: 'Time domain',
    },
    '111921': {
      display: 'Spectral domain',
    },
    '111922': {
      display: 'No corneal compensation',
    },
    '111923': {
      display: 'Corneal birefringence compensation',
    },
    '111924': {
      display: 'Retinal topography',
    },
    '111925': {
      display: 'Retinal nerve fiber layer thickness',
    },
    '111926': {
      display: 'Ganglion cell complex thickness',
    },
    '111927': {
      display: 'Total retinal thickness (ILM to IS-OS)',
    },
    '111928': {
      display: 'Total retinal thickness (ILM to RPE)',
    },
    '111929': {
      display: 'Total retinal thickness (ILM to BM)',
    },
    '111930': {
      display: 'Absolute ophthalmic thickness',
    },
    '111931': {
      display: 'Thickness deviation category from normative data',
    },
    '111932': {
      display: 'Thickness deviation from normative data',
    },
    '111933': {
      display: 'Related ophthalmic thickness map',
    },
    '111934': {
      display: 'Disc-Fovea',
    },
    '111935': {
      display: 'p>5%',
    },
    '111936': {
      display: 'p<5%',
    },
    '111937': {
      display: 'p<2%',
    },
    '111938': {
      display: 'p<1%',
    },
    '111939': {
      display: 'p<0.5%',
    },
    '111940': {
      display: 'Corneal axial power map',
    },
    '111941': {
      display: 'Corneal instantaneous power map',
    },
    '111942': {
      display: 'Corneal refractive power map',
    },
    '111943': {
      display: 'Corneal elevation map',
    },
    '111944': {
      display: 'Corneal wavefront map',
    },
    '111945': {
      display: 'Elevation-based corneal tomographer',
    },
    '111946': {
      display: 'Reflection-based corneal topographer',
    },
    '111947': {
      display: 'Interferometry-based corneal tomographer',
    },
    '112000': {
      display: 'Chest CAD Report',
    },
    '112001': {
      display: 'Opacity',
    },
    '112002': {
      display: 'Series Instance UID',
    },
    '112003': {
      display: 'Associated Chest Component',
    },
    '112004': {
      display: 'Abnormal interstitial pattern',
    },
    '112005': {
      display: 'Radiographic anatomy',
    },
    '112006': {
      display: 'Distribution Descriptor',
    },
    '112007': {
      display: 'Border definition',
    },
    '112008': {
      display: 'Site involvement',
    },
    '112009': {
      display: 'Type of Content',
    },
    '112010': {
      display: 'Texture Descriptor',
    },
    '112011': {
      display: 'Positioner Primary Angle',
    },
    '112012': {
      display: 'Positioner Secondary Angle',
    },
    '112013': {
      display: 'Location in Chest',
    },
    '112014': {
      display: 'Orientation Descriptor',
    },
    '112015': {
      display: 'Border shape',
    },
    '112016': {
      display: 'Baseline Category',
    },
    '112017': {
      display: 'Cavity extent as percent of volume',
    },
    '112018': {
      display: 'Calcification extent as percent of surface',
    },
    '112019': {
      display: 'Calcification extent as percent of volume',
    },
    '112020': {
      display: 'Response Evaluation',
    },
    '112021': {
      display: 'Response Evaluation Method',
    },
    '112022': {
      display: 'RECIST',
    },
    '112023': {
      display: 'Composite Feature Modifier',
    },
    '112024': {
      display: 'Single Image Finding Modifier',
    },
    '112025': {
      display: 'Size Descriptor',
    },
    '112026': {
      display: 'Width Descriptor',
    },
    '112027': {
      display: 'Opacity Descriptor',
    },
    '112028': {
      display: 'Abnormal Distribution of Anatomic Structure',
    },
    '112029': {
      display: 'WHO',
    },
    '112030': {
      display: 'Calcification Descriptor',
    },
    '112031': {
      display: 'Attenuation Coefficient',
    },
    '112032': {
      display: 'Threshold Attenuation Coefficient',
    },
    '112033': {
      display: 'Abnormal opacity',
    },
    '112034': {
      display: 'Calculation Description',
    },
    '112035': {
      display: 'Performance of Pediatric and Adult Chest Radiography, ACR',
    },
    '112036': {
      display: 'ACR Position Statement',
    },
    '112037': {
      display: 'Non-lesion Modifier',
    },
    '112038': {
      display: 'Osseous Modifier',
    },
    '112039': {
      display: 'Tracking Identifier',
    },
    '112040': {
      display: 'Tracking Unique Identifier',
    },
    '112041': {
      display: 'Target Lesion Complete Response',
    },
    '112042': {
      display: 'Target Lesion Partial Response',
    },
    '112043': {
      display: 'Target Lesion Progressive Disease',
    },
    '112044': {
      display: 'Target Lesion Stable Disease',
    },
    '112045': {
      display: 'Non-Target Lesion Complete Response',
    },
    '112046': {
      display: 'Non-Target Lesion Incomplete Response or Stable Disease',
    },
    '112047': {
      display: 'Non-Target Lesion Progressive Disease',
    },
    '112048': {
      display: 'Current Response',
    },
    '112049': {
      display: 'Best Overall Response',
    },
    '112050': {
      display: 'Anatomic Identifier',
    },
    '112051': {
      display: 'Measurement of Response',
    },
    '112052': {
      display: 'Bronchovascular',
    },
    '112053': {
      display: 'Osseous',
    },
    '112054': {
      display: 'Secondary pulmonary lobule',
    },
    '112055': {
      display: 'Agatston scoring method',
    },
    '112056': {
      display: 'Volume scoring method',
    },
    '112057': {
      display: 'Mass scoring method',
    },
    '112058': {
      display: 'Calcium score',
    },
    '112059': {
      display: 'Primary complex',
    },
    '112060': {
      display: 'Oligemia',
    },
    '112061': {
      display: 'Abnormal lines (1D)',
    },
    '112062': {
      display: 'Abnormal lucency',
    },
    '112063': {
      display: 'Abnormal calcifications',
    },
    '112064': {
      display: 'Abnormal texture',
    },
    '112065': {
      display: 'Reticulonodular pattern',
    },
    '112066': {
      display: 'Beaded septum sign',
    },
    '112067': {
      display: 'Nodular pattern',
    },
    '112068': {
      display: 'Pseudoplaque',
    },
    '112069': {
      display: 'Signet-ring sign',
    },
    '112070': {
      display: 'Air bronchiologram',
    },
    '112071': {
      display: 'Air bronchogram',
    },
    '112072': {
      display: 'Air crescent',
    },
    '112073': {
      display: 'Halo sign',
    },
    '112074': {
      display: 'Target Lesion at Baseline',
    },
    '112075': {
      display: 'Non-Target Lesion at Baseline',
    },
    '112076': {
      display: 'Non-Lesion at Baseline',
    },
    '112077': {
      display: 'Vasoconstriction',
    },
    '112078': {
      display: 'Vasodilation',
    },
    '112079': {
      display: 'Architectural distortion',
    },
    '112080': {
      display: 'Mosaic perfusion',
    },
    '112081': {
      display: 'Pleonemia',
    },
    '112082': {
      display: 'Interface',
    },
    '112083': {
      display: 'Line',
    },
    '112084': {
      display: 'Lucency',
    },
    '112085': {
      display: 'Midlung window',
    },
    '112086': {
      display: 'Carina angle',
    },
    '112087': {
      display: 'Centrilobular structures',
    },
    '112088': {
      display: 'Anterior junction line',
    },
    '112089': {
      display: 'Posterior junction line',
    },
    '112090': {
      display: 'Azygoesophageal recess interface',
    },
    '112091': {
      display: 'Paraspinal line',
    },
    '112092': {
      display: 'Posterior tracheal stripe',
    },
    '112093': {
      display: 'Right tracheal stripe',
    },
    '112094': {
      display: 'Stripe',
    },
    '112095': {
      display: 'Hiatus',
    },
    '112096': {
      display: 'Rib Scalene Tubercle',
    },
    '112097': {
      display: 'Vertebral Intervertebral Notch',
    },
    '112098': {
      display: 'Subscapular Fossa',
    },
    '112099': {
      display: 'Scapular Spine',
    },
    '112100': {
      display: 'Scapular Supraspinatus Fossa',
    },
    '112101': {
      display: 'Scapular Infraspinatus Fossa',
    },
    '112102': {
      display: 'Aortic knob',
    },
    '112103': {
      display: 'Arch of the Azygos vein',
    },
    '112104': {
      display: 'Air-fluid level',
    },
    '112105': {
      display: 'Corona radiata',
    },
    '112106': {
      display: 'Honeycomb pattern',
    },
    '112107': {
      display: "Fleischner's line(s)",
    },
    '112108': {
      display: 'Intralobular lines',
    },
    '112109': {
      display: 'Kerley A line',
    },
    '112110': {
      display: 'Kerley B line',
    },
    '112111': {
      display: 'Kerley C lines',
    },
    '112112': {
      display: 'Parenchymal band',
    },
    '112113': {
      display: 'Reticular pattern',
    },
    '112114': {
      display: 'Septal line(s)',
    },
    '112115': {
      display: 'Subpleural line',
    },
    '112116': {
      display: 'Tramline shadow',
    },
    '112117': {
      display: 'Tubular shadow',
    },
    '112118': {
      display: 'Density',
    },
    '112119': {
      display: 'Dependent opacity',
    },
    '112120': {
      display: 'Ground glass opacity',
    },
    '112121': {
      display: 'Infiltrate',
    },
    '112122': {
      display: 'Micronodule',
    },
    '112123': {
      display: 'Phantom tumor (pseudotumor)',
    },
    '112124': {
      display: 'Shadow',
    },
    '112125': {
      display: 'Small irregular opacities',
    },
    '112126': {
      display: 'Small rounded opacities',
    },
    '112127': {
      display: 'Tree-in-bud sign',
    },
    '112128': {
      display: 'Granular pattern',
    },
    '112129': {
      display: 'Miliary pattern',
    },
    '112130': {
      display: 'Mosaic pattern',
    },
    '112131': {
      display: 'Extremely small',
    },
    '112132': {
      display: 'Very small',
    },
    '112133': {
      display: 'Too small',
    },
    '112134': {
      display: 'Elliptic',
    },
    '112135': {
      display: 'Lobulated',
    },
    '112136': {
      display: 'Spiculated',
    },
    '112137': {
      display: 'Sharply defined',
    },
    '112138': {
      display: 'Distinctly defined',
    },
    '112139': {
      display: 'Well demarcated',
    },
    '112140': {
      display: 'Sharply demarcated',
    },
    '112141': {
      display: 'Poorly demarcated',
    },
    '112142': {
      display: 'Circumscribed',
    },
    '112143': {
      display: 'Air',
    },
    '112144': {
      display: 'Soft tissue',
    },
    '112145': {
      display: 'Calcium',
    },
    '112146': {
      display: 'Acinar',
    },
    '112147': {
      display: 'Air space',
    },
    '112148': {
      display: 'Fibronodular',
    },
    '112149': {
      display: 'Fluffy',
    },
    '112150': {
      display: 'Linear',
    },
    '112151': {
      display: 'Profusion',
    },
    '112152': {
      display: 'Silhouette sign',
    },
    '112153': {
      display: 'Subpleural',
    },
    '112154': {
      display: "Bat's wing distribution",
    },
    '112155': {
      display: 'Butterfly distribution',
    },
    '112156': {
      display: 'Centrilobular',
    },
    '112157': {
      display: 'Coalescent',
    },
    '112158': {
      display: 'Lobar',
    },
    '112159': {
      display: 'Hyper-acute',
    },
    '112160': {
      display: 'Homogeneous (uniform opacity)',
    },
    '112161': {
      display: 'Inhomogeneous',
    },
    '112162': {
      display: 'Target',
    },
    '112163': {
      display: 'Fibrocalcific',
    },
    '112164': {
      display: 'Flocculent',
    },
    '112165': {
      display: 'Difference in border shape',
    },
    '112166': {
      display: 'Difference in border definition',
    },
    '112167': {
      display: 'Difference in distribution',
    },
    '112168': {
      display: 'Difference in site involvement',
    },
    '112169': {
      display: 'Difference in Type of Content',
    },
    '112170': {
      display: 'Difference in Texture',
    },
    '112171': {
      display: 'Fiducial mark',
    },
    '112172': {
      display: 'Portacath',
    },
    '112173': {
      display: 'Chest tube',
    },
    '112174': {
      display: 'Central line',
    },
    '112175': {
      display: 'Kidney stent',
    },
    '112176': {
      display: 'Pancreatic stent',
    },
    '112177': {
      display: 'Nipple ring',
    },
    '112178': {
      display: 'Coin',
    },
    '112179': {
      display: 'Minimum Attenuation Coefficient',
    },
    '112180': {
      display: 'Maximum Attenuation Coefficient',
    },
    '112181': {
      display: 'Mean Attenuation Coefficient',
    },
    '112182': {
      display: 'Median Attenuation Coefficient',
    },
    '112183': {
      display: 'Standard Deviation of Attenuation Coefficient',
    },
    '112184': {
      display: 'Performance of Pediatric and Adult Thoracic CT',
    },
    '112185': {
      display: 'Performance of CT for Detection of Pulmonary Embolism in Adults',
    },
    '112186': {
      display: 'Performance of High-Resolution CT of the Lungs in Adults',
    },
    '112187': {
      display: 'Unspecified method of calculation',
    },
    '112188': {
      display: 'Two-dimensional method',
    },
    '112189': {
      display: 'Three-dimensional method',
    },
    '112191': {
      display: 'Breast tissue density',
    },
    '112192': {
      display: 'Volume of parenchymal tissue',
    },
    '112193': {
      display: 'Volume of breast',
    },
    '112194': {
      display: 'Mass of parenchymal tissue',
    },
    '112195': {
      display: 'Mass of breast',
    },
    '112196': {
      display: 'Area of Vascular Calcification',
    },
    '112197': {
      display: 'Volume of Vascular Calcification',
    },
    '112198': {
      display: 'Percentage of Vascular Calcification',
    },
    '112199': {
      display: 'Mass of Vascular Calcification',
    },
    '112200': {
      display: 'Average calcification distance in a calcification cluster',
    },
    '112201': {
      display: 'Standard deviation distance of calcifications in a cluster',
    },
    '112220': {
      display: 'Colon CAD Report',
    },
    '112222': {
      display: 'Colon Overall Assessment',
    },
    '112224': {
      display: 'Image Set Properties',
    },
    '112225': {
      display: 'Slice Thickness',
    },
    '112226': {
      display: 'Spacing between slices',
    },
    '112227': {
      display: 'Frame of Reference UID',
    },
    '112228': {
      display: 'Recumbent Patient Position with respect to gravity',
    },
    '112229': {
      display: 'Identifying Segment',
    },
    '112232': {
      display: 'Polyp stalk width',
    },
    '112233': {
      display: 'Distance from anus',
    },
    '112238': {
      display: 'Anatomic non-colon',
    },
    '112240': {
      display: 'C0 - Inadequate Study/Awaiting Prior Comparisons',
    },
    '112241': {
      display: 'C1 - Normal Colon or Benign Lesion',
    },
    '112242': {
      display: 'C2 - Intermediate Polyp or Indeterminate Finding',
    },
    '112243': {
      display: 'C3 - Polyp, Possibly Advanced Adenoma',
    },
    '112244': {
      display: 'C4 - Colonic Mass, Likely Malignant',
    },
    '112248': {
      display: 'ACR Guideline, Performance of Adult CT Colonography',
    },
    '112249': {
      display: 'ACR Standard, CT medical physics performance monitoring',
    },
    '112300': {
      display: 'AP+45',
    },
    '112301': {
      display: 'AP-45',
    },
    '112302': {
      display: 'Anatomical axis of femur',
    },
    '112303': {
      display: 'Acetabular Center of Rotation',
    },
    '112304': {
      display: 'Femur Head Center of Rotation',
    },
    '112305': {
      display: 'Acetabular Cup Shell',
    },
    '112306': {
      display: 'Acetabular Cup Insert',
    },
    '112307': {
      display: 'Acetabular Cup Monoblock',
    },
    '112308': {
      display: 'Femoral Head Ball Component',
    },
    '112309': {
      display: 'Femoral Head Cone Taper Component',
    },
    '112310': {
      display: 'Femoral Stem',
    },
    '112311': {
      display: 'Femoral Stem Distal Component',
    },
    '112312': {
      display: 'Femoral Stem Proximal Component',
    },
    '112313': {
      display: 'Femoral Stem Component',
    },
    '112314': {
      display: 'Neck Component',
    },
    '112315': {
      display: 'Monoblock Stem',
    },
    '112316': {
      display: 'Prosthetic Shaft Augment',
    },
    '112317': {
      display: 'Femoral Head Resurfacing Component',
    },
    '112318': {
      display: 'Pinning',
    },
    '112319': {
      display: 'Sewing',
    },
    '112320': {
      display: 'Bolting',
    },
    '112321': {
      display: 'Wedging',
    },
    '112325': {
      display: 'Distal Centralizer',
    },
    '112340': {
      display: 'Generic 2D Planning',
    },
    '112341': {
      display: 'Generic 3D Planning',
    },
    '112342': {
      display: 'Generic Planning for Hip Replacement',
    },
    '112343': {
      display: 'Generic Planning for Knee Replacement',
    },
    '112344': {
      display: 'Müller Method Planning for Hip Replacement',
    },
    '112345': {
      display: 'Implantation Plan',
    },
    '112346': {
      display: 'Selected Implant Component',
    },
    '112347': {
      display: 'Component ID',
    },
    '112348': {
      display: 'Implant Template',
    },
    '112350': {
      display: 'Component Connection',
    },
    '112351': {
      display: 'Mating Feature Set ID',
    },
    '112352': {
      display: 'Mating Feature ID',
    },
    '112353': {
      display: 'Spatial Registration',
    },
    '112354': {
      display: 'Patient Image',
    },
    '112355': {
      display: 'Assembly',
    },
    '112356': {
      display: 'User Selected Fiducial',
    },
    '112357': {
      display: 'Derived Fiducial',
    },
    '112358': {
      display: 'Information used for planning',
    },
    '112359': {
      display: 'Supporting Information',
    },
    '112360': {
      display: 'Implant Component List',
    },
    '112361': {
      display: 'Patient Data Used During Planning',
    },
    '112362': {
      display: 'Degrees of Freedom Specification',
    },
    '112363': {
      display: 'Degree of Freedom ID',
    },
    '112364': {
      display: 'Related Patient Data Not Used During Planning',
    },
    '112365': {
      display: 'Related Implantation Reports',
    },
    '112366': {
      display: 'Implant Assembly Template',
    },
    '112367': {
      display: 'Planning Information for Intraoperative Usage',
    },
    '112368': {
      display: 'Implantation Patient Positioning',
    },
    '112369': {
      display: 'Fiducial Intent',
    },
    '112370': {
      display: 'Component Type',
    },
    '112371': {
      display: 'Manufacturer Implant Template',
    },
    '112372': {
      display: 'Derived Planning Images',
    },
    '112373': {
      display: 'Other Derived Planning Data',
    },
    '112374': {
      display: 'Connected Implantation Plan Component',
    },
    '112375': {
      display: 'Planning Method',
    },
    '112376': {
      display: 'Degree of Freedom Exact Translational Value',
    },
    '112377': {
      display: 'Degree of Freedom Minimum Translational Value',
    },
    '112378': {
      display: 'Degree of Freedom Maximum Translational Value',
    },
    '112379': {
      display: 'Degree of Freedom Exact Rotational Translation Value',
    },
    '112380': {
      display: 'Degree of Freedom Minimum Rotational Value',
    },
    '112381': {
      display: 'Degree of Freedom Maximum Rotational Value',
    },
    '112700': {
      display: 'Peri-operative Photographic Imaging',
    },
    '112701': {
      display: 'Gross Specimen Imaging',
    },
    '112702': {
      display: 'Slide Microscopy',
    },
    '112703': {
      display: 'Whole Slide Imaging',
    },
    '112704': {
      display: 'WSI 20X RGB',
    },
    '112705': {
      display: 'WSI 40X RGB',
    },
    '112706': {
      display: 'Illumination Method',
    },
    '112707': {
      display: 'Number of focal planes',
    },
    '112708': {
      display: 'Focal plane Z offset',
    },
    '112709': {
      display: 'Magnification selection',
    },
    '112710': {
      display: 'Illumination wavelength',
    },
    '112711': {
      display: 'Illumination spectral band',
    },
    '112712': {
      display: 'Optical filter type',
    },
    '112713': {
      display: 'Tissue selection method',
    },
    '112714': {
      display: 'Multiple planes',
    },
    '112715': {
      display: '5X',
    },
    '112716': {
      display: '10X',
    },
    '112717': {
      display: '20X',
    },
    '112718': {
      display: '40X',
    },
    '112719': {
      display: 'Nominal empty tile suppression',
    },
    '112720': {
      display: 'High threshold empty tile suppression',
    },
    '112721': {
      display: 'No empty tile suppression',
    },
    '113000': {
      display: 'Of Interest',
    },
    '113001': {
      display: 'Rejected for Quality Reasons',
    },
    '113002': {
      display: 'For Referring Provider',
    },
    '113003': {
      display: 'For Surgery',
    },
    '113004': {
      display: 'For Teaching',
    },
    '113005': {
      display: 'For Conference',
    },
    '113006': {
      display: 'For Therapy',
    },
    '113007': {
      display: 'For Patient',
    },
    '113008': {
      display: 'For Peer Review',
    },
    '113009': {
      display: 'For Research',
    },
    '113010': {
      display: 'Quality Issue',
    },
    '113011': {
      display: 'Document Title Modifier',
    },
    '113012': {
      display: 'Key Object Description',
    },
    '113013': {
      display: 'Best In Set',
    },
    '113014': {
      display: 'Study',
    },
    '113015': {
      display: 'Series',
    },
    '113016': {
      display: 'Performed Procedure Step',
    },
    '113017': {
      display: 'Stage-View',
    },
    '113018': {
      display: 'For Printing',
    },
    '113020': {
      display: 'For Report Attachment',
    },
    '113021': {
      display: 'For Litigation',
    },
    '113026': {
      display: 'Double exposure',
    },
    '113030': {
      display: 'Manifest',
    },
    '113031': {
      display: 'Signed Manifest',
    },
    '113032': {
      display: 'Complete Study Content',
    },
    '113033': {
      display: 'Signed Complete Study Content',
    },
    '113034': {
      display: 'Complete Acquisition Content',
    },
    '113035': {
      display: 'Signed Complete Acquisition Content',
    },
    '113036': {
      display: 'Group of Frames for Display',
    },
    '113037': {
      display: 'Rejected for Patient Safety Reasons',
    },
    '113038': {
      display: 'Incorrect Modality Worklist Entry',
    },
    '113039': {
      display: 'Data Retention Policy Expired',
    },
    '113040': {
      display: 'Lossy Compression',
    },
    '113041': {
      display: 'Apparent Diffusion Coefficient',
    },
    '113042': {
      display: 'Pixel by pixel addition',
    },
    '113043': {
      display: 'Diffusion weighted',
    },
    '113044': {
      display: 'Diffusion Anisotropy',
    },
    '113045': {
      display: 'Diffusion Attenuated',
    },
    '113046': {
      display: 'Pixel by pixel division',
    },
    '113047': {
      display: 'Pixel by pixel mask',
    },
    '113048': {
      display: 'Pixel by pixel Maximum',
    },
    '113049': {
      display: 'Pixel by pixel mean',
    },
    '113050': {
      display: 'Metabolite Maps from spectroscopy data',
    },
    '113051': {
      display: 'Pixel by pixel Minimum',
    },
    '113052': {
      display: 'Mean Transit Time',
    },
    '113053': {
      display: 'Pixel by pixel multiplication',
    },
    '113054': {
      display: 'Negative Enhancement Integral',
    },
    '113055': {
      display: 'Regional Cerebral Blood Flow',
    },
    '113056': {
      display: 'Regional Cerebral Blood Volume',
    },
    '113057': {
      display: 'R-Coefficient',
    },
    '113058': {
      display: 'Proton Density',
    },
    '113059': {
      display: 'Signal Change',
    },
    '113060': {
      display: 'Signal to Noise',
    },
    '113061': {
      display: 'Standard Deviation',
    },
    '113062': {
      display: 'Pixel by pixel subtraction',
    },
    '113063': {
      display: 'T1',
    },
    '113064': {
      display: 'T2*',
    },
    '113065': {
      display: 'T2',
    },
    '113066': {
      display: 'Time Course of Signal',
    },
    '113067': {
      display: 'Temperature encoded',
    },
    '113068': {
      display: "Student's T-Test",
    },
    '113069': {
      display: 'Time To Peak',
    },
    '113070': {
      display: 'Velocity encoded',
    },
    '113071': {
      display: 'Z-Score',
    },
    '113072': {
      display: 'Multiplanar reformatting',
    },
    '113073': {
      display: 'Curved multiplanar reformatting',
    },
    '113074': {
      display: 'Volume rendering',
    },
    '113075': {
      display: 'Surface rendering',
    },
    '113076': {
      display: 'Segmentation',
    },
    '113077': {
      display: 'Volume editing',
    },
    '113078': {
      display: 'Maximum intensity projection',
    },
    '113079': {
      display: 'Minimum intensity projection',
    },
    '113080': {
      display: 'Glutamate and glutamine',
    },
    '113081': {
      display: 'Choline/Creatine Ratio',
    },
    '113082': {
      display: 'N-acetylaspartate /Creatine Ratio',
    },
    '113083': {
      display: 'N-acetylaspartate /Choline Ratio',
    },
    '113085': {
      display: 'Spatial resampling',
    },
    '113086': {
      display: 'Edge enhancement',
    },
    '113087': {
      display: 'Smoothing',
    },
    '113088': {
      display: 'Gaussian blur',
    },
    '113089': {
      display: 'Unsharp mask',
    },
    '113090': {
      display: 'Image stitching',
    },
    '113091': {
      display: 'Spatially-related frames extracted from the volume',
    },
    '113092': {
      display: 'Temporally-related frames extracted from the set of volumes',
    },
    '113093': {
      display: 'Polar to Rectangular Scan Conversion',
    },
    '113094': {
      display: 'Creatine and Choline',
    },
    '113095': {
      display: 'Lipid and Lactate',
    },
    '113096': {
      display: 'Creatine+Choline/ Citrate Ratio',
    },
    '113097': {
      display: 'Multi-energy proportional weighting',
    },
    '113100': {
      display: 'Basic Application Confidentiality Profile',
    },
    '113101': {
      display: 'Clean Pixel Data Option',
    },
    '113102': {
      display: 'Clean Recognizable Visual Features Option',
    },
    '113103': {
      display: 'Clean Graphics Option',
    },
    '113104': {
      display: 'Clean Structured Content Option',
    },
    '113105': {
      display: 'Clean Descriptors Option',
    },
    '113106': {
      display: 'Retain Longitudinal Temporal Information Full Dates Option',
    },
    '113107': {
      display: 'Retain Longitudinal Temporal Information Modified Dates Option',
    },
    '113108': {
      display: 'Retain Patient Characteristics Option',
    },
    '113109': {
      display: 'Retain Device Identity Option',
    },
    '113110': {
      display: 'Retain UIDs Option',
    },
    '113111': {
      display: 'Retain Safe Private Option',
    },
    '113500': {
      display: 'Radiopharmaceutical Radiation Dose Report',
    },
    '113502': {
      display: 'Radiopharmaceutical Administration',
    },
    '113503': {
      display: 'Radiopharmaceutical Administration Event UID',
    },
    '113505': {
      display: 'Intravenous Extravasation Symptoms',
    },
    '113506': {
      display: 'Estimated Extravasation Activity',
    },
    '113507': {
      display: 'Administered activity',
    },
    '113508': {
      display: 'Pre-Administration Measured Activity',
    },
    '113509': {
      display: 'Post-Administration Measured Activity',
    },
    '113510': {
      display: 'Drug Product Identifier',
    },
    '113511': {
      display: 'Radiopharmaceutical Dispense Unit Identifier',
    },
    '113512': {
      display: 'Radiopharmaceutical Lot Identifier',
    },
    '113513': {
      display: 'Reagent Vial Identifier',
    },
    '113514': {
      display: 'Radionuclide Vial Identifier',
    },
    '113516': {
      display: 'Prescription Identifier',
    },
    '113517': {
      display: 'Organ Dose Information',
    },
    '113518': {
      display: 'Organ Dose',
    },
    '113520': {
      display: 'MIRD Pamphlet 1',
    },
    '113521': {
      display: 'ICRP Publication 53',
    },
    '113522': {
      display: 'ICRP Publication 80',
    },
    '113523': {
      display: 'ICRP Publication 106',
    },
    '113526': {
      display: 'MIRDOSE',
    },
    '113527': {
      display: 'OLINDA-EXM',
    },
    '113528': {
      display: 'Package Insert',
    },
    '113529': {
      display: 'Institutionally Approved Estimates',
    },
    '113530': {
      display: 'Investigational New Drug',
    },
    '113540': {
      display: 'Activity Measurement Device',
    },
    '113541': {
      display: 'Dose Calibrator',
    },
    '113542': {
      display: 'Infusion System',
    },
    '113543': {
      display: 'Generator',
    },
    '113550': {
      display: 'Fasting Duration',
    },
    '113551': {
      display: 'Hydration Volume',
    },
    '113552': {
      display: 'Recent Physical Activity',
    },
    '113560': {
      display: 'Acute unilateral renal blockage',
    },
    '113561': {
      display: 'Low Thyroid Uptake',
    },
    '113562': {
      display: 'High Thyroid Uptake',
    },
    '113563': {
      display: 'Severely Jaundiced',
    },
    '113568': {
      display: 'Extravasation visible in image',
    },
    '113570': {
      display: 'Cockroft-Gault Formula estimation of GFR',
    },
    '113571': {
      display: 'CKD-EPI Formula estimation of GFR',
    },
    '113572': {
      display: 'Glomerular Filtration Rate (MDRD)',
    },
    '113573': {
      display: 'Glomerular Filtration Rate non-black (MDRD)',
    },
    '113574': {
      display: 'Glomerular Filtration Rate black (MDRD)',
    },
    '113575': {
      display: 'Glomerular Filtration Rate female (MDRD)',
    },
    '113576': {
      display: 'Glomerular Filtration Rate Cystatin-based formula',
    },
    '113577': {
      display: 'Glomerular Filtration Rate Creatinine-based formula (Schwartz)',
    },
    '113601': {
      display: 'Small: < 32.0 cm lateral thickness',
    },
    '113602': {
      display: 'Medium: 32.0-38.0 cm lateral thickness',
    },
    '113603': {
      display: 'Large: > 38.0 cm lateral thickness',
    },
    '113605': {
      display: 'Irradiation Event Label',
    },
    '113606': {
      display: 'Label Type',
    },
    '113607': {
      display: 'Series Number',
    },
    '113608': {
      display: 'Acquisition Number',
    },
    '113609': {
      display: 'Instance Number',
    },
    '113611': {
      display: 'Stationary Acquisition',
    },
    '113612': {
      display: 'Stepping Acquisition',
    },
    '113613': {
      display: 'Rotational Acquisition',
    },
    '113620': {
      display: 'Plane A',
    },
    '113621': {
      display: 'Plane B',
    },
    '113622': {
      display: 'Single Plane',
    },
    '113630': {
      display: 'Continuous',
    },
    '113631': {
      display: 'Pulsed',
    },
    '113650': {
      display: 'Strip filter',
    },
    '113651': {
      display: 'Wedge filter',
    },
    '113652': {
      display: 'Butterfly filter',
    },
    '113653': {
      display: 'Flat filter',
    },
    '113661': {
      display: 'Outline of lobulations',
    },
    '113662': {
      display: 'Inner limits of fuzzy margin',
    },
    '113663': {
      display: 'Outer limits of fuzzy margin',
    },
    '113664': {
      display: 'Outline of spiculations',
    },
    '113665': {
      display: 'Linear spiculation',
    },
    '113666': {
      display: 'Pixelated spiculations',
    },
    '113669': {
      display: 'Orthogonal location arc',
    },
    '113670': {
      display: 'Orthogonal location arc inner margin',
    },
    '113671': {
      display: 'Orthogonal location arc outer margin',
    },
    '113680': {
      display: 'Quality Control Intent',
    },
    '113681': {
      display: 'Phantom',
    },
    '113682': {
      display: 'ACR Accreditation Phantom - CT',
    },
    '113683': {
      display: 'ACR Accreditation Phantom - MR',
    },
    '113684': {
      display: 'ACR Accreditation Phantom - Mammography',
    },
    '113685': {
      display: 'ACR Accreditation Phantom - Stereotactic Breast Biopsy',
    },
    '113686': {
      display: 'ACR Accreditation Phantom - ECT',
    },
    '113687': {
      display: 'ACR Accreditation Phantom - PET',
    },
    '113688': {
      display: 'ACR Accreditation Phantom - ECT/PET',
    },
    '113689': {
      display: 'ACR Accreditation Phantom - PET Faceplate',
    },
    '113690': {
      display: 'IEC Head Dosimetry Phantom',
    },
    '113691': {
      display: 'IEC Body Dosimetry Phantom',
    },
    '113692': {
      display: 'NEMA XR21-2000 Phantom',
    },
    '113701': {
      display: 'X-Ray Radiation Dose Report',
    },
    '113702': {
      display: 'Accumulated X-Ray Dose Data',
    },
    '113704': {
      display: 'Projection X-Ray',
    },
    '113705': {
      display: 'Scope of Accumulation',
    },
    '113706': {
      display: 'Irradiation Event X-Ray Data',
    },
    '113710': {
      display: 'Niobium or Niobium compound',
    },
    '113711': {
      display: 'Europium or Europium compound',
    },
    '113720': {
      display: 'Calibration Protocol',
    },
    '113721': {
      display: 'Irradiation Event Type',
    },
    '113722': {
      display: 'Dose Area Product Total',
    },
    '113723': {
      display: 'Calibration Date',
    },
    '113724': {
      display: 'Calibration Responsible Party',
    },
    '113725': {
      display: 'Dose (RP) Total',
    },
    '113726': {
      display: 'Fluoro Dose Area Product Total',
    },
    '113727': {
      display: 'Acquisition Dose Area Product Total',
    },
    '113728': {
      display: 'Fluoro Dose (RP) Total',
    },
    '113729': {
      display: 'Acquisition Dose (RP) Total',
    },
    '113730': {
      display: 'Total Fluoro Time',
    },
    '113731': {
      display: 'Total Number of Radiographic Frames',
    },
    '113732': {
      display: 'Fluoro Mode',
    },
    '113733': {
      display: 'KVP',
    },
    '113734': {
      display: 'X-Ray Tube Current',
    },
    '113735': {
      display: 'Exposure Time',
    },
    '113736': {
      display: 'Exposure',
    },
    '113737': {
      display: 'Distance Source to Reference Point',
    },
    '113738': {
      display: 'Dose (RP)',
    },
    '113739': {
      display: 'Positioner Primary End Angle',
    },
    '113740': {
      display: 'Positioner Secondary End Angle',
    },
    '113742': {
      display: 'Irradiation Duration',
    },
    '113743': {
      display: 'Patient Orientation',
    },
    '113744': {
      display: 'Patient Orientation Modifier',
    },
    '113745': {
      display: 'Patient Table Relationship',
    },
    '113748': {
      display: 'Distance Source to Isocenter',
    },
    '113750': {
      display: 'Distance Source to Detector',
    },
    '113751': {
      display: 'Table Longitudinal Position',
    },
    '113752': {
      display: 'Table Lateral Position',
    },
    '113753': {
      display: 'Table Height Position',
    },
    '113754': {
      display: 'Table Head Tilt Angle',
    },
    '113755': {
      display: 'Table Horizontal Rotation Angle',
    },
    '113756': {
      display: 'Table Cradle Tilt Angle',
    },
    '113757': {
      display: 'X-Ray Filter Material',
    },
    '113758': {
      display: 'X-Ray Filter Thickness Minimum',
    },
    '113759': {
      display: 'Table Longitudinal End Position',
    },
    '113760': {
      display: 'Table Lateral End Position',
    },
    '113761': {
      display: 'Table Height End Position',
    },
    '113763': {
      display: 'Calibration Uncertainty',
    },
    '113764': {
      display: 'Acquisition Plane',
    },
    '113766': {
      display: 'Focal Spot Size',
    },
    '113767': {
      display: 'Average X-Ray Tube Current',
    },
    '113768': {
      display: 'Number of Pulses',
    },
    '113769': {
      display: 'Irradiation Event UID',
    },
    '113770': {
      display: 'Column Angulation',
    },
    '113771': {
      display: 'X-Ray Filters',
    },
    '113772': {
      display: 'X-Ray Filter Type',
    },
    '113773': {
      display: 'X-Ray Filter Thickness Maximum',
    },
    '113780': {
      display: 'Reference Point Definition',
    },
    '113788': {
      display: 'Collimated Field Height',
    },
    '113789': {
      display: 'Collimated Field Width',
    },
    '113790': {
      display: 'Collimated Field Area',
    },
    '113791': {
      display: 'Pulse Rate',
    },
    '113792': {
      display: 'Distance Source to Table Plane',
    },
    '113793': {
      display: 'Pulse Width',
    },
    '113794': {
      display: 'Dose Measurement Device',
    },
    '113795': {
      display: 'Acquired Image',
    },
    '113800': {
      display: 'DLP to E conversion via MC computation',
    },
    '113801': {
      display: 'CTDIfreeair to E conversion via MC computation',
    },
    '113802': {
      display: 'DLP to E conversion via measurement',
    },
    '113803': {
      display: 'CTDIfreeair to E conversion via measurement',
    },
    '113804': {
      display: 'Sequenced Acquisition',
    },
    '113805': {
      display: 'Constant Angle Acquisition',
    },
    '113806': {
      display: 'Stationary Acquisition',
    },
    '113807': {
      display: 'Free Acquisition',
    },
    '113808': {
      display: 'ICRP Pub 60',
    },
    '113809': {
      display: 'Start of X-Ray Irradiation',
    },
    '113810': {
      display: 'End of X-Ray Irradiation',
    },
    '113811': {
      display: 'CT Accumulated Dose Data',
    },
    '113812': {
      display: 'Total Number of Irradiation Events',
    },
    '113813': {
      display: 'CT Dose Length Product Total',
    },
    '113814': {
      display: 'CT Effective Dose Total',
    },
    '113815': {
      display: 'Patient Model',
    },
    '113816': {
      display: 'Condition Effective Dose measured',
    },
    '113817': {
      display: 'Effective Dose Phantom Type',
    },
    '113818': {
      display: 'Dosimeter Type',
    },
    '113819': {
      display: 'CT Acquisition',
    },
    '113820': {
      display: 'CT Acquisition Type',
    },
    '113821': {
      display: 'X-Ray Filter Aluminum Equivalent',
    },
    '113822': {
      display: 'CT Acquisition Parameters',
    },
    '113823': {
      display: 'Number of X-Ray Sources',
    },
    '113824': {
      display: 'Exposure Time',
    },
    '113825': {
      display: 'Scanning Length',
    },
    '113826': {
      display: 'Nominal Single Collimation Width',
    },
    '113827': {
      display: 'Nominal Total Collimation Width',
    },
    '113828': {
      display: 'Pitch Factor',
    },
    '113829': {
      display: 'CT Dose',
    },
    '113830': {
      display: 'Mean CTDIvol',
    },
    '113831': {
      display: 'CT X-Ray Source Parameters',
    },
    '113832': {
      display: 'Identification of the X-Ray Source',
    },
    '113833': {
      display: 'Maximum X-Ray Tube Current',
    },
    '113834': {
      display: 'Exposure Time per Rotation',
    },
    '113835': {
      display: 'CTDIw Phantom Type',
    },
    '113836': {
      display: 'CTDIfreeair Calculation Factor',
    },
    '113837': {
      display: 'Mean CTDIfreeair',
    },
    '113838': {
      display: 'DLP',
    },
    '113839': {
      display: 'Effective Dose',
    },
    '113840': {
      display: 'Effective Dose Conversion Factor',
    },
    '113841': {
      display: 'ICRP Pub 103',
    },
    '113842': {
      display: 'X-Ray Modulation Type',
    },
    '113845': {
      display: 'Exposure Index',
    },
    '113846': {
      display: 'Target Exposure Index',
    },
    '113847': {
      display: 'Deviation Index',
    },
    '113850': {
      display: 'Irradiation Authorizing',
    },
    '113851': {
      display: 'Irradiation Administering',
    },
    '113852': {
      display: 'Irradiation Event',
    },
    '113853': {
      display: 'Irradiation Event UID',
    },
    '113854': {
      display: 'Source of Dose Information',
    },
    '113855': {
      display: 'Total Acquisition Time',
    },
    '113856': {
      display: 'Automated Data Collection',
    },
    '113857': {
      display: 'Manual Entry',
    },
    '113858': {
      display: 'MPPS Content',
    },
    '113859': {
      display: 'Irradiating Device',
    },
    '113860': {
      display: '15cm from Isocenter toward Source',
    },
    '113861': {
      display: '30cm in Front of Image Input Surface',
    },
    '113862': {
      display: '1cm above Tabletop',
    },
    '113863': {
      display: '30cm above Tabletop',
    },
    '113864': {
      display: '15cm from Table Centerline',
    },
    '113865': {
      display: 'Entrance exposure to a 4.2 cm breast thickness',
    },
    '113866': {
      display: 'Copied From Image Attributes',
    },
    '113867': {
      display: 'Computed From Image Attributes',
    },
    '113868': {
      display: 'Derived From Human-Readable Reports',
    },
    '113870': {
      display: 'Person Name',
    },
    '113871': {
      display: 'Person ID',
    },
    '113872': {
      display: 'Person ID Issuer',
    },
    '113873': {
      display: 'Organization Name',
    },
    '113874': {
      display: 'Person Role in Organization',
    },
    '113875': {
      display: 'Person Role in Procedure',
    },
    '113876': {
      display: 'Device Role in Procedure',
    },
    '113877': {
      display: 'Device Name',
    },
    '113878': {
      display: 'Device Manufacturer',
    },
    '113879': {
      display: 'Device Model Name',
    },
    '113880': {
      display: 'Device Serial Number',
    },
    '113890': {
      display: 'All Planes',
    },
    '113893': {
      display: 'Length of Reconstructable Volume',
    },
    '113895': {
      display: 'Top Z Location of Reconstructable Volume',
    },
    '113896': {
      display: 'Bottom Z Location of Reconstructable Volume',
    },
    '113897': {
      display: 'Top Z Location of Scanning Length',
    },
    '113898': {
      display: 'Bottom Z Location of Scanning Length',
    },
    '113899': {
      display: 'Exposed Range',
    },
    '113900': {
      display: 'Dose Check Alert Details',
    },
    '113901': {
      display: 'DLP Alert Value Configured',
    },
    '113902': {
      display: 'CTDIvol Alert Value Configured',
    },
    '113903': {
      display: 'DLP Alert Value',
    },
    '113904': {
      display: 'CTDIvol Alert Value',
    },
    '113905': {
      display: 'Accumulated DLP Forward Estimate',
    },
    '113906': {
      display: 'Accumulated CTDIvol Forward Estimate',
    },
    '113907': {
      display: 'Reason for Proceeding',
    },
    '113908': {
      display: 'Dose Check Notification Details',
    },
    '113909': {
      display: 'DLP Notification Value Configured',
    },
    '113910': {
      display: 'CTDIvol Notification Value Configured',
    },
    '113911': {
      display: 'DLP Notification Value',
    },
    '113912': {
      display: 'CTDIvol Notification Value',
    },
    '113913': {
      display: 'DLP Forward Estimate',
    },
    '113914': {
      display: 'CTDIvol Forward Estimate',
    },
    '113921': {
      display: 'Radiation Exposure',
    },
    '113922': {
      display: 'Radioactive Substance Administered',
    },
    '113923': {
      display: 'Radiation Exposure and Protection Information',
    },
    '113930': {
      display: 'Size Specific Dose Estimation',
    },
    '113931': {
      display: 'Measured Lateral Dimension',
    },
    '113932': {
      display: 'Measured AP Dimension',
    },
    '113933': {
      display: 'Derived Effective Diameter',
    },
    '113934': {
      display: 'AAPM 204 Lateral Dimension',
    },
    '113935': {
      display: 'AAPM 204 AP Dimension',
    },
    '113936': {
      display: 'AAPM 204 Sum of Lateral and AP Dimension',
    },
    '113937': {
      display: 'AAPM 204 Effective Diameter Estimated From Patient Age',
    },
    '113940': {
      display: 'System Calculated',
    },
    '113941': {
      display: 'In Detector Plane',
    },
    '113942': {
      display: 'X-Ray Reading Device',
    },
    '113943': {
      display: 'X-Ray Source Data Available',
    },
    '113944': {
      display: 'X-Ray Mechanical Data Available',
    },
    '113945': {
      display: 'X-Ray Detector Data Available',
    },
    '113946': {
      display: 'Projection Eponymous Name',
    },
    '113947': {
      display: 'Detector Type',
    },
    '113948': {
      display: 'Direct Detector',
    },
    '113949': {
      display: 'Indirect Detector',
    },
    '113950': {
      display: 'Storage Detector',
    },
    '113951': {
      display: 'Film',
    },
    '113952': {
      display: 'Table Mount',
    },
    '113953': {
      display: 'Unmounted Detector',
    },
    '113954': {
      display: 'Upright Stand Mount',
    },
    '113955': {
      display: 'C-Arm Mount',
    },
    '113956': {
      display: 'CR/DR Mechanical Configuration',
    },
    '113957': {
      display: 'Fluoroscopy-Guided Projection Radiography System',
    },
    '113958': {
      display: 'Integrated Projection Radiography System',
    },
    '113959': {
      display: 'Cassette-based Projection Radiography System',
    },
    '113961': {
      display: 'Reconstruction Algorithm',
    },
    '113962': {
      display: 'Filtered Back Projection',
    },
    '113963': {
      display: 'Iterative Reconstruction',
    },
    '113970': {
      display: 'Procedure Step To This Point',
    },
    '114000': {
      display: 'Not a number',
    },
    '114001': {
      display: 'Negative Infinity',
    },
    '114002': {
      display: 'Positive Infinity',
    },
    '114003': {
      display: 'Divide by zero',
    },
    '114004': {
      display: 'Underflow',
    },
    '114005': {
      display: 'Overflow',
    },
    '114006': {
      display: 'Measurement failure',
    },
    '114007': {
      display: 'Measurement not attempted',
    },
    '114008': {
      display: 'Calculation failure',
    },
    '114009': {
      display: 'Value out of range',
    },
    '114010': {
      display: 'Value unknown',
    },
    '114011': {
      display: 'Value indeterminate',
    },
    '114201': {
      display: 'Time of flight',
    },
    '114202': {
      display: 'Interferometry',
    },
    '114203': {
      display: 'Laser scanning',
    },
    '114204': {
      display: 'Pattern projection',
    },
    '114205': {
      display: 'Shape from shading',
    },
    '114206': {
      display: 'Shape from motion',
    },
    '114207': {
      display: 'Confocal imaging',
    },
    '114208': {
      display: 'Point Cloud Algorithmic',
    },
    '114209': {
      display: 'Turntable Scan Method',
    },
    '114210': {
      display: 'High resolution',
    },
    '114211': {
      display: 'Fast mode',
    },
    '114213': {
      display: 'Iterative Closest Point',
    },
    '114215': {
      display: 'Freehand',
    },
    '114216': {
      display: 'Checkerboard',
    },
    '121001': {
      display: 'Quotation Mode',
    },
    '121002': {
      display: 'Quoted Source',
    },
    '121003': {
      display: 'Document',
    },
    '121004': {
      display: 'Verbal',
    },
    '121005': {
      display: 'Observer Type',
    },
    '121006': {
      display: 'Person',
    },
    '121007': {
      display: 'Device',
    },
    '121008': {
      display: 'Person Observer Name',
    },
    '121009': {
      display: "Person Observer's Organization Name",
    },
    '121010': {
      display: "Person Observer's Role in the Organization",
    },
    '121011': {
      display: "Person Observer's Role in this Procedure",
    },
    '121012': {
      display: 'Device Observer UID',
    },
    '121013': {
      display: 'Device Observer Name',
    },
    '121014': {
      display: 'Device Observer Manufacturer',
    },
    '121015': {
      display: 'Device Observer Model Name',
    },
    '121016': {
      display: 'Device Observer Serial Number',
    },
    '121017': {
      display: 'Device Observer Physical Location During Observation',
    },
    '121018': {
      display: 'Procedure Study Instance UID',
    },
    '121019': {
      display: 'Procedure Study Component UID',
    },
    '121020': {
      display: 'Placer Number',
    },
    '121021': {
      display: 'Filler Number',
    },
    '121022': {
      display: 'Accession Number',
    },
    '121023': {
      display: 'Procedure Code',
    },
    '121024': {
      display: 'Subject Class',
    },
    '121025': {
      display: 'Patient',
    },
    '121026': {
      display: 'Fetus',
    },
    '121027': {
      display: 'Specimen',
    },
    '121028': {
      display: 'Subject UID',
    },
    '121029': {
      display: 'Subject Name',
    },
    '121030': {
      display: 'Subject ID',
    },
    '121031': {
      display: 'Subject Birth Date',
    },
    '121032': {
      display: 'Subject Sex',
    },
    '121033': {
      display: 'Subject Age',
    },
    '121034': {
      display: 'Subject Species',
    },
    '121035': {
      display: 'Subject Breed',
    },
    '121036': {
      display: 'Mother of fetus',
    },
    '121037': {
      display: 'Fetus number',
    },
    '121038': {
      display: 'Number of Fetuses',
    },
    '121039': {
      display: 'Specimen UID',
    },
    '121040': {
      display: 'Specimen Accession Number',
    },
    '121041': {
      display: 'Specimen Identifier',
    },
    '121042': {
      display: 'Specimen Type',
    },
    '121043': {
      display: 'Slide Identifier',
    },
    '121044': {
      display: 'Slide UID',
    },
    '121045': {
      display: 'Language',
    },
    '121046': {
      display: 'Country of Language',
    },
    '121047': {
      display: 'Language of Value',
    },
    '121048': {
      display: 'Language of Name and Value',
    },
    '121049': {
      display: 'Language of Content Item and Descendants',
    },
    '121050': {
      display: 'Equivalent Meaning of Concept Name',
    },
    '121051': {
      display: 'Equivalent Meaning of Value',
    },
    '121052': {
      display: 'Presence of property',
    },
    '121053': {
      display: 'Present',
    },
    '121054': {
      display: 'Absent',
    },
    '121055': {
      display: 'Path',
    },
    '121056': {
      display: 'Area outline',
    },
    '121057': {
      display: 'Perimeter outline',
    },
    '121058': {
      display: 'Procedure reported',
    },
    '121059': {
      display: 'Presence Undetermined',
    },
    '121060': {
      display: 'History',
    },
    '121062': {
      display: 'Request',
    },
    '121064': {
      display: 'Current Procedure Descriptions',
    },
    '121065': {
      display: 'Procedure Description',
    },
    '121066': {
      display: 'Prior Procedure Descriptions',
    },
    '121068': {
      display: 'Previous Findings',
    },
    '121069': {
      display: 'Previous Finding',
    },
    '121070': {
      display: 'Findings',
    },
    '121071': {
      display: 'Finding',
    },
    '121072': {
      display: 'Impressions',
    },
    '121073': {
      display: 'Impression',
    },
    '121074': {
      display: 'Recommendations',
    },
    '121075': {
      display: 'Recommendation',
    },
    '121076': {
      display: 'Conclusions',
    },
    '121077': {
      display: 'Conclusion',
    },
    '121078': {
      display: 'Addendum',
    },
    '121079': {
      display: 'Baseline',
    },
    '121080': {
      display: 'Best illustration of finding',
    },
    '121081': {
      display: 'Physician',
    },
    '121082': {
      display: 'Nurse',
    },
    '121083': {
      display: 'Technologist',
    },
    '121084': {
      display: 'Radiographer',
    },
    '121085': {
      display: 'Intern',
    },
    '121086': {
      display: 'Resident',
    },
    '121087': {
      display: 'Registrar',
    },
    '121088': {
      display: 'Fellow',
    },
    '121089': {
      display: 'Attending [Consultant]',
    },
    '121090': {
      display: 'Scrub nurse',
    },
    '121091': {
      display: 'Surgeon',
    },
    '121092': {
      display: 'Sonologist',
    },
    '121093': {
      display: 'Sonographer',
    },
    '121094': {
      display: 'Performing',
    },
    '121095': {
      display: 'Referring',
    },
    '121096': {
      display: 'Requesting',
    },
    '121097': {
      display: 'Recording',
    },
    '121098': {
      display: 'Verifying',
    },
    '121099': {
      display: 'Assisting',
    },
    '121100': {
      display: 'Circulating',
    },
    '121101': {
      display: 'Standby',
    },
    '121102': {
      display: 'Other sex',
    },
    '121103': {
      display: 'Undetermined sex',
    },
    '121104': {
      display: 'Ambiguous sex',
    },
    '121105': {
      display: 'Radiation Physicist',
    },
    '121106': {
      display: 'Comment',
    },
    '121109': {
      display: 'Indications for Procedure',
    },
    '121110': {
      display: 'Patient Presentation',
    },
    '121111': {
      display: 'Summary',
    },
    '121112': {
      display: 'Source of Measurement',
    },
    '121113': {
      display: 'Complications',
    },
    '121114': {
      display: 'Performing Physician',
    },
    '121115': {
      display: 'Discharge Summary',
    },
    '121116': {
      display: 'Proximal Finding Site',
    },
    '121117': {
      display: 'Distal Finding Site',
    },
    '121118': {
      display: 'Patient Characteristics',
    },
    '121120': {
      display: 'Cath Lab Procedure Log',
    },
    '121121': {
      display: 'Room identification',
    },
    '121122': {
      display: 'Equipment Identification',
    },
    '121123': {
      display: 'Patient Status or Event',
    },
    '121124': {
      display: 'Procedure Action Item ID',
    },
    '121125': {
      display: 'DateTime of Recording of Log Entry',
    },
    '121126': {
      display: 'Performed Procedure Step SOP Instance UID',
    },
    '121127': {
      display: 'Performed Procedure Step SOP Class UID',
    },
    '121128': {
      display: 'Procedure Action Duration',
    },
    '121130': {
      display: 'Start Procedure Action Item',
    },
    '121131': {
      display: 'End Procedure Action Item',
    },
    '121132': {
      display: 'Suspend Procedure Action Item',
    },
    '121133': {
      display: 'Resume Procedure Action Item',
    },
    '121135': {
      display: 'Observation DateTime Qualifier',
    },
    '121136': {
      display: 'DateTime Unsynchronized',
    },
    '121137': {
      display: 'DateTime Estimated',
    },
    '121138': {
      display: 'Image Acquired',
    },
    '121139': {
      display: 'Modality',
    },
    '121140': {
      display: 'Number of Frames',
    },
    '121141': {
      display: 'Image Type',
    },
    '121142': {
      display: 'Acquisition Duration',
    },
    '121143': {
      display: 'Waveform Acquired',
    },
    '121144': {
      display: 'Document Title',
    },
    '121145': {
      display: 'Description of Material',
    },
    '121146': {
      display: 'Quantity of Material',
    },
    '121147': {
      display: 'Billing Code',
    },
    '121148': {
      display: 'Unit Serial Identifier',
    },
    '121149': {
      display: 'Lot Identifier',
    },
    '121150': {
      display: 'Device Code',
    },
    '121151': {
      display: 'Lesion Identifier',
    },
    '121152': {
      display: 'Person administering drug/contrast',
    },
    '121153': {
      display: 'Lesion Risk',
    },
    '121154': {
      display: 'Intervention attempt identifier',
    },
    '121155': {
      display: 'Deployment',
    },
    '121156': {
      display: 'Percutaneous Entry Action',
    },
    '121157': {
      display: 'Begin Circulatory Support',
    },
    '121158': {
      display: 'End Circulatory Support',
    },
    '121160': {
      display: 'Oxygen Administration Rate',
    },
    '121161': {
      display: 'Begin Oxygen Administration',
    },
    '121162': {
      display: 'End oxygen administration',
    },
    '121163': {
      display: 'By ventilator',
    },
    '121165': {
      display: 'Patient Assessment Performed',
    },
    '121166': {
      display: 'Begin Pacing',
    },
    '121167': {
      display: 'End Pacing',
    },
    '121168': {
      display: 'Begin Ventilation',
    },
    '121169': {
      display: 'End Ventilation',
    },
    '121171': {
      display: 'Tech Note',
    },
    '121172': {
      display: 'Nursing Note',
    },
    '121173': {
      display: 'Physician Note',
    },
    '121174': {
      display: 'Procedure Note',
    },
    '121180': {
      display: 'Key Images',
    },
    '121181': {
      display: 'DICOM Object Catalog',
    },
    '121190': {
      display: 'Referenced Frames',
    },
    '121191': {
      display: 'Referenced Segment',
    },
    '121192': {
      display: 'Device Subject',
    },
    '121193': {
      display: 'Device Subject Name',
    },
    '121194': {
      display: 'Device Subject Manufacturer',
    },
    '121195': {
      display: 'Device Subject Model Name',
    },
    '121196': {
      display: 'Device Subject Serial Number',
    },
    '121197': {
      display: 'Device Subject Physical Location during observation',
    },
    '121198': {
      display: 'Device Subject UID',
    },
    '121200': {
      display: 'Illustration of ROI',
    },
    '121201': {
      display: 'Area Outline',
    },
    '121202': {
      display: 'Area of Defined Region',
    },
    '121206': {
      display: 'Distance',
    },
    '121207': {
      display: 'Height',
    },
    '121208': {
      display: 'Inter-Marker Distance',
    },
    '121210': {
      display: 'Path',
    },
    '121211': {
      display: 'Path length',
    },
    '121213': {
      display: 'Perimeter Outline',
    },
    '121214': {
      display: 'Referenced Segmentation Frame',
    },
    '121216': {
      display: 'Volume estimated from single 2D region',
    },
    '121217': {
      display: 'Volume estimated from three or more non-coplanar 2D regions',
    },
    '121218': {
      display: 'Volume estimated from two non-coplanar 2D regions',
    },
    '121219': {
      display: 'Volume of bounding three dimensional region',
    },
    '121220': {
      display: 'Volume of circumscribed sphere',
    },
    '121221': {
      display: 'Volume of ellipsoid',
    },
    '121222': {
      display: 'Volume of sphere',
    },
    '121230': {
      display: 'Path Vertex',
    },
    '121231': {
      display: 'Volume Surface',
    },
    '121232': {
      display: 'Source series for segmentation',
    },
    '121233': {
      display: 'Source image for segmentation',
    },
    '121242': {
      display: 'Distance from nipple',
    },
    '121243': {
      display: 'Distance from skin',
    },
    '121244': {
      display: 'Distance from chest wall',
    },
    '121290': {
      display: 'Patient exposure to ionizing radiation',
    },
    '121291': {
      display: 'Results communicated',
    },
    '121301': {
      display: 'Simultaneous Doppler',
    },
    '121302': {
      display: 'Simultaneous Hemodynamic',
    },
    '121303': {
      display: 'Simultaneous ECG',
    },
    '121304': {
      display: 'Simultaneous Voice Narrative',
    },
    '121305': {
      display: 'Simultaneous Respiratory Waveform',
    },
    '121306': {
      display: 'Simultaneous Arterial Pulse Waveform',
    },
    '121307': {
      display: 'Simultaneous Phonocardiographic Waveform',
    },
    '121311': {
      display: 'Localizer',
    },
    '121312': {
      display: 'Biopsy localizer',
    },
    '121313': {
      display: 'Other partial views',
    },
    '121314': {
      display: 'Other image of biplane pair',
    },
    '121315': {
      display: 'Other image of stereoscopic pair',
    },
    '121316': {
      display: 'Images related to standalone object',
    },
    '121317': {
      display: 'Spectroscopy',
    },
    '121318': {
      display: 'Spectroscopy Data for Water Phase Correction',
    },
    '121320': {
      display: 'Uncompressed predecessor',
    },
    '121321': {
      display: 'Mask image for image processing operation',
    },
    '121322': {
      display: 'Source image for image processing operation',
    },
    '121323': {
      display: 'Source series for image processing operation',
    },
    '121324': {
      display: 'Source Image',
    },
    '121325': {
      display: 'Lossy compressed image',
    },
    '121326': {
      display: 'Alternate SOP Class instance',
    },
    '121327': {
      display: 'Full fidelity image',
    },
    '121328': {
      display: 'Alternate Photometric Interpretation image',
    },
    '121329': {
      display: 'Source image for montage',
    },
    '121330': {
      display: 'Lossy compressed predecessor',
    },
    '121331': {
      display: 'Equivalent CDA Document',
    },
    '121332': {
      display: 'Complete Rendering for Presentation',
    },
    '121333': {
      display: 'Partial Rendering for Presentation',
    },
    '121334': {
      display: 'Extended Rendering for Presentation',
    },
    '121335': {
      display: 'Source Document',
    },
    '121338': {
      display: 'Anatomic image',
    },
    '121339': {
      display: 'Functional image',
    },
    '121340': {
      display: 'Spectral filtered image',
    },
    '121341': {
      display: 'Device localizer',
    },
    '121342': {
      display: 'Dose Image',
    },
    '121346': {
      display: 'Acquisition frames corresponding to volume',
    },
    '121347': {
      display: 'Volume corresponding to spatially-related acquisition frames',
    },
    '121348': {
      display: 'Temporal Predecessor',
    },
    '121349': {
      display: 'Temporal Successor',
    },
    '121350': {
      display: 'Same acquisition at lower resolution',
    },
    '121351': {
      display: 'Same acquisition at higher resolution',
    },
    '121352': {
      display: 'Same acquisition at different focal depth',
    },
    '121353': {
      display: 'Same acquisition at different spectral band',
    },
    '121354': {
      display: 'Imaged container label',
    },
    '121358': {
      display: 'For Processing predecessor',
    },
    '121360': {
      display: 'Replaced report',
    },
    '121361': {
      display: 'Addended report',
    },
    '121362': {
      display: 'Preliminary report',
    },
    '121363': {
      display: 'Partial report',
    },
    '121370': {
      display: 'Composed from prior doses',
    },
    '121371': {
      display: 'Composed from prior doses and current plan',
    },
    '121372': {
      display: 'Source dose for composing current dose',
    },
    '121380': {
      display: 'Active Ingredient Undiluted Concentration',
    },
    '121381': {
      display: 'Contrast/Bolus Ingredient Opaque',
    },
    '121382': {
      display: 'Quantity administered',
    },
    '121383': {
      display: 'Mass administered',
    },
    '121401': {
      display: 'Derivation',
    },
    '121402': {
      display: 'Normality',
    },
    '121403': {
      display: 'Level of Significance',
    },
    '121404': {
      display: 'Selection Status',
    },
    '121405': {
      display: 'Population description',
    },
    '121406': {
      display: 'Reference Authority',
    },
    '121407': {
      display: 'Normal Range description',
    },
    '121408': {
      display: 'Normal Range Authority',
    },
    '121410': {
      display: 'User chosen value',
    },
    '121411': {
      display: 'Most recent value chosen',
    },
    '121412': {
      display: 'Mean value chosen',
    },
    '121414': {
      display: 'Standard deviation of population',
    },
    '121415': {
      display: 'Percentile Ranking of measurement',
    },
    '121416': {
      display: 'Z-Score of measurement',
    },
    '121417': {
      display: '2 Sigma deviation of population',
    },
    '121420': {
      display: 'Equation',
    },
    '121421': {
      display: 'Equation Citation',
    },
    '121422': {
      display: 'Table of Values Citation',
    },
    '121423': {
      display: 'Method Citation',
    },
    '121424': {
      display: 'Table of Values',
    },
    '121425': {
      display: 'Index',
    },
    '121427': {
      display: 'Estimated',
    },
    '121428': {
      display: 'Calculated',
    },
    '121430': {
      display: 'Concern',
    },
    '121431': {
      display: 'DateTime Concern Noted',
    },
    '121432': {
      display: 'DateTime Concern Resolved',
    },
    '121433': {
      display: 'DateTime Problem Resolved',
    },
    '121434': {
      display: 'Service Delivery Location',
    },
    '121435': {
      display: 'Service Performer',
    },
    '121436': {
      display: 'Medical Device Used',
    },
    '121437': {
      display: 'Pharmacologic and exercise stress test',
    },
    '121438': {
      display: 'Paced stress test',
    },
    '121439': {
      display: 'Correction of congenital cardiovascular deformity',
    },
    '121701': {
      display: 'RT Patient Setup',
    },
    '121702': {
      display: 'RT Patient Position Acquisition, single plane MV',
    },
    '121703': {
      display: 'RT Patient Position Acquisition, dual plane MV',
    },
    '121704': {
      display: 'RT Patient Position Acquisition, single plane kV',
    },
    '121705': {
      display: 'RT Patient Position Acquisition, dual plane kV',
    },
    '121706': {
      display: 'RT Patient Position Acquisition, dual plane kV/MV',
    },
    '121707': {
      display: 'RT Patient Position Acquisition, CT kV',
    },
    '121708': {
      display: 'RT Patient Position Acquisition, CT MV',
    },
    '121709': {
      display: 'RT Patient Position Acquisition, Optical',
    },
    '121710': {
      display: 'RT Patient Position Acquisition, Ultrasound',
    },
    '121711': {
      display: 'RT Patient Position Acquisition, Spatial Fiducials',
    },
    '121712': {
      display: 'RT Patient Position Registration, single plane',
    },
    '121713': {
      display: 'RT Patient Position Registration, dual plane',
    },
    '121714': {
      display: 'RT Patient Position Registration, 3D CT general',
    },
    '121715': {
      display: 'RT Patient Position Registration, 3D CT marker-based',
    },
    '121716': {
      display: 'RT Patient Position Registration, 3D CT volume-based',
    },
    '121717': {
      display: 'RT Patient Position Registration, 3D on 2D reference',
    },
    '121718': {
      display: 'RT Patient Position Registration, 2D on 3D reference',
    },
    '121719': {
      display: 'RT Patient Position Registration, Optical',
    },
    '121720': {
      display: 'RT Patient Position Registration, Ultrasound',
    },
    '121721': {
      display: 'RT Patient Position Registration, Spatial Fiducials',
    },
    '121722': {
      display: 'RT Patient Position Adjustment',
    },
    '121723': {
      display: 'RT Patient Position In-treatment-session Review',
    },
    '121724': {
      display: 'RT Treatment Simulation with Internal Verification',
    },
    '121725': {
      display: 'RT Treatment Simulation with External Verification',
    },
    '121726': {
      display: 'RT Treatment with Internal Verification',
    },
    '121727': {
      display: 'RT Treatment with External Verification',
    },
    '121728': {
      display: 'RT Treatment QA with Internal Verification',
    },
    '121729': {
      display: 'RT Treatment QA with External Verification',
    },
    '121730': {
      display: 'RT Machine QA',
    },
    '121731': {
      display: 'RT Treatment QA by RT Plan Dose Check',
    },
    '121732': {
      display: 'RT Treatment QA by RT Plan Difference Check',
    },
    '121733': {
      display: 'RT Treatment QA by RT Ion Plan Dose Check',
    },
    '121734': {
      display: 'RT Treatment QA with RT Ion Plan Difference Check',
    },
    '121740': {
      display: 'Treatment Delivery Type',
    },
    '122001': {
      display: 'Patient called to procedure room',
    },
    '122002': {
      display: 'Patient admitted to procedure room',
    },
    '122003': {
      display: 'Patient given pre-procedure instruction',
    },
    '122004': {
      display: 'Patient informed consent given',
    },
    '122005': {
      display: 'Patient advance directive given',
    },
    '122006': {
      display: 'Nil Per Os (NPO) status confirmed',
    },
    '122007': {
      display: 'Patient assisted to table',
    },
    '122008': {
      display: 'Patient prepped and draped',
    },
    '122009': {
      display: 'Patient connected to continuous monitoring',
    },
    '122010': {
      display: 'Patient transferred to holding area',
    },
    '122011': {
      display: 'Patient transferred to surgery',
    },
    '122012': {
      display: 'Patient transferred to CCU',
    },
    '122020': {
      display: 'Patient disoriented',
    },
    '122021': {
      display: 'Patient reports nausea',
    },
    '122022': {
      display: 'Patient reports discomfort',
    },
    '122023': {
      display: 'Patient reports chest pain',
    },
    '122024': {
      display: 'Patient reports no pain',
    },
    '122025': {
      display: 'Patient alert',
    },
    '122026': {
      display: 'Patient restless',
    },
    '122027': {
      display: 'Patient sedated',
    },
    '122028': {
      display: 'Patient asleep',
    },
    '122029': {
      display: 'Patient unresponsive',
    },
    '122030': {
      display: 'Patient has respiratory difficulty',
    },
    '122031': {
      display: 'Patient coughed',
    },
    '122032': {
      display: 'Patient disconnected from continuous monitoring',
    },
    '122033': {
      display: 'Hemostasis achieved',
    },
    '122034': {
      display: 'Hemostasis not achieved - oozing',
    },
    '122035': {
      display: 'Hemostasis not achieved - actively bleeding',
    },
    '122036': {
      display: 'Patient given post-procedure instruction',
    },
    '122037': {
      display: 'Patient discharged from department',
    },
    '122038': {
      display: 'Patient pronounced dead',
    },
    '122039': {
      display: 'Patient transferred to morgue',
    },
    '122041': {
      display: 'Personnel Arrived',
    },
    '122042': {
      display: 'Personnel Departed',
    },
    '122043': {
      display: 'Page Sent To',
    },
    '122044': {
      display: 'Consultation With',
    },
    '122045': {
      display: 'Office called',
    },
    '122046': {
      display: 'Equipment failure',
    },
    '122047': {
      display: 'Equipment brought to procedure room',
    },
    '122048': {
      display: 'Equipment ready',
    },
    '122049': {
      display: 'Equipment removed',
    },
    '122052': {
      display: 'Bioptome',
    },
    '122053': {
      display: 'Valvular Intervention',
    },
    '122054': {
      display: 'Aortic Intervention',
    },
    '122055': {
      display: 'Septal Defect Intervention',
    },
    '122056': {
      display: 'Vascular Intervention',
    },
    '122057': {
      display: 'Myocardial biopsy',
    },
    '122058': {
      display: 'Arterial conduit angiography',
    },
    '122059': {
      display: 'Single plane Angiography',
    },
    '122060': {
      display: 'Bi-plane Angiography',
    },
    '122061': {
      display: 'Percutaneous Coronary Intervention',
    },
    '122062': {
      display: '15-Lead ECG',
    },
    '122072': {
      display: 'Pre-procedure log',
    },
    '122073': {
      display: 'Current procedure evidence',
    },
    '122075': {
      display: 'Prior report for current patient',
    },
    '122076': {
      display: 'Consumable taken from inventory',
    },
    '122077': {
      display: 'Consumable returned to inventory',
    },
    '122078': {
      display: 'Remaining consumable disposed',
    },
    '122079': {
      display: 'Consumable unusable',
    },
    '122081': {
      display: 'Drug start',
    },
    '122082': {
      display: 'Drug end',
    },
    '122083': {
      display: 'Drug administered',
    },
    '122084': {
      display: 'Contrast start',
    },
    '122085': {
      display: 'Contrast end',
    },
    '122086': {
      display: 'Contrast administered',
    },
    '122087': {
      display: 'Infusate start',
    },
    '122088': {
      display: 'Infusate end',
    },
    '122089': {
      display: 'Device crossed lesion',
    },
    '122090': {
      display: 'Intervention Action',
    },
    '122091': {
      display: 'Volume administered',
    },
    '122092': {
      display: 'Undiluted dose administered',
    },
    '122093': {
      display: 'Concentration',
    },
    '122094': {
      display: 'Rate of administration',
    },
    '122095': {
      display: 'Duration of administration',
    },
    '122096': {
      display: 'Volume unadministered or discarded',
    },
    '122097': {
      display: 'Catheter Curve',
    },
    '122098': {
      display: 'Transmit Frequency',
    },
    '122099': {
      display: 'ST change from baseline',
    },
    '122101': {
      display: 'Aneurysm on cited vessel',
    },
    '122102': {
      display: 'Graft to cited segment, proximal section',
    },
    '122103': {
      display: 'Graft to cited segment, mid section',
    },
    '122104': {
      display: 'Graft to cited segment, distal section',
    },
    '122105': {
      display: 'DateTime of Intervention',
    },
    '122106': {
      display: 'Duration of Intervention',
    },
    '122107': {
      display: 'Baseline Stenosis Measurement',
    },
    '122108': {
      display: 'Post-Intervention Stenosis Measurement',
    },
    '122109': {
      display: 'Baseline TIMI Flow',
    },
    '122110': {
      display: 'Post-Intervention TIMI Flow',
    },
    '122111': {
      display: 'Primary Intervention Device',
    },
    '122112': {
      display: 'Normal Myocardium',
    },
    '122113': {
      display: 'Sacrred Myocardial',
    },
    '122114': {
      display: 'Thinning Myocardium',
    },
    '122120': {
      display: 'Hemodynamics Report',
    },
    '122121': {
      display: 'Atrial pressure measurements',
    },
    '122122': {
      display: 'Ventricular pressure measurements',
    },
    '122123': {
      display: 'Gradient assessment',
    },
    '122124': {
      display: 'Blood velocity measurements',
    },
    '122125': {
      display: 'Blood lab measurements',
    },
    '122126': {
      display: 'Derived Hemodynamic Measurements',
    },
    '122127': {
      display: 'Clinical Context',
    },
    '122128': {
      display: 'Patient Transferred From',
    },
    '122129': {
      display: 'PCI during this procedure',
    },
    '122130': {
      display: 'Dose Area Product',
    },
    '122131': {
      display: 'Degree of Thrombus',
    },
    '122132': {
      display: 'Severity of Calcification',
    },
    '122133': {
      display: 'Lesion Morphology',
    },
    '122134': {
      display: 'Vessel Morphology',
    },
    '122138': {
      display: 'Circulatory Support',
    },
    '122139': {
      display: 'Reason for Exam',
    },
    '122140': {
      display: 'Comparison with Prior Exam Done',
    },
    '122141': {
      display: 'Electrode Placement',
    },
    '122142': {
      display: 'Acquisition Device Type',
    },
    '122143': {
      display: 'Acquisition Device ID',
    },
    '122144': {
      display: 'Quantitative Analysis',
    },
    '122145': {
      display: 'Qualitative Analysis',
    },
    '122146': {
      display: 'Procedure DateTime',
    },
    '122147': {
      display: 'Clinical Interpretation',
    },
    '122148': {
      display: 'Lead ID',
    },
    '122149': {
      display: 'Beat Number',
    },
    '122150': {
      display: 'Compound Statement',
    },
    '122151': {
      display: 'Trend',
    },
    '122152': {
      display: 'Statement',
    },
    '122153': {
      display: 'Statement Modifier',
    },
    '122154': {
      display: 'Conjunctive Term',
    },
    '122157': {
      display: 'Probability',
    },
    '122158': {
      display: 'ECG Global Measurements',
    },
    '122159': {
      display: 'ECG Lead Measurements',
    },
    '122160': {
      display: 'Derived Area, Non-Valve',
    },
    '122161': {
      display: 'Pulmonary Flow',
    },
    '122162': {
      display: 'Systemic Flow',
    },
    '122163': {
      display: 'Discharge DateTime',
    },
    '122164': {
      display: 'Coronary Artery Bypass During This Admission',
    },
    '122165': {
      display: 'Date of Death',
    },
    '122166': {
      display: 'Death During This Admission',
    },
    '122167': {
      display: 'Death During Catheterization',
    },
    '122170': {
      display: 'Type of Myocardial Infarction',
    },
    '122171': {
      display: 'Coronary lesion > = 50% stenosis',
    },
    '122172': {
      display: 'Acute MI Present',
    },
    '122173': {
      display: 'ST Elevation Onset DateTime',
    },
    '122175': {
      display: 'Number of lesion interventions attempted',
    },
    '122176': {
      display: 'Number of lesion interventions successful',
    },
    '122177': {
      display: 'Procedure Result',
    },
    '122178': {
      display: 'Lesion Intervention Information',
    },
    '122179': {
      display: 'Peri-procedural MI occurred',
    },
    '122180': {
      display: 'CK-MB baseline',
    },
    '122181': {
      display: 'CK-MB peak',
    },
    '122182': {
      display: 'R-R interval',
    },
    '122183': {
      display: 'Blood temperature',
    },
    '122185': {
      display: 'Blood Oxygen content',
    },
    '122187': {
      display: 'Blood Carbon dioxide saturation',
    },
    '122188': {
      display: 'Pulmonary Arterial Content (FCpa)',
    },
    '122189': {
      display: 'Pulmonary Venous Content (FCpv)',
    },
    '122190': {
      display: 'Max dp/dt/P',
    },
    '122191': {
      display: 'Ventricular End Diastolic pressure',
    },
    '122192': {
      display: 'Indicator appearance time',
    },
    '122193': {
      display: 'Maximum pressure acceleration',
    },
    '122194': {
      display: 'Ventricular Systolic blood pressure',
    },
    '122195': {
      display: 'Pulse Strength',
    },
    '122196': {
      display: 'C wave pressure',
    },
    '122197': {
      display: 'Gradient pressure, average',
    },
    '122198': {
      display: 'Gradient pressure, peak',
    },
    '122199': {
      display: 'Pressure at dp/dt max',
    },
    '122201': {
      display: 'Diastolic blood velocity, mean',
    },
    '122202': {
      display: 'Diastolic blood velocity, peak',
    },
    '122203': {
      display: 'Systolic blood velocity, mean',
    },
    '122204': {
      display: 'Systolic blood velocity, peak',
    },
    '122205': {
      display: 'Blood velocity, mean',
    },
    '122206': {
      display: 'Blood velocity, minimum',
    },
    '122207': {
      display: 'Blood velocity, peak',
    },
    '122208': {
      display: 'x-descent pressure',
    },
    '122209': {
      display: 'y-descent pressure',
    },
    '122210': {
      display: 'z-point pressure',
    },
    '122211': {
      display: 'Left Ventricular ejection time',
    },
    '122212': {
      display: 'Left Ventricular filling time',
    },
    '122213': {
      display: 'Right Ventricular ejection time',
    },
    '122214': {
      display: 'Right Ventricular filling time',
    },
    '122215': {
      display: 'Total Pulmonary Resistance',
    },
    '122216': {
      display: 'Total Vascular Resistance',
    },
    '122217': {
      display: 'Coronary Flow reserve',
    },
    '122218': {
      display: 'Diastolic/Systolic velocity ratio',
    },
    '122219': {
      display: 'Hyperemic ratio',
    },
    '122220': {
      display: 'Hemodynamic Resistance Index',
    },
    '122221': {
      display: 'Thorax diameter, sagittal',
    },
    '122222': {
      display: 'Procedure Environmental Characteristics',
    },
    '122223': {
      display: 'Room oxygen concentration',
    },
    '122224': {
      display: 'Room temperature',
    },
    '122225': {
      display: 'Room Barometric pressure',
    },
    '122227': {
      display: 'Left to Right Flow',
    },
    '122228': {
      display: 'Right to Left Flow',
    },
    '122229': {
      display: 'Arteriovenous difference',
    },
    '122230': {
      display: '10 Year CHD Risk',
    },
    '122231': {
      display: 'Comparative Average10 Year CHD Risk',
    },
    '122232': {
      display: 'Comparative Low10 Year CHD Risk',
    },
    '122233': {
      display: 'LDL Cholesterol Score Sheet for Men',
    },
    '122234': {
      display: 'LDL Cholesterol Score Sheet for Women',
    },
    '122235': {
      display: 'Total Cholesterol Score Sheet for Men',
    },
    '122236': {
      display: 'Total Cholesterol Score Sheet for Women',
    },
    '122237': {
      display: 'Corrected Sinus Node Recovery Time',
    },
    '122238': {
      display: 'Max volume normalized to 50mmHg pulse pressure',
    },
    '122239': {
      display: 'Oxygen Consumption',
    },
    '122240': {
      display: 'BSA = 3.207*WT^(0.7285-0.0188 log (WT)) *HT^0.3',
    },
    '122241': {
      display: 'BSA = 0.007184*WT^ 0.425*HT^0.725',
    },
    '122242': {
      display: 'BSA = 0.0235*WT^0.51456*HT^ 0.42246',
    },
    '122243': {
      display: 'BSA = 0.024265*WT^0.5378*HT^0.3964',
    },
    '122244': {
      display: 'BSA = (HT * WT/36) ^0.5',
    },
    '122245': {
      display: 'BSA = 1321+0.3433*WT',
    },
    '122246': {
      display: 'BSA = 0.0004688 * WT ^ (0.8168 - 0.0154 * log(WT))',
    },
    '122247': {
      display: 'VO2male = BSA (138.1 - 11.49 * loge(age) + 0.378 * HRf)',
    },
    '122248': {
      display: 'VO2female = BSA (138.1 - 17.04 * loge(age) + 0.378 * HRf)',
    },
    '122249': {
      display: 'VO2 = VeSTPD * 10 * (FIO2 - FE02)',
    },
    '122250': {
      display: 'VO2 = 152 * BSA',
    },
    '122251': {
      display: 'VO2 = 175 * BSA',
    },
    '122252': {
      display: 'VO2 = 176 * BSA',
    },
    '122253': {
      display: 'Robertson & Reid table',
    },
    '122254': {
      display: 'Fleisch table',
    },
    '122255': {
      display: 'Boothby table',
    },
    '122256': {
      display: 'if (prem age< 3days) P50 = 19.9',
    },
    '122257': {
      display: 'if (age < 1day) P50 = 21.6',
    },
    '122258': {
      display: 'if (age < 30day) P50 = 24.6',
    },
    '122259': {
      display: 'if (age < 18y) P50 = 27.2',
    },
    '122260': {
      display: 'if (age < 40y) P50 = 27.4',
    },
    '122261': {
      display: 'if (age > 60y) P50 = 29.3',
    },
    '122262': {
      display: 'Area = Flow / 44.5 * sqrt(Gradient[mmHg])',
    },
    '122263': {
      display: 'MVA = Flow / 38.0 * sqrt(Gradient[mmHg])',
    },
    '122265': {
      display: 'BMI = Wt / Ht ^ 2',
    },
    '122266': {
      display: 'BSA = 0.007358 * WT ^ 0.425 * HT ^ 0.725',
    },
    '122267': {
      display: 'BSA = 0.010265 * WT ^ 0.423 * HT ^ 0.651',
    },
    '122268': {
      display: 'BSA = 0.008883 * WT ^ 0.444 * HT ^ 0.663',
    },
    '122269': {
      display: 'BSA = 0.038189 * WT ^ 0.423 * HT ^ 0.362',
    },
    '122270': {
      display: 'BSA = 0.009568 * WT ^ 0.473 * HT ^ 0.655',
    },
    '122271': {
      display: 'Skin Condition Warm',
    },
    '122272': {
      display: 'Skin Condition Cool',
    },
    '122273': {
      display: 'Skin Condition Cold',
    },
    '122274': {
      display: 'Skin Condition Dry',
    },
    '122275': {
      display: 'Skin Condition Clammy',
    },
    '122276': {
      display: 'Skin Condition Diaphoretic',
    },
    '122277': {
      display: 'Skin Condition Flush',
    },
    '122278': {
      display: 'Skin Condition Mottled',
    },
    '122279': {
      display: 'Skin Condition Pale',
    },
    '122281': {
      display: 'Airway unobstructed',
    },
    '122282': {
      display: 'Airway partially obstructed',
    },
    '122283': {
      display: 'Airway severely obstructed',
    },
    '122288': {
      display: 'Not Visualized',
    },
    '122291': {
      display: 'Quantitative Arteriography Report',
    },
    '122292': {
      display: 'Quantitative Ventriculography Report',
    },
    '122301': {
      display: 'Guidewire crossing lesion unsuccessful',
    },
    '122302': {
      display: 'Guidewire crossing lesion successful',
    },
    '122303': {
      display: 'Angioplasty balloon inflated',
    },
    '122304': {
      display: 'Angioplasty balloon deflated',
    },
    '122305': {
      display: 'Device deployed',
    },
    '122306': {
      display: 'Stent re-expanded',
    },
    '122307': {
      display: 'Object removed',
    },
    '122308': {
      display: 'Radiation applied',
    },
    '122309': {
      display: 'Radiation removed',
    },
    '122310': {
      display: 'Interventional device placement unsuccessful',
    },
    '122311': {
      display: 'Interventional device placed',
    },
    '122312': {
      display: 'Intervention performed',
    },
    '122313': {
      display: 'Interventional device withdrawn',
    },
    '122319': {
      display: 'Catheter Size',
    },
    '122320': {
      display: 'Injectate Temperature',
    },
    '122321': {
      display: 'Injectate Volume',
    },
    '122322': {
      display: 'Calibration Factor',
    },
    '122325': {
      display: 'IVUS Report',
    },
    '122330': {
      display: 'EEM Diameter',
    },
    '122331': {
      display: 'Plaque Plus Media Thickness',
    },
    '122332': {
      display: 'Lumen Perimeter',
    },
    '122333': {
      display: 'EEM Cross-Sectional Area',
    },
    '122334': {
      display: 'Plaque plus Media Cross-Sectional Area',
    },
    '122335': {
      display: 'In-Stent Neointimal Cross-Sectional Area',
    },
    '122336': {
      display: 'Vascular Volume measurement length',
    },
    '122337': {
      display: 'Relative position',
    },
    '122339': {
      display: 'Stent Volume Obstruction',
    },
    '122340': {
      display: 'Fiducial feature',
    },
    '122341': {
      display: 'Calcium Length',
    },
    '122343': {
      display: 'Lumen Eccentricity Index',
    },
    '122344': {
      display: 'Plaque plus Media Eccentricity Index',
    },
    '122345': {
      display: 'Remodeling Index',
    },
    '122346': {
      display: 'Stent Symmetry Index',
    },
    '122347': {
      display: 'Stent Expansion Index',
    },
    '122348': {
      display: 'Lumen Shape Index',
    },
    '122350': {
      display: 'Lumen Diameter Ratio',
    },
    '122351': {
      display: 'Stent Diameter Ratio',
    },
    '122352': {
      display: 'EEM Diameter Ratio',
    },
    '122354': {
      display: 'Plaque Burden',
    },
    '122355': {
      display: 'Arc of Calcium',
    },
    '122356': {
      display: 'Soft plaque',
    },
    '122357': {
      display: 'In-Stent Neointima',
    },
    '122360': {
      display: 'True Lumen',
    },
    '122361': {
      display: 'False Lumen',
    },
    '122363': {
      display: 'Plaque Rupture',
    },
    '122364': {
      display: 'Stent Gap',
    },
    '122367': {
      display: 'T-1 Worst',
    },
    '122368': {
      display: 'T-2 Secondary',
    },
    '122369': {
      display: 'T-3 Secondary',
    },
    '122370': {
      display: 'T-4 Secondary',
    },
    '122371': {
      display: 'EEM Volume',
    },
    '122372': {
      display: 'Lumen Volume',
    },
    '122374': {
      display: 'In-Stent Neointimal Volume',
    },
    '122375': {
      display: 'Native Plaque Volume',
    },
    '122376': {
      display: 'Total Plaque Volume',
    },
    '122380': {
      display: 'Proximal Reference',
    },
    '122381': {
      display: 'Distal Reference',
    },
    '122382': {
      display: 'Site of Lumen Minimum',
    },
    '122383': {
      display: 'Entire Pullback',
    },
    '122384': {
      display: 'Stented Region',
    },
    '122385': {
      display: 'Proximal Stent Margin',
    },
    '122386': {
      display: 'Distal Stent Margin',
    },
    '122387': {
      display: 'Dissection Classification',
    },
    '122388': {
      display: 'Intra-stent Dissection',
    },
    '122389': {
      display: 'Vulnerable Plaque',
    },
    '122390': {
      display: 'Eroded Plaque',
    },
    '122391': {
      display: 'Relative Stenosis Severity',
    },
    '122393': {
      display: 'Restenotic Lesion',
    },
    '122394': {
      display: 'Fibro-Lipidic Plaque',
    },
    '122395': {
      display: 'Necrotic-Lipidic Plaque',
    },
    '122398': {
      display: 'Intimal Dissection',
    },
    '122399': {
      display: 'Medial Dissection',
    },
    '122400': {
      display: 'Simultaneously Acquired',
    },
    '122401': {
      display: 'Same Anatomy',
    },
    '122402': {
      display: 'Same Indication',
    },
    '122403': {
      display: 'For Attenuation Correction',
    },
    '122404': {
      display: 'Reconstructed',
    },
    '122405': {
      display: 'Algorithm Manufacturer',
    },
    '122406': {
      display: 'Left Atrial Ejection Fraction by Angiography',
    },
    '122407': {
      display: 'Left Atrial ED Volume',
    },
    '122408': {
      display: 'Left Atrial ES Volume',
    },
    '122410': {
      display: 'Contour Realignment',
    },
    '122411': {
      display: 'Threshold Value',
    },
    '122417': {
      display: 'Regional Abnormal Wall Motion',
    },
    '122421': {
      display: 'Calibration Object',
    },
    '122422': {
      display: 'Calibration Method',
    },
    '122423': {
      display: 'Calibration Object Size',
    },
    '122428': {
      display: 'Area Length Method',
    },
    '122429': {
      display: 'Volume Method',
    },
    '122430': {
      display: 'Reference Method',
    },
    '122431': {
      display: 'Regression Slope ED',
    },
    '122432': {
      display: 'Regression Offset ED',
    },
    '122433': {
      display: 'Regression Slope ES',
    },
    '122434': {
      display: 'Regression Offset ES',
    },
    '122435': {
      display: 'Regression Volume Exponent',
    },
    '122438': {
      display: 'Reference Points',
    },
    '122445': {
      display: 'Wall Thickness',
    },
    '122446': {
      display: 'Wall Volume',
    },
    '122447': {
      display: 'Wall Mass',
    },
    '122448': {
      display: 'Wall Stress',
    },
    '122449': {
      display: 'Centerline Wall Motion Analysis',
    },
    '122450': {
      display: 'Normalized Chord Length',
    },
    '122451': {
      display: 'Abnormal Region',
    },
    '122452': {
      display: 'First Chord of Abnormal Region',
    },
    '122453': {
      display: 'Last Chord of Abnormal Region',
    },
    '122459': {
      display: 'Territory Region Severity',
    },
    '122461': {
      display: 'Opposite Region Severity',
    },
    '122464': {
      display: 'LAD Region in RAO Projection',
    },
    '122465': {
      display: 'RCA Region in ROA Projection',
    },
    '122466': {
      display: 'Single LAD Region in RAO Projection',
    },
    '122467': {
      display: 'Single RCA Region in RAO Projection',
    },
    '122468': {
      display: 'Multiple LAD Region in RAO Projection',
    },
    '122469': {
      display: 'Multiple RCA Region in RAO Projection',
    },
    '122470': {
      display: 'LAD Region in LAO Projection',
    },
    '122471': {
      display: 'RCA Region in LAO Projection',
    },
    '122472': {
      display: 'CFX Region in LAO Projection',
    },
    '122473': {
      display: 'Circular Method',
    },
    '122474': {
      display: 'Densitometric Method',
    },
    '122475': {
      display: 'Center of Gravity',
    },
    '122476': {
      display: 'Long Axis Based',
    },
    '122477': {
      display: 'No Realignment',
    },
    '122480': {
      display: 'Vessel Lumen Cross-Sectional Area',
    },
    '122481': {
      display: 'Contour Start',
    },
    '122482': {
      display: 'Contour End',
    },
    '122485': {
      display: 'Sphere',
    },
    '122486': {
      display: 'Geometric Isocenter',
    },
    '122487': {
      display: 'Geometric Non-Isocenter',
    },
    '122488': {
      display: 'Calibration Object Used',
    },
    '122489': {
      display: 'Curve Fitted Reference',
    },
    '122490': {
      display: 'Interpolated Local Reference',
    },
    '122491': {
      display: 'Mean Local Reference',
    },
    '122493': {
      display: 'Radial Based Wall Motion Analysis',
    },
    '122495': {
      display: 'Regional Contribution to Ejection Fraction',
    },
    '122496': {
      display: 'Radial Shortening',
    },
    '122497': {
      display: 'Landmark Based Wall Motion Analysis',
    },
    '122498': {
      display: 'Slice Contribution to Ejection Fraction',
    },
    '122499': {
      display: 'Frame to Frame Analysis',
    },
    '122501': {
      display: 'Area of closed irregular polygon',
    },
    '122502': {
      display: 'Area of a closed NURBS',
    },
    '122503': {
      display: 'Integration of sum of closed areas on contiguous slices',
    },
    '122505': {
      display: 'Calibration',
    },
    '122507': {
      display: 'Left Contour',
    },
    '122508': {
      display: 'Right Contour',
    },
    '122509': {
      display: 'Diameter Graph',
    },
    '122510': {
      display: 'Length Luminal Segment',
    },
    '122511': {
      display: 'Graph Increment',
    },
    '122516': {
      display: 'Site of Maximum Luminal',
    },
    '122517': {
      display: 'Densitometric Luminal Cross-sectional Area Graph',
    },
    '122528': {
      display: 'Position of Proximal Border',
    },
    '122529': {
      display: 'Position of Distal Border',
    },
    '122542': {
      display: 'Plaque Area',
    },
    '122544': {
      display: 'Diameter Symmetry',
    },
    '122545': {
      display: 'Area Symmetry',
    },
    '122546': {
      display: 'Inflow Angle',
    },
    '122547': {
      display: 'Outflow Angle',
    },
    '122548': {
      display: 'Stenotic Flow Reserve',
    },
    '122549': {
      display: 'Poiseuille Resistance',
    },
    '122550': {
      display: 'Turbulence Resistance',
    },
    '122551': {
      display: 'Pressure Drop at SFR',
    },
    '122554': {
      display: 'Segmentation Method',
    },
    '122555': {
      display: 'Estimated Normal Flow',
    },
    '122558': {
      display: 'Area Length Kennedy',
    },
    '122559': {
      display: 'Area Length Dodge',
    },
    '122560': {
      display: 'Area Length Wynne',
    },
    '122562': {
      display: 'Multiple Slices',
    },
    '122563': {
      display: 'Boak',
    },
    '122564': {
      display: 'TS Pyramid',
    },
    '122565': {
      display: 'Two Chamber',
    },
    '122566': {
      display: 'Parallelepiped',
    },
    '122572': {
      display: 'BSA^1.219',
    },
    '122574': {
      display: 'Equidistant method',
    },
    '122575': {
      display: 'User selected method',
    },
    '122582': {
      display: 'Left ventricular posterobasal segment',
    },
    '122600': {
      display: 'Cardiovascular Analysis Report',
    },
    '122601': {
      display: 'Ventricular Analysis',
    },
    '122602': {
      display: 'Myocardial Perfusion Analysis',
    },
    '122603': {
      display: 'Calcium Scoring Analysis',
    },
    '122604': {
      display: 'Flow Quantification',
    },
    '122605': {
      display: 'Vascular Morphological Analysis',
    },
    '122606': {
      display: 'Vascular Functional Analysis',
    },
    '122607': {
      display: 'Thickening Analysis',
    },
    '122608': {
      display: 'Absolute Values Of Ventricular Measurements',
    },
    '122609': {
      display: 'Normalized Values Of Ventricular Measurements',
    },
    '122611': {
      display: 'Reference Point',
    },
    '122612': {
      display: 'Central breathing position',
    },
    '122616': {
      display: 'Peak Ejection Rate',
    },
    '122617': {
      display: 'Peak Ejection Time',
    },
    '122618': {
      display: 'Peak Filling Rate',
    },
    '122619': {
      display: 'Peak Filling Time',
    },
    '122620': {
      display: 'Papillary Muscle Excluded',
    },
    '122621': {
      display: 'Papillary Muscle Included',
    },
    '122624': {
      display: 'Wall Thickness Ratio end-systolic to end-diastolic',
    },
    '122627': {
      display: 'Curve Fit Method',
    },
    '122628': {
      display: 'Baseline Result Correction',
    },
    '122631': {
      display: 'Signal Earliest Peak Time',
    },
    '122633': {
      display: 'Signal Increase Start Time',
    },
    '122634': {
      display: 'Signal Time to Peak',
    },
    '122635': {
      display: 'MR Perfusion Peak',
    },
    '122636': {
      display: 'MR Perfusion Slope',
    },
    '122637': {
      display: 'MR Perfusion Time Integral',
    },
    '122638': {
      display: 'Signal Baseline Start',
    },
    '122639': {
      display: 'Signal Baseline End',
    },
    '122640': {
      display: 'Image Interval',
    },
    '122642': {
      display: 'Velocity Encoding Minimum Value',
    },
    '122643': {
      display: 'Velocity Encoding Maximum Value',
    },
    '122645': {
      display: 'Net Forward Volume',
    },
    '122650': {
      display: 'Area Based Method',
    },
    '122651': {
      display: 'Diameter Based Method',
    },
    '122652': {
      display: 'Volume Based Method',
    },
    '122655': {
      display: 'NASCET',
    },
    '122656': {
      display: 'ECST',
    },
    '122657': {
      display: 'Agatston Score Threshold',
    },
    '122658': {
      display: 'Calcium Mass Threshold',
    },
    '122659': {
      display: 'Calcium Scoring Calibration',
    },
    '122660': {
      display: 'Calcium Volume',
    },
    '122661': {
      display: 'Calcium Mass',
    },
    '122664': {
      display: 'Late Contrast Enhancement',
    },
    '122665': {
      display: 'Time interval since injection of contrast media',
    },
    '122666': {
      display: 'Time relative to R-wave peak',
    },
    '122667': {
      display: 'Blood velocity vs. time of cardiac cycle',
    },
    '122668': {
      display: 'Time interval since detection of contrast bolus',
    },
    '122670': {
      display: 'Papillary Muscle Included/Excluded',
    },
    '122675': {
      display: 'Anterior-Posterior',
    },
    '122680': {
      display: 'endoleak',
    },
    '122683': {
      display: 'Stent Fracture',
    },
    '122684': {
      display: 'Stent Disintegration',
    },
    '122685': {
      display: 'Stent Composition',
    },
    '122686': {
      display: 'Parent Vessel Finding',
    },
    '122687': {
      display: 'Site of Lumen Maximum',
    },
    '122698': {
      display: 'X-Concept',
    },
    '122699': {
      display: 'Y-Concept',
    },
    '122700': {
      display: 'Indications for Pharmacological Stress',
    },
    '122701': {
      display: 'Procedure time base',
    },
    '122702': {
      display: 'Treadmill speed',
    },
    '122703': {
      display: 'Treadmill gradient',
    },
    '122704': {
      display: 'Ergometer power',
    },
    '122705': {
      display: 'Pharmacological Stress Agent Dose Rate',
    },
    '122706': {
      display: 'Rating of Perceived Exertion',
    },
    '122707': {
      display: 'Number of Ectopic Beats',
    },
    '122708': {
      display: 'Double Product',
    },
    '122709': {
      display: 'Activity workload',
    },
    '122710': {
      display: 'Time since start of stage',
    },
    '122711': {
      display: 'Exercise duration after stress agent injection',
    },
    '122712': {
      display: 'Imaging Start Time',
    },
    '122713': {
      display: 'Attenuation correction method',
    },
    '122715': {
      display: 'Pharmacological Stress Agent Dose',
    },
    '122716': {
      display: 'Maximum Power Output Achieved',
    },
    '122717': {
      display: 'Peak activity workload',
    },
    '122718': {
      display: 'Peak Double Product',
    },
    '122720': {
      display: 'OSEM algorithm',
    },
    '122721': {
      display: 'Chang method',
    },
    '122726': {
      display: 'Algorithmic attenuation correction',
    },
    '122727': {
      display: 'NM transmission attenuation correction',
    },
    '122728': {
      display: 'CT-based attenuation correction',
    },
    '122729': {
      display: 'No Attenuation Correction',
    },
    '122730': {
      display: 'Bazett QTc Algorithm',
    },
    '122731': {
      display: 'Hodges QTc Algorithm',
    },
    '122732': {
      display: 'Fridericia QTc Algorithm',
    },
    '122733': {
      display: 'Framingham QTc Algorithm',
    },
    '122734': {
      display: 'Borg RPE Scale',
    },
    '122735': {
      display: 'Borg CR10 Scale',
    },
    '122739': {
      display: 'Overall study quality',
    },
    '122740': {
      display: 'Excellent image quality',
    },
    '122741': {
      display: 'Good image quality',
    },
    '122742': {
      display: 'Poor image quality',
    },
    '122743': {
      display: 'Body habitus attenuation',
    },
    '122744': {
      display: 'Breast attenuation',
    },
    '122745': {
      display: 'Diaphragmatic attenuation',
    },
    '122748': {
      display: 'False positive defect finding',
    },
    '122750': {
      display: 'Non-diagnostic - low heart rate',
    },
    '122751': {
      display: 'Non-diagnostic - resting ST abnormalities',
    },
    '122752': {
      display: 'Non-diagnostic - ventricular pacing or LBBB',
    },
    '122753': {
      display: 'Non-diagnostic ECG',
    },
    '122755': {
      display: 'Strongly positive',
    },
    '122756': {
      display: 'Strongly positive - ST elevation',
    },
    '122757': {
      display: 'ST Depression - Horizontal',
    },
    '122758': {
      display: 'ST Depression - Upsloping',
    },
    '122759': {
      display: 'ST Depression - Downsloping',
    },
    '122760': {
      display: 'Stress test score',
    },
    '122762': {
      display: 'Number of diseased vessel territories',
    },
    '122764': {
      display: 'Weight exceeds equipment limit',
    },
    '122768': {
      display: 'Difference in Ejection Fraction',
    },
    '122769': {
      display: 'Difference in ED LV Volume',
    },
    '122770': {
      display: 'Ratio of achieved to predicted maximal oxygen consumption',
    },
    '122771': {
      display: 'Ratio of achieved to predicted functional capacity',
    },
    '122772': {
      display: 'Aerobic index',
    },
    '122773': {
      display: 'ST/HR Index',
    },
    '122775': {
      display: 'Agreement with prior findings',
    },
    '122776': {
      display: 'Disagreement with prior findings',
    },
    '122781': {
      display: 'Rest thallium/stress technetium procedure',
    },
    '122782': {
      display: 'Rest technetium/stress technetium 1 day procedure',
    },
    '122783': {
      display: 'Rest technetium/stress technetium 2 day procedure',
    },
    '122784': {
      display: 'Stress technetium/rest technetium 1 day procedure',
    },
    '122785': {
      display: 'NM Myocardial Viability procedure',
    },
    '122791': {
      display: 'PET Myocardial Perfusion, Rest only',
    },
    '122792': {
      display: 'PET Myocardial Perfusion, Stress only',
    },
    '122793': {
      display: 'PET Myocardial Perfusion, Rest and Stress',
    },
    '122795': {
      display: 'PET Myocardial Viability, Rest only',
    },
    '122796': {
      display: 'PET Myocardial Viability, Stress only',
    },
    '122797': {
      display: 'PET Myocardial Viability, Rest and Stress',
    },
    '122799': {
      display: 'Anginal Equivalent',
    },
    '123001': {
      display: 'Radiopharmaceutical',
    },
    '123003': {
      display: 'Radiopharmaceutical Start Time',
    },
    '123004': {
      display: 'Radiopharmaceutical Stop Time',
    },
    '123005': {
      display: 'Radiopharmaceutical Volume',
    },
    '123006': {
      display: 'Radionuclide Total Dose',
    },
    '123007': {
      display: 'Radiopharmaceutical Specific Activity',
    },
    '123009': {
      display: 'Radionuclide Syringe Counts',
    },
    '123010': {
      display: 'Radionuclide Residual Syringe Counts',
    },
    '123011': {
      display: 'Contrast/Bolus Agent',
    },
    '123012': {
      display: 'Pre-Medication',
    },
    '123014': {
      display: 'Target Region',
    },
    '123015': {
      display: 'Imaging Direction',
    },
    '123016': {
      display: 'Imaging Conditions',
    },
    '123019': {
      display: 'Caudal 10 degree distal-cranioproximal oblique',
    },
    '123101': {
      display: 'Neighborhood Analysis',
    },
    '123102': {
      display: 'Adaptive Filtering',
    },
    '123103': {
      display: 'Edge Detection',
    },
    '123104': {
      display: 'Morphological Operations',
    },
    '123105': {
      display: 'Histogram Analysis',
    },
    '123106': {
      display: 'Multi-Scale/Resolution Filtering',
    },
    '123107': {
      display: 'Cluster Analysis',
    },
    '123108': {
      display: 'Multispectral Processing',
    },
    '123109': {
      display: 'Manual Processing',
    },
    '123110': {
      display: 'Artificial Intelligence',
    },
    '123111': {
      display: 'Deformable Models',
    },
    '125000': {
      display: 'OB-GYN Ultrasound Procedure Report',
    },
    '125001': {
      display: 'Fetal Biometry Ratios',
    },
    '125002': {
      display: 'Fetal Biometry',
    },
    '125003': {
      display: 'Fetal Long Bones',
    },
    '125004': {
      display: 'Fetal Cranium',
    },
    '125005': {
      display: 'Biometry Group',
    },
    '125006': {
      display: 'Biophysical Profile',
    },
    '125007': {
      display: 'Measurement Group',
    },
    '125008': {
      display: 'Fetus Summary',
    },
    '125009': {
      display: 'Early Gestation',
    },
    '125010': {
      display: 'Identifier',
    },
    '125011': {
      display: 'Pelvis and Uterus',
    },
    '125012': {
      display: 'Growth Percentile rank',
    },
    '125013': {
      display: 'Growth Z-score',
    },
    '125015': {
      display: 'Fetus Characteristics',
    },
    '125016': {
      display: 'Fetal Measurements',
    },
    '125021': {
      display: 'Frame of Reference Identity',
    },
    '125022': {
      display: 'Fiducial Alignment',
    },
    '125023': {
      display: 'Acquisition Equipment Alignment',
    },
    '125024': {
      display: 'Image Content-based Alignment',
    },
    '125025': {
      display: 'Visual Alignment',
    },
    '125030': {
      display: 'Inter-Hemispheric Plane',
    },
    '125031': {
      display: 'Right Hemisphere Most Anterior',
    },
    '125032': {
      display: 'Right Hemisphere Most Posterior',
    },
    '125033': {
      display: 'Right Hemisphere Most Superior',
    },
    '125034': {
      display: 'Right Hemisphere Most Inferior',
    },
    '125035': {
      display: 'Left Hemisphere Most Anterior',
    },
    '125036': {
      display: 'Left Hemisphere Most Posterior',
    },
    '125037': {
      display: 'Left Hemisphere Most Superior',
    },
    '125038': {
      display: 'Left Hemisphere Most Inferior',
    },
    '125040': {
      display: 'Background',
    },
    '125041': {
      display: 'Registration Input',
    },
    '125100': {
      display: 'Vascular Ultrasound Procedure Report',
    },
    '125101': {
      display: 'Vessel Branch',
    },
    '125102': {
      display: 'Graft Type',
    },
    '125105': {
      display: 'Measurement Orientation',
    },
    '125106': {
      display: 'Doppler Angle',
    },
    '125107': {
      display: 'Sample Volume Depth',
    },
    '125195': {
      display: 'Pediatric Cardiac Ultrasound Report',
    },
    '125196': {
      display: 'Fetal Cardiac Ultrasound Report',
    },
    '125197': {
      display: 'Adult Congenital Cardiac Ultrasound Report',
    },
    '125200': {
      display: 'Adult Echocardiography Procedure Report',
    },
    '125201': {
      display: 'Illustration of Finding',
    },
    '125202': {
      display: 'LV Wall Motion Score Index',
    },
    '125203': {
      display: 'Acquisition Protocol',
    },
    '125204': {
      display: 'Area-length biplane',
    },
    '125205': {
      display: 'Area-Length Single Plane',
    },
    '125206': {
      display: 'Cube',
    },
    '125207': {
      display: 'Method of Disks, Biplane',
    },
    '125208': {
      display: 'Method of Disks, Single Plane',
    },
    '125209': {
      display: 'Teichholz',
    },
    '125210': {
      display: 'Area by Pressure Half-Time',
    },
    '125211': {
      display: 'Biplane Ellipse',
    },
    '125212': {
      display: 'Continuity Equation',
    },
    '125213': {
      display: 'Continuity Equation by Mean Velocity',
    },
    '125214': {
      display: 'Continuity Equation by Peak Velocity',
    },
    '125215': {
      display: 'Continuity Equation by Velocity Time Integral',
    },
    '125216': {
      display: 'Proximal Isovelocity Surface Area',
    },
    '125217': {
      display: 'Full Bernoulli',
    },
    '125218': {
      display: 'Simplified Bernoulli',
    },
    '125219': {
      display: 'Doppler Volume Flow',
    },
    '125220': {
      display: 'Planimetry',
    },
    '125221': {
      display: 'Left Ventricle Mass by M-mode',
    },
    '125222': {
      display: 'Left Ventricle Mass by Truncated Ellipse',
    },
    '125223': {
      display: '4 Point Segment Finding Scale',
    },
    '125224': {
      display: '5 Point Segment Finding Scale',
    },
    '125225': {
      display: '5 Point Segment Finding Scale With Graded Hypokinesis',
    },
    '125226': {
      display: 'Single Plane Ellipse',
    },
    '125227': {
      display: 'Modified Simpson',
    },
    '125228': {
      display: 'Bullet Method',
    },
    '125230': {
      display: 'Power Doppler',
    },
    '125231': {
      display: '3D mode',
    },
    '125233': {
      display: 'Start of drug dose administration',
    },
    '125234': {
      display: 'Start of contrast agent administration',
    },
    '125235': {
      display: 'Destruction of microbubbles',
    },
    '125236': {
      display: 'Onset of exercise',
    },
    '125237': {
      display: 'Cessation of exercise',
    },
    '125238': {
      display: 'Onset of stimulation',
    },
    '125239': {
      display: 'Cessation of stimulation',
    },
    '125240': {
      display: 'Line scan pattern',
    },
    '125241': {
      display: 'Plane scan pattern',
    },
    '125242': {
      display: 'Volume scan pattern',
    },
    '125251': {
      display: 'Non-imaging Doppler ultrasound transducer geometry',
    },
    '125252': {
      display: 'Linear ultrasound transducer geometry',
    },
    '125253': {
      display: 'Curved linear ultrasound transducer geometry',
    },
    '125254': {
      display: 'Sector ultrasound transducer geometry',
    },
    '125255': {
      display: 'Radial ultrasound transducer geometry',
    },
    '125256': {
      display: 'Ring ultrasound transducer geometry',
    },
    '125257': {
      display: 'Fixed beam direction',
    },
    '125258': {
      display: 'Mechanical beam steering',
    },
    '125259': {
      display: 'Phased beam steering',
    },
    '125261': {
      display: 'External Transducer',
    },
    '125262': {
      display: 'Transesophageal Transducer',
    },
    '125263': {
      display: 'Endovaginal Transducer',
    },
    '125264': {
      display: 'Endorectal Transducer',
    },
    '125265': {
      display: 'Intravascular Transducer',
    },
    '125270': {
      display: 'Left Ventricle Mass by Area Length',
    },
    '125271': {
      display: 'Left Ventricle Mass by M-mode - adjusted by Height',
    },
    '125272': {
      display: 'Left Ventricle Mass by Truncated Ellipse - adjusted by Height',
    },
    '125273': {
      display: 'Left Ventricle Mass by Area Length - adjusted by Height',
    },
    '125901': {
      display: 'CARDIOsphere',
    },
    '125902': {
      display: 'Echovist',
    },
    '125903': {
      display: 'Imagify',
    },
    '125904': {
      display: 'Levovist',
    },
    '125905': {
      display: 'Sonazoid',
    },
    '125906': {
      display: 'SonoVue',
    },
    '125907': {
      display: 'Targestar-B',
    },
    '125908': {
      display: 'Targestar-P',
    },
    '126000': {
      display: 'Imaging Measurement Report',
    },
    '126001': {
      display: 'Oncology Measurement Report',
    },
    '126002': {
      display: 'Dynamic Contrast MR Measurement Report',
    },
    '126003': {
      display: 'PET Measurement Report',
    },
    '126010': {
      display: 'Imaging Measurements',
    },
    '126011': {
      display: 'Derived Imaging Measurements',
    },
    '126020': {
      display: 'Multiparametric MRI',
    },
    '126021': {
      display: 'Multiparametric MRI of prostate',
    },
    '126022': {
      display: 'Multiparametric MRI of whole body',
    },
    '126030': {
      display: 'Sum of segmented voxel volumes',
    },
    '126031': {
      display: 'Peak Value Within ROI',
    },
    '126032': {
      display: 'Metabolic Volume',
    },
    '126033': {
      display: 'Total Lesion Glycolysis',
    },
    '126034': {
      display: 'Glycolysis',
    },
    '126035': {
      display: 'Total Lesion Proliferation',
    },
    '126036': {
      display: 'Proliferative Activity',
    },
    '126037': {
      display: 'Standardized Added Metabolic Activity (SAM)',
    },
    '126038': {
      display: 'Standardized Added Metabolic Activity (SAM) Background',
    },
    '126039': {
      display: 'Lesion to Background SUV Ratio',
    },
    '126040': {
      display: 'Background for Lesion to Background SUV Ratio',
    },
    '126050': {
      display: 'Fractal Dimension',
    },
    '126051': {
      display: 'Skewness',
    },
    '126052': {
      display: 'Kurtosis',
    },
    '126060': {
      display: 'Entropy of GLCM',
    },
    '126061': {
      display: 'Energy of GLCM',
    },
    '126062': {
      display: 'Homogeneity of GLCM',
    },
    '126063': {
      display: 'Contrast of GLCM',
    },
    '126064': {
      display: 'Dissimilarity of GLCM',
    },
    '126065': {
      display: 'ASM of GLCM',
    },
    '126066': {
      display: 'Correlation of GLCM',
    },
    '126067': {
      display: 'Gray Level Co-occurrence Matrix (GLCM)',
    },
    '126070': {
      display: 'Subject Time Point Identifier',
    },
    '126071': {
      display: 'Protocol Time Point Identifier',
    },
    '126072': {
      display: 'Time Point Type',
    },
    '126073': {
      display: 'Time Point Order',
    },
    '126074': {
      display: 'Posttreatment',
    },
    '126075': {
      display: 'Eligibility',
    },
    '126080': {
      display: 'RECIST 1.0',
    },
    '126081': {
      display: 'RECIST 1.1',
    },
    '126100': {
      display: 'Real World Value Map used for measurement',
    },
    '126200': {
      display: 'Image Library Group',
    },
    '126201': {
      display: 'Acquisition Date',
    },
    '126202': {
      display: 'Acquisition Time',
    },
    '126203': {
      display: 'PET Radionuclide Incubation Time',
    },
    '126220': {
      display: 'R2-Coefficient',
    },
    '126300': {
      display: 'Perfusion analysis by Stable Xenon CT technique',
    },
    '126301': {
      display: 'Perfusion analysis by IV Iodinated Contrast CT technique',
    },
    '126302': {
      display: 'Perfusion analysis by Arterial Spin Labeling MR technique',
    },
    '126303': {
      display: 'Perfusion analysis by Susceptibility MR technique',
    },
    '126310': {
      display: 'Least Mean Square (LMS) deconvolution',
    },
    '126311': {
      display: 'Singular Value Decomposition (SVD) deconvolution',
    },
    '126312': {
      display: 'Ktrans',
    },
    '126313': {
      display: 'kep',
    },
    '126314': {
      display: 've',
    },
    '126320': {
      display: 'IAUC',
    },
    '126321': {
      display: 'IAUC60',
    },
    '126322': {
      display: 'IAUC90',
    },
    '126330': {
      display: 'tau_m',
    },
    '126331': {
      display: 'vp',
    },
    '126340': {
      display: 'Standard Tofts Model',
    },
    '126341': {
      display: 'Extended Tofts Model',
    },
    '126342': {
      display: 'Model-free concentration-time quantitification',
    },
    '126343': {
      display: 'First Pass Leakage Profile (FPLP)',
    },
    '126344': {
      display: 'Shutter-Speed Model (SSM)',
    },
    '126350': {
      display: 'T1 by Multiple Flip Angles',
    },
    '126351': {
      display: 'T1 by Inversion Recovery',
    },
    '126352': {
      display: 'T1 by Fixed Value',
    },
    '126353': {
      display: 'T1 Used For Calculation',
    },
    '126360': {
      display: 'AIF Ignored',
    },
    '126361': {
      display: 'Population Averaged AIF',
    },
    '126362': {
      display: 'User-defined AIF ROI',
    },
    '126363': {
      display: 'Automatically Detected AIF ROI',
    },
    '126364': {
      display: 'Blind Estimation of AIF',
    },
    '126370': {
      display: 'Time of Peak Concentration',
    },
    '126371': {
      display: 'Bolus Arrival Time',
    },
    '126372': {
      display: 'Time of Leading Half-Peak Concentration',
    },
    '126373': {
      display: 'Temporal Derivative Exceeds Threshold',
    },
    '126374': {
      display: 'Temporal Derivative Threshold',
    },
    '126375': {
      display: 'Maximum Slope',
    },
    '126376': {
      display: 'Maximum Difference',
    },
    '126377': {
      display: 'Tracer Concentration',
    },
    '126380': {
      display: 'Contrast Longitudinal Relaxivity',
    },
    '126390': {
      display: 'Regional Blood Flow',
    },
    '126391': {
      display: 'Regional Blood Volume',
    },
    '126392': {
      display: 'Oxygen Extraction Fraction',
    },
    '126393': {
      display: 'R1',
    },
    '126394': {
      display: 'R2',
    },
    '126400': {
      display: 'Standardized Uptake Value',
    },
    '126401': {
      display: 'SUVbw',
    },
    '126402': {
      display: 'SUVlbm',
    },
    '126403': {
      display: 'SUVbsa',
    },
    '126404': {
      display: 'SUVibw',
    },
    '126410': {
      display: 'SUV body weight calculation method',
    },
    '126411': {
      display: 'SUV lean body mass calculation method',
    },
    '126412': {
      display: 'SUV body surface area calculation method',
    },
    '126413': {
      display: 'SUV ideal body weight calculation method',
    },
    '126500': {
      display: 'Pittsburgh compound B C^11^',
    },
    '126501': {
      display: 'Florbetaben F^18^',
    },
    '126502': {
      display: 'T807 F^18^',
    },
    '126503': {
      display: 'Flubatine F^18^',
    },
    '126510': {
      display: 'Monoclonal Antibody (mAb) ^64^Cu',
    },
    '126511': {
      display: 'Monoclonal Antibody (mAb) ^89^Zr',
    },
    '126512': {
      display: 'Trastuzumab ^89^Zr',
    },
    '126513': {
      display: 'Cetuximab ^89^Zr',
    },
    '126514': {
      display: 'J591 ^89^Zr',
    },
    '126515': {
      display: 'cU36 ^89^Zr',
    },
    '126516': {
      display: 'Bevacizumab ^89^Zr',
    },
    '126517': {
      display: "cG250-F(ab')(2) ^89^Zr",
    },
    '126518': {
      display: 'R1507 ^89^Zr',
    },
    '126519': {
      display: 'E4G10 ^89^Zr',
    },
    '126520': {
      display: 'Df-CD45 ^89^Zr',
    },
    '126600': {
      display: '^44^Scandium',
    },
    '126601': {
      display: '^51^Manganese',
    },
    '126602': {
      display: '^70^Arsenic',
    },
    '126603': {
      display: '^90^Niobium',
    },
    '126604': {
      display: '^191m^Iridium',
    },
    '126605': {
      display: '^43^Scandium',
    },
    '126606': {
      display: '^152^Terbium',
    },
    '126700': {
      display: 'ATSM Cu^60^',
    },
    '126701': {
      display: 'ATSM Cu^61^',
    },
    '126702': {
      display: 'ATSM Cu^62^',
    },
    '126703': {
      display: 'Choline C^11^',
    },
    '126704': {
      display: 'Fallypride C^11^',
    },
    '126705': {
      display: 'Fallypride F^18^',
    },
    '126706': {
      display: 'FLB 457 C^11^',
    },
    '126707': {
      display: 'Fluorotriopride F^18^',
    },
    '126708': {
      display: 'Fluoromisonidazole (FMISO) F^18^',
    },
    '126709': {
      display: 'Glutamine C^11^',
    },
    '126710': {
      display: 'Glutamine C^14^',
    },
    '126711': {
      display: 'Glutamine F^18^',
    },
    '126712': {
      display: 'Flubatine F^18^',
    },
    '126713': {
      display: '2FA F^18^',
    },
    '126714': {
      display: 'Nifene F^18^',
    },
    '126715': {
      display: 'CLR1404 I^124^',
    },
    '126716': {
      display: 'CLR1404 I^131^',
    },
    '126801': {
      display: 'IEC6127 Patient Support Continuous Angle',
    },
    '126802': {
      display: 'IEC6127 Table Top Continuous Pitch Angle',
    },
    '126803': {
      display: 'IEC6127 Table Top Continuous Roll Angle',
    },
    '126804': {
      display: 'IEC6127 Table Top Eccentric Axis Distance',
    },
    '126805': {
      display: 'IEC6127 Table Top Continuous Eccentric Angle',
    },
    '126806': {
      display: 'IEC6127 Table Top Lateral Position',
    },
    '126807': {
      display: 'IEC6127 Table Top Longitudinal Position',
    },
    '126808': {
      display: 'IEC6127 Table Top Vertical Position',
    },
    '126809': {
      display: 'IEC6127 Gantry Continuous Roll Angle',
    },
    '126810': {
      display: 'IEC6127 Gantry Continuous Pitch Angle',
    },
    '126811': {
      display: 'IEC6127 Gantry Continuous Yaw Angle',
    },
  },
};
