import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const JuridischeStatusCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.14.3.2--20171231000000',
  systems: {
    'urn:oid:2.16.840.1.113883.2.4.3.11.22.232': {
      '1': {
        'nl-NL': 'Rechterlijke machtiging (rm) voorlopig / voortgezette machtiging',
        display: 'Rechterlijke machtiging (rm) voorlopig / voortgezette machtiging',
      },
      '2': {
        'nl-NL': 'RM op eigen verzoek',
        display: 'RM op eigen verzoek',
      },
      '3': {
        'nl-NL': 'RM met voorwaardelijk ontslag',
        display: 'RM met voorwaardelijk ontslag',
      },
      '4': {
        'nl-NL': 'Onder toezichtstelling (ots)',
        display: 'Onder toezichtstelling (ots)',
      },
      '5': {
        'nl-NL': 'In bewaringstelling (ibs)',
        display: 'In bewaringstelling (ibs)',
      },
      '6': {
        'nl-NL': 'Voogdij',
        display: 'Voogdij',
      },
      '7': {
        'nl-NL': 'Bewindvoering',
        display: 'Bewindvoering',
      },
      '8': {
        'nl-NL': 'Mentorschap',
        display: 'Mentorschap',
      },
      '9': {
        'nl-NL': 'Onder curatele stelling',
        display: 'Onder curatele stelling',
      },
      '10': {
        'nl-NL': 'Zaakwaarneming',
        display: 'Zaakwaarneming',
      },
      '11': {
        'nl-NL': 'Strafrechterlijke justitiële contacten: tbs',
        display: 'Strafrechterlijke justitiële contacten: tbs',
      },
      '12': {
        'nl-NL': 'Strafrechterlijke justitiële contacten: overig',
        display: 'Strafrechterlijke justitiële contacten: overig',
      },
    },
  },
};
