import { CompositionEvent } from '@hl7fhir';
import { CodeableConceptPipe, PeriodTypePipe } from '@hl7fhir/data-types';
import { getReferences } from '@hl7fhir/foundation';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class CompositionEventViewModel extends ElementViewModel<CompositionEvent> {
  get code(): string | undefined {
    const compositionEvent = this.element as r3.CompositionEvent | r4.CompositionEvent | r4b.CompositionEvent;
    return compositionEvent?.code && new CodeableConceptPipe().transform(compositionEvent?.code);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element?.period);
  }

  get detail(): string | undefined {
    const compositionEvent = this.element as r3.CompositionEvent | r4.CompositionEvent | r4b.CompositionEvent;
    return compositionEvent?.detail && getReferences(compositionEvent?.detail);
  }
}
