import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../../features';
import { reducer } from './practitioner-reducer';
import { PractitionerEffects } from './practitioner.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(features.FHIR.practitioners, reducer),
    EffectsModule.forFeature([PractitionerEffects]),
  ],
})
export class PractitionerStoreModule {}
