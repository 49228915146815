import { HttpResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import {
  AccountsResponse,
  AuthorizationDetails,
  CAFileListResponse,
  PortabilityReport,
  ReadAccountsResponse,
  ReauthorizationDetails,
  SourceType,
  UserAD,
  UserResponse,
} from '@digi.me/models';
import { Observation } from '@hl7fhir';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export type Trigger = 'all' | 'loading' | string | undefined;

export const CLAIM_API_ACTIONS = createActionGroup({
  source: 'Digi.me Claim Api',
  events: {
    'Claim Requested': props<{ library: string }>(),
    'Claim Load Succeeded': emptyProps(),
    'Claim Load Failed': props<{ error: any }>(),
  },
});

export const AUTHORIZE_URL_API_ACTIONS = createActionGroup({
  source: 'Digi.me AuthorizeUrl Api',
  events: {
    'Authorize Url Requested': props<{ createNewUser: boolean; sourceFetch: boolean; sourceType: SourceType }>(),
    'Authorize Url Load Succeeded': props<{ url: AuthorizationDetails }>(),
    'Authorize Url Load Failed': props<{ error: any }>(),
  },
});

export const REVOKE_URL_API_ACTIONS = createActionGroup({
  source: 'Digi.me Revoke Api',
  events: {
    'Revoke Requested': props<{ accountId: string }>(),
    'Revoke Load Succeeded': props<{ url: string }>(),
    'Revoke Load Failed': props<{ error: any }>(),
  },
});

export const REAUTHORIZE_URL_API_ACTIONS = createActionGroup({
  source: 'Digi.me Reauthorize Api',
  events: {
    'Reauthorize Requested': props<{ accountId: string }>(),
    'Reauthorize Load Succeeded': props<{ details: ReauthorizationDetails }>(),
    'Reauthorize Load Failed': props<{ error: any }>(),
  },
});

export const PORTABILITY_REPORT_API_ACTIONS = createActionGroup({
  source: 'Digi.me Portability Report Api',
  events: {
    'Portability Report Requested': props<{ from?: number; to?: number }>(),
    'Portability Report Load Succeeded': props<{ report: PortabilityReport }>(),
    'Portability Report Load Failed': props<{ error: any }>(),
  },
});

export const READ_ACCOUNTS_API_ACTIONS = createActionGroup({
  source: 'Digi.me Accounts Api',
  events: {
    'Accounts Requested': emptyProps(),
    'Accounts Load Succeeded': props<{ accounts: ReadAccountsResponse }>(),
    'Accounts Load Failed': props<{ error: any }>(),
  },
});

export const FILES_API_ACTIONS = createActionGroup({
  source: 'Digi.me Files Api',
  events: {
    'Files Requested': props<{ sourceFetch: boolean; trigger: Trigger; accountId?: string }>(),
    'Files Load Succeeded': props<{ sourceFetch: boolean; trigger: Trigger }>(),
    'Files Load Failed': props<{ error: any }>(),
  },
});

export const FILE_LIST_API_ACTIONS = createActionGroup({
  source: 'Digi.me File List Api',
  events: {
    'File List Requested': emptyProps(),
    'File List Load Succeeded': props<{ fileList: CAFileListResponse }>(),
    'File List Load Failed': props<{ error: any }>(),
  },
});

export const RESET_API_ACTIONS = createActionGroup({
  source: 'Digi.me Reset Api',
  events: {
    'Reset Requested': emptyProps(),
    'Reset Succeeded': emptyProps(),
    'Reset Failed': props<{ error: any }>(),
  },
});

export const USER_API_ACTIONS = createActionGroup({
  source: 'Digi.me User Api',
  events: {
    'User Data Requested': props<{ sourceFetch: boolean; trigger: Trigger }>(),
    'User Data Load Succeeded': props<{ user: UserResponse; trigger: Trigger }>(),
    'User Data Load Failed': props<{ error: any }>(),
  },
});

export const SIGN_UP_API_ACTIONS = createActionGroup({
  source: 'Azure B2C Sign Up',
  events: {
    'Sign Up Started': props<{ library: string }>(),
    'Sign Up Asked': props<{ library: string }>(),
  },
});

export const CANCEL_SIGN_UP_API_ACTIONS = createActionGroup({
  source: 'Cancel Azure B2C Sign Up',
  events: {
    'Cancel Sign Up Started': emptyProps(),
    'Cancel Sign Up Succeeded': emptyProps(),
  },
});

/**
 * The set of action that can be dispatch after a return from the Digi.me Saas platform.
 *
 * Authorize: https://digime.github.io/digime-sdk-nodejs/pages/fundamentals/authorize.html
 * Onboard: https://digime.github.io/digime-sdk-nodejs/pages/fundamentals/onboard.html
 * Reauthorize: https://digime.github.io/digime-sdk-nodejs/pages/fundamentals/reauthorize.html
 */
export const DIGI_ME_SAAS_RETURN_ACTIONS = createActionGroup({
  source: 'Digi.me Saas Return',
  events: {
    'Authorize Succeeded': props<{ params: Params }>(),
    'Authorize Failed': props<{ params: Params }>(),
    'Onboard Succeeded': props<{ params: Params }>(),
    'Reauthorize Completed': props<{ params: Params }>(),
    'Revoke Completed': props<{ params: Params }>(), // TODO: Use revoke when we switch to different return components,
    'Manage Permissions Cancelled': emptyProps(),
  },
});

export const EXCHANGE_CODE_FOR_TOKEN_API_ACTIONS = createActionGroup({
  source: 'Digi.me Exchange Code For Token',
  events: {
    'Exchange Code For Token Failed': props<{ httpResponse: HttpResponse<any> }>(),
  },
});

export const RESET_UI_ACTIONS = createActionGroup({
  source: 'Digi.me Reset UI',
  events: {
    'Reset Warning Requested': emptyProps(),
    'Reset Warning Dismissed': emptyProps(),
  },
});

export const CLEAR_UI_DATA_ACTIONS = createActionGroup({
  source: 'Digi.me Clear UI Data',
  events: {
    'Clear Data Requested': emptyProps(),
  },
});

export const DELETE_API_ACTIONS = createActionGroup({
  source: 'Digi.me Delete Api',
  events: {
    'Delete Requested': emptyProps(),
    'Delete Succeeded': emptyProps(),
    'Delete Failed': props<{ error: any }>(),
  },
});

export const DELETE_ACCOUNT_API_ACTIONS = createActionGroup({
  source: 'Digi.me Delete Account Api',
  events: {
    'Delete Account Requested': props<{ accountId: string }>(),
    'Delete Account Succeeded': emptyProps(),
    'Delete Account Failed': props<{ error: any }>(),
  },
});

export const UPDATE_AD_USER_API_ACTIONS = createActionGroup({
  source: 'Digi.me Update AD User Api',
  events: {
    'Update AD User Requested': props<UserAD>(),
    'Update AD User Succeeded': props<UserAD>(),
    'Update AD User Failed': props<{ error: any }>(),
  },
});

export const USER_AD_API_ACTIONS = createActionGroup({
  source: 'Digi.me User AD Api',
  events: {
    'User AD Requested': emptyProps(),
    'User AD Succeeded': props<UserAD>(),
    'User AD Failed': props<{ error: any }>(),
  },
});

export const DELETE_UI_ACTIONS = createActionGroup({
  source: 'Digi.me Delete UI',
  events: {
    'Delete Warning Requested': emptyProps(),
    'Delete Warning Dismissed': emptyProps(),
  },
});

export const MEASUREMENTS_UI_ACTIONS = createActionGroup({
  source: 'Measurements UI',
  events: {
    Add: props<{ measurement: Observation }>(),
  },
});

export const SHARE_MEASUREMENTS = createActionGroup({
  source: 'Share MEASUREMENTS UI',
  events: {
    'Find Account Succeeded': props<{ account: AccountsResponse }>(),
    Reset: emptyProps(),
  },
});

export const BINARY_ACTIONS = createActionGroup({
  source: 'Binary Download',
  events: {
    Requested: props<{ id: string }>(),
  },
});
