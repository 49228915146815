<app-menu-navbar></app-menu-navbar>
<router-outlet></router-outlet>

<app-digi-me-reset [resetting]="isResetting$ | async"
                   [showWarning]="showResetWarning$ | async"></app-digi-me-reset>

<app-account-deletion [showWarning]="showAccountDeleteWaring$ | async"
                      [showDeleting]="isAccountDeleting$ | async"
                      [showDeleted]="isAccountDeleted$ | async"></app-account-deletion>
<app-error-modal [errorMessage]="errorMessage$ | async"></app-error-modal>
<app-navigator-on-line></app-navigator-on-line>