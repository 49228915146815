import { Pipe, PipeTransform } from '@angular/core';

// TODO Add tests
@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: any, field: string): any[] | undefined {
    if (!Array.isArray(array)) {
      return undefined;
    }

    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });

    return array;
  }
}
