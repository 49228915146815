import { Pipe, PipeTransform } from '@angular/core';
import { HumanName } from '@hl7fhir';
import { StructureDefinition } from '@hl7fhir/structure-definitions';

@Pipe({
  name: 'family',
})
export class FamilyNamePipe implements PipeTransform {
  transform(humanName: HumanName | undefined, code: string) {
    if (!humanName || !humanName._family) {
      return undefined;
    }

    return humanName._family.extension
      ?.flat()
      ?.find((extension) => extension?.url === `${StructureDefinition.Hl7.STRUCTURE_DEFINITION}/humanname-${code}`)
      ?.valueString;
  }
}
