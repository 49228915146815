import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { MedicationDispensePerformer } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';

export class MedicationDispensePerformerViewModel extends BackboneElementViewModel<MedicationDispensePerformer> {
  get actor(): string | undefined {
    return this.element?.actor && getReference(this.element?.actor);
  }

  get onBehalfOf(): string | undefined {
    const elementR3 = this.element as r3.MedicationDispensePerformer | undefined;
    return elementR3 && getReference(elementR3.onBehalfOf);
  }

  get function(): string | undefined {
    const elementR = this.element as
      | r4.MedicationDispensePerformer
      | r4b.MedicationDispensePerformer
      | r5.MedicationDispensePerformer
      | undefined;
    return elementR && new CodeableConceptPipe().transform(elementR);
  }
}
