import { formatDate } from '@angular/common';
const isCypress = (<any>window).Cypress;

export function formatLocaleDate(date: string | undefined, format: 'short' | 'medium' | 'long' | ''): string {
  if (!date || !format) {
    return '';
  }

  // Only render date if there is no time present
  let renderFormat: string = format;
  if (!date.includes('T')) {
    renderFormat = format + 'Date';
  }
  const parsedDate = Date.parse(date);
  // If there is timezone information render in local time
  return formatDate(parsedDate, renderFormat, $localize.locale ?? 'en-US', isCypress ? 'UTC+0' : undefined);
}

export function comparatorDate(valueA: string, valueB: string): number {
  function parseDate(dateValue: string): Date | null {
    const parts = dateValue.split(' - ');
    const lastPart = parts[parts.length - 1];

    const dateMatch = lastPart.match(/(\d{4}-\d{2}-\d{2})(?: at | om )?(\d{2}:\d{2})?/);
    if (dateMatch) {
      const [_, datePart, timePart] = dateMatch;
      const dateTime = timePart ? `${datePart}T${timePart}` : datePart;
      return new Date(dateTime);
    }

    return new Date(lastPart.replace(' at ', ' ').replace(' om ', ' '));
  }

  const dateA = parseDate(valueA);
  const dateB = parseDate(valueB);

  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;

  if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
  if (isNaN(dateA.getTime())) return 1;
  if (isNaN(dateB.getTime())) return -1;

  return dateA.getTime() - dateB.getTime();
}

/**
 * Returns the start of the given day.
 * The time is set to 00:00:00.000.
 * @param date The date for which to get the start of the day. If no date is provided, the current date is used.
 * @returns {Date} The start of the given day.
 */
export function getStartOfDay(date: Date = new Date()): Date {
  const startOfGivenDay = new Date(date);
  startOfGivenDay.setHours(0, 0, 0, 0);

  return startOfGivenDay;
}

/**
 * Returns the end of the given day.
 * The time is set to 23:59:59.999.
 * @param date The date for which to get the end of the day. If no date is provided, the current date is used.
 * @returns {Date} The end of the given day.
 */
export function getEndDay(date: Date = new Date()): Date {
  const endOfGivenDay = new Date(date);
  endOfGivenDay.setHours(23, 59, 59, 999);

  return endOfGivenDay;
}

/**
 * Returns the first day (Monday) of the week for a given date.
 * @param {Date} date The date for which to get the first day of the week. If no date is provided, the current date is used.
 * @returns The first day of the week.
 */
export function getFirstDayOfWeek(date: Date = new Date()): Date {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  const firstDayOfWeek = new Date(date.setDate(diff));
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
}

/**
 * Returns the first day of the month for the given date.
 * @param {Date} date The date for which to get the first day of the month.  If no date is provided, the current date is used.
 * @returns {Date} The first day of the month.
 */
export function getFirstDayOfMonth(date: Date = new Date()): Date {
  const givenDay = new Date(date);
  const firstDayOfMonth = new Date(givenDay.getFullYear(), givenDay.getMonth(), 1);

  return firstDayOfMonth;
}

/**
 * Returns the first day of the year for the given date.
 * @param {Date} date The date for which to get the first day of the year. If no date is provided, the current date is used.
 * @returns {Date} The first day of the year.
 */
export function getFirstDayOfYear(date: Date = new Date()): Date {
  return new Date(date.getFullYear(), 0, 1);
}

/**
 * Returns the current date in the format 'YYYY-MM-DD'.
 *
 * @returns {string} The current date.
 */
export function getCurrentDate(): string {
  return new Date().toISOString().split('T')[0];
}
