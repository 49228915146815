import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from '@hl7fhir';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(duration: Duration | undefined): string | undefined {
    if (!duration) {
      return '';
    }

    return duration.value ? `${duration.value}${duration.unit ? ` ${duration.unit}` : ''}` : '';
  }
}
