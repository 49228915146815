import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { getReference } from '@hl7fhir/foundation';
import * as r3 from 'fhir/r3';

export class ImmunizationRecommendationRecommendationProtocolViewModel extends BackboneElementViewModel<r3.ImmunizationRecommendationRecommendationProtocol> {
  get doseSequence(): string | undefined {
    return this.element?.doseSequence?.toString();
  }

  get description(): string | undefined {
    return this.element?.description;
  }

  get authority(): string | undefined {
    return this.element?.authority && getReference(this.element.authority);
  }

  get series(): string | undefined {
    return this.element?.series;
  }
}
