import { DocumentManifest } from '@hl7fhir';
import { DocumentManifestViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromDocumentManifest from './document-manifest.reduce';

export const selectDocumentManifestState = createFeatureSelector<fromDocumentManifest.State>(
  features.FHIR.documentManifest
);

export const selectDocumentManifestEntities = createSelector(
  selectDocumentManifestState,
  fromDocumentManifest.selectDocumentManifestEntities
);

export const selectAllDocumentManifests = createSelector(
  selectDocumentManifestState,
  fromDocumentManifest.selectAllDocumentManifests
);

export const selectDocumentManifestById = createSelector(
  selectRouteParams,
  selectDocumentManifestEntities,
  ({ id }, documentManifests: Dictionary<DocumentManifest>) =>
    id && (documentManifests[id] ?? documentManifests[id.toLowerCase()] ?? documentManifests[id.toUpperCase()])
);

export const selectAllDocumentManifestViewModels = createSelector(
  selectAllDocumentManifests,
  (documentManifests: DocumentManifest[]) =>
    documentManifests.map((documentManifest) => new DocumentManifestViewModel(documentManifest))
);

export const selectDocumentManifestViewModelById = createSelector(
  selectDocumentManifestById,
  (entity: DocumentManifest | undefined) => entity && new DocumentManifestViewModel(entity)
);
