import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const RoleCodeNLZorgverlenertypen: CodeSystemFlattened = {
  id: '69dff0a9-2ba4-4f4e-aea7-b3b42303cc63',
  codes: {
    AssignedRoleType: {
      display:
        'Een roltype wordt gebruikt om een entiteit verder aan te duiden, die een rol speelt met AssignedEntity als modelattribuut RoleClass.',
    },
    '01.000': {
      display: 'Arts',
    },
    '01.002': {
      display: 'Allergoloog (gesloten register)',
    },
    '01.003': {
      display: 'Anesthesioloog',
    },
    '01.004': {
      display: 'Apotheekhoudende huisarts',
    },
    '01.008': {
      display: 'Bedrijfsarts',
    },
    '01.010': {
      display: 'Cardioloog',
    },
    '01.011': {
      display: 'Cardiothoracaal chirurg',
    },
    '01.012': {
      display: 'Dermatoloog',
    },
    '01.013': {
      display: 'Arts v. maag-darm-leverziekten',
    },
    '01.014': {
      display: 'Chirurg',
    },
    '01.015': {
      display: 'Huisarts',
    },
    '01.016': {
      display: 'Internist',
    },
    '01.018': {
      display: 'Keel- neus- en oorarts',
    },
    '01.019': {
      display: 'Kinderarts',
    },
    '01.020': {
      display: 'Arts klinische chemie (gesloten register)',
    },
    '01.021': {
      display: 'Klinisch geneticus',
    },
    '01.022': {
      display: 'Klinisch geriater',
    },
    '01.023': {
      display: 'Longarts',
    },
    '01.024': {
      display: 'Arts-microbioloog',
    },
    '01.025': {
      display: 'Neurochirurg',
    },
    '01.026': {
      display: 'Neuroloog',
    },
    '01.030': {
      display: 'Nucleair geneeskundige',
    },
    '01.031': {
      display: 'Oogarts',
    },
    '01.032': {
      display: 'Orthopedisch chirurg',
    },
    '01.033': {
      display: 'Patholoog',
    },
    '01.034': {
      display: 'Plastisch chirurg',
    },
    '01.035': {
      display: 'Psychiater',
    },
    '01.039': {
      display: 'Radioloog',
    },
    '01.040': {
      display: 'Radiotherapeut',
    },
    '01.041': {
      display: 'Reumatoloog',
    },
    '01.042': {
      display: 'Revalidatiearts',
    },
    '01.045': {
      display: 'Uroloog',
    },
    '01.046': {
      display: 'Gynaecoloog',
    },
    '01.047': {
      display: 'Specialist ouderengeneeskunde',
    },
    '01.048': {
      display: 'Verzekeringsarts',
    },
    '01.050': {
      display: 'Zenuwarts (gesloten register)',
    },
    '01.062': {
      display: 'Internist-Allergoloog (gesloten register)',
    },
    '01.055': {
      display: 'Arts maatschappij en gezondheid',
    },
    '01.056': {
      display: 'Arts voor verstandelijk gehandicapten',
    },
    '01.070': {
      display: 'Jeugdarts',
    },
    '01.071': {
      display: 'Spoedeisende hulp arts',
    },
    '01.074': {
      display: 'Sportarts',
    },
    '02.000': {
      display: 'Tandarts',
    },
    '02.053': {
      display: 'Orthodontist',
    },
    '02.054': {
      display: 'Kaakchirurg',
    },
    '03.000': {
      display: 'Verloskundige',
    },
    '04.000': {
      display: 'Fysiotherapeut',
    },
    '16.000': {
      display: 'Psychotherapeut',
    },
    '17.000': {
      display: 'Apotheker',
    },
    '17.060': {
      display: 'Ziekenhuisapotheker',
    },
    '17.075': {
      display: 'Openbaar apotheker',
    },
    '25.000': {
      display: 'Gezondheidszorgpsycholoog',
    },
    '25.061': {
      display: 'Klinisch psycholoog',
    },
    '25.063': {
      display: 'Klinisch neuropsycholoog',
    },
    '30.000': {
      display: 'Verpleegkundige',
    },
    '30.065': {
      display: 'Verpl. spec. prev. zorg bij som. aandoeningen',
    },
    '30.066': {
      display: 'Verpl. spec. acute zorg bij som. aandoeningen',
    },
    '30.067': {
      display: 'Verpl. spec. intensieve zorg bij som. aandoeningen',
    },
    '30.068': {
      display: 'Verpl. spec. chronische zorg bij som. aandoeningen',
    },
    '30.069': {
      display: 'Verpl. spec. geestelijke gezondheidszorg',
    },
    '30.076': {
      display: 'Verpl. spec. algemene gezondheidszorg',
    },
    '31.000': {
      display: 'Orthopedagoog-generalist',
    },
    '79.000': {
      display: 'Geregistreerd-mondhygiënist',
    },
    '80.000': {
      display: 'Bachelor medisch hulpverlener',
    },
    '81.000': {
      display: 'Physician assistant',
    },
    '82.000': {
      display: 'Klinisch technoloog',
    },
    '83.000': {
      display: 'Apothekersassistent',
    },
    '84.000': {
      display: 'Klinisch fysicus',
    },
    '85.000': {
      display: 'Tandprotheticus',
    },
    '86.000': {
      display: 'VIG-er',
    },
    '87.000': {
      display: 'Optometrist',
    },
    '88.000': {
      display: 'Huidtherapeut',
    },
    '89.000': {
      display: 'Diëtist',
    },
    '90.000': {
      display: 'Ergotherapeut',
    },
    '91.000': {
      display: 'Logopedist',
    },
    '92.000': {
      display: 'Mondhygiënist',
    },
    '93.000': {
      display: 'Oefentherapeut Mensendieck',
    },
    '94.000': {
      display: 'Oefentherapeut Cesar',
    },
    '95.000': {
      display: 'Orthoptist',
    },
    '96.000': {
      display: 'Podotherapeut',
    },
    '97.000': {
      display: 'Radiodiagnostisch laborant',
    },
    '98.000': {
      display: 'Radiotherapeutisch laborant',
    },
    '99.000': {
      display: 'Zorgverlener andere zorg',
    },
  },
};
