import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coverageStatus',
})
export class CoverageStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'active':
        return $localize`:@@fhir.coverage-status.active:Active`;
      case 'cancelled':
        return $localize`:@@fhir.coverage-status.cancelled:Cancelled`;
      case 'draft':
        return $localize`:@@fhir.coverage-status.draft:Draft`;
      case 'entered-in-error':
        return $localize`:@@fhir.coverage-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
