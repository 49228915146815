<div class="d-flex">
  <div class="side d-none d-lg-block overflow-auto px-3 px-md-4">
    <app-side-menu-container></app-side-menu-container>
  </div>
  <div class="main d-flex flex-grow-1 w-100">
    <div class="main-content flex-grow-1 ps-3 ps-lg-0 pe-3 pe-md-4">
      <app-middle-menu-container></app-middle-menu-container>
      <router-outlet *ngIf="(success$ | async) || (loading$ | async)"
                     data-cy="main-router-outlet"></router-outlet>
      <app-digi-me-onboard-container data-cy="main-onboard"
                                     *ngIf="mustOnboard$ | async"></app-digi-me-onboard-container>
      <app-account-create-container *ngIf="mustCreateAccount$ | async"></app-account-create-container>
    </div>
  </div>
</div>