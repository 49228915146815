import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const AdresSoortCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.20.5.3--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/AddressUse': {
      PST: {
        'nl-NL': 'Postadres',
        display: 'Postal Addres',
      },
      HP: {
        'nl-NL': 'Officieel adres',
        display: 'Primary Home',
      },
      PHYS: {
        'nl-NL': 'Woon-/verblijfadres',
        display: 'Visit Address',
      },
      TMP: {
        'nl-NL': 'Tijdelijk adres',
        display: 'Tempory Address',
      },
      WP: {
        'nl-NL': 'Werkadres',
        display: 'Work Place',
      },
      HV: {
        'nl-NL': 'Vakantie adres',
        display: 'Vacation Home',
      },
    },
  },
};
