<div class="grid-footer py-3">
  <div *ngIf="visibleFooter"
       class="d-flex justify-content-center">
    <ng-lottie [options]="options"
               width="60px"
               height="50px" />
  </div>
  <div *ngIf="visibleFooter"
       class="d-flex justify-content-center fw-semibold">
    <h3 class="text-center">{{text}}</h3>
  </div>
</div>