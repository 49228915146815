import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../../features';
import { PatientEffects } from './patient.effects';
import { reducer } from './patient.reducer';

@NgModule({
  imports: [StoreModule.forFeature(features.FHIR.patients, reducer), EffectsModule.forFeature([PatientEffects])],
})
export class PatientStoreModule {}
