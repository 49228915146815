import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Reference } from '@hl7fhir';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceComponent implements OnChanges {
  @Input() reference: Reference | null | undefined;
  @Input() suppressLink = false;

  fallbackDisplay: string | undefined = '';

  ngOnChanges(changes: SimpleChanges): void {
    const reference: Reference = changes['reference'].currentValue;
    // If display is not present show ID from reference.
    this.fallbackDisplay = reference?.reference?.split('/')[1];
  }
}
