import { Component } from '@angular/core';
import { StorageKeys } from '@globals';
import { Store, createSelector } from '@ngrx/store';
import { selectIsFilesCompleted, selectIsLoading, selectMustCreateAccount, selectMustOnboard } from '@store/digi.me';
import { selectUrl } from '@store/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, combineLatest, map, of } from 'rxjs';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
})
export class TopMenuComponent {
  protected selectIsLoadingAndNotOnLinkedSourcesPage = createSelector(
    selectIsLoading,
    selectUrl,
    (loading, url) => loading && !url.includes('/linked-sources') && !url.includes('/settings')
  );

  protected selectIsFilesCompletedAndNotOnLinkedSourcesPage = createSelector(
    selectIsFilesCompleted,
    selectUrl,
    (completed, url) => completed || url.includes('/linked-sources') || url.includes('/settings')
  );

  protected selectMustOnboard = createSelector(
    selectMustOnboard,
    selectUrl,
    (mustOnboard, url) => mustOnboard && !url.includes('/settings')
  );

  protected readonly success$: Observable<boolean> = this.store.select(
    this.selectIsFilesCompletedAndNotOnLinkedSourcesPage
  );
  protected readonly loading$: Observable<boolean> = this.store.select(this.selectIsLoadingAndNotOnLinkedSourcesPage);
  protected readonly mustOnboard$: Observable<boolean> = this.store.select(this.selectMustOnboard);

  // We should display create user account component when the library has not yet been claimed
  // by the user or when the user is not authenticated and shouldCreateAccount set to true.
  protected readonly mustCreateAccount$: Observable<boolean> = combineLatest([
    this.store.select(selectMustCreateAccount),
    of(Boolean(localStorage.getItem(StorageKeys.SHOULD_CREATE_ACCOUNT))),
    this.oidcSecurityService.isAuthenticated$,
  ]).pipe(
    map(
      ([libraryExists, shouldCreateAccount, authenticatedResult]) =>
        libraryExists || (shouldCreateAccount && !authenticatedResult.isAuthenticated)
    )
  );

  constructor(
    private readonly store: Store,
    private readonly oidcSecurityService: OidcSecurityService
  ) {}
}
