import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'procedureStatus',
})
export class ProcedureStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'preparation':
        return $localize`:@@fhir.procedure-status.preparation:Preparation`;
      case 'in-progress':
        return $localize`:@@fhir.procedure-status.in-progress:In Progress`;
      case 'suspended':
        return $localize`:@@fhir.procedure-status.suspended:Suspended`;
      case 'aborted':
        return $localize`:@@fhir.procedure-status.aborted:Aborted`;
      case 'completed':
        return $localize`:@@fhir.procedure-status.completed:Completed`;
      case 'entered-in-error':
        return $localize`:@@fhir.procedure-status.entered-in-error:Entered In Error`;
      case 'unknown':
        return $localize`:@@fhir.procedure-status.unknown:Unknown`;
      default:
        return '';
    }
  }
}
