import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compositionRelateToCode',
})
export class CompositionRelateToCodePipe implements PipeTransform {
  transform(code: string | undefined): string {
    switch (code) {
      case 'replaces':
        return $localize`:@@fhir.composition-status.replaces:Replaces`;
      case 'transforms':
        return $localize`:@@fhir.composition-status.transforms:Transforms`;
      case 'signs':
        return $localize`:@@fhir.composition-status.signs:Signs`;
      case 'appends':
        return $localize`:@@fhir.composition-status.appends:Appends`;
      default:
        return '';
    }
  }
}
