import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { getReference } from '@hl7fhir/foundation';
import { MedicationRequestRequester } from 'fhir/r3';

export class MedicationRequestRequesterViewModel extends BackboneElementViewModel<MedicationRequestRequester> {
  get agent(): string | undefined {
    return this.element?.agent && getReference(this.element.agent);
  }

  get onBehalfOf(): string | undefined {
    return this.element?.onBehalfOf && getReference(this.element.onBehalfOf);
  }
}
