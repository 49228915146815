import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { PractitionerQualification } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel, PeriodTypePipe } from '@hl7fhir/data-types';

export class PractitionerQualificationViewModel extends BackboneElementViewModel<PractitionerQualification> {
  get identifier(): IdentifierViewModel[] | undefined {
    return this.element?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.code);
  }

  get period(): string | undefined {
    return new PeriodTypePipe().transform(this.element?.period);
  }
}
