import { Observation } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS, MEASUREMENTS_UI_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectObservationId(observation: Observation): string {
  if (!observation.id) throw new Error('The observation is not created yet.');
  return observation.id;
}

export type State = EntityState<Observation>;

export const adapter: EntityAdapter<Observation> = createEntityAdapter<Observation>({
  selectId: selectObservationId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { observations }) => {
    // TODO Fhir multiversion
    return adapter.upsertMany(<Observation[]>observations, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state)),
  on(MEASUREMENTS_UI_ACTIONS.add, (state, action) => adapter.addOne(action.measurement, state))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectObservationEntities = selectEntities;

export const selectAllObservations = selectAll;
