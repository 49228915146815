import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { ConditionEvidence } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReferences } from '@hl7fhir/foundation';

export class ConditionEvidenceViewModel extends BackboneElementViewModel<ConditionEvidence> {
  get code(): string | undefined {
    return this.element?.code && new CodeableConceptPipe().transform(this.element.code);
  }

  get detail(): string | undefined {
    return this.element?.detail && getReferences(this.element.detail);
  }
}
