import { Binary } from '@hl7fhir';
import { getReference } from '@hl7fhir/foundation';
import { ResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';

export class BinaryViewModel extends ResourceViewModel<Binary> {
  get contentType(): string | undefined {
    return this.resource?.contentType;
  }

  get securityContext(): string | undefined {
    return getReference(this.resource?.securityContext);
  }

  get content(): string | undefined {
    const binaryR3 = this.resource as r3.Binary | undefined;
    return binaryR3?.content;
  }
}
