import { Pipe, PipeTransform } from '@angular/core';
import { Coding } from '@hl7fhir';
import { ValueSetFlattened, ValueSets } from '@hl7fhir/value-sets';

@Pipe({
  name: 'codings',
})
export class CodingsPipe implements PipeTransform {
  transform(codings: Coding | Coding[] | undefined, preferredValueSets?: ValueSetFlattened[]): string | undefined {
    if (!codings) {
      return undefined;
    }

    // Normalize single item to array
    if (!(codings instanceof Array)) {
      codings = [codings];
    }

    return codings.reduce<string | undefined>((result, coding) => {
      // Lookup the code and aggregate it to the result
      // if it is known
      const { code } = ValueSets.getDisplay(coding, preferredValueSets ?? []);
      if (code) {
        return result ? result + ', ' + code : code;
      }

      // Return the display value if we don't have one for the combination
      // of system and code, and otherwise the code
      if (coding.display) {
        return result ? result + ', ' + coding.display : coding.display;
      } else if (coding.code) {
        return result ? result + ', ' + coding.code : coding.code;
      }

      // If no code display is known or display is set, just return the previous result
      return result;
    }, undefined);
  }
}
