import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Narrative } from '@hl7fhir';

@Component({
  selector: 'app-narrative',
  templateUrl: './narrative.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NarrativeComponent {
  @Input() narrative: Narrative | undefined;

  validate(): boolean {
    if (
      !this.narrative?.status ||
      !['generated', 'extensions', 'additional', 'empty'].includes(this.narrative.status)
    ) {
      return false;
    }

    return true;
  }
}
