import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { Extension, AppointmentParticipant } from '@hl7fhir';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { SelectPipe } from '@shared';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { getReference } from '@hl7fhir/foundation';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import { AppointmentParticipantRequiredPipe, AppointmentParticipantStatusPipe } from '@hl7fhir/value-sets';

export class AppointmentParticipantViewModel extends ElementViewModel<AppointmentParticipant> {
  get actor(): string | undefined {
    return this.element?.actor && getReference(this.element.actor);
  }

  get required(): string | undefined {
    if (this.element?.required !== undefined) {
      return new AppointmentParticipantRequiredPipe().transform(this.element?.required);
    } else {
      return undefined;
    }
  }

  get status(): string | undefined {
    return this.element?.status && new AppointmentParticipantStatusPipe().transform(this.element.status);
  }

  get type(): string | undefined {
    return this.element?.type && new CodeableConceptPipe().transform(this.element.type);
  }

  get practitionerRole(): string | undefined {
    if (this.element?.actor?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.element.actor.extension,
        StructureDefinition.Nictiz.PRACTITIONER_ROLE.reference
      );

      if (extension) {
        return getReference(new SelectPipe().transform(extension, 'valueReference'));
      }
    }

    return undefined;
  }
}
