import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'episodeOfCareStatus',
})
export class EpisodeOfCareStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'planned':
        return $localize`:@@fhir.episode-of-care-status.planned:Planned`;
      case 'waitlist':
        return $localize`:@@fhir.episode-of-care-status.waitlist:Waitlist`;
      case 'active':
        return $localize`:@@fhir.episode-of-care-status.active:Active`;
      case 'onhold':
        return $localize`:@@fhir.episode-of-care-status.onhold:On Hold`;
      case 'finished':
        return $localize`:@@fhir.episode-of-care-status.finished:Finished`;
      case 'cancelled':
        return $localize`:@@fhir.episode-of-care-status.cancelled:Cancelled`;
      case 'entered-in-error':
        return $localize`:@@fhir.episode-of-care-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
