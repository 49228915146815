import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allergyIntoleranceCategory',
})
export class AllergyIntoleranceCategoryPipe implements PipeTransform {
  transform(statusses: string[] | undefined): string[] {
    if (!statusses) {
      return [''];
    }

    return statusses.map((status) => {
      switch (status) {
        case 'food':
          return $localize`:@@fhir.allergy-intolerance-category.food:Food`;
        case 'medication':
          return $localize`:@@fhir.allergy-intolerance-category.medication:Medication`;
        case 'environment':
          return $localize`:@@fhir.allergy-intolerance-category.environment:Environment`;
        case 'biologic':
          return $localize`:@@fhir.allergy-intolerance-category.biologic:Biologic`;
        default:
          return '';
      }
    });
  }
}
