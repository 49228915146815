import { NgModule } from '@angular/core';
import {
  AllergyIntoleranceCategoryPipe,
  AllergyIntoleranceClinicalStatusPipe,
  AllergyIntoleranceCriticalityPipe,
  AllergyIntoleranceReactionSeverityPipe,
  AllergyIntoleranceTypePipe,
  AllergyIntoleranceVerificationStatusPipe,
} from './allergy-intolerance';
import {
  AppointmentParticipantRequiredPipe,
  AppointmentParticipantStatusPipe,
  AppointmentStatusPipe,
} from './appointment';
import {
  CompositionAttesterModesPipe,
  CompositionRelateToCodePipe,
  CompositionSectionModePipe,
  CompositionStatusPipe,
} from './composition';
import { ConditionClinicalStatusPipe, ConditionVerificationStatusPipe } from './condition';
import { ConsentCategoryExtensionPipe } from './consent';
import { CoverageStatusPipe } from './coverage';
import { EntryTypePipe } from './device-udi-entry-type';
import { DeviceUseStatementStatusPipe } from './device-use-statement';
import { EncounterLocationStatusPipe, EncounterStatusPipe } from './encounter';
import { EpisodeOfCareStatusPipe } from './episode-of-care';
import { FlagStatusPipe } from './flag';
import { IdentifierUsePipe } from './identifier';
import { AvailabilityPipe } from './imaging-study';
import { ImmunizationStatusPipe } from './immunization';
import { MedicationStatusPipe } from './medication';
import { MedicationDispenseStatusPipe } from './medication-dispense';
import {
  MedicationRequestIntentPipe,
  MedicationRequestPriorityPipe,
  MedicationRequestStatusPipe,
} from './medication-request';
import { MedicationStatementStatusPipe, MedicationStatementTakenPipe } from './medication-statement';
import { NarrativeStatusPipe } from './narrative';
import { NutritionOrderStatusPipe } from './nutrition-order';
import { ObservationStatusPipe } from './observation';
import { MaritalStatusPipe } from './patient';
import { AdministrativeGenderPipe, CodeSystemPipe } from './pipes';
import { ProcedureStatusPipe } from './procedure';
import { SpecimenStatusPipe } from './specimen';

const observation = [ObservationStatusPipe];
const identifier = [IdentifierUsePipe];
const condition = [ConditionClinicalStatusPipe, ConditionVerificationStatusPipe];
const specimen = [SpecimenStatusPipe];
const medicationRequest = [MedicationRequestStatusPipe, MedicationRequestIntentPipe, MedicationRequestPriorityPipe];
const medication = [MedicationStatusPipe];
const allergyIntolerance = [
  AllergyIntoleranceClinicalStatusPipe,
  AllergyIntoleranceVerificationStatusPipe,
  AllergyIntoleranceReactionSeverityPipe,
  AllergyIntoleranceCriticalityPipe,
  AllergyIntoleranceCategoryPipe,
  AllergyIntoleranceTypePipe,
];
const patient = [MaritalStatusPipe];
const immunization = [ImmunizationStatusPipe];
const composition = [
  CompositionStatusPipe,
  CompositionAttesterModesPipe,
  CompositionRelateToCodePipe,
  CompositionSectionModePipe,
];
const episodeOfCare = [EpisodeOfCareStatusPipe];
const coverage = [CoverageStatusPipe];
const consent = [ConsentCategoryExtensionPipe];
const medicationStatement = [MedicationStatementStatusPipe, MedicationStatementTakenPipe];
const medicationDispense = [MedicationDispenseStatusPipe];
const nutritionOrder = [NutritionOrderStatusPipe];
const deviceUseStatement = [DeviceUseStatementStatusPipe];
const entryType = [EntryTypePipe];
const procedure = [ProcedureStatusPipe];
const imagingStudy = [AvailabilityPipe];
const appointment = [AppointmentStatusPipe, AppointmentParticipantStatusPipe, AppointmentParticipantRequiredPipe];
const encounter = [EncounterStatusPipe, EncounterLocationStatusPipe];
const flag = [FlagStatusPipe];

const pipes = [
  AdministrativeGenderPipe,
  CodeSystemPipe,
  NarrativeStatusPipe,
  ...allergyIntolerance,
  ...observation,
  ...identifier,
  ...condition,
  ...specimen,
  ...medicationRequest,
  ...medication,
  ...composition,
  ...patient,
  ...immunization,
  ...episodeOfCare,
  ...coverage,
  ...consent,
  ...medicationStatement,
  ...medicationDispense,
  ...nutritionOrder,
  ...deviceUseStatement,
  ...entryType,
  ...procedure,
  ...imagingStudy,
  ...appointment,
  ...encounter,
  ...flag,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class ValueSetsModule {}
