import { Pipe, PipeTransform } from '@angular/core';
import { HumanName } from '@hl7fhir';
import { StructureDefinition } from '@hl7fhir/structure-definitions';

@Pipe({
  name: 'given',
})
export class GivenNamePipe implements PipeTransform {
  /**
   *
   * @param value
   * @param code
   * http://hl7.org/fhir/R4B/valueset-name-part-qualifier.html#definition
   * @returns
   */
  transform(value: HumanName | undefined, code: string) {
    if (!value || !code) {
      return null;
    }

    // If no additional information is available aggregate all items and display them in the GivenName column
    if (!value._given && code === 'BR') {
      return value.given?.join(' ');
    } else if (!value._given && code !== 'BR') {
      return null;
    }

    const extensionIndex = value._given?.findIndex((element) =>
      element.extension?.some(
        (extension: { url?: string; valueCode?: string }) =>
          extension.url === `${StructureDefinition.Hl7.STRUCTURE_DEFINITION}/iso21090-EN-qualifier` &&
          extension.valueCode === code
      )
    );

    if (extensionIndex === undefined || extensionIndex === -1 || !value.given) {
      return null;
    }

    const items = value.given[extensionIndex];

    return items;
  }
}
