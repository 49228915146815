import { PractitionerRole } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export type State = EntityState<PractitionerRole>;

export const adapter: EntityAdapter<PractitionerRole> = createEntityAdapter<PractitionerRole>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { practitionerRoles }) => {
    return adapter.upsertMany(practitionerRoles, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectPractitionerRolesEntities = selectEntities;

export const selectAllPractitionerRoles = selectAll;
