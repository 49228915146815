import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Annotation } from '@hl7fhir';

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationsComponent {
  @Input() annotations: Annotation[] | undefined;
}
