import { ObservationComponent } from '@hl7fhir';
import { CodeableConceptPipe, getChoiceOfType } from '@hl7fhir/data-types';
import { ObservationReferenceViewModel } from './observation-reference.viewmodel';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';

export class ObservationComponentViewModel extends BackboneElementViewModel<ObservationComponent> {
  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.code);
  }

  get value(): string | undefined {
    return getChoiceOfType({
      dateTime: this.element?.valueDateTime,
      period: this.element?.valuePeriod,
      time: this.element?.valueTime,
      string: this.element?.valueString,
      ratio: this.element?.valueRatio,
      sampledData: this.element?.valueSampledData,
      quantity: this.element?.valueQuantity,
      range: this.element?.valueRange,
      codeableConcept: this.element?.valueCodeableConcept,
    });
  }

  get dataAbsentReason(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.dataAbsentReason);
  }

  get interpretation(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.interpretation);
  }

  get referenceRanges(): ObservationReferenceViewModel[] | undefined {
    return this.element?.referenceRange?.map(
      (referenceRange) => new ObservationReferenceViewModel(referenceRange, this.fhirVersion)
    );
  }
}
