<div class="container vstack align-items-center gap-5 text-center"
     style="max-width: 800px;">
  <mat-icon class="text-success material-icons-check-circle">check_circle</mat-icon>
  <h2 i18n="@@app.signinSuccess.heading">You've successfully imported your medical records. Final Step: Create
    Your Account</h2>
  <p i18n="@@app.signinSuccess.explainer">Complete your account setup with multi-factor authentication to access your
    medical records. Don't worry—if you can't finish now, any imported data will be deleted for your privacy and won't
    affect your hospital records.</p>
  <app-button type="primary"
              size="lg"
              (clicked)="create.emit(this.library)"
              i18n-label="@@app.signinSuccess.button"
              label="Create your account"></app-button>
  <a (click)="this.cancel.emit();"
     style="cursor: pointer;"
     target="_blank"
     class="link-dark link-primary-hover fw-semibold link-underline-opacity-100-hover"
     i18n="@@app.singninSuccess.cancel">Or, cancel and remove imported records</a>
  <app-medmij-trusted></app-medmij-trusted>
</div>