import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from '@core/services';
import { configId } from '@environments/environment';
import { FreshdeskService } from '@freshdesk';
import { StorageKeys } from '@globals';
import { toAzureAdB2c } from '@language';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest } from 'rxjs';
import { delay, filter, map, take, tap } from 'rxjs/operators';
import { CLEAR_UI_DATA_ACTIONS } from '../digi.me/digi-me.actions';
import { selectUser, selectUserAd } from '../digi.me/selectors';
import { APP_ACTIONS, FRESHDESK_ACTIONS, IDLE_ACTIONS } from './app.actions';

@Injectable()
export class AppEffects {
  removeMustCreateAccountOnLogIn$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticated),
        tap(() => {
          localStorage.removeItem(StorageKeys.SHOULD_CREATE_ACCOUNT);
        })
      );
    },
    { dispatch: false }
  );

  logOutWhenIdleTimeout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IDLE_ACTIONS.timeout),
        concatLatestFrom(() => {
          return this.oidcSecurityService.isAuthenticated$.pipe(
            take(1),
            map((result) => result.allConfigsAuthenticated.some((x) => x.configId === configId && x.isAuthenticated))
          );
        }),
        filter(([_, isAuthenticated]) => isAuthenticated),
        map(() => {
          const logoutOptions: LogoutAuthOptions = {
            urlHandler: (_url: string) => {
              return '';
            },
          };
          this.oidcSecurityService.logoffAndRevokeTokens(configId, logoutOptions).subscribe();
          this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-signup`, logoutOptions).subscribe();
          this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-reset`, logoutOptions).subscribe();
          this.oidcSecurityService.logoffLocalMultiple();
          this.router.navigate(['onboard-or-log-in']);
          return APP_ACTIONS.timeoutLogout();
        })
      );
    },
    { dispatch: true }
  );

  logOutWhenAuthenticationFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticationFailed),
        map(() => {
          const logoutOptions: LogoutAuthOptions = {
            urlHandler: (_url: string) => {
              return '';
            },
          };
          this.oidcSecurityService.logoffAndRevokeTokens(configId, logoutOptions).subscribe();
          this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-signup`, logoutOptions).subscribe();
          this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-reset`, logoutOptions).subscribe();
          this.oidcSecurityService.logoffLocalMultiple();
          this.router.navigate(['onboard-or-log-in']);
          return APP_ACTIONS.timeoutLogout();
        })
      );
    },
    { dispatch: true }
  );

  resetStateOnTimeoutLogout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.timeoutLogout),
        map(() => {
          this.deviceService.removeDeviceId();
          this.modalService.dismissAll();
          return CLEAR_UI_DATA_ACTIONS.clearDataRequested();
        })
      );
    },
    { dispatch: true }
  );

  loggin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticationStarted),
        // Delay the sign in allowing the event log time to send its request
        // before navigating away from the page for the login
        delay(100),
        tap((action) => {
          let customParams: { [param: string]: string } = {
            ui_locales: toAzureAdB2c($localize.locale),
          };

          if (action.prompt) {
            customParams = {
              ...customParams,
              prompt: action.prompt,
            };
          }
          this.oidcSecurityService.authorize(configId, { customParams });
        })
      );
    },
    { dispatch: false }
  );

  openFreshdeskWidget = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FRESHDESK_ACTIONS.openWidget),
        concatLatestFrom(() => combineLatest([this.store.select(selectUser), this.store.select(selectUserAd)])),
        tap(([, selectUser]) => {
          const userId = selectUser[0]?.id;
          const userName = selectUser[1]?.displayName;
          const userEmail = selectUser[1]?.emailAddress;

          this.freshdeskWidgetService.freshworksWidget('prefill', 'ticketForm', {
            name: userName,
            email: userEmail,
            subject: $localize`:@@app.feedback.widget.reportbug:Bug Report` + `${userId ? ` (${userId})` : ''}`,
          });
          this.freshdeskWidgetService.freshworksWidget('open');
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly modalService: NgbModal,
    private readonly freshdeskWidgetService: FreshdeskService,
    private readonly store: Store,
    private readonly deviceService: DeviceService
  ) {}
}
