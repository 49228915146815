import { Attachment } from '@hl7fhir';
import { ElementViewModel } from '@hl7fhir/viewmodels';

export class AttachmentViewModel extends ElementViewModel<Attachment> {
  get contentType(): string | undefined {
    return this.element?.contentType;
  }

  get creation(): string | undefined {
    return this.element?.creation;
  }

  get data(): string | undefined {
    return this.element?.data;
  }

  get hash(): string | undefined {
    return this.element?.hash;
  }

  get language(): string | undefined {
    return this.element?.language;
  }

  get size(): string | undefined {
    return this.element?.size?.toString();
  }

  get title(): string | undefined {
    return this.element?.title;
  }

  get url(): string | undefined {
    return this.element?.url;
  }
}
