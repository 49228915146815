import { NarrativeViewModel } from './narrative.viewmodel';

export function getNarrative(narrative: NarrativeViewModel | undefined): string | undefined {
  if (!narrative?.status || !narrative?.div) {
    return undefined;
  }

  return `Status - ${narrative.status}: ${narrative.div
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()};`;
}
