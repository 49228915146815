import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { CodeableConceptPipe, getChoiceOfType } from '@hl7fhir/data-types';
import { SpecimenProcessing } from 'src/app/fhir/fhir.types';

export class SpecimenProcessingViewModel extends BackboneElementViewModel<SpecimenProcessing> {
  private readonly specProR = this.element as r3.SpecimenProcessing | r4.SpecimenProcessing | r4b.SpecimenProcessing;
  get description(): string | undefined {
    return this.element?.description;
  }

  //R3, R4, R4b only
  get procedure(): string | undefined {
    return this.specProR?.procedure && new CodeableConceptPipe().transform(this.specProR.procedure);
  }

  get additive(): string | undefined {
    return this.element?.additive && getReferences(this.element.additive);
  }

  get time(): string | undefined {
    return getChoiceOfType({
      dateTime: formatLocaleDate(this.element?.timeDateTime, 'long'),
      period: this.element?.timePeriod,
    });
  }
}
