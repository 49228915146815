import { Pipe, PipeTransform } from '@angular/core';
import { HumanName } from '@hl7fhir';
import { HumanNameComparer } from '@hl7fhir/comparers';

@Pipe({
  name: 'humanNameRank',
})
export class HumanNameRankPipe implements PipeTransform {
  transform(value: HumanName[] | undefined | null): HumanName | null {
    if (!value) return null;

    if (value.length == 1) {
      return value[0];
    }

    // Sort and return first
    return value.sort(HumanNameComparer.compare)[0];
  }
}
