<div class="d-block d-lg-none"
     *ngIf="activeMenuItem$ | async">
  <div ngbAccordion
       #acc="ngbAccordion"
       class="accordion-flush">
    <div ngbAccordionItem>
      <h2 ngbAccordionHeader
          class="accordion-header-item">
        <button class="accordion-header-item icon-link middle-menu py-2 ps-2 pe-3"
                ngbAccordionButton>
          <svg data-cy="header-icon"
               class="d-inline-block menu-icon">
            <use attr.xlink:href="./assets/images/assets.svg#{{(activeMenuItem$ | async)?.icon}}"></use>
          </svg>
          {{ (activeMenuItem$ | async)?.header }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody
             class="pe-0 py-0">
          <ng-template>
            <app-middle-menu (selected)="acc.collapseAll()"
                             [menu]="subMenu$ | async"></app-middle-menu>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>