import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allergyIntoleranceClinicalStatus',
})
export class AllergyIntoleranceClinicalStatusPipe implements PipeTransform {
  transform(input: string | undefined): string | undefined {
    if (typeof input === 'string') {
      return this.transformString(input);
    }

    return '';
  }

  private transformString = (input: string): string => {
    switch (input) {
      case 'active':
        return $localize`:@@fhir.allergy-intolerance-clinical-status.active:Active`;
      case 'inactive':
        return $localize`:@@fhir.allergy-intolerance-clinical-status.inactive:Inactive`;
      case 'resolved':
        return $localize`:@@fhir.allergy-intolerance-clinical-status.resolved:Resolved`;
      default:
        return '';
    }
  };
}
