<div class="border-top border-secondary">
  <div data-cy="addresses-header"
       *ngIf="addresses?.length !== 1"
       class="p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span class="h6"
          i18n="@@app.addresses.header">Addresses</span>
  </div>

  <p *ngIf="!addresses || addresses.length === 0"
     class="p-2"
     data-cy="addresses-empty"
     i18n="@@app.addresses.empty">
    No addresses to show.
  </p>

  <ng-container *ngFor="let address of addresses">
    <app-address [address]="address"></app-address>
  </ng-container>
</div>