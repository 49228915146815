import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CodeableConceptHasCodingPipe } from './pipes/codeable-concept-has-coding.pipe';
import { CodeableConceptPipe } from './pipes/codeable-concept.pipe';
import { CodeableConceptFilterPipe } from './pipes/codeable-concept-filter.pipe';

const pipes = [CodeableConceptHasCodingPipe, CodeableConceptPipe, CodeableConceptFilterPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class CodeableConceptModule {}
