import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allergyIntoleranceCriticality',
})
export class AllergyIntoleranceCriticalityPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'low':
        return $localize`:@@fhir.allergy-intolerance-criticality.low:Low Risk`;
      case 'high':
        return $localize`:@@fhir.allergy-intolerance-criticality.high:High Risk`;
      case 'unable-to-assess':
        return $localize`:@@fhir.allergy-intolerance-criticality.unable-to-assess:Unable to Assess Risk`;
      default:
        return '';
    }
  }
}
