import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemLanguageAbilityProficiency: CodeSystemFlattened = {
  id: 'v3-LanguageAbilityProficiency',
  codes: {
    E: {
      display: 'Excellent',
    },
    F: {
      display: 'Fair',
    },
    G: {
      display: 'Good',
    },
    P: {
      display: 'Poor',
    },
  },
};
