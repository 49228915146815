import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const COD016_VEKT: CodeSystemFlattened = {
  id: '147d713b-576f-491c-bbb2-c37c5186b0a2',
  codes: {
    '0100': {
      display: 'Huisartsen, niet nader gespecificeerd',
    },
    '0101': {
      display: 'Huisartsen, niet apotheekhoudend',
    },
    '0102': {
      display: 'Huisarts, medicatiebeoordeling chronisch UR-geneesmiddelgebruik',
    },
    '0110': {
      display: 'Huisartsen, apotheekhoudend',
    },
    '0111': {
      display: 'Huisartsen, niet apotheekhoudend, medicatiebeoordeling',
    },
    '0112': {
      display: 'Huisartsen, Apotheekhoudend, medicatiebeoordeling',
    },
    '0120': {
      display: 'Alternatieve huisartsen',
    },
    '0200': {
      display: 'Apothekers',
    },
    '0201': {
      display: 'Apotheker, medicatiebeoordeling chronisch UR-geneesmiddelgebruik',
    },
    '0300': {
      display: 'Medisch specialisten, niet nader gespecificeerd',
    },
    '0301': {
      display: 'Medisch specialisten, oogheelkunde',
    },
    '0302': {
      display: 'Medisch specialisten, keel-, neus- en oorheelkunde',
    },
    '0303': {
      display: 'Medisch specialisten, chirurgie',
    },
    '0304': {
      display: 'Medisch specialisten, plastische chirurgie',
    },
    '0305': {
      display: 'Medisch specialisten, orthopedie',
    },
    '0306': {
      display: 'Medisch specialisten, urologie',
    },
    '0307': {
      display: 'Medisch specialisten, obstetrie en gynaecologie',
    },
    '0308': {
      display: 'Medisch specialisten, neurochirurgie',
    },
    '0309': {
      display: 'Medisch specialisten, zenuw - en zielsziekten',
    },
    '0310': {
      display: 'Medisch specialisten, dermatologie',
    },
    '0313': {
      display: 'Medisch specialisten, inwendige geneeskunde',
    },
    '0316': {
      display: 'Medisch specialisten, kindergeneeskunde',
    },
    '0318': {
      display: 'Medisch specialisten, gastro-enterologie (maag-darm-lever-arts)',
    },
    '0320': {
      display: 'Medisch specialisten, cardiologie',
    },
    '0322': {
      display: 'Medisch specialisten, longziekten',
    },
    '0324': {
      display: 'Medisch specialisten, reumatologie',
    },
    '0326': {
      display: 'Medisch specialisten, allergologie',
    },
    '0327': {
      display: 'Medisch specialisten, revalidatie',
    },
    '0328': {
      display: 'Medisch specialisten, cardio thoracale chirurgie',
    },
    '0329': {
      display: 'Medisch specialisten, psychiatrie',
    },
    '0330': {
      display: 'Medisch specialisten, neurologie',
    },
    '0335': {
      display: 'Medisch specialisten, geriatrie',
    },
    '0360': {
      display: 'Medisch specialisten, radiologie',
    },
    '0361': {
      display: 'Medisch specialisten, radiotherapie',
    },
    '0362': {
      display: 'Medisch specialisten, radiologie (voorheen radiodiagnostiek)',
    },
    '0363': {
      display: 'Medisch specialisten, nucleaire geneeskunde',
    },
    '0386': {
      display: 'Medisch specialisten, klinische chemie',
    },
    '0387': {
      display: 'Medisch specialisten, medische microbiologie',
    },
    '0388': {
      display: 'Medisch specialisten, pathologische anatomie',
    },
    '0389': {
      display: 'Medisch specialisten, anaesthesiologie',
    },
    '0390': {
      display: 'Medisch specialisten, klinische genetica',
    },
    '0400': {
      display: 'Fysiotherapeuten, niet nader gespecificeerd',
    },
    '0401': {
      display: 'Fysiotherapeuten, fysiotherapie',
    },
    '0402': {
      display: 'Fysiotherapeuten, heilgymnastiek/massage',
    },
    '0403': {
      display: 'Fysiotherapeuten, kinderfysiotherapie',
    },
    '0404': {
      display: 'Fysiotherapeuten, manuele therapie',
    },
    '0405': {
      display: 'Fysiotherapeuten, oedeemtherapie',
    },
    '0406': {
      display: 'Fysiotherapeuten, bekkenfysiotherapie',
    },
    '0407': {
      display: 'Fysiotherapeuten, fysiotherapie in de geriatrie',
    },
    '0408': {
      display: 'Fysiotherapeuten, sportfysiotherapie',
    },
    '0409': {
      display: 'Fysiotherapeuten, psychosomatische fysiotherapie',
    },
    '0410': {
      display: 'Oro-faciale fysiotherapie',
    },
    '0411': {
      display: 'Arbeids- en/of bedrijfsfysiotherapeut',
    },
    '0412': {
      display: 'Fysiotherapeuten, Oncologie',
    },
    '0413': {
      display: 'Fysiotherapeuten, manuele therapie en heilgymnastiek',
    },
    '0414': {
      display: 'Fysiotherapeuten, manuele therapie en sportfysiotherapie',
    },
    '0415': {
      display: 'Fysiotherapeuten, manuele therapie en kinderfysiotherapie',
    },
    '0416': {
      display: 'Fysiotherapeuten, manuele therapie en oedeemtherapie',
    },
    '0417': {
      display: 'Fysiotherapeuten, bekkenfysiotherapie en manuele therapie',
    },
    '0418': {
      display: 'Fysiotherapeuten, kinderfysio- en oedeemtherapie',
    },
    '0419': {
      display: 'Fysiotherapeuten, kinder- en bekkenfysiotherapie',
    },
    '0420': {
      display: 'Fysiotherapeuten, bekkenfysio- en oedeemtherapie',
    },
    '0421': {
      display: 'Fysiotherapeuten, fysiotherapie in de geriatrie en oedeemtherapie',
    },
    '0422': {
      display: 'Fysiotherapeuten, sportfysio- en oedeemtherapie',
    },
    '0423': {
      display: 'Fysiotherapeuten, manuele therapie, kinderfysio- en oedeemtherapie',
    },
    '0424': {
      display: 'Fysiotherapeuten, manuele therapie, bekkenfysio- en oedeemtherapie',
    },
    '0425': {
      display: 'Fysiotherapeuten, kinderfysio-, bekkenfysiotherapie, en manuele therapie',
    },
    '0426': {
      display: 'Fysiotherapeuten, kinderfysio-, bekkenfysio- en oedeemtherapie',
    },
    '0427': {
      display: 'Fysiotherapeuten, manuele therapie, kinderfysio-, bekkenfysio- en oedeemtherapie',
    },
    '0428': {
      display: 'Fysiotherapeuten, manuele therapie, kinderfysio-, sportfysio-, oedeemtherapie',
    },
    '0429': {
      display:
        'Fysiotherapeuten, manuele therapie, bekkenfysio-, sportfysio-, oedeemtherapie, fysiotherapie in de geriatrie',
    },
    '0430': {
      display: 'Fysiotherapeuten, manuele therapie, bekkenfysio-, sportfysio- en oedeemtherapie',
    },
    '0431': {
      display: 'Fysiotherapeuten, heilgymnastiek/massage en fysiotherapie in de geriatrie',
    },
    '0432': {
      display: 'Fysiotherapeuten, heilgymnastiek/massage en sportfysiotherapie',
    },
    '0433': {
      display: 'Fysiotherapeuten, manuele therapie, oedeemtherapie, kinder-, bekken- en sportfysiotherapie',
    },
    '0434': {
      display:
        'Fysiotherapeuten, heilgymnastiek, kinder-, bekken- en sportfysiotherapie, fysiotherapie in de geriatrie',
    },
    '0435': {
      display: 'Fysiotherapeuten, manuele therapie, kinder- en sportfysiotherapie',
    },
    '0436': {
      display: 'Fysiotherapeuten, manuele therapie, oedeemtherapie en fysiotherapie in de geriatrie',
    },
    '0437': {
      display:
        'Fysiotherapeuten, manuele therapie, oedeemtherapie, kinder-, bekken- en sportfysiotherapie, fysiotherapie in de geriatrie',
    },
    '0438': {
      display: 'Fysiotherapeuten, manuele therapie en fysiotherapie in de geriatrie',
    },
    '0439': {
      display: 'Fysiotherapeuten, manuele therapie, bekken- en sportfysiotherapie',
    },
    '0440': {
      display: 'Fysiotherapeuten, kinderfysiotherapie en fysiotherapie in de geriatrie',
    },
    '0441': {
      display: 'Fysiotherapeuten, psychosomatische fysio- en oedeemtherapie',
    },
    '0442': {
      display: 'Fysiotherapeuten, psychosomatische fysiotherapie en manuele therapie',
    },
    '0443': {
      display: 'Fysiotherapeuten, Psychosomatische fysio- en bekkentherapie',
    },
    '0444': {
      display: 'Fysiotherapeuten, Sportfysio-, kinderfysio-, en oedeemtherapie',
    },
    '0445': {
      display: 'Fysiotherapeuten, bekkenfysiotherapie, oedeemtherapie en fysiotherapie in de geriatrie',
    },
    '0446': {
      display: 'Fysiotherapeuten, kinderfysiotherapie en psychosomatische fysiotherapie',
    },
    '0447': {
      display: 'Fysiotherapeuten, sport- en kinderfysiotherapie',
    },
    '0448': {
      display: 'Fysiotherapeuten, fysiotherapie en manuele therapie',
    },
    '0449': {
      display: 'Fysiotherapeuten, kinder- en sportfysiotherapie, oedeemtherapie en psychosomatische fysiotherapie',
    },
    '0450': {
      display: 'Fysiotherapeuten, fysio- en kinderfysiotherapie',
    },
    '0451': {
      display: 'Fysiotherapeuten, psychosomatische-, oedeem-, bekkenfysiotherapie',
    },
    '0452': {
      display: 'Fysiotherapeuten, psychosomatische- en sportfysiotherapie',
    },
    '0453': {
      display: 'Fysiotherapeuten, heilgymnastiek/massage, oedeemtherapie',
    },
    '0454': {
      display: 'Fysiotherapeuten, heilgymnastiek, massage en bekkenfysiotherapie',
    },
    '0455': {
      display: 'Fysiotherapeuten, psychosomatische, sportfysio- en manuele therapie',
    },
    '0456': {
      display: 'Fysiotherapeuten, heilgymnastiek, massage, geriatrie en psychosomatische fysiotherapie',
    },
    '0457': {
      display: 'Fysiotherapeuten, manuele therapie, oedeemtherapie, kinder-, bekkenfysiotherapie en geriatrie',
    },
    '0458': {
      display: 'Fysiotherapeuten, manuele therapie, kinderfysiotherapie, oedeemtherapie en geriatrie',
    },
    '0459': {
      display: 'Fysiotherapeuten, manuele therapie, bekkenfysiotherapie en geriatrie',
    },
    '0460': {
      display: 'Fysiotherapeuten, fysiotherapie, manuele therapie, sportfysiotherapie en geriatrie',
    },
    '0461': {
      display: 'Fysiotherapeuten, fysiotherapie en heilgymnastiek/massage',
    },
    '0462': {
      display: 'Fysiotherapeuten, fysio- en oedeemtherapie (lympfedrainage)',
    },
    '0463': {
      display: 'Fysiotherapeuten, fysio- en bekkenfysiotherapie',
    },
    '0464': {
      display: 'Fysiotherapeuten, fysio- en fysiotherapie in de geriatrie',
    },
    '0465': {
      display: 'Fysiotherapeuten, fysio- en sportfysiotherapie',
    },
    '0466': {
      display: 'Fysiotherapeuten, fysio- en psychosomatische fysiotherapie',
    },
    '0467': {
      display: 'Fysiotherapeuten, fysio-, manuele- en kinderfysiotherapie',
    },
    '0468': {
      display: 'Fysiotherapeuten, fysio-, manuele- en oedeemtherapie',
    },
    '0469': {
      display: 'Fysiotherapeuten, fysio-, manuele- en bekkenfysiotherapie',
    },
    '0470': {
      display: 'Fysiotherapeuten, fysio-, kinderfysio- en oedeemtherapie',
    },
    '0471': {
      display: 'Fysiotherapeuten, fysio-, kinderfysio- en bekkenfysiotherapie',
    },
    '0472': {
      display: 'Fysiotherapeuten, fysio-, oedeem- en bekkentherapie',
    },
    '0473': {
      display: 'Fysiotherapeuten, fysio-, oedeem- en geriatrische fysiotherapie',
    },
    '0474': {
      display: 'Fysiotherapeuten, fysio- en ora-faciaal therapie',
    },
    '0475': {
      display: 'Fysiotherapeuten, fysio-, manueel en ora-faciaal therapie',
    },
    '0476': {
      display: 'Fysiotherapeuten, fysio-, manueel en arbeidsfysiotherapie',
    },
    '0477': {
      display: 'Fysiotherapeut Leefstijlcoach',
    },
    '0478': {
      display: 'Hart, vaat en long fysiotherapeut',
    },
    '0500': {
      display: 'Logopedisten, niet nader gespecificeerd',
    },
    '0501': {
      display: 'Logopedisten, logopedie',
    },
    '0502': {
      display: 'Logopedisten, foniatrie',
    },
    '0503': {
      display: 'Logopedisten, stottertherapie',
    },
    '0504': {
      display: 'Logopedisten, preverbale therapie',
    },
    '0505': {
      display: 'Logopedie, direct toegankelijk / 1e lijn',
    },
    '0506': {
      display: 'Logopedisten, afasie',
    },
    '0507': {
      display: 'Logopedisten, Hanen-ouderprogramma',
    },
    '0508': {
      display: 'Logopedisten, Integraal Zorg Stotteren (IZS)',
    },
    '0510': {
      display: 'Logopedisten, logopedie, direct toegankelijk',
    },
    '0511': {
      display: 'Logopedisten, foniatrie, direct toegankelijk',
    },
    '0512': {
      display: 'Logopedisten, stottertherapie, direct toegankelijk',
    },
    '0513': {
      display: 'Logopedisten, preverbale therapie, direct toegankelijk',
    },
    '0514': {
      display: 'Logopedisten, afasie, direct toegankelijk',
    },
    '0515': {
      display: 'Logopedisten, Hanen-ouderenprogramma, direct toegankelijk',
    },
    '0516': {
      display: 'Logopedisten, Integraal Stotteren IZS, direct toegankelijk',
    },
    '0517': {
      display: 'Preverbale therapie, Hanen-ouderen programma',
    },
    '0518': {
      display: 'Stottertherapie, preverbale therapie',
    },
    '0519': {
      display: 'Stottertherapie,Integraal Zorg Stotteren (IZS)',
    },
    '0520': {
      display: 'Stottertherapie, Hanen-ouderprogramma',
    },
    '0521': {
      display: 'Preverbale therapie, Afasie',
    },
    '0522': {
      display: 'Afasie, Hanen-ouderprogramma',
    },
    '0523': {
      display: 'Stottertherapie, Afasie',
    },
    '0524': {
      display: 'Stottertherapie, Afasie, Preverbale therapie',
    },
    '0525': {
      display: 'Stottertherapie ,Hanen-ouderprogramma, Afasie, Preverbale therapie',
    },
    '0526': {
      display: 'Stottertherapie, Hanen-ouderprogramma,Preverbale therapie',
    },
    '0527': {
      display: 'Hanen-ouderprogramma, Preverbale therapie, Direct toegankelijk',
    },
    '0528': {
      display: 'Stottertherapie, Preverbale therapie, Direct toegankelijk',
    },
    '0529': {
      display: 'Stottertherapie, Preverbale therapie, Afasie, Direct toegankelijk',
    },
    '0530': {
      display: 'Stottertherapie, Preverbale therapie, Afasie, Hanen-ouderprogramma, Direct toegankelijk',
    },
    '0531': {
      display: 'Stottertherapie, Preverbale therapie, Hanen-ouderprogramma, Direct toegankelijk',
    },
    '0532': {
      display: 'Stottertherapie, Integraal Zorg Stotteren (IZS), Direct toegankelijk',
    },
    '0533': {
      display: 'Stottertherapie, Hanen-ouderprogramma, Direct toegankelijk',
    },
    '0534': {
      display: 'Preverbale therapie, Afasie, Direct toegankelijk',
    },
    '0535': {
      display: 'Afasie, Hanen-ouderprogramma, Direct toegankelijk',
    },
    '0536': {
      display: 'Stottertherapie, Afasie, Direct toegankelijk',
    },
    '0600': {
      display: 'Ziekenhuizen, niet nader gespecificeerd',
    },
    '0601': {
      display: 'Ziekenhuizen, algemene ziekenhuizen',
    },
    '0602': {
      display: 'Ziekenhuizen, academische ziekenhuizen',
    },
    '0603': {
      display: 'Ziekenhuizen, astmaklinieken',
    },
    '0604': {
      display: 'Ziekenhuizen, beademingscentra',
    },
    '0606': {
      display: 'Ziekenhuizen, epilepsiecentra',
    },
    '0607': {
      display: 'Ziekenhuizen, kinderziekenhuizen',
    },
    '0608': {
      display: 'Ziekenhuizen, kankerklinieken',
    },
    '0610': {
      display: 'Ziekenhuizen, kraamklinieken',
    },
    '0612': {
      display: 'Ziekenhuizen, neurologische klinieken',
    },
    '0613': {
      display: 'Ziekenhuizen, oogziekenhuizen',
    },
    '0614': {
      display: 'Ziekenhuizen, orthopaedische klinieken',
    },
    '0615': {
      display: 'Ziekenhuizen, reumaklinieken',
    },
    '0616': {
      display: 'Ziekenhuizen, revalidatie-centra',
    },
    '0617': {
      display: 'Ziekenhuizen, sanatoria met longklinieken',
    },
    '0621': {
      display: 'Ziekenhuizen, sanatoria',
    },
    '0628': {
      display: 'Ziekenhuizen, multi-categorale ziekenhuizen',
    },
    '0629': {
      display: 'Ziekenhuizen, psychiatrische ziekenhuizen',
    },
    '0630': {
      display: 'Ziekenhuizen, militaire ziekenhuizen',
    },
    '0650': {
      display: 'Ziekenhuizen, buitenlandse ziekenhuizen',
    },
    '0700': {
      display: 'Oefentherapeuten, niet nader gespecificeerd',
    },
    '0701': {
      display: 'Oefentherapeuten, methode Cesar',
    },
    '0702': {
      display: 'Oefentherapeuten, methode Mensendieck',
    },
    '0703': {
      display: 'Oefentherapeuten, kinderoefentherapie',
    },
    '0704': {
      display: 'Oefentherapeuten, psychosomatische oefentherapie',
    },
    '0705': {
      display: 'Oefentherapeuten, methode cesar, kinderoefentherapie',
    },
    '0706': {
      display: 'Oefentherapeuten, methode mensendieck, kinderoefentherapie',
    },
    '0707': {
      display: 'Oefentherapeuten, methode cesar, psychosomatische oefentherapie',
    },
    '0708': {
      display: 'Oefentherapeuten, methode mensendieck, psychosomatische oefentherapie',
    },
    '0709': {
      display: 'Oefentherapeuten, direct toegankelijk',
    },
    '0710': {
      display: 'Oefentherapeuten, Methode Cesar, direct toegankelijk',
    },
    '0711': {
      display: 'Oefentherapeuten, Methode Mensendieck, direct toegankelijk',
    },
    '0712': {
      display: 'Oefentherapeuten, kinderoefentherapie, direct toegankelijk',
    },
    '0713': {
      display: 'Oefentherapeuten, psychosomatische oefentherapie, direct toegankelijk',
    },
    '0714': {
      display: 'Oefentherapeuten, Methode Cesar, kinderoefentherapie, direct toegankelijk',
    },
    '0715': {
      display: 'Oefentherapeuten, Methode Mensendieck, kinderoefentherapie, direct toegankelijk',
    },
    '0716': {
      display: 'Oefentherapeuten, Methode Cesar, psychosomatische oefentherapie, direct toegankelijk',
    },
    '0717': {
      display: 'Oefentherapeuten, Methode Mensendieck, psychosomatische oefentherapie, direct toegankelijk',
    },
    '0718': {
      display: 'Oefentherapeut Leefstijlcoach',
    },
    '0719': {
      display: 'Bekkenoefentherapie',
    },
    '0720': {
      display: 'Geriatrie Oefentherapie',
    },
    '0800': {
      display: 'Verloskundigen, niet nader gespecificeerd',
    },
    '0801': {
      display: 'Verloskundigen, met bevoegdheid echoscopie',
    },
    '0802': {
      display: 'Verloskundigen, echoscopie NT',
    },
    '0803': {
      display: 'Verloskundigen, echoscopie SEO',
    },
    '0804': {
      display: 'Verloskundigen, echoscopie uitwendig',
    },
    '1100': {
      display: 'Tandartsspecialisten mondziekten en kaakchirurgie',
    },
    '1101': {
      display: 'Mondzorg en kaakchirurgie, implantoloog',
    },
    '1200': {
      display: 'Tandartsen, algemeen practicus',
    },
    '1201': {
      display: 'Tandarts, implantoloog',
    },
    '1202': {
      display: 'Tandartsen, pedodontoloog',
    },
    '1203': {
      display: 'Tandartsen, endododontoloog',
    },
    '1210': {
      display: 'Tandarts algemeen practicus, Pedodontoloog',
    },
    '1211': {
      display: 'Tandarts algemeen practicus, Endodontoloog',
    },
    '1212': {
      display: 'Tandarts, Endodontoloog, Pedodontoloog',
    },
    '1213': {
      display: 'Tandarts algemeen practicus, Pedodontoloog, Endodontoloog',
    },
    '1300': {
      display: 'Tandartsspecialisten dentomaxillaire orthopaedie',
    },
    '1301': {
      display: 'Tandartsspecialisten dentomaxillaire orthopaedie, Implantologie',
    },
    '1400': {
      display: 'Arts arbeid en gezondheid',
    },
    '1401': {
      display: 'Arts arbeid en gezondheid, zorgverlener',
    },
    '1402': {
      display: 'Arts arbeid en gezondheid, bedrijfsgeneeskunde',
    },
    '1403': {
      display: 'Arts arbeid en gezondheid, verzekeringsgeneeskunde',
    },
    '1410': {
      display: 'Arts arbeid en gezondheid, bedrijfs- en verzekeringsgeneeskunde',
    },
    '1700': {
      display: 'Rechtspersonen',
    },
    '1800': {
      display: 'Dialysecentra',
    },
    '1900': {
      display: 'Audiologische centra',
    },
    '2000': {
      display: 'Radiotherapeutische centra',
    },
    '2100': {
      display: 'Dienstenstructuren (ANW-diensten)',
    },
    '2200': {
      display: 'Zelfstandige behandelcentra (ZBC)',
    },
    '2270': {
      display: 'Zelfstandige behandelcentra (EMS)',
    },
    '2300': {
      display: 'Inrichtingen voor revalidatiedagbehandeling',
    },
    '2400': {
      display: 'Dietisten',
    },
    '2401': {
      display: 'Dietisten, direct toegankelijk / 1e lijn',
    },
    '2402': {
      display: 'Diëtist Leefstijlcoach',
    },
    '2410': {
      display: 'Dietisten, dietist, direct toegankelijk',
    },
    '2500': {
      display: 'Inrichtingen voor psychiatrische deeltijdbehandeling',
    },
    '2600': {
      display: 'Podotherapeuten',
    },
    '2601': {
      display: 'Sportpodotherapeut',
    },
    '2602': {
      display: 'Podotherapeuten, sportpodotherapeut',
    },
    '3000': {
      display: 'Instelling voor verstandelijk gehandicapten',
    },
    '3100': {
      display: 'Bloedbanken',
    },
    '3200': {
      display: "GGD's (instellingen)",
    },
    '3300': {
      display: 'Kraamcentra',
    },
    '3400': {
      display: 'Trombosediensten',
    },
    '3500': {
      display: 'Instellingen voor visueel gehandicapten',
    },
    '3600': {
      display: 'Ambulancevervoerders',
    },
    '3700': {
      display: 'Gezondheidscentra',
    },
    '3800': {
      display: 'Instellingen voor bijzondere tandheelkunde en mondzorg',
    },
    '3801': {
      display: 'Instelling voor bijzondere tandheelkunde, collectief tijdstarief',
    },
    '3802': {
      display: 'Instelling voor bijzondere tandheelkunde, individueel tijdstarief',
    },
    '3803': {
      display: 'Instelling voor Mondzorg',
    },
    '3900': {
      display: 'Regionale instellingen voor jeugdtandverzorging',
    },
    '4000': {
      display: 'Instellingen voor auditief gehandicapten',
    },
    '4100': {
      display: 'Beheerstichtingen verpleeghuizen, verzorgingshuizen en thuiszorg',
    },
    '4101': {
      display: 'ZZP-ers in wijkverpleging',
    },
    '4102': {
      display: 'PGB aanbieders',
    },
    '4200': {
      display: 'Verzorgingstehuizen',
    },
    '4300': {
      display: 'Beheerstichtingen verzorgingshuizen',
    },
    '4400': {
      display: 'Optometristen',
    },
    '4401': {
      display: 'Optometristen, direct toegankelijk',
    },
    '4402': {
      display: 'Orthoptisten',
    },
    '4500': {
      display: 'Verpleeginrichtingen somatische zieken',
    },
    '4600': {
      display: 'Verpleeginrichtingen psycho-geriatrische patienten',
    },
    '4700': {
      display: 'Gecombineerde verpleeginrichtingen',
    },
    '4800': {
      display: 'Overige instellingen',
    },
    '4801': {
      display: 'Gemeenten',
    },
    '4802': {
      display: 'Instelling voor Reizigersvaccinatie',
    },
    '4900': {
      display: 'Abortusklinieken, beheerstichtingen verpleeghuizen',
    },
    '5000': {
      display: 'Laboratoria',
    },
    '5100': {
      display: 'Klinisch-genetisch centra',
    },
    '5200': {
      display: 'Eurotransplant',
    },
    '5300': {
      display: 'Diverse samenwerkingsverbanden, niet nader gespecificeerd.',
    },
    '5301': {
      display: 'Diverse samenwerkingsverbanden, DM type 2',
    },
    '5302': {
      display: 'Diverse samenwerkingsverbanden, CVR',
    },
    '5303': {
      display: 'Diverse samenwerkingsverbanden, COPD',
    },
    '5310': {
      display: 'Diverse samenwerkingsverbanden, DM type 2 en CVR',
    },
    '5311': {
      display: 'Diverse samenwerkingsverbanden, DM type 2, CVR en COPD',
    },
    '5312': {
      display: 'Diverse samenwerkingsverbanden, DM type 2 en COPD',
    },
    '5313': {
      display: 'Diverse samenwerkingsverbanden, CVR en COPD',
    },
    '5314': {
      display: 'Integrale geboortezorg',
    },
    '5316': {
      display: 'Samenwerkingsverband GLI',
    },
    '5400': {
      display: 'GGZ Instellingen (PUK/PAAZ)',
    },
    '5600': {
      display: 'Consultatiebureaus voor alcohol en drugs',
    },
    '5700': {
      display: 'Physician Assistant',
    },
    '5800': {
      display: "Centrale posten ambulancediensten (CPA's)",
    },
    '6000': {
      display: 'Instellingen voor dagverpleging voor ouderen',
    },
    '6100': {
      display: 'Beheerstichtingen dagverblijven',
    },
    '6500': {
      display: 'Gezinsvervangende tehuizen',
    },
    '6600': {
      display: 'Koepels en beheerstichtingen WLZ',
    },
    '6701': {
      display: 'Regionetwerk Casemanagement Dementie',
    },
    '7000': {
      display: 'Kinderdagverblijven',
    },
    '7100': {
      display: 'Betekenisloze vestigingscode Alleen voor gebruik in de AGB-registratie.',
    },
    '7200': {
      display: 'Regionale instellingen beschermd wonen (RIBW)',
    },
    '7300': {
      display: 'WLZ-gecombineerd',
    },
    '7400': {
      display: 'ARBO-diensten',
    },
    '7500': {
      display: 'Thuiszorg, kruiswerk en gezinsverzorging',
    },
    '7600': {
      display: 'Leveranciers hulpmiddelen, niet nader gespecificeerd',
    },
    '7601': {
      display: 'Leveranciers hulpmiddelen, ledemaatprothesen',
    },
    '7602': {
      display: 'Leveranciers hulpmiddelen, mammaprothesen',
    },
    '7603': {
      display: 'Leveranciers hulpmiddelen, oogprothesen',
    },
    '7604': {
      display: 'Leveranciers hulpmiddelen, prothetische voorzieningen voor onder- en bovenkaak',
    },
    '7605': {
      display: 'Leveranciers hulpmiddelen, gelaatsprothesen',
    },
    '7606': {
      display: 'Leveranciers hulpmiddelen, corsetten',
    },
    '7607': {
      display: 'Leveranciers hulpmiddelen, orthopedisch schoeisel',
    },
    '7608': {
      display: 'Leveranciers hulpmiddelen, overige orthesen',
    },
    '7609': {
      display: 'Leveranciers hulpmiddelen, verbandschoenen',
    },
    '7611': {
      display: 'Leveranciers hulpmiddelen, brillenglazen',
    },
    '7612': {
      display: 'Leveranciers hulpmiddelen, contactlenzen',
    },
    '7613': {
      display: 'Leveranciers hulpmiddelen, bijzondere gezichtshulpmiddelen',
    },
    '7614': {
      display: 'Leveranciers hulpmiddelen, bijzondere gezichtshulpmiddelen algemeen',
    },
    '7615': {
      display: 'Leveranciers hulpmiddelen, bijzondere gezichtshulpmiddelen maatwerk',
    },
    '7616': {
      display: 'Leveranciers hulpmiddelen, hoortoestellen',
    },
    '7617': {
      display: 'Leveranciers hulpmiddelen, extern hoortoestel in combinatie met implantaat (BAHA)',
    },
    '7618': {
      display: 'Leveranciers hulpmiddelen, maskeerder ter behandeling van oorsuizen (Tinnetusmaskeerder)',
    },
    '7619': {
      display: 'Leveranciers hulpmiddelen, gehoorhulpmiddelen (inclusief maskeerder)',
    },
    '7620': {
      display: 'Leveranciers hulpmiddelen, solo apparatuur',
    },
    '7621': {
      display: 'Leveranciers hulpmiddelen, incontinentie-absorptiemateriaal',
    },
    '7622': {
      display: 'Leveranciers hulpmiddelen, overige verzorgingsmiddelen',
    },
    '7623': {
      display: 'Leveranciers hulpmiddelen, draagbare uitwendige infuuspomp',
    },
    '7624': {
      display: 'Leveranciers hulpmiddelen, incontinentie afvoerend materiaal',
    },
    '7625': {
      display: 'Leveranciers hulpmiddelen, stoma materialen',
    },
    '7626': {
      display: 'Leveranciers hulpmiddelen, slijmuitzuigapparatuur',
    },
    '7627': {
      display: 'Leveranciers hulpmiddelen, confectie orthesen',
    },
    '7628': {
      display: 'Leveranciers hulpmiddelen, maatwerk orthesen',
    },
    '7629': {
      display: 'Leveranciers hulpmiddelen, tracheacanule en stemprothesen',
    },
    '7630': {
      display: 'Leveranciers hulpmiddelen, steunzolen',
    },
    '7631': {
      display: 'Leveranciers hulpmiddelen, loophulpmiddelen',
    },
    '7632': {
      display: 'Leveranciers hulpmiddelen, eenvoudige hulpmiddelen voor mobiliteit van personen',
    },
    '7633': {
      display: 'Leveranciers hulpmiddelen, overige hulpmiddelen voor mobiliteit van personen',
    },
    '7634': {
      display: 'Leveranciers hulpmiddelen, blindegeleidehonden',
    },
    '7635': {
      display: 'Leveranciers hulpmiddelen, hulphonden',
    },
    '7636': {
      display: 'Leveranciers hulpmiddelen, pruiken',
    },
    '7637': {
      display: 'Leveranciers hulpmiddelen, arm-, hand-, vingerfunctievervullers (robotarm)',
    },
    '7638': {
      display: 'Leveranciers hulpmiddelen, anaalspoelapparatuur',
    },
    '7639': {
      display: 'Leveranciers hulpmiddelen, plaswekker',
    },
    '7640': {
      display: 'Leveranciers hulpmiddelen, bewakingsmonitor voor baby',
    },
    '7641': {
      display: 'Leveranciers hulpmiddelen, elastische kousen',
    },
    '7642': {
      display: 'Leveranciers hulpmiddelen, allergeenvrije hoezen',
    },
    '7643': {
      display: 'Leveranciers hulpmiddelen, verbandmiddelen',
    },
    '7644': {
      display: 'Leveranciers hulpmiddelen, UV-B lichttherapie',
    },
    '7645': {
      display: 'Leveranciers hulpmiddelen, allergeenvrije schoenen',
    },
    '7646': {
      display: 'Leveranciers hulpmiddelen, anti-conceptionele hulpmiddelen',
    },
    '7647': {
      display: 'Leveranciers hulpmiddelen, kappen ter bescherming van de schedel',
    },
    '7649': {
      display: 'Leveranciers hulpmiddelen, thuisdialyse apparatuur',
    },
    '7650': {
      display: 'Leveranciers hulpmiddelen, zelfmeetapparatuur voor bloedstollingstijden',
    },
    '7651': {
      display: 'Leveranciers hulpmiddelen, injectiespuiten',
    },
    '7656': {
      display: 'Leveranciers hulpmiddelen, insuline-infuuspompen',
    },
    '7657': {
      display: 'Leveranciers hulpmiddelen, overige hulpmiddelen bij diabetes',
    },
    '7661': {
      display: 'Leveranciers hulpmiddelen, zuurstofapparaten en -concentrators',
    },
    '7662': {
      display: 'Leveranciers hulpmiddelen, apparatuur voor verzekerden met ademhalingsstoornissen (CPAP)',
    },
    '7663': {
      display: 'Leveranciers hulpmiddelen, apparatuur voor positieve uitademingsdruk',
    },
    '7666': {
      display: 'Leveranciers hulpmiddelen, longvibrators',
    },
    '7671': {
      display: 'Leveranciers hulpmiddelen, vernevelaars',
    },
    '7676': {
      display: 'Leveranciers hulpmiddelen, beeldschermloep',
    },
    '7677': {
      display: 'Leveranciers hulpmiddelen, tactiel leesapparatuur',
    },
    '7681': {
      display: 'Leveranciers hulpmiddelen, elektrastimulator',
    },
    '7682': {
      display: 'Leveranciers hulpmiddelen, botgroei-stimulators',
    },
    '7685': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor het toedienen van voeding',
    },
    '7686': {
      display: 'Leveranciers hulpmiddelen, dieetpreparaten',
    },
    '7687': {
      display: 'Leveranciers hulpmiddelen, voeding',
    },
    '7688': {
      display: 'Leveranciers hulpmiddelen, eetapparaten',
    },
    '7689': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communicatie voor lichamelijk gehandicapten',
    },
    '7690': {
      display: 'Leveranciers hulpmiddelen, inrichtingselementen van woningen',
    },
    '7691': {
      display: 'Leveranciers hulpmiddelen, inrichtingselementen van woningen',
    },
    '7692': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communiatie persoonlijke alarmering',
    },
    '7693': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communiatie-, wek- en waarschuwing',
    },
    '7694': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communiatie bij revalidatie',
    },
    '7695': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communicatie, informatie en signalering',
    },
    '7696': {
      display: 'Leveranciers hulpmiddelen, fax-apparatuur voor auditief gehandicapten',
    },
    '7697': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communicatie grootlettersystemen',
    },
    '7698': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communicatie-, opname- en voorleesapparatuur',
    },
    '7699': {
      display: 'Leveranciers hulpmiddelen, hulpmiddelen voor communicatie-, telefoons en telefoneerhulpmiddelen',
    },
    '7800': {
      display: 'Sociaal pedagogische diensten',
    },
    '7900': {
      display: "RIAGG's",
    },
    '8000': {
      display: 'Niet erkende verpleeginrichtingen',
    },
    '8100': {
      display: 'Niet erkende zwakzinnigeninrichtingen',
    },
    '8200': {
      display: 'Bejaardenoorden',
    },
    '8300': {
      display: 'Overige niet erkende instellingen',
    },
    '8400': {
      display: 'Overige artsen, niet nader gespecificeerd',
    },
    '8401': {
      display: 'Arts, chiropractie',
    },
    '8402': {
      display: 'Arts, musculoskeletale geneeskunde',
    },
    '8403': {
      display: 'Arts, acupunctuur',
    },
    '8404': {
      display: 'Arts, irisscopie',
    },
    '8405': {
      display: 'Arts, homeopathie',
    },
    '8406': {
      display: 'Arts, natuurgeneeskunde',
    },
    '8407': {
      display: 'Arts, antroposofische geneeskunde',
    },
    '8408': {
      display: 'Overige artsen, moermantherapie',
    },
    '8409': {
      display: 'Arts, enzymtherapie',
    },
    '8410': {
      display: 'Arts, manuele geneeskunde',
    },
    '8411': {
      display: 'Arts, haptotherapie',
    },
    '8412': {
      display: 'Arts, osteopathie',
    },
    '8413': {
      display: 'Overige artsen, flebologie/proctologie',
    },
    '8414': {
      display: 'Overige artsen, orthomoleculaire geneeskunde',
    },
    '8415': {
      display: 'Overige artsen, neurale therapie',
    },
    '8416': {
      display: 'Overige artsen, sportgeneeskunde',
    },
    '8417': {
      display: 'Overige artsen, SEH-arts',
    },
    '8418': {
      display: 'Overige artsen, specialist ouderengeneeskunde',
    },
    '8419': {
      display: 'Overige artsen, schoolartsen',
    },
    '8420': {
      display: 'Overige artsen, consultatiebureau-artsen',
    },
    '8421': {
      display: 'Overige artsen, SCEN-arts',
    },
    '8422': {
      display: 'Overige artsen, orthopedische geneeskunde',
    },
    '8423': {
      display: 'Overige artsen, GGD artsen (TBC artsen)',
    },
    '8424': {
      display: 'Overige artsen, verslavingsartsen',
    },
    '8425': {
      display: 'Overige artsen, basisarts',
    },
    '8426': {
      display: 'Overige artsen, arts voor verstandelijk gehandicapten',
    },
    '8427': {
      display: 'Overige artsen, arts palliatieve geneeskunde',
    },
    '8428': {
      display: 'Overige artsen, jeugdarts',
    },
    '8429': {
      display: 'Overige artsen, Klinisch Fysicus audioloog',
    },
    '8430': {
      display: 'Arts maatschappij en gezondheid / Infectieziektebestrijding',
    },
    '8431': {
      display: 'Medisch seksuoloog',
    },
    '8432': {
      display: 'Klinisch technoloog',
    },
    '8433': {
      display: 'Cosmetisch arts',
    },
    '8434': {
      display: 'Ziekenhuisarts',
    },
    '8500': {
      display: 'Taxivervoerders',
    },
    '8600': {
      display: 'Instellingen voor maatschappelijke dienstverlening',
    },
    '8700': {
      display: 'Mondhygienisten',
    },
    '8800': {
      display: 'Ergotherapeuten',
    },
    '8801': {
      display: 'Ergotherapie, direct toegankelijk / 1e lijn',
    },
    '8810': {
      display: 'Ergotherapeuten, ergotherapie, direct toegankelijk',
    },
    '8900': {
      display: 'Schoonheidsspecialisten, niet nader gespecificeerd',
    },
    '8901': {
      display: 'Schoonheidsspecialisten, acne',
    },
    '8902': {
      display: 'Schoonheidsspecialisten, camouflage',
    },
    '8903': {
      display: 'Schoonheidsspecialisten, elektrisch epileren',
    },
    '8904': {
      display: 'Schoonheidsspecialist',
    },
    '8905': {
      display: 'Acne',
    },
    '8906': {
      display: 'Camouflage',
    },
    '8907': {
      display: 'Elektrisch ontharen',
    },
    '8908': {
      display: 'Schoonheidsspecialist(e), acne en camouflage, ANBOS geregistreerd',
    },
    '8909': {
      display: 'Schoonheidsspecialist(e), acne en elektrisch epileren, ANBOS geregistreerd',
    },
    '8910': {
      display: 'Schoonheidsspecialist(e), camouflage en elektrisch epileren, ANBOS geregistreerd',
    },
    '8911': {
      display: 'Schoonheidsspecialist(e), acne, camouflage en elektrisch epileren, ANBOS geregistreerd',
    },
    '8912': {
      display: 'Schoonheidsspecialist(e), niet ANBOS geregistreerd',
    },
    '8913': {
      display: 'Ontharingstechnieken',
    },
    '8914': {
      display: 'Schoonheidsspecialist(e), ontharingstechnieken en acne, ANBOS geregistreerd',
    },
    '8915': {
      display: 'Schoonheidsspecialist(e), ontharingstechnieken en camouflage, ANBOS geregistreerd',
    },
    '8916': {
      display: 'Schoonheidsspecialist(e), ontharingstechnieken, acne en camouflage, ANBOS geregistreerd',
    },
    '8919': {
      display: 'Schoonheidsspecialist(e), elektrisch epileren en ontharingstechnieken, ANBOS geregistreerd',
    },
    '8920': {
      display: 'Schoonheidsspecialist(e), Acne, camouflage en ontharingstechnieken, ANBOS geregistreerd',
    },
    '8921': {
      display: 'Schoonheidsspecialist(e), acne, elektrisch epileren en ontharingstechnieken, ANBOS geregistreerd',
    },
    '8922': {
      display: 'Schoonheidsspecialist(e), camouflage, elektrisch epileren en ontharingstechnieken, ANBOS geregistreerd',
    },
    '8923': {
      display:
        'Schoonheidsspecialist(e), acne, camouflage, elektrisch epileren en ontharingstechnieken, ANBOS geregistreerd',
    },
    '8924': {
      display: 'Algemene schoonheids-specialist ANBOS geregistreerd, Acne ANBOS geregistreerd',
    },
    '9000': {
      display: 'Overige therapeuten en Complementair en Aanvullende zorg',
    },
    '9001': {
      display: 'Chiropractie',
    },
    '9002': {
      display: 'Orthomanuele geneeskunde',
    },
    '9003': {
      display: 'Acupunctuur',
    },
    '9004': {
      display: 'Irisscopie',
    },
    '9005': {
      display: 'Homeopathie',
    },
    '9006': {
      display: 'Natuurgeneeskunde',
    },
    '9007': {
      display: 'Antroposofische geneeskunde',
    },
    '9008': {
      display: 'Moerman therapie',
    },
    '9009': {
      display: 'Enzymtherapie',
    },
    '9010': {
      display: 'Manuele geneeskunde',
    },
    '9011': {
      display: 'Haptotherapie',
    },
    '9012': {
      display: 'Osteopathie',
    },
    '9013': {
      display: 'Huidtherapie',
    },
    '9014': {
      display: 'Kunstzinnige therapie',
    },
    '9015': {
      display: 'Podologie',
    },
    '9016': {
      display: 'Orthomoleculaire geneeskunde',
    },
    '9017': {
      display: 'Shiatsu therapie',
    },
    '9018': {
      display: 'Echoscopie',
    },
    '9020': {
      display: 'Seksuologen, Lid NVVS',
    },
    '9021': {
      display: 'Overigen',
    },
    '9022': {
      display: 'Cranio sacraal therapie',
    },
    '9023': {
      display: 'Adem- en ontspanningstherapie methode van Dixhoorn',
    },
    '9024': {
      display: 'Kindertherapie VvvK',
    },
    '9025': {
      display: 'Vaktherapie',
    },
    '9026': {
      display: 'Echoscopie NT',
    },
    '9027': {
      display: 'Echoscopie SEO',
    },
    '9028': {
      display: 'Echoscopie uitwendig',
    },
    '9029': {
      display: 'Gewichtsconsulent',
    },
    '9030': {
      display: 'Lactatiedeskundige',
    },
    '9031': {
      display: 'Huidtherapie, direct toegankelijk',
    },
    '9032': {
      display: 'Reflexzonetherapeut',
    },
    '9033': {
      display: 'Doula',
    },
    '9034': {
      display: 'Yogadocenten',
    },
    '9035': {
      display: 'Sportmasseur',
    },
    '9036': {
      display: 'Preventieve voetzorg',
    },
    '9037': {
      display: 'Psychosociaal zorgverlener',
    },
    '9038': {
      display: 'Leefstijlcoach',
    },
    '9100': {
      display: 'Verpleegkundigen, niet nader gespecificeerd',
    },
    '9101': {
      display: 'Gespecialiseerd verpleegkundige, diabetes',
    },
    '9102': {
      display: 'Gespecialiseerd verpleegkundige, incontinentie',
    },
    '9103': {
      display: 'Gespecialiseerd verpleegkundige, wijk',
    },
    '9104': {
      display: 'Gespecialiseerd verpleegkundige, wond',
    },
    '9105': {
      display: 'Gespecialiseerd verpleegkundige, stoma',
    },
    '9106': {
      display: 'Gespecialiseerd verpleegkundige, long',
    },
    '9107': {
      display: 'Gespecialiseerd verpleegkundige, oncologie',
    },
    '9108': {
      display: 'Gespecialiseerd verpleegkundige, transfer',
    },
    '9109': {
      display: 'Verpleegkundigen niveau 4',
    },
    '9110': {
      display: 'Verpleegkundigen niveau 5',
    },
    '9111': {
      display: 'Verzorgenden',
    },
    '9112': {
      display: 'Verpleegkundigen niveau 4 PGB',
    },
    '9113': {
      display: 'Verpleegkundigen niveau 5 PGB',
    },
    '9114': {
      display: 'Verpleegkundigen niveau 4 en Verpleegkundigen niveau 4 PGB',
    },
    '9115': {
      display: 'Verpleegkundigen niveau 5 en Verpleegkundigen niveau 5 PGB',
    },
    '9130': {
      display: 'Verpleegkundig specialist, preventieve zorg bij somatische aandoeningen',
    },
    '9131': {
      display: 'Verpleegkundig specialist, acute zorg bij somatische aandoeningen',
    },
    '9132': {
      display: 'Verpleegkundig specialist, intensieve zorg bij somatische aandoeningen',
    },
    '9133': {
      display: 'Verpleegkundig specialist, chronische zorg bij somatische aandoeningen',
    },
    '9134': {
      display: 'Verpleegkundig specialist, geestelijke gezondheidszorg',
    },
    '9140': {
      display: 'Verpleegkundig specialist, preventieve zorg bij somatische aandoeningen (9100 + 9130)',
    },
    '9141': {
      display: 'Verpleegkundig specialist, acute zorg bij somatische aandoeningen (9100 + 9131)',
    },
    '9142': {
      display: 'Verpleegkundig specialist, intensieve zorg bij somatische aandoeningen (9100 + 9132)',
    },
    '9143': {
      display: 'Verpleegkundig specialist, chronische zorg bij somatische aandoeningen (9100 + 9133)',
    },
    '9144': {
      display: 'Verpleegkundig specialist, geestelijke gezondheidszorg (9100 + 9134)',
    },
    '9150': {
      display: 'Gespecialiseerd verpleegkundige, diabetes (9100 + 9101)',
    },
    '9151': {
      display: 'Gespecialiseerd verpleegkundige, incontinentie (9100 + 9102)',
    },
    '9152': {
      display: 'Gespecialiseerd verpleegkundige, wijk (9100 + 9103)',
    },
    '9153': {
      display: 'Gespecialiseerd verpleegkundige, wond (9100 + 9104)',
    },
    '9154': {
      display: 'Gespecialiseerd verpleegkundige, stoma (9100 + 9105)',
    },
    '9155': {
      display: 'Gespecialiseerd verpleegkundige, long (9100 + 9106)',
    },
    '9156': {
      display: 'Gespecialiseerd verpleegkundige, oncologie (9100 + 9107)',
    },
    '9157': {
      display: 'Gespecialiseerd verpleegkundige, transfer (9100 + 9108)',
    },
    '9180': {
      display: 'Verzorgende (ZZP)',
    },
    '9181': {
      display: 'Verpleegkundige (ZZP)',
    },
    '9190': {
      display: 'Administratieve eenheid (AE)',
    },
    '9200': {
      display: 'Ziekenhuisvervangende thuiszorg (financiering 2e compartiment)',
    },
    '9300': {
      display: 'Tandtechnici/tandprothetici, niet nader gespecificeerd',
    },
    '9301': {
      display: 'Tandprotheticus',
    },
    '9302': {
      display: 'Tandtechnicus',
    },
    '9400': {
      display: 'Psychologische zorgverleners, niet nader gespecificeerd',
    },
    '9401': {
      display: 'Psychologische zorgverleners, GZ-psychologie met eerstelijnskwalificatie',
    },
    '9402': {
      display: 'Psychologische zorgverleners, psychotherapie',
    },
    '9403': {
      display: 'Psychologische zorgverleners, sexuologie',
    },
    '9404': {
      display: 'Psychologische zorgverleners, K&J-psychologie',
    },
    '9405': {
      display: 'Psychologische zorgverleners, klinische psychologie',
    },
    '9406': {
      display: 'Psychologische zorgverleners, GZ-psychologie',
    },
    '9407': {
      display: 'Psychologische zorgverleners, GZ-psychologie, psychotherapie',
    },
    '9408': {
      display: 'Psychologische zorgveleners, GZ-psychologie met eerstelijnskwalificatie, psychotherapie',
    },
    '9409': {
      display:
        'Psychologische zorgverleners, GZ-psychologie met eerstelijnskwalificatie, kinder- en jeugdpsychologie en/of NVO Orthopedagogie-Generalist',
    },
    '9410': {
      display:
        'Psychologische zorgverleners, psychotherapie, kinder- en jeugdpsychologie en/of NVO Orthopedagogie-Generalist',
    },
    '9411': {
      display:
        'Psychologische zorgverleners, GZ-psychologie, psychotherapie, kinder- en jeugdpsychologie en/of NVO Orthopedagogie-Generalist',
    },
    '9412': {
      display:
        'Psycho. zorgverl., GZ-psychologie met eerstelijnskwal., psychotherapie, kinder- en jeugdpsychologie en/of NVO Orthopedagogie-Generalist',
    },
    '9413': {
      display:
        'Psychologische zorgverleners, klinische psychologie, kinder- en jeugdpsychologie en/of NVO Orthopedagogie-Generalist',
    },
    '9414': {
      display: 'Psychologische zorgverleners, klinische psychologie met eerstelijnskwalificatie',
    },
    '9415': {
      display:
        'Psycho. zorgverl., klinisch psychologie met eerstelijnskwalificatie, kinder- en jeugdpsychologie en/of NVO Orthopedagogie-Generalist',
    },
    '9416': {
      display: 'Psychologische zorgverleners, psycholoog NIP/ master-psycholoog SJK',
    },
    '9417': {
      display: 'Psychologische zorgverleners, orthopedagoog (generalist, specialist)',
    },
    '9418': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie',
    },
    '9419': {
      display: 'Psychologische zorgverleners, orthopedagogen (overig)',
    },
    '9420': {
      display: 'Psychologische zorgverleners, orthopedagogen (basis)',
    },
    '9421': {
      display: 'Psychologische zorgverleners, therapeut nap',
    },
    '9422': {
      display: 'Psychologische zorgverleners, therapeut nvpa',
    },
    '9423': {
      display: 'Psychologische zorgverleners, kinder en jeugdpsycholoog nip',
    },
    '9424': {
      display: 'Psychologische zorgverleners, klinische psychologie en psychotherapie',
    },
    '9425': {
      display: 'Psychologische zorgverleners, klinische psychologie, 1e lijn en psychotherapie',
    },
    '9426': {
      display:
        'Psychologische zorgverleners, klinische psychologie, 1e lijn, psychotherapie, K&J psychologie en/of orthopedagoog',
    },
    '9427': {
      display: 'Psychologische zorgverleners, klinische psychologie, K&J psychologie en psychotherapie',
    },
    '9430': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie en 1e lijn',
    },
    '9431': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie en K&J psychologie en/of orthopedagoog',
    },
    '9432': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie en klinische psychologie',
    },
    '9433': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, 1e lijn en K&J psychologie en/of orthopedagoog',
    },
    '9434': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie, 1e lijn en klinische psychologie',
    },
    '9435': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, 1e lijn, klinische psychologie en K&J psychologie en/of orthopedagoog',
    },
    '9436': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie en psychotherapie',
    },
    '9437': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie, 1e lijn en psychotherapie',
    },
    '9438': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, psychotherapie en K&J psychologie en/of orthopedagoog',
    },
    '9439': {
      display: 'Psychologische zorgverleners, klinische neuropsychologie, klinische psychologie en psychotherapie',
    },
    '9440': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, 1e lijn, psychotherapie en K&J psychologie en/of orthopedagoog',
    },
    '9441': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, 1e lijn, klinische psychologie en psychotherapie',
    },
    '9442': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, 1e lijn, klinische psychologie, psychotherapie, K&J psychologie en/of orthopedag.',
    },
    '9443': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, klinische psychologie, K&J psychologie en/of orthopedagoog.',
    },
    '9444': {
      display:
        'Psychologische zorgverleners, klinische neuropsychologie, klinische psychologie, psychotherapie, K&J psychologie en/of orthopedagoog.',
    },
    '9445': {
      display: 'Psychologische zorgverleners, klinisch psychologie, gz-psychologie, psychotherapie',
    },
    '9446': {
      display: 'Psychologische zorgverleners, klinisch psychologie, gz-psychologie, 1e lijn, psychotherapie',
    },
    '9447': {
      display:
        'Psychologische zorgverleners, klinisch psychologie, gz-psychologie, 1e lijn, psychotherapie, K&J psycholoog',
    },
    '9448': {
      display: 'Psychologische zorgverleners, klinisch psychologie, gz-psychologie, psychotherapie, K&J psycholoog',
    },
    '9449': {
      display: 'Psychologische zorgverleners, gz-psychologie 1e lijn, K&J psycho e/o orthopedagoog, psychotherapeut',
    },
    '9450': {
      display: 'Psychologische zorgverleners, psycholoog NIP, gz psychologie',
    },
    '9451': {
      display: 'Psychologische zorgverleners, psycholoog NIP, psychotherapeut',
    },
    '9452': {
      display: 'Psychologische zorgverleners, kinder- en jeugdpsycholoog NIP, gz psychologie',
    },
    '9453': {
      display: 'Psychologische zorgverleners, kinder- en jeugdpsycholoog NIP, psychotherapie',
    },
    '9454': {
      display: 'Psychologische zorgverleners, psycholoog NIP, gz psychologie 1e lijn',
    },
    '9455': {
      display: 'Psychologische zorgverleners, GZ-psycholoog, orthopedagoog (generalist, specialist)',
    },
    '9456': {
      display: 'Psychologische zorgverleners, K&J-psycholoog, GZ-psycholoog, orthopedagoog (generalist, specialist)',
    },
    '9457': {
      display: 'Psychologische zorgverleners, kinder- en jeugdpsycholoog NIP, GZ-psychologie, 1e lijn',
    },
    '9458': {
      display: 'Psychologische zorgverleners, psycholoog NIP, orthopedagoog (generalist, specialist)',
    },
    '9459': {
      display: 'Psychologische zorgverleners, psycholoog NIP, therapeut NVPA',
    },
    '9460': {
      display:
        'Psychologische zorgverleners, GZ-psycholoog, kinder- en jeugdpsycholoog NIP, orthopedagoog (generalist, specialist)',
    },
    '9461': {
      display: 'Psychologische zorgverleners, klinisch psycholoog, kinder- en jeugdpsycholoog NIP',
    },
    '9462': {
      display: 'Psychologische zorgverleners, psycholoog Arbeid en Gezondheid NIP',
    },
    '9463': {
      display: 'Psychologische zorgverleners, master pedagoog',
    },
    '9464': {
      display: 'Psychologische zorgverleners, jeugd- en gezinsprofessional',
    },
    '9465': {
      display: 'Psychologische zorgverleners, kinder- en jeugdpsycholoog',
    },
    '9466': {
      display: 'Psychologische zorgverleners, postmaster orthopedagoog',
    },
    '9467': {
      display: 'Psychologische zorgverleners, GZ-psychologie 1e lijn, master pedagoog',
    },
    '9468': {
      display: 'Psychologische zorgverleners, GZ-psychologie 1e lijn, jeugdzorgwerker',
    },
    '9469': {
      display: 'Psychologische zorgverleners, kinder- en jeugdpsycholoog, postmaster orthopedagoog',
    },
    '9470': {
      display:
        'Psychologische zorgverleners, GZ-psycholoog 1e lijn, kinder- en jeugdpsycholoog, postmaster orthopedagoog',
    },
    '9500': {
      display: 'Orthopedagogen',
    },
    '9600': {
      display: 'Pedicuren',
    },
    '9601': {
      display: 'Pedicure',
    },
    '9602': {
      display: 'Pedicure, specialisatie reumatische voet',
    },
    '9603': {
      display: 'Pedicure, specialisatie diabetische voet',
    },
    '9604': {
      display: 'Pedicure, specialisatie reumatische voet + diabetische voet',
    },
    '9605': {
      display: 'Pedicure, specialisatie medische pedicure',
    },
    '9606': {
      display: 'Pedicure, pedicure in de zorg',
    },
    '9607': {
      display: 'Pedicure, registerpedicure',
    },
    '9608': {
      display: 'Pedicure, paramedisch chiropodist',
    },
    '9609': {
      display: 'Pedicure, specialisatie Risicovoet',
    },
    '9800': {
      display: 'Declaranten',
    },
    '9801': {
      display: 'Servicebureau',
    },
    '9802': {
      display: 'Aanbieder van maatschappelijke ondersteuning',
    },
    '9803': {
      display: 'Zorgverzekeraar',
    },
    '9804': {
      display: 'Aanbieder PGB WLZ',
    },
    '9900': {
      display: 'Overigen',
    },
    '9901': {
      display: 'Verpleeghuisartsen',
    },
    '9902': {
      display: 'Bedrijfsarts',
    },
    '9903': {
      display: 'Schoolarts',
    },
    '9904': {
      display: 'Sportarts',
    },
  },
};
