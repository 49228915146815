export enum ErrorCodes {
  userCancel = 'USER_CANCEL',
  onboardError = 'ONBOARD_ERROR',
  initializeCheckFail = 'INITIALIZE_CHECK_FAIL',
  invalidCode = 'INVALID_CODE',
  serverError = 'SERVER_ERROR',
  binaryNotFound = 'BINARY_NOT_FOUND',
  serviceAuthorizationError = 'ServiceAuthorizationError',
}

export interface ErrorModel {
  title?: string;
  message?: string;
  code?: string;
}

export function getErrorModel(code: ErrorCodes): ErrorModel {
  const onboardDefaultErrorTitle = $localize`:@@app.Error.onboard.title:Unable to link your records`;
  const defaultErrorTitle = $localize`:@@app.Error.title:Something went wrong`;
  const defaultErrorMessage = $localize`:@@app.Error.message:If you're stuck, reach out to support and we'll do our best to get you unstuck or try again later.`;

  switch (code) {
    case ErrorCodes.onboardError:
      return {
        title: onboardDefaultErrorTitle,
        message: $localize`:@@app.Error.onboard.message:Something went wrong. If you're stuck, reach out to support and we'll do our best to get you unstuck or try again later.`,
        code: code,
      };
    case ErrorCodes.userCancel:
      return {
        title: $localize`:@@app.Error.onboard.userCancel.title:Couldn't find your healthcare provider?`,
        message: $localize`:@@app.Error.onboard.userCancel.message:We are adding more and more healthcare providers every week. If you couldn't find yours let us know and we'll try to fast track it.`,
        code: code,
      };
    case ErrorCodes.binaryNotFound:
      return {
        title: $localize`:@@docs.binary.error.modalTitle:Unable to display this document`,
        message: $localize`:@@docs.binary.error.modalBody:The document may not have been imported, or is no longer attached to your medical record. You can try to refresh all your sources to see if that fixes this issue, or contact your healthcare provider directly.`,
        code: code,
      };
    case ErrorCodes.initializeCheckFail:
    case ErrorCodes.invalidCode:
    case ErrorCodes.serverError:
    default:
      return {
        title: defaultErrorTitle,
        message: defaultErrorMessage,
        code: code,
      };
  }
}
