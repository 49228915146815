import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigatorOnLineService {
  private networkStatusSubject$ = new Subject<boolean>();

  get online$(): Observable<boolean> {
    return this.networkStatusSubject$.asObservable();
  }

  public init(): void {
    window.addEventListener('offline', () => {
      this.networkStatusSubject$.next(false);
    });

    window.addEventListener('online', () => {
      this.networkStatusSubject$.next(true);
    });
  }
}
