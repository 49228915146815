import { NgModule } from '@angular/core';
import { ReferencesModule } from '@hl7fhir/foundation';
import { SharedModule } from '@shared';
import { AnnotationComponent } from './annotation.component';
import { AnnotationsComponent } from './annotations.component';

const components = [AnnotationsComponent, AnnotationComponent];
const foundation = [ReferencesModule];

@NgModule({
  imports: [SharedModule, ...foundation],
  declarations: [...components],
  exports: [...components],
})
export class AnnotationModule {}
