import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { getReference } from '@hl7fhir/foundation';
import { CodeableConceptPipe } from '@hl7fhir/data-types';

export class ImmunizationVaccinationProtocolViewModel extends BackboneElementViewModel<
  | r3.ImmunizationVaccinationProtocol
  | r4.ImmunizationProtocolApplied
  | r4b.ImmunizationProtocolApplied
  | r5.ImmunizationProtocolApplied
> {
  get targetDisease(): string | undefined {
    return this.element?.targetDisease && new CodeableConceptPipe().transform(this.element.targetDisease);
  }

  get series(): string | undefined {
    return this.element?.series;
  }

  get description(): string | undefined {
    const immuneR3 = this.element as r3.ImmunizationVaccinationProtocol;
    return immuneR3?.description;
  }

  get doseSequence(): string | undefined {
    const immuneR3 = this.element as r3.ImmunizationVaccinationProtocol;
    return immuneR3.doseSequence?.toString();
  }

  get seriesDoses(): string | undefined {
    const immuneR3R5 = this.element as r3.ImmunizationVaccinationProtocol | r5.ImmunizationProtocolApplied;
    const immuneR4 = this.element as r4.ImmunizationProtocolApplied | r4b.ImmunizationProtocolApplied;
    return immuneR3R5.seriesDoses?.toString() ?? immuneR4.seriesDosesString;
  }

  get doseNumber(): string | undefined {
    const immuneR5 = this.element as r5.ImmunizationProtocolApplied;
    const immuneR4 = this.element as r4.ImmunizationProtocolApplied | r4b.ImmunizationProtocolApplied;

    return immuneR5.doseNumber?.toString() ?? immuneR4.doseNumberString;
  }

  get doseStatus(): string | undefined {
    const immuneR3 = this.element as r3.ImmunizationVaccinationProtocol;
    return immuneR3.doseStatus && new CodeableConceptPipe().transform(immuneR3.doseStatus);
  }

  get doseStatusReason(): string | undefined {
    const immuneR3 = this.element as r3.ImmunizationVaccinationProtocol;
    return immuneR3.doseStatusReason && new CodeableConceptPipe().transform(immuneR3.doseStatusReason);
  }

  get authority(): string | undefined {
    return this.element?.authority && getReference(this.element.authority);
  }
}
