import { Condition } from '@hl7fhir';
import { ConditionViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromCondition from './condition.reducer';

export const selectConditionState = createFeatureSelector<fromCondition.State>(features.FHIR.conditions);

export const selectConditionEntities = createSelector(selectConditionState, fromCondition.selectConditionEntities);

export const selectAllConditions = createSelector(selectConditionState, fromCondition.selectAllConditions);

export const selectConditionById = createSelector(
  selectRouteParams,
  selectConditionEntities,
  ({ id }, conditions: Dictionary<Condition>) =>
    id && (conditions[id] ?? conditions[id.toLowerCase()] ?? conditions[id.toUpperCase()])
);

export const selectConditionViewModels = createSelector(selectAllConditions, (dataSource: Condition[]) =>
  dataSource.map((item) => new ConditionViewModel(item))
);

export const selectConditionViewModelById = createSelector(
  selectConditionById,
  (entity: Condition | undefined) => entity && new ConditionViewModel(entity)
);
