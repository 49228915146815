<div ngbAccordionBody
     class="pe-0 py-0">
  <ng-template>
    <div ngbAccordion
         #acc="ngbAccordion"
         class="accordion-flush"
         *ngFor="let item of menu">
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader
            class="accordion-header-item"
            *ngIf="item.subMenu && item.subMenu.length > 0">
          <button class="accordion-header-item icon-link"
                  ngbAccordionButton>
            <svg data-cy="header-icon"
                 class="d-inline-block menu-icon">
              <use attr.xlink:href="./assets/images/assets.svg#{{item.icon}}"></use>
            </svg>
            {{ item.header }}
          </button>
        </h2>
        <a ngbAccordionHeader
           *ngIf="!item.subMenu || item.subMenu.length === 0"
           class="accordion-button custom-header accordion-header-item icon-link"
           [routerLinkActive]="['active']"
           [routerLink]="item.path"
           (click)="select(item)">
          <svg data-cy="header-icon"
               class="d-inline-block menu-icon">
            <use attr.xlink:href="./assets/images/assets.svg#{{item.icon}}"></use>
          </svg>
          {{ item.header }}
        </a>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody
               class="pe-0 py-0">
            <ng-template>
              <app-middle-menu [menu]="item.subMenu"
                               (selected)="select(item)"></app-middle-menu>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>