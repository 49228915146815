import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { MedicationIngredient } from '@hl7fhir';
import { CodeableConceptPipe, RatioPipe, YesNoPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import * as r3 from 'fhir/r3';

export class MedicationIngredientViewModel extends BackboneElementViewModel<MedicationIngredient> {
  private elementR3 = this.element as r3.MedicationIngredient | undefined;

  get itemCodeableConcept(): string | undefined {
    return (
      this.elementR3?.itemCodeableConcept && new CodeableConceptPipe().transform(this.elementR3.itemCodeableConcept)
    );
  }

  get itemReference(): string | undefined {
    return this.elementR3?.itemReference && getReference(this.elementR3.itemReference); // TODO: Reference
  }

  get isActive(): string | undefined {
    if (this.element?.isActive === undefined) {
      return undefined;
    }
    return new YesNoPipe().transform(this.element.isActive);
  }

  get amount(): string | undefined {
    return this.elementR3?.amount && new RatioPipe().transform(this.elementR3.amount);
  }
}
