import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const GeverifieerdBijCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.2.1.4--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '116154003': {
        'nl-NL': 'Patiënt',
        display: 'Patient',
      },
      '40683002': {
        'nl-NL': 'Ouder',
        display: 'Parent',
      },
      '394619001': {
        'nl-NL': 'Voogd',
        display: 'Guardian',
      },
      '8601000146109': {
        'nl-NL': 'Gevolmachtigde',
        display: 'Holder of enduring power of attorney',
      },
    },
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Anders, namelijk  (in vrije tekst meegeven)',
        display: 'Other',
      },
    },
  },
};
