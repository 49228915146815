import { ConsentActor } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';

export class ConsentActorViewModel extends BackboneElementViewModel<ConsentActor> {
  get role(): string | undefined {
    return this.element?.role && new CodeableConceptPipe().transform(this.element?.role);
  }

  get reference(): string | undefined {
    return this.element?.reference && getReference(this.element?.reference);
  }
}
