<div *ngIf="timing"
     class="border-top border-secondary">
  <div data-cy="timing-header"
       class="p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span class="h6">{{ timingHeader }}</span>
  </div>

  <ng-container *ngIf="timings">
    <div *ngFor="let timing of timings"
         class="border-top border-secondary">
      <div data-cy="timing-header"
           class="p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
        <span class="h6"
              i18n="@@fhir.Timing.header">Timing</span>
      </div>

      <app-entry>
        <label class="fw-semibold"
               i18n="@@fhir.Timing.code">Code</label>
        <span data-cy="timing-code">{{ timing.code | codeableConcept }}</span>
      </app-entry>

      <app-entry>
        <label class="fw-semibold"
               i18n="@@fhir.Timing.event">Event</label>
        <span data-cy="timing-event">{{ timing.event | join:', ' }}</span>
      </app-entry>

      <app-timing-repeat data-cy="timing-repeat"
                         [timingRepeat]="timing.repeat"></app-timing-repeat>
    </div>
  </ng-container>

</div>