import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { ProcedureFocalDevice } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';

export class ProcedureFocalDeviceViewModel extends BackboneElementViewModel<ProcedureFocalDevice> {
  get action(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.action);
  }

  get manipulated(): string | undefined {
    return getReference(this.element?.manipulated);
  }
}
