import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicationRequestIntent',
})
export class MedicationRequestIntentPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'proposal':
        return $localize`:@@fhir.medication-request-intent.proposal:Proposal`;
      case 'plan':
        return $localize`:@@fhir.medication-request-intent.plan:Plan`;
      case 'order':
        return $localize`:@@fhir.medication-request-intent.order:Order`;
      case 'instance-order':
        return $localize`:@@fhir.medication-request-intent.instance-order:Instance Order`;
      default:
        return '';
    }
  }
}
