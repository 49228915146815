import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicationRequestPriority',
})
export class MedicationRequestPriorityPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'routine':
        return $localize`:@@fhir.medication-request-routine.routine:Routine`;
      case 'urgent':
        return $localize`:@@fhir.medication-request-routine.urgent:Urgent`;
      case 'stat':
        return $localize`:@@fhir.medication-request-routine.stat:Stat`;
      case 'asap':
        return $localize`:@@fhir.medication-request-routine.asap:ASAP`;
      default:
        return '';
    }
  }
}
