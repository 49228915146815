import { Pipe, PipeTransform } from '@angular/core';
import { CodeableConcept, Coding } from '@hl7fhir';

@Pipe({
  name: 'hasCoding',
})
export class CodeableConceptHasCodingPipe implements PipeTransform {
  transform(value: CodeableConcept | CodeableConcept[] | undefined, coding: Coding | Coding[]): boolean | undefined {
    if (!value) {
      return undefined;
    }

    if (Array.isArray(value)) {
      if (Array.isArray(coding)) {
        return value.some((codeableConcept) =>
          codeableConcept.coding?.some((c1: Coding) =>
            coding.some((c2: Coding) => c2.code === c1.code && c2.system === c1.system)
          )
        );
      }

      return value.some((codeableConcept) =>
        codeableConcept.coding?.some((c: Coding) => c.code === coding.code && c.system === coding.system)
      );
    } else {
      if (Array.isArray(coding)) {
        return value.coding?.some((c1: Coding) =>
          coding.some((c2: Coding) => c2.code === c1.code && c2.system === c1.system)
        );
      }

      return value.coding?.some((c: Coding) => c.code === coding.code && c.system === coding.system);
    }
  }
}
