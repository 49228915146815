import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { EncounterDiagnosis } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class EncounterDiagnosisViewModel extends BackboneElementViewModel<EncounterDiagnosis> {
  private readonly encounterDiagnosisR3 = this.element as r3.EncounterDiagnosis | undefined;
  private readonly encounterDiagnosisR = this.element as
    | r3.EncounterDiagnosis
    | r4.EncounterDiagnosis
    | r4b.EncounterDiagnosis
    | undefined;

  get condition(): string | undefined {
    return this.encounterDiagnosisR?.condition && getReference(this.encounterDiagnosisR.condition);
  }

  get rank(): string | undefined {
    return this.encounterDiagnosisR?.rank?.toString();
  }

  get role(): string | undefined {
    return this.encounterDiagnosisR3?.role && new CodeableConceptPipe().transform(this.encounterDiagnosisR3.role);
  }
}
