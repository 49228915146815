import { getAccountEntityId } from '@globals';
import { Coverage } from '@hl7fhir';
import { CoverageViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromCoverage from './coverage.reducer';

export const selectCoverageState = createFeatureSelector<fromCoverage.State>(features.FHIR.coverages);

export const selectCoverageIds = createSelector(selectCoverageState, fromCoverage.selectCoverageIds);

export const selectCoverageEntities = createSelector(selectCoverageState, fromCoverage.selectCoverageEntities);

export const selectAllCoverages = createSelector(selectCoverageState, fromCoverage.selectAllCoverages);

export const selectCoverageTotal = createSelector(selectCoverageState, fromCoverage.selectCoverageTotal);

export const selectCoverageById = createSelector(
  selectRouteParams,
  selectCoverageEntities,
  ({ id }, coverages: Dictionary<Coverage>) =>
    id && (coverages[id] ?? coverages[id.toLowerCase()] ?? coverages[id.toUpperCase()])
);

export const selectAllCoverageViewModels = createSelector(selectAllCoverages, (dataSource: Coverage[]) =>
  dataSource.map((item: Coverage) => new CoverageViewModel(item))
);

export const selectCoverageViewModelById = createSelector(
  selectCoverageById,
  (entity: Coverage | undefined) => entity && new CoverageViewModel(entity)
);

export const selectCoverageMatchingByAccount = createSelector(
  selectCoverageById,
  selectAllCoverages,
  (insurance, coverages) => {
    const vms = coverages
      .filter((coverage) => getAccountEntityId(coverage) === getAccountEntityId(insurance))
      .map((coverage) => new CoverageViewModel(coverage));

    return {
      organization: vms.find((vm) => vm.isPayorOrganization),
      patient: vms.find((vm) => vm.isPayorPatient),
    };
  }
);
