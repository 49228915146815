export type AvailableLocales = 'nl-NL' | 'en-US' | 'fr-FR';

export const currentLocale = $localize.locale ?? 'en-US';

export function toLocale(locale?: string): AvailableLocales {
  switch (locale) {
    case 'nl':
      return 'nl-NL';
    case 'fr-FR':
      return 'fr-FR';
    default:
      return 'en-US';
  }
}

/**
 * Azure AD B2C bases its country code on the region part of the local.
 * We want to always preselect the Dutch country code for the Multifactor
 * since we are targeting the Dutch market, so expect this to be the default
 * flow.
 * @param locale The angular locale
 * @returns the b2c local
 */
export function toAzureAdB2c(locale?: string): string {
  switch (locale) {
    case 'en-US':
      return 'en';
    default:
      return 'nl';
  }
}

// ! TODO: It seems we are not using this function anymore. Should we remove it?
export function getCountryFromLocale(locale: AvailableLocales): string {
  return locale.substring(locale.indexOf('-') + 1).toLowerCase();
}

export function wrapTitle(title: string) {
  return `${$localize`:@@app.title:digi.me`} - ${title}`;
}
