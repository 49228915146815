import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FreshdeskService {
  get window(): any {
    return window;
  }

  get freshworksWidget(): any {
    return this.window.FreshworksWidget;
  }

  loadWidget(): void {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `https://widget.freshworks.com/widgets/${environment.freshdeskWidget.widgetId}.js`;

    this.window.fwSettings = {
      widget_id: environment.freshdeskWidget.widgetId,
      locale: $localize`:@@app.feedback.widget.languagecode:en`,
    };

    this.window.FreshworksWidget ||
      (() => {
        if ('function' != typeof this.window.FreshworksWidget) {
          const n: any = function (...args: any[]) {
            n['q'].push(args);
          };

          (n['q'] = []), (this.window.FreshworksWidget = n);
        }
      })();

    script.onload = (_event) => {
      try {
        this.freshworksWidget('hide', 'launcher');
        this.freshworksWidget('setLabels', {
          [$localize`:@@app.feedback.widget.languagecode:en`]: {
            banner: $localize`:@@app.feedback.widget.banner:Contact Us`,
            launcher: $localize`:@@app.feedback.widget.launcher:Help`,
            contact_form: {
              confirmation: $localize`:@@app.feedback.widget.confirmation:Thank you. We will be in touch soon.`,
              submit: $localize`:@@app.feedback.widget.submit:Send`,
            },
          },
        });
      } catch (error) {
        // ! TODO Handle error
      }
    };

    script.onerror = function (_event) {
      // ! TODO Handle error
    };

    document.head.append(script);
  }
}
