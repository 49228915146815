import { Consent } from '@hl7fhir';
import { ConsentViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromConsent from './consent.reducer';

export const selectConsent = createFeatureSelector<fromConsent.State>(features.FHIR.consent);

export const selectConsentEntities = createSelector(selectConsent, fromConsent.selectConsentEntities);

export const selectAllConsents = createSelector(selectConsent, fromConsent.selectAllConsents);

export const selectConsentById = createSelector(
  selectRouteParams,
  selectConsentEntities,
  ({ id }, consents: Dictionary<Consent>) =>
    id && (consents[id] ?? consents[id.toLowerCase()] ?? consents[id.toUpperCase()])
);

export const selectConsentViewModels = createSelector(selectAllConsents, (dataSource: Consent[]) =>
  dataSource.map((item) => new ConsentViewModel(item))
);

export const selectConsentViewModelById = createSelector(
  selectConsentById,
  (entity: Consent | undefined) => entity && new ConsentViewModel(entity)
);
