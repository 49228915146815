import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CodingFilterPipe } from './coding-filter/coding-filter.pipe';
import { CodingsPipe } from './codings.pipe';

const pipes = [CodingFilterPipe, CodingsPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class CodingModule {}
