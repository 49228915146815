import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { EpisodeOfCare } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel, PeriodTypePipe } from '@hl7fhir/data-types';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { getReference } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { EpisodeOfCareStatusPipe } from '@hl7fhir/value-sets';
import { EpisodeOfCareDiagnosisViewModel } from './episode-of-care-diagnosis.viewmodel';
import { EpisodeOfCareStatusHistoryViewModel } from './episode-of-care-status-history.viewmodel';

export class EpisodeOfCareViewModel extends DomainResourceViewModel<EpisodeOfCare> {
  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }
  get title() {
    return new ExtensionPipe().transform(this.resource?.extension, StructureDefinition.Nictiz.EPISODE_OF_CARE.title)
      ?.valueString;
  }
  get firstEncounter() {
    return formatLocaleDate(
      new ExtensionPipe().transform(
        this.resource?.extension,
        StructureDefinition.Nictiz.EPISODE_OF_CARE.dateFirstEncounter
      )?.valueDateTime,
      'long'
    );
  }

  get lastEncounter() {
    return formatLocaleDate(
      new ExtensionPipe().transform(
        this.resource?.extension,
        StructureDefinition.Nictiz.EPISODE_OF_CARE.dateLastEncounter
      )?.valueDateTime,
      'long'
    );
  }

  get status() {
    return new EpisodeOfCareStatusPipe().transform(this.resource?.status);
  }

  get type() {
    return new CodeableConceptPipe().transform(this.resource?.type);
  }

  get patient() {
    return getReference(this.resource?.patient);
  }

  get managingOrganization() {
    return getReference(this.resource?.managingOrganization);
  }

  get period() {
    return new PeriodTypePipe().transform(this.resource?.period);
  }

  get careManager() {
    return getReference(this.resource?.careManager);
  }

  get statusHistory() {
    return this.resource?.statusHistory?.map(
      (history) => new EpisodeOfCareStatusHistoryViewModel(history, this.fhirVersion)
    );
  }

  get diagnosis() {
    return this.resource?.diagnosis?.map(
      (diagnosis) => new EpisodeOfCareDiagnosisViewModel(diagnosis, this.fhirVersion)
    );
  }
}
