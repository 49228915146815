import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemObservationValue: CodeSystemFlattened = {
  id: 'v3-ObservationValue',
  codes: {
    _ActCoverageAssessmentObservationValue: {
      display: 'ActCoverageAssessmentObservationValue',
    },
    _AllergyTestValue: {
      display: 'AllergyTestValue',
    },
    _CompositeMeasureScoring: {
      display: 'CompositeMeasureScoring',
    },
    _CoverageLimitObservationValue: {
      display: 'CoverageLimitObservationValue',
    },
    _CriticalityObservationValue: {
      display: 'CriticalityObservationValue',
    },
    _GeneticObservationValue: {
      display: 'GeneticObservationValue',
    },
    _ObservationMeasureScoring: {
      display: 'ObservationMeasureScoring',
    },
    _ObservationMeasureType: {
      display: 'ObservationMeasureType',
    },
    _ObservationPopulationInclusion: {
      display: 'ObservationPopulationInclusion',
    },
    _PartialCompletionScale: {
      display: 'PartialCompletionScale',
    },
    _SecurityObservationValue: {
      display: 'SecurityObservationValue',
    },
    _SeverityObservation: {
      display: 'SeverityObservation',
    },
    _SubjectBodyPosition: {
      display: '_SubjectBodyPosition',
    },
    _VerificationOutcomeValue: {
      display: 'verification outcome',
    },
    _AnnotationValue: {
      display: 'AnnotationValue',
    },
    _CommonClinicalObservationValue: {
      display: 'common clinical observation',
    },
    _IndividualCaseSafetyReportValueDomains: {
      display: 'Individual Case Safety Report Value Domains',
    },
    _IndicationValue: {
      display: 'IndicationValue',
    },
    _ActFinancialStatusObservationValue: {
      display: 'ActFinancialStatusObservationValue',
    },
    ELSTAT: {
      display: 'eligibility indicator',
    },
    HLSTAT: {
      display: 'health status',
    },
    LIVDEP: {
      display: 'living dependency',
    },
    LIVSIT: {
      display: 'living situation',
    },
    SOECSTAT: {
      display: 'socio economic status',
    },
    ASSET: {
      display: 'asset',
    },
    INCOME: {
      display: 'income',
    },
    LIVEXP: {
      display: 'living expense',
    },
    ANNUITY: {
      display: 'annuity',
    },
    PROP: {
      display: 'real property',
    },
    RETACCT: {
      display: 'retirement investment account',
    },
    TRUST: {
      display: 'trust',
    },
    CHILD: {
      display: 'child support',
    },
    DISABL: {
      display: 'disability pay',
    },
    INVEST: {
      display: 'investment income',
    },
    PAY: {
      display: 'paid employment',
    },
    RETIRE: {
      display: 'retirement pay',
    },
    SPOUSAL: {
      display: 'spousal or partner support',
    },
    SUPPLE: {
      display: 'income supplement',
    },
    TAX: {
      display: 'tax obligation',
    },
    CLOTH: {
      display: 'clothing expense',
    },
    FOOD: {
      display: 'food expense',
    },
    HEALTH: {
      display: 'health expense',
    },
    HOUSE: {
      display: 'household expense',
    },
    LEGAL: {
      display: 'legal expense',
    },
    MORTG: {
      display: 'mortgage',
    },
    RENT: {
      display: 'rent',
    },
    SUNDRY: {
      display: 'sundry expense',
    },
    TRANS: {
      display: 'transportation expense',
    },
    UTIL: {
      display: 'utility expense',
    },
    ADOPT: {
      display: 'adoption document',
    },
    BTHCERT: {
      display: 'birth certificate',
    },
    CCOC: {
      display: 'creditable coverage document',
    },
    DRLIC: {
      display: 'driver license',
    },
    FOSTER: {
      display: 'foster child document',
    },
    MEMBER: {
      display: 'program or policy member',
    },
    MIL: {
      display: 'military identification',
    },
    MRGCERT: {
      display: 'marriage certificate',
    },
    PASSPORT: {
      display: 'passport',
    },
    STUDENRL: {
      display: 'student enrollment',
    },
    DISABLE: {
      display: 'disabled',
    },
    DRUG: {
      display: 'drug use',
    },
    IVDRG: {
      display: 'IV drug use',
    },
    PGNT: {
      display: 'pregnant',
    },
    RELDEP: {
      display: 'relative dependent',
    },
    SPSDEP: {
      display: 'spouse dependent',
    },
    URELDEP: {
      display: 'unrelated person dependent',
    },
    ALONE: {
      display: 'alone',
    },
    DEPCHD: {
      display: 'dependent children',
    },
    DEPSPS: {
      display: 'dependent spouse',
    },
    DEPYGCHD: {
      display: 'dependent young children',
    },
    FAM: {
      display: 'live with family',
    },
    RELAT: {
      display: 'relative',
    },
    SPS: {
      display: 'spouse only',
    },
    UNREL: {
      display: 'unrelated person',
    },
    ABUSE: {
      display: 'abuse victim',
    },
    HMLESS: {
      display: 'homeless',
    },
    ILGIM: {
      display: 'illegal immigrant',
    },
    INCAR: {
      display: 'incarcerated',
    },
    PROB: {
      display: 'probation',
    },
    REFUG: {
      display: 'refugee',
    },
    UNEMPL: {
      display: 'unemployed',
    },
    A0: {
      display: 'no reaction',
    },
    A1: {
      display: 'minimal reaction',
    },
    A2: {
      display: 'mild reaction',
    },
    A3: {
      display: 'moderate reaction',
    },
    A4: {
      display: 'severe reaction',
    },
    ALLORNONESCR: {
      display: 'All-or-nothing Scoring',
    },
    LINEARSCR: {
      display: 'Linear Scoring',
    },
    OPPORSCR: {
      display: 'Opportunity Scoring',
    },
    WEIGHTSCR: {
      display: 'Weighted Scoring',
    },
    _CoverageLevelObservationValue: {
      display: 'CoverageLevelObservationValue',
    },
    ADC: {
      display: 'adult child',
    },
    CHD: {
      display: 'child',
    },
    DEP: {
      display: 'dependent',
    },
    DP: {
      display: 'domestic partner',
    },
    ECH: {
      display: 'employee',
    },
    FLY: {
      display: 'family coverage',
    },
    IND: {
      display: 'individual',
    },
    SSP: {
      display: 'same sex partner',
    },
    CRITH: {
      display: 'high criticality',
    },
    CRITL: {
      display: 'low criticality',
    },
    CRITU: {
      display: 'unable to assess criticality',
    },
    Homozygote: {
      display: 'HOMO',
    },
    COHORT: {
      display: 'cohort measure scoring',
    },
    CONTVAR: {
      display: 'continuous variable measure scoring',
    },
    PROPOR: {
      display: 'proportion measure scoring',
    },
    RATIO: {
      display: 'ratio measure scoring',
    },
    COMPOSITE: {
      display: 'composite measure type',
    },
    EFFICIENCY: {
      display: 'efficiency measure type',
    },
    EXPERIENCE: {
      display: 'experience measure type',
    },
    OUTCOME: {
      display: 'outcome measure type',
    },
    PROCESS: {
      display: 'process measure type',
    },
    RESOURCE: {
      display: 'resource use measure type',
    },
    STRUCTURE: {
      display: 'structure measure type',
    },
    DENEX: {
      display: 'denominator exclusions',
    },
    DENEXCEP: {
      display: 'denominator exceptions',
    },
    DENOM: {
      display: 'denominator',
    },
    IP: {
      display: 'initial population',
    },
    MSRPOPL: {
      display: 'measure population',
    },
    NUMER: {
      display: 'numerator',
    },
    NUMEX: {
      display: 'numerator exclusions',
    },
    IPP: {
      display: 'initial patient population',
    },
    G: {
      display: 'Great extent',
    },
    LE: {
      display: 'Large extent',
    },
    ME: {
      display: 'Medium extent',
    },
    MI: {
      display: 'Minimal extent',
    },
    N: {
      display: 'None',
    },
    S: {
      display: 'Some extent',
    },
    _SECINTOBV: {
      display: 'security integrity',
    },
    SECTRSTOBV: {
      display: 'security trust observation',
    },
    _SECALTINTOBV: {
      display: 'alteration integrity',
    },
    _SECDATINTOBV: {
      display: 'data integrity',
    },
    _SECINTCONOBV: {
      display: 'integrity confidence',
    },
    _SECINTPRVOBV: {
      display: 'provenance',
    },
    ABSTRED: {
      display: 'abstracted',
    },
    AGGRED: {
      display: 'aggregated',
    },
    ANONYED: {
      display: 'anonymized',
    },
    MAPPED: {
      display: 'mapped',
    },
    MASKED: {
      display: 'masked',
    },
    PSEUDED: {
      display: 'pseudonymized',
    },
    REDACTED: {
      display: 'redacted',
    },
    SUBSETTED: {
      display: 'subsetted',
    },
    SYNTAC: {
      display: 'syntactic transform',
    },
    TRSLT: {
      display: 'translated',
    },
    VERSIONED: {
      display: 'versioned',
    },
    CRYTOHASH: {
      display: 'cryptographic hash function',
    },
    DIGSIG: {
      display: 'digital signature',
    },
    HRELIABLE: {
      display: 'highly reliable',
    },
    RELIABLE: {
      display: 'reliable',
    },
    UNCERTREL: {
      display: 'uncertain reliability',
    },
    UNRELIABLE: {
      display: 'unreliable',
    },
    _SECINTPRVABOBV: {
      display: 'provenance asserted by',
    },
    _SECINTPRVRBOBV: {
      display: 'provenance reported by',
    },
    CLINAST: {
      display: 'clinician asserted',
    },
    DEVAST: {
      display: 'device asserted',
    },
    HCPAST: {
      display: 'healthcare professional asserted',
    },
    PACQAST: {
      display: 'patient acquaintance asserted',
    },
    PATAST: {
      display: 'patient asserted',
    },
    PAYAST: {
      display: 'payer asserted',
    },
    PROAST: {
      display: 'professional asserted',
    },
    SDMAST: {
      display: 'substitute decision maker asserted',
    },
    CLINRPT: {
      display: 'clinician reported',
    },
    DEVRPT: {
      display: 'device reported',
    },
    HCPRPT: {
      display: 'healthcare professional reported',
    },
    PACQRPT: {
      display: 'patient acquaintance reported',
    },
    PATRPT: {
      display: 'patient reported',
    },
    PAYRPT: {
      display: 'payer reported',
    },
    PRORPT: {
      display: 'professional reported',
    },
    SDMRPT: {
      display: 'substitute decision maker reported',
    },
    TRSTACCRDOBV: {
      display: 'trust accreditation observation',
    },
    TRSTAGREOBV: {
      display: 'trust agreement observation',
    },
    TRSTCERTOBV: {
      display: 'trust certificate observation',
    },
    TRSTLOAOBV: {
      display: 'trust assurance observation',
    },
    TRSTMECOBV: {
      display: 'none supplied 6',
    },
    LOAAN: {
      display: 'authentication level of assurance value',
    },
    LOAAP: {
      display: 'authentication process level of assurance value',
    },
    LOAAS: {
      display: 'assertion level of assurance value',
    },
    LOACM: {
      display: 'token and credential management level of assurance value)',
    },
    LOAID: {
      display: 'identity proofing level of assurance',
    },
    LOANR: {
      display: 'non-repudiation level of assurance value',
    },
    LOARA: {
      display: 'remote access level of assurance value',
    },
    LOATK: {
      display: 'token level of assurance value',
    },
    LOAAN1: {
      display: 'low authentication level of assurance',
    },
    LOAAN2: {
      display: 'basic authentication level of assurance',
    },
    LOAAN3: {
      display: 'medium authentication level of assurance',
    },
    LOAAN4: {
      display: 'high authentication level of assurance',
    },
    LOAAP1: {
      display: 'low authentication process level of assurance',
    },
    LOAAP2: {
      display: 'basic authentication process level of assurance',
    },
    LOAAP3: {
      display: 'medium authentication process level of assurance',
    },
    LOAAP4: {
      display: 'high authentication process level of assurance',
    },
    LOAAS1: {
      display: 'low assertion level of assurance',
    },
    LOAAS2: {
      display: 'basic assertion level of assurance',
    },
    LOAAS3: {
      display: 'medium assertion level of assurance',
    },
    LOAAS4: {
      display: 'high assertion level of assurance',
    },
    LOACM1: {
      display: 'low token and credential management level of assurance',
    },
    LOACM2: {
      display: 'basic token and credential management level of assurance',
    },
    LOACM3: {
      display: 'medium token and credential management level of assurance',
    },
    LOACM4: {
      display: 'high token and credential management level of assurance',
    },
    LOAID1: {
      display: 'low identity proofing level of assurance',
    },
    LOAID2: {
      display: 'basic identity proofing level of assurance',
    },
    LOAID3: {
      display: 'medium identity proofing level of assurance',
    },
    LOAID4: {
      display: 'high identity proofing level of assurance',
    },
    LOANR1: {
      display: 'low non-repudiation level of assurance',
    },
    LOANR2: {
      display: 'basic non-repudiation level of assurance',
    },
    LOANR3: {
      display: 'medium non-repudiation level of assurance',
    },
    LOANR4: {
      display: 'high non-repudiation level of assurance',
    },
    LOARA1: {
      display: 'low remote access level of assurance',
    },
    LOARA2: {
      display: 'basic remote access level of assurance',
    },
    LOARA3: {
      display: 'medium remote access level of assurance',
    },
    LOARA4: {
      display: 'high remote access level of assurance',
    },
    LOATK1: {
      display: 'low token level of assurance',
    },
    LOATK2: {
      display: 'basic token level of assurance',
    },
    LOATK3: {
      display: 'medium token level of assurance',
    },
    LOATK4: {
      display: 'high token level of assurance',
    },
    H: {
      display: 'High',
    },
    L: {
      display: 'Low',
    },
    M: {
      display: 'Moderate',
    },
    LLD: {
      display: 'left lateral decubitus',
    },
    PRN: {
      display: 'prone',
    },
    RLD: {
      display: 'right lateral decubitus',
    },
    SFWL: {
      display: "Semi-Fowler's",
    },
    SIT: {
      display: 'sitting',
    },
    STN: {
      display: 'standing',
    },
    SUP: {
      display: 'supine',
    },
    RTRD: {
      display: 'reverse trendelenburg',
    },
    TRD: {
      display: 'trendelenburg',
    },
    ACT: {
      display: 'active coverage',
    },
    ACTPEND: {
      display: 'active - pending investigation',
    },
    ELG: {
      display: 'eligible',
    },
    INACT: {
      display: 'inactive',
    },
    INPNDINV: {
      display: 'inactive - pending investigation',
    },
    INPNDUPD: {
      display: 'inactive - pending eligibility update',
    },
    NELG: {
      display: 'not eligible',
    },
  },
};
