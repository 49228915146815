import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availability',
})
export class AvailabilityPipe implements PipeTransform {
  transform(availability: string | undefined): string {
    switch (availability) {
      case 'ONLINE':
        return $localize`:@@fhir.availability.online:Online`;
      case 'OFFLINE':
        return $localize`:@@fhir.availability.offline:Offline`;
      case 'NEARLINE':
        return $localize`:@@fhir.availability.nearline:Nearline`;
      case 'UNAVAILABLE':
        return $localize`:@@fhir.availability.unavailable:Unavailable`;
      default:
        return '';
    }
  }
}
