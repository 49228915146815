import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-medmij-trusted',
  templateUrl: './medmij-trusted.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class MedMijTrustedComponent {
  @Input() showDescription = true;
  @Input() horizontal = false;
}
