/*
 * LibrarySyncStatus
 */
export type LibrarySyncStatus = 'running' | 'partial' | 'completed' | 'pending';

/*
 * CAFileListEntry
 */
export interface CAFileListEntry {
  name: string;
  updatedDate: number;
  schema?: FileDataSchema;
}

export interface ObjectTypeError {
  error: {
    code: string;
    message: string;
    statusCode: number;
  };
  objectType: number;
}
export interface PartialError {
  code: string;
  statusCode: number;
  message?: string;
  reauth?: boolean;
  retryAfter?: number;
  objectTypeErrors?: ObjectTypeError[];
}

export interface AccountSyncStatusEntryPartial {
  state: 'partial';
  error: PartialError;
}

export interface AccountSyncStatusEntryRunning {
  state: 'running';
}

export interface AccountSyncStatusEntryCompleted {
  state: 'completed';
}

export type AccountSyncStatusEntry =
  | AccountSyncStatusEntryPartial
  | AccountSyncStatusEntryRunning
  | AccountSyncStatusEntryCompleted;

/*
 * CAFileListResponse
 */
export interface CAFileListResponse {
  status: Status;
  fileList?: CAFileListEntry[];
  //userAccessToken?: UserAccessToken;
}

export interface Status {
  state: LibrarySyncStatus;
  details?: Record<string, AccountSyncStatusEntry>;
}

export interface FileDataSchema {
  id?: string;
  standard: Schemas;
  version: string; // SemVer, ie "1.0.0"
}

export type Schemas = 'digime' | 'fhir';
