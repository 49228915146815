<ng-container *ngIf="address; else noAddress">
  <div data-cy="address-header"
       class="hstack gap-2 h-6 p-2 border-secondary border-bottom border-top bg-secondary bg-opacity-10">
    <span i18n="@@fhir.Address"
          class="h6 m-0">Address</span>
    <span i18n="@@fhir.Address.use"
          class="ms-auto text-muted">Address Use</span>
    <span data-cy="use">{{ address.use | code:codeSystems.ADDRESS_USE }}</span>
  </div>

  <app-entry *ngIf="showLines">
    <label class="fw-semibold"
           i18n="@@fhir.Address.line">Lines</label>
    <p data-cy="address-lines"
       class="m-0"
       *ngFor="let line of address?.line">{{ line }}</p>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.Address.postalCode">Postal Code</label>
    <span data-cy="address-postcode">{{ address.postalCode }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.Address.city">City</label>
    <span data-cy="address-city">{{ address.city }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.Address.district">District</label>
    <span data-cy="address-district">{{ address.district }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.Address.state">State</label>
    <span data-cy="address-state">{{ address.state }}</span>
  </app-entry>

  <app-entry>
    <label class="fw-semibold"
           i18n="@@fhir.Address.country">Country</label>
    <span data-cy="address-country">{{ address.country | countryCodeToName }}</span>
  </app-entry>

  <app-entry *ngIf="address | line:'iso21090-ADXP-streetName' as streetName">
    <label class="fw-semibold"
           i18n="@@fhir.Address.line.iso21090-ADXP-streetName">Street</label>
    <span data-cy="address-street">{{ streetName }}</span>
  </app-entry>

  <app-entry *ngIf="address | line:'iso21090-ADXP-houseNumber' as houseNumber">
    <label class="fw-semibold"
           i18n="@@fhir.Address.line.iso21090-ADXP-houseNumber">Housenumber</label>
    <span data-cy="address-housenumber">{{ houseNumber }}</span>
  </app-entry>

  <app-entry *ngIf="showType">
    <label class="fw-semibold"
           i18n="@@fhir.Address.type">Address Type</label>
    <span data-cy="address-type">{{ address.type | code:codeSystems.ADDRESS_TYPE }}</span>
  </app-entry>

  <app-entry *ngIf="showUse">
    <label class="fw-semibold"
           i18n="@@fhir.Address.use">Address Use</label>
    <span data-cy="address-use">{{ address.use | code:codeSystems.ADDRESS_USE }}</span>
  </app-entry>

  <app-entry
             *ngIf="address.extension | extension:structureDefinition.Nictiz.ADDRESS_INFORMATION.addressType as extension">
    <label class="fw-semibold"
           i18n="@@fhir.nl-core-address.adresSoort">Address Kind</label>
    <span data-cy="address-adres-soort-esxtension">{{ extension | select:'valueCodeableConcept' |
      codeableConcept:adresSoortCodelijst }}</span>
  </app-entry>
</ng-container>

<ng-template #noAddress>
  <p class="p-2"
     data-cy="address-empty"
     i18n="@@fhir.Address.empty">No address to show.</p>
</ng-template>
