import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unit',
})
export class UnitPipe implements PipeTransform {
  transform(unit: string | undefined): string {
    switch (unit) {
      case 's':
        return $localize`:@@fhir.timing-repeat-unit.seconds:Seconds`;
      case 'min':
        return $localize`:@@fhir.timing-repeat-unit.minutes:Minutes`;
      case 'h':
        return $localize`:@@fhir.timing-repeat-unit.hours:Hours`;
      case 'd':
        return $localize`:@@fhir.timing-repeat-unit.days:Days`;
      case 'wk':
        return $localize`:@@fhir.timing-repeat-unit.weeks:Weeks`;
      case 'mo':
        return $localize`:@@fhir.timing-repeat-unit.months:Months`;
      case 'a':
        return $localize`:@@fhir.timing-repeat-unit.years:Years`;
      default:
        return '';
    }
  }
}
