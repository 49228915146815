import { DocumentManifestRelated } from '@hl7fhir';
import { IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';

export class DocumentManifestRelatedViewModel extends BackboneElementViewModel<DocumentManifestRelated> {
  get identifier(): IdentifierViewModel | undefined {
    return this.element?.identifier && new IdentifierViewModel(this.element.identifier, this.fhirVersion);
  }

  get ref(): string | undefined {
    return this.element?.ref && getReference(this.element.ref);
  }
}
