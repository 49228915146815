import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const VerzekeringssoortCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.1.1.1--20171231000000',
  systems: {
    'urn:oid:2.16.840.1.113883.2.4.3.11.60.101.5.1': {
      B: {
        'nl-NL': 'Basis verzekerd',
        display: 'Basis',
      },
      A: {
        'nl-NL': 'Aanvullend verzekerd',
        display: 'Aanvullend verzekerd',
      },
      BZ: {
        'nl-NL': 'Basisverzekering vanuit AWBZ (tegenwoordig uit Wet Langdurige Zorg)',
        display: 'Basisverzekering vanuit AWBZ',
      },
      H: {
        'nl-NL':
          'Een basisverzekering die geen onderdeel uitmaakt van de ZVW of AWBZ (bijvoorbeeld voor expats in het buitenland).',
        display: 'Hoofdverzekering',
      },
      T: {
        'nl-NL': 'Tandverzekering',
        display: 'Tandverzekering (los)',
      },
      AT: {
        'nl-NL': 'Aanvullend + tand',
        display: 'Aanvullend + tand',
      },
    },
  },
};
