import { ObservationReferenceRange } from '@hl7fhir';
import { CodeableConceptPipe, QuantityTypePipe, AgeTypePipe } from '@hl7fhir/data-types';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';

export class ObservationReferenceViewModel extends BackboneElementViewModel<ObservationReferenceRange> {
  get low(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.low);
  }

  get high(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.high);
  }

  get type(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.type);
  }

  get appliesTo(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.appliesTo);
  }

  get age(): string | undefined {
    return new AgeTypePipe().transform(this.element?.age);
  }

  get text(): string | undefined {
    return this.element?.text;
  }
}
