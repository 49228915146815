/* eslint-disable @typescript-eslint/naming-convention */
export class SourceProperties {
  /**
    ID of the onboarded source.
    Discovery API equivalent: data.services[0].id
   */
  source_id?: number;

  /**
    Name of the onboarded source.
    Discovery API equivalent: data.services[0].name
    Potentially not specified in cases where provided source ID cannot be found in the Discovery API
   */
  source_name?: string;

  /**
    Reference of the onboarded source.
    Discovery API equivalent: data.services[0].reference
    Potentially not specified in cases where provided source ID cannot be found in the Discovery API
   */
  source_reference?: string;

  /**
    Shortened source reference.
    Discovery API: Take data.services[0].reference , split on ? and use the first element.
    Potentially not specified in cases where provided source ID cannot be found in the Discovery API
   */
  source_reference_short?: string;

  /**
    Array of Service Group ID’s of the onboarded source.
    Discovery API: Extract id from objects within data.services[0].serviceGroups array.
    Potentially not specified in cases where provided source ID cannot be found in the Discovery API
   */
  source_service_group_ids?: number[];

  /**
    Array of Service Group names of the onboarded source.
    Discovery API: Use id from objects within data.services[0].serviceGroups array to retrieve names from objects within data.serviceGroups array.
    Potentially not specified in cases where provided source ID or service group cannot be found in the Discovery API
  */
  source_service_group_names?: string[];
}
