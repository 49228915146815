import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RangePipe } from './pipes/range.pipe';

const pipes = [RangePipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class RangeModule {}
