import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appointmentParticipantStatus',
})
export class AppointmentParticipantStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'accepted':
        return $localize`:@@fhir.appointment-participant-status.accepted:Accepted`;
      case 'declined':
        return $localize`:@@fhir.appointment-participant-status.declined:Declined`;
      case 'tentative':
        return $localize`:@@fhir.appointment-participant-status.tentative:Tentative`;
      case 'needs-action':
        return $localize`:@@fhir.appointment-participant-status.needs-action:Needs Action`;
      default:
        return '';
    }
  }
}
