<nav [ngClass]="(online$ | async) === false ? 'navbar-offline' : ''"
     class="navbar navbar-expand-lg bg-gray-100 fixed-top">
  <div class="container-fluid p-3 p-lg-4 d-flex justify-content-between align-items-center">
    <a class="navbar-brand icon-link"
       [routerLink]="['/']">
      <svg class="d-inline-block align-text-top menu-icon">
        <use xlink:href="./assets/images/assets.svg#digital-me"></use>
      </svg>
      <span i18n="@@app.title">digi.me</span>
    </a>

    <div class="d-none d-lg-block mx-auto"
         *ngIf="(isAuthenticated$ | async) === true">
      <app-menu [menu]="menu$ | async"></app-menu>
    </div>

    <div class="d-flex gap-2">
      <app-log-in-button-container
                                   *ngIf="(isAuthenticated$ | async) === false && (mustCreateAccount$ | async) === false"></app-log-in-button-container>

      <app-button *ngIf="environment.features.measurements.notifications && (isAuthenticated$ | async) === true"
                  type="secondary"
                  icon="notifications"
                  (click)="gotoNotifications()"></app-button>

      <app-help-drop-down-menu></app-help-drop-down-menu>
    </div>
  </div>
</nav>
<div class="spacer-top"></div>