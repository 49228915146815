import { ConsentData } from '@hl7fhir';
import { getReference } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';

export class ConsentDataViewModel extends BackboneElementViewModel<ConsentData> {
  get meaning(): string | undefined {
    return this.element?.meaning;
  }

  get reference(): string | undefined {
    return this.element?.reference && getReference(this.element?.reference);
  }
}
