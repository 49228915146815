export const STRUCTURE_DEFINITION = 'http://nictiz.nl/fhir/StructureDefinition';

export const ADDRESS_INFORMATION = {
  addressType: `${STRUCTURE_DEFINITION}/zib-AddressInformation-AddressType`,
};

export const OBSERVATION = {
  gpLaboratoryResult: `${STRUCTURE_DEFINITION}/gp-LaboratoryResult`,
  hospitalLaboratoryResultObservation: `${STRUCTURE_DEFINITION}/zib-LaboratoryTestResult-Observation`,
  hospitalLaboratoryResultSpecimen: `${STRUCTURE_DEFINITION}/zib-LaboratoryTestResult-Specimen`,
};

export const EPISODE_OF_CARE = {
  profile: `${STRUCTURE_DEFINITION}/nl-core-episodeofcare`,
  dateFirstEncounter: `${STRUCTURE_DEFINITION}/EpisodeOfCare-DateFirstEncounter`,
  dateLastEncounter: `${STRUCTURE_DEFINITION}/EpisodeOfCare-DateLastEncounter`,
  title: `${STRUCTURE_DEFINITION}/EpisodeOfCare-Title`,
};

export const NUTRITION_ADVICE = {
  explanation: `${STRUCTURE_DEFINITION}/zib-NutritionAdvice-Explanation`,
};

export const PRACTITIONER_ROLE = {
  reference: `${STRUCTURE_DEFINITION}/practitionerrole-reference`,
};

export const MEDICAL_DEVICE = {
  profile: `${STRUCTURE_DEFINITION}/zib-MedicalDevice`,
  organization: `${STRUCTURE_DEFINITION}/zib-MedicalDevice-Organization`,
  practitioner: `${STRUCTURE_DEFINITION}/zib-MedicalDevice-Practitioner`,
};

export const DEVICE_USE_STATEMENT = {
  reasonReference: `${STRUCTURE_DEFINITION}/deviceUseStatement-reasonReferenceSTU3`,
};

export const APPOINTMENT = {
  patientInstructions: `${STRUCTURE_DEFINITION}/eAfspraak-Appointment-PatientInstructions`,
  onlineEditable: `${STRUCTURE_DEFINITION}/eAfspraak-Appointment-OnlineEditable`,
};

export const ONLINE_EDITABLE = {
  indicator: 'Indicator',
  onlineEditableUntil: 'OnlineEditableUntil',
};

export const PAYER = {
  profile: `${STRUCTURE_DEFINITION}/zib-Payer`,
  bankInformation: `${STRUCTURE_DEFINITION}/zib-Payer-BankInformation`,
};

export const BANK_INFORMATION = {
  bankName: 'BankName',
  accountNumber: 'AccountNumber',
  bankCode: 'Bankcode',
};

export const TREATMENT_DIRECTIVE = {
  treatment: `${STRUCTURE_DEFINITION}/zib-TreatmentDirective-Treatment`,
  verification: `${STRUCTURE_DEFINITION}/zib-TreatmentDirective-Verification`,
  treatmentPermitted: `${STRUCTURE_DEFINITION}/zib-TreatmentDirective-TreatmentPermitted`,
};

export const ADVANCE_DIRECTIVE = {
  disorder: `${STRUCTURE_DEFINITION}/zib-AdvanceDirective-Disorder`,
  comment: `${STRUCTURE_DEFINITION}/Comment`,
};

export const BODY_SITE = {
  qualifier: `${STRUCTURE_DEFINITION}/BodySite-Qualifier`,
};

export const CODE = {
  icpc1Nl: `${STRUCTURE_DEFINITION}/code-icpc-1-nl`,
  specification: `${STRUCTURE_DEFINITION}/code-specification`,
};

export const PATIENT = {
  lifeStance: `${STRUCTURE_DEFINITION}/zib-LifeStance`,
  legalStatus: `${STRUCTURE_DEFINITION}/zib-patient-legalstatus`,
  nationality: `${STRUCTURE_DEFINITION}/patient-nationality`,
  preferredPharmacy: `${STRUCTURE_DEFINITION}/nl-core-preferred-pharmacy`,
  proficiency: `${STRUCTURE_DEFINITION}/patient-proficiency`,
};

export const CONTACT_INFORMATION = {
  telecomType: `${STRUCTURE_DEFINITION}/zib-ContactInformation-TelecomType`,
};

export const ALLERGY_INTOLERANCE = `${STRUCTURE_DEFINITION}/zib-AllergyIntolerance`;
