import { Encounter } from '@hl7fhir';
import { EncounterViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromEncounter from './encounter.reducer';

export const selectEncounterState = createFeatureSelector<fromEncounter.State>(features.FHIR.encounters);

export const selectEncounterEntities = createSelector(selectEncounterState, fromEncounter.selectEncounterEntities);

export const selectAllEncounters = createSelector(selectEncounterState, fromEncounter.selectAllEncounters);

export const selectEncounterById = createSelector(
  selectRouteParams,
  selectEncounterEntities,
  ({ id }, encounters: Dictionary<Encounter>) =>
    id && (encounters[id] ?? encounters[id.toLowerCase()] ?? encounters[id.toUpperCase()])
);

export const selectAllEncounterViewModels = createSelector(selectAllEncounters, (dataSource: Encounter[]) =>
  dataSource.map((item) => new EncounterViewModel(item))
);

export const selectEncounterViewModelById = createSelector(
  selectEncounterById,
  (entity: Encounter | undefined) => entity && new EncounterViewModel(entity)
);
