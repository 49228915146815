import { DetailsItem, ElementItem } from '@resource-view/models';
import { AddressViewModel } from './address.viewmodel';

export function buildAddressElements(vm: AddressViewModel | undefined): ElementItem[] {
  return [
    {
      id: 'use',
      header: $localize`:@@fhir.Address.use:Address Use`,
      value: vm?.use,
    },
    {
      id: 'lines',
      header: $localize`:@@fhir.Address.line:Lines`,
      value: vm?.lines,
    },
    {
      id: 'postalCode',
      header: $localize`:@@fhir.Address.postalCode:Postal Code`,
      value: vm?.postalCode,
    },
    {
      id: 'city',
      header: $localize`:@@fhir.Address.city:City`,
      value: vm?.city,
    },
    {
      id: 'district',
      header: $localize`:@@fhir.Address.district:District`,
      value: vm?.district,
    },
    {
      id: 'state',
      header: $localize`:@@fhir.Address.state:State`,
      value: vm?.state,
    },
    {
      id: 'country',
      header: $localize`:@@fhir.Address.country:Country`,
      value: vm?.country,
    },
    {
      id: 'street',
      header: $localize`:@@fhir.Address.street:Street`,
      value: vm?.street,
    },
    {
      id: 'houseNumber',
      header: $localize`:@@fhir.Address.houseNumber:House Number`,
      value: vm?.houseNumber,
    },
    {
      id: 'type',
      header: $localize`:@@fhir.Address.type:Address Type`,
      value: vm?.type,
    },
    {
      id: 'kind',
      header: $localize`:@@fhir.nl-core-address.adresSoort:Address Kind`,
      value: vm?.kind,
    },
  ];
}

export function buildAddressesDetails(vms: AddressViewModel[] | undefined): DetailsItem[] | undefined {
  return vms?.map((vm: AddressViewModel, index: number) => ({
    id: `address-${index}`,
    header: `${vm?.kind || vm?.use || $localize`:@@fhir.Address:Address`}`,
    elements: buildAddressElements(vm),
  }));
}
