import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'immunizationStatus',
})
export class ImmunizationStatusPipe implements PipeTransform {
  transform(status: ('completed' | 'entered-in-error' | 'not-done') | undefined): string {
    switch (status) {
      case 'completed':
        return $localize`:@@fhir.immunization-status.completed:Completed`;
      case 'entered-in-error':
        return $localize`:@@fhir.immunization-status.entered-in-error:Entered in Error`;
      case 'not-done':
        return $localize`:@@fhir.immunization-status.not-done:Not Done`;
      default:
        return '';
    }
  }
}
