import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const GBATabel32Nationaliteiten: CodeSystemFlattened = {
  id: '59ea63d2-b1eb-4f87-a45d-cc3db304877c',
  codes: {
    '0000': {
      display: 'Onbekend',
    },
    '0001': {
      display: 'Nederlandse',
    },
    '0002': {
      display: 'Behandeld als Nederlander',
    },
    '0027': {
      display: 'Slowaakse',
    },
    '0028': {
      display: 'Tsjechische',
    },
    '0029': {
      display: 'Burger van Bosnië-Herzegovina',
    },
    '0030': {
      display: 'Georgische',
    },
    '0031': {
      display: 'Turkmeense',
    },
    '0032': {
      display: 'Tadzjiekse',
    },
    '0033': {
      display: 'Oezbeekse',
    },
    '0034': {
      display: 'Oekraïense',
    },
    '0035': {
      display: 'Kirgizische',
    },
    '0036': {
      display: 'Moldavische',
    },
    '0037': {
      display: 'Kazachse',
    },
    '0038': {
      display: 'Belarussische',
    },
    '0039': {
      display: 'Azerbeidzjaanse',
    },
    '0040': {
      display: 'Armeense',
    },
    '0041': {
      display: 'Russische',
    },
    '0042': {
      display: 'Sloveense',
    },
    '0043': {
      display: 'Kroatische',
    },
    '0044': {
      display: 'Letse',
    },
    '0045': {
      display: 'Estische',
    },
    '0046': {
      display: 'Litouwse',
    },
    '0047': {
      display: 'Marshalleilandse',
    },
    '0048': {
      display: 'Myanmarese',
    },
    '0049': {
      display: 'Namibische',
    },
    '0050': {
      display: 'Albanese',
    },
    '0051': {
      display: 'Andorrese',
    },
    '0052': {
      display: 'Belgische',
    },
    '0053': {
      display: 'Bulgaarse',
    },
    '0054': {
      display: 'Deense',
    },
    '0055': {
      display: 'Burger van de Bondsrepubliek Duitsland',
    },
    '0056': {
      display: 'Finse',
    },
    '0057': {
      display: 'Franse',
    },
    '0058': {
      display: 'Jemenitische',
    },
    '0059': {
      display: 'Griekse',
    },
    '0060': {
      display: 'Brits burger',
    },
    '0061': {
      display: 'Hongaarse',
    },
    '0062': {
      display: 'Ierse',
    },
    '0063': {
      display: 'IJslandse',
    },
    '0064': {
      display: 'Italiaanse',
    },
    '0065': {
      display: 'Joegoslavische',
    },
    '0066': {
      display: 'Liechtensteinse',
    },
    '0067': {
      display: 'Luxemburgse',
    },
    '0068': {
      display: 'Maltese',
    },
    '0069': {
      display: 'Monegaskische',
    },
    '0070': {
      display: 'Noorse',
    },
    '0071': {
      display: 'Oostenrijkse',
    },
    '0072': {
      display: 'Poolse',
    },
    '0073': {
      display: 'Portugese',
    },
    '0074': {
      display: 'Roemeense',
    },
    '0075': {
      display: 'Burger van de Sovjet-Unie',
    },
    '0076': {
      display: 'San Marinese',
    },
    '0077': {
      display: 'Spaanse',
    },
    '0078': {
      display: 'Tsjecho-Slowaakse',
    },
    '0079': {
      display: 'Vaticaanse',
    },
    '0080': {
      display: 'Zweedse',
    },
    '0081': {
      display: 'Zwitserse',
    },
    '0082': {
      display: 'Oost-Duitse',
    },
    '0083': {
      display: 'Brits onderdaan',
    },
    '0084': {
      display: 'Eritrese',
    },
    '0085': {
      display: 'Brits overzees burger',
    },
    '0086': {
      display: 'Macedonische',
    },
    '0087': {
      display: 'Kosovaarse',
    },
    '0088': {
      display: 'Macedonische/Burger van Noord-Macedonië',
    },
    '0100': {
      display: 'Algerijnse',
    },
    '0101': {
      display: 'Angolese',
    },
    '0104': {
      display: 'Burundese',
    },
    '0105': {
      display: 'Botswaanse',
    },
    '0106': {
      display: 'Burkinese',
    },
    '0108': {
      display: 'Centraal-Afrikaanse',
    },
    '0109': {
      display: 'Comorese',
    },
    '0110': {
      display: 'Burger van Congo',
    },
    '0111': {
      display: 'Beninse',
    },
    '0112': {
      display: 'Egyptische',
    },
    '0113': {
      display: 'Equatoriaal-Guinese',
    },
    '0114': {
      display: 'Ethiopische',
    },
    '0115': {
      display: 'Djiboutiaanse',
    },
    '0116': {
      display: 'Gabonese',
    },
    '0117': {
      display: 'Gambiaanse',
    },
    '0118': {
      display: 'Ghanese',
    },
    '0119': {
      display: 'Guinese',
    },
    '0120': {
      display: 'Ivoriaanse',
    },
    '0121': {
      display: 'Kaapverdische',
    },
    '0122': {
      display: 'Kameroense',
    },
    '0123': {
      display: 'Kenyaanse',
    },
    '0124': {
      display: 'Zaïrese',
    },
    '0125': {
      display: 'Lesothaanse',
    },
    '0126': {
      display: 'Liberiaanse',
    },
    '0127': {
      display: 'Libische',
    },
    '0128': {
      display: 'Malagassische',
    },
    '0129': {
      display: 'Malawische',
    },
    '0130': {
      display: 'Malinese',
    },
    '0131': {
      display: 'Marokkaanse',
    },
    '0132': {
      display: 'Mauritaanse',
    },
    '0133': {
      display: 'Mauritiaanse',
    },
    '0134': {
      display: 'Mozambikaanse',
    },
    '0135': {
      display: 'Swazische',
    },
    '0136': {
      display: 'Nigerese',
    },
    '0137': {
      display: 'Nigeriaanse',
    },
    '0138': {
      display: 'Ugandese',
    },
    '0139': {
      display: 'Guinee-Bissause',
    },
    '0140': {
      display: 'Zuid-Afrikaanse',
    },
    '0141': {
      display: 'Eswatinische',
    },
    '0142': {
      display: 'Zimbabwaanse',
    },
    '0143': {
      display: 'Rwandese',
    },
    '0144': {
      display: 'Burger van São Tomé en Principe',
    },
    '0145': {
      display: 'Senegalese',
    },
    '0147': {
      display: 'Sierra Leoonse',
    },
    '0148': {
      display: 'Soedanese',
    },
    '0149': {
      display: 'Somalische',
    },
    '0151': {
      display: 'Tanzaniaanse',
    },
    '0152': {
      display: 'Togolese',
    },
    '0154': {
      display: 'Tsjadische',
    },
    '0155': {
      display: 'Tunesische',
    },
    '0156': {
      display: 'Zambiaanse',
    },
    '0157': {
      display: 'Zuid-Soedanese',
    },
    '0200': {
      display: 'Bahamaanse',
    },
    '0202': {
      display: 'Belizaanse',
    },
    '0204': {
      display: 'Canadese',
    },
    '0205': {
      display: 'Costa Ricaanse',
    },
    '0206': {
      display: 'Cubaanse',
    },
    '0207': {
      display: 'Dominicaanse',
    },
    '0208': {
      display: 'Salvadoraanse',
    },
    '0211': {
      display: 'Guatemalaanse',
    },
    '0212': {
      display: 'Haïtiaanse',
    },
    '0213': {
      display: 'Hondurese',
    },
    '0214': {
      display: 'Jamaicaanse',
    },
    '0216': {
      display: 'Mexicaanse',
    },
    '0218': {
      display: 'Nicaraguaanse',
    },
    '0219': {
      display: 'Panamese',
    },
    '0222': {
      display: 'Burger van Trinidad en Tobago',
    },
    '0223': {
      display: 'Amerikaans burger',
    },
    '0250': {
      display: 'Argentijnse',
    },
    '0251': {
      display: 'Barbadaanse',
    },
    '0252': {
      display: 'Boliviaanse',
    },
    '0253': {
      display: 'Braziliaanse',
    },
    '0254': {
      display: 'Chileense',
    },
    '0255': {
      display: 'Colombiaanse',
    },
    '0256': {
      display: 'Ecuadoraanse',
    },
    '0259': {
      display: 'Guyaanse',
    },
    '0261': {
      display: 'Paraguayaanse',
    },
    '0262': {
      display: 'Peruaanse',
    },
    '0263': {
      display: 'Surinaamse',
    },
    '0264': {
      display: 'Uruguayaanse',
    },
    '0265': {
      display: 'Venezolaanse',
    },
    '0267': {
      display: 'Grenadaanse',
    },
    '0268': {
      display: 'Burger van Saint Kitts en Nevis',
    },
    '0300': {
      display: 'Afghaanse',
    },
    '0301': {
      display: 'Bahreinse',
    },
    '0302': {
      display: 'Bhutaanse',
    },
    '0303': {
      display: 'Burmaanse',
    },
    '0304': {
      display: 'Bruneise',
    },
    '0305': {
      display: 'Cambodjaanse',
    },
    '0306': {
      display: 'Sri Lankaanse',
    },
    '0307': {
      display: 'Chinese',
    },
    '0308': {
      display: 'Cyprische',
    },
    '0309': {
      display: 'Filipijnse',
    },
    '0310': {
      display: 'Taiwanese',
    },
    '0312': {
      display: 'Indiase',
    },
    '0313': {
      display: 'Indonesische',
    },
    '0314': {
      display: 'Iraakse',
    },
    '0315': {
      display: 'Iraanse',
    },
    '0316': {
      display: 'Israëlische',
    },
    '0317': {
      display: 'Japanse',
    },
    '0318': {
      display: 'Noord-Jemenitische',
    },
    '0319': {
      display: 'Jordaanse',
    },
    '0320': {
      display: 'Koeweitse',
    },
    '0321': {
      display: 'Laotiaanse',
    },
    '0322': {
      display: 'Libanese',
    },
    '0324': {
      display: 'Maldivische',
    },
    '0325': {
      display: 'Maleisische',
    },
    '0326': {
      display: 'Mongolische',
    },
    '0327': {
      display: 'Omaanse',
    },
    '0328': {
      display: 'Nepalese',
    },
    '0329': {
      display: 'Noord-Koreaanse',
    },
    '0331': {
      display: 'Pakistaanse',
    },
    '0333': {
      display: 'Qatarese',
    },
    '0334': {
      display: 'Saoedi-Arabische',
    },
    '0335': {
      display: 'Singaporese',
    },
    '0336': {
      display: 'Syrische',
    },
    '0337': {
      display: 'Thaise',
    },
    '0338': {
      display: 'Burger van de Verenigde Arabische Emiraten',
    },
    '0339': {
      display: 'Turkse',
    },
    '0340': {
      display: 'Zuid-Jemenitische',
    },
    '0341': {
      display: 'Zuid-Koreaanse',
    },
    '0342': {
      display: 'Vietnamese',
    },
    '0345': {
      display: 'Bengalese',
    },
    '0400': {
      display: 'Australische',
    },
    '0401': {
      display: 'Papoea-Nieuw-Guinese',
    },
    '0402': {
      display: 'Nieuw-Zeelandse',
    },
    '0404': {
      display: 'West-Samoaanse',
    },
    '0405': {
      display: 'Samoaanse',
    },
    '0421': {
      display: 'Burger van Antigua en Barbuda',
    },
    '0424': {
      display: 'Vanuatuaanse',
    },
    '0425': {
      display: 'Fijische',
    },
    '0429': {
      display: 'Burger van Britse afhankelijke gebieden',
    },
    '0430': {
      display: 'Tongaanse',
    },
    '0431': {
      display: 'Nauruaanse',
    },
    '0432': {
      display: 'Palause',
    },
    '0437': {
      display: 'Amerikaans onderdaan',
    },
    '0442': {
      display: 'Salomonseilandse',
    },
    '0443': {
      display: 'Micronesische',
    },
    '0444': {
      display: 'Seychelse',
    },
    '0445': {
      display: 'Kiribatische',
    },
    '0446': {
      display: 'Tuvaluaanse',
    },
    '0447': {
      display: 'Saint Luciaanse',
    },
    '0448': {
      display: 'Burger van Dominica',
    },
    '0449': {
      display: 'Burger van Saint Vincent en de Grenadines',
    },
    '0450': {
      display: 'British National (overseas)',
    },
    '0451': {
      display: 'Burger van Democratische Republiek Congo',
    },
    '0452': {
      display: 'Burger van Timor Leste',
    },
    '0453': {
      display: 'Burger van Servië en Montenegro',
    },
    '0454': {
      display: 'Servische',
    },
    '0455': {
      display: 'Montenegrijnse',
    },
    '0499': {
      display: 'Staatloos',
    },
    '0500': {
      display: 'Vastgesteld niet-Nederlander',
    },
  },
};
