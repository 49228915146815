import { AllergyIntolerance } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectAllergyIntoleranceId(allergyIntolerance: AllergyIntolerance): string {
  if (!allergyIntolerance.id) throw new Error('The AllergyIntolerance is not created yet.');
  return allergyIntolerance.id;
}

export function sortById(a: AllergyIntolerance, b: AllergyIntolerance): number {
  return selectAllergyIntoleranceId(a).localeCompare(selectAllergyIntoleranceId(b));
}

export type State = EntityState<AllergyIntolerance>;

export const adapter: EntityAdapter<AllergyIntolerance> = createEntityAdapter<AllergyIntolerance>({
  selectId: selectAllergyIntoleranceId,
  sortComparer: sortById,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { allergyIntolerances }) => {
    return adapter.upsertMany(allergyIntolerances, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

const { selectAll, selectEntities } = adapter.getSelectors();
export const selectAllergyIntoleranceEntities = selectEntities;
export const selectAllAllergyIntolerances = selectAll;
