import { environment } from '@environments/environment';

export const ROOT_URL = environment.apiRoot ?? '/api';
export const AUTHORIZE_URL = `${ROOT_URL}/authorize-url`;
export const REVOKE = `${ROOT_URL}/revoke`;
export const REAUTHORIZE_URL = `${ROOT_URL}/reauthorize`;
export const EXCHANGE_CODE_FOR_TOKEN = `${ROOT_URL}/exchange-code-for-token`;
export const READ_ACCOUNTS = `${ROOT_URL}/read-accounts`;
export const DELETE_ACCOUNT = `${ROOT_URL}/delete-account`;
export const UPDATE_AD_USER = `${ROOT_URL}/update-ad-user`;
export const AD_USER = `${ROOT_URL}/ad-user`;
export const FILES = `${ROOT_URL}/files`;
export const FILE_LIST = `${ROOT_URL}/file-list`;
export const RESET = `${ROOT_URL}/reset`;
export const PORTABILITY_REPORT = `${ROOT_URL}/portability-report`;
export const CLAIM = `${ROOT_URL}/claim`;
export const EVENT_LOG = `${ROOT_URL}/event-log`;
export const USER = `${ROOT_URL}/user`;
export const CONFIRM_URL = `${ROOT_URL}/confirm`;
export const MERGE_DISTINCT_IDS = `${ROOT_URL}/merge-distinct-ids`;
export const FILE = `${ROOT_URL}/file`;
export const PUSH_DATA = `${ROOT_URL}/push`;
