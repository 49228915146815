import { Extension } from '@hl7fhir';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { YesNoPipe } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';

export class AppointmentOnlineEditableViewModel extends ElementViewModel<Extension> {
  get indicator(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.element?.extension,
      StructureDefinition.Nictiz.ONLINE_EDITABLE.indicator
    );
    return new YesNoPipe().transform(extension?.valueBoolean);
  }

  get onlineEditableUntil(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.element?.extension,
      StructureDefinition.Nictiz.ONLINE_EDITABLE.onlineEditableUntil
    );

    return extension?.valueDateTime && formatLocaleDate(extension.valueDateTime, 'long');
  }
}
