/**
 * Reference:
 * https://hl7.org/fhir/versioning.html#mp-version
 */

export type FhirVersion = 'R3' | 'R4' | 'R4b' | 'R5';
export const versionProfileMap: Record<FhirVersion, string> = {
  R3: 'http://hl7.org/fhir/3.0/',
  R4: 'http://hl7.org/fhir/4.0/',
  R4b: 'http://hl7.org/fhir/4.3/',
  R5: 'http://hl7.org/fhir/5.0/',
};
export function getProfileForVersion(version: FhirVersion): string {
  return versionProfileMap[version];
}
