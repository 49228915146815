import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PeriodTypePipe } from './pipes/period.pipe';

const pipes = [PeriodTypePipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class PeriodModule {}
