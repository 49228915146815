import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../../features';
import { PatientContactEffects } from './patient-contact.effects';
import { reducer } from './patient-contact.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(features.FHIR.patientContacts, reducer),
    EffectsModule.forFeature([PatientContactEffects]),
  ],
})
export class PatientContactStoreModule {}
