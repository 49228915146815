import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { Specimen } from '@hl7fhir';
import { AnnotationViewModel, CodeableConceptPipe, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { SpecimenStatusPipe } from '@hl7fhir/value-sets';
import { SpecimenCollectionViewModel } from './specimen-collection.viewmodel';
import { SpecimenContainerViewModel } from './specimen-container.viewmodel';
import { SpecimenFeatureViewModel } from './specimen-feature.viewmodel';
import { SpecimenProcessingViewModel } from './specimen-processing.viewmodel';

export class SpecimenViewModel extends DomainResourceViewModel<Specimen> {
  private readonly specimenR = this.resource as r4.Specimen | r4b.Specimen | r5.Specimen;
  private readonly specimenR5 = this.resource as r5.Specimen;

  get identifier(): IdentifierViewModel[] | undefined {
    return (
      this.resource?.identifier &&
      this.resource.identifier.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion))
    );
  }

  get accessionIdentifier(): IdentifierViewModel | undefined {
    return (
      this.resource?.accessionIdentifier && new IdentifierViewModel(this.resource.accessionIdentifier, this.fhirVersion)
    );
  }

  get status(): string | undefined {
    return this.resource?.status && new SpecimenStatusPipe().transform(this.resource.status);
  }

  get type(): string | undefined {
    return this.resource?.type && new CodeableConceptPipe().transform(this.resource.type);
  }

  get subject(): string | undefined {
    return this.resource?.subject && getReference(this.resource.subject);
  }

  get receivedTime(): string | undefined {
    return this.resource?.receivedTime && formatLocaleDate(this.resource.receivedTime, 'long');
  }

  get parent(): string | undefined {
    return this.resource?.parent && getReferences(this.resource.parent);
  }

  get request(): string | undefined {
    return this.resource?.request && getReferences(this.resource.request);
  }

  get collection(): SpecimenCollectionViewModel | undefined {
    return this.resource?.collection && new SpecimenCollectionViewModel(this.resource.collection, this.fhirVersion);
  }

  get processing(): SpecimenProcessingViewModel[] | undefined {
    return (
      this.resource?.processing &&
      this.resource.processing.map((item) => new SpecimenProcessingViewModel(item, this.fhirVersion))
    );
  }

  get container(): SpecimenContainerViewModel[] | undefined {
    return (
      this.resource?.container &&
      this.resource.container.map((item) => new SpecimenContainerViewModel(item, this.fhirVersion))
    );
  }

  get annotations(): AnnotationViewModel[] | undefined {
    return (
      this.resource?.note &&
      this.resource.note.map((annotation) => new AnnotationViewModel(annotation, this.fhirVersion))
    );
  }

  //R4, R4b, R5 only
  get condition(): string | undefined {
    return this.specimenR?.condition && new CodeableConceptPipe().transform(this.specimenR.condition);
  }

  //R5 only
  get combined(): string | undefined {
    return this.specimenR5?.combined && this.specimenR5.combined;
  }

  //R5 only
  get role(): string | undefined {
    return this.specimenR5?.role && new CodeableConceptPipe().transform(this.specimenR5.role);
  }

  //R5 only
  get feature(): SpecimenFeatureViewModel[] | undefined {
    return (
      this.specimenR5?.feature &&
      this.specimenR5.feature?.map((item) => new SpecimenFeatureViewModel(item, this.fhirVersion))
    );
  }
}
