import { Specimen } from '@hl7fhir';
import { SpecimenViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromDiagnosticReport from './specimen.reducer';

export const selectSpecimenState = createFeatureSelector<fromDiagnosticReport.State>(features.FHIR.specimen);

export const selectSpecimenEntities = createSelector(
  selectSpecimenState,
  fromDiagnosticReport.selectDiagnosticReportEntities
);

export const selectAllSpecimens = createSelector(selectSpecimenState, fromDiagnosticReport.selectAllDiagnosticReports);

export const selectSpecimenById = createSelector(
  selectRouteParams,
  selectSpecimenEntities,
  ({ id }, specimens: Dictionary<Specimen>) =>
    id && (specimens[id] ?? specimens[id.toLowerCase()] ?? specimens[id.toUpperCase()])
);

export const selectAllSpecimenViewModels = createSelector(selectAllSpecimens, (dataSource: Specimen[]) =>
  dataSource.map((item) => new SpecimenViewModel(item))
);

export const selectSpecimenViewModelById = createSelector(
  selectSpecimenById,
  (entity: Specimen | undefined) => entity && new SpecimenViewModel(entity)
);
