import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { Encounter } from '@hl7fhir';
import {
  CodeableConceptPipe,
  CodingsPipe,
  IdentifierViewModel,
  PeriodTypePipe,
  QuantityTypePipe,
} from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { EncounterStatusPipe } from '@hl7fhir/value-sets';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { EncounterClassHistoryViewModel } from './encounter-class-history.viewmodel';
import { EncounterDiagnosisViewModel } from './encounter-diagnosis.viewmodel';
import { EncounterHospitalizationViewModel } from './encounter-hospitalization.viewmodel';
import { EncounterLocationViewModel } from './encounter-location.viewmodel';
import { EncounterParticipantViewModel } from './encounter-participant.viewmodel';
import { EncounterStatusHistoryViewModel } from './encounter-status-history.viewmodel';

export class EncounterViewModel extends DomainResourceViewModel<Encounter> {
  private readonly encounterR3 = this.resource as r3.Encounter | undefined;
  private readonly encounterR = this.resource as r3.Encounter | r4.Encounter | r4b.Encounter | undefined;

  get identifier(): IdentifierViewModel[] | undefined {
    return (
      this.resource?.identifier &&
      this.resource.identifier.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion))
    );
  }

  get status(): string | undefined {
    return this.resource?.status && new EncounterStatusPipe().transform(this.resource.status);
  }

  get class(): string | undefined {
    return this.resource?.class && new CodingsPipe().transform(this.resource.class);
  }

  get type(): string | undefined {
    return this.resource?.type && new CodeableConceptPipe().transform(this.resource.type);
  }

  get subject(): string | undefined {
    return this.resource?.subject && getReference(this.resource.subject);
  }

  get period(): string | undefined {
    return this.encounterR?.period && new PeriodTypePipe().transform(this.encounterR.period);
  }

  get reason(): string | undefined {
    return this.encounterR3?.reason && new CodeableConceptPipe().transform(this.encounterR3.reason);
  }

  get priority(): string | undefined {
    return this.resource?.priority && new CodeableConceptPipe().transform(this.resource.priority);
  }

  get participants(): EncounterParticipantViewModel[] | undefined {
    return (
      this.encounterR?.participant &&
      this.encounterR.participant.map((participant) => new EncounterParticipantViewModel(participant, this.fhirVersion))
    );
  }

  get individual(): string | undefined {
    return (
      this.encounterR?.participant &&
      getReferences(
        this.encounterR.participant
          ?.map((participant) => participant.individual)
          .filter((participant): participant is r3.Reference | r4.Reference | r4b.Reference => !!participant)
      )
    );
  }

  get serviceProvider(): string | undefined {
    return this.resource?.serviceProvider && getReference(this.resource.serviceProvider);
  }

  get account(): string | undefined {
    return this.resource?.account && getReferences(this.resource.account);
  }

  get appointment(): string | undefined {
    return this.encounterR3?.appointment && getReference(this.encounterR3.appointment);
  }

  get classHistory(): EncounterClassHistoryViewModel[] | undefined {
    return (
      this.encounterR?.classHistory &&
      this.encounterR.classHistory.map((history) => new EncounterClassHistoryViewModel(history, this.fhirVersion))
    );
  }

  get diagnosis(): EncounterDiagnosisViewModel[] | undefined {
    return (
      this.encounterR?.diagnosis &&
      this.encounterR.diagnosis.map((diagnosis) => new EncounterDiagnosisViewModel(diagnosis, this.fhirVersion))
    );
  }

  get episodeOfCare(): string | undefined {
    return this.resource?.episodeOfCare && getReferences(this.resource.episodeOfCare);
  }

  get hospitalization(): EncounterHospitalizationViewModel | undefined {
    return (
      this.encounterR?.hospitalization &&
      new EncounterHospitalizationViewModel(this.encounterR.hospitalization, this.fhirVersion)
    );
  }

  get incomingReferral(): string | undefined {
    return this.encounterR3?.incomingReferral && getReferences(this.encounterR3.incomingReferral);
  }

  get length(): string | undefined {
    return this.resource?.length && new QuantityTypePipe().transform(this.resource.length);
  }

  get location(): EncounterLocationViewModel[] | undefined {
    return (
      this.encounterR?.location &&
      this.encounterR.location.map((location) => new EncounterLocationViewModel(location, this.fhirVersion))
    );
  }

  get partOf(): string | undefined {
    return this.resource?.partOf && getReference(this.resource.partOf);
  }

  get statusHistory(): EncounterStatusHistoryViewModel[] | undefined {
    return (
      this.encounterR?.statusHistory &&
      this.encounterR.statusHistory.map(
        (statusHistory) => new EncounterStatusHistoryViewModel(statusHistory, this.fhirVersion)
      )
    );
  }
}
