import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { AllergyIntoleranceReaction } from '@hl7fhir';
import { AnnotationViewModel, CodeableConceptPipe } from '@hl7fhir/data-types';
import { AllergyIntoleranceReactionSeverityPipe } from '@hl7fhir/value-sets';

export class AllergyIntoleranceReactionViewModel extends BackboneElementViewModel<AllergyIntoleranceReaction> {
  get substance(): string | undefined {
    return this.element?.substance && new CodeableConceptPipe().transform(this.element.substance);
  }

  get manifestation(): string | undefined {
    if (this.fhirVersion === 'R5') {
      // TODO: Support R5 codeablereference type
      return undefined;
    }
    return this.element?.manifestation && new CodeableConceptPipe().transform(this.element.manifestation);
  }

  get description(): string | undefined {
    return this.element?.description;
  }

  get onset(): string | undefined {
    return this.element?.onset && formatLocaleDate(this.element.onset, 'long');
  }

  get severity(): string | undefined {
    return this.element?.severity && new AllergyIntoleranceReactionSeverityPipe().transform(this.element.severity);
  }

  get exposureRoute(): string | undefined {
    return this.element?.exposureRoute && new CodeableConceptPipe().transform(this.element.exposureRoute);
  }

  get note(): AnnotationViewModel[] | undefined {
    return this.element?.note && this.element.note.map((note) => new AnnotationViewModel(note, this.fhirVersion));
  }
}
