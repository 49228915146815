import { Params } from '@angular/router';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const APP_ACTIONS = createActionGroup({
  source: 'App',
  events: {
    Authenticated: props<{ isNewUser?: boolean; isReturn?: boolean }>(),
    Unauthenticated: emptyProps(),
    'Authentication Started': props<{ prompt?: string; skipLog?: boolean }>(),
    'Authentication Failed': emptyProps(),
    'Authentication Error': props<{ error: any }>(),
    claim: props<{ library: string }>(),
    userVisit: emptyProps(),
    Authorize: emptyProps(),
    timeoutLogout: emptyProps(),
  },
});

export const DOCUMENT_DOWNLOAD_ACTIONS = createActionGroup({
  source: 'Document Download',
  events: {
    'Download Started': props<{ documentType: string; startedFrom: string }>(),
    'Download Success': props<{ documentType: string }>(),
    'Download Failure': props<{ documentType: string | undefined; error: any }>(),
  },
});

export const IDLE_ACTIONS = createActionGroup({
  source: 'IdleService',
  events: {
    Start: emptyProps(),
    End: emptyProps(),
    Timeout: emptyProps(),
  },
});

export const ERROR_UI_ACTIONS = createActionGroup({
  source: 'Error UI',
  events: {
    'Show Error Message': props<{ code: string | undefined; message?: string }>(),
    'Reset Error Message': emptyProps(),
    'Ignore Error Message': emptyProps(),
    'General failure': props<{ params: Params }>(),
  },
});

export const MODAL_ACTIONS = createActionGroup({
  source: 'Modal',
  events: {
    'Show Modal': props<{ modal: string }>(),
    'Reset State': emptyProps(),
  },
});

export const FRESHDESK_ACTIONS = createActionGroup({
  source: 'Freshdesk UI',
  events: {
    'Open Widget': emptyProps(),
  },
});
