import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EntryComponent } from './entry/entry.component';

const components = [EntryComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class LayoutModule {}
