import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentManifestStatus',
})
export class DocumentManifestStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'current':
        return $localize`:@@fhir.document-manifest-status.current:Current`;
      case 'superseded':
        return $localize`:@@fhir.document-manifest-status.superseded:Superseded`;
      case 'entered-in-error':
        return $localize`:@@fhir.document-manifest-status.entered-in-error:Entered in Error`;
      default:
        return '';
    }
  }
}
