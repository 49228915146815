import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavigatorOnLineService } from '@core/services';
import { Status } from '@digi.me/models';
import { environment } from '@environments/environment';
import { StorageKeys } from '@globals';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '@store/app';
import { selectFileListStatus, selectMustCreateAccount, selectShouldReauthorize } from '@store/digi.me';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, combineLatest, map, of } from 'rxjs';
import { MenuItem } from '../menu-item.model';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-menu-navbar',
  templateUrl: './menu-navbar.component.html',
  styles: ['.spacer { min-height: 6rem }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuNavBarComponent {
  readonly menu$: Observable<MenuItem[] | undefined>;
  readonly shouldReauthorize$: Observable<boolean>;
  readonly digiMeStatus$: Observable<Status | undefined>;
  readonly isAuthenticated$: Observable<boolean>;
  online$ = this.navigatorOnlineService.online$;

  readonly environment = environment;
  protected readonly mustCreateAccount$: Observable<boolean> = combineLatest([
    this.store.select(selectMustCreateAccount),
    of(Boolean(localStorage.getItem(StorageKeys.SHOULD_CREATE_ACCOUNT))),
    this.oidcSecurityService.isAuthenticated$,
  ]).pipe(
    map(
      ([libraryExists, shouldCreateAccount, authenticatedResult]) =>
        libraryExists || (shouldCreateAccount && !authenticatedResult.isAuthenticated)
    )
  );

  constructor(
    private offcanvasService: NgbOffcanvas,
    menuService: MenuService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly navigatorOnlineService: NavigatorOnLineService,
    private readonly oidcSecurityService: OidcSecurityService
  ) {
    this.menu$ = menuService.menu$;
    this.shouldReauthorize$ = store.select(selectShouldReauthorize);
    this.digiMeStatus$ = store.select(selectFileListStatus);
    this.isAuthenticated$ = store.select(selectIsAuthenticated);
  }

  open(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  close() {
    this.offcanvasService.dismiss();
  }

  gotoNotifications() {
    this.router.navigate(['/notifications']);
  }
}
