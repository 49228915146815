<app-choice-of-types *ngIf="params"
                     [dateTime]="params['dateTime']"
                     [string]="params['string']"
                     [age]="params['age']"
                     [period]="params['period']"
                     [range]="params['range']"
                     [quantity]="params['quantity']"
                     [codeableConcept]="params['codeableConcept']"
                     [boolean]="params['boolean']"
                     [integer]="params['integer']"
                     [ratio]="params['ratio']"
                     [sampledData]="params['sampledData']"
                     [time]="params['time']"
                     [attachment]="params['attachment']"
                     [reference]="params['reference']"
                     [instant]="params['instant']">
</app-choice-of-types>