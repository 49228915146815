import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';
export const v3CodeSystemConfidentiality: CodeSystemFlattened = {
  id: 'v3-Confidentiality',
  codes: {
    _Confidentiality: {
      display: 'Confidentiality',
    },
    _ConfidentialityByAccessKind: {
      display: 'ConfidentialityByAccessKind',
    },
    _ConfidentialityByInfoType: {
      display: 'ConfidentialityByInfoType',
    },
    _ConfidentialityModifiers: {
      display: 'ConfidentialityModifiers',
    },
    L: {
      display: 'low',
    },
    M: {
      display: 'moderate',
    },
    N: {
      display: 'normal',
    },
    R: {
      display: 'restricted',
    },
    U: {
      display: 'unrestricted',
    },
    V: {
      display: 'very restricted',
    },
    B: {
      display: 'business',
    },
    D: {
      display: 'clinician',
    },
    I: {
      display: 'individual',
    },
    ETH: {
      display: 'substance abuse related',
    },
    HIV: {
      display: 'HIV related',
    },
    PSY: {
      display: 'psychiatry relate',
    },
    SDV: {
      display: 'sexual and domestic violence related',
    },
    C: {
      display: 'celebrity',
    },
    S: {
      display: 'sensitive',
    },
    T: {
      display: 'taboo',
    },
  },
};
