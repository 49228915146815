import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '@layout';

@Component({
  standalone: true,
  selector: 'app-log-in-button',
  imports: [ButtonComponent],
  template: `
    <app-button
      type="secondary"
      data-cy="login-btn"
      icon="exit_to_app"
      (clicked)="login.emit()"
      i18n-label="@@app.OnboardOrLogIn.logInButton"
      label="Log in"
    ></app-button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogInButtonComponent {
  @Output() login = new EventEmitter();

  constructor() {}
}
