import { DocumentManifestContent } from '@hl7fhir';
import { AttachmentViewModel } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';

export class DocumentManifestContentViewModel extends BackboneElementViewModel<DocumentManifestContent> {
  get pAttachment(): AttachmentViewModel | undefined {
    return this.element?.pAttachment && new AttachmentViewModel(this.element.pAttachment, this.fhirVersion);
  }

  get pReference(): string | undefined {
    return this.element?.pReference && getReference(this.element?.pReference);
  }
}
