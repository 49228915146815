import { Appointment } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectAppointmentId(appointment: Appointment): string {
  if (!appointment.id) throw new Error('The appointment is not created yet.');

  return appointment.id;
}

export type State = EntityState<Appointment>;

export const adapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: selectAppointmentId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { appointments }) => {
    return adapter.upsertMany(appointments, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state, _action) => adapter.removeAll(state))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectAppointmentEntities = selectEntities;

export const selectAllAppointments = selectAll;
