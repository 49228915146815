import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '../../fhir.types';
import { FhirVersion, getProfileForVersion } from './version.model';

@Pipe({
  name: 'version',
})
export class VersionPipe implements PipeTransform {
  transform(resource: Resource | null | undefined, ...allowedVersions: FhirVersion[]): boolean {
    if (!resource || !resource.meta || !resource.meta.profile) {
      return false;
    }

    for (const version of allowedVersions) {
      const profileUri = getProfileForVersion(version);
      if (resource.meta?.profile?.some((url) => url.startsWith(profileUri))) {
        return true;
      }
    }

    return false;
  }
}
