import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  private readonly history: string[] = [];
  private readonly maxStackSize = 10;

  constructor(
    private readonly router: Router,
    private readonly location: Location
  ) {}

  startHistoryTracking(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.length === this.maxStackSize) {
          this.history.shift();
        }
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(defaultUrl: string | undefined): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(defaultUrl ?? '/');
    }
  }
}
