import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const BehandelingToegestaanCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.2.1.2--20171231000000',
  systems: {
    'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4': {
      JA: {
        'nl-NL': 'Behandeling is toegestaan en/of wenselijk.',
        display: 'Ja',
      },
      JA_MAAR: {
        'nl-NL': 'Behandeling toegestaan en/of wenselijk maar met beperkingen die dan ook vermeld moeten worden',
        display: 'Ja, maar met beperkingen',
      },
      NEE: {
        'nl-NL': 'Behandeling niet toegestaan en/of wenselijk',
        display: 'Nee',
      },
    },
  },
};
