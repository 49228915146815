import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const SecurityRoleType: CodeSystemFlattened = {
  id: 'extra-security-role-type',
  codes: {
    authserver: {
      display: 'authorization server',
    },
    datacollector: {
      display: 'data collector',
    },
    dataprocessor: {
      display: 'data processor',
    },
    datasubject: {
      display: 'data subject',
    },
  },
};
