import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { APP_ACTIONS } from '@store/app';
import { LogInButtonComponent } from './login-button.component';

@Component({
  standalone: true,
  selector: 'app-log-in-button-container',
  imports: [LogInButtonComponent],
  template: `<app-log-in-button (login)="login()"></app-log-in-button> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogInButtonContainerComponent {
  constructor(private readonly store: Store) {}

  login() {
    this.store.dispatch(APP_ACTIONS.authenticationStarted({ prompt: 'login' }));
  }
}
