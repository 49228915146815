export interface UserResponse {
  user: User;
  tokens: Tokens;
}

export interface User {
  id: string;
}

export interface Tokens {
  accessTokenExpiry: number;
  refreshTokenEpiry: number;
}
