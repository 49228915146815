export const STRUCTURE_DEFINITION = 'http://hl7.org/fhir/StructureDefinition';

export const PROCEDURE = {
  method: 'http://hl7.org/fhir/StructureDefinition/procedure-method',
};

export const PRACTITIONER_ROLE = {
  nlProfile: 'http://fhir.nl/fhir/StructureDefinition/nl-core-practitionerrole',
};

export const FLAG = {
  priority: 'http://hl7.org/fhir/StructureDefinition/flag-priority',
  detail: 'http://hl7.org/fhir/StructureDefinition/flag-detail',
};

export const CONSENT = {
  exceptType: 'http://hl7.org/fhir/ValueSet/consent-except-type',
};
