import { Patient } from '@hl7fhir';
import { PatientViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromPatient from './patient.reducer';

export const selectPatientState = createFeatureSelector<fromPatient.State>(features.FHIR.patients);

export const selectPatientIds = createSelector(selectPatientState, fromPatient.selectPatientIds);

export const selectPatientEntities = createSelector(selectPatientState, fromPatient.selectPatientEntities);

export const selectAllPatients = createSelector(selectPatientState, fromPatient.selectAllPatients);

export const selectPatientTotal = createSelector(selectPatientState, fromPatient.selectPatientTotal);

export const selectPatientById = createSelector(
  selectRouteParams,
  selectPatientEntities,
  ({ id }, patients: Dictionary<Patient>) =>
    id && (patients[id] ?? patients[id.toLowerCase()] ?? patients[id.toUpperCase()])
);

export const selectAllPatientViewModels = createSelector(selectAllPatients, (patients: Patient[]) =>
  patients.map((patient) => new PatientViewModel(patient))
);

export const selectPatientViewModelById = createSelector(
  selectPatientById,
  (entity: Patient | undefined) => entity && new PatientViewModel(entity)
);
