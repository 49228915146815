import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const ISO3166_1Alpha2: CodeSystemFlattened = {
  id: 'ec0373c0-5960-4e89-bfcc-b03c003de9e6',
  codes: {
    AF: {
      'fr-FR': "Afghanistan (l')",
      'en-US': 'Afghanistan',
      display: 'Afghanistan',
    },
    AL: {
      'fr-FR': "Albanie (l')",
      'en-US': 'Albania',
      display: 'Albanië',
    },
    DZ: {
      'fr-FR': "Algérie (l')",
      'en-US': 'Algeria',
      display: 'Algerije',
    },
    AS: {
      'fr-FR': 'Samoa américaines (les)',
      'en-US': 'American Samoa',
      display: 'Amerikaans-Samoa',
    },
    AD: {
      'fr-FR': "Andorre (l')",
      'en-US': 'Andorra',
      display: 'Andorra',
    },
    AO: {
      'fr-FR': "Angola (l')",
      'en-US': 'Angola',
      display: 'Angola',
    },
    AI: {
      'fr-FR': 'Anguilla',
      'en-US': 'Anguilla',
      display: 'Anguilla',
    },
    AQ: {
      'fr-FR': "Antarctique (l')",
      'en-US': 'Antarctica',
      display: 'Antarctica',
    },
    AG: {
      'fr-FR': 'Antigua-et-Barbuda',
      'en-US': 'Antigua and Barbuda',
      display: 'Antigua en Barbuda',
    },
    AR: {
      'fr-FR': "Argentine (l')",
      'en-US': 'Argentina',
      display: 'Argentinië',
    },
    AM: {
      'fr-FR': "Arménie (l')",
      'en-US': 'Armenia',
      display: 'Armenië',
    },
    AW: {
      'fr-FR': 'Aruba',
      'en-US': 'Aruba',
      display: 'Aruba',
    },
    AU: {
      'fr-FR': "Australie (l')",
      'en-US': 'Australia',
      display: 'Australië',
    },
    AT: {
      'fr-FR': "Autriche (l')",
      'en-US': 'Austria',
      display: 'Oostenrijk',
    },
    AZ: {
      'fr-FR': "Azerbaïdjan (l')",
      'en-US': 'Azerbaijan',
      display: 'Azerbeidzjan',
    },
    BS: {
      'fr-FR': 'Bahamas (les)',
      'en-US': 'Bahamas (the)',
      display: "Bahama's",
    },
    BH: {
      'fr-FR': 'Bahreïn',
      'en-US': 'Bahrain',
      display: 'Bahrein',
    },
    BD: {
      'fr-FR': 'Bangladesh (le)',
      'en-US': 'Bangladesh',
      display: 'Bangladesh',
    },
    BB: {
      'fr-FR': 'Barbade (la)',
      'en-US': 'Barbados',
      display: 'Barbados',
    },
    BY: {
      'fr-FR': 'Bélarus (le)',
      'en-US': 'Belarus',
      display: 'Wit-Rusland',
    },
    BE: {
      'fr-FR': 'Belgique (la)',
      'en-US': 'Belgium',
      display: 'België',
    },
    BZ: {
      'fr-FR': 'Belize (le)',
      'en-US': 'Belize',
      display: 'Belize',
    },
    BJ: {
      'fr-FR': 'Bénin (le)',
      'en-US': 'Benin',
      display: 'Benin',
    },
    BM: {
      'fr-FR': 'Bermudes (les)',
      'en-US': 'Bermuda',
      display: 'Bermuda',
    },
    BT: {
      'fr-FR': 'Bhoutan (le)',
      'en-US': 'Bhutan',
      display: 'Bhutan',
    },
    BO: {
      'fr-FR': 'Bolivie (État plurinational de)',
      'en-US': 'Bolivia (Plurinational State of)',
      display: 'Bolivia',
    },
    BQ: {
      'fr-FR': 'Bonaire, Saint-Eustache et Saba',
      'en-US': 'Bonaire, Sint Eustatius and Saba',
      display: 'Caribisch Nederland',
    },
    BA: {
      'fr-FR': 'Bosnie-Herzégovine (la)',
      'en-US': 'Bosnia and Herzegovina',
      display: 'Bosnië en Herzegovina',
    },
    BW: {
      'fr-FR': 'Botswana (le)',
      'en-US': 'Botswana',
      display: 'Botswana',
    },
    BV: {
      'fr-FR': "Bouvet (l'Île)",
      'en-US': 'Bouvet Island',
      display: 'Bouveteiland',
    },
    BR: {
      'fr-FR': 'Brésil (le)',
      'en-US': 'Brazil',
      display: 'Brazilië',
    },
    IO: {
      'fr-FR': "Indien (le Territoire britannique de l'océan)",
      'en-US': 'British Indian Ocean Territory (the)',
      display: 'Brits Indische Oceaanterritorium',
    },
    BN: {
      'fr-FR': 'Brunéi Darussalam (le)',
      'en-US': 'Brunei Darussalam',
      display: 'Brunei',
    },
    BG: {
      'fr-FR': 'Bulgarie (la)',
      'en-US': 'Bulgaria',
      display: 'Bulgarije',
    },
    BF: {
      'fr-FR': 'Burkina Faso (le)',
      'en-US': 'Burkina Faso',
      display: 'Burkina Faso',
    },
    BI: {
      'fr-FR': 'Burundi (le)',
      'en-US': 'Burundi',
      display: 'Burundi',
    },
    CV: {
      'fr-FR': 'Cabo Verde',
      'en-US': 'Cabo Verde',
      display: 'Kaapverdië',
    },
    KH: {
      'fr-FR': 'Cambodge (le)',
      'en-US': 'Cambodia',
      display: 'Cambodja',
    },
    CM: {
      'fr-FR': 'Cameroun (le)',
      'en-US': 'Cameroon',
      display: 'Kameroen',
    },
    CA: {
      'fr-FR': 'Canada (le)',
      'en-US': 'Canada',
      display: 'Canada',
    },
    KY: {
      'fr-FR': 'Caïmans (les Îles)',
      'en-US': 'Cayman Islands (the)',
      display: 'Kaaimaneilanden',
    },
    CF: {
      'fr-FR': 'République centrafricaine (la)',
      'en-US': 'Central African Republic (the)',
      display: 'Centraal-Afrikaanse Republiek',
    },
    TD: {
      'fr-FR': 'Tchad (le)',
      'en-US': 'Chad',
      display: 'Tsjaad',
    },
    CL: {
      'fr-FR': 'Chili (le)',
      'en-US': 'Chile',
      display: 'Chili',
    },
    CN: {
      'fr-FR': 'Chine (la)',
      'en-US': 'China',
      display: 'China',
    },
    CX: {
      'fr-FR': "Christmas (l'Île)",
      'en-US': 'Christmas Island',
      display: 'Christmaseiland',
    },
    CC: {
      'fr-FR': 'Cocos (les Îles)/ Keeling (les Îles)',
      'en-US': 'Cocos (Keeling) Islands (the)',
      display: 'Cocoseilanden',
    },
    CO: {
      'fr-FR': 'Colombie (la)',
      'en-US': 'Colombia',
      display: 'Colombia',
    },
    KM: {
      'fr-FR': 'Comores (les)',
      'en-US': 'Comoros (the)',
      display: 'Comoren',
    },
    CD: {
      'fr-FR': 'Congo (la République démocratique du)',
      'en-US': 'Congo (the Democratic Republic of the)',
      display: 'Congo-Kinshasa',
    },
    CG: {
      'fr-FR': 'Congo (le)',
      'en-US': 'Congo (the)',
      display: 'Congo-Brazzaville',
    },
    CK: {
      'fr-FR': 'Cook (les Îles)',
      'en-US': 'Cook Islands (the)',
      display: 'Cookeilanden',
    },
    CR: {
      'fr-FR': 'Costa Rica (le)',
      'en-US': 'Costa Rica',
      display: 'Rica Costa Rica',
    },
    HR: {
      'fr-FR': 'Croatie (la)',
      'en-US': 'Croatia',
      display: 'Kroatië',
    },
    CU: {
      'fr-FR': 'Cuba',
      'en-US': 'Cuba',
      display: 'Cuba',
    },
    CW: {
      'fr-FR': 'Curaçao',
      'en-US': 'Curaçao',
      display: 'Curaçao',
    },
    CY: {
      'fr-FR': 'Chypre',
      'en-US': 'Cyprus',
      display: 'Cyprus',
    },
    CZ: {
      'fr-FR': 'Tchéquie (la)',
      'en-US': 'Czechia',
      display: 'Tsjechië',
    },
    CI: {
      'fr-FR': "Côte d'Ivoire (la)",
      'en-US': "Côte d'Ivoire",
      display: 'Ivoorkust',
    },
    DK: {
      'fr-FR': 'Danemark (le)',
      'en-US': 'Denmark',
      display: 'Denemarken',
    },
    DJ: {
      'fr-FR': 'Djibouti',
      'en-US': 'Djibouti',
      display: 'Djibouti',
    },
    DM: {
      'fr-FR': 'Dominique (la)',
      'en-US': 'Dominica',
      display: 'Dominica',
    },
    DO: {
      'fr-FR': 'dominicaine (la République)',
      'en-US': 'Dominican Republic (the)',
      display: 'Dominicaanse Republiek',
    },
    EC: {
      'fr-FR': "Équateur (l')",
      'en-US': 'Ecuador',
      display: 'Ecuador',
    },
    EG: {
      'fr-FR': "Égypte (l')",
      'en-US': 'Egypt',
      display: 'Egypte',
    },
    SV: {
      'fr-FR': 'El Salvador',
      'en-US': 'El Salvador',
      display: 'El Salvador',
    },
    GQ: {
      'fr-FR': 'Guinée équatoriale (la)',
      'en-US': 'Equatorial Guinea',
      display: 'Equatoriaal-Guinea',
    },
    ER: {
      'fr-FR': "Érythrée (l')",
      'en-US': 'Eritrea',
      display: 'Eritrea',
    },
    EE: {
      'fr-FR': "Estonie (l')",
      'en-US': 'Estonia',
      display: 'Estland',
    },
    SZ: {
      'fr-FR': "Eswatini (l')",
      'en-US': 'Eswatini',
      display: 'Swaziland',
    },
    ET: {
      'fr-FR': "Éthiopie (l')",
      'en-US': 'Ethiopia',
      display: 'Ethiopië',
    },
    FK: {
      'fr-FR': 'Falkland (les Îles)/Malouines (les Îles)',
      'en-US': 'Falkland Islands (the) [Malvinas]',
      display: 'Falklandeilanden',
    },
    FO: {
      'fr-FR': 'Féroé (les Îles)',
      'en-US': 'Faroe Islands (the)',
      display: 'Faeröer',
    },
    FJ: {
      'fr-FR': 'Fidji (les)',
      'en-US': 'Fiji',
      display: 'Fiji',
    },
    FI: {
      'fr-FR': 'Finlande (la)',
      'en-US': 'Finland',
      display: 'Finland',
    },
    FR: {
      'fr-FR': 'France (la)',
      'en-US': 'France',
      display: 'Frankrijk',
    },
    GF: {
      'fr-FR': 'Guyane française (la )',
      'en-US': 'French Guiana',
      display: 'Frans-Guyana',
    },
    PF: {
      'fr-FR': 'Polynésie française (la)',
      'en-US': 'French Polynesia',
      display: 'Frans-Polynesië',
    },
    TF: {
      'fr-FR': 'Terres australes françaises (les)',
      'en-US': 'French Southern Territories (the)',
      display: 'Franse Zuidelijke en Antarctische Gebieden',
    },
    GA: {
      'fr-FR': 'Gabon (le)',
      'en-US': 'Gabon',
      display: 'Gabon',
    },
    GM: {
      'fr-FR': 'Gambie (la)',
      'en-US': 'Gambia (the)',
      display: 'Gambia',
    },
    GE: {
      'fr-FR': 'Géorgie (la)',
      'en-US': 'Georgia',
      display: 'Georgië',
    },
    DE: {
      'fr-FR': "Allemagne (l')",
      'en-US': 'Germany',
      display: 'Duitsland',
    },
    GH: {
      'fr-FR': 'Ghana (le)',
      'en-US': 'Ghana',
      display: 'Ghana',
    },
    GI: {
      'fr-FR': 'Gibraltar',
      'en-US': 'Gibraltar',
      display: 'Gibraltar',
    },
    GR: {
      'fr-FR': 'Grèce (la)',
      'en-US': 'Greece',
      display: 'Griekenland',
    },
    GL: {
      'fr-FR': 'Groenland (le)',
      'en-US': 'Greenland',
      display: 'Groenland',
    },
    GD: {
      'fr-FR': 'Grenade (la)',
      'en-US': 'Grenada',
      display: 'Grenada',
    },
    GP: {
      'fr-FR': 'Guadeloupe (la)',
      'en-US': 'Guadeloupe',
      display: 'Guadeloupe',
    },
    GU: {
      'fr-FR': 'Guam',
      'en-US': 'Guam',
      display: 'Guam',
    },
    GT: {
      'fr-FR': 'Guatemala (le)',
      'en-US': 'Guatemala',
      display: 'Guatemala',
    },
    GG: {
      'fr-FR': 'Guernesey',
      'en-US': 'Guernsey',
      display: 'Guernsey',
    },
    GN: {
      'fr-FR': 'Guinée (la)',
      'en-US': 'Guinea',
      display: 'Guinee',
    },
    GW: {
      'fr-FR': 'Guinée-Bissau (la)',
      'en-US': 'Guinea-Bissau',
      display: 'Guinee-Bissau',
    },
    GY: {
      'fr-FR': 'Guyana (le)',
      'en-US': 'Guyana',
      display: 'Guyana',
    },
    HT: {
      'fr-FR': 'Haïti',
      'en-US': 'Haiti',
      display: 'Haïti',
    },
    HM: {
      'fr-FR': "Heard-et-Îles MacDonald (l'Île)",
      'en-US': 'Heard Island and McDonald Islands',
      display: 'Heard en McDonaldeilanden',
    },
    VA: {
      'fr-FR': 'Saint-Siège (le)',
      'en-US': 'Holy See (the)',
      display: 'Vaticaanstad',
    },
    HN: {
      'fr-FR': 'Honduras (le)',
      'en-US': 'Honduras',
      display: 'Honduras',
    },
    HK: {
      'fr-FR': 'Hong Kong',
      'en-US': 'Hong Kong',
      display: 'Hongkong',
    },
    HU: {
      'fr-FR': 'Hongrie (la)',
      'en-US': 'Hungary',
      display: 'Hongarije',
    },
    IS: {
      'fr-FR': "Islande (l')",
      'en-US': 'Iceland',
      display: 'IJsland',
    },
    IN: {
      'fr-FR': "Inde (l')",
      'en-US': 'India',
      display: 'India',
    },
    ID: {
      'fr-FR': "Indonésie (l')",
      'en-US': 'Indonesia',
      display: 'Indonesië',
    },
    IR: {
      'fr-FR': "Iran (République Islamique d')",
      'en-US': 'Iran (Islamic Republic of)',
      display: 'Iran',
    },
    IQ: {
      'fr-FR': "Iraq (l')",
      'en-US': 'Iraq',
      display: 'Irak',
    },
    IE: {
      'fr-FR': "Irlande (l')",
      'en-US': 'Ireland',
      display: 'Ierland',
    },
    IM: {
      'fr-FR': 'Île de Man',
      'en-US': 'Isle of Man',
      display: 'Man',
    },
    IL: {
      'fr-FR': 'Israël',
      'en-US': 'Israel',
      display: 'Israël',
    },
    IT: {
      'fr-FR': "Italie (l')",
      'en-US': 'Italy',
      display: 'Italië',
    },
    JM: {
      'fr-FR': 'Jamaïque (la)',
      'en-US': 'Jamaica',
      display: 'Jamaica',
    },
    JP: {
      'fr-FR': 'Japon (le)',
      'en-US': 'Japan',
      display: 'Japan',
    },
    JE: {
      'fr-FR': 'Jersey',
      'en-US': 'Jersey',
      display: 'Jersey',
    },
    JO: {
      'fr-FR': 'Jordanie (la)',
      'en-US': 'Jordan',
      display: 'Jordanië',
    },
    KZ: {
      'fr-FR': 'Kazakhstan (le)',
      'en-US': 'Kazakhstan',
      display: 'Kazachstan',
    },
    KE: {
      'fr-FR': 'Kenya (le)',
      'en-US': 'Kenya',
      display: 'Kenia',
    },
    KI: {
      'fr-FR': 'Kiribati',
      'en-US': 'Kiribati',
      display: 'Kiribati',
    },
    KP: {
      'fr-FR': 'Corée (la République populaire démocratique de)',
      'en-US': "Korea (the Democratic People's Republic of)",
      display: 'Noord-Korea',
    },
    KR: {
      'fr-FR': 'Corée (la République de)',
      'en-US': 'Korea (the Republic of)',
      display: 'Zuid-Korea',
    },
    KW: {
      'fr-FR': 'Koweït (le)',
      'en-US': 'Kuwait',
      display: 'Koeweit',
    },
    KG: {
      'fr-FR': 'Kirghizistan (le)',
      'en-US': 'Kyrgyzstan',
      display: 'Kirgizië',
    },
    LA: {
      'fr-FR': 'Lao (la République démocratique populaire)',
      'en-US': "Lao People's Democratic Republic (the)",
      display: 'Laos',
    },
    LV: {
      'fr-FR': 'Lettonie (la)',
      'en-US': 'Latvia',
      display: 'Letland',
    },
    LB: {
      'fr-FR': 'Liban (le)',
      'en-US': 'Lebanon',
      display: 'Libanon',
    },
    LS: {
      'fr-FR': 'Lesotho (le)',
      'en-US': 'Lesotho',
      display: 'Lesotho',
    },
    LR: {
      'fr-FR': 'Libéria (le)',
      'en-US': 'Liberia',
      display: 'Liberia',
    },
    LY: {
      'fr-FR': 'Libye (la)',
      'en-US': 'Libya',
      display: 'Libië',
    },
    LI: {
      'fr-FR': 'Liechtenstein (le)',
      'en-US': 'Liechtenstein',
      display: 'Liechtenstein',
    },
    LT: {
      'fr-FR': 'Lituanie (la)',
      'en-US': 'Lithuania',
      display: 'Litouwen',
    },
    LU: {
      'fr-FR': 'Luxembourg (le)',
      'en-US': 'Luxembourg',
      display: 'Luxemburg',
    },
    MO: {
      'fr-FR': 'Macao',
      'en-US': 'Macao',
      display: 'Macau',
    },
    MG: {
      'fr-FR': 'Madagascar',
      'en-US': 'Madagascar',
      display: 'Madagaskar',
    },
    MW: {
      'fr-FR': 'Malawi (le)',
      'en-US': 'Malawi',
      display: 'Malawi',
    },
    MY: {
      'fr-FR': 'Malaisie (la)',
      'en-US': 'Malaysia',
      display: 'Maleisië',
    },
    MV: {
      'fr-FR': 'Maldives (les)',
      'en-US': 'Maldives',
      display: 'Maldiven',
    },
    ML: {
      'fr-FR': 'Mali (le)',
      'en-US': 'Mali',
      display: 'Mali',
    },
    MT: {
      'fr-FR': 'Malte',
      'en-US': 'Malta',
      display: 'Malta',
    },
    MH: {
      'fr-FR': 'Marshall (les Îles)',
      'en-US': 'Marshall Islands (the)',
      display: 'Marshalleilanden',
    },
    MQ: {
      'fr-FR': 'Martinique (la)',
      'en-US': 'Martinique',
      display: 'Martinique',
    },
    MR: {
      'fr-FR': 'Mauritanie (la)',
      'en-US': 'Mauritania',
      display: 'Mauritanië',
    },
    MU: {
      'fr-FR': 'Maurice',
      'en-US': 'Mauritius',
      display: 'Mauritius',
    },
    YT: {
      'fr-FR': 'Mayotte',
      'en-US': 'Mayotte',
      display: 'Mayotte',
    },
    MX: {
      'fr-FR': 'Mexique (le)',
      'en-US': 'Mexico',
      display: 'Mexico',
    },
    FM: {
      'fr-FR': 'Micronésie (États fédérés de)',
      'en-US': 'Micronesia (Federated States of)',
      display: 'Micronesia',
    },
    MD: {
      'fr-FR': 'Moldova (la République de)',
      'en-US': 'Moldova (the Republic of)',
      display: 'Moldavië',
    },
    MC: {
      'fr-FR': 'Monaco',
      'en-US': 'Monaco',
      display: 'Monaco',
    },
    MN: {
      'fr-FR': 'Mongolie (la)',
      'en-US': 'Mongolia',
      display: 'Mongolië',
    },
    ME: {
      'fr-FR': 'Monténégro (le)',
      'en-US': 'Montenegro',
      display: 'Montenegro',
    },
    MS: {
      'fr-FR': 'Montserrat',
      'en-US': 'Montserrat',
      display: 'Montserrat',
    },
    MA: {
      'fr-FR': 'Maroc (le)',
      'en-US': 'Morocco',
      display: 'Marokko',
    },
    MZ: {
      'fr-FR': 'Mozambique (le)',
      'en-US': 'Mozambique',
      display: 'Mozambique',
    },
    MM: {
      'fr-FR': 'Myanmar (le)',
      'en-US': 'Myanmar',
      display: 'Myanmar',
    },
    NA: {
      'fr-FR': 'Namibie (la)',
      'en-US': 'Namibia',
      display: 'Namibië',
    },
    NR: {
      'fr-FR': 'Nauru',
      'en-US': 'Nauru',
      display: 'Nauru',
    },
    NP: {
      'fr-FR': 'Népal (le)',
      'en-US': 'Nepal',
      display: 'Nepal',
    },
    NL: {
      'fr-FR': 'Pays-Bas (les)',
      'en-US': 'Netherlands (the)',
      display: 'Nederland',
    },
    NC: {
      'fr-FR': 'Nouvelle-Calédonie (la)',
      'en-US': 'New Caledonia',
      display: 'Nieuw-Caledonië',
    },
    NZ: {
      'fr-FR': 'Nouvelle-Zélande (la)',
      'en-US': 'New Zealand',
      display: 'Nieuw-Zeeland',
    },
    NI: {
      'fr-FR': 'Nicaragua (le)',
      'en-US': 'Nicaragua',
      display: 'Nicaragua',
    },
    NE: {
      'fr-FR': 'Niger (le)',
      'en-US': 'Niger (the)',
      display: 'Niger',
    },
    NG: {
      'fr-FR': 'Nigéria (le)',
      'en-US': 'Nigeria',
      display: 'Nigeria',
    },
    NU: {
      'fr-FR': 'Niue',
      'en-US': 'Niue',
      display: 'Niue',
    },
    NF: {
      'fr-FR': "Norfolk (l'Île)",
      'en-US': 'Norfolk Island',
      display: 'Norfolk',
    },
    MK: {
      'fr-FR': 'Macédoine du Nord (la)',
      'en-US': 'North Macedonia',
      display: 'Noord-Macedonië',
    },
    MP: {
      'fr-FR': 'Mariannes du Nord (les Îles)',
      'en-US': 'Northern Mariana Islands (the)',
      display: 'Noordelijke Marianen',
    },
    NO: {
      'fr-FR': 'Norvège (la)',
      'en-US': 'Norway',
      display: 'Noorwegen',
    },
    OM: {
      'fr-FR': 'Oman',
      'en-US': 'Oman',
      display: 'Oman',
    },
    PK: {
      'fr-FR': 'Pakistan (le)',
      'en-US': 'Pakistan',
      display: 'Pakistan',
    },
    PW: {
      'fr-FR': 'Palaos (les)',
      'en-US': 'Palau',
      display: 'Palau',
    },
    PS: {
      'fr-FR': 'Palestine, État de',
      'en-US': 'Palestine, State of',
      display: 'Palestina',
    },
    PA: {
      'fr-FR': 'Panama (le)',
      'en-US': 'Panama',
      display: 'Panama',
    },
    PG: {
      'fr-FR': 'Papouasie-Nouvelle-Guinée (la)',
      'en-US': 'Papua New Guinea',
      display: 'Papoea-Nieuw-Guinea',
    },
    PY: {
      'fr-FR': 'Paraguay (le)',
      'en-US': 'Paraguay',
      display: 'Paraguay',
    },
    PE: {
      'fr-FR': 'Pérou (le)',
      'en-US': 'Peru',
      display: 'Peru',
    },
    PH: {
      'fr-FR': 'Philippines (les)',
      'en-US': 'Philippines (the)',
      display: 'Filipijnen Filipijnen',
    },
    PN: {
      'fr-FR': 'Pitcairn',
      'en-US': 'Pitcairn',
      display: 'Pitcairneilanden',
    },
    PL: {
      'fr-FR': 'Pologne (la)',
      'en-US': 'Poland',
      display: 'Polen',
    },
    PT: {
      'fr-FR': 'Portugal (le)',
      'en-US': 'Portugal',
      display: 'Portugal',
    },
    PR: {
      'fr-FR': 'Porto Rico',
      'en-US': 'Puerto Rico',
      display: 'Rico Puerto Rico',
    },
    QA: {
      'fr-FR': 'Qatar (le)',
      'en-US': 'Qatar',
      display: 'Qatar',
    },
    RO: {
      'fr-FR': 'Roumanie (la)',
      'en-US': 'Romania',
      display: 'Roemenië',
    },
    RU: {
      'fr-FR': 'Russie (la Fédération de)',
      'en-US': 'Russian Federation (the)',
      display: 'Rusland',
    },
    RW: {
      'fr-FR': 'Rwanda (le)',
      'en-US': 'Rwanda',
      display: 'Rwanda',
    },
    RE: {
      'fr-FR': 'Réunion (La)',
      'en-US': 'Réunion',
      display: 'Réunion',
    },
    BL: {
      'fr-FR': 'Saint-Barthélemy',
      'en-US': 'Saint Barthélemy',
      display: 'Saint-Barthélemy',
    },
    SH: {
      'fr-FR': 'Sainte-Hélène, Ascension et Tristan da Cunha',
      'en-US': 'Saint Helena, Ascension and Tristan da Cunha',
      display: 'Sint-Helena, Ascension en Tristan da Cunha',
    },
    KN: {
      'fr-FR': 'Saint-Kitts-et-Nevis',
      'en-US': 'Saint Kitts and Nevis',
      display: 'Saint Kitts en Nevis',
    },
    LC: {
      'fr-FR': 'Sainte-Lucie',
      'en-US': 'Saint Lucia',
      display: 'Saint Lucia',
    },
    MF: {
      'fr-FR': 'Saint-Martin (partie française)',
      'en-US': 'Saint Martin (French part)',
      display: 'Sint-Maarten',
    },
    PM: {
      'fr-FR': 'Saint-Pierre-et-Miquelon',
      'en-US': 'Saint Pierre and Miquelon',
      display: 'Saint-Pierre en Miquelon',
    },
    VC: {
      'fr-FR': 'Saint-Vincent-et-les Grenadines',
      'en-US': 'Saint Vincent and the Grenadines',
      display: 'Saint Vincent en de Grenadines',
    },
    WS: {
      'fr-FR': 'Samoa (le)',
      'en-US': 'Samoa',
      display: 'Samoa',
    },
    SM: {
      'fr-FR': 'Saint-Marin',
      'en-US': 'San Marino',
      display: 'Marino San Marino',
    },
    ST: {
      'fr-FR': 'Sao Tomé-et-Principe',
      'en-US': 'Sao Tome and Principe',
      display: 'Sao Tomé en Principe',
    },
    SA: {
      'fr-FR': "Arabie saoudite (l')",
      'en-US': 'Saudi Arabia',
      display: 'Saoedi-Arabië',
    },
    SN: {
      'fr-FR': 'Sénégal (le)',
      'en-US': 'Senegal',
      display: 'Senegal',
    },
    RS: {
      'fr-FR': 'Serbie (la)',
      'en-US': 'Serbia',
      display: 'Servië',
    },
    SC: {
      'fr-FR': 'Seychelles (les)',
      'en-US': 'Seychelles',
      display: 'Seychellen',
    },
    SL: {
      'fr-FR': 'Sierra Leone (la)',
      'en-US': 'Sierra Leone',
      display: 'Sierra Leone',
    },
    SG: {
      'fr-FR': 'Singapour',
      'en-US': 'Singapore',
      display: 'Singapore',
    },
    SX: {
      'fr-FR': 'Saint-Martin (partie néerlandaise)',
      'en-US': 'Sint Maarten (Dutch part)',
      display: 'Sint Maarten',
    },
    SK: {
      'fr-FR': 'Slovaquie (la)',
      'en-US': 'Slovakia',
      display: 'Slowakije',
    },
    SI: {
      'fr-FR': 'Slovénie (la)',
      'en-US': 'Slovenia',
      display: 'Slovenië',
    },
    SB: {
      'fr-FR': 'Salomon (les Îles)',
      'en-US': 'Solomon Islands',
      display: 'Salomonseilanden',
    },
    SO: {
      'fr-FR': 'Somalie (la)',
      'en-US': 'Somalia',
      display: 'Somalië',
    },
    ZA: {
      'fr-FR': "Afrique du Sud (l')",
      'en-US': 'South Africa',
      display: 'Zuid-Afrika',
    },
    GS: {
      'fr-FR': 'Géorgie du Sud-et-les Îles Sandwich du Sud (la)',
      'en-US': 'South Georgia and the South Sandwich Islands',
      display: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    },
    SS: {
      'fr-FR': 'Soudan du Sud (le)',
      'en-US': 'South Sudan',
      display: 'Zuid-Soedan',
    },
    ES: {
      'fr-FR': "Espagne (l')",
      'en-US': 'Spain',
      display: 'Spanje',
    },
    LK: {
      'fr-FR': 'Sri Lanka',
      'en-US': 'Sri Lanka',
      display: 'Sri Lanka',
    },
    SD: {
      'fr-FR': 'Soudan (le)',
      'en-US': 'Sudan (the)',
      display: 'Soedan',
    },
    SR: {
      'fr-FR': 'Suriname (le)',
      'en-US': 'Suriname',
      display: 'Suriname',
    },
    SJ: {
      'fr-FR': "Svalbard et l'Île Jan Mayen (le)",
      'en-US': 'Svalbard and Jan Mayen',
      display: 'Spitsbergen en Jan Mayen',
    },
    SE: {
      'fr-FR': 'Suède (la)',
      'en-US': 'Sweden',
      display: 'Zweden',
    },
    CH: {
      'fr-FR': 'Suisse (la)',
      'en-US': 'Switzerland',
      display: 'Zwitserland',
    },
    SY: {
      'fr-FR': 'République arabe syrienne (la)',
      'en-US': 'Syrian Arab Republic (the)',
      display: 'Syrië',
    },
    TW: {
      'fr-FR': 'Taïwan (Province de Chine)',
      'en-US': 'Taiwan (Province of China)',
      display: 'Taiwan',
    },
    TJ: {
      'fr-FR': 'Tadjikistan (le)',
      'en-US': 'Tajikistan',
      display: 'Tadzjikistan',
    },
    TZ: {
      'fr-FR': 'Tanzanie (la République-Unie de)',
      'en-US': 'Tanzania, the United Republic of',
      display: 'Tanzania',
    },
    TH: {
      'fr-FR': 'Thaïlande (la)',
      'en-US': 'Thailand',
      display: 'Thailand',
    },
    TL: {
      'fr-FR': 'Timor-Leste (le)',
      'en-US': 'Timor-Leste',
      display: 'Oost-Timor',
    },
    TG: {
      'fr-FR': 'Togo (le)',
      'en-US': 'Togo',
      display: 'Togo',
    },
    TK: {
      'fr-FR': 'Tokelau (les)',
      'en-US': 'Tokelau',
      display: 'Tokelau',
    },
    TO: {
      'fr-FR': 'Tonga (les)',
      'en-US': 'Tonga',
      display: 'Tonga',
    },
    TT: {
      'fr-FR': 'Trinité-et-Tobago (la)',
      'en-US': 'Trinidad and Tobago',
      display: 'Trinidad en Tobago',
    },
    TN: {
      'fr-FR': 'Tunisie (la)',
      'en-US': 'Tunisia',
      display: 'Tunesië',
    },
    TR: {
      'fr-FR': 'Turquie (la)',
      'en-US': 'Turkey',
      display: 'Turkije',
    },
    TM: {
      'fr-FR': 'Turkménistan (le)',
      'en-US': 'Turkmenistan',
      display: 'Turkmenistan',
    },
    TC: {
      'fr-FR': 'Turks-et-Caïcos (les Îles)',
      'en-US': 'Turks and Caicos Islands (the)',
      display: 'Turks- en Caicoseilanden',
    },
    TV: {
      'fr-FR': 'Tuvalu (les)',
      'en-US': 'Tuvalu',
      display: 'Tuvalu',
    },
    UG: {
      'fr-FR': "Ouganda (l')",
      'en-US': 'Uganda',
      display: 'Oeganda',
    },
    UA: {
      'fr-FR': "Ukraine (l')",
      'en-US': 'Ukraine',
      display: 'Oekraïne',
    },
    AE: {
      'fr-FR': 'Émirats arabes unis (les)',
      'en-US': 'United Arab Emirates (the)',
      display: 'Verenigde Arabische Emiraten',
    },
    GB: {
      'fr-FR': "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
      'en-US': 'United Kingdom of Great Britain and Northern Ireland (the)',
      display: 'Verenigd Koninkrijk',
    },
    UM: {
      'fr-FR': 'Îles mineures éloignées des États-Unis (les)',
      'en-US': 'United States Minor Outlying Islands (the)',
      display: 'Kleine afgelegen eilanden van de Verenigde Staten',
    },
    US: {
      'fr-FR': "États-Unis d'Amérique (les)",
      'en-US': 'United States of America (the)',
      display: 'Verenigde Staten',
    },
    UY: {
      'fr-FR': "Uruguay (l')",
      'en-US': 'Uruguay',
      display: 'Uruguay',
    },
    UZ: {
      'fr-FR': "Ouzbékistan (l')",
      'en-US': 'Uzbekistan',
      display: 'Oezbekistan',
    },
    VU: {
      'fr-FR': 'Vanuatu (le)',
      'en-US': 'Vanuatu',
      display: 'Vanuatu',
    },
    VE: {
      'fr-FR': 'Venezuela (République bolivarienne du)',
      'en-US': 'Venezuela (Bolivarian Republic of)',
      display: 'Venezuela',
    },
    VN: {
      'fr-FR': 'Viet Nam (le)',
      'en-US': 'Viet Nam',
      display: 'Vietnam',
    },
    VG: {
      'fr-FR': 'Vierges britanniques (les Îles)',
      'en-US': 'Virgin Islands (British)',
      display: 'Britse Maagdeneilanden',
    },
    VI: {
      'fr-FR': 'Vierges des États-Unis (les Îles)',
      'en-US': 'Virgin Islands (U.S.)',
      display: 'Amerikaanse Maagdeneilanden',
    },
    WF: {
      'fr-FR': 'Wallis-et-Futuna ',
      'en-US': 'Wallis and Futuna',
      display: 'Wallis en Futuna',
    },
    EH: {
      'fr-FR': 'Sahara occidental (le)*',
      'en-US': 'Western Sahara*',
      display: 'Westelijke Sahara',
    },
    YE: {
      'fr-FR': 'Yémen (le)',
      'en-US': 'Yemen',
      display: 'Jemen',
    },
    ZM: {
      'fr-FR': 'Zambie (la)',
      'en-US': 'Zambia',
      display: 'Zambia',
    },
    ZW: {
      'fr-FR': 'Zimbabwe (le)',
      'en-US': 'Zimbabwe',
      display: 'Zimbabwe',
    },
    AX: {
      'fr-FR': 'Åland(les Îles)',
      'en-US': 'Åland Islands',
      display: 'Åland',
    },
  },
};
