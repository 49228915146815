import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appointmentParticipantRequired',
})
export class AppointmentParticipantRequiredPipe implements PipeTransform {
  transform(required: string | undefined | boolean): string {
    switch (required) {
      case 'required':
        return $localize`:@@fhir.appointment-participant-required.required:Required`;
      case 'optional':
        return $localize`:@@fhir.appointment-participant-required.optional:Optional`;
      case 'information-only':
        return $localize`:@@fhir.appointment-participant-required.information-only:Information Only`;
      default:
        return '';
    }
  }
}
