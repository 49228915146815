import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HumanName } from '@hl7fhir';

@Component({
  selector: 'app-human-name',
  templateUrl: './human-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['span:empty::before { content: "\\200b"; /* unicode zero width space character */ }'],
})
export class HumanNameComponent {
  @Input() humanName: HumanName | undefined;
}
