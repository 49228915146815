import { Binary } from '@hl7fhir';
import { BinaryViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromBinary from './binary.reduce';

export const selectBinaryState = createFeatureSelector<fromBinary.State>(features.FHIR.binaries);

export const selectBinaryEntities = createSelector(selectBinaryState, fromBinary.selectBinaryEntities);

export const selectAllBinaries = createSelector(selectBinaryState, fromBinary.selectAllBinaries);

export const selectBinaryById = createSelector(
  selectRouteParams,
  selectBinaryEntities,
  ({ id }, binaries: Dictionary<Binary>) =>
    id && (binaries[id] ?? binaries[id.toLowerCase()] ?? binaries[id.toUpperCase()])
);

export const selectBinaryByReferenceId = (id: string | undefined) =>
  createSelector(
    selectBinaryEntities,
    (binaries: Dictionary<Binary>) => id && (binaries[id] ?? binaries[id.toLowerCase()] ?? binaries[id.toUpperCase()])
  );

export const selectAllBinaryViewModels = createSelector(selectAllBinaries, (binaries: Binary[]) =>
  binaries.map((binary) => new BinaryViewModel(binary))
);

export const selectBinaryViewModelById = createSelector(
  selectBinaryById,
  (entity: Binary | undefined) => entity && new BinaryViewModel(entity)
);
