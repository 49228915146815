import { formatLocaleDate } from '@globals';
import { DocumentManifest } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { DocumentManifestStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { DocumentManifestContentViewModel } from './document-manifest-content.viewmodel';
import { DocumentManifestRelatedViewModel } from './document-manifest-related.viewmodel';

export class DocumentManifestViewModel extends DomainResourceViewModel<DocumentManifest> {
  get author(): string | undefined {
    return this.resource?.author && getReferences(this.resource.author);
  }

  get created(): string | undefined {
    return this.resource?.created && formatLocaleDate(this.resource.created, 'long');
  }

  get description(): string | undefined {
    return this.resource?.description;
  }

  get identifier(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((id) => new IdentifierViewModel(id, this.fhirVersion));
  }

  get masterIdentifier(): IdentifierViewModel | undefined {
    return this.resource?.masterIdentifier && new IdentifierViewModel(this.resource.masterIdentifier, this.fhirVersion);
  }

  get recipient(): string | undefined {
    return this.resource?.recipient && getReferences(this.resource.recipient);
  }

  get source(): string | undefined {
    return this.resource?.source;
  }

  get status(): string | undefined {
    return new DocumentManifestStatusPipe().transform(this.resource?.status);
  }

  get subject(): string | undefined {
    return this.resource?.subject && getReference(this.resource.subject);
  }

  get type(): string | undefined {
    return this.resource?.type && new CodeableConceptPipe().transform(this.resource.type);
  }

  get content(): DocumentManifestContentViewModel[] | undefined {
    if (this.isR3()) {
      const context = this.resource as r3.DocumentManifest;
      return context?.content?.map((content) => new DocumentManifestContentViewModel(content, this.fhirVersion));
    }

    return undefined;
  }

  get related(): DocumentManifestRelatedViewModel[] | undefined {
    if (!this.isR5()) {
      const context = this.resource as r3.DocumentManifest | r4.DocumentManifest | r4b.DocumentManifest;
      return context?.related?.map((related) => new DocumentManifestRelatedViewModel(related, this.fhirVersion));
    }

    return undefined;
  }
}
