import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsFilesCompleted, selectIsLoading, selectMustCreateAccount, selectMustOnboard } from '@store/digi.me';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent {
  readonly success$: Observable<boolean> = this.store.select(selectIsFilesCompleted);
  readonly loading$: Observable<boolean> = this.store.select(selectIsLoading);
  readonly mustOnboard$: Observable<boolean> = this.store.select(selectMustOnboard);
  readonly mustCreateAccount$: Observable<boolean> = this.store.select(selectMustCreateAccount);

  constructor(private readonly store: Store) {}
}
