<div [class.d-grid]="use === 'block' || use === 'menu'">
  <button *ngIf="visible && !href"
          data-cy="button-normal"
          class="btn icon-link text-nowrap"
          [class.justify-content-center]="use === 'block'"
          (click)="onClicked()"
          [disabled]="disabled"
          [class.btn-primary]="type === 'primary'"
          [class.btn-secondary]="type === 'secondary'"
          [class.btn-destructive]="type === 'destructive'"
          [class.btn-navigation]="type === 'navigation'"
          [class.selected]="selected"
          [type]="btnType">
    <mat-icon *ngIf="icon && !working"
              [fontIcon]="icon"></mat-icon>
    <svg *ngIf="svg && !working"
         class="menu-icon">
      <use attr.xlink:href="./assets/images/assets.svg#{{svg}}"></use>
    </svg>
    <span *ngIf="working"
          class="spinner-border spinner-border-sm"
          aria-hidden="true"></span>
    <span *ngIf="label">{{ label }}</span>
    <ng-content></ng-content>
  </button>

  <a *ngIf="visible && href"
     data-cy="button-href"
     class="btn icon-link text-nowrap"
     [class.justify-content-center]="use === 'block'"
     (click)="onClicked()"
     [class.btn-primary]="type === 'primary'"
     [class.btn-secondary]="type === 'secondary'"
     [class.btn-destructive]="type === 'destructive'"
     [class.btn-navigation]="type === 'navigation'"
     [class.selected]="selected"
     [attr.href]="href"
     [attr.target]="target"
     type="button">

    <span *ngIf="working"
          class="spinner-border spinner-border-sm"
          aria-hidden="true"></span>
    <mat-icon *ngIf="icon && !working"
              [fontIcon]="icon"></mat-icon>
    <svg *ngIf="svg && !working"
         class="menu-icon">
      <use attr.xlink:href="./assets/images/assets.svg#{{svg}}"></use>
    </svg>
    <span *ngIf="working"
          class="spinner-border spinner-border-sm"
          aria-hidden="true"></span>
    <span *ngIf="label">{{ label }}</span>
  </a>
</div>