<div class="d-flex justify-content-center align-items-center h-100 bg-black bg-opacity-50 position-absolute w-100 top-0"
     style="z-index: 10000;"
     *ngIf="resetting"
     data-cy="digi-me-reset-indicator">
  <div class="card shadow">
    <div class="card-body d-flex align-items-center p-5">
      <div class="spinner-border text-success"
           role="status">
      </div>
      <span class="ms-2 text-success fw-bold"
            i18n="@@app.clearingAllData">Clearing all imported encrypted data...</span>
    </div>
  </div>
</div>

<ng-template #resetWarning
             let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-danger"
        id="modal-basic-title"
        i18n="@@app.reset.header">Clear all imported encrypted data</h4>
    <button type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Close')"></button>
  </div>
  <div class="modal-body">
    <p i18n="@@app.reset.warning">This will clear all your imported encrypted medical data from this application. It
      will remain
      available for you to
      re-onboard by selecting you medical service provider(s).</p>
  </div>
  <div class="modal-footer">
    <app-button type="destructive"
                i18n-label="@@app.reset.clear"
                label="Clear"
                (clicked)="modal.close('Clear')"></app-button>
    <app-button type="secondary"
                i18n-label="@@app.reset.cancel"
                (clicked)="modal.close('Close')"
                label="Cancel"></app-button>
  </div>
</ng-template>