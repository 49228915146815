import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { formatLocaleDate } from '@globals';
import { ImmunizationRecommendationRecommendationDateCriterion } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';

export class ImmunizationRecommendationRecommendationDateCriterionViewModel extends BackboneElementViewModel<ImmunizationRecommendationRecommendationDateCriterion> {
  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.code);
  }

  get value(): string | undefined {
    return formatLocaleDate(this.element?.value, 'long');
  }

  override toString(): string | undefined {
    let result = this.value;
    if (this.code) {
      result += ' (' + this.code + ')';
    }

    return result;
  }
}
