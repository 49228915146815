<div class="d-flex flex-column justify-content-center h-100">
  <div class="row g-0 justify-content-center">
    <div class="col-md-6 col-lg-4 col-xl-3 text-center">
      <div class="d-flex justify-content-center align-items-center flex-column">
        <svg class="onboard-image">
          <use xlink:href="./assets/images/assets.svg#linked-empty"></use>
        </svg>
        <p i18n="@@app.onboard.description">See your medical records by linking to your healthcare providers. Select
          from thousands of GPs and Hospitals.</p>
        <app-button (clicked)="sourceLink.emit()"
                    icon="add_link"
                    i18n-label="@@app.onboard.button"
                    label="Find your healthcare provider">
        </app-button>
      </div>
    </div>
  </div>
</div>