import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This interceptor is designed to add the environment header to all API requests, ensuring that each request aligns with
 * the appropriate environment. This is crucial, as the client app operates across various digi.me development environments,
 * with the sole exception of production.
 *
 * The purpose of an empty interceptor is to replace this api interceptor in the production environment.
 * This is done to remove all traces of the API interceptor from the client app while in production, to prevent bad
 * actors from exploiting it.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (req.url.includes('/api/')) {
      const env = sessionStorage.getItem('env') ?? 'prod';

      request = req.clone({
        setHeaders: {
          env,
        },
      });
    }

    return next.handle(request);
  }
}
