import { NutritionOrderEnteralFormulaAdministration } from '@hl7fhir';
import { QuantityTypePipe, RatioPipe, TimingViewModel } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class NutritionOrderEnteralFormulaAdministrationViewModel extends ElementViewModel<NutritionOrderEnteralFormulaAdministration> {
  get quantity(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.quantity);
  }

  get rateQuantity(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.rateQuantity);
  }

  get rateRatio(): string | undefined {
    return new RatioPipe().transform(this.element?.rateRatio);
  }

  get schedule(): TimingViewModel | undefined {
    const nutritionOrderEnteralFormulaAdministrationR = this.element as
      | r3.NutritionOrderEnteralFormulaAdministration
      | r4.NutritionOrderEnteralFormulaAdministration
      | r4b.NutritionOrderEnteralFormulaAdministration;
    return new TimingViewModel(nutritionOrderEnteralFormulaAdministrationR?.schedule, this.fhirVersion);
  }
}
