import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selects',
})
export class SelectsPipe implements PipeTransform {
  transform<T, R>(obj: T[] | undefined, prop: keyof T): R[] | undefined {
    if (!obj) return undefined;

    return obj.map((o) => o[prop] as R);
  }
}
