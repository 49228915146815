import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectCompositionAttesterMode',
})
export class CompositionAttesterModesPipe implements PipeTransform {
  transform(statuses: string[] | string | undefined | null): string {
    if (!statuses) {
      return '';
    }

    if (Array.isArray(statuses)) {
      return statuses.map((status) => this.transformMode(status)).join(', ');
    } else {
      return this.transformMode(statuses);
    }
  }

  private transformMode(mode: string | undefined | null): string {
    switch (mode) {
      case 'personal':
        return $localize`:@@fhir.composition-status.personal:Personal`;
      case 'professional':
        return $localize`:@@fhir.composition-status.professional:Professional`;
      case 'legal':
        return $localize`:@@fhir.composition-status.legal:Legal`;
      case 'official':
        return $localize`:@@fhir.composition-status.official:Official`;
      default:
        return '';
    }
  }
}
