import { Pipe, PipeTransform } from '@angular/core';
import { CodeableConcept, Coding } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';

@Pipe({
  name: 'maritalStatus',
})
export class MaritalStatusPipe implements PipeTransform {
  transform(status: CodeableConcept | undefined): string {
    if (!status) {
      return '';
    }

    if (status?.text) {
      return status.text;
    }

    const codings: Coding[] | undefined = status.coding;
    const coding = codings?.find(
      (coding: Coding) =>
        coding?.system === CodeSystems.V3_MARITAL_STATUS || coding?.system === CodeSystems.R3_V3_MARITAL_STATUS
    );
    if (coding) {
      switch (coding.code) {
        case 'A':
          return $localize`:@@fhir.v3-MaritalStatus.A:Annulled`;
        case 'D':
          return $localize`:@@fhir.v3-MaritalStatus.D:Divorced`;
        case 'I':
          return $localize`:@@fhir.v3-MaritalStatus.I:Interlocutory`;
        case 'L':
          return $localize`:@@fhir.v3-MaritalStatus.L:Legally Separated`;
        case 'M':
          return $localize`:@@fhir.v3-MaritalStatus.M:Married`;
        case 'C':
          return $localize`:@@fhir.v3-MaritalStatus.C:Common Law`;
        case 'P':
          return $localize`:@@fhir.v3-MaritalStatus.P:Polygamous`;
        case 'T':
          return $localize`:@@fhir.v3-MaritalStatus.T:Domestic partner`;
        case 'U':
          return $localize`:@@fhir.v3-MaritalStatus.U:unmarried`;
        case 'S':
          return $localize`:@@fhir.v3-MaritalStatus.S:Never Married`;
        case 'W':
          return $localize`:@@fhir.v3-MaritalStatus.W:Widowed`;
      }
    }

    const nullCoding = codings?.find(
      (coding: Coding | undefined) =>
        coding?.system === CodeSystems.V3_NULL_FLAVOR || coding?.system === CodeSystems.R3_V3_NULL_FLAVOR
    );
    if (nullCoding && nullCoding.code === 'UNK') {
      // Only code allowed
      // TODO, should not be needed anymore after code system lookups
      return $localize`:@@fhir.v3-NullFlavor.UNK:unknown`;
    }

    // TODO Log mismatch
    return '';
  }
}
