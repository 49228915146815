import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { Coding, ImagingStudy, ImagingStudySeries } from '@hl7fhir';
import { CodeableConceptPipe, CodingsPipe, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { AvailabilityPipe } from '@hl7fhir/value-sets';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { ImagingStudySeriesViewModel } from './imaging-study-series.viewmodel';
import { formatLocaleDate } from '@globals';

export class ImagingStudyViewModel extends DomainResourceViewModel<ImagingStudy> {
  get accession(): IdentifierViewModel | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    if (this.fhirVersion === 'R3') {
      return imagingStudyR3?.accession
        ? new IdentifierViewModel(imagingStudyR3.accession, this.fhirVersion)
        : undefined;
    }

    return undefined;
  }

  get availability(): string | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    if (this.fhirVersion === 'R3') {
      return new AvailabilityPipe().transform(imagingStudyR3?.availability);
    }

    return undefined;
  }

  get basedOn(): string | undefined {
    return getReferences(this.resource?.basedOn);
  }

  get context(): string | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    return getReference(imagingStudyR3?.context);
  }

  get description(): string | undefined {
    return this.resource?.description;
  }

  get endpoint(): string | undefined {
    return getReferences(this.resource?.endpoint);
  }

  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get interpreter(): string | undefined {
    const imagingStudyR = this.resource as r3.ImagingStudy | r4.ImagingStudy | r4b.ImagingStudy;
    return getReferences(imagingStudyR?.interpreter);
  }

  get modalityList(): string | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    if (this.fhirVersion === 'R3') {
      return new CodingsPipe().transform(imagingStudyR3?.modalityList);
    }

    return undefined;
  }

  get numberOfInstances(): string | undefined {
    return this.resource?.numberOfInstances?.toString();
  }

  get numberOfSeries(): string | undefined {
    return this.resource?.numberOfSeries?.toString();
  }

  get patient(): string | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    return getReference(imagingStudyR3?.patient);
  }

  get procedureCode(): string | undefined {
    const imagingStudyR = this.resource as r3.ImagingStudy | r4.ImagingStudy | r4b.ImagingStudy;
    return getReferences(imagingStudyR?.procedureCode);
  }

  get procedureReference(): string | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    return getReferences(imagingStudyR3?.procedureReference);
  }

  get reason(): string | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    return new CodeableConceptPipe().transform(imagingStudyR3?.reason);
  }

  get referrer(): string | undefined {
    return getReference(this.resource?.referrer);
  }

  get started(): string | undefined {
    return formatLocaleDate(this.resource?.started, 'long');
  }

  get uid(): string | undefined {
    const imagingStudyR3 = this.resource as r3.ImagingStudy;
    return imagingStudyR3?.uid;
  }

  get series(): ImagingStudySeriesViewModel[] | undefined {
    return this.resource?.series?.map((series) => new ImagingStudySeriesViewModel(series, this.fhirVersion));
  }

  get valueSeries(): string | undefined {
    if (!this.resource?.series) {
      return undefined;
    }

    const bodySites: Coding[] = this.resource.series
      .map((series: ImagingStudySeries) => series?.bodySite)
      .filter((coding): coding is Coding => Boolean(coding));
    const modalities: Coding[] = this.resource.series
      .map((series: ImagingStudySeries) => series?.modality)
      .filter((coding): coding is Coding => Boolean(coding));
    const lateralities: Coding[] = this.resource.series
      .map((series: ImagingStudySeries) => series?.laterality)
      .filter((coding): coding is Coding => Boolean(coding));

    return new CodingsPipe().transform([...bodySites, ...lateralities, ...modalities] as Coding[]);
  }
}
