import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { Device } from '@hl7fhir';
import {
  AnnotationViewModel,
  CodeableConceptPipe,
  ContactPointViewModel,
  IdentifierViewModel,
} from '@hl7fhir/data-types';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { DeviceUdiViewModel } from './device-udi.viewmodel';
import { getReference } from '@hl7fhir/foundation';
import { formatLocaleDate } from '@globals';

export class DeviceViewModel extends DomainResourceViewModel<Device> {
  get identifier(): IdentifierViewModel[] | undefined {
    return (
      this.resource?.identifier &&
      this.resource.identifier.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion))
    );
  }

  get manufacturer() {
    return this.resource?.manufacturer;
  }

  get model() {
    const resourceR3 = this.resource as r3.Device;
    return resourceR3.model;
  }

  get expirationDate() {
    return this.resource?.expirationDate && formatLocaleDate(this.resource.expirationDate, 'long');
  }

  get manufactureDate() {
    return this.resource?.manufactureDate && formatLocaleDate(this.resource.manufactureDate, 'long');
  }

  get lotNumber() {
    return this.resource?.lotNumber;
  }

  get status() {
    return this.resource?.status;
  }

  get type() {
    return this.resource?.type && new CodeableConceptPipe().transform(this.resource.type);
  }

  get location() {
    return getReference(this.resource?.location);
  }

  get owner() {
    return getReference(this.resource?.owner);
  }

  get patient() {
    const resourceR = this.resource as r3.Device | r4.Device | r4b.Device;
    return getReference(resourceR?.patient);
  }

  get safety() {
    return this.resource?.safety && new CodeableConceptPipe().transform(this.resource.safety);
  }

  get note() {
    return this.resource?.note && this.resource.note.map((n) => new AnnotationViewModel(n, this.fhirVersion));
  }

  get contact() {
    return this.resource?.contact && this.resource.contact.map((c) => new ContactPointViewModel(c, this.fhirVersion));
  }

  get udi() {
    const resourceR3 = this.resource as r3.Device;
    return resourceR3.udi && new DeviceUdiViewModel(resourceR3.udi, this.fhirVersion);
  }

  get uidDisplay() {
    const resourceR3 = this.resource as r3.Device;
    const resourceR4R4b = this.resource as r4.Device | r4b.Device;
    const resourceR5 = this.resource as r5.Device;

    if (this.fhirVersion === 'R3') {
      return resourceR3.udi?.name;
    } else if (this.fhirVersion === 'R4' || this.fhirVersion === 'R4b') {
      return resourceR4R4b.deviceName?.map((d: r4.DeviceDeviceName | r4b.DeviceDeviceName) => d.name).join(', ');
    } else if (this.fhirVersion === 'R5') {
      return resourceR5.name?.map((d: r5.DeviceName) => d.display).join(', ');
    }

    return '';
  }
}
