import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-button',
  imports: [CommonModule, MatIconModule],
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() label?: string;
  @Input() icon?: string;
  @Input() disabled: boolean = false;
  @Input() visible: boolean = true;
  @Input() selected: boolean = false;
  @Input() href?: string;
  @Input() target: '_blank' | '_self' | '_parent' | '_top' | string = '_blank';
  @Input() type: 'primary' | 'secondary' | 'destructive' | 'navigation' = 'primary';
  @Input() use: 'default' | 'block' | 'menu' = 'default';
  @Input() working: boolean = false;
  @Input() svg?: string;

  /**
   * The type of the button.
   * Possible values are: 'button', 'submit', 'reset'.
   * Defaults to 'button'.
   */
  @Input({ required: false }) btnType: 'button' | 'submit' | 'reset' = 'button';

  @Output() clicked = new EventEmitter();

  onClicked() {
    if (!this.working) {
      this.clicked.emit();
    }
  }
}
