import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '../../fhir.types';

@Pipe({
  name: 'hasProfile',
})
export class HasProfilePipe implements PipeTransform {
  transform(resource: Resource | null | undefined, profile: string): boolean {
    if (!resource || !resource.meta || !resource.meta.profile) {
      return false;
    }

    return resource.meta.profile.some((url) => url === profile);
  }
}
