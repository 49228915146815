import { Injectable } from '@angular/core';
import { configId } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { delay, tap } from 'rxjs';
// The same issue with imports as in mixpanel.service.ts
// ! TODO We need to address and resolve this issue.
import { DeviceService } from '../../core/services/device.service';
import { USER_ACTIONS } from './user.actions';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  /**
   * Effect that handles the sign out action.
   * Removes the device ID, logs off and revokes tokens for the current user.
   */
  signOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signOut),
        delay(500),
        tap(() => {
          // Set a timeout to dispatch the 'sign_out' event using Mixpanel before initiating the user's logoff process.
          // This ensures that the 'sign_out' event is dispatched prior to the user being logged off. If the user is logged
          // off before the 'sign_out' event is dispatched, the event will not be sent. This occurs because the user is logged
          // off before the HTTP request to Mixpanel is completed, resulting in the request being canceled.
          this.deviceService.removeDeviceId();
          this.deviceService.removeFromLocalStorage();
          this.oidcSecurityService.logoffAndRevokeTokens(configId).subscribe();
          this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-signup`).subscribe();
        })
      );
    },
    {
      dispatch: false,
    }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly deviceService: DeviceService
  ) {}
}
