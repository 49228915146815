import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nutritionOrderStatus',
})
export class NutritionOrderStatusPipe implements PipeTransform {
  transform(status: string | undefined): string {
    switch (status) {
      case 'proposed':
        return $localize`:@@fhir.nutrition-order-status.proposed:Proposed`;
      case 'draft':
        return $localize`:@@fhir.nutrition-order-status.draft:Draft`;
      case 'planned':
        return $localize`:@@fhir.nutrition-order-status.planned:Planned`;
      case 'requested':
        return $localize`:@@fhir.nutrition-order-status.requested:Requested`;
      case 'active':
        return $localize`:@@fhir.nutrition-order-status.active:Active`;
      case 'on-hold':
        return $localize`:@@fhir.nutrition-order-status.on-hold:On Hold`;
      case 'completed':
        return $localize`:@@fhir.nutrition-order-status.completed:Completed`;
      case 'cancelled':
        return $localize`:@@fhir.nutrition-order-status.cancelled:Cancelled`;
      case 'entered-in-error':
        return $localize`:@@fhir.nutrition-order-status.entered-in-error:Entered In Error`;
      default:
        return '';
    }
  }
}
