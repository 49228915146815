import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemRoleClass: CodeSystemFlattened = {
  id: 'v3-RoleClass',
  codes: {
    ROL: {
      display: 'role',
    },
    CHILD: {
      display: 'child',
    },
    CRED: {
      display: 'credentialed entity',
    },
    NURPRAC: {
      display: 'nurse practitioner',
    },
    NURS: {
      display: 'nurse',
    },
    PA: {
      display: 'physician assistant',
    },
    PHYS: {
      display: 'physician',
    },
    _RoleClassAssociative: {
      display: 'RoleClassAssociative',
    },
    _RoleClassOntological: {
      display: 'RoleClassOntological',
    },
    _RoleClassPartitive: {
      display: 'RoleClassPartitive',
    },
    _RoleClassMutualRelationship: {
      display: 'RoleClassMutualRelationship',
    },
    _RoleClassPassive: {
      display: 'RoleClassPassive',
    },
    _RoleClassRelationshipFormal: {
      display: 'RoleClassRelationshipFormal',
    },
    CAREGIVER: {
      display: 'caregiver',
    },
    PRS: {
      display: 'personal relationship',
    },
    SELF: {
      display: 'self',
    },
    AFFL: {
      display: 'affiliate',
    },
    AGNT: {
      display: 'agent',
    },
    CIT: {
      display: 'citizen',
    },
    COVPTY: {
      display: 'covered party',
    },
    CRINV: {
      display: 'clinical research investigator',
    },
    CRSPNSR: {
      display: 'clinical research sponsor',
    },
    EMP: {
      display: 'employee',
    },
    GUAR: {
      display: 'guarantor',
    },
    INVSBJ: {
      display: 'Investigation Subject',
    },
    LIC: {
      display: 'licensed entity',
    },
    PAT: {
      display: 'patient',
    },
    PAYEE: {
      display: 'payee',
    },
    PAYOR: {
      display: 'invoice payor',
    },
    POLHOLD: {
      display: 'policy holder',
    },
    QUAL: {
      display: 'qualified entity',
    },
    SPNSR: {
      display: 'coverage sponsor',
    },
    STD: {
      display: 'student',
    },
    UNDWRT: {
      display: 'underwriter',
    },
    ASSIGNED: {
      display: 'assigned entity',
    },
    CON: {
      display: 'contact',
    },
    GUARD: {
      display: 'guardian',
    },
    COMPAR: {
      display: 'commissioning party',
    },
    SGNOFF: {
      display: 'signing authority or officer',
    },
    ECON: {
      display: 'emergency contact',
    },
    NOK: {
      display: 'next of kin',
    },
    CLAIM: {
      display: 'claimant',
    },
    NAMED: {
      display: 'named insured',
    },
    PROG: {
      display: 'program eligible',
    },
    DEPEN: {
      display: 'dependent',
    },
    INDIV: {
      display: 'individual',
    },
    SUBSCR: {
      display: 'subscriber',
    },
    MIL: {
      display: 'military person',
    },
    CASEBJ: {
      display: 'Case Subject',
    },
    RESBJ: {
      display: 'research subject',
    },
    NOT: {
      display: 'notary public',
    },
    PROV: {
      display: 'healthcare provider',
    },
    ACCESS: {
      display: 'access',
    },
    ADJY: {
      display: 'adjacency',
    },
    ADMM: {
      display: 'Administerable Material',
    },
    BIRTHPL: {
      display: 'birthplace',
    },
    DEATHPLC: {
      display: 'place of death',
    },
    DST: {
      display: 'distributed material',
    },
    EXLOC: {
      display: 'event location',
    },
    EXPR: {
      display: 'exposed entity',
    },
    HLD: {
      display: 'held entity',
    },
    HLTHCHRT: {
      display: 'health chart',
    },
    IDENT: {
      display: 'identified entity',
    },
    MANU: {
      display: 'manufactured product',
    },
    MNT: {
      display: 'maintained entity',
    },
    OWN: {
      display: 'owned entity',
    },
    RGPR: {
      display: 'regulated product',
    },
    TERR: {
      display: 'territory of authority',
    },
    USED: {
      display: 'used entity',
    },
    WRTE: {
      display: 'warranted product',
    },
    CONC: {
      display: 'connection',
    },
    BOND: {
      display: 'molecular bond',
    },
    CONY: {
      display: 'continuity',
    },
    RET: {
      display: 'retailed material',
    },
    SDLOC: {
      display: 'service delivery location',
    },
    DSDLOC: {
      display: 'dedicated service delivery location',
    },
    ISDLOC: {
      display: 'incidental service delivery location',
    },
    THER: {
      display: 'therapeutic agent',
    },
    EQUIV: {
      display: 'equivalent entity',
    },
    GEN: {
      display: 'has generalization',
    },
    INST: {
      display: 'instance',
    },
    SUBS: {
      display: 'subsumer',
    },
    SAME: {
      display: 'same',
    },
    SUBY: {
      display: 'subsumed by',
    },
    GRIC: {
      display: 'has generic',
    },
    CONT: {
      display: 'content',
    },
    EXPAGTCAR: {
      display: 'exposure agent carrier',
    },
    INGR: {
      display: 'ingredient',
    },
    LOCE: {
      display: 'located entity',
    },
    MBR: {
      display: 'member',
    },
    PART: {
      display: 'part',
    },
    SPEC: {
      display: 'specimen',
    },
    EXPVECTOR: {
      display: 'exposure vector',
    },
    FOMITE: {
      display: 'fomite',
    },
    ACTI: {
      display: 'active ingredient',
    },
    ADJV: {
      display: 'adjuvant',
    },
    ADTV: {
      display: 'additive',
    },
    BASE: {
      display: 'base',
    },
    CNTM: {
      display: 'contaminant ingredient',
    },
    IACT: {
      display: 'inactive ingredient',
    },
    MECH: {
      display: 'mechanical ingredient',
    },
    ACTIB: {
      display: 'active ingredient - basis of strength',
    },
    ACTIM: {
      display: 'active ingredient - moiety is basis of strength',
    },
    ACTIR: {
      display: 'active ingredient - reference substance is basis of strength',
    },
    COLR: {
      display: 'color additive',
    },
    FLVR: {
      display: 'flavor additive',
    },
    PRSV: {
      display: 'preservative',
    },
    STBL: {
      display: 'stabilizer',
    },
    STOR: {
      display: 'stored entity',
    },
    ACTM: {
      display: 'active moiety',
    },
    ALQT: {
      display: 'aliquot',
    },
    ISLT: {
      display: 'isolate',
    },
  },
};
